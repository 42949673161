<template>
    <div class="staff-el flex">
        <img v-if="img" :src="'/img/uploads/logo/esta/' + img" class="logo-staff">
        <div class="data-user-staff" @click="$router.push('/establishment/member/' + id)">
            <p class="name">{{ name }}</p>
            <p class="job">{{ job }}</p>
        </div>
        <my-button active="true" label="Add" style="width: 100px;height: 30px; margin-left: auto; margin-top: auto; margin-bottom: auto;" :class="{'close-img': true, 'pointer': true, 'ml-a': (setting == 'true')}" @click="addUser"/>
    </div>
</template>

<script>
    export default {
        name: 'member',
        props: {
            img: String,
            name: String,
            job: String,
            id: String,
            link: String
        },
        methods: {
            getId() {
                this.$emit('getid', this.id)
            },
            addUser() {
                this.$emit('add', this.id)
            }
        },
    }
</script>

<style scoped>
.logo-staff {
    object-fit: cover;
    width: 42px;
    height: 42px;
    border-radius: 100%;
}
    .staff-el {
        margin-top: 15px;
        border-radius: 10px;
        background-color: #1F1F1F;
        width: 940px;
        padding: 12px;
    }
    .name {
    margin-top: auto;
    margin-bottom: auto;
     color: var(--main-text-color); 
     font-family: 'Rubik-Regular';  
    }
    .job {
    margin-top: auto;
    margin-bottom: auto;
     color: #ABABAB; 
     font-family: 'Rubik-Light'; 
     font-size: 14px; 
    }
    .data-user-staff {
        padding-top: 3px;
        margin-left: 12px;
    }
    .setting-img {
        margin-left: auto;
    }
    .close-img {
        margin-left: 24px;
    }
    .ml-a {
    margin-left: auto;
    margin-right: 15px;
}
@media screen and (max-width: 500px) {
    .staff-el {
        width: 290px;
        background-color: #2C2C2C;
    }
}
</style>