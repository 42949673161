export default {
    data() {
        return {
            isActiveQr: false
        }
    },
    methods: {
        setActiveQr() {
            this.isActiveQr = !this.isActiveQr
        },
        updateQr() {
            this.setActiveQr()
        }
    }
}