<template>
    <div class="outer-modal-div-form client-outer">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form w-800">  
                <div class="height-client-est flex">
                    <img :src="require('../../assets/img/uploads/' + estaData.logo)" class="est-client-logo">
                    <div class="data-esta-client">
                        <h1>{{ estaData.name }}</h1>
                    </div>
                    
                </div>
                <div class="esta-bottom-er mt-2-mobile">
                    <div class="flex flex-c-c sf">
                        <div class="flex">
                            <img src="../../assets/img/UI/left-strel-client.svg" class="pointer" @click="$router.push('/')">
                            <h2 class="title-recip ml-1">Select Recipient</h2>
                        </div>
                        <div class="flex wid-100 mt-3 sf">
                            <img src="../../assets/img/UI/grey-loop.svg" class="loop-img">
                            <div class="wid-100 w-320-mob"><input type="text" class="search-input inp-s-client" placeholder="Search..."/></div>
                        </div>
                        <img src="../../assets/img/UI/QRactive.svg" @click="$router.push('/client/scan')" class="qr-active">
                        <p class="client-titlt-st">Staff</p>
                    </div>
                    <div class="w-staff-c">
                        <staff-client :avatar="el.avatar" v-for="el in staff" @click="$router.push('/client/employee')" :name="el.name" class="mt-1" :job="el.job"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>

    export default {
        name: 'search client',
        data() {
            return {
                estaData: { 
                    name: 'Macdonalds',
                    logo: 'mac.jpg',
                    star: 4
                },
                formData: {
                    check: 'Quality products',
                    price: '$150.00',
                    star: ''
                },
                staff: [
                    {
                        name: 'Denis Petrov',
                        avatar: 'BigLogo.jpg',
                        job: 'Writer'
                    },
                    {
                        name: 'Denis Petrov',
                        avatar: 'BigLogo.jpg',
                        job: 'Writer'
                    },
                    {
                        name: 'Denis Petrov',
                        avatar: 'BigLogo.jpg',
                        job: 'Writer',
                    },
                ]
            }
        },
        watch: {
            'formData.price': function() {
                if(this.formData.price.length > 1)this.formData.price = this.formData.price.replace(/[^0-9.]/g,'');
            }
        },
        methods: {
            setCheck(value) {
                this.formData.check = value
            },
            isNumber(e) {
                e.target.value =  e.target.value.replace(/[^0-9.]/g,'');
                if(e.target.value[0] != "$") {
                    e.target.value = '$' + e.target.value
                }
            },
            delPrice() {
                this.formData.price = '$'
            },
            setStar(value) {
                console.log(value)
                this.estaData.star = value
                this.formData.star = value
            }
        }
    }
</script>