import configMixin from "./configMixin"

export default {
    mixins: [configMixin],
    methods: {
        updateInfo(name, address) {

            let body = {}

            if(name != undefined && name != null && name != '') body.name = name
            if(address != undefined && address != null && address != '') body.address = address

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/team/update/info/' + this.$route.params?.establishment_id, {
                    method: "PUT",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}