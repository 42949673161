import configMixin from "./configMixin";


export default {
    mixins: [configMixin],
    methods: {
        getTeamsMixins(order, lead) {

            let page_id = (this.$route.query.page == undefined || this.$route.query.page == null) ? 
            1 : this.$route.query.page

            let orderFilter = (order == undefined || order == null || order == 'rate') ? '&order=rate' : '&order=sum'

            let leadFilter = (lead == undefined || lead == null || lead == 'Leader teams') ? 
            '&leader=team' : '&leader=employee'

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/business/teams?page=' + page_id + orderFilter + leadFilter, {
                    method: "GET",
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}