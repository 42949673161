<template>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true}">
            <div class="flex mb-2">
                <img src="../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/staff')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">Employee registration</h2>     
                <!-- <h2 class="main-title-dash">{{estaData.name}}</h2>      -->
            </div>
            <div v-if="false"><p class="error-input-text err-au" v-if="(establishment.length == 0 || teams.length == 0) && old == true" style="text-align: left; 
                margin-bottom: 0px; 
                margin-top: 0;">Before creating a employee, you need to create an establishment and a team!</p></div>
                <p class="green-text mt-1" v-if="$route.query?.ok == 'ok'">Invitation sent!</p>

                <div><p class="error-input-text err-au" v-if="(error != '')" style="text-align: left; 
                margin-bottom: 0px; 
                margin-top: 10px;" v-for="err in error">{{err}}</p></div>

            <div id="learning-employee-registration" class="flex mt-2 flex-content-mob mt-1-mobile">

                <div class="reg-staff add-e-s-i">
                    <my-input 
                        style="width: 320px" 
                        label="First Name" 
                        v-model="dataForm.firstName"
                        @keydown="codeKeyDown"
                    />
                </div>

                <div class="reg-staff ml-2 add-e-s-i">
                    <my-input 
                        style="width: 320px" 
                        label="Last Name" 
                        v-model="dataForm.lastName"
                        @keydown="codeKeyDown"
                    />
                </div>
                
                <div class="flex  add-e-s-i se-s-i" style="z-index: 6;" >
                    <select-input-new v-if="establishment.length > 0" width="320px" style="min-height: 62px;" label="Establishment" @getvalue="setEstablishment" :inps="establishment"/>
                    <my-input 
                        v-else
                        style="width: 320px" 
                        label="Title (position)" 
                        v-model="dataForm.job"
                        @keydown="codeKeyDown"
                    />
                </div>

            </div>
            <div class="flex mt-2 flex-content-mob mt-1-mobile">
                <div class="">
                    <my-input 
                        label="Employee's email" 
                        style="width:320px" 
                        v-model="dataForm.email"
                        @keydown="codeKeyDown"
                    />
                    <p class="error-input-text err-au" v-if="siActiveEmailErr">This email already exists</p>
                </div>
                <div class="reg-staff num-s-i">
                <vue-tel-input wrapperClasses="rg" 
                    inputClasses="rtb" class="rg" 
                    ref="inp" 
                    style="border: none;z-index: 3; position: relative; height: 47px;outline: none; box-shadow: none; background-color: #252525 !important;width: 320px"  
                    v-model="dataForm.phone" :style="{'height': '62px'}" 
                    validCharactersOnly="true" 
                    @validate="phoneObject" 
                    @country-changed="setCountryCode"
                    :enabledCountryCode="true"
                    @input="foo"
                    :onlyCountries="['us']"
                    defaultCountry="us"
                    @keydown="codeKeyDown"
                    :enabledFlags="false"
                    mode="international"> 
                    <template v-slot:arrow-icon>
                    </template>
                    </vue-tel-input>
                    <p class="error-input-text err-au" v-if="isAvtivePhonErr">This phone already exists</p> 
                </div>
                <div class="flex se-s-i mt-1-mobile" v-if="teams.length > 0" style=" z-index: 3; position: relative">
                    <select-input-new width="320px" style="min-height: 62px;" label="Team" @getvalue="setTeam" :inps="teams" />
                </div>   
            </div>
            <div class="flex mt-2 flex-content-mob mt-1-mobile" v-if="establishment.length > 0">
                <div class="">
                    <my-input 
                        style="width: 320px" 
                        label="Title (position)" 
                        v-model="dataForm.job"
                        @keydown="codeKeyDown"
                    />
                </div>
            </div>
            <div class="warning-message mt-3 w-320-mob">
                <img src="../../assets/img/UI/warning.svg">
                <p class="ml-1">A message will be sent to the employee with an invitation code, which will need to be specified in order to gain access to registration</p>
            </div>
            <div class="btn-registr-empl m-x-auto mt-4"><my-button label="Registration" :active="isActiveBtn" @click="sendData"/></div>
            <h2 class="main-title-dash pointer mt-6">Those who have not completed registration</h2>
            <div class="flex mt-3 flex-content-mob">
                <div class="flex">
                    <img src="../../assets/img/UI/loop.svg" class="loop-img">
                    <div><input type="text" style="width: 320px !important" class="search-input" placeholder="Search" v-model="search"/></div>
                </div>
                <!-- <div class="flex ml-0-mobile mt-2-mobile">
                    <select-input width="320px"  label="Status" @getvalue="" :inps="['1', '2', '3']"/>
                </div> -->
                <div class="flex ml-0-mobile mt-1-mobile" style="margin-left: auto;">
                    <select-input width="320px" style="min-height: 62px;" label="Establishment" @getvalue="setEstablishmentUnactive" :inps="[{id: 0, name: 'All'},...establishment]"/>
                </div>
            </div>
            <staff-user
            v-if="search == ''"  
            v-for="member in unactiveUsers" 
            :img="member.avatar" 
            class="team-esta-el pointer" 
            :name="member.name + ' ' + member.last_name" 
            :job="member.job" 
            setting="true" 
            @delete="deleteUser" 
            :id="member.id" 
            style="margin-left: auto; margin-right: auto;"/>
            <staff-user
            v-if="search != ''"  
            v-for="member in searchInput" 
            :img="member.avatar" 
            class="team-esta-el pointer" 
            :name="member.name + ' ' + member.last_name" 
            :job="member.job" 
            setting="true" 
            @delete="deleteUser" 
            :id="member.id" 
            style="margin-left: auto; margin-right: auto;"/>
            <div class="no-found-employ" v-if="unactiveUsers.length == 0">
                <img src="../../assets/img/UI/no_found_users.svg">
                <p class="no-title">no employees found</p>
                <p class="no-text">Try changing the search parameters</p>
            </div>
        </div>
    </div>
</template>

<script>
import registrAdminMixin from '@/mixins/registrAdminMixin'
import sendEmployeeRegistrMixin from '@/mixins/sendEmployeeRegistrMixin'
import configMixin from '@/mixins/configMixin'

import TgInstance from '@/components/instance/tourguide';

    export default {
        name: 'employee registration',
        mixins: [registrAdminMixin, sendEmployeeRegistrMixin, configMixin],
        data() {
            return {
                isActiveBtn: false,
                dataForm: {
                    establishment: '',
                    email: '',
                    team: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    isValid: false,
                    countryCode: '',
                    job: ''
                },
                teams: [],
                establishment: [],
                unactiveUsers: [],
                searchInput: [],
                countryCode: '',
                siActiveEmailErr: false,
                isAvtivePhonErr: false,
                index: 0,
                search: '',
                old: false,
                error: ''
            }
        },
        watch: {
            search() {
                if(this.search == '') this.searchInput = []
                else this.searchUser()
            },
            index() {
                if(this.index == 1) {
                    this.isAvtivePhonErr = false
                    this.index++
                }
            },
            'dataForm.establishment': function() {
                this.isActive()
            },
            'dataForm.email': function() {
                this.checkEmail()
            },
            'dataForm.team': function() {
                this.isActive()
            },
            'dataForm.firstName': function() {
                this.isActive()
            },
            'dataForm.lastName': function() {
                this.isActive()
            },
            'dataForm.job': function() {
                this.isActive()
            },
            'dataForm.phone': function() {
                if(this.dataForm.phone.length < this.countryCode.length) {
                    this.dataForm.phone = this.countryCode
                }
                this.checkPhone()
            },
            countryCode() {
                this.dataForm.phone = this.countryCode
            }
        },
        methods: {
            searchUser() {
                this.unactiveUsers.forEach(el => {
                    if(el?.name?.toLowerCase()?.includes(this.search.toLowerCase()) == true || 
                    el?.last_name?.toLowerCase()?.includes(this.search.toLowerCase()) == true ||
                    el?.username?.toLowerCase()?.includes(this.search.toLowerCase()) == true) {
                       
                        if(this.searchInput.find(item => item.id == el.id) == undefined) this.searchInput.push(el)
                    } else {
                       
                        this.searchInput.splice(this.searchInput.findIndex(item => item.id == el.id), 1)
                    }
                })
            },
            setCountryCode(val) {
                this.countryCode = "+" + val.dialCode
            },
            phoneObject(object) {
                this.isValid = object.valid;
            },
            checkPhone() {
                this.checkCEOPhone(this.dataForm.phone).then(data => {
                    if(data?.ok === true) {
                        this.isAvtivePhonErr = false
                    } else if(data?.ok === false) {
                        this.isAvtivePhonErr = true
                    }
                    this.index++
                    this.isActive()
                })
            },
            checkEmail() {
                this.checkAdminEmail(this.dataForm.email).then(data => {
                    if(data?.ok === true) {
                        this.siActiveEmailErr = false
                    } else if(data?.ok === false) {
                        this.siActiveEmailErr = true
                    }
                    this.isActive()
                })
            },
            isActive() {
                const validateEmail = (email) => {
                return String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
                };
                if(this.dataForm.email != "" && 
                this.dataForm.firstName != "" &&
                this.dataForm.lastName != "" &&
                this.dataForm.job != "" &&
                this.dataForm.phone != "" && validateEmail(this.dataForm.email) && 
                !this.siActiveEmailErr && 
                !this.isAvtivePhonErr &&
                this.isValid) this.isActiveBtn = true
                else this.isActiveBtn = false
            },
            setEstablishment(value, id) {
                this.dataForm.establishment = {name: value, id: id}
            },
            setTeam(value, id) {
                this.dataForm.team = {name: value, id: id}
            },
            sendData() {
                if(this.isActiveBtn) {
                    this.getDataEmployee(this.dataForm.firstName, this.dataForm.lastName, this.dataForm?.establishment?.id, this.dataForm.email, this.dataForm.phone.split(" ").join(""), this.dataForm?.team?.id, this.dataForm.job).then(data => {
                        if(data?.ok == true) {
                            window.location.href = document.location.pathname + "?ok=ok";
                        } else {
                            if(data?.length > 0) {
                                let err_arr = []

                                err_arr = data.map(el => {
                                    console.log(el)
                                    if(el?.input != undefined) {
                                        
                                        el.input = el.input[0].toUpperCase() + el.input.slice(1);
                                        return el.error.replace('String', el.input)
                                    } else {
                                        return el.error
                                    }
                                })

                                this.error = err_arr
                            } else {
                                this.err_arr = ['An error occurred, please try later']
                            }
                            
                        }
                    })
                }
            },
            getUnactiveUser(establishment_id) {
                let est = (establishment_id == null || establishment_id == undefined || establishment_id == 0) ? '' : '?establishment_id=' + establishment_id

                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/team/unactive' + est, {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }
                return response()
            },
            setEstablishmentUnactive(name, id) {
                this.getUnactiveUser(id).then(data => {
                    this.unactiveUsers = data.users
                })
            },
            setDel(id) {
                
            },
            deleteUser(id) {
               
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/unactive/delete/' + id, {
                        method: "DELETE",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }
                response().then(data => {
                    this.$router.go()
                })
            },
            codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.isActiveBtn) {
                        this.sendData();
                    }
                }
            }
        },
        mounted() {
            this.getUnactiveUser().then(data => {
                this.unactiveUsers = data.users
            })
            
            this.getDataTeamAndEsta().then(data => {
                this.teams = data.teams
                this.establishment = data.establishments
                this.old = true
                this.dataForm.establishment = data.establishments[0]
                this.dataForm.team = data.teams[0]
            })
        },
        async created() {
            const tgInstance = TgInstance.instance;
            if (tgInstance) {
                console.log ('TgInstance.instance is set');

                const groupName = tgInstance.groupName;
                if (!tgInstance.tg.isFinished(groupName))
                    tgInstance.tg.visitStep(8);
            }
        }
    }
</script>

<style scoped>
.reg-staff {
    width :320px
}
</style>