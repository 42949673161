import axios from 'axios'
import configMixin from './configMixin'

export default {
    mixins: [configMixin],
    methods: {
        sendDataApi(email) {
        
        let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)
       
        let jsonObject = {
            type: 'email',
            object: email,
            _csrf: csrf
        }

         let response = fetch(this.config.API_URL + '/code', {
            headers: {
                "Content-Type": "application/json"
            },
            method: 'POST',
            body: JSON.stringify(jsonObject)
         }).then(data => data.json()).
         then(data => {
            if(data.ok) return true
            return false
         }).catch(err => {
            console.log(err)
            return false
         })
         return response
        },
        getDataApi(email, code) {
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)
            let jsonObject = {
                type: 'email',
                object: email,
                _csrf: csrf,
                code: code
            }
            
            let func = async () => {
                let response = await fetch(this.config.API_URL + '/code/' + email, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: 'POST',
                    body: JSON.stringify(jsonObject)
                 })

                 let codes = await response.json()
                 return Promise.resolve(codes)
            }
            return func()
        },
        sendDataPhone(phone) {
        
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)
           
            let jsonObject = {
                type: 'phone',
                object: phone,
                _csrf: csrf
            }
    
             let response = fetch(this.config.API_URL + '/code', {
                headers: {
                    "Content-Type": "application/json"
                },
                method: 'POST',
                body: JSON.stringify(jsonObject)
             }).then(data => data.json()).
             then(data => {
                if(data.ok) return true
                return false
             }).catch(err => {
                console.log(err)
                return false
             })
             return response
        },
        getDataPhone(phone, code) {
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)
            let jsonObject = {
                type: 'phone',
                object: phone.split(" ").join(""),
                _csrf: csrf,
                code: code
            }
            
            let func = async () => {
                let response = await fetch(this.config.API_URL + '/code/' + phone.split(" ").join(''), {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: 'POST',
                    body: JSON.stringify(jsonObject)
                 })
    
                 let codes = await response.json()
                 return Promise.resolve(codes)
            }
            return func()
        }
    },
    
}