<template>
    <div class="outer-container">
        <div class="middle-container">
            <div class="header-container">
                <h1 class="logo-client-start m-x-auto">Merci<span class="h orange-color q-logo">Q</span></h1>
                <h2 class="mt-1">Choose the type of account</h2>
            </div>
            <div class="flex type-container-block mt-3">
                <div class="type-block flex flex-column" @click="setBusinessAccountType">
                    <div class="type-block-business-img"></div>
                    <h3 class="mt-2">Business profile</h3>
                    <p class="mt-2 mb-3">Suitable for businesses to manage employees and branches. Invite your team, track tips, and control employee accounts</p>
                    <custom-button label="Choose" @click="setBusinessAccountType" :active="true" class="mt-4 choose-button"/>
                </div>
                <div class="type-block flex flex-column" @click="setPersonalAccountType">
                    <div class="type-block-personal-img"></div>
                    <h3 class="mt-2">Personal profile</h3>
                    <p class="mt-2 mb-3">Suitable for individual specialists such as waiters, drivers, tutors, etc. Easy start with light interface</p>
                    <custom-button label="Choose" @click="setPersonalAccountType" :active="true" class="mt-4 choose-button"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'account type',
        methods: {
            setBusinessAccountType() {
                localStorage.setItem('userData.accountType', 'business');
                this.$router.push("/fillingbusiness");
            },
            setPersonalAccountType() {
                localStorage.setItem('userData.accountType', 'personal');
                this.$router.push("/employee/personal");
            },
        },
        beforeMount() {
            if(localStorage.getItem('userData.email') == undefined || 
                localStorage.getItem('userData.password') == undefined) 
                    return this.$router.push('/signupforms')
        }
    }
</script>

<style scoped>
    .outer-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .middle-container {
        text-align: center;
    }

    .type-container-block {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }

    .type-block {
        background-color: #171717;
        width: 500px;
        height: auto;
        border-radius: 10px;
        cursor: pointer;
        padding: 20px;
        align-items: center;
    }
    .type-block h3 {
        font-family: 'Rubik-SemiBold';
        text-transform: uppercase;
    }

    .type-block p {
        font-size: 18px;
        font-family: 'Rubik-Light';        
        color: #FFFFFF;
    }

    .choose-button {
        margin-top: auto;
        text-transform: uppercase;
    }
    
    .type-block-business-img {
        background-size: 300px;
        background-image: url('../assets/img/UI/img_business.png');
        width: 300px; 
        height: 300px;
    }

    .type-block-personal-img {
        background-size: 300px;
        background-image: url('../assets/img/UI/img_personal.png');
        width: 300px;
        height: 300px;
    }

    @media screen and (max-width: 500px) {

        .type-container-block {
            text-align: left;
            gap: 18px;
        }
        
        .type-block {
            background-color: #1E1E1E;
            width: 320px;
            height: auto;
        }

        .type-block h3 {
            margin-right: auto;
        }

        .type-block-business-img {
            background-size: 48px;
            background-image: url('../assets/img/UI/business_profile_icon_mobile.svg');
            width: 48px; 
            height: 48px;
            margin-right: auto;
        }
        .type-block-personal-img {
            background-size: 48px;
            background-image: url('../assets/img/UI/personal_profile_icon_mobile.svg');
            width: 48px; 
            height: 48px;
            margin-right: auto;
        }
    }

</style>