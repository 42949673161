<template>
        <terms @ok="send" v-if="isActiveTerms"/>
 <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form inner-modal-div-form-dop fil-bus">
                <div class="wrapper-inner-modal" :class='{"calendar-active-cont": isActiveTerms}' style="overflow-x: hidden"> 
                <div class="HeadStroke_father">
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke grey"></div>
                    <div class="HeadStroke grey"></div>
                    <div class="HeadStroke grey"></div>
                    <div class="HeadStroke grey"></div>
                </div>
                    <h3 class="mt-4">TYPE OF YOUR BUSINESS</h3>
                <div class="RadioBusiness mt-4">
                    
                    <div class="RadioBusiness_child">
                        <div class="form_radio_b">
                            <input id="radio-1" type="radio" name="radio" value="Self-Employed"  @click="checks" checked>
                            <label for="radio-1"></label>
                        </div>
                        <label class="ml-1" for=""> Self-Employed / Individual</label>
                    </div>
                    <div class="RadioBusiness_child mt-2">
                        <div class="form_radio_b">
                            <input id="radio-2" type="radio" name="radio" value="LLC"  @click="checks2">
                            <label for="radio-2"></label>
                        </div>
                        <label class="ml-1" for=""> LLC</label>
                    </div>
                    <div class="RadioBusiness_child mt-2">
                        <div class="form_radio_b">
                            <input id="radio-3" type="radio" name="radio" value="C-corp"  @click="checks3">
                            <label for="radio-3"></label>
                        </div>
                        <label class="ml-1" for=""> C-corp</label>
                    </div>
                    <div class="RadioBusiness_child mt-2">
                        <div class="form_radio_b">
                            <input id="radio-4" type="radio" name="radio" value="Partnership"  @click="checks4">
                            <label for="radio-4"></label>
                        </div>
                        <label class="ml-1" for=""> Partnership</label>
                    </div>
                    <div class="RadioBusiness_child mt-2">
                        <div class="form_radio_b">
                            <input id="radio-5" type="radio" name="radio" value="Other"  @click="checks5">
                            <label for="radio-5"></label>
                        </div>
                        <label class="ml-1" for="">Other</label>
                    </div>
                </div>
                <div class="flex flex-column" style="margin-left: auto; align-items: left;">
                    <div class="flex mt-2" v-if="formData.checks != 'Self-Employed'">
                        <custom-input 
                            type="text" 
                            style="width: 320px;" 
                            label="Name of the Organisation" 
                            @getvalue="NameOrganisation" 
                            class="mt-2" 
                            ref="nameOrganisationInput"
                            @blur="nameOrganisationValidate"
                        />                        
                    </div>
                    <p class="error-input-text err-au" v-if="formData.checks != 'Self-Employed' && organisationNameError?.length > 0">{{ organisationNameError }}</p>
                    <div class="flex mt-2" v-if="formData.checks == 'Self-Employed'">
                        <custom-input 
                            type="text" 
                            label="First name" 
                            style="width: 320px" 
                            v-model="formData.firstname" 
                            class="mt-2" 
                            ref="firstnameInput"
                            @blur="firstnameValidate"
                        />
                    </div>
                    <p class="error-input-text err-au" v-if="formData.checks == 'Self-Employed' && firstnameError?.length > 0">{{ firstnameError }}</p>
                    <div class="flex" v-if="formData.checks == 'Self-Employed'">
                        <custom-input 
                            type="text" 
                            label="Last name" 
                            style="width: 320px" 
                            v-model="formData.lastname" 
                            class="mt-2" 
                            ref="lastnameInput"
                            @blur="lastnameValidate"
                        />
                    </div>
                    <p class="error-input-text err-au" v-if="formData.checks == 'Self-Employed' && lastnameError?.length > 0">{{ lastnameError }}</p>
                    
                    <div style="height: 0; "></div>

                    <!-- <div class="flex mt-2" style="overflow-y: auto; overflow-x: hidden; margin-top: 100px; margin-bottom: 59px; position: absolute; text-align: left; z-index: 10">
                        <select-input width="320px" :inps="country" label="Country" @getvalue="Country" style=" max-height: 200px; min-height: 59px"/>
                    </div>
                     -->
                    <div class="flex mt-2" style="overflow-y: auto; overflow-x: hidden;text-align: left; text-align: left; z-index: 9;height: 62px;">
                        <select-input-search 
                            width="320px" 
                            :inps="state" 
                            label="State" 
                            @getvalue="State" 
                            style=" 
                            max-height: 200px; min-height: 59px"
                        />
                    </div>
                    <!-- <div class="flex mt-2"><my-input label="State" style="width: 320px" v-model="formData.State"/></div> -->
                    <div class="flex mt-2" style="overflow-y: auto; overflow-x: hidden;text-align: left; z-index: 8;height: 62px;">
                        <select-input-search 
                            width="320px" 
                            :inps="city" 
                            label="City" 
                            @getvalue="City" 
                        />
                    </div>
                    <div class="flex mt-2" style="overflow-y: auto; overflow-x: hidden;text-align: left; z-index: 7;height: 62px;">
                        <select-input-search 
                            width="320px" 
                            :inps="businessType" 
                            label="Business category" 
                            @getvalue="MCC" 
                        />
                    </div>
                    <!-- <div class="flex mt-2"><my-input style="width: 320px" label="City" v-model="formData.City"/></div> -->
                    <div class="flex mt-2">
                        <custom-input 
                            label="Address" 
                            style="width: 320px;" 
                            v-model="formData.Adress"
                            ref="addressInput"
                            @blur="addressValidate"
                        />
                    </div>
                    <p class="error-input-text err-au" v-if="addressError?.length > 0">{{ addressError }}</p>
                    <custom-input 
                        type="number" 
                        style="width: 320px" 
                        label="Zip-code" 
                        @getvalue="ZipCode" 
                        class="mt-2" 
                        maxlength="6"
                        ref="zipCodeInput"
                        @blur="zipCodeValidate"
                    />
                    <p class="error-input-text err-au" v-if="zipCodeError?.length > 0">{{ zipCodeError }}</p>
                    <custom-input 
                        type="number" 
                        style="width: 320px" 
                        v-if="formData.checks != 'Self-Employed' && formData.checks != 'Other'" 
                        label="Tax id" 
                        v-model="formData.taxId" 
                        class="mt-2" 
                        maxlength="9"
                        ref="taxIdInput"
                        @blur="taxIdValidate"
                    />
                    <p class="error-input-text err-au" v-if="formData.checks != 'Self-Employed' && formData.checks != 'Other' && taxIdError?.length > 0">{{ taxIdError }}</p>
                    
                    <div class="flex ssn-container mt-3">
                        <p class="ssn-fill-label">Fill up 4 last digits of SSN</p>
                        <img src="../assets/img/UI/ssl_secured_icon.png" class="ssl-secured" style="width: 100px;">
                        <!--
                        <ssn-input 
                            type="text" 
                            style="width: 300px; align-self: center;" 
                            label="Social Security Number" 
                            @input="e => {/*formatedSSN(e)*/}" 
                            v-model="formData.social_number" 
                            class="mt-2" 
                            maxlength="11"
                            @keydown="ssnKeyDown"
                            @select="ssnPreventSelect"
                            ref="ssnInputRef"
                            @blur="ssnValidate"
                        />
                        -->
                        <!--
                        <ssn-input 
                            type="text" 
                            style="width: 300px; align-self: center;" 
                            label="Social Security Number" 
                            @input="ssnInput" 
                            v-model="formData.social_number" 
                            class="mt-2" 
                            maxlength="4"
                            @keydown="ssnKeyDown"
                            @select="ssnPreventSelect"
                            ref="ssnInputRef"
                            @blur="ssnValidate"
                        />
                        -->
                        <ssn-input 
                            type="text" 
                            style="width: 300px; align-self: center;" 
                            label="Social Security Number" 
                            @input="ssnInput" 
                            v-model="formData.social_number" 
                            class="mt-2" 
                            maxlength="4"
                            @select="ssnPreventSelect"
                            ref="ssnInputRef"
                            @blur="ssnValidate"
                        />
                        <div>
                            <img 
                                :src="ssnHidden 
                                    ? require('@/assets/img/UI/show.png') 
                                    : require('@/assets/img/UI/show-no.png')" 
                                class="btn-show"  
                                @click="showSsn"
                            >
                        </div>
                        <p class="error-input-text err-au" v-if="ssnError?.length > 0">{{ ssnError }}</p>
                        <div style="display:flex; align-items: center; margin-top: 5px;">
                            <img src="../assets/img/UI/guaranteed_safe_and_secure.png" style="width: 60%;">
                            <img src="../assets/img/UI/powered_by_stripe.jpg" style="width: 38%;cursor: pointer;" @click="goToStripe">
                        </div>
                        <p class="field-provided-info">
                            This field is provided by <a href="https://stripe.com/" class="orange-color">www.stripe.com</a>; MerciQ App doesn't access this info.<br>
                            Your data is protected with 256-bit SSL encryption. View our <a href="/docs/Privacy Policy.pdf" class="orange-color">Privacy Policy</a>.
                        </p>
                        <div class="warning-message mt-2 w-300" 
                            :style="{
                                'align-self': 'center', 
                                'margin-bottom': !isActiveSSN ? '10px' : '0',
                                'border-bottom-right-radius': !isActiveSSN ? '10px' : '0',
                                'border-bottom-left-radius': !isActiveSSN ? '10px' : '0'                                
                                }">
                            <img src="../assets/img/UI/warning.svg">
                            <p class="ml-1" style="text-align: left; font-size: 14px">Why are we asking you to provide SSN?</p>
                            <img src="../assets/img/UI/right-strel.svg" class="pointer" 
                            :style="{transform: !isActiveSSN ? 'rotate(90deg)' : 'rotate(-90deg)', 'margin-left': '2px'}" 
                            @click="setActiveSSN">
                        </div>
                        <div class="warning-message w-300" 
                            style="align-self: center; margin-bottom:10px;border-top-left-radius:0;border-top-right-radius:0;" v-if="isActiveSSN">
                            <img style="visibility: hidden;" src="../assets/img/UI/warning.svg">
                            <p class="ml-1" style="text-align: left; font-size: 14px">By law, Stripe (our payment provider) must ask for your SSN to make sure you
    who you say you are. Your SSN is for identity verification ONLY; Stripe is federally required to officially know
    someone connected to the account.
    Your information is secure, and MerciQ doesn't access or store it. Stripe
    doesn't run credit checks, so inputting
    your SSN for this purpose won't affect
    your credit score. MerciQ has no access to your banking data, so we don't store it and your privacy is tightly controlled. Stripe keeps all the information you enter in Stripe safe with bank-level security measures.</p>
                        </div>
                    </div>
                    
                    <p class="mt-2 center">
                        <a class="orange-color" style="font-size: 15px;" href="/docs/reg guide.pdf">
                            NEED HELP WITH THE FORM?<br>CLICK HERE FOR THE GUIDE
                        </a>
                    </p>
                    <my-button label="Next" style="width: 320px" :active="true" @click="setActiveTerm" class="mt-2"/>
                </div> 
                </div>      
            </div>
        </div>
    </div>
    
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import configMixin from '@/mixins/configMixin'
import mccMixin from '@/mixins/mccMixin'

export default {
    name: 'FillingBusiness',
    mixins: [configMixin, mccMixin],
    data() {
       return {
        isActive: false,
        formData: {
            NameOrganisation: '',
            Country: 'United States',
            Adress: '',
            City: '',
            State: '',
            ZipCode: '',
            social_number: '',
            checks: 'Self-Employed',
            taxId: '',
            mcc: '',
            firstname: '',
            lastname: ''
        },
        businessType: [],
        isActiveSSN: false,
        isActiveTerms: false,
        country: [],
        state: [],
        city: [],
        token: '',
        ssnDigits: [],
        ssnHidden: true,
        organisationNameError: '',
        firstnameError: '',
        lastnameError: '',
        addressError: '',
        zipCodeError: '',
        taxIdError: '',
        ssnError: ''
       }
    },
    watch: {
        'formData.taxId': function() {
            this.sendData()
        },
        'formData.State': async function() {
            
            await fetch('https://www.universal-tutorial.com/api/cities/' + this.formData.State, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + this.token,
                }
            }).then(data => data.json()).then(data => {
                this.formData.City = data[0].city_name
                this.city = data.map(el => el.city_name)
            }).catch(err => {})
        },
        'formData.firstname': function() {
            this.formData.NameOrganisation = this.formData.firstname + ' ' + this.formData.lastname;
            this.sendData();
        },
        'formData.lastname': function() {
            this.formData.NameOrganisation = this.formData.firstname + ' ' + this.formData.lastname;
            this.sendData();
        }
    },
    methods: {

        formatedSSN(e) {
            var val = e.target.value.replace(/\D/g, '');
            val = val.replace(/^(\d{3})/, '$1-');
            val = val.replace(/-(\d{2})/, '-$1-');
            val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
            e.target.value = val;
            this.sendData()
        },
        ssnInput(event) {

            const numKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

            let selectionStart = event.target.selectionStart;
//            alert(selectionStart);
            const lastChar = event.target.value.charAt(selectionStart - 1);
//            alert(lastChar);
        
            if (event.target.value.length < this.ssnDigits.length) {
                this.ssnDigits.splice(selectionStart, 1);
            } else if (numKeys.includes(lastChar)) {
                
                this.ssnDigits.splice(selectionStart - 1, 0, lastChar);

                if (this.ssnHidden) {
                    setTimeout(() => {
                        event.target.value = event.target.value.replace(/\d/g, "*");
                    }, 500);
                }
            } else if (event.target.value.length < 4) {
                
                const val = event.target.value.split("");

                val.splice(selectionStart - 1, 1);
                event.target.value = val.join("");
            }

//            const val = event.target.value.replace(/\D/g, '');
//            event.target.value = val;

//            alert(this.ssnDigits);

            if (this.ssnDigits.length === 4)
                this.sendData();

        },
        setActiveSSN() {
            this.isActiveSSN = !this.isActiveSSN
        },
        setActiveTerm() {

            if (this.formData.checks != 'Self-Employed') {
                this.nameOrganisationValidate();
            } else {
                this.firstnameValidate();
                this.lastnameValidate();
            }

            this.addressValidate();
            this.zipCodeValidate();

            if (this.formData.checks != 'Self-Employed' && this.formData.checks != 'Other')
                this.taxIdValidate();

            this.ssnValidate();
                
            if(this.isActive) 
                this.isActiveTerms = !this.isActiveTerms
        },
        socialNumber(value) {
            this.formData.social_number = value
            this.sendData()
        },
    
        NameOrganisation(value) {
            this.formData.NameOrganisation = value
            this.sendData()
        },
        Country(value) {
            if(value != undefined)this.formData.Country = value
            this.sendData()
        },
        Adress(value) {
            this.formData.Adress = value
            this.sendData()
        },
        MCC(value) {
            if (value != undefined) {
                this.formData.mcc = this.businessType.find(item => item.name == value).value
                this.sendData()
            }
        },
        City(value) {
            this.formData.City = value
            this.sendData()
        },
        State(value) {
            this.formData.State = value
            this.sendData()
        },
        ZipCode(value) {
            this.formData.ZipCode = value
            this.sendData()
        },

        checks(value) {
            this.formData.checks = "Self-Employed"
            this.sendData()
        },
        checks2() {
            this.formData.checks = "LLC"
            this.sendData()
        },
        checks3() {
            this.formData.checks = "C-corp"
            this.sendData()
        },
        checks4() {
            this.formData.checks = "Partnership"
            this.sendData()
        },
        checks5() {
            this.formData.checks = "Other"
            this.sendData()
        },
        /*
        ssnKeyDown(event) {
            const numKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
            const navKeys = [
                "Backspace",
                "Delete",
                "Shift",
                "ArrowUp",
                "ArrowDown",
                "ArrowLeft",
                "ArrowRight",
                "Tab"
            ];
            let selectionStart = event.target.selectionStart;            
            const eventKey = event.key;
            console.log('selectionStart', selectionStart);
            console.log('eventKey', eventKey);
            // If not NumKeys or NavKeys prevent event
            if (!numKeys.includes(eventKey) && !navKeys.includes(eventKey)) {
                event.preventDefault();
                return false;
            }
            if (event.key === "Backspace" || event.key === "Delete") {
                selectionStart = selectionStart - 1 <= 0 ? 0 : selectionStart - 1;
                console.log('Backspace selectionStart', selectionStart);
                if (selectionStart == 4 || selectionStart == 7) {
                    let value = event.target.value;
                    value = value.substring(0, value.length - 1);
                    event.target.value = value;
                } 
                if (selectionStart >= 7 ) {   
                    this.ssnDigits.splice(selectionStart - 2, 1);        
                } else if (selectionStart >= 4 ) {
                    this.ssnDigits.splice(selectionStart - 1, 1);
                } else  {
                    this.ssnDigits.splice(selectionStart, 1);
                }
            } else if (numKeys.includes(eventKey)) {
                if (selectionStart > 10) {
                    return false;
                }
                
                if (selectionStart >= 8) {
                    this.ssnDigits.splice(selectionStart - 2, 0, eventKey);
                } else if (selectionStart >= 5) {
                    this.ssnDigits.splice(selectionStart - 1, 0, eventKey);
                } else {
                    // insert new key at specific index
                    this.ssnDigits.splice(selectionStart, 0, eventKey);
                }

                if (selectionStart == 3 || selectionStart == 6) {
                    event.target.value += '-';
                }
                // 
                if (this.ssnHidden) {
                    setTimeout(() => {
                        event.target.value = event.target.value.replace(/\d/g, "*");
                    }, 500);
                }
            }
            console.log('event.target.value', event.target.value);
            console.log('ssnDigits', this.ssnDigits);
            
            if (this.ssnDigits.length === 9)
                this.sendData();

        },
        */
        ssnKeyDown(event) {
                const numKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
                const navKeys = [
                    "Backspace",
                    "Delete",
                    "Shift",
                    "ArrowUp",
                    "ArrowDown",
                    "ArrowLeft",
                    "ArrowRight",
                    "Tab"
                ];
                let selectionStart = event.target.selectionStart;            
                const eventKey = event.key;
                console.log('selectionStart', selectionStart);
                console.log('eventKey', eventKey);
                // If not NumKeys or NavKeys prevent event
                if (!numKeys.includes(eventKey) && !navKeys.includes(eventKey)) {
                    event.preventDefault();
                    return false;
                }
                if (event.key === "Backspace" || event.key === "Delete") {
                    selectionStart = selectionStart - 1 <= 0 ? 0 : selectionStart - 1;
                    console.log('Backspace selectionStart', selectionStart);
                    
                    this.ssnDigits.splice(selectionStart, 1);

                } else if (numKeys.includes(eventKey)) {
                    if (selectionStart >= 4) {
                        return false;
                    }
                    
                    this.ssnDigits.splice(selectionStart, 0, eventKey);

                    // 
                    if (this.ssnHidden) {
                        setTimeout(() => {
                            event.target.value = event.target.value.replace(/\d/g, "*");
                        }, 500);
                    }
                }
                console.log('event.target.value', event.target.value);
                console.log('ssnDigits', this.ssnDigits);
                
                if (this.ssnDigits.length === 4)
                    this.sendData();

        },
        ssnPreventSelect(event) {
            event.target.selectionStart = event.target.selectionEnd;
        },
        showSsn() {
            if (this.ssnHidden) {
                let val = this.ssnDigits.join("");
            
//                val = val.replace(/^(\d{3})/, '$1-');
//                val = val.replace(/-(\d{2})/, '-$1-');
//                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');

                this.$refs.ssnInputRef.value = val;

                this.ssnHidden = false;
            } else {
                this.$refs.ssnInputRef.value = this.$refs.ssnInputRef.value.replace(/\d/g, "*");

                this.ssnHidden = true;
            }
//            this.formData.social_number = this.ssnDigits.join("");
        },
        nameOrganisationValidate() {
            this.validateTextInput('nameOrganisationInput', 'NameOrganisation', 'organisationNameError', 'Please enter your organisation name');
        }, 
        firstnameValidate() {
            this.validateTextInput('firstnameInput', 'firstname', 'firstnameError', 'Please enter your firstname');
        },
        lastnameValidate() {
            this.validateTextInput('lastnameInput', 'lastname', 'lastnameError', 'Please enter your lastname');
        },
        addressValidate() {
            this.validateTextInput('addressInput', 'Adress', 'addressError', 'Please enter your address');
        },
        zipCodeValidate() {
            this.validateZipCodeInput('zipCodeInput', 'ZipCode', 'zipCodeError', 'Please enter your zip-code');
        },
        taxIdValidate() {
            this.validateTextInput('taxIdInput', 'taxId', 'taxIdError', 'Please enter your tax id');
        },
        ssnValidate() {
            this.validateSsnInput('ssnInputRef', 'social_number', 'ssnError', 'Please enter your social security number');
        },        
        validateTextInput(refKey, formDataKey, errorKey, errorText) {
            const inputValue = this.formData[formDataKey];
            
            const validationResult = this.validateText(inputValue);
            
            if (validationResult) {
                this[errorKey] = '';
                this.$refs[refKey].setError(false);
            } else {
                this[errorKey] = errorText;
                this.$refs[refKey].setError(true);
            }
        },
        validateZipCodeInput(refKey, formDataKey, errorKey, errorText) {
            const inputValue = this.formData[formDataKey];
            
            const validationResult = this.validateText(inputValue, 4);
            
            if (validationResult) {
                this[errorKey] = '';
                this.$refs[refKey].setError(false);
            } else {
                this[errorKey] = errorText;
                this.$refs[refKey].setError(true);
            }
        },
        validateSsnInput(refKey, formDataKey, errorKey, errorText) {
            const inputValue = this.formData[formDataKey];
            
            const validationResult = this.validateSsn();
            
            if (validationResult) {
                this[errorKey] = '';
                this.$refs[refKey].setError(false);
            } else {
                this[errorKey] = errorText;
                this.$refs[refKey].setError(true);
            }
        },
        validateText(value, minLength = 0) {
            return value.length > minLength;
        },        
        validateSsn() {
            return this.ssnDigits.length == 4
        },
        goToStripe() {
            window.location.href = "https://stripe.com/";
        },
        sendData() {
            
            console.log('senddata');
            if(
                this.formData.NameOrganisation != "" && 
 
                this.formData.Adress != "" && 
                this.formData.City != "" && 
                this.formData.State != "" && 
                this.formData.ZipCode != "" && 
                this.formData.checks != "" &&
//                this.formData.social_number != '' &&
                this.formData.ZipCode.length > 4 &&
                this.ssnDigits.length == 4
//                this.formData.social_number.split("-").join("").trim().length == 9
                
                ) this.isActive = true
            else this.isActive = false
            if(this.isActive) {
                let ct = (this.formData.Country == 'undefined' || this.formData.Country == undefined ) ? 'United States' : this.formData.Country
                localStorage.setItem('establishment.typeBusiness', this.formData.checks)
                localStorage.setItem('establishment.nameOrganization', this.formData.NameOrganisation)
                localStorage.setItem('adress.country', this.formData.Country)
                localStorage.setItem('adress.adress', this.formData.Adress)
                localStorage.setItem('adress.city', this.formData.City)
                localStorage.setItem('adress.state', this.formData.State)
                localStorage.setItem('docs.ssn', this.ssnDigits.join(""))
//                localStorage.setItem('docs.ssn', this.formData.social_number.split("-").join(""))
                localStorage.setItem('docs.taxId', this.formData.taxId)
                localStorage.setItem('docs.mcc', this.formData.mcc)
                localStorage.setItem('establishment.zipCode', this.formData.ZipCode)

                if (this.formData.checks == 'Self-Employed') {                
                    localStorage.setItem('CEO.name', this.formData.firstname)
                    localStorage.setItem('CEO.lastname', this.formData.lastname)
                }
            }
        },
        send() {
            if(this.isActive) {
                this.$router.push('/FillingBusiness2');
            }
        },
    },
    async beforeMount() {

        if(localStorage.getItem('code-start') == undefined ||
        (localStorage.getItem('userData.email') == undefined && localStorage.getItem('userData.phone') == undefined) || 
        localStorage.getItem('userData.password') == undefined || 
        localStorage.getItem('userData.username') == undefined) return this.$router.push('/signupforms')

        let query = await fetch('https://www.universal-tutorial.com/api/getaccesstoken', {
            method: "GET",
            headers: {
                "Accept": "application/json",
                'api-token': "-g27lPGpwWOzarFcylW620SzveZ7k25ziZdoiO8jvAQ4pIDtb6_LmCZ7Dpxj1ywZrF0",
                'user-email': "qwerty@gmail.ru"
            }
        })

        let json = await query.json()

        this.token = json['auth_token']

        this.state = ["Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware","District of Columbia","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Louisiana","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Montana","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina","North Dakota","Ohio","Oklahoma","Ontario","Oregon","Pennsylvania","Ramey","Rhode Island","South Carolina","South Dakota","Sublimity","Tennessee","Texas","Trimble","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"]
        this.formData.State = this.state[0]
        
        // fetch('https://www.universal-tutorial.com/api/states/United States', {
        //         method: "GET",
        //         headers: {
        //             "Accept": "application/json",
        //             "Authorization": "Bearer " + this.token,
        //         }
        // }).then(data => data.json()).then(data => {

        //     this.formData.State = data[0].state_name
        //     this.state = data.map(el => el.state_name)
        //     console.log(JSON.stringify(this.state))
        // })

            this.city = ["Alabaster","Albertville","Alexander City","Anniston","Arab","Ashville","Athens","Atmore","Auburn","Bessemer","Birmingham","Capshaw","Center Point","Childersburg","Cullman","Daleville","Daphne","Decatur","Dothan","Enterprise","Eufaula","Fairfield","Fairhope","Florence","Fort Payne","Gadsden","Grand Bay","Grove Hill","Guntersville","Hampton Cove","Hanceville","Hartselle","Headland","Helena","Hodges","Homewood","Hoover","Hueytown","Huntsville","Jacksonville","Jasper","Leeds","Luverne","Madison","Mobile","Montgomery","Mountain Brook","Muscle Shoals","Northport","Notasulga","Opelika","Oxford","Ozark","Pelham","Pell City","Pennsylvania","Phenix City","Prattville","Prichard","Ramer","Roanoke","Saraland","Scottsboro","Selma","Sheffield","Smiths","Sumiton","Sylacauga","Talladega","Thomasville","Trafford","Troy","Trussville","Tuscaloosa","Tuskegee","Vestavia Hills"]
            this.formData.City = this.city[0]
            // fetch('https://www.universal-tutorial.com/api/cities/Alabama', {
            //     method: "GET",
            //     headers: {
            //         "Accept": "application/json",
            //         "Authorization": "Bearer " + this.token,
            //     }
            // }).then(data => data.json()).then(data => {
            //     this.formData.City = data[0].city_name
            //     this.city = data.map(el => el.city_name)
            //     console.log(JSON.stringify(this.city))
            // })
        
        
            this.businessType = this.mcc
            this.formData.mcc = this.businessType[0].value
            
    }
}
</script>

<style scoped>
    .ssn-container {
        border: 2px solid #282828;
        border-radius: 20px;
        position: relative;
        width: 320px;
        display: flex;
        flex-direction: column;
    }
    .ssl-secured {
        position: absolute;
        width: 100px;
        right: 0;
        top: -16px;
        background: #171717;
    }
    .ssn-fill-label {
        color: white;
        position: absolute;
        left: 10px;
        top: -11px;
        background: #171717;
        padding: 0 3px;
    }
    .field-provided-info {
        font-size: 8px;
        text-align: left;
        font-family: 'Rubik-Medium';
        color: #ABABAB;
        padding-left: 10px;
        margin-top: 5px;
    }

    .btn-show {
        margin-left: 120px;
    }
</style>