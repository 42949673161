<template>
    <div class="flex">
        <h2 class="main-title-dash pointer"><img @click="$router.go(-1)" src="../../assets/img/UI/left-strel.svg"> <span class="d-none-desc" @click="$router.push('/establishment/team/' + $route.params?.establishment_id)">{{ name }} ({{login}})</span><img src="../../assets/img/UI/set.svg" @click="redactdata" class="set-img d-none-desc"></h2>
    </div>
    <div class="p-28 w-320-mob">
        <div class="flex mt-3 d-none-desc">
            <img 
            v-if="avatar != undefined"
            :src="'/img/uploads/logo/esta/' + avatar" alt="" class="member-img">
            <img :src="qr_code" v-if="qr_code != undefined" class="qr-member pointer" @click="showQR">
        </div>
        <div class="d-none-mobile">
            <img 
            v-if="avatar != undefined"
            :src="'/img/uploads/logo/esta/' + avatar"
             alt="" class="member-img member-img-mobile">
            <div class="showQR" @click="showQR"><img src="../../assets/img/UI/showQR.svg" alt=""></div>
            <div>
                <span class="title-login-mobile">{{ login }}</span>
                <img src="../../assets/img/UI/set.svg" @click="redactdata" class="set-img">
            </div>
        </div>
        <p class="member-phone">{{ phone }}</p>
        <div class="flex centr-mob">
            <p class="desc-min-data-member">{{ establishment }}</p>
            <p class="desc-min-data-member ml-3 ser-mob">{{ team }}</p>
        </div>
        <button class="releas-code-member mt-2" @click="updateQR">
            <img src="../../assets/img/UI/red-close.svg">
            <span>release the code</span>
        </button>
    </div>
</template>

<script>
let QRCode = require('qrcode')
export default {
    name: 'member-info',
    data() {
        return {
            qr_code: ''
        }
    },
    props: {
        login: String,
        name: String,
        phone: String,
        team: String,
        establishment: String,
        qr: String,
        avatar: String
    },
    watch: {
        qr() {
            this.getQr()
        }
    },
    methods: {
        showQR() {
            this.$emit('showqr')
        },
        redactdata() {
            this.$emit('redact')
        },
        getQr() {
            QRCode.toDataURL(window.location.origin + '/client/employee/' + this.qr, (err, url) => {
                    this.qr_code = url
                })
        },
        updateQR() {
            this.$emit('updateQR')
        }
    },
    beforeMount() {
        this.getQr()
    }
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
    .w-320-mob {
        text-align: center;
    }
    .desc-min-data-member {
        margin-left: auto;
    }
    .ser-mob {
        margin-right: auto;
    }
    .member-img-mobile {
        margin-left: auto;
        margin-right: auto;
    }
    .main-title-dash {
        margin-top: 0;
    }
    .title-login-mobile {
        color: white;
        font-size: 24px;
        margin: 5px auto;
    }
    .set-img {
        margin-left: 10px;
        width: 13px;
    }
    .showQR {
        margin-top: -14px;
        cursor: pointer;
    }
}
</style>