<template>
<div class="outer-modal-div-form client-outer" style="display: flex; flex-direction: column; background-color: #171717">
    <div class="pay-wrapper"> 
            <success/> 
    </div>
</div>

</template>

<script>
    export default {
        name: "success client operation",
        beforeMount() {

            fetch('/api/transaction/success/' + this.$route.query.id, {
                method: "GET"
            }).then(data => data.json()).then(data => {
                console.log(data)
            })
        }
    }
</script>