<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <p class="login-h">Login history</p>
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
            <table class="mt-1">
                <tr сlass="tr-e">
                    <td class="left-s pl-0">Time</td>
                    <td class="left-s">Date</td>
                    <td class="left-s">IP</td>
                    <td class="left-s">Location</td>
                </tr>
                
                <tr сlass="tr-e" v-for="el in history">
                    <td class="left-s grey-td pl-0">{{ el.time }}</td>
                    <td class="left-s grey-td">{{el.createdAt.split('T')[0].split('-').join('.')}}</td>
                    <td class="left-s grey-td">{{el.ip}}</td>
                    <td class="left-s grey-td">{{el.country}}, {{ el.city }}</td>
                </tr>
            </table>
            <!-- <button class="border-grey mt-2 btnj-short" style="height: 48px; border: 1px solid #343434">Change pass</button>        -->
        </div>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'
import { promise } from 'bcrypt/promises'

    export default {
        name: 'login-history',
        mixins: [configMixin],
        data() {
          return {
            history: []
          }
       },
      
       methods: {
          
           getData() {
               if(this.isActive) this.$emit('getdata')
           },
           close() {
            this.$emit('close')
           }
       },
        mounted() {
            let response = async() => {

                let query = await fetch(this.config.API_URL + '/user/login', {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

                return response().then(data => {
                 this.history = data.data
                })
            }
        }
</script>

<style scoped> 
.close {
    margin-left: auto;
    
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
.close {
    position: absolute;
    margin-left: 55%;
    margin-top: -60px;
}
.inner-modal-div-form {
    background: #1E1E1E;
}
.inner-modal-div-form {
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #1E1E1E;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
        padding-top: 90px;
        top: 0;
        min-height: 100vh;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }

    .close {
    position: absolute;
    margin-left: 40%;
    margin-top: -20px;
}
.inner-modal-div-form {
    padding-top: 30px
}
}
</style>