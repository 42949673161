<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getCalendar" v-if="isActiveCalendar"/>
    <qr-code  
    @close="setActiveQr" 
    @update="refreshQr" 
    :qr="userInfo.qr_id"
    v-if="isActiveQr && userInfo?.qr_id != undefined"
    />
    <modal-edit-employee 
    v-if="isActiveEditUserPanel" 
    @close="setActivePanelAdd" 
    :email="userInfo.email" 
    :login="userInfo.login" 
    :name="userInfo.name" 
    :phone="userInfo.phone" 
    :establishment="establishments" 
    :team="teams"
    :id="userInfo.id"/>
    <modal-edit-manager 
    v-if="isActiveEditUserPanel && userInfo.job == 'Manager'" 
    @close="setActivePanelAdd" 
    :email="userInfo.email" 
    :login="userInfo.login" 
    :name="userInfo.name" 
    :phone="userInfo.phone" 
    :establishment="establishments" 
    :role="['Full access', 'Branch access']"
    :team="teams"
    :id="userInfo.id"/>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': (isActiveCalendar || isActiveQr || isActiveEditUserPanel), 'member-team': true}">
            <member-info 
            @showqr="setActiveQr" 
            @redact="setActivePanelAdd" 
            :login="userInfo.login"
            :name="userInfo.name" 
            :phone="userInfo.phone"
            :team="userInfo.team"
            :avatar="userInfo.avatar"
            :establishment="userInfo.establishment"
            :qr="userInfo.qr_id"
            @updateQR="refreshQr"/>
            <div class="main-nav-mobile d-none-mobile member-nav-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex">
                        <h2 :class="{ active_nav_panel: true}" @click="$router.push('/establishment/member/' + this.$route.params?.user_id)" >Transactions</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': false}" @click="$router.push('/establishment/member/statistic/' + this.$route.params?.user_id)">All аnalytics</h2>
                    </div>
                    <div class="main-line-menu">
                        <div class="menu-mobile-nav-line flex">
                            <div :class="{'fr-main-inp-active': true}" class="mobile-nav-est-1"></div>
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-2"></div>
                        </div>
                    </div>
                </div>

                <div class="flex p-28 d-none-desc" style="margin-top: 56px;">
                    <div class="first-staff-nav m-y-auto">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true, 'nav-member-er': true}" @click="$router.push('/establishment/member/' + this.$route.params?.user_id)">Transactions</p>
                    </div>
                    <div class="second-staff-nav m-y-auto">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false, 'nav-member-er': true}" @click="$router.push('/establishment/member/statistic/' + this.$route.params?.user_id)">All аnalytics</p>
                    </div> 
                    <div class="flex ml-auto">
                        <div class="min-panel-end flex panel-average">
                            <div class="panel-calendar-min ml-3" @click="setActiveCalendar" v-if="choiceDate == ''">
                                <img src="../../assets/img/UI/calendar-min-panel.svg">
                            </div>
                            <div class="panel-calendar-min ml-3" v-if="choiceDate != ''">
                                <div>
                                    <img src="../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delCalendar">
                                </div>
                            </div>
                            <div class="min-panel-date-filter flex ml-1">
                                <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0), getFinance('Day')">Day</div>
                                <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1), getFinance('Week')">Week</div>
                                <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2), getFinance('Month')">Month</div>
                                <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3), getFinance('Year')">Year</div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="border-staff flex m-28 d-none-desc" style="width: 730px; margin-top: 14px;">
                <div :class="{'active-first': true, 'active-nav-staff': true, 'trans-lines': true}"></div>
                <div :class="{'second-first': true, 'active-nav-staff': false, 'analit-line': true}"></div>
            </div>
            <div class="div-null-height-2 d-none-mobile" style="height: 50px"></div>
            <div class="flex ml-auto d-none-mobile">
                <div class="min-panel-end flex panel-average">
                    <div class="panel-calendar-min ml-3" @click="setActiveCalendar" v-if="choiceDate == ''">
                        <img src="../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    <div class="panel-calendar-min ml-3" v-if="choiceDate != ''">
                        <div>
                            <img src="../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delCalendar">
                        </div>
                    </div>
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0), getFinance('Day')">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1), getFinance('Week')">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2), getFinance('Month')">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3), getFinance('Year')">Year</div>
                    </div>
                </div>
            </div>
            <div class="user-transactions mt-3 us-t-r">
                    <history-transaction 
                    v-for="el in trans"
                    :id="zeroPad(el.id)" 
                    :time="el.time" 
                    :price="Number(el.sum).toFixed(2)" 
                    :stars="(el.stars == null) ? 0 : el.stars" 
                    :employee="el.User.name + ' ' + el.User.last_name" 
                    :desc="(el.comment == null) ? '' : el.comment" 
                    style="margin-right: -10%;" 
                    class="mt-2 trans-member wer-t" 
                    :width="width"
                    :type="el.type"/>
            </div>
            <paginate :count="Number(lastpage)" :link="'/establishment/member/' + $route.params?.user_id"/>
        </div>
    </div>
</template>

<script>
import dashboardMixin from '@/mixins/dashboardMixin';
import qrMixin from '@/mixins/qrMixin';
import staffMixin from '@/mixins/staffMixin';
import memberMixin from '@/mixins/memberMixin';
import dateIconMixin from '@/mixins/dateIconMixin';
import sendEmployeeRegistrMixin from '@/mixins/sendEmployeeRegistrMixin'

export default {
    name: 'team member',
    mixins: [dashboardMixin, qrMixin, staffMixin, memberMixin, dateIconMixin, sendEmployeeRegistrMixin],
    data() {
        return {
            userInfo: {
                login: 'Denis Petrov',
                phone: '+7 900 777 58 59',
                establishment: 'Establishment 1',
                team: 'Team 1',
                email: 'email',
                avatar: ''
            },
            lastpage: 0,
            trans: [],
            width: '730px',
            choiceDate: '',
            establishments: '',
            isActiveEditUserPanel: false,
            teams: '',
            date_f: null
        }
    },
    mounted() {
        if(window.innerWidth > 500) {
            this.width = '730px'
        } else {
            this.width = '320px'
        }
    },
    watch: {
        '$route.query.page': function(){

            this.getFinance(this.date_f)
        },
    },
    methods: {
        zeroPad(num) {
                return num.toString().padStart(7, "0");
        },
        setActivePanelAdd() {
            this.isActiveEditUserPanel = !this.isActiveEditUserPanel
        },
        delCalendar() {
            this.date_f = null
            this.getFinance(null)
            this.delDateIcon()
        },
        getCalendar(value) {
            this.date_f = value
            let date = (value == null || value == undefined) ? null : JSON.stringify({date: value})
            this.getFinance(value)
            this.getDateIcon(value)
        },
        getFinance(date) {
            let page = (this.$route.query?.page == null || 
            this.$route.query?.page == undefined ||
            isNaN(Number(this.$route.query?.page))) ? 1 : this.$route.query?.page

            let dateFilter = (date == null || date == undefined) ? '' : '&date=' + date
            if(date != "Day" && date != 'Week' && date != 'Month' && date != 'Year' && date != null && date != undefined) {
                dateFilter = (date == null || date == undefined) ? '' : '&date=' + JSON.stringify({date: date})
            }

            let resonse = async() => {
                let query = await fetch(this.config.API_URL + '/transaction/personal/' + this.$route.params?.user_id + '?page=' + page + dateFilter, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }
            return resonse().then(data => {
                this.lastpage = Math.ceil(data.count / 10)
                this.trans = data.rows
            })
        },
        refreshQr() {
            let response = async() => {

                let query = await fetch(this.config.API_URL + '/user/qr/refresh/'  + this.$route.params?.establishment_id, {
                    method: "PUT",
                    headers: {
                        "Authorization": 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                if(query.status != 200)  this.$router.push('/dashboard')

                let result = await query.json()

                if(result?.id == undefined 
                || result?.id == null) this.$router.push('/dashboard')

                return Promise.resolve(result) 
            }
            response().then(data => {
                this.userInfo.qr_id = data.qr
            })
        }
    },
    beforeMount() {
        if(this.$route.params?.user_id == null || 
        this.$route.params?.user_id == 'undefined' ||
        this.$route.params?.user_id == undefined ||
        isNaN(Number(this.$route.params?.user_id))) return this.$router.push('/dashboard')

        let esta_id = 0
        let team_id = 0
        
        this.getMemeber().then(data => {
            try {
                data = data.user
                this.userInfo.login = data.username
                this.userInfo.avatar = data.avatar
                this.userInfo.phone = data.phone
                this.userInfo.name = data.name + ' ' + data.last_name
                this.userInfo.qr_id = data.qr_id
                this.userInfo.avatar = data.avatar
                this.userInfo.email = data.email
                this.userInfo.id = data.id
                this.userInfo.job = data.job
                this.userInfo.team = (data.team == null) ? '' : data.team

                esta_id = data?.Establishment?.id
                team_id = data?.Team?.id

                this.userInfo.team = (data.Team == null) ? '' : data.Team.name
                this.userInfo.establishment = (data.Establishment == null) ? '' : data.Establishment.name
               
            } catch(err) {
                this.$router.push('/dashboard')
            }
        }).catch(err => {
            console.log(err)
            this.$router.push('/dashboard')
        })

        this.getFinance(null)

        this.getDataTeamAndEsta().then(data => {
            if(team_id != undefined && team_id != 0 && esta_id != null) {
                let start_teams = data.teams[0]

                let need = data.teams[data.teams.findIndex(item => item.id == team_id)]
                let i = data.teams.findIndex(item => item.id == team_id)

                this.teams = data.teams
                this.teams[0] = need

                this.teams[i] = start_teams
            } else {
                this.teams = data.teams
            }

            if(esta_id != undefined && esta_id != 0 && esta_id != null) {
                let start_esta = data.establishments[0]

                let need = data.establishments[data.establishments.findIndex(item => item.id == esta_id)]
                let i = data.establishments.findIndex(item => item.id == esta_id)
                console.log(data.establishments)
                this.establishments = data.establishments
               
                this.establishments[0] = need

                this.establishments[i] = start_esta
            } else {
                this.establishments = data.establishments
            }
        })
    }

}
</script>