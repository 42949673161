<template>
 <div style="position: absolute;
    top: 50%;
    transform: translateY(-50%); text-align: center; left: 0; right: 0;">
    <h1 class="logo-client-start m-x-auto">Merci<span class="h orange-color q-logo">Q</span></h1>
        <h2 class="mt-1">Select profile</h2>
        <div class="flex sign-block mt-3">
            <div class="sign-block-child" @click="$router.push('/')">
                <img src="/1487716857-user_81635.svg" style="width: 100px;">
                <h3>Send tip</h3>
                <p>Send tips with MerciQ</p>
            </div>
            <div class="sign-block-child dn-1" @click="$router.push('/signupshort')">
                <img src="/1487716860-users_81631.svg" style="width: 100px;">
                <h3>Staff</h3>
                <p>Manage and analyze your tips</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'input type'
    }
</script>