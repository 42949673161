import configMixin from "./configMixin";

export default {
    methods: {
        getDataNewEmployee(id, email) {

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/employee/invitation?id=' + id + '&email=' + email, {
                    method: 'GET'
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}