<template>
 <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
                    <h3 class="email-title">SMS<br> CONFIRMATION</h3>
                    <p class="number-desc mt-2">code was sent to the number</p>
                    <p class="orange-color">{{ phone }}</p>
                    <div class="flex el-sms-div">
                        <div class="el-sms-num">
                                <input 
                                    type="number"  
                                    class="mt-4 inputText" 
                                    ref="v1" @click="setTest" 
                                    @input=" e => {setCode(e.target.value, 0)}" 
                                    @paste="codePaste"
                                    max="1"
                                />
                            </div>
                            <div class="el-sms-num">
                                <input 
                                    type="number" 
                                    class="mt-4 inputText" 
                                    ref="v2" 
                                    id="v2" 
                                    @input=" e => {setCode(e.target.value, 1)}" 
                                    @paste="codePaste"
                                    @keydown="e => codeKeyPress(e, 1)"
                                    max="1"
                                />
                            </div>
                            <div class="el-sms-num">
                                <input 
                                    type="number" 
                                    class="mt-4 inputText" 
                                    ref="v3" 
                                    @input=" e => {setCode(e.target.value, 2)}" 
                                    @paste="codePaste"
                                    @keydown="e => codeKeyPress(e, 2)"
                                    max="1"
                                />
                            </div>
                            <div class="el-sms-num">
                                <input 
                                    type="number" 
                                    class="mt-4 inputText" 
                                    ref="v4" 
                                    @input=" e => {setCode(e.target.value, 3)}" 
                                    @paste="codePaste"
                                    @keydown="e => codeKeyPress(e, 3)"
                                    max="1"
                                />
                            </div>
                       </div>
                    <p class="error-input-text err-au" v-if="isError">{{ error }}</p>
                    <div class="flex flex-center mt-3"><p class="orange-color pointer" @click="sendAgain" v-if="timer.minute == 0 && timer.seconds == 0">Resend</p><p class="other-text-color" v-if="timer.minute != 0 || timer.seconds != 0">Resend after</p><p class="main-text-color ml-1" v-if="timer.minute != 0 || timer.seconds != 0">{{ timer.minute }}:{{ (String(timer.seconds).length < 2) ? "0" + timer.seconds : timer.seconds}}</p></div>
                    <my-button label="Registration" @click="sendData" :active="isActive" class="mt-4"/>
                    <div class="flex mt-3">
                        <div class="form_radio">
                            <input id="radio-1" type="radio" @click="setIsCheck" name="radio" value="1"  v-model="isCheck" checked>
                            <label for="radio-1"></label>
                        </div>
                        <p class="radio-text main-text-color">Allow MerciQ to send me important system notifications and marketing messages.</p>
                    </div>
                    <span class="flex mt-6 flex-center">
                        <p class="main-text-color">No phone access?</p>
                        <RouterLink to="/signupshort"><p class="orange-color ml-2">Change</p></RouterLink>
                    </span>
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import sendCodeMixin from '@/mixins/sendCodeMixin'

export default {
    name: 'sms confirmation',
    mixins: [sendCodeMixin],
    data() {
        return {
            timer: {minute: 1, seconds: 30},
            isResend: false,
            isActive: false,
            isCheck: false,
            code: ['', '', '', ''],
            test: false,
            phone: localStorage.getItem('userData.phone')
        }
    },
    methods: {
        setTest() {
            this.test = !this.test
        },
        sendData() {
            if(this.isActive) {
                const codeSend = this.$refs['v1'].value + this.$refs['v2'].value + this.$refs['v3'].value + this.$refs['v4'].value;
                let res = this.getDataPhone(this.phone.split(" ").join(""), codeSend, this.error)
                .then(data => {
                    console.log(data)
                    if(data?.ok == true) {
                        localStorage.removeItem('google_id')
                        localStorage.removeItem('facebook_id')
                        localStorage.setItem('code-start', codeSend)
                        this.$router.push("/username")
                    } else {
                        this.isError = true
                        this.error = data.error
                    }
                })
               }
        },
        setCode(value, num) {

            console.log('setCode', value);

            if (value[String(value).length - 1] === undefined)
                return;

            this.$refs['v' + String(num + 1)].value = value[String(value).length - 1]

            console.log('this.code',this.code);

                if(value != '' && value != ' ') {
                    if(this.code[num] != "") {
                        if(value == "") this.code[num] = ''
                    } else {
                        this.code[num] = value
                    }
                    this.isActive = !this.code.includes("")
                    if(!this.code.includes("") ) this.isActive = true
                    else this.isActive = false

                    if(this.code[2].length != 0) {
                        this.$refs.v4.focus()
                    }else if(this.code[1].length != 0) {

                        this.$refs.v3.focus()
                    } else if(this.code[0].length != 0) {
                        this.$refs.v2.focus()
                    } 
                } else {
                    const setType = (num) => {
                        console.log('setType', num)
                        this.$refs['v' + num].focus()
                        this.$refs['v' + num].setAttribute('type', 'text')
                        console.log(this.$refs['v' + num].getAttribute('type'))
                        this.$refs['v' + num ].selectionStart =  this.$refs['v' + num].value.length
                    this.$refs['v' + num ].setAttribute('type', 'number')
                    
                }

                    this.code[num] = ''
                    if(num == 1) {
                        setType(1)
                    
                    } else if(num == 2) {
                        setType(2)
                        
                    } else if(num == 3) {
                        setType(3)
                        
                    }
                }

                console.log('this.code',this.code);

            },
            codeKeyPress(event, num) {
                // backspace pressed
                if (event.keyCode === 8) {
                    console.log('codeKeyPress num', num);
                    const fieldValue = this.$refs['v' + String(num + 1)].value;
                    console.log('fieldValue', fieldValue);
                    if (fieldValue.length === 0) {
                        this.$refs['v' + String(num)].focus();
                        this.code[num - 1] = '';
                    }
                }
            },
            codePaste(event) {
                console.log('on paste', event.clipboardData.getData('text'));

                const data = event.clipboardData.getData('text')
                const matched = String(data).match(/^\d+$/)
                if (matched) {
                    this.$refs.v1.value = data[0];
                    this.code[0] = data[0];
                    this.$refs.v2.value = data[1];
                    this.code[1] = data[1];
                    this.$refs.v3.value = data[2];
                    this.code[2] = data[2];
                    this.$refs.v4.value = data[3];
                    this.code[3] = data[3];
                    
                    this.$refs.v4.focus();

                    this.isActive = true;
                }

                event.preventDefault();
            },
        setIsCheck() {
            if(this.isCheck == '1') this.isCheck = ''
        },
        sendAgain() {
            this.timer.minute = 1
            this.timer.seconds = 30
            let timerId = setInterval(() => {
               this.timer.seconds--
               if(this.timer.seconds == -1 && this.timer.minute == 1) {
                   this.timer.minute = 0 
                   this.timer.seconds = 59
               }
               if(this.timer.seconds == 0 && this.timer.minute == 0) clearInterval(timerId)
           }, 1000)
            this.sendDataPhone(this.phone.split(" ").join(""))
           }

    },
    mounted() {
        let timerId = setInterval(() => {
            this.timer.seconds--
            if(this.timer.seconds == -1 && this.timer.minute == 1) {
                this.timer.minute = 0 
                this.timer.seconds = 59
            }
            if(this.timer.seconds == 0 && this.timer.minute == 0) clearInterval(timerId)
        }, 1000)
    },
    computed: {
        ...mapState({
            dataUser: state => state.code.data
        }),
        ...mapGetters({
            getData: 'code/getData'
        }),
    }
}
</script>

<style scoped>
.inputText{
	width: 100%;
	outline: none;
	background-color: #1E1E1E;
  font-size: 15px;
  color: #FFFFFF;
  padding-left: 10px;
  border: none;
  height: 62px;
	border-radius: 10px;
 	box-shadow: none !important;
}

</style>