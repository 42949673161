<template>
    <div class="cale" style="width: 320px; text-align: center">
        <h1 style="color: white; text-align: center;">Success!</h1>
        <p style="color: var(--orange-color); text-align: center; width: 100%; margin-top: 10px; font-size: 15px">Your payment has been processed</p>
        <p style="color: white; text-align: center; width: 100%; margin-top: 20px">Enter your e-mail in the box below to get a receipt: </p>
        <my-input bgColor="#252525" class="mt-2" v-model="email" label="username@example.com"/>
        <my-button label="Done" active="true" class="mt-2" @click="done" />
    </div>
</template>
    
<script>
    export default {
        name: 'success',
        data() {
            return {
                email: ""
            }
        },
        methods: {
            async done() {
                if (this.email.trim() != "") {
                    await fetch('/api/transaction/success/email', {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            email: this.email,
                            id: this.$route.query.id
                        })
                    })
                }
                this.$router.push('/')
            }
        },
        mounted() {
            console.log(this.$route.query.id)
        }
    }
</script>
    
<style scoped>
.btn-delte-est {
    width: 219px;
}
.btn-sum {
    width: 219px;
    border: 1px solid #424242;
    border-radius: 200px;
    background: none;
    color: white;
    font-size: 16px;
    font-family: 'Rubik-Medium';

}

.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
}
.cale {
    position: unset;
    top: 200px;
    left: calc(60% - 408px);
    z-index: 3;
    opacity: 1;
    width: 540px;
    padding: 40px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .cale {
        position: absolute;
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .cale p {
        width: 290px;
        text-align: center;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .column-r-s {
        flex-direction: column;
    }
    .btn-sum {
        height: 48px;
        width: 271px;
        margin-top: 20px;
    }
    .btn-delte-est {
        width: 271px;
    }
}
</style>