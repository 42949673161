<template>
    <qr-code 
    v-if="isActiveQRcode" 
    @close="setActiveQR" 
    :personal="dataUser.username"
    :qr="dataUser.qr_id"
    @update="updateQr"/>
    <one-transaction 
        v-if="isActiveHistory"
        @close="setActiveHistory"
        :id="showData.id"
        :time="showData.time"
        :amount="showData.price"
        :desc="showData.desc"
        :date="showData.createdAt"
        :stars="showData.stars"
        style="position: fixed"
    />
    <div class="container-dash" style="height: 100%">
        <employee-dashboard @getavatar="getAvatar"/>
        <div class="wrapper-dash main-mirciq workspace-wrapper-das" 
        style="height: 100%; position: relative;"
        :class="{'calendar-active-cont': isActiveQRcode}">
            <div class="workspace-wrapper">
                <div id="learning-header" class="d-none-mobile height-work-mobile">
                    <my-button active="true" @click="setActiveQR">QR-code</my-button>
                    <div class="profile-panel-height pointer" @click="$router.push('/employee/profile')">
                        <img :src="'/img/uploads/logo/esta/' + avatar" v-if="avatar != ''" class="logo-p">
                        <div class="data-panel-p">
                            <p>{{ dataUser.name }}</p>
                            <div class="star-p-h">
                                <img src="../../../assets/img/UI/start.svg">
                                <span>{{dataUser.stars}}</span>
                            </div>
                        </div>
                        <img src="../../../assets/img/UI/setting-grey.svg" class="setting-panel-height">
                    </div>
                </div>
                
                <h2 class="main-title-dash d-none-desc">Workspace</h2>
                <div class="banner-verif mt-1" v-if="dataUser.stripeStatus != 1">
                    <p class="date-empl-w" style="color: #F28E22">Your account is under verification</p>
                </div>
                <div id="learning-balance" class="main-b-balance" style="background-color: var(--main-bg-color);" >
                    <p>Balance</p>
                    <h1>${{Number(dataUser.balance).toFixed(2)}}</h1>
                    <div class="b-button" style="width:auto">
                    <button class="btn-boder-grey" @click="$router.push('/employee/finance')">Finance history</button>
                    </div>
                    <div class="b-button" style="width:auto">
                    <button class="btn-boder-orange" @click="$router.push('/employee/payout')">Withdraw</button>
                    </div>
                </div>
                <div class="flex mt-1">
                    <p class="date-empl-w">Last Week</p>
                    <p class="total-price-empl">Total: ${{dataUser.lastWeekSum}}</p>
                </div>
                <div class="histoery-work" v-for="el in transaction">
                    <history-transaction-empl 
                        @transaction-show="setActiveHistory" 
                        :id="zeroPad(el.id)" 
                        :time="el.time" 
                        :date="el.createdAt.split('-')[1] + '-' + el.createdAt.split('-')[2].split('T')[0]"
                        :createdAt="el.createdAt"
                        :desc="(el.comment == null) ? '' : el.comment"
                        :stars="(el.stars == null) ? 0 : Number(el.stars)" 
                        :price="el.sum"
                        :type="el.type"
                        :additionalInfo="el.additional_info"
                    />
                </div>
                <div class="center mt-3"><button class="border-active-btn" @click="$router.push('/employee/tips')">All tips</button></div>
            </div>
            <div class="workspace-wrapper mt-2" > 
                <div class="flex flex-between pointer" @click="$router.push('/employee/statistic')"><h2 class="main-title-dash">Analytics</h2><img src="../../../assets/img/UI/border-strel.svg" style="margin-top: auto; margin-bottom: auto;"></div>
                <div id="learning-earnings" class="flex mt-2 column-mobile">
                    <div class="panel-balance-el pointer w-330 w-320-mob" @click="$router.push('/employee/finance')">
                            <p>Total earnings</p>
                            <p class="balance-el-t">${{dataUser.total}}</p>
                            <p class="balance-el-t-c">{{dataUser.count}} transactions</p>
                    </div>
                    <div class="flex mobile-panel-per">
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/employee/average')">
                            <div class="pie " :style="{'--p': 100}">{{ dataUser.stars }}</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ dataUser.stars }}</span></div>
                            <p class="mt-2">Average rating</p>
                        </div>
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/employee/reviews')">
                            <div class="pie " style="--p:100">{{line_average}}%</div>
                            <div class="pie pie-two" :style="{'--p':line_average}"><span>{{line_average}}%</span></div>
                            <p class="mt-2">Happy clients</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-between mt-2"><h2 class="main-title-dash pointer" @click="$router.push('/employee/achievements')">Achievements</h2> <img src="../../../assets/img/UI/border-strel.svg" class="mt-2 pointer" @click="$router.push('/employee/achievements')" style="margin-top: auto; margin-bottom: auto;"></div>
                <article class="flex flex-between mt-2" style="flex-wrap: wrap;">
                    <div class="div-achiev mt-2"  v-for="el in achieved">
                            <div class="flex flex-start">
                                <div class="div-achi-child">
                                    <img v-if="el.img != null && el.img != undefined" :src="'/img/achievements/' + el.img">
                                    <h3>{{el.title}}</h3>
                                    <p>{{el.text}}</p>
                                </div>
                                <div class="div-achi-ball">+50</div>
                            </div>
                        </div>
                </article>
                <div class="ert-h-nil"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';

    import configMixin from '@/mixins/configMixin';
    import ahievmentsMixin from '@/mixins/ahievmentsMixin'
    import refreshQr from '@/mixins/refreshQr'
    import loaderMixin from '@/mixins/loaderMixin';

    import { useRouter, useRoute } from 'vue-router';

    import { useStore } from 'vuex';

    import "@sjmc11/tourguidejs/src/scss/tour.scss" // Styles
    import { TourGuideClient } from "@sjmc11/tourguidejs/src/Tour" // JS
    
    import TgInstance from '@/components/instance/tourguide';


    export default {
        name: 'workspace main',
        mixins: [configMixin, ahievmentsMixin, refreshQr, loaderMixin],
        setup() {

            // new store reference
            const store = useStore();

            const username = ref('');

            const router = useRouter();
            const route = useRoute();
            // Now you can access params like:
            console.log('learning', route.query.learning);

            let learning = false;
            if (route.query.learning == 'true')
                learning = true;

            const isMobile = () => {
                if( screen.width <= 760 ) {
                    return true;
                }
                else {
                    return false;
                }
            }

            const tgInstance = isMobile() ? new TgInstance({
                autoScroll: false,
                keyboardControls: true,
                rememberStep: true,
                showButtons: false,
                showStepDots: false,
                showStepProgress: false,
                exitOnClickOutside: false,
                allowedPlacements: ['top', 'bottom']
            }) : new TgInstance({
                autoScroll: true,
                keyboardControls: true,
                rememberStep: true,
                showButtons: false,
                showStepDots: false,
                showStepProgress: false,
                exitOnClickOutside: false,
                allowedPlacements: ['top', 'right', 'bottom', 'left']
            });

//            const groupName = 'employee';
//            tgInstance.tg.group = groupName;

            tgInstance.tg.onFinish(()=>{
                console.warn('Tour finished')

                enableScroll();
            });

            tgInstance.tg.onAfterStepChange(()=>{
                console.warn('Tour step changed')
            });

            const setGroupName = (groupName) => {
                tgInstance.groupName = groupName;
            }

            const startTour = (groupName) => {
                tgInstance.tg.start(groupName);
            }

            const isTourFinished = (groupName) => {
                return tgInstance.tg.isFinished(groupName);
            }

            const nextStep = () => {

                tgInstance.tg.nextStep();
            }

            /*
            const nextStepWithHideModal = () => {
                const element = document.getElementById('tg-dialog-body');
                element.style.display = 'none';

                tgInstance.tg.nextStep();
            }
            */

            const finishTour = () => {
                console.log('username.value', username.value);

                const learningMenu = document.getElementById('learning-menu');
                
                if (isMobile() && learningMenu) {
                    learningMenu.style.display = 'flex';
                    learningMenu.style.height = '54px';
                }

                enableScroll();

                tgInstance.tg.finishTour(true, username.value);
                
//                tgInstance.tg.finishTour(true, groupName);
            }

            const assignLearningButton = () => {
                const nextStepButton = document.getElementById('learning-next-step');
                if (nextStepButton) {
                    nextStepButton.addEventListener('click', nextStep);
                }

                const learningSkipButton = document.getElementById('learning-skip');
                if (learningSkipButton) {
                    learningSkipButton.addEventListener('click', finishTour);
                }
            }

            /*
            const assignLearningButtonAndHideModal = () => {
                const nextStepButton = document.getElementById('learning-next-step');
                if (nextStepButton) {
                
                    nextStepButton.addEventListener('click', nextStepWithHideModal);
                }

                const learningSkipButton = document.getElementById('learning-skip');
                if (learningSkipButton) {
                    learningSkipButton.addEventListener('click', finishTour);
                }
            }
            */

            const goToInstruction = () => {
                if (isMobile()) {
                    window.location.href='/docs/Merciq_business_mob.pdf';
                } else {
                    window.location.href='/docs/MerciQ_GUIDE_BUSINESS_WEB.pdf';
                }
            }


            function preventDefault(e) {
                e.preventDefault();
            }

            // modern Chrome requires { passive: false } when adding event
            let supportsPassive = false;
            try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () { supportsPassive = true; } 
            }));
            } catch(e) {}

            let wheelOpt = supportsPassive ? { passive: false } : false;
            let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

            // call this to Disable
            function disableScroll() {
                window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
                window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
                window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
            }

            // call this to Enable
            function enableScroll() {
                window.removeEventListener('DOMMouseScroll', preventDefault, false);
                window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
                window.removeEventListener('touchmove', preventDefault, wheelOpt);
            }

            const addSteps = (groupName) => {

                if (isMobile()) {

                    tgInstance.tg.addSteps([
                        {
                            title: 'Welcome',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">
                                        <div class="flex flex-column justify-center align-center mb-2">
                                            <img src="${require('../../../assets/img/UI/learning_hat_icon.svg')}" class="pointer header-icon" />
                                            <h3 class="head-title">Welcome to<br>training mode</h3>
                                        </div>
                                        <p class="learning-title">
                                            Welcome to MerciQ. You are now in training mode. 
                                            If this is your first time here, click "Start Training" and we will guide you through the main features of our service.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>      
                                </div>
                            `,
                            order: 0, // Add to start
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

                                    disableScroll();

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise((resolve) => {

                                    const learningMenu = document.getElementById('learning-menu');
                                    learningMenu.style.display = 'none';

                                    const element = document.getElementById('learning-header');

                                    if (element) {                                
                                        const y = element.getBoundingClientRect().top + window.scrollY;
                                        window.scroll({
                                            top: y - 20,
                                            behavior: 'smooth'
                                        });
                                    }

                                    const image = new Image();
                                    image.src = `${require('../../../assets/img/UI/mascot_variant15.png')}`;

                                    return resolve(true);
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Dashboard',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant15.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 12,5%"></div>
                                            <p class="steps-title">Dashboard Overview</p>
                                            <p class="steps-numbers">1 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            The QR Code button gives you access to your QR. 
                                            Show it to clients with one touch. 
                                            Below, you can see profile info and rating based on customer reviews. 
                                            Tap the gear icon for profile settings.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: "#learning-header",
                            order: 1,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterLeave');

                                    /*
                                    window.scrollTo({
                                        top: document.body.scrollHeight,
                                        behavior: "smooth",
                                    });
                                    */
                                    
                                    const element = document.getElementById('learning-balance');
                                    
                                    const y = element.getBoundingClientRect().top + window.scrollY;
                                    window.scroll({
                                        top: y - 20,
                                        behavior: 'smooth'
                                    });

                                    const image = new Image();
                                    image.src = `${require('../../../assets/img/UI/mascot_variant2.png')}`;

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: "Balance",
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant2.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 25%"></div>
                                            <p class="steps-title">Balance</p>
                                            <p class="steps-numbers">2 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Balance shows your current amount. "Finance History" lets you view past transactions. 
                                            Click "Withdraw" to withdraw funds. "Last Week" shows the total tips for the week. 
                                            "All Tips" displays the full list of tips.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>  
                                </div>
                            `,
                            target: "#learning-balance",
                            order: 2,
                            afterEnter: () => {
                                return new Promise((resolve) => {

                                    const element = document.getElementById('tg-dialog-body');
//                                    element.style.display = 'block';

                                    element.style.position = 'relative';
                                    element.style.top = '-50px';

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise((resolve) => {
                                    console.log ('beforeLeave');
                                    
                                    const element = document.getElementById('learning-earnings');                                
                                    element.scrollIntoView(false);

                                    /*
                                    const y = element.getBoundingClientRect().bottom;
                                    window.scroll({
                                        bottom: y - 100,
                                        behavior: 'smooth'
                                    });
                                    */

                                    const image = new Image();
                                    image.src = `${require('../../../assets/img/UI/mascot_variant15.png')}`;

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: "Analytics",
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant15.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 37,5%"></div>
                                            <p class="steps-title">Analytics</p>
                                            <p class="steps-numbers">3 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Analytics includes useful metrics. 
                                            Click the title or arrow for detailed analytics with time filters and average tip value. 
                                            Click "Total Earnings" for transactions, "Average Rating" for rating details, and "Happy Clients" for client’s reviews.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>  
                                </div>
                            `,
                            target: "#learning-earnings",
                            order: 3,
                            afterEnter: () => {
                                return new Promise((resolve) => {

                                    const element = document.getElementById('tg-dialog-body');

                                    element.style.position = 'relative';
                                    element.style.top = '30px';

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise((resolve) => {
                                    console.log ('beforeLeave');
                                    

                                    const learningMenu = document.getElementById('learning-menu');
                                    learningMenu.style.height = '34px';
                                    learningMenu.style.display = 'flex';
                                    
                                    const element = document.getElementById('learning-menu');
                                    
                                    const y = element.getBoundingClientRect().top + window.scrollY;
                                    window.scroll({
                                        top: y,
                                        behavior: 'smooth'
                                    });                                    

                                    const image = new Image();
                                    image.src = `${require('../../../assets/img/UI/mascot_variant16.png')}`;

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Menu overview',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img variant16" src="${require('../../../assets/img/UI/mascot_variant16.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 50%"></div>
                                            <p class="steps-title">Menu overview</p>
                                            <p class="steps-numbers">4 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            In the web version, the menu is fixed on the left. 
                                            To open it in the mobile version, tap the menu icon in the app header. 
                                            The menu offers: Dashboard, Finance history, Statistic, Profile settings, QR-code, 
                                            Get your NFC card (order a scannable card), Verification (additional info may be required), and Log out button.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: "#learning-menu",
                            order: 4,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

                                    const nextStepButton = document.getElementById('learning-next-step');
                                    if (nextStepButton) {
                                        nextStepButton.addEventListener('click', () => {

                                            store.dispatch('setLoader', true);

//                                            tgInstance.tg.exit();

                                            const image = new Image();
                                            image.src = `${require('../../../assets/img/UI/mascot_variant2.png')}`;

                                            router.push('/employee/profile');
                                        });
                                    }

                                    const learningSkipButton = document.getElementById('learning-skip');
                                    if (learningSkipButton) {
                                        learningSkipButton.addEventListener('click', finishTour);
                                    }
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise(async (resolve) => {
                                    console.log ('afterLeave');

//                                    router.push('/employee/profile');

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Profile settings',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant2.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 62.5%"></div>
                                            <p class="steps-title">Profile settings</p>
                                            <p class="steps-numbers">5 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Here you can update profile info, provide payment information, get a personal QR code and link, 
                                            use the FAQ and help center, and contact technical support.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: '#learning-profile',
                            order: 5,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

//                                    assignLearningButton();

                                    const nextStepButton = document.getElementById('learning-next-step');
                                    if (nextStepButton) {
                                        nextStepButton.addEventListener('click', () => {

                                            store.dispatch('setLoader', true);
                                            
//                                            tgInstance.tg.exit();

                                            const image = new Image();
                                            image.src = `${require('../../../assets/img/UI/mascot_variant8.png')}`;

                                            router.push('/employee/payment');
                                        });
                                    }

                                    const learningSkipButton = document.getElementById('learning-skip');
                                    if (learningSkipButton) {
                                        learningSkipButton.addEventListener('click', finishTour);
                                    }
                                        
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise(async (resolve) => {
                                    console.log ('afterLeave');

//                                    router.push('/employee/payment');

                                    return resolve(true)
                                });                            
                            },
                            group: groupName
                        },
                        {
                            title: 'Update linked card',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant8.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 62.5%"></div>
                                            <p class="steps-title">Update linked card</p>
                                            <p class="steps-numbers">6 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Update linked card. To update the linked card, click "Update linked card." 
                                            Enter the new card number, expiration date, and CVC code. Click "Confirm" to save the changes.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: '#learning-update-payment-info',
                            order: 6,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

//                                    assignLearningButton();

                                    const nextStepButton = document.getElementById('learning-next-step');
                                    if (nextStepButton) {
                                        nextStepButton.addEventListener('click', () => {

                                            store.dispatch('setLoader', true);

//                                            tgInstance.tg.exit();

                                            const image = new Image();
                                            image.src = `${require('../../../assets/img/UI/mascot_variant1.png')}`;

                                            router.push('/employee/payout');
                                        });
                                    }

                                    const learningSkipButton = document.getElementById('learning-skip');
                                    if (learningSkipButton) {
                                        learningSkipButton.addEventListener('click', finishTour);
                                    }
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise(async (resolve) => {
                                    console.log ('afterLeave');

//                                    router.push('/employee/payout');

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Payout',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant1.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 75%"></div>
                                            <p class="steps-title">Payout</p>
                                            <p class="steps-numbers">7 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Enter the amount and click "Withdraw" or "Withdraw the entire amount." 
                                            You can also transfer funds to another MerciQ user by entering their username.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: '#learning-payout',
                            order: 7,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise(async (resolve) => {
                                    console.log ('afterLeave');

                                    router.push({name: 'workspace main', query: { learning: true }});

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Completion of training',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">
                                        <div class="flex flex-column justify-center align-center mb-2">
                                            <img src="${require('../../../assets/img/UI/learning_heart_icon.svg')}" class="pointer header-icon" />
                                            <h3 class="head-title">Thank you for<br>reading</h3>
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 100%"></div>
                                            <p class="steps-title">Completion of training</p>
                                            <p class="steps-numbers">8 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Thank you for completing the training! 
                                            You can now use MerciQ. 
                                            Congratulations on successfully finishing!
                                        </p>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Enjoy MerciQ benefits</slot></button>
                                        </div>
                                    </div>      
                                </div>
                            `,
                            order: 8,
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

                                    const learningSkipButton = document.getElementById('learning-skip');
                                    if (learningSkipButton) {
                                        learningSkipButton.addEventListener('click', finishTour);
                                    }
                                    
                                    return resolve(true);
                                });
                            },
                            group: groupName
                        },
                    ]);

                } else {

                    tgInstance.tg.addSteps([
                        {
                            title: 'Welcome',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">
                                        <div class="flex flex-column justify-center align-center mb-2">
                                            <img src="${require('../../../assets/img/UI/learning_hat_icon.svg')}" class="pointer header-icon" />
                                            <h3 class="head-title">Welcome to<br>training mode</h3>
                                        </div>
                                        <p class="learning-title">
                                            Welcome to MerciQ. You are now in training mode. 
                                            If this is your first time here, click "Start Training" and we will guide you through the main features of our service.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>      
                                </div>
                            `,
                            order: 0, // Add to start
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Dashboard',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant15.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 12,5%"></div>
                                            <p class="steps-title">Dashboard Overview</p>
                                            <p class="steps-numbers">1 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            The QR Code button gives you access to your QR. 
                                            Show it to clients with one touch. 
                                            Below, you can see profile info and rating based on customer reviews. 
                                            Tap the gear icon for profile settings.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: "#learning-user-dashboard",
                            order: 1,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterLeave');

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: "Balance",
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant2.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 25%"></div>
                                            <p class="steps-title">Balance</p>
                                            <p class="steps-numbers">2 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Balance shows your current amount. "Finance History" lets you view past transactions. 
                                            Click "Withdraw" to withdraw funds. "Last Week" shows the total tips for the week. 
                                            "All Tips" displays the full list of tips.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>  
                                </div>
                            `,
                            target: "#learning-balance",
                            order: 2,
                            afterEnter: () => {
                                return new Promise((resolve) => {

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            group: groupName
                        },
                        {
                            title: "Analytics",
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant15.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 37,5%"></div>
                                            <p class="steps-title">Analytics</p>
                                            <p class="steps-numbers">3 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Analytics includes useful metrics. 
                                            Click the title or arrow for detailed analytics with time filters and average tip value. 
                                            Click "Total Earnings" for transactions, "Average Rating" for rating details, and "Happy Clients" for client’s reviews.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>  
                                </div>
                            `,
                            target: "#learning-earnings",
                            order: 3,
                            afterEnter: () => {
                                return new Promise((resolve) => {

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise((resolve) => {
                                    console.log ('beforeLeave');
                                    
                                    /*
                                    const element = document.getElementById('learning-menu');
                                    
                                    const y = element.getBoundingClientRect().top + window.scrollY;
                                    window.scroll({
                                        top: y,
                                        behavior: 'smooth'
                                    });
                                    */

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Menu overview',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img variant16" src="${require('../../../assets/img/UI/mascot_variant16.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 50%"></div>
                                            <p class="steps-title">Menu overview</p>
                                            <p class="steps-numbers">4 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            In the web version, the menu is fixed on the left. 
                                            To open it in the mobile version, tap the menu icon in the app header. 
                                            The menu offers: Dashboard, Finance history, Statistic, Profile settings, QR-code, 
                                            Get your NFC card (order a scannable card), Verification (additional info may be required), and Log out button.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: "#learning-desktop-menu",
                            order: 4,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

//                                    assignLearningButton();

                                    const nextStepButton = document.getElementById('learning-next-step');
                                    if (nextStepButton) {
                                        nextStepButton.addEventListener('click', () => {

                                            store.dispatch('setLoader', true);

//                                            tgInstance.tg.exit();

                                            const image = new Image();
                                            image.src = `${require('../../../assets/img/UI/mascot_variant2.png')}`;

                                            router.push('/employee/profile');
                                        });
                                    }

                                    const learningSkipButton = document.getElementById('learning-skip');
                                    if (learningSkipButton) {
                                        learningSkipButton.addEventListener('click', finishTour);
                                    }
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise(async (resolve) => {
                                    console.log ('afterLeave');

//                                    router.push('/employee/profile');

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Profile settings',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant2.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 62.5%"></div>
                                            <p class="steps-title">Profile settings</p>
                                            <p class="steps-numbers">5 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Here you can update profile info, provide payment information, get a personal QR code and link, 
                                            use the FAQ and help center, and contact technical support.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: '#learning-profile',
                            order: 5,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

//                                    assignLearningButton();

                                    const nextStepButton = document.getElementById('learning-next-step');
                                    if (nextStepButton) {
                                        nextStepButton.addEventListener('click', () => {

                                            store.dispatch('setLoader', true);

//                                            tgInstance.tg.exit();

                                            const image = new Image();
                                            image.src = `${require('../../../assets/img/UI/mascot_variant8.png')}`;

                                            router.push('/employee/payment');
                                        });
                                    }

                                    const learningSkipButton = document.getElementById('learning-skip');
                                    if (learningSkipButton) {
                                        learningSkipButton.addEventListener('click', finishTour);
                                    }
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise(async (resolve) => {
                                    console.log ('afterLeave');

//                                    router.push('/employee/payment');

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Update linked card',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant8.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 62.5%"></div>
                                            <p class="steps-title">Update linked card</p>
                                            <p class="steps-numbers">6 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Update linked card. To update the linked card, click "Update linked card." 
                                            Enter the new card number, expiration date, and CVC code. Click "Confirm" to save the changes.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: '#learning-update-payment-info',
                            order: 6,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

//                                  assignLearningButton();

                                    const nextStepButton = document.getElementById('learning-next-step');
                                    if (nextStepButton) {
                                        nextStepButton.addEventListener('click', () => {

                                            store.dispatch('setLoader', true);

//                                            tgInstance.tg.exit();

                                            const image = new Image();
                                            image.src = `${require('../../../assets/img/UI/mascot_variant1.png')}`;

                                            router.push('/employee/payout');
                                        });
                                    }

                                    const learningSkipButton = document.getElementById('learning-skip');
                                    if (learningSkipButton) {
                                        learningSkipButton.addEventListener('click', finishTour);
                                    }
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise(async (resolve) => {
                                    console.log ('afterLeave');

//                                    router.push('/employee/payout');

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Payout',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">                            
                                        <div class="flex justify-end mascot-container">
                                            <img class="mascot-container-img" src="${require('../../../assets/img/UI/mascot_variant1.png')}" />
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 75%"></div>
                                            <p class="steps-title">Payout</p>
                                            <p class="steps-numbers">7 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Enter the amount and click "Withdraw" or "Withdraw the entire amount." 
                                            You can also transfer funds to another MerciQ user by entering their username.
                                        </p>
                                        <div id="learning-next-step" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Next step</slot></button>
                                        </div>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn not_active"><slot>Skip learning</slot></button>
                                        </div>
                                    </div>
                                </div>
                            `,
                            target: '#learning-payout',
                            order: 7,       
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

                                    assignLearningButton();
                                    
                                    return resolve(true);
                                });
                            },
                            beforeLeave: () => {
                                return new Promise(async (resolve) => {
                                    console.log ('afterLeave');

                                    router.push({name: 'workspace main', query: { learning: true }});

                                    return resolve(true)
                                });
                            },
                            group: groupName
                        },
                        {
                            title: 'Completion of training',
                            content: `
                                <div class="learning-modal-div flex justify-center align-center">
                                    <div class="inner-modal-div-form">
                                        <div class="flex flex-column justify-center align-center mb-2">
                                            <img src="${require('../../../assets/img/UI/learning_heart_icon.svg')}" class="pointer header-icon" />
                                            <h3 class="head-title">Thank you for<br>reading</h3>
                                        </div>
                                        <div class="flex align-center learning-steps-container">
                                            <div class="learning-steps-indicator" style="width: 100%"></div>
                                            <p class="steps-title">Completion of training</p>
                                            <p class="steps-numbers">8 / 8</p>
                                        </div>
                                        <p class="learning-title">
                                            Thank you for completing the training! 
                                            You can now use MerciQ. 
                                            Congratulations on successfully finishing!
                                        </p>
                                        <div id="learning-skip" class="m-x-auto mt-2">
                                            <button class="btn"><slot>Enjoy MerciQ benefits</slot></button>
                                        </div>
                                    </div>      
                                </div>
                            `,
                            order: 8,
                            afterEnter: () => {
                                return new Promise((resolve) => {
                                    console.log ('afterEnter');

                                    const learningSkipButton = document.getElementById('learning-skip');
                                    if (learningSkipButton) {
                                        learningSkipButton.addEventListener('click', finishTour);
                                    }
                                    
                                    return resolve(true);
                                });
                            },
                            group: groupName
                        },
                    ]);
                }
            }

            
            return { startTour, isTourFinished, addSteps, setGroupName, username, learning }

        },
        data() {
            return {
                dataUser: {
                    name: '',
                    avatar: '',
                    balance: '',
                    total: '',
                    average_order: '',
                    avarage_rating: '',
                    happy_clients: '',
                    stars: 0,
                    sum: 0,
                    qr_id: '',
                    username: '',
                    stripeStatus: 1,
                    lastWeekSum: 0
                },
                isActiveQRcode: false,
                isActiveHistory: false,
                showData: {
                    id: '',
                    time: '',
                    price: '',
                    desc: '',
                    stars: '', 
                    createdAt: ''
                },
                toDate: '',
                line_average: 0,
                transaction: [],
                achieved: [],
                avatar: ''
            }
        },
        methods: {
            updateQr() {
                this.refreshQr().then(data => {
                    this.qr_id = data.qr
                })
            },
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            getToDay() {
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                const d = new Date();
                return d.getDate() + " " + monthNames[d.getMonth()]
            },
            getData() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/employee/main', {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            },
            getAvatar(img) {
                this.avatar = img
                console.log(img)
            },
            setActiveQR() {
                this.isActiveQRcode = !this.isActiveQRcode
            },
            setActiveHistory(id, time, price, stars, desc, createdAt) {
                this.showData.id = id
                this.showData.time = time
                this.showData.price = price
                this.showData.stars = stars
                this.showData.desc = desc
                this.showData.createdAt = createdAt
                console.log('isActiveHistory', this.isActiveHistory);
                this.isActiveHistory = !this.isActiveHistory
            },
        },
        beforeMount() {
            /*
            this.getData().then(data => {
                this.dataUser.stripeStatus = data.account_request
                let last_name = (data.user.last_name == null) ? "" : data.user.last_name

                this.dataUser.name = data.user.name + " " + last_name,
                this.dataUser.avatar = data.user.avatar
                this.dataUser.balance = Number(data.user.balance).toFixed(2)
                this.dataUser.total = (data.total == null) ? '0' : (Number(data.total)).toFixed(2)
                this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                this.dataUser.avatar =  data.user.avatar
                this.dataUser.count =  (data.count == null) ? '0' : data.count
                this.dataUser.stars = (data.stars == null) ? '0' : Math.round(Number(data.stars)).toFixed(0)
                this.dataUser.lastWeekSum = Number(data.lastWeekSum).toFixed(2)
                
                this.dataUser.username = data.user.username

                this.dataUser.qr_id = data.user.qr_id
                console.log(this.dataUser)
                this.transaction = data.transaction
                this.transaction.forEach(el => {
                    this.dataUser.sum += Number(el.sum)
                })

                this.toDate = this.getToDay()
                this.line_average = (data.stars == null) ? 0 : ((Math.round(Number(data.stars)) * 100) / 5).toFixed(0)
            })

            this.getAchievments().then(data => {
                let achieved = this.achievments.filter(el => {
                    let result = null;
                    data.achievements.map(item => {
                        if(item == el.id) 
                            result = el;
                    })
                    if(result == null) {
                        return;
                    } else 
                        return result;

                })
                this.achieved = achieved;
            })
            */
        },
        async created() {
            // start tour
//            this.switchLearningModal();

            await this.getData().then(data => {
                this.dataUser.stripeStatus = data.account_request
                let last_name = (data.user.last_name == null) ? "" : data.user.last_name

                this.dataUser.name = data.user.name + " " + last_name,
                this.dataUser.avatar = data.user.avatar
                this.dataUser.balance = Number(data.user.balance).toFixed(2)
                this.dataUser.total = (data.total == null) ? '0' : (Number(data.total)).toFixed(2)
                this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                this.dataUser.avatar =  data.user.avatar
                this.dataUser.count =  (data.count == null) ? '0' : data.count
                this.dataUser.stars = (data.stars == null) ? '0' : Math.round(Number(data.stars)).toFixed(0)
                this.dataUser.lastWeekSum = Number(data.lastWeekSum).toFixed(2)
                
                this.dataUser.username = data.user.username

                this.dataUser.qr_id = data.user.qr_id
                console.log(this.dataUser)
                this.transaction = data.transaction
                this.transaction.forEach(el => {
                    this.dataUser.sum += Number(el.sum)
                })

                this.toDate = this.getToDay()
                this.line_average = (data.stars == null) ? 0 : ((Math.round(Number(data.stars)) * 100) / 5).toFixed(0)

                this.setLoader(false);
            })

            await this.getAchievments().then(data => {
                let achieved = this.achievments.filter(el => {
                    let result = null;
                    data.achievements.map(item => {
                        if(item == el.id) 
                            result = el;
                    })
                    if(result == null) {
                        return;
                    } else 
                        return result;

                })
                this.achieved = achieved;
            })

            console.log ('userName', this.dataUser.username);
            this.username = this.dataUser.username;
            if (this.dataUser.username) {
                const groupName = this.dataUser.username;
                if (!this.isTourFinished(groupName) && !this.learning) {

                    this.setGroupName(groupName);
                    this.addSteps(groupName);
                    this.startTour(groupName);
                }
            } else {
                const groupName = 'employee';
                if (!this.isTourFinished(groupName) && !this.learning) {

                    this.setGroupName(groupName);
                    this.addSteps(groupName);
                    this.startTour(groupName);
                }
            }
        }
        
    }
</script>

<style>

    .learning-modal-div .mascot-container .variant16 {
        width: 155px;
        height: 140px;
    }

    @media screen and (max-width: 500px) {
        .learning-modal-div .mascot-container .variant16 {
            width: 131px;
            height: 120px;
        }        
    } 

</style>