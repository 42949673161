import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        getData(value, date) {
            let ert = (value == undefined || value == null) ? '' : value

            let dateFilter = (date == null || date == undefined || date == '') ? '' : 'date=' + date
            if(date != "Day" && date != 'Week' && date != 'Month' && date != 'Year' && date != null && date != undefined && date != '') {
                dateFilter = (date == null || date == undefined) ? '' : 'date=' + JSON.stringify({date: date})
            }
            if(dateFilter != '') {
                if(ert == '') dateFilter = '?' + dateFilter
                else dateFilter = "&" + dateFilter
            }
            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/get' + ert + dateFilter, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)  
            }

            return response()
        }
    }
}