import configMixin from "./configMixin";


export default {
    mixins: [configMixin],
    methods: {
        getStaffMixins(job) {

            let jobFilter = (job == null || job == undefined || job == 'Waiter') ? 'Waiter' : 'Manager'

            let page_id = (this.$route.query?.page == undefined || 
                this.$route.query?.page == null || this.$route.query?.page == 1) ?
                1 : this.$route.query?.page

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/business/staff?job=' + jobFilter + "&page=" + page_id, {
                    method: "GET",
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}