<template>
      <div class="cale">
        <div class="flex">
            <h3 class="head-title">Notifications</h3>
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
        </div>
       <div class="flex flex-between mt-4">
        <p>System notifications</p>
        <label class="toggle">
            <input class="toggle-checkbox" type="checkbox" :checked="dataForm.systemNotifications == true" @click="setSystemNotifications">
            <div class="toggle-switch"></div>
        </label>
       </div>
       <div class="flex flex-between mt-4">
        <p>Newsletters, messages about new system updates, marketing information</p>
        <label class="toggle">
            <input class="toggle-checkbox" type="checkbox" :checked="dataForm.marketingInformation == true" @click="setMarketingInformation">
            <div class="toggle-switch"></div>
        </label>
       </div>
       <p class="desc-mod mt-4">If you turn off all notifications, MerciQ will not be able to send you emails, including important information about changes to our service, documentation, and personal system information about your account</p>
       <my-button active="true" class="mt-4" @click="getdata" label="Save"/>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'
import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'notifications',
        mixins: [configMixin, loaderMixin],
        data() {
            return {
                dataForm: {
                    systemNotifications: Boolean,
                    marketingInformation: Boolean
                }
            }
        },
        methods: {
            close() {
               this.$emit('close')
            },
            getdata() {
                this.updateSetting()
                this.$emit('getdata', this.dataForm)
            },

            getSetting() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/notification', {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            },
            setSystemNotifications() {
                this.dataForm.systemNotifications = !this.dataForm.systemNotifications
            },
            setMarketingInformation() {
                this.dataForm.marketingInformation = !this.dataForm.marketingInformation
            },
            updateSetting() {

                let body = {
                 settings: JSON.stringify({
                        "System notifications": this.dataForm.systemNotifications,
                        "Newsletters, messages about new system updates, marketing information": this.dataForm.marketingInformation
                    })
                }

                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/notification', {
                        method: "PUT",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            }
        },
        mounted() {
            this.getSetting().then(data => {
                data = JSON.parse(data.setting.settings)

                this.dataForm.systemNotifications = data["System notifications"]
                this.dataForm.marketingInformation = data["Newsletters, messages about new system updates, marketing information"]

                this.setLoader(false);
            })
        }
    }
</script>

<style scoped>

p {
    color: white;
    font-family: 'Rubik-Regular';
    font-size: 16px;
}
.flex-between p {
    width: 75%;
}
.desc-mod {
    text-align: center;
    color: rgba(171, 171, 171, 1);
    font-size: 12px;
    font-family: 'Rubik-Light';
}
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
}
.cale {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 320px;
    padding: 40px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
.close {
    position: absolute;
    margin-left: 94%;
    margin-top: -40px;
}
@media screen and (max-width: 500px) {
    .cale {
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-top: -20px;
    }
}
</style>