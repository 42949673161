<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getDateCalendar" v-if="isActiveCalendar"/>
    <div class="container-dash average-bg-mobile coll-1-e">
        <employee-dashboard />
        <div :class="{'wrapper-dash': true, 'wrapper-average': true, 'calendar-active-cont': isActiveCalendar}" class="cont-7 coll-1-e">
            <div class="flex top-avarage-mobile d-none-mobile">
                <h2 class="main-title-dash pointer" @click="$router.push('/employee/workspace')"><img src="../../../assets/img/UI/left-strel.svg">  Leader teams</h2>
                <div class="flex mt-2">
                    <div :class="{customer: true, grey_block: false, pointer: true}">
                    <p style="font-size: 14px; font-family: 'Rubik-Regular';" :class="{'active-customer': true}">Customer ratings</p>
                </div>
                <div :class="{customer: true, grey_block: true, pointer: true}">
                    <p style="font-size: 14px; font-family: 'Rubik-Regular';" :class="{'active-customer': false}">Volume of income</p>
                </div>
                </div>
                <div class="min-panel-end flex panel-average">
                    <div class="panel-calendar-min ml-3" @click="setActiveCalendar">
                        <img src="../../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0)">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1)">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2)">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3)">Year</div>
                    </div>
                </div>
                
            </div>
            <div class="d-none-desc">
            <h2 class="main-title-dash pointer" @click="$router.push('/employee/workspace')"><img src="../../../assets/img/UI/left-strel.svg"> Leader teams</h2>
            <div class="flex mt-3">
                 <div class="flex">
                    <div :class="{customer: true, grey_block: false, pointer: true}">
                    <p style="font-size: 14px; font-family: 'Rubik-Regular';" :class="{'active-customer': true}">Customer ratings</p>
                </div>
                <div :class="{customer: true, grey_block: true, pointer: true}">
                    <p style="font-size: 14px; font-family: 'Rubik-Regular';" :class="{'active-customer': false}">Volume of income</p>
                </div>
                </div>
                <div class="min-panel-end flex panel-average">
                    <div class="panel-calendar-min ml-3" @click="setActiveCalendar">
                        <img src="../../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0)">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1)">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2)">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3)">Year</div>
                    </div>
                </div>
                
            </div>
        </div>
            <div class="block_stroke mt-3">
                <div class="stroke" style="width: 100%"></div>
            </div>
            <div class="main_block mt-3">
                <div class="Position">Position</div>
                <div class="Team_name">Team name</div>
                <div class="Rate">Rate</div>
            </div>
            <TeamRate place='1' team='Team 1' rate='4.9'/>
            <TeamRate place='2' team='Team 2' rate='4.9'/>
            <TeamRate place='3' team='Team 3' rate='4.9'/>
            <TeamRate place='4' team='Team 4' rate='4.9'/>
            <TeamRate place='5' team='Team 5' rate='4.9'/>
            <TeamRate place='6' team='Team 6' rate='4.9'/>
            <TeamRate place='7' team='Team 7' rate='4.9'/>
            <TeamRate place='8' team='Team 8' rate='4.9'/>
            <TeamRate place='9' team='Team 9' rate='4.9'/>
            <TeamRate place='10' team='Team 10' rate='4.9'/>
            <TeamRate place='11' team='Team 11' rate='4.9'/>
                <div class="flex mt-3 pages-b-b">
                        <img src="../../../assets/img/UI/left-b.svg">
                        <p class="pages-b-b-el pages-b-b-active pointer">1</p>
                        <p class="pages-b-b-el pointer">2</p>
                        <p class="pages-b-b-el pointer">3</p>
                        <p class="pages-b-b-el pointer">...</p>
                        <p class="pages-b-b-el pointer">126</p>
                        <img src="../../../assets/img/UI/right-b.svg">
                </div> 
        </div>
        
    </div>
    
</template>
    
<script>
    import dashboardMixin from '@/mixins/dashboardMixin';

    export default {
        name: 'leaders order empl',
        mixins: [dashboardMixin]

    }
</script>