<template>
    <div class="cale">
            <div class="flex">
                <h3 class="head-title">Loading</h3>
            </div>
            <div class="loading-container">
                <span class="loader"></span>
            </div>
            <p class="text-loading">{{text}}</p>
        </div>
    </template>
    
    <script>
    import configMixin from '@/mixins/configMixin'
    
        export default {
            name: 'loading',
            mixins: [configMixin],
            props: {
                text: String
            },
            methods:{
                addEmployee() {
                    
                    this.$emit('addempl')
                },
                close() {
                    this.$emit('close')
                },
               
            },
           
        }
    </script>
    
    <style scoped>
    .cale .flex {
        justify-content: center
    }
    .close {
        margin-left: auto;
    }
    .head-title {
        font-size: 24px;
        font-family: 'Rubik-Medium';
        color: var(--main-text-color);
    }
    .cale {
        position: absolute;
        top: 200px;
        left: calc(60% - 408px);
        z-index: 3;
        opacity: 1;
        width: 450px;
        padding: 40px;
        background-color: #171717;
        border-radius: 10px;
    }
    @media screen and (max-width: 500px) {
        .cale {
            padding: 15px;
            width: 320px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
    }
    .loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--orange-color);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
    .loading-container {
        text-align: center;
        margin-top: 40px;
    }
    .text-loading {
        color: white;
        text-align: center;
        margin-top: 30px;
    }
    </style>
    