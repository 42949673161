<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getCalendar" v-if="isActiveCalendar"/>
    <qr-code  
    @close="setActiveQr"  
    @update="refreshQr"  
    :qr="userInfo.qr_id"
    v-if="isActiveQr && userInfo?.qr_id != undefined"/>
    <modal-edit-employee 
    v-if="isActiveEditUserPanel && userInfo.job == 'Waiter'" 
    @close="setActivePanelAdd" 
    :email="userInfo.email" 
    :login="userInfo.login" 
    :name="userInfo.name" 
    :phone="userInfo.phone" 
    :establishment="establishments" 
    :team="teams"
    :id="userInfo.id"/>
    <modal-edit-manager 
    v-if="isActiveEditUserPanel && userInfo.job == 'Manager'" 
    @close="setActivePanelAdd" 
    :email="userInfo.email" 
    :login="userInfo.login" 
    :name="userInfo.name" 
    :phone="userInfo.phone" 
    :establishment="establishments" 
    :role="['Full access', 'Branch access']"
    :team="teams"
    :id="userInfo.id"/>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': (isActiveCalendar || isActiveQr || isActiveEditUserPanel), 'member-team': true}">
            <member-info 
            @showqr="setActiveQr" 
            @redact="setActivePanelAdd" 
            :login="userInfo.login"
            :name="userInfo.name" 
            :phone="userInfo.phone"
            :team="userInfo.team"
            :establishment="userInfo.establishment"
            :avatar="userInfo.avatar"
            :qr="userInfo.qr_id"
            @updateQR="refreshQr"/>
            <div class="main-nav-mobile d-none-mobile member-nav-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex">
                        <h2 :class="{ active_nav_panel: false}" @click="$router.push('/establishment/member/' + this.$route.params?.user_id)" >Transactions</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': true}" @click="$router.push('/establishment/member/statistic/'  + this.$route.params?.user_id)">All аnalytics</h2>
                    </div>
                    <div class="main-line-menu">
                        <div class="menu-mobile-nav-line flex">
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-1"></div>
                            <div :class="{'fr-main-inp-active': true, 'mobile-start-el': true}" class="mobile-nav-est-2"></div>
                        </div>
                    </div>
                </div>

                <div class="flex p-28 d-none-desc" style="margin-top: 56px;">
                    <div class="first-staff-nav m-y-auto">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false, 'nav-member-er': true}" @click="$router.push('/establishment/member/' + this.$route.params?.user_id)">Transactions</p>
                    </div>
                    <div class="second-staff-nav m-y-auto">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true, 'nav-member-er': true}" @click="$router.push('/establishment/member/statistic/' + this.$route.params?.user_id)">All аnalytics</p>
                    </div> 
                    
            </div>
            <div class="border-staff flex m-28 d-none-desc" style="width: 730px; margin-top: 14px;">
                <div :class="{'active-first': true, 'active-nav-staff': false, 'trans-lines': true}"></div>
                <div :class="{'second-first': true, 'active-nav-staff': true, 'analit-line': true}"></div>
            </div>
            <div class="div-null-height" style="height: 50px"></div>
            
            <div class="flex mt-3 ml-t flex-content-mob ml-0-mobile">
                <div class="panel-balance-el ml-2 pointer member-el-div ml-0-mobile member-el-stat" @click="$router.push('/establishment/member/history/' + this.$route.params?.user_id)">
                        <p>Total earnings</p>
                        <p class="balance-el-t">${{ transactionData.total }}</p>
                        <p class="balance-el-t-c">{{ transactionData.count }} transactions</p>
                    </div>
                <div class="panel-balance-el balance-active pointer member-el-div ml-2 member-el-stat" @click="$router.push('/establishment/member/history/' + this.$route.params?.user_id)">
                        <p>Business account<br> balance</p>
                        <p class="business-sum">${{ transactionData.balance }}</p>
                </div>
            </div>
            <div class="flex mt-2 ert-fl ml-t flex-content-mob ml-0-mobile">
                <div class="panel-balance-el balance-active pointer member-el-div secus member-el-stat sty-mobile" @click="$router.push('/establishment/member/average/' + this.$route.params?.user_id)">
                        <p>Average tip value</p>
                        <p class="business-sum">${{ transactionData.average }}</p>
                </div>
                <div class="flex mt-1-mobile">
                    <div class="panel-stats-el ml-2 elpi" style="height: 200px;">
                        <div class="pie el-pie" :style="{'--p': transactionData.happy}">{{ transactionData.rate }}</div>
                        <div class="pie pie-two p-s-mobile" :style="{'--p': transactionData.happy}"><span>{{ transactionData.rate }}</span></div>
                        <p class="mt-2">Average rating</p>
                    </div>
                    <div class="panel-stats-el ml-2 elpi ml-1-mobile">
                        <div class="pie el-pie" :style="{'--p': transactionData.happy}">{{transactionData.happy}}%</div>
                        <div class="pie pie-two p-s-mobile" :style="{'--p': transactionData.happy}"><span>{{transactionData.happy}}%</span></div>
                        <p class="mt-2">Happy clients</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dashboardMixin from '@/mixins/dashboardMixin';
import qrMixin from '@/mixins/qrMixin';
import staffMixin from '@/mixins/staffMixin';
import memberMixin from '@/mixins/memberMixin';
import dateIconMixin from '@/mixins/dateIconMixin';
import sendEmployeeRegistrMixin from '@/mixins/sendEmployeeRegistrMixin'

export default {
    name: 'statistic member',
    mixins: [dashboardMixin, qrMixin, staffMixin, memberMixin, dateIconMixin, sendEmployeeRegistrMixin],
    data() {
        return {
            userInfo: {
                login: 'Denis Petrov',
                phone: '+7 900 777 58 59',
                establishment: 'Establishment 1',
                team: 'Team 1',
                avatar: '',
                job: ''
            },
            transactionData: {
                total: 0,
                count: 0,
                balance: 0,
                average: 0,
                rate: 0,
                happy: 0
            },
            choiceDate: '',
            establishments: '',
            teams: '',
            establishments: '',
            teams: ''
        }
    },
    methods: {
        setActivePanelAdd() {
            this.isActiveEditUserPanel = !this.isActiveEditUserPanel
        },
        zeroPad(num) {
            return num.toString().padStart(7, "0");
        },
        delCalendar() {
            this.getTransactionData(null).then(data => {
                this.transactionData.total = (data.total == null) ? '0' : data.total
                this.transactionData.balance = data.admin.balance
                this.transactionData.count = data.count
                this.transactionData.average = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                this.transactionData.rate = (data.stars == null) ? '0' : Math.round(Number(data.stars)).toFixed(0)
                this.transactionData.happy = (data.stars == null) ? '0' : (Math.round(Number(data.stars)) * 100) / 5
            })
            this.delDateIcon()
        },
        getCalendar(value) {
            let date = (value == null || value == undefined) ? null : JSON.stringify({date: value})
            this.getTransactionData(value).then(data => {
                this.transactionData.total = (data.total == null) ? '0' : data.total
                this.transactionData.balance = data.admin.balance
                this.transactionData.count = data.count
                this.transactionData.average = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                this.transactionData.rate = (data.stars == null) ? '0' : Math.round(Number(data.stars)).toFixed(0)
                this.transactionData.happy = (data.stars == null) ? '0' : (Math.round(Number(data.stars)) * 100) / 5
            })
            this.getDateIcon(value)
        },
        getTransactionData(date) {
            
            let dateFilter = (date == null || date == undefined) ? '' : '?date=' + date
            if(date != "Day" && date != 'Week' && date != 'Month' && date != 'Year' && date != null && date != undefined) {
                dateFilter = (date == null || date == undefined) ? '' : '?date=' + JSON.stringify({date: date})
            }

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/get/finance/' + this.$route.params?.user_id + dateFilter, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        },
        refreshQr() {
            let response = async() => {

                let query = await fetch(this.config.API_URL + '/user/qr/refresh/'  + this.$route.params?.establishment_id, {
                    method: "PUT",
                    headers: {
                        "Authorization": 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                if(query.status != 200) return this.$router.push('/dashboard')

                let result = await query.json()

                return Promise.resolve(result) 
            }
            response().then(data => {
                this.userInfo.qr_id = data.qr
            })
        }
    },
    beforeMount() {
        let esta_id = 0
        let team_id = 0
        this.getMemeber().then(data => {
            try {
                data = data.user
                this.userInfo.login = data.username
                this.userInfo.avatar = data.avatar
                this.userInfo.phone = data.phone
                this.userInfo.name = data.name + ' ' + data.last_name
                this.userInfo.qr_id = data.qr_id
                this.userInfo.avatar = data.avatar
                this.userInfo.email = data.email
                this.userInfo.id = data.id
                this.userInfo.job = data.job
                this.userInfo.team = (data.team == null) ? '' : data.team

                esta_id = data?.Establishment?.id
                team_id = data?.Team?.id

                this.userInfo.team = (data.Team == null) ? '' : data.Team.name
                this.userInfo.establishment = (data.Establishment == null) ? '' : data.Establishment.name
               
            } catch(err) {
                this.$router.push('/dashboard')
            }
        })

        this.getTransactionData().then(data => {
            this.transactionData.total = (data.total == null) ? '0' : data.total
            this.transactionData.balance = (data.admin.balance == null) ? 0 : data.admin.balance
            this.transactionData.count = data.count
            this.transactionData.average = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
            this.transactionData.rate = (data.stars == null) ? '0' : Math.round(Number(data.stars)).toFixed(0)
            this.transactionData.happy = (data.stars == null) ? '0' : (Math.round(Number(data.stars)) * 100) / 5
            console.log(data)
        })

        this.getDataTeamAndEsta().then(data => {
            if(team_id != undefined && team_id != 0 && esta_id != null) {
                let start_teams = data.teams[0]

                let need = data.teams[data.teams.findIndex(item => item.id == team_id)]
                let i = data.teams.findIndex(item => item.id == team_id)

                this.teams = data.teams
                this.teams[0] = need

                this.teams[i] = start_teams
            } else {
                this.teams = data.teams
            }

            if(esta_id != undefined && esta_id != 0 && esta_id != null) {
                let start_esta = data.establishments[0]

                let need = data.establishments[data.establishments.findIndex(item => item.id == esta_id)]
                let i = data.establishments.findIndex(item => item.id == esta_id)
                console.log(data.establishments)
                this.establishments = data.establishments
               
                this.establishments[0] = need

                this.establishments[i] = start_esta
            } else {
                this.establishments = data.establishments
            }
        })
    }

}
</script>