<template>
    <qr-code 
        v-if="isActiveQRcode" 
        @close="setActiveQR" 
        :personal="dataUser.username"
        :qr="dataUser.qr_id"
        @update="updateQr"
    />
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getCalendar" v-if="isActiveCalendar"/>
    <verification-required-modal 
        v-if="showVerificationModal"
        @close="closeVerificationModal" 
        @goToVerification="goToVerification"
    />
    <learning-modal 
        v-if="showLearningModal"
        @close="switchLearningModal"
    />
    <div class="container-dash">
        <dashboard-nav @getavatar="getAvatar" />
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': isActiveCalendar || isActiveQRcode, 'main-mirciq': true, 'workspace-wrapper-das': true}">

            <div class="d-none-mobile height-work-mobile">
                <my-button active="true" @click="setActiveQR">QR-code</my-button>
                <div class="profile-panel-height pointer" @click="$router.push('/settings')">
                    <img :src="'/img/uploads/logo/esta/' + avatar" v-if="avatar != ''" class="logo-p">
                    <div class="data-panel-p">
                        <p>{{ dataUser.name }}</p>
                        <div class="star-p-h">
                            <img src="../../assets/img/UI/start.svg">
                            <span>{{dataUser.stars}}</span>
                        </div>
                    </div>
                    <img src="../../assets/img/UI/setting-grey.svg" class="setting-panel-height">
                </div>
            </div>

            <h2 class="main-title-dash">Quick actions</h2>

            <div class="warning-message mt-2" style="width: 100%" v-if="isFailVerif">
                <img src="../../assets/img/UI/warning.svg">
                <p class="ml-1" style="text-align: left;">To be able to accept tips, you need to complete verification on the <RouterLink to="/stripe"><span class="orange-color">"Verification"</span></RouterLink> tab</p>   
            </div>
            <div id="quick-actions" class="height-panels mt-2 flex">
                <div class="employee-div pointer" @click="$router.push('/staff')">
                    <img src="../../assets/img/UI/employee.svg">
                    <p>Employee<br> registration</p>
                </div>
                <div class="employee-div ml-2 lock-pan pointer" @click="$router.push('/excel')">
                    <img src="../../assets/img/UI/report_excel.svg">
                    <p>Reporting<br> excel</p>
                </div>
                <div class="employee-div ml-2 lock-pan pointer" @click="goToInstruction">
                    <img src="../../assets/img/UI/instraction_panel.svg">
                    <p>Instruction<br> MERCIQ</p>
                </div>
            </div>
            
            <div class="height-height-panel-div"></div>
            
            <div class="title-li-panel flex mt-5">
                <div id="analytics-navigation-desktop" class="main-nav-mobile d-none-desc" ref="mobile_menu" @touchstart="touchStartFunc" @touchmove="touchEndFunc" @touchend="touchEndFunc">
                    <div class="min-panel-nav flex d-none-desc qweb">
                        <h2 :class="{ active_nav_panel: isActive[0]}" @click="setNavPanel('', 0)" >All аnalytics</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': isActive[1]}" @click="setNavPanel('Establishments',1)">Establishments</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: isActive[2]}" @click="setNavPanel('Teams', 2)">Teams</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: isActive[3]}" @click="setNavPanel('Employees', 3)">Employees</h2>
                    </div>
                    <div class="main-line-menu d-none-desc">
                        <div :class="{'fr-main-inp-active': isActive[0]}" class="fr-main-inp"></div>
                        <div :class="{'fr-main-inp-active': isActive[1]}" class="sc-main-inp"></div>
                        <div :class="{'fr-main-inp-active': isActive[2]}" class="tr-main-inp"></div>
                        <div  :class="{'fr-main-inp-active': isActive[3]}" class="fo-main-inp"></div>
                    </div>
                </div>
                <div class="main-nav-mobile d-none-mobile" ref="mobile_menu" >
                    <div id="analytics-navigation-mob" class="min-panel-nav flex min-panel-nav-setting" style="width: auto;">
                        <h2 :class="{'ml-4': true, active_nav_panel: isActive[0]}" @click="setNavPanel('', 0)" style="margin-left: 0">All аnalytics</h2>
                        <h2 :class="{ active_nav_panel: isActive[1]}" @click="setNavPanel('Establishments',1)"  style="margin-left: 20px;">Establishments</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': isActive[2]}" @click="setNavPanel('Teams', 2)">Teams</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: isActive[3]}" @click="setNavPanel('Employees', 3)">Employees</h2>
                    </div>
                    <div class="main-line-menu min-panel-nav-setting" style="width: 600px">
                        <div class="menu-mobile-nav-line flex min-panel-nav-setting-child">
                            <!-- <div style="width: 99px; text-align: center;">
                                <div :class="{'fr-main-inp-active': isActive[0]}" class="sec-setting-mobile-1" ></div>
                            </div>
                            <div style="width: 125px; text-align: center; margin-left: 20px; display: flex; justify-content: center; align-items: center">
                                <div :class="{'fr-main-inp-active': isActive[1]}" class="sec-setting-mobile-2" style="margin-left: 0"></div>
                            </div>
                            <div style="width: 52px; text-align: center; margin-left: 20px; display: flex; justify-content: center">
                                <div :class="{'fr-main-inp-active': isActive[2]}" class="sec-setting-mobile-2" style="position: relative; left: 20px"></div>
                            </div>
                            <div style="width: 52px; text-align: center; margin-left: 20px; ">
                                <div  :class="{'fr-main-inp-active': isActive[3]}" class="sec-setting-mobile-3" style="margin-left: 0"></div>
                            </div> -->
                        </div>
                    </div>
                </div>
                
                <div class="min-panel-end flex" style="flex-wrap: wrap; align-items: flex-start">
                    <div class="flex">
                        <img src="../../assets/img/UI/strel.svg" class="img-strel-list-main" @click="setClassSelect" v-if="navPanel == 'Establishments' || navPanel == 'Teams' || navPanel == 'Employees'">
                        <div class="select main-to"  @click="setClassSelect" v-if="navPanel == 'Establishments' || navPanel == 'Teams' || navPanel == 'Employees'">
                            <ul ref="select" class="closed esta-select select-main das">
                                <li class="option" style="padding-top: 7px;  border-radius: 5px" @click="e => {setSelectFilter(0, e)}">{{  navPanelFunc()}}</li>
                                <li
                                class="option" 
                                @click="e => {setSelectFilter(el.id, e)}"
                                style="padding-top: 7px; border-radius: 0px"
                                v-for="el in resources">{{ el.name }}</li>
                            </ul>
                        </div>
                    </div>
                    <div style="height: 90px"></div>
                    <div class="panel-calendar-min ml-3 " @click="setActiveCalendar" style="margin-top: 30px" v-if="choiceDate == ''">
                        <img src="../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    
                    <div class="panel-calendar-min ml-3 mt-1-mobile mon-0" v-if="choiceDate != ''" style="align-items: center">
                        <div>
                            <span class="choiceDate" style="white-space: nowrap; margin-left: 0;">{{ choiceDate }}</span>
                            <img src="../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px"  class="pointer" @click="delCalendar">
                        </div>
                    </div>
                    <!-- <div class="panel-calendar-min mt-2-mobile" style="margin-left: 0" v-if="choiceDate != ''">
                        <div>
                            <img src="../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delCalendar">
                        </div>
                    </div> -->
                    <div class="min-panel-date-filter flex ml-1" :style="{'margin-top': (choiceDate != '') ? '30px': '27px'}">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0), updateGetData('Day')">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1), updateGetData('Week')">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2), updateGetData('Month')">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3), updateGetData('Year')">Year</div>
                    </div>
                </div>
            </div>
            <div v-if="navPanel == ''">
                <div class="flex panel-balance">
                    <div id="account-balance" class="panel-balance-el balance-active pointer main-panel-name" @click="$router.push('/dashboard/businessbalance')">
                        <p>Business account<br> balance</p>
                        <p class="business-sum">${{ dataUser.balance }}</p>
                    </div>
                    <div class="panel-balance-el ml-2 pointer main-panel-name" @click="$router.push('/dashboard/history')">
                        <p>Total earnings</p>
                        <p class="balance-el-t-c dev-totla">{{ dataUser.count }} transactions</p>
                        <p class="balance-el-t toteln">${{ dataUser.total }}</p>
                    </div>
                    <div id="average-tip" class="panel-balance-el ml-2 average-panel main-panel-name" @click="$router.push('/dashboard/average')">
                        <p>Average tip value</p>
                        <p class="average-sum">${{ dataUser.average_order }}</p>
                    </div>
                    <div class="flex mobile-panel-per">
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/dashboard/leaders')">
                            <div class="pie " style="--p:100">{{ dataUser.stars }}</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ dataUser.stars }}</span></div>
                            <p class="mt-2">Average rating</p>
                        </div>
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/dashboard/reviews')">
                            <div class="pie " style="--p:100">{{ line_average }}%</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ line_average }}%</span></div>
                            <p class="mt-2">Happy clients</p>
                        </div>
                    </div>
                </div>
                <LineChartContainer 
                class="graph-1-2 mt-5" 
                style="height: 300px" 
                :dataOption="day" 
                v-if="day.length > 0 && false"/>
            </div>

            <div v-else-if="navPanel == 'Establishments'">
                <div class="flex">
                    <div style="width: 350px; margin-left: auto; margin-right: auto"><my-button active="true" @click='$router.push("/settings/establishments")' label="Manage establishments"/></div>
                </div>
                <div class="flex panel-balance">
                    <div class="panel-balance-el balance-active pointer main-panel-name" @click="$router.push('/dashboard/businessbalance')">
                        <p>Business account<br> balance</p>
                        <p class="business-sum">${{ dataUser.balance }}</p>
                    </div>
                    <div class="panel-balance-el ml-2 pointer main-panel-name" @click="$router.push('/dashboard/history')">
                        <p>Total earnings</p>
                        <p class="balance-el-t">${{ dataUser.total }}</p>
                        <p class="balance-el-t-c">{{ dataUser.count }} transactions</p>
                    </div>
                    <div class="panel-balance-el ml-2 average-panel main-panel-name" @click="$router.push('/dashboard/average')">
                        <p>Average tip value</p>
                        <p class="average-sum">${{ dataUser.average_order }}</p>
                    </div>
                    <div class="flex mobile-panel-per">
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/dashboard/leaders')">
                            <div class="pie " style="--p:100">{{ dataUser.stars }}</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ dataUser.stars }}</span></div>
                            <p class="mt-2">Average rating</p>
                        </div>
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/dashboard/reviews')">
                            <div class="pie " style="--p:100">{{ line_average }}%</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ line_average }}%</span></div>
                            <p class="mt-2">Happy clients</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="navPanel == 'Teams'">
                <div class="flex">
                    <div style="width: 350px; margin-left: auto; margin-right: auto"><my-button active="true" @click='$router.push("/settings/establishments")' label="Manage teams"/></div>
                </div>

                <div class="flex panel-balance">
                    <div class="panel-balance-el balance-active pointer main-panel-name" @click="$router.push('/dashboard/businessbalance')">
                        <p>Business account<br> balance</p>
                        <p class="business-sum">${{ dataUser.balance }}</p>
                    </div>
                    <div class="panel-balance-el ml-2 pointer main-panel-name" @click="$router.push('/dashboard/history')">
                        <p>Total earnings</p>
                        <p class="balance-el-t">${{ dataUser.total }}</p>
                        <p class="balance-el-t-c">{{ dataUser.count }} transactions</p>
                    </div>
                    <div class="panel-balance-el ml-2 average-panel main-panel-name" @click="$router.push('/dashboard/average')">
                        <p>Average tip value</p>
                        <p class="average-sum">${{ dataUser.average_order }}</p>
                    </div>
                    <div class="flex mobile-panel-per">
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/dashboard/leaders')">
                            <div class="pie " style="--p:100">{{ dataUser.stars }}</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ dataUser.stars }}</span></div>
                            <p class="mt-2">Average rating</p>
                        </div>
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/dashboard/reviews')">
                            <div class="pie " style="--p:100">{{ line_average }}%</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ line_average }}%</span></div>
                            <p class="mt-2">Happy clients</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="navPanel == 'Employees'">
                <div class="flex" style="flex-wrap: wrap; justify-content: space-between">
                    <div style="width: 350px; margin-left: auto; margin-right: auto"><my-button label="Manage employees" active="true" @click='$router.push("/staff")'/></div>
                </div>
                <div class="flex panel-balance">
                    <div class="panel-balance-el balance-active pointer main-panel-name" @click="$router.push('/dashboard/businessbalance')">
                        <p>Business account<br> balance</p>
                        <p class="business-sum">${{ dataUser.balance }}</p>
                    </div>
                    <div class="panel-balance-el ml-2 pointer main-panel-name" @click="$router.push('/dashboard/history')">
                        <p>Total earnings</p>
                        <p class="balance-el-t">${{ dataUser.total }}</p>
                        <p class="balance-el-t-c">{{ dataUser.count }} transactions</p>
                    </div>
                    <div class="panel-balance-el ml-2 average-panel main-panel-name" @click="$router.push('/dashboard/average')">
                        <p>Average tip value</p>
                        <p class="average-sum">${{ dataUser.average_order }}</p>
                    </div>
                    <div class="flex mobile-panel-per">
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/dashboard/leaders')">
                            <div class="pie " style="--p:100">{{ dataUser.stars }}</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ dataUser.stars }}</span></div>
                            <p class="mt-2">Average rating</p>
                        </div>
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/dashboard/reviews')">
                            <div class="pie " style="--p:100">{{ line_average }}%</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ line_average }}%</span></div>
                            <p class="mt-2">Happy clients</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="tg-backdrop" v-if="showLearningModal"></div>

    
</template>

<script>
    import { ref } from 'vue';

    import dashboardMixin from '@/mixins/dashboardMixin';
    import touchMixin from '@/mixins/touchMixin';
    import getAdminDataMixin from '@/mixins/getAdminDataMixin';
    import dateIconMixin from '@/mixins/dateIconMixin';
    import refreshQr from '@/mixins/refreshQr';

    import { useRouter, useRoute } from 'vue-router';

    import { useStore } from 'vuex';

    import { mapActions } from 'vuex';

    import "@sjmc11/tourguidejs/src/scss/tour.scss" // Styles
    import { TourGuideClient } from "@sjmc11/tourguidejs/src/Tour" // JS

    import TgInstance from '@/components/instance/tourguide';

    export default {
        name: 'main dashboard',
        mixins: [dashboardMixin, touchMixin, getAdminDataMixin, dateIconMixin, refreshQr],
        setup() {

            // new store reference
            const store = useStore();

            const username = ref('');

            const router = useRouter();
            const route = useRoute();
            // Now you can access params like:
            console.log('learning', route.query.learning);
            
            let learning = false;
            if (route.query.learning == 'true')
                learning = true;
            
            const isMobile = () => {
                if( screen.width <= 760 ) {
                    return true;
                }
                else {
                    return false;
                }
            }

            const tgInstance = isMobile() ? new TgInstance({
                autoScroll: false,
                keyboardControls: true,
                rememberStep: true,
                showButtons: false,
                showStepDots: false,
                showStepProgress: false,
                exitOnClickOutside: false,
                allowedPlacements: ['top', 'bottom']
            }) : new TgInstance({
                autoScroll: true,
                keyboardControls: true,
                rememberStep: true,
                showButtons: false,
                showStepDots: false,
                showStepProgress: false,
                exitOnClickOutside: false,
                allowedPlacements: ['top', 'right', 'bottom', 'left']
            });

//            const groupName = 'admin';            
//            tgInstance.tg.group = groupName;

            tgInstance.tg.onFinish(()=>{
                console.warn('Tour finished')

                enableScroll();
            });

            tgInstance.tg.onAfterStepChange(()=>{
                console.warn('Tour step changed')
            });

            const setGroupName = (groupName) => {
                tgInstance.groupName = groupName;
            }

            const startTour = (groupName) => {
                tgInstance.tg.start(groupName);
            }

            const isTourFinished = (groupName) => {
                return tgInstance.tg.isFinished(groupName);
            }

            const nextStep = () => {

                tgInstance.tg.nextStep();
            }

            /*
            const nextStepWithHideModal = () => {
                const element = document.getElementById('tg-dialog-body');
                element.style.display = 'none';

                tgInstance.tg.nextStep();
            }
            */

            const finishTour = () => {
                console.log('username.value', username.value);

                const learningMenu = document.getElementById('learning-menu');
                
                if (isMobile() && learningMenu) {
                    learningMenu.style.display = 'flex';
                    learningMenu.style.height = '54px';
                }

                enableScroll();

                tgInstance.tg.finishTour(true, username.value);
                
//                tgInstance.tg.finishTour(true, groupName);
            }

            const assignLearningButton = () => {
                const nextStepButton = document.getElementById('learning-next-step');
                if (nextStepButton) {
                    nextStepButton.addEventListener('click', nextStep);
                }

                const learningSkipButton = document.getElementById('learning-skip');
                if (learningSkipButton) {
                    learningSkipButton.addEventListener('click', finishTour);
                }
            }

            /*
            const assignLearningButtonAndHideModal = () => {
                const nextStepButton = document.getElementById('learning-next-step');
                if (nextStepButton) {
                
                    nextStepButton.addEventListener('click', nextStepWithHideModal);
                }

                const learningSkipButton = document.getElementById('learning-skip');
                if (learningSkipButton) {
                    learningSkipButton.addEventListener('click', finishTour);
                }
            }
            */

            const goToInstruction = () => {
//                finishTour();
                if (isMobile()) {
//                    window.location.href = '/docs/MerciQ_GUIDE_BUSINESS_WEB.pdf';
                    window.open('/docs/MerciQ_GUIDE_BUSINESS_WEB.pdf', '_blank');
                } else {
//                    window.location.href = '/docs/MerciQ_GUIDE_BUSINESS_WEB.pdf';
                    window.open('/docs/MerciQ_GUIDE_BUSINESS_WEB.pdf', '_blank');
                }
            }

            function preventDefault(e) {
                e.preventDefault();
            }

            // modern Chrome requires { passive: false } when adding event
            let supportsPassive = false;
            try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () { supportsPassive = true; } 
            }));
            } catch(e) {}

            let wheelOpt = supportsPassive ? { passive: false } : false;
            let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

            // call this to Disable
            function disableScroll() {
                window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
                window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
                window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
            }

            // call this to Enable
            function enableScroll() {
                window.removeEventListener('DOMMouseScroll', preventDefault, false);
                window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
                window.removeEventListener('touchmove', preventDefault, wheelOpt);
            }

            const addSteps = (groupName) => {

                if (isMobile()) {

                    tgInstance.tg.addSteps([{
                        title: 'Welcome',
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">
                                    <div class="flex flex-column justify-center align-center mb-2">
                                        <img src="${require('../../assets/img/UI/learning_hat_icon.svg')}" class="pointer header-icon" />
                                        <h3 class="head-title">Welcome to<br>training mode</h3>
                                    </div>
                                    <p class="learning-title">
                                        Welcome to the MerciQ Business Profile Guide. 
                                        This guide will help you navigate and utilize the various features of your business account, 
                                        from managing employees and establishments to handling analytics and payouts. 
                                        Follow the steps to make the most out of your MerciQ experience.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>      
                            </div>
                        `,
                        order: 0, // Add to start
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                console.log ('afterEnter');

                                disableScroll();
                                
                                /*
                                const appElement = document.getElementById('app');
                                appElement.style.position = 'fixed';
                                appElement.style.left = 0;
                                appElement.style.right = 0;
                                */

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        /*
                        afterLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('afterLeave');

                                return resolve(true)
                            });
                        },
                        */
                        beforeLeave: () => {
                            return new Promise((resolve) => {

                                /*
                                const appElement = document.getElementById('app');
                                appElement.style.position = "";
                                appElement.style.left = "";
                                appElement.style.right = "";
                                */

                                const learningMenu = document.getElementById('learning-menu');
                                learningMenu.style.display = 'none';

                                const element = document.getElementById('quick-actions');
                                
                                if (element) {
                                    const y = element.getBoundingClientRect().top + window.scrollY;
                                    window.scroll({
                                        top: y - 20,
                                        behavior: 'smooth'
                                    });
                                }

                                const image = new Image();
                                image.src = `${require('../../assets/img/UI/mascot_variant2.png')}`;

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: 'Quick actions',
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                    <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant2.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 7%"></div>
                                        <p class="steps-title">Dashboard Overview</p>
                                        <p class="steps-numbers">1 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Quick Actions. In this section, you can quickly perform essential tasks. 
                                        "Employee Registration" allows you to add new employees. 
                                        "Reporting Excel" lets you generate reports in Excel format. 
                                        "Instruction MerciQ" provides more detailed a guide to using the platform.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>
                            </div>
                        `,
                        target: "#quick-actions",
                        order: 1,       
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                console.log ('afterEnter');

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('afterLeave');

                                /*
                                window.scrollTo({
                                    top: document.body.scrollHeight,
                                    behavior: "smooth",
                                });
                                */
                                
                                const element = document.getElementById('account-balance');
                                
                                const y = element.getBoundingClientRect().top + window.scrollY;
                                window.scroll({
                                    top: y - 20,
                                    behavior: 'smooth'
                                });

                                const image = new Image();
                                image.src = `${require('../../assets/img/UI/mascot_variant3.png')}`;

                                return resolve(true)
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Analytics",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                    <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant3.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 14%"></div>
                                        <p class="steps-title">Analytics</p>
                                        <p class="steps-numbers">2 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Total Earnings shows the total earnings from all employees, 
                                        while Business Account Balance displays the available balance for withdrawal. 
                                        Clicking on these blocks will open a detailed page with more information.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#account-balance",
                        order: 2,
                        afterEnter: () => {
                            return new Promise((resolve) => {

                                const element = document.getElementById('tg-dialog-body');
//                                element.style.display = 'block';

                                element.style.position = 'relative';
                                element.style.top = '-50px';

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('beforeLeave');
                                
                                const element = document.getElementById('average-tip');
                                
                                element.scrollIntoView({block: "end", inline: "nearest"});



    //                            const tgDialogBody = document.getElementById('tg-dialog-body');
    //                            tgDialogBody.style.display = 'none';

                                const image = new Image();
                                image.src = `${require('../../assets/img/UI/mascot_variant1.png')}`;

                                return resolve(true)
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Average tip",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-start mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant1.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 21%"></div>
                                        <p class="steps-title">Additional Analytics</p>
                                        <p class="steps-numbers">3 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Average Tip Value shows the average tip across the business. 
                                        Happy Clients displays the percentage of satisfied clients and reviews. 
                                        Average Rating shows your employees rating. 
                                        Clicking each section gives detailed info and allows sorting by establishments, teams, and employees.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#average-tip",
                        order: 3,
                        afterEnter: () => {
                            return new Promise((resolve) => {

                                const element = document.getElementById('tg-dialog-body');
//                                element.style.display = 'block';

                                element.style.position = 'relative';
                                element.style.top = '30px';

                                assignLearningButton();

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('beforeLeave');                            

                                const element = document.getElementById('analytics-navigation-mob');
                                
                                const y = element.getBoundingClientRect().top + window.scrollY;
                                window.scroll({
                                    top: y,
                                    behavior: 'smooth'
                                });

                                const image = new Image();
                                image.src = `${require('../../assets/img/UI/mascot_variant6.png')}`;

    //                            const tgDialogBody = document.getElementById('tg-dialog-body');
    //                            tgDialogBody.style.display = 'none';

                                return resolve(true)
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Analytics navigation",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant6.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 29%"></div>
                                        <p class="steps-title">Analytics Navigation</p>
                                        <p class="steps-numbers">4 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Use the top tabs to switch between "All Analytics," "Establishments," "Teams," and "Employees." 
                                        Adjust the time period with Day, Week, Month, and Year filters to view specific data periods.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#analytics-navigation-mob",
                        order: 4,
                        afterEnter: () => {
                            return new Promise((resolve) => {

    //                            const element = document.getElementById('tg-dialog-body');
    //                            element.style.display = 'block';

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise((resolve) => {

                                const learningMenu = document.getElementById('learning-menu');
                                learningMenu.style.height = '34px';
                                learningMenu.style.display = 'flex';

                                const y = learningMenu.getBoundingClientRect().top + window.scrollY;
                                window.scroll({
                                    top: y,
                                    behavior: 'smooth'
                                });

                                const image = new Image();
                                image.src = `${require('../../assets/img/UI/mascot_variant7.png')}`;

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Mobile menu",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant7.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 36%"></div>
                                        <p class="steps-title">Menu Navigation</p>
                                        <p class="steps-numbers">5 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        The sidebar menu is fixed on the left in the web. 
                                        In the mobile version, tap the menu icon in the top right corner to access it. 
                                        The menu includes sections like Workspace, Finance History, Statistics, Profile, Dashboard, 
                                        Staff Management, All Establishments, and options for Settings, QR-code, and Payout.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-menu",
                        order: 5,
                        afterEnter: () => {
                            return new Promise((resolve) => {

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('beforeLeave');

                                const mobileMenuHamburger = document.getElementById('mobile-menu-hamburger');
                                mobileMenuHamburger.click();

                                const image = new Image();
                                image.src = `${require('../../assets/img/UI/mascot_variant8.png')}`;

                                return resolve(true)
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Settings",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant8.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 43%"></div>
                                        <p class="steps-title">Settings</p>
                                        <p class="steps-numbers">6 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        In the settings, accessible by the sidebar menu, you can manage various aspects of your account. 
                                        Update business details in "Edit My Business Account." 
                                        Manage notifications in "Notification Settings." 
                                        Change passwords, phone numbers, and emails in "Password and Security." 
                                        Update payment details and linked bank cards in "Payment Information."
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#mobile-menu-learning",
                        order: 6,
                        
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
    //                            const mobileLogin = document.querySelector('.main-menu-mobile .mobile-login');
    //                            mobileLogin.style.display = 'none';

                                const element = document.getElementById('tg-dialog-body');
                                element.style.position = 'relative';
                                element.style.top = '-120px';

//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();
                                    
                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant9.png')}`;

                                        router.push('/establishment/add');
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

        //                        const mobileMenuEstablishment = document.getElementById('mobile-menu-establishment');
        //                        mobileMenuEstablishment.click();

        //                        router.push('/establishment/add');

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Creating an Establishment",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant9.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 50%"></div>
                                        <p class="steps-title">Creating an Establishment</p>
                                        <p class="steps-numbers">7 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Go to the "Add Establishment" tab and click "Add New Establishment." 
                                        Enter the establishment info, then click "Create Establishment." 
                                        The new establishment will be listed for management.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-establishment",
                        order: 7,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
                                const element = document.getElementById('tg-dialog-body');
                                element.style.position = 'relative';
                                element.style.top = '-80px';

//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', async () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant10.png')}`;

                                        let body = {
                                            name: 'The bar',
                                            address: '9 Main st. Big-City, 09090',
                                            users: []
                                        }

                                        let response = async () => {
                                            let query = await fetch(window.origin + '/api' + "/team/establishment/create", {
                                                method: "POST",
                                                headers: {
                                                    'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify(body)
                                            })

                                            let result = await query.json()
                                            
                                            return Promise.resolve(result)
                                        }

                                        const data = await response();
                                        localStorage.setItem('learning-establishment-id', data.id);

                                        router.push('/staff/employeeregistr');

                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

        //                        const mobileMenuEstablishment = document.getElementById('mobile-menu-establishment');
        //                        mobileMenuEstablishment.click();

                                /*
                                let body = {
                                    name: 'The bar',
                                    address: '9 Main st. Big-City, 09090',
                                    users: []
                                }

                                let response = async () => {
                                    let query = await fetch(window.origin + '/api' + "/team/establishment/create", {
                                        method: "POST",
                                        headers: {
                                            'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify(body)
                                    })

                                    let result = await query.json()
                                    
                                    return Promise.resolve(result)
                                }

                                const data = await response();
                                localStorage.setItem('learning-establishment-id', data.id);
                                */

//                                router.push('/staff/employeeregistr');

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Employee Registration",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant10.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 57%"></div>
                                        <p class="steps-title">Employee Registration</p>
                                        <p class="steps-numbers">8 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Go to "Employee Registration" in the Quick Actions or side menu. 
                                        Enter the employee's name, email, and position. 
                                        Review the info and click "Register Employee". 
                                        The employee will now be listed in your dashboard.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-employee-registration",
                        order: 8,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant11.png')}`;

                                        router.push('/establishment/team/' + localStorage.getItem('learning-establishment-id'));
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

        //                        const mobileMenuEstablishment = document.getElementById('mobile-menu-establishment');
        //                        mobileMenuEstablishment.click();

//                                router.push('/establishment/team/' + localStorage.getItem('learning-establishment-id'));

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Creating a Team",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant11.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 64%"></div>
                                        <p class="steps-title">Creating a Team</p>
                                        <p class="steps-numbers">9 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Navigate to "All Establishments" in the sidebar menu. 
                                        Within the establishment's you wish to add a team, click on "Add New Team." 
                                        Provide a name for the team and select the employees to include by clicking on "Add Member." 
                                        After changes done, confirm by clicking "Save."
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-add-new-team",
                        order: 9,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', async () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant12.png')}`;

                                        let response = async() => {
                                            let query = await fetch(window.origin + '/api' + '/team/establishment/' + localStorage.getItem('learning-establishment-id'), {
                                                method: "DELETE",
                                                headers: {
                                                    'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                                                }
                                            })

                                            let result = await query.json()

                                            return Promise.resolve(result)
                                        }

                                        const data = await response();

                                        router.push('/settings/establishments');
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

        //                        const mobileMenuEstablishment = document.getElementById('mobile-menu-establishment');
        //                        mobileMenuEstablishment.click();

//                                router.push('/settings/establishments');

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Managing",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant12.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 71%"></div>
                                        <p class="steps-title">Managing</p>
                                        <p class="steps-numbers">10 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Access the list of all establishments by clicking "All Establishments" from the menu. 
                                        Choose one to manage from the list. 
                                        You can add or manage team and employees or change establishment details.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-establishment-all",
                        order: 10,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
//                                assignLearningButton();
                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant13.png')}`;

                                        router.push('/settings/payment');
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

//                                router.push('/settings/payment');

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Update linked card",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant13.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 78%"></div>
                                        <p class="steps-title">Update linked card</p>
                                        <p class="steps-numbers">11 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        To update the linked card, click "Update linked card." 
                                        Enter the new card number, expiration date, and CVC code. 
                                        Click "Confirm" to save the changes.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-update-payment-info",
                        order: 10,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
                                const element = document.getElementById('tg-dialog-body');
                                element.style.position = 'relative';
                                element.style.top = '-30px';

//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant13.png')}`;

                                        router.push({name: 'payout', query: { learning: true }});
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }


                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

//                                router.push({name: 'payout', query: { learning: true }});
                                
                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Payout",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant14.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 85%"></div>
                                        <p class="steps-title">Payout</p>
                                        <p class="steps-numbers">12 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Withdraw funds with a minimum limit of $10. 
                                        Enter the amount and click "Withdraw" to transfer to your linked debit card. 
                                        You can also transfer money to another MerciQ user by searching for their username and following the steps.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-payout",
                        order: 11,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
                                assignLearningButton();

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

                                store.dispatch('setLoader', true);

                                const image = new Image();
                                image.src = `${require('../../assets/img/UI/mascot_variant15.png')}`;
                                
                                router.push({name: 'main dashboard', query: { learning: true }});

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Full user guide Overview",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant15.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 93%"></div>
                                        <p class="steps-title">Full user guide Overview</p>
                                        <p class="steps-numbers">13 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        This training provided only brief information. 
                                        We highly recommend reviewing the full User Guide manual for a better understanding of account management principles and additional features.
                                    </p>
                                    <div id="learning-view-user-guide" class="m-x-auto mt-2">
                                        <button class="btn"><slot>View user guide</slot></button>
                                    </div>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Continue</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        order: 12,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
                                const element = document.getElementById('tg-dialog-body');
                                element.style.position = 'relative';
                                element.style.top = '0';

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', nextStep);
                                }

                                const learningViewUserGuide = document.getElementById('learning-view-user-guide');
                                if (learningViewUserGuide) {
                                    learningViewUserGuide.addEventListener('click', goToInstruction);
                                }

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: 'Completion of training',
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">
                                    <div class="flex flex-column justify-center align-center mb-2">
                                        <img src="${require('../../assets/img/UI/learning_heart_icon.svg')}" class="pointer header-icon" />
                                        <h3 class="head-title">Thank you for<br>reading</h3>
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 100%"></div>
                                        <p class="steps-title">Completion of training</p>
                                        <p class="steps-numbers">14 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Thank you for completing the training! 
                                        You can now use MerciQ. 
                                        Congratulations on successfully finishing!
                                    </p>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Enjoy MerciQ benefits</slot></button>
                                    </div>
                                </div>      
                            </div>
                        `,
                        order: 13,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                console.log ('afterEnter');

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }
                                
                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    ]);
                
                } else {


                    tgInstance.tg.addSteps([{
                        title: 'Welcome',
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">
                                    <div class="flex flex-column justify-center align-center mb-2">
                                        <img src="${require('../../assets/img/UI/learning_hat_icon.svg')}" class="pointer header-icon" />
                                        <h3 class="head-title">Welcome to<br>training mode</h3>
                                    </div>
                                    <p class="learning-title">
                                        Welcome to the MerciQ Business Profile Guide. 
                                        This guide will help you navigate and utilize the various features of your business account, 
                                        from managing employees and establishments to handling analytics and payouts. 
                                        Follow the steps to make the most out of your MerciQ experience.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>      
                            </div>
                        `,
                        order: 0, // Add to start
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                console.log ('afterEnter');

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        afterLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('afterLeave');


                                return resolve(true)
                            });
                        },
                        group: groupName
                    },
                    {
                        title: 'Quick actions',
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                    <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant2.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 7%"></div>
                                        <p class="steps-title">Dashboard Overview</p>
                                        <p class="steps-numbers">1 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Quick Actions. In this section, you can quickly perform essential tasks. 
                                        "Employee Registration" allows you to add new employees. 
                                        "Reporting Excel" lets you generate reports in Excel format. 
                                        "Instruction MerciQ" provides more detailed a guide to using the platform.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>
                            </div>
                        `,
                        target: "#quick-actions",
                        order: 1,       
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                console.log ('afterEnter');

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        afterLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('afterLeave');

                                /*
                                window.scrollTo({
                                    top: document.body.scrollHeight,
                                    behavior: "smooth",
                                });
                                */

                                return resolve(true)
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Analytics",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                    <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant3.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 14%"></div>
                                        <p class="steps-title">Analytics</p>
                                        <p class="steps-numbers">2 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Total Earnings shows the total earnings from all employees, 
                                        while Business Account Balance displays the available balance for withdrawal. 
                                        Clicking on these blocks will open a detailed page with more information.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#account-balance",
                        order: 2,
                        afterEnter: () => {
                            return new Promise((resolve) => {

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        afterLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('afterLeave');

                                return resolve(true)
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Average tip",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-start mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant1.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 21%"></div>
                                        <p class="steps-title">Additional Analytics</p>
                                        <p class="steps-numbers">3 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Average Tip Value shows the average tip across the business. 
                                        Happy Clients displays the percentage of satisfied clients and reviews. 
                                        Average Rating shows your employees rating. 
                                        Clicking each section gives detailed info and allows sorting by establishments, teams, and employees.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#average-tip",
                        order: 3,
                        afterEnter: () => {
                            return new Promise((resolve) => {

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        afterLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('afterLeave');

                                return resolve(true)
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Analytics navigation",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant6.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 29%"></div>
                                        <p class="steps-title">Analytics Navigation</p>
                                        <p class="steps-numbers">4 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Use the top tabs to switch between "All Analytics," "Establishments," "Teams," and "Employees." 
                                        Adjust the time period with Day, Week, Month, and Year filters to view specific data periods.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#analytics-navigation-desktop",
                        order: 4,
                        afterEnter: () => {
                            return new Promise((resolve) => {

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise((resolve) => {


                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Mobile menu",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant7.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 36%"></div>
                                        <p class="steps-title">Menu Navigation</p>
                                        <p class="steps-numbers">5 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        The sidebar menu is fixed on the left in the web. 
                                        In the mobile version, tap the menu icon in the top right corner to access it. 
                                        The menu includes sections like Workspace, Finance History, Statistics, Profile, Dashboard, 
                                        Staff Management, All Establishments, and options for Settings, QR-code, and Payout.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-menu-desktop",
                        order: 5,
                        afterEnter: () => {
                            return new Promise((resolve) => {

                                assignLearningButton();
                                
                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise((resolve) => {
                                console.log ('beforeLeave');

                                return resolve(true)
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Settings",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant8.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 43%"></div>
                                        <p class="steps-title">Settings</p>
                                        <p class="steps-numbers">6 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        In the settings, accessible by the sidebar menu, you can manage various aspects of your account. 
                                        Update business details in "Edit My Business Account." 
                                        Manage notifications in "Notification Settings." 
                                        Change passwords, phone numbers, and emails in "Password and Security." 
                                        Update payment details and linked bank cards in "Payment Information."
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#desktop-menu-learning",
                        order: 6,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
//                                assignLearningButton();
                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant9.png')}`;

                                        router.push('/establishment/add');
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }


                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

        //                        const mobileMenuEstablishment = document.getElementById('mobile-menu-establishment');
        //                        mobileMenuEstablishment.click();

//                                router.push('/establishment/add');

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Creating an Establishment",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant9.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 50%"></div>
                                        <p class="steps-title">Creating an Establishment</p>
                                        <p class="steps-numbers">7 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Go to the "Add Establishment" tab and click "Add New Establishment." 
                                        Enter the establishment info, then click "Create Establishment." 
                                        The new establishment will be listed for management.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-establishment",
                        order: 7,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', async () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant10.png')}`;

                                        let body = {
                                            name: 'The bar',
                                            address: '9 Main st. Big-City, 09090',
                                            users: []
                                        }

                                        let response = async () => {
                                            let query = await fetch(window.origin + '/api' + "/team/establishment/create", {
                                                method: "POST",
                                                headers: {
                                                    'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify(body)
                                            })

                                            let result = await query.json()
                                            
                                            return Promise.resolve(result)
                                        }

                                        const data = await response();
                                        localStorage.setItem('learning-establishment-id', data.id);

                                        router.push('/staff/employeeregistr');
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

        //                        const mobileMenuEstablishment = document.getElementById('mobile-menu-establishment');
        //                        mobileMenuEstablishment.click();

                                /*
                                let body = {
                                    name: 'The bar',
                                    address: '9 Main st. Big-City, 09090',
                                    users: []
                                }

                                let response = async () => {
                                    let query = await fetch(window.origin + '/api' + "/team/establishment/create", {
                                        method: "POST",
                                        headers: {
                                            'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify(body)
                                    })

                                    let result = await query.json()
                                    
                                    return Promise.resolve(result)
                                }

                                const data = await response();
                                localStorage.setItem('learning-establishment-id', data.id);
                                */

//                                router.push('/staff/employeeregistr');
                                

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Employee Registration",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant10.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 57%"></div>
                                        <p class="steps-title">Employee Registration</p>
                                        <p class="steps-numbers">8 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Go to "Employee Registration" in the Quick Actions or side menu. 
                                        Enter the employee's name, email, and position. 
                                        Review the info and click "Register Employee". 
                                        The employee will now be listed in your dashboard.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-employee-registration",
                        order: 8,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant11.png')}`;

                                        router.push('/establishment/team/' + localStorage.getItem('learning-establishment-id'));
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

        //                        const mobileMenuEstablishment = document.getElementById('mobile-menu-establishment');
        //                        mobileMenuEstablishment.click();

//                                router.push('/establishment/team/' + localStorage.getItem('learning-establishment-id'));

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Creating a Team",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant11.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 64%"></div>
                                        <p class="steps-title">Creating a Team</p>
                                        <p class="steps-numbers">9 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Navigate to "All Establishments" in the sidebar menu. 
                                        Within the establishment's you wish to add a team, click on "Add New Team." 
                                        Provide a name for the team and select the employees to include by clicking on "Add Member." 
                                        After changes done, confirm by clicking "Save."
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-add-new-team",
                        order: 9,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant12.png')}`;

                                        router.push('/settings/establishments');
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

        //                        const mobileMenuEstablishment = document.getElementById('mobile-menu-establishment');
        //                        mobileMenuEstablishment.click();

//                                router.push('/settings/establishments');

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Managing",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant12.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 71%"></div>
                                        <p class="steps-title">Managing</p>
                                        <p class="steps-numbers">10 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Access the list of all establishments by clicking "All Establishments" from the menu. 
                                        Choose one to manage from the list. 
                                        You can add or manage team and employees or change establishment details.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-establishment-all",
                        order: 10,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant13.png')}`;

                                        router.push('/settings/payment');
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

//                                router.push('/settings/payment');

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Update linked card",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant13.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 78%"></div>
                                        <p class="steps-title">Update linked card</p>
                                        <p class="steps-numbers">11 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        To update the linked card, click "Update linked card." 
                                        Enter the new card number, expiration date, and CVC code. 
                                        Click "Confirm" to save the changes.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-update-payment-info",
                        order: 10,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
//                                assignLearningButton();

                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', () => {

                                        store.dispatch('setLoader', true);

//                                        tgInstance.tg.exit();

                                        const image = new Image();
                                        image.src = `${require('../../assets/img/UI/mascot_variant14.png')}`;

                                        router.push({name: 'payout', query: { learning: true }});
                                    });
                                }

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

//                                router.push({name: 'payout', query: { learning: true }});
                                
                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Payout",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant14.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 85%"></div>
                                        <p class="steps-title">Payout</p>
                                        <p class="steps-numbers">12 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Withdraw funds with a minimum limit of $10. 
                                        Enter the amount and click "Withdraw" to transfer to your linked debit card. 
                                        You can also transfer money to another MerciQ user by searching for their username and following the steps.
                                    </p>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Next step</slot></button>
                                    </div>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Skip learning</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        target: "#learning-payout",
                        order: 11,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
                                assignLearningButton();

                                return resolve(true);
                            });
                        },
                        beforeLeave: () => {
                            return new Promise(async (resolve) => {
                                console.log ('beforeLeave');

                                router.push({name: 'main dashboard', query: { learning: true }});

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: "Full user guide Overview",
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">                            
                                <div class="flex justify-end mascot-container">
                                        <img class="mascot-container-img" src="${require('../../assets/img/UI/mascot_variant15.png')}" />
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 93%"></div>
                                        <p class="steps-title">Full user guide Overview</p>
                                        <p class="steps-numbers">13 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        This training provided only brief information. 
                                        We highly recommend reviewing the full User Guide manual for a better understanding of account management principles and additional features.
                                    </p>
                                    <div id="learning-view-user-guide" class="m-x-auto mt-2">
                                        <button class="btn"><slot>View user guide</slot></button>
                                    </div>
                                    <div id="learning-next-step" class="m-x-auto mt-2">
                                        <button class="btn not_active"><slot>Continue</slot></button>
                                    </div>
                                </div>  
                            </div>
                        `,
                        order: 12,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                
                                const nextStepButton = document.getElementById('learning-next-step');
                                if (nextStepButton) {
                                    nextStepButton.addEventListener('click', nextStep);
                                }

                                const learningViewUserGuide = document.getElementById('learning-view-user-guide');
                                if (learningViewUserGuide) {
                                    learningViewUserGuide.addEventListener('click', goToInstruction);
                                }

                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    {
                        title: 'Completion of training',
                        content: `
                            <div class="learning-modal-div flex justify-center align-center">
                                <div class="inner-modal-div-form">
                                    <div class="flex flex-column justify-center align-center mb-2">
                                        <img src="${require('../../assets/img/UI/learning_heart_icon.svg')}" class="pointer header-icon" />
                                        <h3 class="head-title">Thank you for<br>reading</h3>
                                    </div>
                                    <div class="flex align-center learning-steps-container">
                                        <div class="learning-steps-indicator" style="width: 100%"></div>
                                        <p class="steps-title">Completion of training</p>
                                        <p class="steps-numbers">14 / 14</p>
                                    </div>
                                    <p class="learning-title">
                                        Thank you for completing the training! 
                                        You can now use MerciQ. 
                                        Congratulations on successfully finishing!
                                    </p>
                                    <div id="learning-skip" class="m-x-auto mt-2">
                                        <button class="btn"><slot>Enjoy MerciQ benefits</slot></button>
                                    </div>
                                </div>      
                            </div>
                        `,
                        order: 13,
                        afterEnter: () => {
                            return new Promise((resolve) => {
                                console.log ('afterEnter');

                                const learningSkipButton = document.getElementById('learning-skip');
                                if (learningSkipButton) {
                                    learningSkipButton.addEventListener('click', finishTour);
                                }
                                
                                return resolve(true);
                            });
                        },
                        group: groupName
                    },
                    ]);

                }
            }

            return { startTour, isTourFinished, addSteps, setGroupName, username, learning }

        },
        data() {
            return {
                refs: this.$refs.mobile_menu,
                dataUser: {
                    name: '',
                    avatar: '',
                    balance: '',
                    total: '',
                    average_order: '',
                    avarage_rating: '',
                    happy_clients: '',
                    qr_id: '',
                    username: ''
                },
                isActiveQRcode: false,
                isFailVerif: false,
                showVerificationModal: false,
                showLearningModal: false,
                selectNav: '?establishment_id=',
                resources: [],
                day: [],
                selectFilter: '',
                date_filter: ''
            }
        },
        watch: {
            navPanel() {
                if(this.navPanel == '') {
                    this.selectFilter = 0
                    this.selectNav = '?establishment_id='
                    this.getDopData('establishment')
                } else if(this.navPanel == 'Establishments') {
                    this.selectFilter = 0
                    this.selectNav = '?establishment_id='
                    this.getDopData('establishment')
                } else if(this.navPanel == 'Teams') {
                    this.selectFilter = 0
                    this.selectNav = '?team_id='
                    this.getDopData('team')
                } else if(this.navPanel == 'Employees') {
                    this.selectFilter = 0
                    this.selectNav = '?employee='
                    this.getDopData('employee')
                }
            },
            selectFilter() {
                let ert = (this.selectFilter == 0 || this.selectFilter == '') ? '' : this.selectNav + this.selectFilter

                this.getData(ert, this.date_filter).then(data => {
                    let last_name = (data.admin.last_name == null) ? "" : data.admin.last_name

                    this.dataUser.name = data.admin.name + last_name,
                    this.dataUser.avatar = data.admin.avatar
                    this.dataUser.balance = Number(data.admin.balance).toFixed(2)
                    this.dataUser.total = (data.total == null) ? '0' : Number(data.total).toFixed(2)
                    this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                    this.dataUser.avatar =  data.admin.avatar
                    this.dataUser.count =  (data.count == null) ? '0' : data.count
                    this.dataUser.stars = (data.stars == null) ? '0' : Number(Math.round(Number(data.stars))).toFixed(2)

                    this.line_average = (data.stars == null) ? '0' : Number((Math.round(Number(data.stars)) * 100) / 5).toFixed(2)

                })
            }
        },
        methods: {
            ...mapActions({
                setLoader: 'loader/setLoader',
            }),
            navPanelFunc() {
                if (this.navPanel == 'Establishments' || this.navPanel == '') {
                    return 'All establishment' 
                } else if(this.navPanel == 'Teams' || this.navPanel == ''){
                    return 'All teams'
                }  else {
                    return 'All employees'
                }
            },
            getCalendar(value) {
                let date = (value == null || value == undefined) ? null : JSON.stringify({date: value})
                let ert = (this.selectFilter == 0 || this.selectFilter == '') ? '' : this.selectNav + this.selectFilter
                this.date_filter = value
                this.getData(ert, value).then(data => {
                    let last_name = (data.admin.last_name == null) ? "" : data.admin.last_name

                    this.dataUser.name = data.admin.name + last_name,
                    this.dataUser.avatar = data.admin.avatar
                    this.dataUser.balance = Number(data.admin.balance).toFixed(2)
                    this.dataUser.total = (data.total == null) ? '0' : Number(data.total).toFixed(2)
                    this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                    this.dataUser.avatar =  data.admin.avatar
                    this.dataUser.count =  (data.count == null) ? '0' : data.count
                    this.dataUser.stars = (data.stars == null) ? '0' : Math.round(Number(data.stars))
                    

                    this.line_average = (data.stars == null) ? '0' : ((Math.round(Number(data.stars)) * 100) / 5).toFixed(0)

                })
                this.getDateIcon(value)
            },
            updateGetData(value) {
                let ert = (this.selectFilter == 0 || this.selectFilter == '') ? '' : this.selectNav + this.selectFilter
                
                this.getData(ert, value).then(data => {
                let last_name = (data.admin.last_name == null) ? "" : data.admin.last_name

                this.dataUser.name = data.admin.name + last_name,
                this.dataUser.avatar = data.admin.avatar
                this.dataUser.balance = Number(data.admin.balance).toFixed(2)
                this.dataUser.total = (data.total == null) ? '0' : Number(data.total).toFixed(2)
                this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                this.dataUser.avatar =  data.admin.avatar
                this.dataUser.count =  (data.count == null) ? '0' : data.count
                this.dataUser.stars = (data.stars == null) ? '0' : Math.round(Number(data.stars))

                this.line_average = (data.stars == null) ? '0' : ((Math.round(Number(data.stars)) * 100) / 5).toFixed(0)

            })
            },
            delCalendar() {
                let ert = (this.selectFilter == 0 || this.selectFilter == '') ? '' : this.selectNav + this.selectFilter
                
                this.getData(ert, null).then(data => {
                let last_name = (data.admin.last_name == null) ? "" : data.admin.last_name

                this.dataUser.name = data.admin.name + last_name,
                this.dataUser.avatar = data.admin.avatar
                this.dataUser.balance = Number(data.admin.balance).toFixed(2)
                this.dataUser.total = (data.total == null) ? '0' : Number(data.total).toFixed(2)
                this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                this.dataUser.avatar =  data.admin.avatar
                this.dataUser.count =  (data.count == null) ? '0' : data.count
                this.dataUser.stars = (data.stars == null) ? '0' : Math.round(Number(data.stars))

                this.line_average = (data.stars == null) ? '0' : ((Math.round(Number(data.stars)) * 100) / 5).toFixed(0)

            })
                this.delDateIcon()
            },
            setSelectFilter(value, e) {
                this.$refs.select.insertBefore(e.target, this.$refs.select.firstChild)
                this.selectFilter = value
            },
            getEsta() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/nav', {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        },
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }
                return response().then(data => {
                    this.esta = data.establishment
                })
            },
            getDay() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/transaction/days', {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    this.day = data.days.map(el => {
                        if(el == null) return 0
                        else return el
                    })
                })
            },
            getDopData(value) {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/business/resources?res=' + value, {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }
                return response().then(data => {
                    this.resources = data.rows
                })
            },
            closeVerificationModal() {
                this.showVerificationModal = false;
                localStorage.setItem('skipVerificationModal', 'true');
            },            
            goToVerification() {
                this.showVerificationModal = false;
                localStorage.setItem('skipVerificationModal', 'true');
                this.$router.push('/stripe');
            },
            getAvatar(img) {
                this.avatar = img
                console.log(img)
            },
            setActiveQR() {
                this.isActiveQRcode = !this.isActiveQRcode
            },
            updateQr() {
                this.refreshQr().then(data => {
                    this.qr_id = data.qr
                })
            },
            switchLearningModal() {
                this.showLearningModal = !this.showLearningModal;
            },
            isMobile() {
                if( screen.width <= 760 ) {
                    return true;
                }
                else {
                    return false;
                }
            },
            goToInstruction() {
                if (this.isMobile()) {
                    window.location.href='/docs/Merciq_business_mob.pdf';
                } else {
                    window.location.href='/docs/MerciQ_GUIDE_BUSINESS_WEB.pdf';
                }
            }
        },
        beforeMount() {

            this.setLoader(true);

            /*
            if(this.$route.query?.token != null && 
                this.$route.query?.token != undefined && 
                this.$route.query?.token != ''
            ) {
                fetch('/api/logout', {
                    method: 'POST'
                }).then(data => {
                    localStorage.setItem('auth-token', this.$route.query?.token)
                    this.$router.push('/dashboard')
                })
            }
            this.refs =  this.$refs.mobile_menu
            this.getDay()
            this.getDopData('establishment')
        // localStorage.setItem('auth-token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJ0ZXN0VXNlciIsInJvbGUiOiJhZG1pbiIsImJ1c2luZXNzX2lkIjoxLCJlc3RhYmxpc2htZW50X2lkIjpudWxsLCJ0ZWFtX2lkIjpudWxsLCJpYXQiOjE2OTAzNzExMTh9.aAIDmjZtzbEBkjD3KLs2KEcswmxRrV1z_WsLIm-tJqA')
            this.getData().then(data => {
                let last_name = (data.admin.last_name == null) ? "" : data.admin.last_name

                this.dataUser.name = data.admin.name + last_name,
                this.dataUser.avatar = data.admin.avatar
                this.dataUser.balance = Number(data.admin.balance).toFixed(2)
                this.dataUser.total = (data.total == null) ? '0' : Number(data.total).toFixed(2)
                this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                this.dataUser.avatar =  data.admin.avatar
                this.dataUser.count =  (data.count == null) ? '0' : Number(data.count)
                this.dataUser.stars = (data.stars == null) ? '0' : Number(Math.round(Number(data.stars)))
                this.dataUser.username = data.admin.username;

                this.line_average = (data.stars == null) ? '0' : ((Math.round(Number(data.stars)) * 100) / 5).toFixed(0)

            })
            fetch(this.config.API_URL + '/user/stripe/business', {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                }
            }).then(data => data.json()).then(data => {
                let paymentStatus = (data.payments_status_stripe == "Disabled" || data.Business.payouts_status_stripe == "Disabled") ? "Disabled" : "Enabled"           
                let payoutsStatus = (data.payouts_status_stripe == "Disabled" || data.Business.payouts_status_stripe == "Disabled") ? "Disabled" : "Enabled"

                if (payoutsStatus != "Enabled" || payoutsStatus != "Enabled") {
                    this.isFailVerif = true;

                    if (localStorage.getItem('skipVerificationModal') !== 'true' && this.isTourFinished())
                        this.showVerificationModal = true;
                }
            })
            */
        },
        async mounted() {
            // start tour
//            this.switchLearningModal();

            if(this.$route.query?.token != null && 
                this.$route.query?.token != undefined && 
                this.$route.query?.token != ''
            ) {
                fetch('/api/logout', {
                    method: 'POST'
                }).then(data => {
                    localStorage.setItem('auth-token', this.$route.query?.token)
                    this.$router.push('/dashboard')
                })
            }
            this.refs =  this.$refs.mobile_menu
            this.getDay()
            this.getDopData('establishment')
        // localStorage.setItem('auth-token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJ0ZXN0VXNlciIsInJvbGUiOiJhZG1pbiIsImJ1c2luZXNzX2lkIjoxLCJlc3RhYmxpc2htZW50X2lkIjpudWxsLCJ0ZWFtX2lkIjpudWxsLCJpYXQiOjE2OTAzNzExMTh9.aAIDmjZtzbEBkjD3KLs2KEcswmxRrV1z_WsLIm-tJqA')
            await this.getData().then(data => {
                let last_name = (data.admin.last_name == null) ? "" : data.admin.last_name

                this.dataUser.name = data.admin.name + last_name,
                this.dataUser.avatar = data.admin.avatar
                this.dataUser.balance = Number(data.admin.balance).toFixed(2)
                this.dataUser.total = (data.total == null) ? '0' : Number(data.total).toFixed(2)
                this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                this.dataUser.avatar =  data.admin.avatar
                this.dataUser.count =  (data.count == null) ? '0' : Number(data.count)
                this.dataUser.stars = (data.stars == null) ? '0' : Number(Math.round(Number(data.stars)))
                this.dataUser.username = data.admin.username;

                this.line_average = (data.stars == null) ? '0' : ((Math.round(Number(data.stars)) * 100) / 5).toFixed(0)

            })
            
            this.setLoader(false);

            console.log ('userName', this.dataUser.username);
            this.username = this.dataUser.username;

            fetch(this.config.API_URL + '/user/stripe/business', {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                }
            }).then(data => data.json()).then(data => {
                let paymentStatus = (data.payments_status_stripe == "Disabled" || data.Business.payouts_status_stripe == "Disabled") ? "Disabled" : "Enabled"           
                let payoutsStatus = (data.payouts_status_stripe == "Disabled" || data.Business.payouts_status_stripe == "Disabled") ? "Disabled" : "Enabled"

                if (payoutsStatus != "Enabled" || payoutsStatus != "Enabled") {
                    this.isFailVerif = true;

                    if (this.dataUser.username) {
                        const groupName = this.dataUser.username;
                        if (localStorage.getItem('skipVerificationModal') !== 'true' && this.isTourFinished(groupName))
                            this.showVerificationModal = true;
                    } else {
                        const groupName = 'admin';
                        if (localStorage.getItem('skipVerificationModal') !== 'true' && this.isTourFinished(groupName))
                            this.showVerificationModal = true;
                    }
                }
            })

            if (this.dataUser.username) {
                const groupName = this.dataUser.username;
                if (!this.isTourFinished(groupName) && !this.learning) {

                    this.setGroupName(groupName);
                    this.addSteps(groupName);
                    this.startTour(groupName);
                }
            } else {
                const groupName = 'admin';
                if (!this.isTourFinished(groupName) && !this.learning) {
                    
                    this.setGroupName(groupName);
                    this.addSteps(groupName);
                    this.startTour(groupName);
                }
            }
        }        
    }
   
</script>

<style scoped>



    /*
    .tg-backdrop {
        position: absolute;
        align-items: center;
        justify-content: center;
        display: none;
        z-index: 99;
        border-radius: 7px;
        box-shadow: rgba(33, 33, 33, .8) 0 0 1px 2px, rgba(33, 33, 33, .8) 0 0 0 1000vh;
        border: 1px solid rgba(193, 192, 232, .5);
        top: 50vh;
        left: 50vw;
        width: 0;
        height: 0;
        box-shadow: rgba(100, 100, 121, 0.84) 0px 0px 1px 2px, rgba(18, 20, 21, 0.94) 0px 0px 0px 1000vh;    
        display: block;
        
        left: 193.5px;
        width: 0px;
        height: 0px;
    }
    */

    /*
    .tg-backdrop {
        position: fixed;
    }
    */

</style>