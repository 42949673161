import configMixin from './configMixin'

export default {
    mixins: [configMixin],
    data() {
        return {
            userData: {
                email: (localStorage.getItem('userData.email') == "null" || localStorage.getItem('userData.email') == "undefined" || 
                localStorage.getItem('userData.email') == null || localStorage.getItem('userData.email') == undefined) ? '' : localStorage.getItem('userData.email'),
                phone: (localStorage.getItem('userData.phone') == "null" || localStorage.getItem('userData.phone') == "undefined" || 
                localStorage.getItem('userData.phone') == null || localStorage.getItem('userData.phone') == undefined) ? '' : localStorage.getItem('userData.phone'),
                password: localStorage.getItem('userData.password'),
                username: localStorage.getItem('userData.username'),
            },
            establishment: {
                typeBusiness: localStorage.getItem('establishment.typeBusiness'),
                nameOrganization: localStorage.getItem('establishment.nameOrganization'),
                adress: {
                    country: localStorage.getItem('adress.country'),
                    adress: localStorage.getItem('adress.adress'),
                    city: localStorage.getItem('adress.city'),
                    state: localStorage.getItem('adress.state'),
                },
                zipCode: localStorage.getItem('establishment.zipCode'),
                CEO: {
                    name: localStorage.getItem('CEO.name'),
                    occupation: localStorage.getItem('CEO.occupation'),
                    phone: localStorage.getItem('CEO.phone'),
                    email: localStorage.getItem('CEO.ceo_email'),
                },
                public: {
                    desc: localStorage.getItem('public.desc'),
                    phone: localStorage.getItem('public.phone'),
                    website: (localStorage.getItem('public.website') == undefined ||
                    localStorage.getItem('public.website') == null) ? '' :
                    localStorage.getItem('public.website'),
                },
                QRcode: '',
                distribution: '',
                serviceFee: ''
            },
            smsCode: ''
        }
    }, 
    methods: {
        sendApiData(logo) {
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)

            let jsonObject = new FormData()

            let phone = (this.public?.phone == undefined || 
                this.public?.phone == null) ? null : this.public?.phone.split(" ").join("")

            let website = (localStorage.getItem('public.website') == undefined) ? null : localStorage.getItem('public.website')
            
            let logo_form = (logo == undefined || logo == null || logo == '') ? '' : logo.get('file')
            
            jsonObject.append('username', this.userData.username)
            jsonObject.append('desc', localStorage.getItem('public.desc_product'))
            jsonObject.append('name', this.establishment.nameOrganization)
            jsonObject.append('type', this.establishment.typeBusiness)
            jsonObject.append('public_name', localStorage.getItem('public.desc'))
            jsonObject.append('phone', localStorage.getItem('public.phone'))
            jsonObject.append('logo', logo_form)
            jsonObject.append('country', this.establishment.adress.country)
            jsonObject.append('password', this.userData.password)
            jsonObject.append('address', this.establishment.adress.adress)
            jsonObject.append('city', this.establishment.adress.city)
            jsonObject.append('state', this.establishment.adress.state)
            jsonObject.append('zip', this.establishment.zipCode)
            jsonObject.append('distribution', 'Manual distribution')
            jsonObject.append('service_fee', null)
            jsonObject.append('ceo_name', localStorage.getItem('CEO.name'))
            jsonObject.append('ceo_lastname', localStorage.getItem('CEO.lastname'))
            jsonObject.append('ceo_country', localStorage.getItem('CEO.country'))
            jsonObject.append('ceo_city', localStorage.getItem('CEO.city'))
            jsonObject.append('ceo_state', localStorage.getItem('CEO.state'))
            jsonObject.append('ceo_address', localStorage.getItem('CEO.address'))
            jsonObject.append('ceo_birthday', localStorage.getItem('CEO.birthday'))
            jsonObject.append('ceo_occupation', this.establishment.CEO.occupation)
            jsonObject.append('ceo_number', this.establishment.CEO.phone.split(" ").join(""))
            jsonObject.append('ceo_email', this.establishment.CEO.email)
            jsonObject.append('mcc', localStorage.getItem('docs.mcc'))
            jsonObject.append('ssn', localStorage.getItem('docs.ssn'))
            jsonObject.append('tax_id', localStorage.getItem('docs.taxId'))
            jsonObject.append('email', this.userData.email)
            jsonObject.append('website', website)
            jsonObject.append('type_external', localStorage.getItem('CEO.type_external'))
            jsonObject.append('card_month', localStorage.getItem('CEO.external.card_month'))
            jsonObject.append('card_year', localStorage.getItem('CEO.external.card_year'))
            jsonObject.append('holder_name_card', localStorage.getItem('CEO.external.holder_name_card'))
            jsonObject.append('card_number', localStorage.getItem('CEO.external.card_number'))
            jsonObject.append('account_number', localStorage.getItem('CEO.external.account_number'))
            jsonObject.append('name_account', localStorage.getItem('CEO.external.name_account'))
            jsonObject.append('routing_number', localStorage.getItem('CEO.external.routing_number'))
            jsonObject.append('card_token', localStorage.getItem('card_token'))

            if(localStorage.getItem('google_id') != undefined) {
                jsonObject.append('google_id', localStorage.getItem('google_id'))
            } else if(localStorage.getItem('facebook_id') != undefined) {
                jsonObject.append('facebook_id', localStorage.getItem('facebook_id'))
            }

            let responseData = async () => {
                let response = await fetch(this.config.API_URL + '/business', {
                    method: 'POST',
                    body: jsonObject
                 })

                 let codes = await response.json()
                 return Promise.resolve(codes)
            }
            return responseData()
        },
        checkAdminEmail(email) {
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)

            let response = async () => {
                let query = await fetch(this.config.API_URL + '/business/check/admin/' + email, {
                    method: "GET",
                })

                let result = await query.json()

                return Promise.resolve(result) 
            }
            return response()
        },
        checkCEOPhone(phone) {
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)

            let response = async () => {
                let query = await fetch(this.config.API_URL + '/business/check/CEO/phone/' + phone, {
                    method: "GET",
                })

                let result = await query.json()

                return Promise.resolve(result) 
            }
            return response()
        },
        checkEmail(email) {
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)

            let response = async () => {
                let query = await fetch(this.config.API_URL + '/business/check/email/' + email, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result) 
            }
            return response()
        },
        checkPhone(phone) {
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)

            let response = async () => {
                let query = await fetch(this.config.API_URL + '/business/check/phone/' + phone, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result) 
            }
            return response()
        },
        checkCEOEmail(email) {
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)

            let response = async () => {
                let query = await fetch(this.config.API_URL + '/business/check/CEO/email/' + email, {
                    method: "GET",
                })

                let result = await query.json()

                return Promise.resolve(result) 
            }
            return response()
        },
        checkPublicPhone(phone) {
            let csrf = document?.cookie?.match(/_csrf=(.+?)(;|$)/)

            let response = async () => {
                let query = await fetch(this.config.API_URL + '/business/check/public/phone/' + phone, {
                    method: "GET",
                })

                let result = await query.json()

                return Promise.resolve(result) 
            }
            return response()
        },
        updateDistribution(distribution, fee) {
            let object = {
                distribution: distribution,
                fee: null
            }
            if(fee != undefined) object.fee = fee

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/business/distribution', {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(object)
                })

                let result = await query.json()

                return Promise.resolve(result) 
            }
            return response()
        }
    }
}