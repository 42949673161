<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <div class="flex">
                <h3 class="head-title">CHANGE EMAIL</h3>
                <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
            </div>
            <my-input label="New Email" bgColor="#1E1E1E" class="mt-4" v-model="formData.email"/>
            <p class="error-input-text err-au" v-if="isActiveEmailErr">This email is already taken</p>        
            <my-button label="Confirm" :active="isActiveBtn" @click="updateData" class="mt-4"/>
        </div>
    </div>
</template>

<script>
import registrAdminMixin from '@/mixins/registrAdminMixin'

    export default {
        name: 'change-email',
        mixins: [registrAdminMixin],
        data() {
            return {
                isActiveBtn: false,
                formData: {
                    email: ''
                },
                isActiveEmailErr: false
            }
        },
        watch: {
            'formData.email': function() {
                this.checkEmail(this.formData.email).then(data => {
                    if(data.ok === true) {
                        this.isActiveEmailErr = false
                    } else if(data.ok === false) {
                        this.isActiveEmailErr = true
                    }
                    this.isIsset()
                })
            },

        },
        methods: {
            close() {
                localStorage.removeItem('sms-code')
                this.$emit('close')
            },
            
            updateData() {
                if(this.isActiveBtn) {
                    let response = async() => {
                        let body = {
                            email: this.formData.email
                        }

                        let query = await fetch(this.config.API_URL + '/user/update/' + localStorage.getItem('sms-code'), {
                            method: "PUT",
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }

                    return response().then(data => {
                        localStorage.removeItem('sms-code')
                        this.$emit('getdata')
                    })
                }
            },
            isIsset() {
                const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            };
                if(this.formData.email != "" && 
                validateEmail(this.formData.email) &&
                !this.isActiveEmailErr) this.isActiveBtn = true
                else this.isActiveBtn = false
            }
        }
    
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
.close {
    position: absolute;
    margin-left: 94%;
    margin-top: -40px;
}
.inner-modal-div-form {
    background: #171717;
}
.inner-modal-div-form {
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #171717;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-left: 101%;
        position: absolute;
        margin-top: 0;
    }
}
</style>