<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
            <h2>Add new employee</h2>

            <div><p class="error-input-text err-au" v-if="(error != '')" style="text-align: left; 
                margin-bottom: 0px; 
                margin-top: 10px;" v-for="err in error">{{err}}</p></div>

            <my-input label="Name" width="320px" bgColor="#252525" class="mt-2" v-model="formData.name"/>
            <my-input label="Lastname" width="320px" bgColor="#252525" class="mt-2" v-model="formData.last_name"/>
            
            <vue-tel-input wrapperClasses="rg" 
            inputClasses="rtb" class="rg mt-2" 
            ref="inp" 
            style="border: none; height: 47px;outline: none; box-shadow: none; background-color: #252525 !important;"  
            v-model="formData.phone" :style="{'height': '62px'}" 
            validCharactersOnly="true" 
            @validate="phoneObject" 
            @country-changed="setCountryCode"
            :enabledCountryCode="true"
            :enabledFlags="false"
            mode="international" > 
            <template v-slot:arrow-icon>
                </template>
            </vue-tel-input>
            <p class="error-input-text err-au" v-if="isAvtivePhonErr">This phone already exists</p> 
            <my-input label="Email" width="320px" bgColor="#252525" class="mt-2" v-model="formData.email"/>
            <p class="error-input-text err-au" v-if="siActiveEmailErr">This email already exists</p>
            <my-button label="Add" :active="isActive" @click="sendData" class="mt-2"/>
        </div>
    </div>
</template>

<script>
import registrAdminMixin from '@/mixins/registrAdminMixin'
import sendEmployeeRegistrMixin from '@/mixins/sendEmployeeRegistrMixin'
import configMixin from '@/mixins/configMixin'

    export default {
        name: 'add-new-empl',
        mixins: [configMixin, registrAdminMixin, sendEmployeeRegistrMixin],
        data() {
          return {
           isActive: false,   
            formData: {
                email: '',
                phone: '',
                name: '',
                last_name: ''
            },
            isValid: false,
            isValidEmail: false,
            siActiveEmailErr: false,
            isAvtivePhonErr: false,
            error: ''
          }
       },
       props: {
            team_id: String,
           establishment_id: String
       },
       watch: {
        'formData.email': function() {
           this.checkEmail()
           console.log(this.establishment_id)
           console.log(this.team_id)
            
        },
        'formData.phone': function() {
            if(this.formData.phone.length < this.countryCode.length) {
                        this.formData.phone = this.countryCode
                    }
                    this.checkPhone()
            
        },
        'formData.name': function() {
            this.isIsset()
        },
        'formData.last_name': function() {
            this.isIsset()
        },
        countryCode() {
                this.formData.phone = this.countryCode
            }
       },
       methods: {
        changePassword() {
        
            this.$emit('changepassword')
        },
        sendData() {
            if(this.isActive) {
                    this.getDataEmployee(this.formData.name, this.formData.last_name, this.establishment_id, this.formData.email, this.formData.phone.split(" ").join(""), null, "Waiter").then(data => {
                        if(data?.ok == true) {
                            this.$emit("created")
                        } else {
                            if(data?.length > 0) {
                                let err_arr = []

                                err_arr = data.map(el => {
                                    console.log(el)
                                    if(el?.input != undefined) {
                                        
                                        el.input = el.input[0].toUpperCase() + el.input.slice(1);
                                        return el.error.replace('String', el.input)
                                    } else {
                                        return el.error
                                    }
                                })

                                this.error = err_arr
                            } else {
                                this.err_arr = ['An error occurred, please try later']
                            }
                            
                        }
                    })
                }
        },
        setCountryCode(val) {
            this.countryCode = "+" + val.dialCode
        },
        phoneObject(object) {
            this.isValid = object.valid;
            this.isIsset()
        },
        setValue(value, e) {
                this.value = value
                this.$refs.select.insertBefore(e.target, this.$refs.select.firstChild)
                this.getValue()
            },
        setClassSelect(value) {
                this.$refs.select.classList.toggle('closed');
                this.$refs.select.style.marginBottom = '-' + String(this.$refs.select.offsetHeight - 500) + 'px'
            },
        getAvatar(value) {
            this.formData.avatar = value
            this.isImageSet = true
        },
        setClick() {
            this.isSelectImg = !this.isSelectImg
        },
       saveClicked () {
            let img = this.$refs.vueavatar.getImageScaled()
            this.$refs.t_img.src = img.toDataURL()
        },
        checkEmail() {
                this.checkAdminEmail(this.formData.email).then(data => {
                    if(data?.ok === true) {
                        this.siActiveEmailErr = false
                        this.isValidEmail = true

                    } else if(data?.ok === false) {
                        this.siActiveEmailErr = true
                        this.isValidEmail = false
                    }
                    this.isIsset()
                })
            },
            checkPhone() {
                this.checkCEOPhone(this.formData.phone).then(data => {
                    if(data?.ok === true) {
                        this.isAvtivePhonErr = false
                    } else if(data?.ok === false) {
                        this.isAvtivePhonErr = true
                    }
                    this.index++
                    this.isIsset()
                })
            },
        isIsset() {
            const validateEmail = (email) => {
                return String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            };
            this.index++
   
            if( this.formData.email != '' 
            && this.isValidEmail
            && this.formData.phone != '' 
            && this.formData.name != ''
            && this.formData.last_name != '') this.isActive = true
            else this.isActive = false
        },
        getData() {
            if(this.isActive) this.$emit('getdata')
        },
        changePhone() {
            this.$emit('changephone')
        },
        changeEmail() {
            this.$emit('changeemail')
        },
        close() {
            this.$emit('close')
        },
        setUploadPhoto() {
            this.isUploadPhoto = !this.isUploadPhoto
        },
        Notif() {
            this.$emit('notif')
        },
        Security() {
            this.$emit('security')
        },
       },
    
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}

.close {
    position: absolute;
    margin-left: 55%;
    margin-top: -40px;
}
.inner-modal-div-form {
    background: #1E1E1E;
}
.inner-modal-div-form {
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .avatar-empl {
        margin-top: 20px;
    }
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #1E1E1E;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
        top: 50px;
        min-height: 100vh;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-left: 40%;
        position: absolute;
        margin-top: -0px;
    }
    .btnj-short  {
        width: 100%;
    }
    .select-w {
        width: 100% !important;
    }
    .select {
        width: 100%;
    }
}
</style>