<template>
    <div class="container-dash absolute-100-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true}">
            <div class="flex mt-2 mt-5-mobile" style="margin-top: 0 !important">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/settings')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">Business account</h2>     
            </div>
            <div class="main-staff setting-div bg-setting">
                <div class="main-nav-mobile d-none-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex min-panel-nav-setting">
                        <h2 :class="{'ml-4': true, active_nav_panel: true}" @click="$router.push('/settings/public')">Public information</h2>
                        <h2 :class="{ active_nav_panel: false}" @click="$router.push('/settings/type')"  style="margin-left: 20px;">Type of your Business</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': false}" @click="$router.push('/settings/personal')">Personal information</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/settings/financial')">Financial model</h2>
                    </div>
                    <div class="main-line-menu min-panel-nav-setting">
                        <!-- <div class="menu-mobile-nav-line flex min-panel-nav-setting-child">
                            <div :class="{'fr-main-inp-active': true}" class="sec-setting-mobile-1"  style="margin-left: 90px;"></div>
                            <div :class="{'fr-main-inp-active': false}" class="sec-setting-mobile-2"></div>
                            <div :class="{'fr-main-inp-active': false}" class="sec-setting-mobile-2"></div>
                            <div  :class="{'fr-main-inp-active': false}" class="sec-setting-mobile-3"></div>
                        </div> -->
                    </div>
                </div>
               <div class="top-bar-staff  d-none-desc">
                <div class="flex">
                    <div class="second-staff-nav" style="margin-left: 0;">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true}" @click="$router.push('/settings/public')">Public information</p>
                    </div> 
                    <div class="first-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/type')" style="margin-left: 20px;">Type of your Business</p>
                    </div>
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/personal')">Personal information</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/financial')">Financial model</p>
                    </div> 
                </div>
                <div class="border-staff flex border-set">
                    <div :class="{'second-first': true, 'active-nav-staff': true, 'set-thre-nav': true}" style="margin-left: -10px;"></div>
                    <div :class="{'active-first': true, 'active-nav-staff': false,'set-staff-nav': true}" style="margin-left: 100px;"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'set-sec-nav': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'set-fo-nav': true}"></div>
                </div>
               </div>
               <div class="div-height-nulls"></div>
               <div class="warning-info">
                    <p>Important Notice: Making changes in this section may temporarily suspend your ability to receive payments and withdraw funds from your account. 
                        Please proceed with caution.</p>
                </div>
               <div class="logo-setting-mobile mt-2-mobile">
                <div style="width: 320px">
                    <avatar-editor 
                    label="Logo of the Organization" 
                    :srcImg="formData.avatar"
                    @get="getAvatar"
                    />
                </div>
                </div>
                <div class="name_form_setting mt-2 w-320-mob ml-o-mobile">
                    <my-input 
                        label="Public Name Of The Organisation" 
                        v-model="formData.desc" bgColor="#252525"
                        @keydown="codeKeyDown"
                    />

                    <vue-tel-input wrapperClasses="rg" 
                    inputClasses="rtb" class="rg mt-2" 
                    ref="inp" 
                    style="border: none; height: 47px;outline: none; box-shadow: none; background-color: #252525 !important;"  
                    v-model="formData.phone" :style="{'height': '62px'}" 
                    validCharactersOnly="true" 
                    @validate="phoneObject" 
                    @country-changed="setCountryCode"
                    :enabledCountryCode="true"
                    @input="foo"
                    :enabledFlags="false"
                    @keydown="codeKeyDown"
                    mode="international"> 
                    <template v-slot:arrow-icon>
                    </template>
                    </vue-tel-input>  
                    <my-input 
                        label="Website" 
                        bgColor="#252525" 
                        class="mt-2" 
                        v-model="formData.website"
                        @keydown="codeKeyDown"  
                    />
                    <my-button label="Update" :active="isActiveBtn" @click="updateData" style="width: 320px; margin-top: 20px"/>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
    import configMixin from '@/mixins/configMixin'
    import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'public information',
        mixins: [configMixin, loaderMixin],
        data() {
            return {
                isSelectImg: false,
                phone: '',
                imgs: '',
                isValid: false,
                formData: {
                    avatar: '',
                    desc: '',
                    website: '',
                    phone: ''
                },
                now_phone: '',
                logo: '',
                isActiveBtn: false,
                countryCode: '',
                isSelectImg: false,
                imgs: '',
                urls: '',
                rotation: 0,
                scale: 1,
                index: 0,
                blob: ''
            }
        },
        watch: {
            'formData.avatar': function() {
                
                this.isIsset()
            },
            'formData.desc': function() {
                this.isIsset()
            },
            'formData.website': function() {
                this.isIsset()
            },
            'formData.phone': function() {
                if(this.formData.phone.length < this.countryCode.length) {
                    this.formData.phone = this.countryCode
                }
                this.isIsset()
            },
            
        },

        methods: {
            async getAvatar(value) {
                this.blob = value
                this.isIsset()
            },
            setCountryCode(val) {
                this.countryCode = "+" + val.dialCode
            },
            getBusinessData() {
                let response = async() => {

                    let query = await fetch(this.config.API_URL + '/business/data', {
                        method: "GET",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            },
            // getAvatar(value) {
            //     this.formData.avatar = value
            //     //this.isisset()
            // },
            isIsset() {
                this.index++
                if(this.index > 4)this.isActiveBtn = true
            },
            phoneObject(object) {
                this.isValid = object.valid;
                console.log(this.isValid)
            },
            previewFiles(event) {
                this.isSelectImg = true
                let reader  = new FileReader();
                let file = event.target.files[0]
                reader.onload = function(e)  {
                    let image = document.createElement("img");
                    // the result image data
                    image.src = e.target.result;
                    image.style.width = '120px'
                    this.imgs = image
                    document.getElementById('imgr').appendChild(image)
                }
                // you have to declare the file loading
                reader.readAsDataURL(file);

            },
            updateData() {
                
                if(this.isActiveBtn) {

                    let body = new FormData()

                    body.append('phone', this.formData.phone)
                    body.append('desc', this.formData.desc)
                    body.append('website', this.formData.website)

                    
                    if(this.blob != '') {
                        body.append('logo', this.blob.get('file'))
                    }
                    let response = async() => {

                        let query = await fetch(this.config.API_URL + '/business', {
                            method: "PUT",
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                            },
                            body: body
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }
                    response().then(data => {
                        this.$router.go()
                    })
                }
            },
            codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.isActiveBtn) {
                        this.updateData();
                    }
                }
            }
        },
        beforeMount() {
            this.getBusinessData().then(data => {
                this.formData.avatar = '/img/uploads/logo/esta/' + data.data.logo
                this.logo = '/img/uploads/logo/esta/' + data.data.logo
                this.formData.desc = data.data.desc
                this.formData.website = data.data.website
                this.formData.phone = (data.data.phone == null) ? '' : data.data.phone

                this.setLoader(false);
            })
        }
    }
</script>