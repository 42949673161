<template>
    <div class="cale">
        <div class="flex">
            <h3 class="head-title">Edit employee information</h3>
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer sery" @click="close">
        </div>
        <div class="flex f-inp">
            <div class="input">
                <my-input label="Name" class="inp" bgColor="#252525" v-model="formData.name"/>
            </div>
            <div class="input mt-m">
                <my-input label="Username" bgColor="#252525" v-model="formData.login"/>
                <p class="error-input-text err-au" v-if="isUse">This username already exists</p>
            </div>
        </div>

        <div class="flex l-input">
            <span class="floating-label est-labe-for" style="z-index: 8;">Establishment</span>
            <input type="hidden" ref="esta">
            <div class="select" @click="setClassSelect" style="z-index: 7;">
                <ul ref="select" class="closed esta-select">
                    <li :values="item.name" v-for="item in establishment" class="option" @click="e => {changeSelect(1, item.name, e, item.id)}">{{ item.name }}</li>
                </ul>
            </div>
            <img style="z-index: 8;" src="../../assets/img/UI/select-panel-min.svg"  class="select-strel" @click="setClassSelect">
            <!-- <span class="floating-label sec" style="z-index: 6">Employee's email</span> -->
            <input type="hidden">
            
            
            <div class="input mt-m ml-2 ml-0-mobile">
                <my-input  class="mt-2-mobile" label="Email" bgColor="#252525" v-model="formData.email"/>
                <p class="error-input-text err-au" v-if="siActiveEmailErr">This email already exists</p>
            </div>

            <!-- <div class="select ser" @click="setClassSelects" style="z-index: 3">
                <ul ref="selects" class="closed esta-select">
                    <li :values="item" v-for="item in email" class="option" @click="changeSelect(2, item, e)">{{ item }}</li>
                </ul>
            </div>
            <img src="../../assets/img/UI/select-panel-min.svg"  class="select-strel" @click="setClassSelects"> -->
        </div>
        <div class="flex l-input">
            <div class="input">
                    <vue-tel-input wrapperClasses="rg" 
                    inputClasses="rtb" class="rg" 
                    ref="inp" 
                    style="border: 1px solid #252525; height: 47px;outline: none; box-shadow: none; background-color: #252525 !important;"  
                    v-model="formData.phone" :style="{'height': '62px'}" 
                    validCharactersOnly="true" 
                    @validate="phoneObject" 
                    @country-changed="setCountryCode"
                    :enabledCountryCode="true"
                    :enabledFlags="false"
                    mode="international"> 
                        <template v-slot:arrow-icon>
                        </template>
                    </vue-tel-input>  
                    <p class="error-input-text err-au" v-if="isAvtivePhonErr">This phone already exists</p> 
            </div>
            <span class="floating-label sec ss">Team</span>
            <input type="hidden">
            <div class="select ses" @click="setClassSelectss">
                <ul ref="selectss" class="closed esta-select">
                    <li :values="item.name" v-for="item in team" class="option" @click="e => {changeSelect(3, item, e, item.id)}">{{ item.name }}</li>
                </ul>
            </div>
            <img src="../../assets/img/UI/select-panel-min.svg"  class="select-strel" @click="setClassSelectss">
        </div>
        <my-button 
            label="Save" 
            class="mt-4" 
            :active="isActiveBtn" 
            @click="closeAddPanel"
            @keydown="codeKeyDown"
        />
    </div>
</template>

<script>
import setEmployeeMixin from '@/mixins/setEmployeeMixin';
import registrAdminMixin from '@/mixins/registrAdminMixin'
import usernameMixin from '@/mixins/usernameMixin'

export default {
        name: 'modal-edit-employee',
        mixins: [setEmployeeMixin, registrAdminMixin, usernameMixin],
        data() {
            return {
                isActiveBtn: false,
               formData: {
                esta: this.establishment[0],
                email: this.email,
                team: this.team[0],
                name: this.name,
                login: this.login,
                phone: this.phone,
               },
               updateData: {
                esta: '',
                team: '',
               },
               isValid: false,
                countryCode: '',
                isUpdate: false,
                siActiveEmailErr: false,
                isAvtivePhonErr: false,
                index: 0,
                isUse: false,
                establishments: '',
            isActiveEditUserPanel: false,
            teams: '',
            }
        },
        props: {
            name: String,
            login: String,
            establishment: Array,
            email: String,
            phone: String,
            team: Array,
            occupation: String,
            id: String
        },
        watch: {
            index() {
                if(this.index == 1) {
                    this.isAvtivePhonErr = false
                    this.index++
                }
            },
            'updateData.esta': function(){
               // console.log(this.updateData.esta)
            },
           'formData.esta': function() {
            this.setActiveBtn()
           },
           'formData.email': function() {
            this.checkEmail()
           },
           'formData.team': function() {
            this.setActiveBtn()
           },
           'formData.name': function() {
            this.setActiveBtn()
           },
           'formData.login': function() {
            this.isCheckUsername()
           },
           'formData.phone': function() {
            if(this.formData.phone.length < this.countryCode.length) {
                this.formData.phone = this.countryCode
            }
            this.checkPhone()
           },
           'formData.occupation': function() {
            this.setActiveBtn()
           },
           countryCode() {
                this.formData.phone = this.countryCode
               }
        },
        mounted() {
            this.isAvtivePhonErr = false
        },
        methods: {
            setCountryCode(val) {
                this.countryCode = "+" + val.dialCode
            },
            phoneObject(object) {
                this.isValid = object.valid;
                console.log(this.isValid)
            },
            closeAddPanel() {
                if(this.isActiveBtn) {
                    let fullname = this.formData.name.split(" ")
                    let name = fullname[0]

                    let last_name = (fullname[1] == "" || fullname[0] == undefined || fullname[0] == null) ?
                    null : fullname[1]

                    this.setEmployee(this.updateData.esta, this.updateData.team, name, last_name, this.formData.login, this.formData.email, this.formData.phone, this.id)
                    this.$emit('close')
                    window.location.reload();
                }

            },
            close() {
                this.$emit('close')
            },
            checkEmail() {
                this.checkAdminEmail(this.formData.email).then(data => {
                    if(data?.ok === true) {
                        this.siActiveEmailErr = false
                    } else if(data?.ok === false) {
                        this.siActiveEmailErr = true
                    }
                    this.setActiveBtn()
                })
            },
            isCheckUsername() {
                this.getUserName(this.formData.login).then(data => {
                    if(data?.ok == true) {
                        this.isUse = false
                    } else {
                        this.isUse = true
                    }
                    this.setActiveBtn()
                })
           },
            checkPhone() {
                this.checkCEOPhone(this.formData.phone).then(data => {
                    if(data?.ok === true) {
                        this.isAvtivePhonErr = false
                    } else if(data?.ok === false) {
                        this.isAvtivePhonErr = true
                    }
                    this.index++
                    this.setActiveBtn()
                })
            },
            setActiveBtn() {
                this.isUpdate = true
                if(this.isUpdate && !this.siActiveEmailErr && !this.isAvtivePhonErr && !this.isUse) return this.isActiveBtn = true
                else  return this.isActiveBtn = false

            },
            changeSelect(id, value, e, object_id) {
                if(id == 1) {
                    this.formData.esta = value
                    this.$refs.select.insertBefore(e.target, this.$refs.select.firstChild)
                    
                    this.updateData.esta = {
                        name: value,
                        id: object_id
                    }
                }
                if(id == 2) {
                    this.formData.email = value
                    this.$refs.selects.insertBefore(e.target, this.$refs.selects.firstChild)
                }
                if(id == 3) {

                    this.updateData.team = {
                        name: value,
                        id: object_id
                    }

                    this.formData.team = value
                    this.$refs.selectss.insertBefore(e.target, this.$refs.selectss.firstChild)
                }
                console.log(this.updateData.team)
            },
            setClassSelect(value) {
                this.$refs.select.classList.toggle('closed');
                this.$refs.select.style.marginBottom = '-' + String(this.$refs.select.offsetHeight -60) + 'px'
            },
            setClassSelects(value) {
                this.$refs.selects.classList.toggle('closed');
                this.$refs.selects.style.marginBottom = '-' + String(this.$refs.selects.offsetHeight -60) + 'px'
            },
            setClassSelectss(value) {
                this.$refs.selectss.classList.toggle('closed');
                this.$refs.selectss.style.marginBottom = '-' + String(this.$refs.selectss.offsetHeight -60) + 'px'
            },
            codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (isActiveBtn) {
                        this.closeAddPanel();
                    }
                }
            }
        }
    }
</script>

<style scoped>
.ser {
    margin-left: 22px;
}
.floating-label {
    z-index: 2;
	position: absolute;
	pointer-events: none;
    color: white;
	transition: 0.2s ease all;
    font-size: 12px;
    color: #ABABAB;
    left: 48px;
    margin-top: 10px;
}

.sec {
    left: 285px
}
.ss {
    left: 286px
}
.l-input {
    margin-top: 20px;
}
.esta-select, .select ul li.option {
    z-index: 1;
    width: 220px;
    padding-top: 15px;
    padding-bottom: 7px;
    border: none;
    background-color: #252525;
}
.ses {
    margin-left: 19px;
}
.esta-select {
    padding-left: 4px;
}
.select ul li.option {
    width: 216px;
}
.select-strel {
    width: 16px;
    margin-left: -20px;
}
.f-inp {
    margin-top: 31px;
    justify-content: space-between;
}
.input{ 
    width: 220px;
}
img {
    margin-left: 120px;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
}
.cale {
    position: absolute;
    top: 200px;
    left: calc(60% - 408px);
    z-index: 3;
    opacity: 1;
    width: 540px;
    padding: 40px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .cale {
            padding: 20px;
            width: 320px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
   .cale {
    width: 320px;
   }
   .f-inp {
    flex-direction: column;
   }
   .l-input {
    flex-direction: column;
   }
   .select-strel {
    margin-left: auto;
    margin-right: 15px;
    margin-top: -40px;
    z-index: 5;
   }
   .sec {
    margin-top: 50px;
    margin-left: 0;
    position: static;
    margin-bottom: -20px;
    margin-left: 9px;
   }
   .ss {
    margin-top: 25px;
   }
   .ser, .ses {
    margin-left: 0;
   }
   .inp-mobile-mt {
    margin-top: 22px;
   }
   .mt-m {
    margin-top: 20px;
   }
   .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .input {
        width: 284px;
    }
    .esta-select, .select ul li.option {
    z-index: 1;
    width: 285px;
    padding-top: 15px;
    padding-bottom: 7px;
    border: none;
    background-color: #252525;
}
.select ul li.option
 {
        width: 276px;
    }
    .est-labe-for {
        margin-left: -19px;
    }
    .sery {
        margin-left: 0;
    }
}
</style>