<template>
    <div  class="cale">
    <div class="cale-wrapper">
            <div class="flex flex-center">
                <p class="head-title">TERMS & CONDITIONS</p>
            </div>
            <p class="center mt-2">I confirm that I have read and understood the Terms & Conditions of MerciQ, Stripe and information on the treatment of personal data and:
        </p>
            <div class="mt-3">
                <div class="flex" style="align-items: center;">
                    <div class="form_radio">
                        <input id="radio-1" type="radio" @click="setIsCheck"  value="1"  v-model="isCheck" checked>
                        <label for="radio-1"></label>
                    </div>
                    <p class="min-title ml-1">I consent to the processing of my personal data for marketing purposes</p>
                    <img src="../../assets/img/UI/client-right-strel.svg" @click="setActive_1" :class="{'rotate_1': !isActive_1, 'rotate_2': isActive_1}">
                </div>
                    <p class="min-text mt-1 center" v-if="isActive_1">I give my consent to the processing of my personal data for marketing purposes by means of automated telephone calls and assimilated methods, such as e-mail, sms, mms, push notifications, as well as traditional methods such as paper mail and phone calls with operator</p>
            </div>
            <div class="mt-3">
                    <div class="flex" style="align-items: center;">
                        <div class="form_radio">
                            <input id="radio-2" type="radio" @click="setIsCheck"  value="1"  v-model="isCheck" checked>
                            <label for="radio-2"></label>
                        </div>
                        <p class="min-title ml-1">I consent to the processing of my personal data for third-party marketing purposes </p>
                        <img src="../../assets/img/UI/client-right-strel.svg" @click="setActive_2" :class="{'rotate_1': !isActive_2, 'rotate_2': isActive_2}">
                    </div>
                    <p class="min-text mt-1 center" v-if="isActive_2">I consent to the disclosure of my personal data to third party companies operating in the following product sectors: [education and training, banking, finance, insurance, advertising, hospitality] to send commercial communications relating to products, services, events and promotions of these third party companies, including market research, statistical processing and surveys, by automated telephone calls and similar methods, such as e-mail, SMS, MMS, push notifications, as well as traditional methods such as paper mail and telephone calls with an operator</p>
            </div>
            <div class="mt-3" style="align-items: center;">
                    <div class="flex">
                        <div class="form_radio">
                            <input id="radio-3" type="radio" @click="setIsCheck"  value="1"  v-model="isCheck" checked>
                            <label for="radio-3"></label>
                        </div>
                        <p class="min-title ml-1">I consent to the processing of my personal data for profiling purposes.</p>
                    </div>
            </div>
            <div class="mt-3">
                <div class="flex">
                        <div class="form_radio">
                            <input id="radio-3" type="radio" @click="setIsCheck"  value="1"  v-model="isCheck" checked>
                            <label for="radio-3"></label>
                        </div>
                        <p class="min-title ml-1">I accept the <a href="/docs/Terms and Conditions.pdf" target="_blank" class="orange-color">terms and conditions of service</a> & <a href="/docs/Privacy Policy.pdf" target="_blank" class="orange-color">privacy policy</a></p>
                    </div>
            </div>
            <my-button label="Next" active="true" class="mt-4" @click="setOk"/>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'terms',
        data() {
            return {
                isActive_1: false,
                isActive_2: false
            }
        },
        methods: {
            setActive_1() {
                this.isActive_1 = !this.isActive_1
            },
            setActive_2() {
                this.isActive_2 = !this.isActive_2
            },
            setOk() {
                this.$emit('ok')
            }
        },
        mounted() {
            
        }
    }
</script>

<style scoped>
    .rotate_1 {
        transform: rotate(-270deg);
    }
    .rotate_2 {
        transform: rotate(270deg);
    }
    .cale {
        overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
        position: fixed;
        
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 10;
        opacity: 1;
        width: 350px;
        border-radius: 20px;
        top: 0; bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
    }
    .cale-wrapper {
        width: 350px;
        padding: 20px;
        padding-bottom: 40px;
        background-color: #1E1E1E;
        margin: auto;
        border-radius: 20px
        
    }
    .cale::-webkit-scrollbar {
      width: 0;
      height: 0;
}
    .head-title {
        font-size: 24px;
        font-family: 'Rubik-Medium';
        color: var(--main-text-color);
        margin-left: auto;
        margin-right: auto;
    }
    p {
        color: white
    }
    .min-title {
        font-family: 'Rubik-Regular'
    }
    .min-text {
        font-family: "Rubik-Light";
        color: #A5A5A5;
    }
</style>