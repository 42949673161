<template>
    <div class="outer-modal-div-form">
           <div class="middle-modal-div-form">
               <div class="inner-modal-div-form form-div-in" style="text-align: left; margin-left: auto; margin-right: auto">
                   <div class="HeadStroke_father">
                       <div class="HeadStroke white"></div>
                       <div class="HeadStroke white"></div>
                       <div class="HeadStroke white"></div>
                       <div class="HeadStroke grey"></div>
                       <div class="HeadStroke grey"></div>
                   </div>
                       <h3 class="mt-4 center">BANK CARD*</h3>
                   <div class="flex flex-column">
                    <div class="card-template mt-4">
                        <my-input type="card" label="" placeholder="0000 0000 0000 0000 0000" style="border: 1px solid #343434; border-radius: 10px;" maxlength="19" v-model="formData.number" height="46px" class="mt-2"/>
                        
                        <div class="flex">
                            <span class="expo-date-card">
                                <input v-model="formData.date.day" maxlength="2" placeholder="00"/>
                                <span>/</span>
                                <input v-model="formData.date.month" maxlength="2" ref="month" placeholder="00"/>
                            </span>
                            <my-input type="number" placeholder="000" label="" style="border: 1px solid #343434; border-radius: 10px; width: 59px; margin-left: 20px; font-size: 30px;" height="46px" maxlength="3" v-model="formData.cvv" class="mt-2"/>
                            <my-input type="number" placeholder="00000" label="" style="border: 1px solid #343434; border-radius: 10px; width: 81px; margin-left: 47px;" maxlength="5" height="46px" v-model="formData.code" class="mt-2"/>
                        </div>
                    </div>
                    <p class="desc-title-empl mt-2" style="font-size: 12px;">*Fill in your bank card details to receive withdrawals in future. The information will be processed by the payment system (Stripe). MerciQ does not collect  this data </p>       
                       <my-button label="Next" style="width: 320px" :active="isActive" @click="send" class="mt-4"/>
                   </div>
               </div>
           </div>
       </div>
       
   </template>
   
   <script>
import { mapState, mapGetters, mapMutations } from 'vuex'
   
   export default {
       name: 'bank card',
       data() {
          return {
           isActive: false,
           formData: {
              number: '',
              date: {
                day: '',
                month: ''
              },
              cvv: '',
              code: ''
           },
           valid: false
          }
       },
       watch: {
        'formData.number': function() {
            this.isIsset();
        },
        'formData.date.day': function() {
            console.log(this.formData.date.day)
            this.formData.date.day = this.formData.date.day.replace(/[^\d]/g,'');
            if(this.formData.date.day.length == 2) this.$refs.month.focus()
            this.isIsset();
        },
        'formData.date.month': function() {
            console.log(this.formData.date.month)
            this.formData.date.month = this.formData.date.month.replace(/[^\d]/g,'');
            this.isIsset();
        },
        'formData.cvv': function() {
            this.isIsset()
        },
        'formData.code': function() {
            this.isIsset()
        }
       },
       methods: {
           setRepeatPassword(value) {
               this.formData.repeatPassword = value
               this.isIsset()
           },
           isIsset() {
            if(this.formData.number.length == 19 && 
            this.formData.date.day.length == 2 &&
            this.formData.date.month.length == 2 &&
            this.formData.cvv.length == 3 &&
            this.formData.code.length == 5) this.isActive = true
            else this.isActive = false
           },
           send() {
               if(this.isActive) this.$router.push('/employee/bank')
           },
       },
   }
   </script>