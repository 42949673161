<template>
    <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
              
                    <h3>SIGN IN</h3>
                    <change-button label="Email" @click="$router.push('/signin')" class="mt-6"/>
                    <change-button v-if="false" label="Phone" @click="$router.push('/signin/phone')" class="mt-1"/>
                    <!-- <change-button label="WhatsApp" @click="$router.push('/signin')" class="mt-1"/> -->
                    <a :href="domain + '/api/google/auth'"><div class="vbn-s-btn mt-2"><img src="../assets/img/icons/social/_google.svg"> Google</div></a>
                    <!-- <div class="flex mt-4 r-icons">
                        <a :href="domain + '/api/google/auth'"><img src="../assets/img/icons/social/google.png"></a>
                        <a v-if="false" :href="domain + '/api/facebook/callback'"><img src="../assets/img/icons/social/facebook.png"></a>
                    </div> -->
                    <p class="orange-color center mt-2 pointer" @click="$router.push('/')">Back</p>
                    <span class="flex mt-1 flex-center">
                        <p class="main-text-color">New to MerciQ?</p>
                        <RouterLink to="/signupshort"><p class="orange-color ml-2">Sign up</p></RouterLink>
                    </span>
                
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
export default {
    name: 'first sign',
    data() {
       return {
        domain: window.location.origin
       }
    },
    beforeMount() {
        //this.$router.push('/signin')
    }
  
}
</script>
