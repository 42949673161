<template>
   <div class="cale">
        <Calendar v-model="dates" @getcalendar="setDate" @closecalendar="closeCalendar" 
        :selectionMode="(selectionMode == false || selectionMode == undefined) ? 'range' : selectionMode" 
        inline
        :showOtherMonths="true"
        />
   </div>
</template>

<script>
    export default {
        name: 'modal-calendar',
        props: {
            selectionMode: String,
        },
        data() {
            return {
                dates: '',
            }
        },
       watch: {
        dates() {
            console.log(this.dates[1]?.getMonth())
        }
       },
       methods: {
        closeCalendar() {
            this.$emit('closecalendar')
        },
        setDate() {
            this.$emit('setcalendar', this.dates)
        }
       }
    }
</script>

<style>
.p-datepicker {
    border-radius: 20px !important;
}
.p-datepicker-header {
    border-bottom: none !important;
}
.p-datepicker {
    border: none !important;;
    border-radius: 20px;
    background-color: var(--main-bg-color) !important;
}
.p-datepicker-calendar thead  tr th span, .p-datepicker-calendar tbody  tr td span {
    color: var(--other-text-color)
}
.p-datepicker-next {
    color: white
}
.p-datepicker-header, .p-datepicker-month, .p-datepicker-year {
    background-color: var(--main-bg-color) !important;
    color: white !important;
}
.p-datepicker table td.p-datepicker-today > span {
    background-color: var(--main-bg-color) !important;
    color: var(--other-text-color) !important
}
.p-datepicker table td > span.p-highlight {
    background-color: var(--orange-color) !important;
    color: black !important;
    box-shadow: none !important;
}
  span.p-highlight  .p-datepicker table td {
    background-color: var(--orange-color) !important;
    color: black !important;
    box-shadow: none !important;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: var(--other-text-color)
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
    background-color: var(--orange-color) !important;
    color: black !important
}
.cale {
    position: absolute;
    top: 200px;
    left: calc(60% - 408px);
    z-index: 3;
    opacity: 1;
}
.p-datepicker-year, .p-datepicker-month {
    box-shadow: none !important;
    border: none !important;
}
@media screen and (max-width: 500px) {
    .cale {
        width: 296px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
}
</style>

