export const loaderModule = {
  state: {
    showLoader: false,
  },
  getters: {
    getLoader: state => {
      return state.showLoader;
    },
  },
  mutations: {
    changeLoader: (state, payload) => {
      state.showLoader = payload;
    }
  },
  actions: {
    setLoader: async (context, payload) => {
      context.commit('changeLoader', payload);
    }
  },
  namespaced: true
}