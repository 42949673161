<template>

    <modal-calendar  
    @closecalendar="setCalendar" 
    @setcalendar="getDate" 
    style="z-index: 15" 
    selectionMode="single" 
    v-if="DOM.isCalendar"/>

    <div class="outer-modal-div-form" :class="{'calendar-active-cont': DOM.isCalendar}">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form inner-modal-div-form-dop fil-bus">
                <div class="wrapper-inner-modal"> 
                <div class="HeadStroke_father">
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke grey"></div>
                    <div class="HeadStroke grey"></div>
                    <div class="HeadStroke grey"></div>
                </div>
                    <h3 class="mt-4">PERSONAL<br>INFORMATION ABOUT<br>THE ADMINISTRATOR<br>OF THE ORGANISATION</h3>
                
                <div class="flex flex-column" style="margin-left: auto; align-items: left;">
                    <div style="height: 0;"></div>
                    
                    <div class="mt-2" v-if="!isInvidual" style="overflow-y: auto; overflow-x: hidden;text-align: left; text-align: left; z-index: 9;">
                        <select-input-search 
                            width="320px" 
                            :inps="state" 
                            label="State" 
                            @getvalue="State" 
                            style="max-height: 200px; min-height: 59px"
                        />
                    </div>
                    <!-- <div class="flex mt-2"><my-input label="State" style="width: 320px" v-model="formData.State"/></div> -->
                    <div class="mt-2" v-if="!isInvidual" style="overflow-y: auto; overflow-x: hidden;text-align: left; text-align: left; z-index: 8;">
                        <select-input-search 
                            width="320px" 
                            :inps="city" 
                            label="City" 
                            @getvalue="City" 
                        />
                    </div>
                    <div v-if="!isInvidual" style="margin-top: 20px; ">
                        <custom-input 
                            type="text" 
                            label="Address" 
                            style="width: 320px" 
                            v-model="formData.Adress" 
                            class="w-320-mob"
                            ref="addressInput"
                            @blur="addressValidate"  
                        />
                    </div>
                    <p class="error-input-text err-au" v-if="addressError?.length > 0">{{ addressError }}</p>
                    <div style="margin-top: 20px; margin-left: auto; margin-right: auto">
                        <custom-input 
                            v-if="!isInvidual" 
                            type="text" 
                            label="First name of the CEO/Representative" 
                            style="width: 320px" 
                            v-model="formData.name" 
                            class="w-320-mob"
                            @blur="firstNameValidate"
                            ref="firstnameInput"
                        />
                        <p class="error-input-text err-au" v-if="!isInvidual && firstnameError?.length > 0">{{ firstnameError }}</p>
                    </div>
                    <div style="margin-top: 20px; margin-left: auto; margin-right: auto">
                        <custom-input 
                            v-if="!isInvidual" 
                            label="Last name of the CEO/Representative" 
                            style="width: 320px; margin-left: auto; margin-right: auto" 
                            v-model="formData.lastname" 
                            class="w-320-mob"
                            @blur="lastNameValidate"
                            ref="lastnameInput"
                        />
                        <p class="error-input-text err-au" v-if="!isInvidual && lastnameError?.length > 0">{{ lastnameError }}</p>
                        
                        <custom-input 
                            type="date" 
                            label="Date of Birth" 
                            style="width: 320px;outline: none;" 
                            v-model="formData.birthDay" 
                            class="w-320-mob mt-2"
                            @blur="birthdayValidate"
                            ref="birthdayInput"
                        />
                    </div>
                    
                    <p class="error-input-text err-au" v-if="isOld">Please enter a valid date</p>
                    <p class="error-input-text err-au" v-if="isNewYear">You must be over 18 years old</p>
                    <custom-input 
                        name="occupation"
                        type="text" 
                        label="Title (position)" 
                        style="width: 320px; margin-left: auto; margin-right: auto" 
                        v-model="formData.occupation" 
                        class="mt-2"
                        @blur="occupationValidate"
                        ref="occupationInput"
                    />
                    <p class="error-input-text err-au" v-if="occupationError?.length > 0">{{ occupationError }}</p>
                    
                    <vue-tel-input 
                        wrapperClasses="rg" 
                        inputClasses="rtb" class="rg mt-2" 
                        style="z-index: 10;;border: none; height: 47px;outline: none; box-shadow: none; background-color: #1E1E1E !important; width: 320px; margin-left: auto; margin-right: auto"  
                        v-model="formData.phone" :style="{'height': '62px'}" 
                        validCharactersOnly="true" 
                        @validate="phoneObject" 
                        @country-changed="setCountryCode"
                        :enabledCountryCode="true"
                        @input="foo"
                        @blur="phoneValidate"
                        :enabledFlags="false"
                        defaultCountry="US"
                        :styleClasses="phoneInputClasses"
                        :onlyCountries="['US']"
                        mode="international"> 
                            <template v-slot:arrow-icon>
                            </template>
                    </vue-tel-input>  
                    <p class="error-input-text err-au" v-if="isAvtivePhonErr">This phone already exists</p>
                    <p class="error-input-text err-au" v-if="phoneError?.length > 0">{{ phoneError }}</p>
                    
                    <custom-input 
                        name="email"
                        type="text" 
                        label="Contact e-mail" 
                        v-model="formData.email" 
                        class="mt-2" 
                        style="width: 320px; margin-left: auto; margin-right: auto"
                        ref="emailInput"
                        @blur="emailValidate"
                    />                    
                    <p class="error-input-text err-au" v-if="isActiveEmailErr">This email already exists</p>
                    <p class="error-input-text err-au" v-if="emailError?.length > 0">{{ emailError }}</p>

                    <!--
                    <p style="color: white; text-align: left;" class="mt-2"></p>                                        
                        <div id="test" class="mt-1"></div>
                    -->

                    

                        <h3 class="mt-4">PROVIDE INFORMATION FOR PAYOUTS</h3>
                        <p class="mt-2 mb-2 main-text-color">
                            To withdraw funds, provide a debit card for payout or link a Bank account
                        </p>

                        <div class="card-form-wrapper">

                        <div class="card-form" :style="{filter: skipCardForm ? 'blur(0.5rem)' : 'blur(0rem)'}">

                        <div class="flex payout-tabs">
                            <custom-tab label="LINK BANK CARD" @click="onBankCardTabClick" :active="bankCardTabActive" class="mt-2 choose-button" />
                            <custom-tab label="LINK BANK ACCOUNT" @click="onBankAccountTabClick" :active="bankAccountTabActive" class="mt-2 choose-button" />
                        </div>

                       

                            <div class="card-form-inner" v-show="bankCardTabActive">

                                <div class="user-input-wrp" style="width: 320px; margin-left: auto; margin-right: auto">
                                    <div class="field"
                                        @focus="onFocus"
                                        @blur="onBlur"
                                        @keyup="onKeyup"
                                    >
                                        <div 
                                            id="card-number" 
                                            class="m-stripe-input empty"                                 
                                        >
                                        </div>
                                        <span class="stripe-floating-label">Card number</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="user-input-wrp mt-2 left half-width">
                                        <div class="field"
                                            @focus="onFocus"
                                            @blur="onBlur"
                                            @keyup="onKeyup"
                                        >
                                            <div 
                                                id="card-expiry" 
                                                class="m-stripe-input empty" 
                                                
                                            >
                                            </div>
                                            <span class="stripe-floating-label">Exp date</span>
                                        </div>
                                    </div>

                                    <div class="user-input-wrp mt-2 right half-width">
                                        <div class="field"
                                            @focus="onFocus"
                                            @blur="onBlur"
                                            @keyup="onKeyup"
                                        >
                                            <div 
                                                id="card-cvc" 
                                                class="m-stripe-input empty" 
                                                
                                            >
                                            </div>
                                            <span class="stripe-floating-label">CVC</span>
                                        </div>
                                    </div>
                                </div>

                                <div style="display:flex; align-items: center; margin-top: 22px;">
                                    <img src="../assets/img/UI/guaranteed_safe_and_secure.png" style="width: 60%;">
                                    <img src="../assets/img/UI/powered_by_stripe.jpg" style="width: 38%;cursor: pointer;" @click="goToStripe">
                                </div>

                            </div>

                            <div class="card-form-inner" v-show="bankAccountTabActive">

                                <div style="">
                                    <custom-input 
                                        type="text" 
                                        label="Account holder name" 
                                        style="width: 320px;margin-left: auto; margin-right: auto;" 
                                        v-model="formData.external.nameAccount" 
                                        class="w-320-mob"
                                        @blur="accountHolderNameValidate"
                                        ref="accountHolderNameInput"
                                    />
                                    <p class="error-input-text err-au" v-if="accountHolderNameError?.length > 0">{{ accountHolderNameError }}</p>
                                </div>

                                <div style="margin-top: 20px;">
                                    <custom-input 
                                        type="text" 
                                        label="Routing number" 
                                        style="width: 320px;margin-left: auto; margin-right: auto;" 
                                        v-model="formData.external.routing_number" 
                                        class="w-320-mob"
                                        @blur="routingNumberValidate"
                                        ref="routingNumberInput"
                                    />
                                    <p class="error-input-text err-au" v-if="routingNumberError?.length > 0">{{ routingNumberError }}</p>
                                </div>

                                <div style="margin-top: 20px;">
                                    <custom-input 
                                        type="text" 
                                        label="Account number" 
                                        style="width: 320px;margin-left: auto; margin-right: auto;" 
                                        v-model="formData.external.accountNumber" 
                                        class="w-320-mob"
                                        @blur="accountNumberValidate"
                                        ref="accountNumberInput"
                                    />
                                    <p class="error-input-text err-au" v-if="accountNumberError?.length > 0">{{ accountNumberError }}</p>
                                </div>

                                <div style="display:flex; align-items: center; margin-top: 22px;">
                                    <img src="../assets/img/UI/guaranteed_safe_and_secure.png" style="width: 60%;">
                                    <img src="../assets/img/UI/powered_by_stripe.jpg" style="width: 38%;cursor: pointer;" @click="goToStripe">
                                </div>

                                </div>

                            </div>

                        <p class="mt-1 center" :style="{position: skipCardForm ? 'absolute' : 'relative', top: skipCardForm ? '100px' : 'auto'}">
                            <a 
                                v-if="!skipCardForm" 
                                class="orange-color" 
                                style="font-size: 15px;" 
                                href="" 
                                @click="skipAddingCard"
                            >
                                Or skip that & add card later by clicking here
                            </a>
                            <a 
                                v-else
                                class="orange-color" 
                                style="font-size: 15px;" 
                                href="" 
                                @click="skipAddingCard"
                            >
                                <span>You chose to fill in your card later thru the<br>"payment information" section in the settings</span>
                                <br>If you want to fill in now, click here
                            </a>
                        </p>    

                    </div>

                    <p class="error-input-text err-au" v-if="DOM.isErrorCard">Invalid debit card</p>
                    <div>
                    </div>
                    <p class="mt-2 center">
                        <a class="orange-color" style="font-size: 15px;" href="/docs/reg guide.pdf">
                            NEED HELP WITH THE FORM?<br>CLICK HERE FOR THE GUIDE
                        </a>
                    </p>
                    <my-button label="Next" :active="true" @click="sends" class="mt-2"/>
                </div>  
                </div>     
            </div>
        </div>
    </div>
    
</template>
<script>
import registrAdminMixin from '@/mixins/registrAdminMixin'
import { PUBLISHABLE_KEY } from '@/assets/constants/stripe'

export default {
    name: 'FillingBusiness2',
    mixins: [registrAdminMixin],
    data() {
        return {
            timer: {minute: 1, seconds: 30},
            isResend: true,
            isInvidual: false,
            formData: {
                NameOrganisation: '',
                Country: 'United States',
                Adress: '',
                City: '',
                State: '',
                ZipCode: '',
                name: '',
                lastname: '',
                phone: '',
                email: '',
                occupation: '',
                birthDay: this.getDate(),
                type: 'account',
                external: {
                    cardMonth: '' ,
                    cardYear: '',
                    holderNameCard: '',
                    cardNumber: '',
                    accountNumber: '',
                    typeAccount: '',
                    nameAccount: '',
                    routing_number: ''
                },
            },
            DOM: {
                isCalendar: false,
                isErrorCard: false,
                cErr: false
            },
            stripe: PUBLISHABLE_KEY,
            cardEl: '',
            cardExpiry: '',
            cardCvc: '',
            isOld: false,
            isNewYear: false,
            country: [],
            state: [],
            city: [],
            phone: '',
            isValid: false,
            countryCode: '',
            isAvtivePhonErr: false,
            cardToken: '',
            isActiveEmailErr: false,
            cardNumberCompleted: false,
            cardExpDateCompleted: false,
            cardCvcCompleted: false,
            firstnameError: '',
            lastnameError: '',
            emailError: '',
            phoneValid: false,
            phoneError: '',
            occupationError: '',
            phoneInputClasses: '',
            addressError: '',
            routingNumberError: '',
            accountNumberError: '',
            accountHolderNameError: '',
            bankCardTabActive: true,
            bankAccountTabActive: false,
            skipCardForm: false
        }
    },
    watch: {
        'formData.occupation': function(){
            this.sendData()
        },
        'formData.birthDay': function(){

            let nowDate = new Date(); // Current date now.
          
            let lastDate = new Date(this.formData.birthDay); // Start of 2010.
            let d = ((nowDate-lastDate) / 1000 / (60 * 60 * 24) / 365.25).toFixed(0)
            if(d > 120) this.isOld = true 
            else this.isOld = false 

            if(d < 18) this.isNewYear = true 
            else this.isNewYear = false 

            this.sendData()
        },
        'formData.ZipCode': function(){
            this.sendData()
        },
        'formData.name': function(){
            this.sendData()
        },
        'formData.lasname': function(){
            this.sendData()
        },
        'formData.external.nameAccount': function(){
            this.sendData()
        },
        'formData.external.routing_number': function(){
            this.sendData()
        },
        'formData.external.accountNumber': function(){
            this.sendData()
        },
        'formData.phone': function(){
            if(this.formData.phone.length < this.countryCode.length) {
                        this.formData.phone = this.countryCode
                    }
            this.checkCEOPhone(this.formData.phone).then(data => {
                if(data.ok === true) {
                    this.isAvtivePhonErr = false
                    this.isValid = true
                } else if(data.ok === false) {
                    this.isAvtivePhonErr = true
                    this.isValid = false
                } else {
                    this.isValid = false
                }
                this.sendData()
            })
        },
        'formData.email': function(){

            this.checkCEOEmail(this.formData.email).then(data => {
                if(data.ok === true) {
                    this.isActiveEmailErr = false
                } else if(data.ok === false) {
                    this.isActiveEmailErr = true
                }
                this.sendData()
            })

        },
        "formData.external": {
            handler() {
                this.sendData()
               
            },
            deep: true
        },
        countryCode() {
            this.formData.phone = '+1'
        },
        cardToken() {
            this.sendData()
        },
    },
    methods: {

        setCalendar() {
            this.DOM.isCalendar = !this.DOM.isCalendar
        },
        getDate(value) {
            this.formData.birthDay = value.getDate() + "-" +(value.getMonth() + 1) + "-" + value.getFullYear()
            this.setCalendar()
        },
        setCountryCode(val) {
            this.countryCode = "+" + val.dialCode
        },
        phoneObject(object) {
            console.log('phoneObject valid', object.valid);
            if (!object.valid)
                this.phoneValid = false;
            else 
                this.phoneValid = true;
        },
        Country(value) {
            this.formData.Country = value
            this.sendData()
        },
        Adress(value) {
            this.formData.Adress = value
            this.sendData()
        },
        City(value) {
            this.formData.City = value
            
        },
        State(value) {
            this.formData.State = value
            this.sendData()
        },
        ZipCode(value) {
            this.formData.ZipCode = value
            this.sendData()
        },
        async makeToken() {
            
            if (this.bankCardTabActive) {

                await this.stripe.createToken(this.cardEl, {
                    currency: "usd"
                }).then((result) => {
                    console.log(result);
                    if (result?.token?.id != undefined) {
                        
                        this.cardToken = result?.token?.id
                        localStorage.setItem('card_token', this.cardToken)
                        this.DOM.cErr = false
                        if(this.isResend) {
                            // Small delay to prevent skype hook error
                            setTimeout(() => {
                                this.$router.push('/FillingBusiness3')
                            }, 600);
                        }
                    } else {
                        this.DOM.cErr = true
                    }
                });
            } else if (this.bankAccountTabActive) {

                await this.stripe.createToken('bank_account', {
                        country: 'US',
                        currency: 'usd',
                        routing_number: this.formData.external.routing_number,
                        account_number: this.formData.external.accountNumber,
                        account_holder_name: this.formData.external.nameAccount,
                        account_holder_type: 'individual',
                    }).then((result) => {
                    console.log(result);
                    if (result?.token?.id != undefined) {
                        
                        this.cardToken = result?.token?.id
                        localStorage.setItem('card_token', this.cardToken)
                        this.DOM.cErr = false
                        if(this.isResend) {
                            // Small delay to prevent skype hook error
                            setTimeout(() => {
                                this.$router.push('/FillingBusiness3')
                            }, 600);
                        }
                    } else {
                        this.DOM.cErr = true
                    }
                });
            }
        },
        sendData() {
            console.log('send data');
            const validateEmail = (email) => {
                return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            };

            let cardValidate = () => {
                if (String(this.formData.cardMonth).trim() == '') return false
        
                if (String(this.formData.external.cardYear).trim() == '') return false
            
                if (String(this.formData.external.cardNumber).trim().split(" ").join("").length != 16) return false
            
                if (this.formData.external.holderNameCard.trim() == "") return false 
 
                return true
            }

            let accountValidate = () => {
                
                if (this.formData.external.accountNumber.length > 15 &&
                this.formData.external.accountNumber.length < 9) return false
                
                if (this.formData.external.nameAccount.trim() == "") return false
                
                if (this.formData.external.routing_number.trim().length != 9) return false

                return true
            }

            const isEmailValid = validateEmail(this.formData.email);

            if (this.isInvidual) {                
                this.formData.name = localStorage.getItem('CEO.name');
                this.formData.lastname = localStorage.getItem('CEO.lastname');
            }

            const bankCardValid = this.bankCardTabActive &&
                this.cardNumberCompleted &&
                this.cardExpDateCompleted &&
                this.cardCvcCompleted;

            const bankAccountValid = this.bankAccountTabActive &&
                this.accountHolderNameValidate() &&
                this.routingNumberValidate() &&
                this.accountNumberValidate();

            if(
                isEmailValid && 
            !this.isAvtivePhonErr && 
            !this.isActiveEmailErr && 
            this.isValid == true &&
            this.formData.lastname.trim() != '' &&
            this.formData.name.trim() != '' &&
            this.formData.occupation.trim() != '' &&
            this.formData.phone.trim() != '' &&  this.formData.birthDay.trim() != '' && 
            !this.isOld && !this.isNewYear &&
            (bankCardValid || bankAccountValid)
            ) {
                this.makeToken()
            }
            if(
                isEmailValid && 
            !this.isAvtivePhonErr && 
            !this.isActiveEmailErr && 
            this.isValid == true &&
            this.formData.lastname.trim() != '' &&
            this.formData.name.trim() != '' &&
            this.formData.occupation.trim() != '' &&
            this.formData.phone.trim() != '' &&  this.formData.birthDay.trim() != '' && 
            !this.isOld && !this.isNewYear && !this.DOM.cErr &&
            (bankCardValid || bankAccountValid || this.skipCardForm)) 
            {
                console.log('validateSuccess');
                this.isResend = true
            } else {
                console.log('validateFailed');
                this.isResend = false
            }
            if(this.isResend ) {
                localStorage.setItem('CEO.name', this.formData.name)
                localStorage.setItem('CEO.lastname', this.formData.lastname)
                localStorage.setItem('CEO.occupation', this.formData.occupation)
                localStorage.setItem('CEO.country', this.formData.Country)
                localStorage.setItem('CEO.address', this.formData.Adress)
                localStorage.setItem('CEO.state', this.formData.State)
                localStorage.setItem('CEO.phone', this.formData.phone)
                localStorage.setItem('CEO.ceo_email', this.formData.email)
                localStorage.setItem('CEO.city', this.formData.City)
                localStorage.setItem('CEO.birthday', this.formData.birthDay)
                localStorage.setItem('CEO.type_external', this.formData.type)
                localStorage.setItem('CEO.external.card_month', this.formData.external.cardMonth)
                localStorage.setItem('CEO.external.card_year', '20' + this.formData.external.cardYear)
                localStorage.setItem('CEO.external.holder_name_card', this.formData.external.holderNameCard)
                localStorage.setItem('CEO.external.card_number', this.formData.external.cardNumber.split(" ").join(""))
                localStorage.setItem('CEO.external.account_number', this.formData.external.accountNumber)
                localStorage.setItem('CEO.external.name_account', this.formData.external.nameAccount)
                localStorage.setItem('CEO.external.routing_number', this.formData.external.routing_number.trim())

                if (this.skipCardForm)
                    localStorage.setItem('card_token', '');
                else
                    localStorage.setItem('card_token', this.cardToken);
                
            }
            
        },
        getDate() {
                let year = new Date().getFullYear()
                let month = (new Date().getMonth() + 1 < 10) ? "0" + String(new Date().getMonth() + 1) : new Date().getMonth() + 1
                let date = (new Date().getDate() < 10) ? "0" + String(new Date().getDate()) : new Date().getDate()

                return year + "-" + month + "-" + date
            },

            goToStripe() {
                window.location.href = "https://stripe.com/";
            },
            skipAddingCard(event) {
                event.preventDefault();

                this.skipCardForm = !this.skipCardForm;

                this.sendData();
            },

        async sends() {

            if (!this.isInvidual) 
                this.firstNameValidate();
            if (!this.isInvidual) 
                this.lastNameValidate();

            this.birthdayValidate();
            this.occupationValidate();
            this.emailValidate();
            this.phoneValidate();            
            
            if (this.bankAccountTabActive) {
                this.routingNumberValidate();
                this.accountNumberValidate();
                this.accountHolderNameValidate();
            }

            if (this.isResend) {

                if (this.skipCardForm) {

                    localStorage.setItem('card_token', '');
                    this.DOM.cErr = false;
                    this.$router.push('/FillingBusiness3');
                } else {
                    await this.makeToken();
                }
            }                
            
        },
        onFocus(target) {
            target.classList.add('focused');
        },
        onBlur(target) {
            target.classList.remove('focused');
        },
        onKeyup(target) {
            if (target.value.length === 0) {
                target.classList.add('empty');
            } else {
                target.classList.remove('empty');
            }
        },
        addressValidate() {
            this.validateTextInput('addressInput', 'Adress', 'addressError', 'Please enter your address');
        },
        firstNameValidate(event) {
            this.validateTextInput('firstnameInput', 'name', 'firstnameError', 'Please enter your CEO firstname');
        },
        lastNameValidate(event) {
            this.validateTextInput('lastnameInput', 'lastname', 'lastnameError', 'Please enter your CEO lastname');
        },
        birthdayValidate() {
            let nowDate = new Date(); // Current date now.
            
            let lastDate = new Date(this.formData.birthDay); // Start of 2010.
            let d = ((nowDate-lastDate) / 1000 / (60 * 60 * 24) / 365.25).toFixed(0)
            if(d > 120) 
                this.isOld = true 
            else 
                this.isOld = false 

            if(d < 18) 
                this.isNewYear = true 
            else 
                this.isNewYear = false 


            if (this.isNewYear || this.isOld)
                this.$refs.birthdayInput.setError(true);
            else
                this.$refs.birthdayInput.setError(false);

        },
        occupationValidate() {
            const inputValue = this.formData.occupation;
            const validationResult = this.validateText(inputValue);
            
            if (validationResult) {
                this.occupationError = '';
                this.$refs.occupationInput.setError(false);
            } else {
                this.occupationError = 'Please enter your correct job position';
                this.$refs.occupationInput.setError(true);
            }
        },
        phoneValidate() {
            if (this.phoneValid) {
                this.phoneError = '';
                this.phoneInputClasses = '';
            } else { 
                this.phoneError = "Please enter your correct phone number";
                this.phoneInputClasses = 'phone-error-input';
            }
        },
        emailValidate() {
            const isEmailValid = this.validateEmail(this.formData.email);
            
            if (isEmailValid) {
                this.emailError = '';
                this.$refs.emailInput.setError(false);
            } else {
                this.emailError = "Please enter your correct email address";
                this.$refs.emailInput.setError(true);
            }

        },
        validateTextInput(refKey, formDataKey, errorKey, errorText) {
            const inputValue = this.formData[formDataKey];
            
            const validationResult = this.validateText(inputValue);
            
            if (validationResult) {
                this[errorKey] = '';
                this.$refs[refKey].setError(false);
            } else {
                this[errorKey] = errorText;
                this.$refs[refKey].setError(true);
            }
        },
        validateText(value) {
            return value.length > 0;
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        routingNumberValidate() {
            
            const validationError = this.formData.external.routing_number.trim().length != 9;
            
            if (validationError) {
                this.routingNumberError = 'Please enter correct routing number';
                this.$refs.routingNumberInput.setError(true);
                return false;
            } else {
                this.routingNumberError = '';
                this.$refs.routingNumberInput.setError(false);
                return true;
            }
        },
        accountNumberValidate() {

            const validationError = this.formData.external.accountNumber.length > 15 ||
                this.formData.external.accountNumber.length < 9;
            
            console.log ('validationError', validationError);
            if (validationError) {
                this.accountNumberError = 'Please enter correct account number';
                this.$refs.accountNumberInput.setError(true);
                return false;
            } else {
                this.accountNumberError = '';
                this.$refs.accountNumberInput.setError(false);
                return true;
            }
        },
        accountHolderNameValidate() {
         
            const validationResult = this.validateText(this.formData.external.nameAccount);

            if (validationResult) {
                this.accountHolderNameError = '';
                this.$refs.accountHolderNameInput.setError(false);
                return true;
            } else {
                this.accountHolderNameError = 'Please enter account holder name';
                this.$refs.accountHolderNameInput.setError(true);
                return false;
            }
        },
        onBankCardTabClick() {
            this.bankCardTabActive = true;
            this.bankAccountTabActive = false;
        },
        onBankAccountTabClick() {
            this.bankAccountTabActive = true;
            this.bankCardTabActive = false;
        }
    },
    mounted() {
        this.stripe = window.Stripe(this.stripe);
            
            const appearance = {
            theme: 'day',
            
            };

            const options = {
                appearance,
                fonts: [
                    {
                    // integrate your font into stripe
                    cssSrc: 'https://fonts.googleapis.com/css?family=Rubik:300,400,500',
                    fontSize: "40px"
                    }
                ]                
            };
            /*
            const stripeStyle = {
                style: {
                    base: {
                        fontFamily: 'Rubik-Light, sans-serif',
                        color: "#ABABAB",
                        fontSize: "16px"
                    },
                },
                hidePostalCode: true
            }
            */


            const elementStyles = {
                base: {
                    color: 'white',
                    fontWeight: 300,
                    fontFamily: 'Rubik-Light, Consolas, Menlo, monospace',
                    fontSize: '15px',
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                        color: '#ABABAB',
                    },
                    ':-webkit-autofill': {
                        color: '#e39f48',
                    },
                }
                /*
                invalid: {
                    color: '#E25950',
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
                */
            };

            const elements = this.stripe.elements(options);

            const elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            };

            // Card number
            this.cardEl = elements.create("cardNumber", {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardEl.mount('#card-number');

            this.cardEl.on('change', event => {
//                console.log("LOGGING THE CARD ELEMENT EVENT", event);
                if (event.complete) {
                    console.log('CARD ELEMENT completed');

                    this.cardNumberCompleted = true;

                    this.sendData()
                    // enable payment button
                } else if (event.error) {
                    // show event.error.message to customer
                    this.cardNumberCompleted = false;
                } else {
                    this.cardNumberCompleted = false;
                }
            });

            // Card number
            this.cardExpiry = elements.create("cardExpiry", {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardExpiry.mount('#card-expiry');

            this.cardExpiry.on('change', event => {
//                console.log("LOGGING THE CARD EXPIRY EVENT", event);
                if (event.complete) {
                    console.log('CARD EXPIRY completed');

                    this.cardExpDateCompleted = true;

                    this.sendData()   
                    // enable payment button
                } else if (event.error) {
                    // show event.error.message to customer
                    this.cardExpDateCompleted = false;
                } else {
                    this.cardExpDateCompleted = false;
                }
            });

            // Card number
            this.cardCvc = elements.create("cardCvc", {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardCvc.mount('#card-cvc');

            this.cardCvc.on('change', event => {
//                console.log("LOGGING THE CARD CVC EVENT", event);
                if (event.complete) {
                    console.log('CARD CVC completed');

                    this.cardCvcCompleted = true;
                    
                    // enable payment button
                    this.sendData();
                } else if (event.error) {
                    // show event.error.message to customer
                    this.cardCvcCompleted = false;
                } else {
                    this.cardCvcCompleted = false;
                }
            });
    },
    async beforeMount() {
        console.log(this.getDate())
        if(localStorage.getItem('code-start') == undefined ||
        (localStorage.getItem('userData.email') == undefined && localStorage.getItem('userData.phone')) || 
        localStorage.getItem('userData.password') == undefined || 
        localStorage.getItem('userData.username') == undefined ||
        localStorage.getItem('establishment.typeBusiness') == undefined ||
        localStorage.getItem('establishment.nameOrganization') == undefined ||
        localStorage.getItem('adress.country') == undefined || 
        localStorage.getItem('adress.adress') == undefined || 
        localStorage.getItem('adress.city') == undefined ||
        localStorage.getItem('adress.state') == undefined ||
        localStorage.getItem('establishment.zipCode') == undefined) return this.$router.push('/signupforms')
        
        if (localStorage.getItem('establishment.typeBusiness') == "Self-Employed") {
            this.isInvidual = true
        }
       
        this.state = ["Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware","District of Columbia","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Louisiana","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Montana","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina","North Dakota","Ohio","Oklahoma","Ontario","Oregon","Pennsylvania","Ramey","Rhode Island","South Carolina","South Dakota","Sublimity","Tennessee","Texas","Trimble","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"]
        this.formData.State = this.state[0]

        this.city = ["Alabaster","Albertville","Alexander City","Anniston","Arab","Ashville","Athens","Atmore","Auburn","Bessemer","Birmingham","Capshaw","Center Point","Childersburg","Cullman","Daleville","Daphne","Decatur","Dothan","Enterprise","Eufaula","Fairfield","Fairhope","Florence","Fort Payne","Gadsden","Grand Bay","Grove Hill","Guntersville","Hampton Cove","Hanceville","Hartselle","Headland","Helena","Hodges","Homewood","Hoover","Hueytown","Huntsville","Jacksonville","Jasper","Leeds","Luverne","Madison","Mobile","Montgomery","Mountain Brook","Muscle Shoals","Northport","Notasulga","Opelika","Oxford","Ozark","Pelham","Pell City","Pennsylvania","Phenix City","Prattville","Prichard","Ramer","Roanoke","Saraland","Scottsboro","Selma","Sheffield","Smiths","Sumiton","Sylacauga","Talladega","Thomasville","Trafford","Troy","Trussville","Tuscaloosa","Tuskegee","Vestavia Hills"]
        this.formData.City = this.city[0]
    }
}
</script>
<style> 
@media screen and (max-width: 500px) {
    body, html, #app, .app{
        background-color: #171717 !important
    }
}

.phone-error-input {
    border: 1px solid #EA4335 !important;
}

.InputElement {
    color: white !important
}

.field {
    position: relative;
    cursor: text;
    height: 62px;
    background-color: #1E1E1E;
    border-radius: 10px;
    display: flex;
    align-items: center;
} 

.m-stripe-input {
    width: 100%;
    padding: 20px 10px;
}


.user-input-wrp .stripe-floating-label {
    position: absolute;
    pointer-events: none;
    color: #ABABAB;
    top: 22px;
    left: 10px;
    transition: 0.2s ease all;
    font-family: 'Rubik-Light';
    font-size: 12px;
}


.user-input-wrp .m-stripe-input.focused + .stripe-floating-label,
.user-input-wrp .m-stripe-input:not(.empty) + .stripe-floating-label {
  top: 5px;
	left: 10px;
	font-size: 12px;
	opacity: 1;
}

.row {
    display: flex;
}

.user-input-wrp.half-width {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}


.user-input-wrp .m-stripe-input::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-input-wrp .m-stripe-input::-moz-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-input-wrp .m-stripe-input:-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-input-wrp .m-stripe-input.StripeElement {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
}

.user-input-wrp .m-stripe-input.focused,
.user-input-wrp .m-stripe-input:not(.empty) {
  opacity: 1;
}

.user-input-wrp .m-stripe-input.focused::-webkit-input-placeholder,
.user-input-wrp .m-stripe-input:not(.empty)::-webkit-input-placeholder {
  color: #cfd7df;
}

.user-input-wrp .m-stripe-input.focused::-moz-placeholder,
.user-input-wrp .m-stripe-input:not(.empty)::-moz-placeholder {
  color: #cfd7df;
}

.user-input-wrp .m-stripe-input.focused:-ms-input-placeholder,
.user-input-wrp .m-stripe-input:not(.empty):-ms-input-placeholder {
  color: #cfd7df;
}

.card-form-wrapper {
    position: relative;
}

.vti__dropdown-list.below {
    display: none !important;    
}

.vti__dropdown.open {
    background-color: unset !important;
}

.vti__dropdown-arrow {
    display: none !important;
}

.vti__input {
    padding-left: 0 !important;
}

.payout-tabs {
    gap: 10px;
}

.card-form-inner {
    padding: 20px 0;
    /*
    border: 2px solid #2E2E2E;
    */
    border-top: 0;
    border-radius: 0 0 10px 10px;
}

@media screen and (max-width: 500px) {
    .user-input-wrp.left.half-width {
        margin-left: unset;        
    }
    .user-input-wrp.right.half-width {
        margin-right: unset;     
    }

    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 40px !important;
    }

}
    


</style>