<template>
 <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form inner-modal-div-form-dop">
                <div class="wrapper-inner-modal">
                    <h3>SIGN IN</h3>
                    <my-input 
                        type="text" 
                        label="Email" 
                        class="mt-4 w-320-mob" 
                        v-model="email"
                        @keydown="codeKeyDown"
                    />
                    <my-input 
                        :type="type" 
                        showFunc="true" 
                        label="Password" 
                        class="mt-1 w-320-mob" 
                        v-model="password"
                        @keydown="codeKeyDown"
                    />
                    
                    <div><img src="../assets/img/UI/show.png" class="btn-show" @click="showNewPassword"></div>
                    <p class="error-input-text err-au" v-if="error != ''">{{ error }}</p>
                    <p style="color: #F28E22; font-size: 14px; margin-top: 15px" class="pointer sign-click-a" @click="$router.push('/forget')">Forgot your password?</p>
                    <!-- <div class="m-x-auto pointer" style="margin-top: 56px"><span style="color: #F28E22; font-size: 16px; font-family: 'Rubik-Medium';" @click="$router.push('/staff/signin')">Sign in another method</span></div> -->
                    <my-button label="Sign In" @click="sendData" :active="isActive" class="mt-4"/>
                </div> 
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
import signInMixin from '@/mixins/signInMixin'

    export default {
        name: 'sign in',
        mixins: [signInMixin],
        data() {
            return {
                email: '',
                password: '',
                isActive: false,
                type: 'password',
                error: ''
            }
        },
        watch: {
            email() {
                this.send()
            },
            password() {
                this.send()
            }
        },
        methods: {
            showNewPassword() {
                if(this.type == 'password') this.type = 'text'
                else this.type = 'password'
            },
            send() {
                const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            };
                if(this.email != "" && this.password != "" && validateEmail(this.email)) this.isActive = true
                else this.isActive = false
            },
            sendData() {
                if(this.isActive) {
                    this.Auth(this.email, this.password).then(data => {

                        if(data?.ok == undefined) {
                            this.error = data.error
                        } else {
                            if(data?.ok == true) {
                                localStorage.setItem('auth.login', this.email)
                                localStorage.setItem('auth.password', this.password)
                                this.$router.push('/authcode')
                            } 
                            // localStorage.setItem('auth-token', data.token)
                            // if(data.role == 'admin') this.$router.push('/dashboard')
                            // else if(data.role == 'developer') this.$router.push('/developer/main')
                            // else this.$router.push('/employee/workspace')
                        }
                        
                    })
                }
                
            },
            codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.isActive) {
                        this.sendData();
                    }
                }
            }
        }
    }
</script>