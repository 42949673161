<template>
    <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
                    <h3>CREATE A NEW<br>PASSWORD</h3>
                    <div v-if="!isChange">
                        <my-input :type="typeNewPassword" label="New password" class="mt-4" v-model="formData.newPassword"/>
                    
                        <div><img src="../assets/img/UI/show.png" class="btn-show" @click="showNewPassword"></div>
                        <my-input :type="typeRepeatPassword" label="Repeat password" class="mt-1" v-model="formData.repeatPassword"/>
                        <div><img src="../assets/img/UI/show.png" class="btn-show"  @click="showRepeatPassword"></div>
                        <p v-if="isActiveErr" class="error-input-text err-au">An error has occurred</p>
                        <my-button label="Change password" @click="updatePassword" :active="isActive" class="mt-4"/>
                    </div>
                    <div v-if="isChange">
                        <p style="color: #34A853; font-size: 25px; margin-top: 10px;">Password changed successfully</p>
                        <p @click="next" style="color: var(--orange-color); font-size: 25px; margin-top: 20px; cursor: pointer">Sign in</p>
                    </div>
                    <div class="flex mt-3">
            </div>
        </div>
    </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
import { mapMutations } from 'vuex'
import configMixin from '@/mixins/configMixin'

export default {
    name: 'new password',
    mixins: [configMixin],
    data() {
       return {
        isActive: false,
        typeNewPassword: 'password',
        typeRepeatPassword: 'password',
        formData: {
            newPassword: '',
            repeatPassword: '',
        },
        email: localStorage.getItem('email-forget'),
        isActiveErr: false,
        isChange: false
       }
    },
    watch: {
        'formData.newPassword': function() {
            this.isIsset()
        },
        'formData.repeatPassword': function() {
            this.isIsset()
        },
    },
    methods: {
        sendData() {
            this.$router.push('/dashboard')
        },
        checks() {
            this.formData.checks = true
            this.isIsset()
        },
        showNewPassword() {
            if(this.typeNewPassword == 'password') this.typeNewPassword = 'text'
            else this.typeNewPassword = 'password'
        },
        showRepeatPassword() {
            if(this.typeRepeatPassword == 'password') this.typeRepeatPassword = 'text'
            else this.typeRepeatPassword = 'password'
        },
        isIsset() {
            const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            };
            if(this.formData.newPassword != "" && 
            this.formData.repeatPassword != "" && 
            this.formData.newPassword == this.formData.repeatPassword) this.isActive = true
            else this.isActive = false
        },
        setEmail(value) {
            this.formData.email = value
            this.isIsset()
        },
        setRepeatPassword(value) {
            this.formData.repeatPassword = value
            this.isIsset()
        },
        setNewPassword(value) {
            this.formData.newPassword = value
            this.isIsset()
        },
        updatePassword() {
            if(this.isActive) {
                let body = {
                    email: this.email,
                    code: localStorage.getItem('verif-code'),
                    password: this.formData.newPassword
                }

                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/forget/password', {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }
                return response().then(data => {
                    if(data?.ok != true) {
                        this.isActiveErr = true
                    } else {
                        localStorage.clear()
                        this.isChange = true
                    }
                })
            }
        },
        next() {
            this.$router.push('/')
        }
    },
    beforeMount() {
        if(localStorage.getItem('email-forget') == undefined || 
        localStorage.getItem('verif-code') == undefined) return this.$router.push('/')
    }
  
}
</script>