<template>
    <div class="cale">
        <div class="flex">
            <h3 class="head-title">Edit manager information</h3>
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer sery" @click="close">
        </div>
        <div class="flex f-inp">
            <div class="input">
                    <vue-tel-input wrapperClasses="rg" 
                    inputClasses="rtb" class="rg" 
                    ref="inp" 
                    style="border: 1px solid #252525; height: 47px;outline: none; box-shadow: none; background-color: #252525 !important;"  
                    v-model="formData.phone" :style="{'height': '62px'}" 
                    validCharactersOnly="true" 
                    @validate="phoneObject" 
                    @country-changed="setCountryCode"
                    :enabledCountryCode="true"
                    :enabledFlags="false"
                    mode="international"> 
                        <template v-slot:arrow-icon>
                        </template>
                    </vue-tel-input>  
                    <p class="error-input-text err-au" v-if="isAvtivePhonErr">This phone already exists</p> 
            </div>
            <div class="input mt-m">
                <my-input label="Full name" bgColor="#252525" v-model="formData.name"/>
            </div>
        </div>
        <div class="flex l-input">
            <div class="input">
                <my-input bgColor="#252525" label="Email" v-model="formData.email"/>
                <p class="error-input-text err-au" v-if="siActiveEmailErr">This email already exists</p>
            </div>
            <!-- <span class="floating-label">Email</span>
            <input type="hidden" ref="esta">
            <div class="select" @click="setClassSelect">
                <ul ref="select" class="closed esta-select">
                    <li :values="item" v-for="item in email" class="option" @click="e => {changeSelect(1, item, e)}">{{ item }}</li>
                </ul>
            </div>
            <img src="../../assets/img/UI/select-panel-min.svg"  class="select-strel" @click="setClassSelect"> -->
            <span class="floating-label sec vbsd" style="z-index: 6">Choose role</span>
            <input type="hidden">
            <div class="select ser fgt" @click="setClassSelects" style="z-index: 5 ">
                <ul ref="selects" class="closed esta-select">
                    <li :values="item" v-for="item in role" class="option" @click="e => {changeSelect(2, item, e)}">{{ item }}</li>
                </ul>
            </div>
            <img src="../../assets/img/UI/select-panel-min.svg"  class="select-strel sbnmq" @click="setClassSelects">
            <div class="dib-n"></div>
        </div>
        <div class="flex l-input" v-if="false">
            <span class="floating-label sad">Establishment</span>
            <input type="hidden">
            <div class="select ses" @click="setClassSelectss">
                <ul ref="selectss" class="closed esta-select swq">
                    <li :values="item.name" v-for="item in establishment" class="option" @click="e => {changeSelect(3, item.name, e, item.id)}">{{ item.name }}</li>
                </ul>
            </div>
            <img src="../../assets/img/UI/select-panel-min.svg"  class="select-strel" @click="setClassSelectss">
        </div>
        <my-button label="Save" class="mt-4" :active="isActiveBtn" @click="closeAddPanel"/>
    </div>
</template>

<script>
import setEmployeeMixin from '@/mixins/setEmployeeMixin';
import registrAdminMixin from '@/mixins/registrAdminMixin'
import usernameMixin from '@/mixins/usernameMixin'

export default {
        name: 'modal-edit-manager',
        mixins: [setEmployeeMixin, registrAdminMixin, usernameMixin],
        data() {
            return {
                isActiveBtn: false,
               formData: {
                establishment: this.establishment[0],
                email: this.email,
                role: this.role[0],
                name: this.name,
                phone: this.phone,
                access: ''
               },
               updateData: {
                esta: '',
               },
               isValid: false,
                countryCode: '',
                siActiveEmailErr: false,
                isAvtivePhonErr: false,
                index: 0,
                isUpdate: false,
                nowPhone: this.phone
            }
        },
        props: {
            name: String,
            establishment: Array,
            email: String,
            phone: String,
            role: Array,
            id: String,
            acess: String,
            log: String
        },
        watch: {
            log() {
                console.log(this.log)
            },
            index() {
                if(this.index == 1) {
                    if(this.phone != this.nowPhone) this.isAvtivePhonErr = false
                    this.index++
                }
            },
           'formData.esta': function() {
            this.setActiveBtn()
           },
           'formData.email': function() {
            this.checkEmail()
           },
           'formData.team': function() {
            this.setActiveBtn()
           },
           'formData.name': function() {
            this.setActiveBtn()
           },
           'formData.login': function() {
            this.setActiveBtn()
           },
           'formData.phone': function() {
            if(this.formData.phone.length < this.countryCode.length) {
                this.formData.phone = this.countryCode
            }
            this.checkPhone()
           },
           'formData.establishment': function() {
            this.setActiveBtn()
           },
           countryCode() {
                this.formData.phone = this.countryCode
               }
        },
        mounted() {
            this.setActiveBtn()
            console.log(this.log)
        },
        methods: {
            getAcces(role) {
                console.log(this.acess)
                if (this.acess == "Full access") {
                    return ['Full access', 'Branch access']
                } else {
                    return ['Branch access', 'Full access']
                }
            },
            setCountryCode(val) {
            this.countryCode = "+" + val.dialCode
        },
        phoneObject(object) {
                this.isValid = object.valid;
        },
        close() {
            this.$emit('close')
        },
            closeAddPanel() {
                if(this.isActiveBtn) {
                    let fullname = this.formData.name.split(" ")
                    let name = fullname[0]

                    let last_name = (fullname[1] == "" || fullname[0] == undefined || fullname[0] == null) ?
                    null : fullname[1]
                    console.log(this.formData.role)
                    this.setEmployee(this.updateData.esta, null, name, last_name, null, this.formData.email, this.formData.phone, this.id, this.formData.role)
                    this.$emit('close')
                    //window.location.reload();
                }
            },
            setActiveBtn() {
                this.isUpdate = true
                if(this.isUpdate && !this.siActiveEmailErr && !this.isAvtivePhonErr) return this.isActiveBtn = true
                else  return this.isActiveBtn = false

            },
            checkEmail() {
                this.checkAdminEmail(this.formData.email).then(data => {
                    if(data?.ok === true) {
                        this.siActiveEmailErr = false
                    } else if(data?.ok === false) {
                        this.siActiveEmailErr = true
                    }
                    this.setActiveBtn()
                })
            },
            checkPhone() {
                this.checkCEOPhone(this.formData.phone).then(data => {
                    if(data?.ok === true) {
                        this.isAvtivePhonErr = false
                    } else if(data?.ok === false) {
                        if(this.phone != this.nowPhone) this.isAvtivePhonErr = true
                    }
                    this.index++
                    this.setActiveBtn()
                })
            },
            changeSelect(id, value, e, object_id) {
                if(id == 3) {
                    this.updateData.esta = {
                        name: value,
                        id: object_id
                    }
                    this.formData.establishment = value
                    this.$refs.selectss.insertBefore(e.target, this.$refs.selectss.firstChild)
                }
                if(id == 1) {
                    this.formData.email = value
                    this.$refs.select.insertBefore(e.target, this.$refs.select.firstChild)
                }
                if(id == 2) {
                    this.$refs.selects.insertBefore(e.target, this.$refs.selects.firstChild)
                    this.formData.role = value
                    console.log(this.formData.role)
                }
            },
            setClassSelect(value) {
                this.$refs.select.classList.toggle('closed');
                this.$refs.select.style.marginBottom = '-' + String(this.$refs.select.offsetHeight -60) + 'px'
            },
            setClassSelects(value) {
                this.$refs.selects.classList.toggle('closed');
                this.$refs.selects.style.marginBottom = '-' + String(this.$refs.selects.offsetHeight -60) + 'px'
            },
            setClassSelectss(value) {
                this.$refs.selectss.classList.toggle('closed');
                this.$refs.selectss.style.marginBottom = '-' + String(this.$refs.selectss.offsetHeight -60) + 'px'
            },
        }
    }
</script>

<style scoped>
.ser {
    margin-left: 22px;
}
.floating-label {
    z-index: 2;
	position: absolute;
	pointer-events: none;
    color: white;
	transition: 0.2s ease all;
    font-size: 12px;
    color: #ABABAB;
    left: 48px;
    margin-top: 10px;
}

.sec {
    left: 285px
}
.ss {
    left: 286px
}
.l-input {
    margin-top: 20px;
}
.esta-select, .select ul li.option {
    z-index: 1;
    width: 220px;
    padding-top: 15px;
    padding-bottom: 7px;
    border: none;
    background-color: #252525;
}

.esta-select {
    padding-left: 4px;
}
.select ul li.option {
    width: 216px;
}
.select-strel {
    margin-left: -20px;
}
.f-inp {
    margin-top: 31px;
    justify-content: space-between;
}
.input{ 
    width: 220px;
}
.ses, .ses ul {
    width: 460px;
}
img {
    margin-left: 120px;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
}
.cale {
    position: absolute;
    top: 200px;
    left: calc(60% - 408px);
    z-index: 3;
    opacity: 1;
    width: 540px;
    padding: 40px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .cale {
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
   .cale {
    width: 320px;
   }
   .f-inp {
    flex-direction: column;
   }
   .l-input {
    flex-direction: column;
   }
   .select-strel {
    margin-left: auto;
    margin-right: 15px;
    margin-top: -40px;
    z-index: 5;
   }
   .floating-label {
    margin-left: -18px;
   }
   .sec {
    margin-top: 50px;
    margin-left: 0;
    position: static;
    margin-bottom: -20px;
    margin-left: 9px;
   }
   .ss {
    margin-top: 25px;
   }
   .ser, .ses {
    margin-left: 0;
   }
   .inp-mobile-mt {
    margin-top: 22px;
   }
   .mt-m {
    margin-top: 20px;
   }
   .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .input {
        width: 284px;
    }
    .esta-select, .select ul li.option {
    z-index: 1;
    width: 285px;
    padding-top: 15px;
    padding-bottom: 7px;
    border: none;
    background-color: #252525;
}
.ses {
    width: 220px;
}
.select ul li.option
 {
        width: 276px;
    }
    .est-labe-for {
        margin-left: -19px;
    }
    .sery {
        margin-left: 0;
    }
    .swq {
        width: 286px !important;
        margin-top: 30px;
    }
    .sad {
        margin-top: 37px;
    }
}
</style>