<template>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'wrapper-average': true, 'calendar-active-cont': isActiveCalendar}" style="background-color: var(--child-bg-color); height: 98vh !important;">
            <div class="flex top-avarage-mobile" style="flex-direction: column">
                <h2 class="main-title-dash pointer" @click="$router.push('/settings')"><img src="../../../assets/img/UI/left-strel.svg"> Terms and condition</h2>
                <a href="/docs/Privacy Policy.pdf">
                    <div class="setting-el flex mt-3">
                        <span>Privacy Policy</span>
                        <img src="../../../assets/img/UI/setting-line.svg" class="line-setting pointer">
                    </div>
                </a>
                <a href="/docs/Fee Plan.pdf">
                    <div class="setting-el flex mt-1">
                        <span>Fee Plan</span>
                        <img src="../../../assets/img/UI/setting-line.svg" class="line-setting pointer">
                    </div>
                </a>
                <a href="/docs/Terms and Conditions.pdf">
                    <div class="setting-el flex mt-1">
                        <span>Terms and Conditions</span>
                        <img src="../../../assets/img/UI/setting-line.svg" class="line-setting pointer">
                    </div>
                </a>
                <a href="https://merciq.com/Cookie%20policy.pdf">
                    <div class="setting-el flex mt-1">
                        <span>Cookie Policy</span>
                        <img src="../../../assets/img/UI/setting-line.svg" class="line-setting pointer">
                    </div>
                </a>
            </div>
           
        </div>

    </div>
</template>

<script>
</script>