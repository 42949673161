<template>
    <are-sure 
    title="You will permanently lose access to your account"
    v-if="isDeleteAccount"
    @close="setDelete"
    @delete="deleteAccount"/>

    <phone-number 
    :title="getTitleRecovery(this.Edit)" 
    :email="userData.email"
    v-if="isActiveRecovery" 
    @getdata="isSend" 
    @close="setActiveRecoveryDel"/>

    <change-password 
    @getdata="setPassword" 
    @close="setPassword" 
    v-if="isActivePassword"/>

    <change-phone 
    @getdata="setPhone" 
    @close="setPhone" 
    v-if="isActivePhone"/>

    <change-email 
    @getdata="setEmail" 
    @close="setEmail" 
    v-if="isActiveEmail"/>

    <delete-acc-empl 
    @close="setActiveDelete" 
    @getdata="setDelete"
    v-if="isActiveDel" 
    :email="userData.email"/>

    <credit-card v-if="isActiveCard" @close="setActiveCard" @getdata="setActiveCard"/>

    <update-info-empl 
    v-if="isActiveEmpl" 
    :email="userData.email"
    @changephone="setActiveRecovery('phone')"
    @changeemail="setActiveRecovery('email')"
    @changepassword="setActiveRecovery('password')"
    :phone="userData.phone"
    :name="userData.name"
    :last_name="userData.last_name"
    :avatar="userData.avatar"
    @close="setActiveEmpl" 
    @getdata="setActiveEmpl"
    @notif="setActiveEmpl(), setActiveNotif()" 
    @security="setActiveEmpl(), setActiveLogin()"/>

    <notifications v-if="isActiveNotife" 
    @close="setActiveNotif" 
    @getdata="setActiveNotif"/>

    <login-history v-if="isActiveLogin" @close="setActiveLogin" @getdata="setActiveLogin"/>

    <qr-code 
    v-if="isActiveQRcode" 
    @close="setActiveQR" 
    :personal="userData.username"
    :qr="qr_id"
    @update="updateQr"/>
    <div class="container-dash">
        <employee-dashboard />
        <div class="wrapper-dash main-mirciq workspace-wrapper-das min-height pb-4 wrapper-profile-div" :class="{'calendar-active-cont': (isActiveDel || 
        isActiveCard || 
        isActiveEmpl || 
        isActiveNotife || 
        isActiveLogin || 
        isActiveQRcode || isActiveRecovery || isActivePhone || isActiveEmail || isDeleteAccount || isActivePassword)}">
            <div class="workspace-wrapper wrapper-profile center-mobile"> 
                <!--
                <h2 class="main-title-dash d-none-desc">Profile</h2>
                -->
                <div id="learning-profile">
                    <div class="flex top-avarage-mobile">
                        <h2 class="main-title-dash pointer" @click="$router.push('/employee/workspace')"><img src="../../../assets/img/UI/left-strel.svg">Profile settings</h2>
                    </div>
                    <div class="avatar-empl mt-4 m-x-auto-mobile">
                        <img :src="userData.avatar">
                    </div>
                    <h3 class="name-emppl-profile mt-2">{{userData.name + ' ' + userData.last_name}}</h3>
                    <p class="nickname-empl mt-1">@{{userData.username}}</p>
                </div>
                <button class="profile-btn profile-btn-orange mt-2" @click="setActiveEmpl">Update profile info</button> 

                <button class="profile-btn mt-1" @click="$router.push('/employee/payment')">Payment information</button>
                <button class="profile-btn mt-1" @click="setActiveQR">Personal QR & Link</button>
                <button class="profile-btn mt-1" @click="$router.push('/employee/faq')">FAQ & Help Center</button>
                <button class="profile-btn mt-1" @click="$router.push('/employee/support')">Contact technical support</button>
                <button class="profile-btn profile-btn-unactive mt-4" @click="setActiveDel">Delete account</button>
                <button class="profile-btn profile-btn-red mt-1" @click="LogOut">Log out</button>
            </div>
        </div>
    </div>
</template>

<script>
    import configMixin from '@/mixins/configMixin'
    import refreshQr from '@/mixins/refreshQr'
    import loaderMixin from '@/mixins/loaderMixin';

    import TgInstance from '@/components/instance/tourguide';
    
    export default {
        name: 'profile empl',
        mixins: [configMixin, refreshQr, loaderMixin],
        data() {
            return {
                ActiveDateFilter: 'Week',
                isActiveHistory: false,
                isActiveDel: false,
                isActiveCard: false,
                isActiveEmpl: false,
                isActiveNotife: false,
                isActiveLogin: false,
                isActiveQRcode: false,
                profile: 'Nickname',
                userData: {
                    name: '',
                    last_name: '',
                    username: '',
                    avatar: null,
                    phone: '',
                    email: ''
                },
                settings: '',
                qr_id: '',
                isActiveRecovery: false,
                title: '',
                isActivePhone: false,
                isActiveEmail: false,
                isActivePassword: false,
                Edit: '',
                isDeleteAccount: false
            }
        },
        methods: {
            setActiveRecovery(value) {
                this.isActiveRecovery = !this.isActiveRecovery

                this.isActiveEmpl = !this.isActiveEmpl

                this.Edit = value
                if(this.isActiveRecovery == true) {
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/code/user', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                            }
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }

                    return response()
                }
            },
            getTitleRecovery(value){
                if(value == 'phone') return 'PHONE NUMBER'
                else if(value == 'email') return 'EMAIL'
                else return 'PASSWORD'
            },
            setActiveDelete() {
                this.isActiveDel = !this.isActiveDel
            },
            setDelete() {
                this.isActiveDel = false
                this.isDeleteAccount = !this.isDeleteAccount
            },
           setActiveDel() {
            this.isActiveDel = !this.isActiveDel
            this.Edit = 'delete'
            if(this.isActiveDel == true) {
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/code/user', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                            }
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }

                    return response()
                }
           },
           setActiveRecoveryDel() {
            this.isActiveRecovery = !this.isActiveRecovery
           },
           setActiveCard() {
            this.isActiveCard = !this.isActiveCard
           },
           setActiveEmpl() {
            this.isActiveEmpl = !this.isActiveEmpl
           },
           setActiveNotif() {
            this.isActiveNotife = !this.isActiveNotife
           },
           setActiveLogin() {
            this.isActiveLogin = !this.isActiveLogin
           },
           setActiveQR() {
            this.isActiveQRcode = !this.isActiveQRcode
           },
           updateQr() {
            this.refreshQr().then(data => {
                this.qr_id = data.qr
            })
           },
           isSend() {
                this.isActiveRecovery = !this.isActiveRecovery
                if(this.Edit == 'email') this.setEmail()
                else if (this.Edit == "phone") this.setPhone()
                else if(this.Edit == 'delete') this.setActiveDel()
                else if(this.Edit == 'password') this.setPassword()
            },
            setPhone() {
                this.isActivePhone = !this.isActivePhone
                if(this.isActivePhone == false) this.$router.go()
            },
            setPassword() {
                this.isActivePassword = !this.isActivePassword
                if(this.isActivePassword == false) this.$router.go()
            },
            setEmail() {
                this.isActiveEmail = !this.isActiveEmail
                if(this.isActiveEmail == false) this.$router.go()
            },
            LogOut() {
                localStorage.removeItem('auth-token')
                this.$router.push('/')
            },
            deleteAccount() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/employee/profile', {
                        method: "DELETE",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    localStorage.clear()
                    this.$router.push('/')
                })
            },
           getData() {
            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/employee/profile', {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response().then(data => {
                data = data.user
                this.userData.phone = data.phone
                this.userData.name = data.name
                this.userData.last_name = data.last_name
                this.userData.email = data.email
                this.userData.avatar = '/img/uploads/logo/esta/' + data.avatar
                this.qr_id = data.qr_id
                this.settings = data.settings
                this.userData.username = data.username

                this.setLoader(false);
            })
           }
        },
        beforeMount() {
            this.getData()
        },
        mounted() {
            const tgInstance = TgInstance.instance;
            if (tgInstance) {
                console.log ('TgInstance.instance is set');

                const groupName = tgInstance.groupName;
                if (!tgInstance.tg.isFinished(groupName))
                    tgInstance.tg.visitStep(5);
            }
        }
    }
</script>