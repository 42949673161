<template>
    <are-sure v-if="isDel" @close="isSure" @delete="delManager"/>
    <add-manager v-if="isActiveAddManagers" @close="setActiveAddManagers" isAllManager="All" @getmembers="addManager"/>
    <div class="container-dash" style="align-items: flex-start;">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'add-establishment': true, 'calendar-active-cont': isDel || isActiveAddManagers}">
            
            <div id="learning-establishment" class="flex flex-between add-esta-div flex-content-mob">
                <h2 class="main-title-dash pointer" @click="$router.push('/dashboard')"><img src="../../assets/img/UI/left-strel.svg"> Add establishment</h2>
                <p class="green-text mt-1" v-if="$route?.query?.ok == 'ok'">Establishment added!</p>
                <my-input 
                    label="Establishment name" 
                    bgColor="#252525" 
                    v-model="dataForm.establishmentName" 
                    class="add-esta-inp mt-3 w-320-mob"
                    @keydown="codeKeyDown"
                />
                <my-input 
                    label="Establishment address" 
                    bgColor="#252525" 
                    v-model="dataForm.establishmentAddress" 
                    class="add-esta-inp mt-3 w-320-mob mt-1-mobile"
                    @keydown="codeKeyDown"
                />
            </div>
            <div class="btn-add-esta m-x-auto w-320-mob"><my-button :active="isActiveBtn" label="Add" @click="sendData" class=""/></div>
            <div class="add-wrapper-est-i">
                <p class="title-add-esta">Add manager</p>
                <manager-esta 
                class="mt-2 mt-1-mobile" 
                @delete="deleteUser" 
                @changeaccess="setAccess" 
                v-for="item in managers" 
                :avatar="item.avatar" 
                :name="item.name" 
                :id="item.id" 
                :phone="item.phone" 
                :ac="item.acces"
                :activate="item.status == 'true'"/>
                <div class="add-member-manager w-290-mobile" @click="setActiveAddManagers">
                    <img src="../../assets/img/UI/add-member.svg">
                    <span>add member</span>
                </div>
            </div>
            <div class="bg-set-esta-s mt-2" v-for="el in esta">
                <div class="setting-el flex mt-1 bg-esta-setting" @click="$router.push('/establishment/team/' + el.id)">
                    <img src="../../assets/img/UI/esta.svg" alt="">
                    <span>{{ el.name }}</span>
                    <img src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import configMixin from "@/mixins/configMixin"
import loaderMixin from "@/mixins/loaderMixin";

import TgInstance from '@/components/instance/tourguide';

    export default {
        name: 'add establishment',
        mixins: [configMixin, loaderMixin],
        data() {
            return {
                isActiveBtn: false,
                dataForm: {
                    establishmentName: '',
                    establishmentAddress: '',
                },
                isActiveAddManagers: false,
                isDel: false,
                managers: [],
                esta: []
                

            }
        },
        watch: {
            'dataForm.establishmentName': function() {
                this.isActive()
            },
            'dataForm.establishmentAddress': function() {
                this.isActive()
            },
        },
        methods: {
            sendData() {
                if(this.isActiveBtn) {

                    let body = {
                        name: this.dataForm.establishmentName,
                        address: this.dataForm.establishmentAddress,
                        users: this.managers.map(el => {
                            return el.id
                        })
                    }

                    let response = async () => {
                        let query = await fetch(this.config.API_URL + "/team/establishment/create", {
                            method: "POST",
                            headers: {
                                'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })

                        let result = await query.json()
                        
                        return Promise.resolve(result)
                    }

                    return response().then(data => {
                        window.location.href = '/establishment/add?ok=ok'
                    })
                }
            },
            setActiveAddManagers() {
                this.isActiveAddManagers = !this.isActiveAddManagers
            },
            deleteUser(id) {
                console.log(id)
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/unactive/delete/' + id, {
                        method: "DELETE",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }
                response().then(data => {
                    this.$route.go()
                })
            },
            isActive() {
                if(this.dataForm.establishmentName != '' &&
                this.dataForm.establishmentAddress != '') this.isActiveBtn = true
                else this.isActiveBtn = false
            },
            setAccess(value, id) {
                console.log(id)
                console.log(value)
                this.managers[this.managers.findIndex(user => user.id == Number(id))].acces = value
                this.updateAccess(value, id)
            },
            delManager(id) {
                this.managers.splice([this.managers.findIndex(user => user.id == id)], 1)
                this.isSure()
            },
            isSure() {
                this.isDel = !this.isDel
            },
            addManager(users) {
                this.managers = users
                this.setActiveAddManagers()
            },
            getMemeber() {
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/team/avilable/all/managers/', {
                            method: "GET",
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
    
                            }
                        })
    
                        let result = await query.json()
    
                        return Promise.resolve(result)
                    }
                    return response()
                },
                updateAccess(access, id) {
                    let body = {
                        access: access
                    }
                    
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/user/update/access/' + id, {
                            method: "PUT",
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })

                        let result = await query.json()
                        
                        return Promise.resolve(result)
                    }

                    return response()
                },
                codeKeyDown(event) {
                    if (event.keyCode === 13) {
                        if (this.isActiveBtn) {
                            this.sendData();
                        }
                    }
                }
        },
        beforeMount() {
            this.getMemeber().then(data => {
                this.managers = data.users
            })
            
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/team/establishment/all', {
                        method: "GET",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    this.esta = data.rows

                    this.setLoader(false);
                })
            
        },
        async created() {
            const tgInstance = TgInstance.instance;
            if (tgInstance) {
                console.log ('TgInstance.instance is set');
                const groupName = tgInstance.groupName;
                if (!tgInstance.tg.isFinished(groupName))
                    tgInstance.tg.visitStep(7);
            }
        }
    }
</script>

<style scoped>
.reg-staff {
    width: 320px
}

@media screen and (max-width: 500px) {
    .main-title-dash {
        padding-top: 0;
    }
}

</style>