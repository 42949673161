<template>
    <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
                    <h3>YOUR INVITE CODE</h3>
                    <my-input type="number" label="Invite code" @getvalue="setInviteCode" class="mt-4"/>
                    <my-button label="Start" :active="isActive" @click="sendData" class="mt-4"/>
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color">Q</span></h1>
</template>

<script>
export default {
    name: 'invite code',
    data() {
        return {
            inviteCode: '',
            isActive: false
        }
    },
    watch: {
        inviteCode() {
            if(this.inviteCode.length > 1) this.isActive = true
            else this.isActive = false
        }
    },
    methods: {
        setInviteCode(value) {
            this.inviteCode = value
            if(this.inviteCode != "") this.isActive = true
            else this.isActive = false
            console.log(this.inviteCode)
        },
        sendData() {
            if(this.isActive == true) {
                this.$router.push('/signupshort')
            }
        }
    }
}

</script>