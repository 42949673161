import MyInput from "@/components/MyInput.vue"
import CustomInput from "@/components/CustomInput.vue"
import MyButton from "@/components/MyButton.vue"
import CustomButton from "@/components/CustomButton.vue"
import CustomTab from "@/components/CustomTab.vue"
import ChangeButton from "@/components/ChangeButton.vue"
import Dashboard from "@/components/block/Dashboard.vue"
import ModalCalendar from "@/components/ModalCalendar.vue"
import UserTransction from "@/components/UserTransction.vue"
import HistoryTransaction from "@/components/HistoryTransaction.vue"
import StaffUser from "@/components/StaffUser.vue"
import EditEmployee from "@/components/block/EditEmployee.vue"
import EditManager from "@/components/block/EditManager.vue"
import TeamRate from "@/components/TeamRate.vue"
import TeamRate_1 from "@/components/TeamRates.vue"
import Employees from "@/components/Employees.vue"
import SelectInput from "@/components/SelectInput.vue"
import SelectInputNew from "@/components/SelectInputNew.vue"
import SelectInputSearch from "@/components/SelectInputSearch.vue"
import ManagerEsta from "@/components/ManagerEsta.vue"
import AddNewTeam from "@/components/block/AddNewTeam.vue"
import DeleteEsta from "@/components/block/DeleteEstablishment.vue"
import InfoMember from "@/components/block/InfoMember.vue"
import QRcode from "@/components/block/QRcode.vue"
import LeaveRequest from "@/components/block/LeaveRequest.vue"
import Notif from "@/components/block/NotifSetting.vue"
import PhoneRecovery from "@/components/block/ChangePassword.vue"
import SetPass from "@/components/block/SetPass.vue"
import SetPhone from "@/components/block/SetPhone.vue"
import SetEmail from "@/components/block/SetEmail.vue"
import ChanePayment from "@/components/block/GetCodeNumber.vue"
import ChanePayments from "@/components/block/ChangePayment.vue"
import ChangeStripePayment from "@/components/block/ChangeStripePayment.vue"
import AreSure from "@/components/block/AreSure.vue"
import EmplDash from "@/components/block/EmployeeDashboard.vue"
import HistEmplTrans from "@/components/HistoryTranEmpl.vue"
import HistoryEl from "@/components/block/MoreHistEl.vue"
import DelAccEmpl from "@/components/block/DeleteAccount.vue"
import CreditCard from "@/components/block/BankCard.vue"
import UpdateEmplInfo from "@/components/block/UpdateEmplInfo.vue"
import LOginHistory from "@/components/block/LoginHistory.vue"
import StaffClient from "@/components/StaffClient.vue"
import AvatarEditor from "@/components/AvatarEditor.vue"
import BarChart from "@/components/block/Chart"
import Success from "@/components/block/Succes"
import Error from "@/components/block/Error"
import Paginate from "@/components/Paginate"
import AddMember from "@/components/block/AddMember"
import AddManager from "@/components/block/AddManager"
import Member from "@/components/Member"
import Cookie from "@/components/block/Cookie"
import Business from '@/components/Business'
import User from '@/components/User'
import Terms from '@/components/block/Terms'
import OneTransaction from '@/components/block/OneTransaction'
import AddNewMember from '@/components/block/AddNewMember'
import Loading from '@/components/block/Loading'
import TransferFunds from '@/components/block/TransferFunds'
import VerificationRequiredModal from '@/components/block/VerificationRequiredModal'
import CustomModal from '@/components/block/CustomModal.vue'
import SsnInput from '@/components/SsnInput'
import FaqElement from '@/components/block/FaqElement.vue'
import LearningModal from '@/components/block/LearningModal.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default [
    Loading,
    AddNewMember,
    OneTransaction,
    Terms,
    User,
    Business,
    Cookie,
    AddManager,
    Member,
    AddMember,
    TeamRate_1,
    Paginate,
    Error,
    Success,
    BarChart,
    MyInput,
    CustomInput,
    MyButton,
    CustomButton,
    CustomTab,
    ChangeButton,
    Dashboard,
    ModalCalendar,
    UserTransction,
    StaffUser,
    EditEmployee,
    HistoryTransaction,
    TeamRate,
    Employees,
    EditManager,
    SelectInput,
    SelectInputNew,
    SelectInputSearch,
    ManagerEsta,
    AddNewTeam,
    DeleteEsta,
    InfoMember,
    QRcode,
    LeaveRequest,
    Notif,
    PhoneRecovery,
    SetPass,
    SetPhone,
    SetEmail,
    ChanePayment,
    ChanePayments,
    ChangeStripePayment,
    AreSure,
    EmplDash,
    HistEmplTrans,
    HistoryEl,
    DelAccEmpl,
    CreditCard,
    UpdateEmplInfo,
    LOginHistory,
    StaffClient,
    AvatarEditor,
    TransferFunds,
    VerificationRequiredModal,
    CustomModal,
    SsnInput,
    FaqElement,
    LearningModal,
    LoaderComponent
]