<template>
  <div class="learning-modal-div flex justify-center align-center">
    <div class="inner-modal-div-form">
      <div class="flex flex-column justify-center align-center mb-2">
        <img src="../../assets/img/UI/learning_hat_icon.svg" class="pointer header-icon" @click="close">
        <h3 class="head-title">Welcome to<br>training mode</h3>          
      </div>
      <div class="flex justify-end mascot-container">
        <img class="mascot-container-img" src="../../assets/img/UI/mascot_variant2.png" />
      </div>
      <div class="flex align-center learning-steps-container">
        <div class="learning-steps-indicator" style="width: 7%"></div>
        <p class="steps-title">Dashboard Overview</p>
        <p class="steps-numbers">1 / 14</p>
      </div>
      <p class="learning-title">
        Welcome to the MerciQ Business Profile Guide. 
        This guide will help you navigate and utilize the various features of your business account, 
        from managing employees and establishments to handling analytics and payouts. 
        Follow the steps to make the most out of your MerciQ experience.
      </p>
      <div class="m-x-auto mt-2">
          <my-button :active="true" label="Next step" @click="goToVerification" class=""/>
      </div>
      <div class="m-x-auto mt-2">
          <my-button :active="true" label="Skip learning" @click="close" class="not_active"/>
      </div>
    </div>      
  </div>
</template>
  
<script>

  export default {
      name: 'learning-modal',
      props: {

      },
      data() {
          return {

          }
      },
      emits: ['close'],
      methods: {
          close() {
             this.$emit('close')
          },          
      }
  }
</script>
  
<style scoped>

  .inner-modal-div-form {
    padding: 20px;
    background-color: unset;
  }
  .header-icon {
    width: 48px;
    height: 48px;
  }
  .head-title {
    font-size: 32px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
  }
  .learning-title {
    color: white;
    font-size: 16px;
    background: #2D2D2D;
    padding: 20px;
    border-radius: 12px;
    line-height: 20.8px;
    text-align: left;
  }
  .learning-modal-div {
    position: absolute;
    z-index: 999;
    opacity: 1;
    padding: 40px;
    border-radius: 10px;
    height: 100vh;
    width: 100%;
  }

  .mascot-container {
    margin-bottom: 20px;    
  }

  .mascot-container-img {
    width: 120px;
    height: 120px;
  }

  .learning-steps-container {
    position: relative;
    justify-content: space-between;
    height: 45px;
    margin-bottom: 10px;
    padding: 0 24px;
    border-radius: 12px;
    background-color: #1E1E1E;
  }

  .learning-steps-container .steps-title {
    font-size: 16px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
  }

  .learning-steps-container .steps-numbers {
    font-size: 16px;
    font-family: 'Rubik-Regular';
    color: #857F7C;
  }
  
  .learning-steps-indicator {    
    position: absolute;
    height: 100%;
    background: linear-gradient(90deg, rgba(241, 142, 34, 0) 0%, rgba(241, 142, 34, 0.12) 100%);
  }

  @media screen and (max-width: 500px) {
    .learning-modal-div {
        padding: 15px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .head-title {
        text-align: center;
    }
    .inner-modal-div-form {
      height: auto;
      width: auto;
    }
  }
</style>