<template>
    <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
                
                    <h3>SIGN UP</h3>
                    <my-input type="email" label="Email" class="mt-4" @getvalue="setEmail"/>
                    <my-input :type="typeNewPassword" showFunc="true" label="New password" class="mt-1" @getvalue="setRepeatPassword"/>
                    
                    <div><img src="../../assets/img/UI/show.png" class="btn-show" @click="showNewPassword"></div>
                    <my-input :type="typeRepeatPassword" showFunc="true" label="Repeat password" class="mt-1" @getvalue="setNewPassword"/>
                    <div><img src="../../assets/img/UI/show.png" class="btn-show"  @click="showRepeatPassword"></div>
                    
                    <my-button label="Registration" @click="sendData" :active="isActive" class="mt-4"/>
                    <div class="flex mt-3">
                        <div class="form_radio">
                            <input id="radio-1" type="radio" name="radio" checked value="1"  @click="checks">
                            <label for="radio-1"></label>
                        </div>
                        <p class="radio-text main-text-color">By registering I confirm I have read and agree 
to the <a href="/docs/Terms and Conditions.pdf" class="orange-color">MerciQ Terms of Use</a>, the Fees set out <a href="/docs/Fee Plan.pdf" class="orange-color">here</a>. We manage personal data as set out in our <a href="/docs/Privacy Policy.pdf" class="orange-color">Privacy Policy</a>. I also confirm that I have read and accept the <a class="orange-color" href="https://stripe.com/en-ch/legal/connect-account">Stripe Connected Account Agreement</a></p>
                </div>
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERddCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'sign up forms empl',
    data() {
       return {
        isActive: false,
        typeNewPassword: 'password',
        typeRepeatPassword: 'password',
        formData: {
            email: '',
            newPassword: '',
            repeatPassword: '',
            checks: true
        }
       }
    },
    methods: {
        ...mapMutations({
            setData: 'code/setData'
        }),
        sendData() {
            this.setData(this.formData.email)
            if(this.isActive) {
                localStorage.setItem('userData.email', this.formData.email)
                localStorage.setItem('userData.password', this.formData.newPassword)
                this.$router.push('/email')
            }
        },
        checks() {
            this.formData.checks = true
            this.isIsset()
        },
        showNewPassword() {
            if(this.typeNewPassword == 'password') this.typeNewPassword = 'text'
            else this.typeNewPassword = 'password'
        },
        showRepeatPassword() {
            if(this.typeRepeatPassword == 'password') this.typeRepeatPassword = 'text'
            else this.typeRepeatPassword = 'password'
        },
        isIsset() {
            const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            };
            if(this.formData.email != "" && 
            this.formData.newPassword != "" && 
            this.formData.repeatPassword != "" && 
            this.formData.newPassword == this.formData.repeatPassword && 
            validateEmail(this.formData.email) &&
            this.formData.checks) this.isActive = true
            else this.isActive = false
        },
        setEmail(value) {
            this.formData.email = value
            this.isIsset()
        },
        setRepeatPassword(value) {
            this.formData.repeatPassword = value
            this.isIsset()
        },
        setNewPassword(value) {
            this.formData.newPassword = value
            this.isIsset()
        },
    }
  
}
</script>