import { createStore } from 'vuex'
import { codeModule } from '@/store/codeModule'
import { loaderModule } from '@/store/modules/loaderModule'

export default createStore({
  modules: {
    code: codeModule,
    loader: loaderModule
  }
})
