<template>
    <div class="container-dash absolute-100-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true}">
            <div class="flex mt-2 mt-5-mobile" style="margin-top: 0 !important">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/settings')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">Business account</h2>     
            </div>
            <div class="main-staff setting-div bg-setting">
                <div class="main-nav-mobile d-none-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex min-panel-nav-setting">
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/settings/public')">Public information</h2>
                        <h2 :class="{ active_nav_panel: true}" @click="$router.push('/settings/type')"  style="margin-left: 20px;">Type of your Business</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': false}" @click="$router.push('/settings/personal')">Personal information</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/settings/financial')">Financial model</h2>
                    </div>
                    <div class="main-line-menu min-panel-nav-setting">
                        <div class="menu-mobile-nav-line flex min-panel-nav-setting-child">
                            <div :class="{'fr-main-inp-active': false}" class="sec-setting-mobile-1"  style="margin-left: 90px;"></div>
                            <div :class="{'fr-main-inp-active': true}" class="sec-setting-mobile-2"></div>
                            <div :class="{'fr-main-inp-active': false}" class="sec-setting-mobile-2"></div>
                            <div  :class="{'fr-main-inp-active': false}" class="sec-setting-mobile-3"></div>
                        </div>
                    </div>
                </div>
               <div class="top-bar-staff  d-none-desc">
                <div class="flex">
                    <div class="second-staff-nav" style="margin-left: 0;">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/public')">Public information</p>
                    </div> 
                    <div class="first-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true}" @click="$router.push('/settings/type')" style="margin-left: 20px;">Type of your Business</p>
                    </div>
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/personal')">Personal information</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/financial')">Financial model</p>
                    </div> 
                </div>
                <div class="border-staff flex border-set">
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'set-thre-nav': true}" style="margin-left: -10px;"></div>
                    <div :class="{'active-first': true, 'active-nav-staff': true,'set-staff-nav': true}" style="margin-left: 100px;"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'set-sec-nav': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'set-fo-nav': true}"></div>
                </div>
               </div>
               <div class="div-height-nulls"></div>

                <div class="warning-info">
                    <p>Important Notice: Making changes in this section may temporarily suspend your ability to receive payments and withdraw funds from your account. 
                        Please proceed with caution.</p>
                </div>

               <div class="RadioBusiness mt-4 mt-b-t-mobile">
                    
                    <div class="RadioBusiness_child">
                        <div class="form_radio_b">
                            <input id="radio-1" type="radio" name="radio" :checked="'Self-Employed' == formData.checks" value="1"  @click="checks">
                            <label for="radio-1"></label>
                        </div>
                        <label class="ml-1" for=""> Self-Employed / Individual</label>
                    </div>
                    <div class="RadioBusiness_child mt-2">
                        <div class="form_radio_b">
                            <input id="radio-2" type="radio" name="radio" value="2" :checked="'LLC' == formData.checks"  @click="checks2">
                            <label for="radio-2"></label>
                        </div>
                        <label class="ml-1" for=""> LLC</label>
                    </div>
                    <div class="RadioBusiness_child mt-2">
                        <div class="form_radio_b">
                            <input id="radio-3" type="radio" name="radio" :checked="'C-corp' == formData.checks" value="3"  @click="checks3">
                            <label for="radio-3"></label>
                        </div>
                        <label class="ml-1" for=""> C-corp</label>
                    </div>
                    <div class="RadioBusiness_child mt-2">
                        <div class="form_radio_b">
                            <input id="radio-4" type="radio" name="radio" value="4" :checked="'Partnership' == formData.checks" @click="checks4">
                            <label for="radio-4"></label>
                        </div>
                        <label class="ml-1" for=""> Partnership</label>
                    </div>
                    <div class="RadioBusiness_child mt-2">
                        <div class="form_radio_b">
                            <input id="radio-5" type="radio" name="radio" value="5"  @click="checks5" :checked="'Other' == formData.checks">
                            <label for="radio-5"></label>
                        </div>
                        <label class="ml-1" for="">Other</label>
                    </div>
                </div>
                <div class="name_form_setting mt-4 w-320-mob">
                    <my-input label="Name of the Organisation" 
                        bgColor="#252525" 
                        v-model="formData.name"
                        @keydown="codeKeyDown"
                    />
                </div>
                <div class="flex mt-2 flex-cc d-none-desc" style="align-items:flex-start; align-content:flex-start;">
                   <div class="first-setting-inp">
                    <div class="flex" style="z-index: 15;position: relative; overflow-y: auto; overflow-x: hidden; text-align: left;"><select-input style="min-height: 62px;" :width="width + 'px'" :inps="country" bgColor="#252525" label="Country" @getvalue="Country"/></div>
                    </div>
                    
                    <div class="flex ml-2 ml-0-mobile mt-2-mobile"  style="z-index: 8; position: relative;">
                        <div class="flex" style="z-index: 7;position: relative; overflow-y: auto; overflow-x: hidden; text-align: left;">
                            <select-input :width="width + 'px'" style="min-height: 62px;" :inps="state" bgColor="#252525" label="State" @getvalue="State"/>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column d-none-mobile" style="margin-left: auto; align-items: left;">

                    <div class="flex mt-2" style="overflow-y: auto; overflow-x: hidden; margin-top: 100px; margin-bottom: 59px; position: absolute; text-align: left; z-index: 10">
                        <select-input width="320px" :inps="country" label="Country" @getvalue="Country" bgColor="#252525" style=" max-height: 200px; min-height: 59px"/>
                    </div>

                    <div class="flex mt-2" style="overflow-y: auto; overflow-x: hidden;text-align: left; position: absolute; text-align: left; margin-top: 180px; z-index: 9;">
                        <select-input width="320px" :inps="state" label="State" @getvalue="State" bgColor="#252525" style=" max-height: 200px; min-height: 59px"/>
                    </div>

                </div> 

                <div class="flex mt-2">
                   <div class="first-setting-inp">
                        <my-input 
                            label="Adress" 
                            bgColor="#252525" 
                            v-model="formData.adress"
                            @keydown="codeKeyDown"
                        />
                    </div>
                    <div class="second-setting-inp">
                        <my-input 
                            label="Zip-code" 
                            bgColor="#252525" 
                            v-model="formData.zip"
                            @keydown="codeKeyDown"
                        />
                    </div>
                </div>
                <div class="btn-update-setiing">
                    <my-button label="Update" :active="isActiveBtn" @click="updateData" style="width: 320px;"/>
                </div>    
            </div>
        </div>

    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin';
import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'type business',
        mixins: [configMixin, loaderMixin],
        data() {
            return {
                formData: {
                    name: '',
                    country: '',
                    state: '',
                    adress: '',
                    zip: '',
                    checks: 'Self-Employed',
                },
                country: [],
                state: [],
                isActiveBtn: false,
                isRedact: false,
                width: 300,
                index: 0
            }
        },
        watch: {
            'formData.name': function() {
                this.sendData()
            },
            'formData.state': function() {
               
                this.sendData()
            },
            'formData.adress': function() {
                this.sendData()
            },
            'formData.zip': function() {
                this.sendData()
            },
            'formData.checks': function() {
                this.sendData()
            },
        },
        methods: {
            Country(value) {
                this.formData.country = value
            },
            State(value) {
                this.formData.state = value
            },
            updateData() {
                if(this.isActiveBtn) {
                    let body = {
                        zip: this.formData.zip,
                        address: this.formData.adress,
                        type: this.formData.checks,
                        state: this.formData.state,
                        name: this.formData.name
                    }

                    let response = async() => {

                        let query = await fetch(this.config.API_URL + '/business', {
                            method: "PUT",
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }
                    response().then(data => {
                        this.$router.go()
                    })
                }

            },
            sendData() {
                this.index++
                if(this.index > 5) {
                    this.isRedact = true
                    if(this.isRedact) {
                        this.isActiveBtn = true
                    }
                }
            },
            getBusinessData() {
                let response = async() => {

                    let query = await fetch(this.config.API_URL + '/business/data', {
                        method: "GET",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            },
            checks() {
                this.formData.checks = "Self-Employed"
                this.sendData()
            },
            checks2() {
                this.formData.checks = "LLC"
                this.sendData()
            },
            checks3() {
                this.formData.checks = "C-corp"
                this.sendData()
            },
            checks4() {
                this.formData.checks = "Partnership"
                this.sendData()
            },
            checks5() {
                this.formData.checks = "Other"
                this.sendData()
            },
            codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.isActiveBtn) {
                        this.updateData();
                    }
                }
            }
        },
        async beforeMount() {
            if(window.innerWidth <= 500) this.width = 320
            await this.getBusinessData().then(data => {
                this.formData.name = data.data.name
                this.formData.state = data.data.state
                this.formData.adress = data.data.address
                this.formData.zip = data.data.zip
                this.formData.checks = data.data.type
                this.formData.country = data.data.country

                this.setLoader(false);
            })

           

            let query = await fetch('https://www.universal-tutorial.com/api/getaccesstoken', {
            method: "GET",
            headers: {
                "Accept": "application/json",
                'api-token': "-g27lPGpwWOzarFcylW620SzveZ7k25ziZdoiO8jvAQ4pIDtb6_LmCZ7Dpxj1ywZrF0",
                'user-email': "qwerty@gmail.ru"
            }
        })

        let json = await query.json()
        this.token = json['auth_token']

        fetch('https://www.universal-tutorial.com/api/countries', {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + this.token,
            }
        }).then(data => data.json()).then(data => {

            let index = data.findIndex(el => el.country_name == this.formData.country)
            console.log(this.formData.country)
            data.splice(index, 1)
            data.unshift({country_name: this.formData.country})
            
            this.country = data.map(el => el.country_name)
        })

        fetch('https://www.universal-tutorial.com/api/states/United States', {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + this.token,
                }
        }).then(data => data.json()).then(data => {
            let index = data.findIndex(el => el.state_name == this.formData.state)

            data.splice(index, 1)
            data.unshift({state_name: this.formData.state})

            this.state = data.map(el => el.state_name)
        })

        // fetch('https://www.universal-tutorial.com/api/cities/Alabama', {
        //         method: "GET",
        //         headers: {
        //             "Accept": "application/json",
        //             "Authorization": "Bearer " + this.token,
        //         }
        //     }).then(data => data.json()).then(data => {
        //         let index = data.findIndex(el => el.city_name == this.formData.state)

        //         data.splice(index, 1)
        //         data.unshift({state_name: this.formData.state})

        //         this.formData.City = data[0].city_name
        //         this.city = data.map(el => el.city_name)
        //     })
        }
        
    }
</script>