<template>
    <div class="container-manager-esta">
        <div class="flex flex-content-mob">
            <div class="flex data-div">
                <img v-if="avatar != null && avatar != undefined && avatar != ''" :src="'/img/uploads/logo/esta/' + avatar" class="logo-staff"/>
                <div class="data-info">
                    <p class="name" @click="$router.push('/establishment/member/' + id)">{{ name }}</p>
                    <div class="access flex">
                        <div v-if="showAccess && this.$route.user.job != 'Manager'">
                            <span @click="setAccess('Full access'), setShowAccess()" class="pointer">Full access</span><br> <span  class="pointer" @click="setAccess('Branch access'), setShowAccess()">Branch access</span>
                        </div>
                        <div v-else>
                            <span>{{ (ac == undefined || ac == null) ? acces : ac  }}</span><br>
                        </div>
                        <img src="../assets/img/UI/access-esta.svg" @click="setShowAccess" class="pointer" v-if="this.$route.user.job != 'Manager'"></div>
                </div>
            </div>
            <p :class="{'phone': true, 'not-active': !activate}">{{ phone }}</p>
            <p v-if="!activate" class="not-act">Activation required</p>
            <img src="../assets/img/UI/close-calendar.svg" class="close pointer" @click="delManager" v-if="this.$route.user.job != 'Manager'">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'manager-esta',
        props: {
            name: String,
            avatar: String,
            phone: String,
            activate: Boolean,
            id: String,
            ac: String
        },
        data() {
            return {
                showAccess: false,
                acces: (this.ac == undefined || this.ac == null) ? 'Full access' : this.ac
            }
        },
        methods: {
            setAccess(value) {
                if(this.$route.user.job != 'Manager') {
                    this.acces = value
                    this.$emit('changeaccess', value, this.id)
                }
            },
            setShowAccess() {
                this.showAccess = !this.showAccess
            },
            delManager(id) {
                if(this.$route.user.job != 'Manager') this.$emit('delete', this.id)
            }
        }
    }
</script>

<style scoped>
.access, .name {
    white-space: nowrap;
}
.close {
    margin-left: auto;
}
.not-act {
    margin-top: 13px;
    color: #F28E22;
    margin-left: 80px;
}
.phone {
    margin-left: 174px;
    color: white;
    font-size: 16px;
    margin-top: 13px;
}
.not-active {
    color: #929292;
}
.access img {
    margin-left: 4px;
}
.access div span {
    color: #ABABAB;
    font-size: 14px;
}
.access {
    margin-top: 6px;
}
.data-info {
    margin-left: 12px;
}
.name {
    color: white;
    font-size: 16px;
    font-family: 'Rubik-Regular';
}
.logo-staff {
    object-fit: cover;
    width: 42px;
    height: 42px;
    border-radius: 100%;
}
    .container-manager-esta {
        width: 940px;
        border-radius: 10px;
        background-color: #252525;
        padding: 12px;
    }
    @media screen and (max-width: 500px) {
        .container-manager-esta {
            background-color: #2C2C2C;
            width: 290px;
        }
        .close {
            order: 2;
            margin-top: -40px;
        }
        .data-div {
            order: 1;
        }
        .phone {
            order: 3;
            margin-left: 0;
            margin-top: 30px;
        }
        .not-act {
            order: 4;
            margin-left: 0;
        }
    }
</style>