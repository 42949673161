import configMixin from "./configMixin";


export default {
    mixins: [configMixin],
    methods: {
        setEmployee(esta_id, team_id, name, last_name, username, email, phone, id, access) {
            let sendObject = {}
            console.log(access)
            if(esta_id?.id != undefined && esta_id?.id != null && esta_id?.id != '') {
                sendObject.establishment_id  = esta_id.id
            }
            
            if(team_id?.id != undefined && team_id?.id != null && team_id?.id != '') {
                sendObject.team_id = team_id.id
            }
           
            if(access == 'Full access') sendObject.acces = 'Full access'
            else if(access == "Branch access") sendObject.acces = 'Branch access'
            
            sendObject.last_name = (last_name == undefined || last_name == null || last_name == '') ?
            null : last_name
            
            sendObject.name = name
            if(username != null && user != undefined && username != '')sendObject.username = username
            sendObject.email = email
            if(phone != undefined && phone != null && phone != '') {
                sendObject.phone = phone.split(" ").join("")
            }

            sendObject = JSON.stringify(sendObject)

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/staff/' + id, {
                    method: "PUT",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    },
                    body: sendObject
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}