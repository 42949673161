import configMixin from './configMixin'
var QRCode = require('qrcode')

export default {
    mixins: [configMixin],
    methods: {
        getDataApi(object) {

            let response = async () => {
                let query = await fetch(this.config.API_URL + '/user/qr/' + object, {
                    method: "GET",
                })

                let result = await query.json()

                return Promise.resolve(result) 
            }
            return response()
        }
    },
    beforeMount() {
        let object = (localStorage.getItem('userData.email') == undefined ||
        localStorage.getItem('userData.email') == null) ? localStorage.getItem('userData.phone') :
        localStorage.getItem('userData.email')
        
        this.getDataApi(object).then(data => {
            console.log(data)
            let result;
            QRCode.toDataURL(window.origin + '/client/employee/' + data.qr_id, (err, url) => {
                result = url
                this.qr_code = result
            })
            let last_Name = (data.last_name == null) ? "" : data.last_name
            this.name = data.name + " " + last_Name,
            this.job = data.job
            this.text = window.origin + '/@' + data.username
        })
    }
}