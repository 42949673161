import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        Auth(login, password) {
            let body = {
                login: login,
                password: password
            }

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/auth', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}