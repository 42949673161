<template>
     <div class="flex mt-3 pages-b-b" v-if="Number(count) <= 5">
                <img src="../assets/img/UI/left-b.svg">
                <p v-for="el in count" class="pages-b-b-el pointer" @click="$router.push(link + '?page=' + el)" :class="{'pages-b-b-active': (($route.query.page == undefined && el == 1) || el == $route.query.page)}">{{ el }}</p>
                <img src="../assets/img/UI/right-b.svg">
            </div>
            <div class="flex mt-3 pages-b-b" v-else-if="Number(count) > 5 && ($route.query.page == 1 ||  $route.query.page == undefined || ((Number($route.query.page) >= 1 && Number($route.query.page) <= 4)))">
                <img src="../assets/img/UI/left-b.svg">
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': ($route.query.page == 1 || $route.query.page == undefined)}" @click="$router.push(link + '?page=1')">1</p>
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': $route.query.page == 2}" @click="$router.push(link + '?page=2')">2</p>
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': $route.query.page == 3}" @click="$router.push(link + '?page=3')">3</p> 
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': $route.query.page == 4}" @click="$router.push(link + '?page=4')">4</p> 
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': $route.query.page == 5}" @click="$router.push(link + '?page=5')">5</p>  
                <p class="pages-b-b-el pointer">...</p>
                <p class="pages-b-b-el pointer" @click="$router.push(link + '?page=' + count)">{{ count }}</p>
                <img src="../assets/img/UI/right-b.svg">
            </div>
            <div class="flex mt-3 pages-b-b" v-else-if="Number(count) > 5 && ($route.query.page == count ||(Number($route.query.page) >= Number(count - 3) && Number($route.query.page) <= Number(count)))">
                <img src="../assets/img/UI/left-b.svg">
                <p class="pages-b-b-el pointer" @click="$router.push(link + '?page=1')">1</p>
                <p class="pages-b-b-el pointer">...</p>
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': $route.query.page == count - 4}" @click="$router.push(link + '?page=' + String(count - 4))">{{ count - 4}}</p> 
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': $route.query.page == count - 3}" @click="$router.push(link + '?page=' + String(count - 3))">{{ count - 3}}</p> 
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': $route.query.page == count - 2}" @click="$router.push(link + '?page=' + String(count - 2))">{{ count - 2}}</p> 
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': $route.query.page == count - 1}" @click="$router.push(link + '?page=' + String(count - 1))">{{ count - 1}}</p> 
                <p class="pages-b-b-el pointer" :class="{'pages-b-b-active': $route.query.page == count}" @click="$router.push(link + '?page=' + count)">{{ count }}</p>
                <img src="../assets/img/UI/right-b.svg">
            </div>
            <div class="flex mt-3 pages-b-b" v-else>
                <img src="../assets/img/UI/left-b.svg">
                <p class="pages-b-b-el pointer" @click="$router.push(link + '?page=1')">1</p>
                <p class="pages-b-b-el pointer">...</p>
                <p class="pages-b-b-el pointer" @click="$router.push(link + '?page=' + String(Number($route.query.page) - 2))">{{ Number($route.query.page) - 2}}</p>
                <p class="pages-b-b-el pointer" @click="$router.push(link + '?page=' + String(Number($route.query.page) - 1))">{{ Number($route.query.page) - 1}}</p> 
                <p class="pages-b-b-el pointer pages-b-b-active" @click="$router.push(link + '?page=' + $route.query.page)">{{ Number($route.query.page) }}</p> 
                <p class="pages-b-b-el pointer" @click="$router.push(link + '?page=' + String(Number($route.query.page) + 1))">{{ Number($route.query.page) + 1}}</p> 
                <p class="pages-b-b-el pointer" @click="$router.push(link + '?page=' + String(Number($route.query.page) + 2))">{{ Number($route.query.page) + 2}}</p> 
                <p class="pages-b-b-el pointer">...</p>
                <p class="pages-b-b-el pointer"  @click="$router.push(link + '?page=' + count)">{{ count }}</p>
                <img src="../assets/img/UI/right-b.svg">
            </div>
</template>

<script>
    export default {
        name: 'paginate',
        data() {
            return {
                pages: []
            }
        },
        props:{
            count: Number,
            link: String
        }
    }

</script>

<style scoped>
@media screen and (max-width: 500px) {
    .pages-b-b {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>