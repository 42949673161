<template>
<div id="learning-menu" class="dasboard-nav-panel-mobile">
        <h2 class="logo-dashboard pointer" @click="$router.push('/employee/workspace')">MERCI<span class="h orange-color logo-dashboard">Q</span></h2>
        <img src="../../assets/img/UI/mobile-menu.svg" @click="setMobile"/>
    </div>
    <div class="null-div-mobile"></div>

    <div class="dasboard-nav-panel min-height" ref="menu">
        <h2 class="logo-dashboard none-mobile pointer" @click="$router.push('/employee/workspace')">MERCI<span class="h orange-color logo-dashboard">Q</span></h2>
        <div id="learning-user-dashboard" class="div-data-user">
            <img 
            :src="'/img/uploads/logo/esta/' + userData.avatar" 
            class="img-user"/>
            <p class="tetler">{{userData.name}}</p>
            <div class="flex star-div-dash">
                <img src="../../assets/img/UI/start.svg">
                <p>{{userData.stars}}</p>
            </div>
        </div>
        <div id="learning-desktop-menu" class="dashboard-inner">
            <div class="dashboard-li-punkt flex main-menu-empl pointer" @click="(demo == true) ? $router.push('/demo/workspace') : $router.push('/employee/workspace')">
                <p class="ml-1">Dashboard</p>
            </div>
            <div class="dashboard-li-punkt flex main-menu-empl mt-1 pointer" @click="(demo == true) ? $router.push('/demo/finance') : $router.push('/employee/finance')">
                <p class="ml-1">Finance history</p>
            </div>
            <div class="dashboard-li-punkt flex main-menu-empl mt-1 pointer" @click="(demo == true) ? $router.push('/demo/statistic') : $router.push('/employee/statistic')">
                <p class="ml-1">Statistic</p>
            </div>
            <div class="dashboard-li-punkt flex main-menu-empl mt-1 pointer" @click="(demo == true) ? $router.push('/demo/profile') : $router.push('/employee/profile')">
                <p class="ml-1">Profile settings</p>
            </div>
            <div class="dashboard-li-punkt flex main-menu-empl mt-1 pointer" @click="(demo == true) ? $router.push('/demo/qr') : $router.push('/employee/qr')">
                <p class="ml-1">QR-code</p>
            </div>
            <div class="dashboard-li-punkt flex main-menu-empl mt-1 pointer" v-if="demo != true" @click="goToInstruction">
                <p class="ml-1">Instructions</p>
            </div>
            <div class="dashboard-li-punkt flex main-menu-empl mt-1 pointer" v-if="demo != true" @click="$router.push('/nfc')">
                <p class="ml-1">Get your NFC card</p>
            </div>
            <div class="dashboard-li-punkt flex main-menu-empl mt-1 pointer" v-if="demo != true" @click="$router.push('/employee/stripe')">
                <p class="ml-1">Verification</p>
            </div>
            <button class="profile-btn profile-btn-red mt-1" style="width: 100%;" @click="LogOut">Log out</button>

        </div>
    </div>
    
    <div class="main-menu-mobile" ref="mobile" @click="onMobileMenuClick">
        <div class="dasboard-nav-panel-mobile">
            <h2 class="logo-dashboard" @click="$router.push('/employee/workspace')">MERCI<span class="h orange-color logo-dashboard">Q</span></h2>
            <img src="../../assets/img/UI/close-staff.svg" @click="setMobile" class="pointer"/>
        </div>
        <div style="height: 50px;"></div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el flex-between" @click="(demo == true) ? $router.push('/demo/workspace') : $router.push('/employee/workspace')">
            <p class="ml-1">Dashboard</p>
            <img src="../../assets/img/UI/EmplDashStrel.svg" class="left-strel-dash">
        </div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el flex-between" @click="(demo == true) ? $router.push('/demo/finance') : $router.push('/employee/finance')">
            <p class="ml-1">Finance history</p>
            <img src="../../assets/img/UI/EmplDashStrel.svg" class="left-strel-dash">
        </div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el flex-between" @click="(demo == true) ? $router.push('/demo/statistic') : $router.push('/employee/statistic')">
            <p class="ml-1">Statistic</p>
            <img src="../../assets/img/UI/EmplDashStrel.svg" class="left-strel-dash">
        </div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el flex-between" @click="(demo == true) ? $router.push('/demo/profile') : $router.push('/employee/profile')">
            <p class="ml-1">Profile settings</p>
            <img src="../../assets/img/UI/EmplDashStrel.svg" class="left-strel-dash">
        </div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el flex-between" @click="(demo == true) ? $router.push('/demo/qr') : $router.push('/employee/qr')">
            <p class="ml-1">QR-code</p>
            <img src="../../assets/img/UI/EmplDashStrel.svg" class="left-strel-dash">
        </div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el flex-between" v-if="demo != true" @click="goToInstruction">
            <p class="ml-1">Instructions</p>
            <img src="../../assets/img/UI/EmplDashStrel.svg" class="left-strel-dash">
        </div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el flex-between" v-if="demo != true" @click="$router.push('/nfc')">
            <p class="ml-1">Get your NFC card</p>
            <img src="../../assets/img/UI/EmplDashStrel.svg" class="left-strel-dash">
        </div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el flex-between" v-if="demo != true" @click="$router.push('/employee/stripe')">
            <p class="ml-1">Verification</p>
            <img src="../../assets/img/UI/EmplDashStrel.svg" class="left-strel-dash">
        </div>
        <button class="profile-btn profile-btn-red mt-1" style="width: 295px; margin-left: auto;margin-right: auto; display: flex; justify-content: center;" @click="LogOut">Log out</button>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'

export default {
    name: 'employee-dashboard',
    mixins: [configMixin],
    data() {
        return {
            userData: {
                name: '',
                avatar: ''
            }
        }
    },
    props: {
        demo: Boolean
    },
    emits: ['getavatar'],
    methods: {
        setMenu() {
            if(this.$refs.menu.style.display == 'block') {
                this.$refs.menu.style.display = 'none'
            }
            else {
                this.$refs.menu.style.display = 'block'
            }
        },
        setMobile() {
            if(this.$refs.mobile.style.display == 'block') {
                this.$refs.mobile.style.display = 'none'
            }
            else {
                this.$refs.mobile.style.display = 'block'
            }
        },
        getNavData() {
            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/nav', {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    },
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response().then(data => {

                this.userData.name = data.user.name + " " + data.user.last_name
                this.userData.avatar = data.user.avatar
                this.$emit('getavatar', data.user.avatar)
                this.userData.stars = Number(data.stars).toFixed(1)
            })
        },
        onMobileMenuClick(event) {
            const target =  event.target;
            const parent = target.parentNode;

            if (target.classList.contains('dashboard-li-punkt') 
                || target.parentNode.classList.contains('dashboard-li-punkt')
            ) {
                this.$refs.mobile.style.display = 'none';                
            }
        },
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        LogOut() {
            localStorage.removeItem('auth-token')
            this.$router.push('/')
        },
        goToInstruction() {
            if (this.isMobile()) {
                window.location.href='/docs/USER_GUIDE_FOR_PERSONAL_ACCOUNT_MERCIQ_mob.pdf';
            } else {
                window.location.href='/docs/WEB_USER_GUIDE_PERSONAL_ACCOUNT_MERCIQ.pdf';
            }
        }
    },
    beforeMount() {
        if(this.demo != true) {
            this.getNavData().then(data => {
                console.log(data)
            })
        } else {
            this.userData.name = "Demo"
            this.userData.avatar = '1487716857-user_81635.svg'
            this.$emit('getavatar', this.userData.avatar)
            this.userData.stars = '4.0'
        }
    }
}
</script>

<style scoped>
* {
    z-index: 5;
}
.main-menu-mobile {
    width: 100%;
    display: none;
}
.dasboard-nav-panel-mobile {
    display: none;
    
}
.dasboard-nav-panel-mobile {
    padding-left: 20px;
    height: 54px;
    width: 100%;
    background-color: #1E1E1E;
}
.dasboard-nav-panel-mobile img{
    margin-left: auto;
    margin-right: 23px;
    width: 18px;
}
.dasboard-nav-panel {
    margin-right: 10px;
    border: none;
    padding-top: 40px;
    width: 280px;
    background-color: var(--main-bg-color);
}
.dashboard-inner {
    width: 220px;
    margin-left: 30px;
    margin-top: 37px;
}
.logo-dashboard {
    text-align: center;
    font-size: 18px;
}
.user-title-dashboard p {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    color: var(--main-text-color);
    font-family: 'Rubik-Regular';
}
.dashboard-li .title-p {
    font-size: 14px;
    font-family: 'Rubik-Regular';
    color: var(--not-active-text-color);
}
.dashboard-li-punkt {
    padding-top: 15px;
    width: 100%;
    background-color: var(--dashboard-el);
    padding-bottom: 15px;
    border-top: 1px solid #363636;
}
.dashboard-li-punkt img {
    margin-left: 16px;
}
.dashboard-li-punkt p{
    color: var(--main-text-color)
}
.first {
    margin-top: 15px;
    border-radius: 10px 10px 0 0;
    border: none;
}
.last {
    border-radius: 0 0 10px 10px;
}
.lang-panel {
   justify-content: center;
   margin-top: 92px;
   padding-bottom: 40px;
}
.lang-panel p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    font-size: 16px;
    color: var(--main-text-color)
}
.one {
    border-radius: 10px;
    border: none;
    margin-top: 15px;
}
.main-menu-empl {
    border-radius: 10px;
    border: none;
}
.div-data-user {
    width: 100%;
    text-align: center;
    margin-top: 154px;
}
.img-user {
    width: 80px;
    border-radius: 100%;
}
.tetler {
    font-family: 'Rubik-Regular';
    font-size: 18px;
    color: white;
    margin-top: 15px;
}
.star-div-dash {
    margin-top: 10px;
    justify-content: center;
}
.star-div-dash p {
    color: #F28E22;
    font-family: 'Rubik-Regular';
    font-size: 16px;
    margin-left: 5px;
}
@media screen and (max-width: 500px) {
    .left-strel-dash  {
        margin-left: auto;
    }
    .main-menu-mobile {
        display: none;
        background-color: #1E1E1E;
        width: 100%;
        position: fixed;
        z-index: 10;
        height: 100vh;
    }
    .mobile-login {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        margin-top: 50px;
    }
    .main-menu-mobile-el {
        margin-left: auto;
        margin-top: 10px;
        border: none;
        margin-right: auto;
        width: 320px;
        cursor: pointer;
        background-color: #252525;
        padding: 20px;
        padding-left: 5px;
        border-radius: 10px;
    }
    .dasboard-nav-panel {
        display: none;
        position: absolute;
    }
    body {
        opacity: 1;
    }
    .logo-dashboard {
        text-align: left;
        margin-top: 15px;
    }
    .dashboard-inner {
        margin-top: 0px;
    }
    .none-mobile {
        display: none;
    }
    .dasboard-nav-panel-mobile {
        position: fixed;
        display: flex;
    }
    .null-div-mobile {
        height: 40px;
        position: relative;
        z-index: -1;
    }
}
</style>