<template>
    <add-member @addempl="AddEmployeeFunc('1')" v-if="isAddMemberTeam" @close="test" @getmembers="updateEmployee"/>
    <add-team v-if="isActiveAdd" @close="setAddFroms" @getdata="getData" @addempl="AddEmployeeFunc"/>
    <delete-estas v-if="isActiveDelete" @close="closeEsta" @delete="deleteEsta"/>

    <add-new-empl v-if="isActiveAddNewEmpl"
    :team_id="select_team_employ"
    :establishment_id="establishment_id"
    @close="AddEmployeeFunc"
    @created="AddEmployeeFunc"/>

    <are-sure 
    style="position: fixed;" 
    title="Delete team" 
    :id="el_team_id" 
    v-if="isDeleteTeam" 
    @close="setDeleteTeam" 
    @delete="deleteTeam"/>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': isActiveAdd || isAddMemberTeam || isDeleteTeam || isActiveAddNewEmpl, 'staff-wrapper': true}">
            <div class="flex">
                <img src="../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/dashboard')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">{{estaData.name}}</h2>     
                <!-- <h2 class="main-title-dash">{{estaData.name}}</h2>      -->
            </div>
            <div class="main-staff main-team-st">
                <div class="main-nav-mobile d-none-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex">
                        <h2 :class="{ active_nav_panel: false}" @click="$router.push('/establishment/transaction/' + this.$route.params?.establishment_id)" >Transactions</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': true}" @click="$router.push('/establishment/team/' + $route.params?.establishment_id)">Team</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/establishment/managers/' + this.$route.params?.establishment_id)" >Managers</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/establishment/information/' + + this.$route.params?.establishment_id)">Information</h2>
                    </div>
                    <div class="main-line-menu">
                        <div class="menu-mobile-nav-line flex">
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-1"></div>
                            <div :class="{'fr-main-inp-active': true}" class="mobile-nav-est-2"></div>
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-3"></div>
                            <div  :class="{'fr-main-inp-active': false}" class="mobile-nav-est-4"></div>
                        </div>
                    </div>
                </div>

                <div class="top-bar-staff d-none-desc">
                <div class="flex">
                    <div class="first-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/transaction/' + this.$route.params?.establishment_id)">Transactions</p>
                    </div>
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true}" @click="$router.push('/establishment/team/' + $route.params?.establishment_id)">Team</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/managers/'+ $route.params?.establishment_id)">Managers</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/information/'+ $route.params?.establishment_id)">Information</p>
                    </div> 
                    <div class="delete-esta-btn" @click="setActiveDel"  v-if="this.$route.user.job != 'Manager'">Delete establishment</div>
                </div>
                <div class="border-staff flex">
                    <div :class="{'active-first': true, 'active-nav-staff': false, 'trans-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': true, 'team-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'manage-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'info-line': true}"></div>
                </div>
               </div>
               <div class="div-null-height"></div>
               <my-button id="learning-add-new-team"  class="btn-add-new-team" style="border-radius: 10px; display: flex; justify-content: center;align-items: center;background-color: rgba(242, 142, 34, 0.1);" @click="setAddFroms">
                        <img src="../../assets/img/UI/plus-staff.svg">
                        <p class="ml-2 main-text-color" style="font-family: 'Rubik-Regular';">Add new team</p>
                </my-button>
                <div class="team-esta mt-2-mobile" v-for="team in members">
                    <div class="head-team-esta flex">
                        <img src="../../assets/img/UI/person-white.svg">
                        <p>{{ team.name }}</p>
                        <img src="../../assets/img/UI/del-btn-red.svg" class="del-team-btn pointer" @click="setDeleteTeam(team.id)">
                    </div>
                    <staff-user  v-for="member in team.users" :img="member.avatar" class="team-esta-el pointer" :name="member.name + ' ' + member.last_name" :job="member.job" setting="true" @delete="deleteUserFromTeam(member.id)" :id="member.id" />
                    <div class="add-member-manager add-memeber-esta m-x-auto" @click="setMemberAdd(team.id)" >
                        <img src="../../assets/img/UI/add-member.svg">
                        <span>add member</span>
                    </div>
                </div>
            </div>
            <paginate :count="Number(lastpage)" 
            :link="'/establishment/team/' + $route.params?.establishment_id"/>
        </div>
    </div>
</template>

<script>
import establishmentMixin from '@/mixins/establishmentMixin'
import getTeamsEstaMixin from '@/mixins/getTeamsEstaMixin'
import configMixin from '@/mixins/configMixin'
import validEstablishmentMixin from '@/mixins/validEstablishmentMixin'

import TgInstance from '@/components/instance/tourguide'
import loaderMixin from '@/mixins/loaderMixin'

 export default {
    name: 'main establishment',
    mixins: [establishmentMixin, getTeamsEstaMixin, configMixin, validEstablishmentMixin, loaderMixin],
    data() {
        return {
            isActiveAdd: false,
            isAddMemberTeam: false,
            isDeleteTeam: false,
            el_team_id: 0,
            select_team_employ: 0,
            members: [],
            isActiveNew: '0',
            lastpage: 0,
            estaData: {
                name: ''
            },
            isActiveAddNewEmpl: false,
            establishment_id: '',
            team_id: ''
        }
    },
    watch: {
        '$route.query.page': function(){
            this.getEstaTeam(this.$route.params?.establishment_id).then(data => {
                this.estaData.name = data.name
                this.members = data.rows
                this.lastpage = Math.ceil(data.count / 10)
            })
        },
        '$route.params.establishment_id': function() {
            this.getEstaTeam(this.$route.params?.establishment_id).then(data => {
                this.estaData.name = data.name
                this.members = data.rows
                this.lastpage = Math.ceil(data.count / 10)
            })
        }
    },
    methods: {
        setActiveEmpl() {
            this.isActiveAddNewEmpl = !this.isActiveAddNewEmpl
        },
        AddEmployeeFunc(val) {
            if(val == '1' || this.isActiveNew == '1') {
                this.isActiveNew = '1'
                this.isActiveAddNewEmpl = !this.isActiveAddNewEmpl
                this.isAddMemberTeam = !this.isAddMemberTeam
            } else {
                this.isActiveNew = '0'
                this.isAddMemberTeam = !this.isAddMemberTeam
                this.isActiveAddNewEmpl = !this.isActiveAddNewEmpl
                this.isActiveAdd = !this.isActiveAdd
            }
        },
        deleteTeam(id) {
            let response = async() => {
                let query = await fetch(this.config.API_URL + '/team/' + id, {
                    method: "DELETE",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }
            response().then(data => {
                
                window.location.reload();
            })
        },
        setDeleteTeam(id) {
            this.el_team_id = id
            this.isDeleteTeam = !this.isDeleteTeam
        },
        deleteUser(id) {
            this.members.forEach((el, i) => {
                el.users.forEach((member, a) => {
                    if(member.id == Number(id)) this.members[i].users.splice(a, 1)
                })
            })
        },
        deleteUserFromTeam(id) {
            let response = async () => {
                let body = {
                    establishment_id: this.$route.params?.establishment_id
                }

                let query = await fetch(this.config.API_URL + '/team/user/delete/' + id, {
                    method: "PUT",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                })

                let result = await query.json()

                return Promise.resolve(result)
            }
            return response().then(data => {
                this.deleteUser(id)
            })
        },
        setAddFroms() {
            this.isActiveAdd = !this.isActiveAdd
        },
        getData(name, data) {
            this.setAddFroms()
        },
        setMemberAdd(id) {
            this.select_team_employ = id
            this.isAddMemberTeam = !this.isAddMemberTeam
        },
        updateEmployee(users) {
            let body = {
                team_id: this.select_team_employ,
                users: users.map(el => {
                    return el.id
                }),
            }
            let response = async() => {
                let query = await fetch(this.config.API_URL + '/team/add/members/' +  this.$route.params?.establishment_id, {
                    method: "PUT",
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                        'Content-Type': "application/json"
                    },
                    body: JSON.stringify(body)
                })

                if(query.status != 200)  this.$router.push('/dashboard')

                let result = await query.json()

                if(result?.id == undefined 
                || result?.id == null) this.$router.push('/dashboard')

                return Promise.resolve(result)
            }
            return response().then(data => {
                window.location.reload();
            })
        }
    },
    beforeMount() {
        if(this.$route.params?.establishment_id == null || 
        this.$route.params?.establishment_id == 'undefined' ||
        this.$route.params?.establishment_id == undefined ||
        isNaN(Number(this.$route.params?.establishment_id))) return this.$router.push('/dashboard')

        this.getEstaTeam(this.$route.params?.establishment_id).then(data => {
            this.estaData.name = data.name
            this.establishment_id = data['id']
            this.members = data.rows
            this.lastpage = Math.ceil(data.count / 10)

            this.setLoader(false);
        })
    },
    async created() {
        const tgInstance = TgInstance.instance;
        if (tgInstance) {
            console.log ('TgInstance.instance is set');

            const groupName = tgInstance.groupName;
            if (!tgInstance.tg.isFinished(groupName))
                tgInstance.tg.visitStep(9);
        }
    }
 }
</script>