<template>

    <div class="container-dash">
        <employee-dashboard />
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'dop-wrpapper-setting': true}">
            <div class="flex">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.go(-1)">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0;">Verification</h2>     
            </div>
            <div class="mt-2">
                <div class="flex mt-1">
                    <p class="stripe-status white-text">Payment: <span class="error-input-text err-au" 
                        :class="{'green-text': (aboutStatus.paymentStatus == 'Enabled') ? true : false}">{{ aboutStatus.paymentStatus }}</span></p>
                    <p class="stripe-status white-text ml-4">Payouts: <span class="error-input-text err-au"
                        :class="{'green-text': (aboutStatus.payoutsStatus == 'Enabled') ? true : false}">{{ aboutStatus.payoutsStatus }}</span></p>
                </div>
                <div class="warning-message mt-1" style="width: 100%">
                        <img src="../../../assets/img/UI/warning.svg">
                        <p class="ml-1" style="text-align: left; font-size: 14px">By law, Stripe (our payment provider) must request your data to ensure that you are by birth dared to be who you say you are. Your data is intended for identity verification ONLY; Stripe is required by federal law to officially know someone associated with the account. Your information is safe
MerciQ does not have access to or store it. Stripe does not check credit.
therefore, entering your details for this purpose will not affect your credit rating. MerciQ does not have access to your banking details, so we do not store them and your privacy is strictly controlled. Stripe saves all the information you enter into Stripe
, safe using bank-level security measures.</p>   
                        </div>
                        <div class="warning-message mt-3" style="width: 100%">
                            <img src="../../../assets/img/UI/warning.svg">
                            <p class="ml-1" style="text-align: left; font-size: 14px">If everything is filled out, but your account, payment or payouts are still not active, then wait a while. If you have a question, write to us</p>   
                        </div>
                        <div id="container" v-show="aboutStatus.payoutsStatus != 'Enabled'" class="verif-stripe-div" width="320">
                            <div v-if="hasError">Something went wrong!</div>
                        </div>
                        <div class="verif-company-div" style="align-items: center;">
                            <p> Personal information about the administrator of the company</p>
                            <div><img 
                            src="../../../assets/img/UI/access-esta.svg"
                            :style="{'transform': (!DOM.block.isActiveAdmin) ? '' : 'rotate(180deg)'}"
                            style="width: 20px !important"
                            class="pointer"
                            @click="setAdminBlockActive">
                            </div>
                        </div>
                        <div v-if="DOM.block.isActiveAdmin"> 

                             <div class="flex flex-column" style="margin-left: auto; align-items: left;">
        
                                <my-input type="text" label="Address" style="margin-top: 20px;" v-if="filled.personal.address" v-model="admin.address"/>
                                <my-input type="text" label="Name" v-if="filled.personal.name" class="mt-2" v-model="admin.name"/>
                                <my-input type="text" label="Lastname" v-if="filled.personal.lastname" class="mt-2" v-model="admin.lastname"/>
                                <my-input type="date" label="Date of Birth" style="outline: none;" v-if="filled.personal.birthday" class="mt-2" v-model="admin.birthday" />
        
                                <vue-tel-input wrapperClasses="rg" 
                                inputClasses="rtb" class="rg mt-2" 
                                ref="inp" 
                                style="border: none; height: 47px;outline: none; box-shadow: none; background-color: #1E1E1E !important; "  
                                v-model="admin.phone" :style="{'height': '62px'}" 
                                validCharactersOnly="true" 
                                @validate="phoneObject" 
                                @country-changed="setCountryCode"
                                :enabledCountryCode="true"
                                @input="foo"
                                :enabledFlags="false"
                                mode="international" v-if="filled.personal.phone"> 
                                    <template v-slot:arrow-icon>
                                    </template>
                                </vue-tel-input> 
                                <p class="error-input-text err-au" v-if="DOM.error.isAdminPhoneUsed">This phone already exists</p>
                                <my-input label="E-mail" class="mt-2" v-model="admin.email" v-if="filled.personal.email"/>
                                <p class="error-input-text err-au" v-if="DOM.error.isAdminEmailUsed">This email already exists</p>
                                <my-input type="number" label="Social Security Number" v-if="filled.personal.ssn" class="mt-2 verif-input" maxlength="9" v-model="admin.ssn"/>

                                <my-button label="Send" class="mt-2" 
                                v-if="filled.personal.address || filled.personal.name || filled.personal.lastname ||
                                filled.personal.birthday || filled.personal.phone || filled.personal.email" 
                                :active="DOM.btn.isActiveAdmin" @click="updatePersonal"/>

                                <div class="filled-verif mt-2" v-if="!filled.personal.address">
                                    <p>Address</p>
                                    <img src="../../../assets/img/UI/filled.svg"/>
                                </div>
                                <div class="filled-verif mt-2" v-if="!filled.personal.name">
                                    <p>Name of the CEO/Representative</p>
                                    <img src="../../../assets/img/UI/filled.svg"/>
                                </div>
                                <div class="filled-verif mt-2" v-if="!filled.personal.lastname">
                                    <p>Lastname of the CEO/Representative</p>
                                    <img src="../../../assets/img/UI/filled.svg"/>
                                </div>
                                <div class="filled-verif mt-2" v-if="!filled.personal.birthday">
                                    <p>Date of Birth</p>
                                    <img src="../../../assets/img/UI/filled.svg"/>
                                </div>
                                <div class="filled-verif mt-2" v-if="!filled.personal.phone">
                                    <p>Phone</p>
                                    <img src="../../../assets/img/UI/filled.svg"/>
                                </div>
                                <div class="filled-verif mt-2" v-if="!filled.personal.email">
                                    <p>Contact e-mail</p>
                                    <img src="../../../assets/img/UI/filled.svg"/>
                                </div>
                                <div class="filled-verif mt-2" v-if="!filled.personal.ssn">
                                    <p>Social Security Number</p>
                                    <img src="../../../assets/img/UI/filled.svg"/>
                                </div>
                            </div>
                        </div>
                       
                <div class="verif-company-div" style="align-items: center;">
                    <p>Information for payouts</p>
                    <div><img 
                    src="../../../assets/img/UI/access-esta.svg"
                    :style="{'transform': (!DOM.block.isActiveExternal) ? '' : 'rotate(180deg)'}"
                    class="pointer"
                    style="width: 20px"
                    @click="setExternal">
                    </div>
                </div>
                <div :style="{display: (DOM.block.isActiveExternal) ? 'block' : 'none'}">
                    
                        <div id="card" class="mt-1"></div>
                        <p class="error-input-text err-au" v-if="DOM.error.isErrorCard">Invalid credit card</p>
                        <my-button label="Send"  :active="true" 
                        @click="updateExternalCard"  v-if="filled.external.cardName ||
                        filled.external.type ||
                        filled.external.cardName || 
                        filled.external.card"
                        class="mt-2"/>
                        
                        <div class="filled-verif mt-2" v-if="!filled.external.cardName ||
                        !filled.external.type ||
                        !filled.external.cardName || 
                        !filled.external.card">
                            <p>The card</p>
                            <img src="../../../assets/img/UI/filled.svg"/>
                        </div>
                    

                </div>
                </div>
                </div>
    </div>
</template>

<script>
import mccMixin from '@/mixins/mccMixin'
import configMixin from '@/mixins/configMixin'
import selectInput from '@/mixins/UI/selectInput'
import checkFilledInput from '@/mixins/fillesInputMixin'
import makeVerifDataObject from '@/mixins/makeVerifDataObject'
import { PUBLISHABLE_KEY } from '@/assets/constants/stripe'
import { loadConnectAndInitialize } from "@stripe/connect-js";

    export default {
    mixins: [makeVerifDataObject, configMixin, mccMixin, selectInput, checkFilledInput],
    name: 'stripe verification',
    data() {
        return {
            cardEl: "",
            stripe: PUBLISHABLE_KEY,
            stripe_id: null,
            aboutStatus: {
                accountStatus: '',
                paymentStatus: '',
                payoutsStatus: ''
            },
            isActiveLogin: false,
            
            admin: {
                country: '',
                state: '',
                city: '',
                address: '',
                name: '',
                lastname: '',
                birthday: new Date().getFullYear() + "-" +  (new Date().getMonth() + 1) + '-' + (new Date().getDate()),
                occupation: '',
                phone: '',
                email: '',
                countryCode: ''
            },
            
            external: {
               cardMonth: '' ,
               cardYear: '',
               holderNameCard: '',
               cardNumber: '',
               accountNumber: '',
               typeAccount: '',
               nameAccount: '',
               routing_number: ''
            },
            DOM: {
                btn: {
                    isActiveCompany: false,
                    isActiveAdmin: false,
                    isActivePublic: false,
                    isActiveExternalAccount: false,
                    isActiveExternalCard: false
                },
                block: {
                    isActiveCompany: false,
                    isActiveAdmin: false,
                    isActivePublicInfo: false,
                    isActiveExternal: false,
                    externalTypeCard: 'account'
                },
                error: {
                    isNotValidBithday: false,
                    isNotOldBithday: false,
                    isAdminPhoneUsed: false,
                    isAdminEmailUsed: false,
                    isPublicPhoneUsed: false,
                    isErrorCard: false
                },
            },
            filled: {
                company: {
                    name: true,
                    state: false,
                    city: false,
                    type: false,
                    address: false,
                    zip: false,
                    tax: false,
                    ssn: false
                }    ,
                personal: {
                    address: false,
                    name: false,
                    lastname: false,
                    birthday: false,
                    phone: false,
                    email: false
                },
                public: {
                    phone: false,
                    website: false,
                    desc: false
                },
                external: {
                    account_number: false,
                    name: false,
                    type: false,
                    month: false,
                    year: false,
                    card: false,
                    cardName: false,
                }
            },
            companyAddress: {
                country: [],
                state: [],
                city: [],
            },
            adminAddress: {
                country: [],
                state: [],
                city: [],
            },
            mccBusinessType: [],
            valid: {
                adminPhoneValid: false,
                publicPhoneValid: false
            },
            hasError: false
        }
    },
    watch: {
        
        'admin': {
            handler() {
                this.DOM.btn.isActiveAdmin = this.validatePersonal()
            },
            deep: true
        },
       
        'external': {
            handler() {
                this.DOM.btn.isActiveExternalAccount = this.validateExternalAccount()
                this.DOM.btn.isActiveExternalCard = this.validExternalCard()
            },
            deep: true
        },
        'admin.birthday': function() {
            this.adminBirthdayValidation()
        },
        'admin.countryCode': function() {
            this.admin.phone = this.admin.countryCode
        },
        'publicInfo.countryCode': function() {
            this.publicInfo.phone = this.publicInfo.countryCode
        },
        'external.month': function() {
            this.external.month = this.external.month.replace(/[^\d]/g,'');
            if(this.external.month.length == 2) this.$refs.year.focus()
            //this.isIsset();
        },
        'external.year': function() {
            this.external.year = this.external.year.replace(/[^\d]/g,'');
            //this.isIsset();
        },
    },
    methods: {
        phoneObject(object) {
                this.valid.adminPhoneValid = object.valid;
        },
        setCountryCode(val) {
            this.admin.countryCode = "+" + val.dialCode
        },
        setCountryCodePublic(val) {
            this.publicInfo.countryCode = "+" + val.dialCode
        },
        phoneObjectPublic(val) {
            this.valid.publicPhoneValid = object.valid;
        },
        adminBirthdayValidation() {
            let nowDate = new Date(); 
            
            let lastDate = new Date(this.admin.birthday); 

            let d = ((nowDate-lastDate) / 1000 / (60 * 60 * 24) / 365.25).toFixed(0)

            if(d > 120) this.DOM.error.isNotValidBithday = true 
            else this.DOM.error.isNotValidBithday = false 

            if(d < 18) this.DOM.error.isNotOldBithday = true 
            else this.DOM.error.isNotOldBithday = false 
        },
        // set DOM for block with form
        setAdminBlockActive() {
            this.DOM.block.isActiveAdmin = !this.DOM.block.isActiveAdmin
        },
        setCompanyBlockActive() {
            this.DOM.block.isActiveCompany = !this.DOM.block.isActiveCompany
        },
        setCompanyBlockPublic() {
            this.DOM.block.isActivePublicInfo = !this.DOM.block.isActivePublicInfo
        },
        setExternal() {
            this.DOM.block.isActiveExternal = !this.DOM.block.isActiveExternal
            if(this.cardEl == "") this.makeCardEl()
        },
        setTypeExternal() {
            this.DOM.block.externalTypeCard = !this.DOM.block.externalTypeCard
        },
        // set select input
        changeCompanyCountry(value) {
            this.company.country = value
        },
        changeCompanyState(value) {
            this.company.state = value
        },
        changeCompanyCity(value) {
            this.company.city = value
        },
        changeCompanyMCC(value) {
            this.company.mcc = value
        },
        changeAdminCountry(value) {
            this.admin.country = value
        },
        changeAdminState(value) {
            this.admin.state = value
        },
        changeAdminCity(value) {
            this.admin.city = value
        },
        // send API query
        updateCompany() {
            if (this.DOM.btn.isActiveCompany) {
                let object = this.makeObjectCompany()

                fetch(this.config.API_URL + '/user/stripe/business/company', {
                    method: "PUT",
                    body: object,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    }
                }).then(data => data.json()).then(data => {
                    console.log(data)
                })
            }
        },
        updatePersonal() {
            if (this.DOM.btn.isActiveAdmin) {
                let object = this.makeObjectPersonal()

                fetch(this.config.API_URL + '/user/stripe/business/personal', {
                    method: "PUT",
                    body: object,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    }
                }).then(data => data.json()).then(data => {
                    console.log(data)
                })
            }
        },
        updatePublic() {
            if (this.DOM.btn.isActivePublic) {
                let object = this.makePublicObject()

                fetch(this.config.API_URL + '/user/stripe/business/public', {
                    method: "PUT",
                    body: object,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    }
                }).then(data => data.json()).then(data => {
                    console.log(data)
                })
            }
        },
        updateExternalAccount() {
            if (this.DOM.btn.isActiveExternalAccount) {
                let object = this.makeExternalAccountObject()

                fetch(this.config.API_URL + '/user/stripe/business/account', {
                    method: "PUT",
                    body: object,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    }
                }).then(data => data.json()).then(data => {
                    console.log(data)
                })
            }
        },
        async updateExternalCard() {
            if (this.DOM.btn.makeExternalCardObject) {
               
                await this.stripe.createToken(this.cardEl, {
                currency: "usd"
            }).then((result) => {
                
                if (result?.token?.id != undefined) {
                    
                    this.cardToken = result?.token?.id
                    
                    this.DOM.error.isErrorCard = false

                    let object = {
                        token: result?.token?.id
                    }
                    fetch(this.config.API_URL + '/user/stripe/business/card', {
                        method: "PUT",
                        body: object,
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                            'Content-Type': 'application/json'
                        }
                    }).then(data => data.json()).then(data => {
                        this.$router.go(0);
                    })
                    this.$router.go(0);
                } else {
                    this.DOM.error.isErrorCard = true
                
                }
            });
            }
        },
        
        makeCardEl() {
             if (this.filled.external.cardName || this.filled.external.type || 
        this.filled.external.cardName || 
        this.filled.external.card) {
            this.stripe = window.Stripe(this.stripe);
                
                const appearance = {
                theme: 'day',
                
                };

                const options = {
                    appearance,
                    fonts: [
                        {
                        // integrate your font into stripe
                        cssSrc: 'https://fonts.googleapis.com/css?family=Rubik:400,500',
                        fontSize: "40px"
                        }
                    ]
                    };
                    const stripeStyle = {
                    style: {
                        base: {
                            fontFamily: 'Rubik-Regular, sans-serif',
                            color: "white",
                            fontSize: "20px"
                        },
                    },
                    hidePostalCode: true
                }
                const elements = this.stripe.elements(options);
                this.cardEl = elements.create("card", stripeStyle);
                this.cardEl.mount('#card');
            }
        }
    },
    async beforeMount() {

        await fetch(this.config.API_URL + '/user/stripe/business', {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
            }
        }).then(data => data.json())
        .then(data => {
            this.stripe_id = data.stripe_id

            this.aboutStatus.paymentStatus = (data.payments_status_stripe != "Enabled" || data.Business.payouts_status_stripe != "Enabled") ? "Disabled" : "Enabled"
            this.aboutStatus.payoutsStatus = (data.payouts_status_stripe != "Enabled" || data.Business.payouts_status_stripe != "Enabled") ? "Disabled" : "Enabled"

            if((data.Business.required_docs?.requirements?.past_due?.length == 0 &&
            data.required_docs?.requirements?.past_due?.length == 0) || (data.Business.required_docs?.requirements?.length == 0 &&
            data.required_docs?.requirements?.length == 0)) {
                this.aboutStatus.accountStatus = "Enabled"
            } else {
                this.aboutStatus.accountStatus = "Restricted"
            }
           
            this.checkFilledInput(data)
        })

        let query = await fetch('https://www.universal-tutorial.com/api/getaccesstoken', {
            method: "GET",
            headers: {
                "Accept": "application/json",
                'api-token': "-g27lPGpwWOzarFcylW620SzveZ7k25ziZdoiO8jvAQ4pIDtb6_LmCZ7Dpxj1ywZrF0",
                'user-email': "qwerty@gmail.ru"
            }
        })

        let json = await query.json()
        this.token = json['auth_token']

        fetch('https://www.universal-tutorial.com/api/countries', {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + this.token,
            }
        }).then(data => data.json()).then(data => {

            let index = data.findIndex(el => el.country_name == 'United States')

            data.splice(index, 1)
            data.unshift({country_name: 'United States'})

            let filteredCountry = data.map(el => el.country_name)
            this.companyAddress.country = filteredCountry
            this.adminAddress.country = filteredCountry
        })

        fetch('https://www.universal-tutorial.com/api/states/United States', {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + this.token,
                }
        }).then(data => data.json()).then(data => {

            let filteredState = data.map(el => el.state_name)
            this.companyAddress.state = filteredState
            this.adminAddress.state = filteredState
        })

        fetch('https://www.universal-tutorial.com/api/cities/Alabama', {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + this.token,
                }
            }).then(data => data.json()).then(data => {
                
                let filteredCity = data.map(el => el.city_name)
                this.companyAddress.city = filteredCity
                this.adminAddress.city = filteredCity
            })

    },
    mounted() {
        const fetchClientSecret = async () => {
        // Fetch the AccountSession client secret
            const response = await fetch(this.config.API_URL + '/user/stripe/business/create-account-session', {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                }
            });
            if (!response.ok) {
                // Handle errors on the client side here
                const {error} = await response.json();
                console.log('An error occurred: ', error);
                this.hasError = true;
                return undefined;
            } else {
                const {client_secret: clientSecret} = await response.json();
                this.hasError = false;
                return clientSecret;
            }
        }

        const stripeConnectInstance = loadConnectAndInitialize({
            // This is a placeholder - it should be replaced with your publishable API key.
            // Sign in to see your own test API key embedded in code samples.
            // Don’t submit any personally identifiable information in requests made with this key.
            publishableKey: PUBLISHABLE_KEY,
            fetchClientSecret: fetchClientSecret,            
            fonts: [
                {
                    // integrate your font into stripe
                    cssSrc: 'https://fonts.googleapis.com/css?family=Rubik:300,400,500',

                }
            ],  
            
            
            appearance: {
                        variables: {
                            colorPrimary: "#F28E22",
                            buttonBorderRadius: "24px",
                            spacingUnit: "10px",
                            fontFamily: 'Rubik',
                            buttonPrimaryColorBackground: "#F28E22",
                            buttonSecondaryColorBackground: "#F28E22",
                            actionSecondaryColorText: "#F28E22",
                            formHighlightColorBorder: "#F28E22",
                            formAccentColor: "#F28E22",
                            colorBackground: "#1E1E1E",
                        },
                    },
   
        });
        const container = document.getElementById("container");
        const accountOnboarding = stripeConnectInstance.create('account-onboarding');
        accountOnboarding.setOnExit(() => {
            console.log('User exited the onboarding flow');
        });

//        accountOnboarding.setFullTermsOfServiceUrl('/docs/Terms and Conditions.pdf');
//        accountOnboarding.setPrivacyPolicyUrl('/docs/Privacy Policy.pdf');
        accountOnboarding.setSkipTermsOfServiceCollection(true);

        container.appendChild(accountOnboarding);
    }
}
</script>