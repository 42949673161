<template>
  <div class="user-input-wrp" @keypress="setCard" >

    <input :type="(type == 'number') ? 'text' : type" 
    ref="inp" 
    @keypress="acceptNumber" 
    :inputmode="inputmode" 
    :placeholder="placeholder" 
    @blur="setValid" 
    @input="setNum" 
    class="inputText" 
    :class="{ 'padding-right-el': (showFunc == 'true')}" :style="{'height': (height) ? height : '62px'}" 
    :name="name" 
    :maxlength="maxlength" 
    :minlength="minlength" 
    required 
    v-model="value" 
    :max="max"/>
    
    <span class="floating-label">{{ label }}</span>
  </div>
</template>

<script>

export default {
  name: 'my-input',
  data() {
    return {
      MyValue: '',
      isValid: true,
    }
  },
  props: {
    label: String,
    name: String,
    type: String,
    maxlength: String,
    minlength: String,
    bgColor: String,
    modelValue: String,
    pattern: String,
    showFunc: String,
    height: String,
    placeholder: String,
    valid: String,
    inputmode: String,
    max: String,
    validateType: String
  },
  mounted() {
    if(this?.bgColor != null &&
    this?.bgColor != undefined &&
    this?.bgColor != '') 
      this.$refs.inp.style.backgroundColor = this.bgColor
  },
  emits: ['password-error', 'update:modelValue', 'getvalue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
		    this.MyValue = val
        this.$emit('update:modelValue', val);
      }
    }
  },
  watch: {
    MyValue() {
      if(this.type != 'tel') {
        if(this.isValid) 
          this.$refs.inp.classList.remove('error-input')
        
        this.$emit('getvalue', this.MyValue, this.name)
      }      
      console.log(this.MyValue)
      if (this.validateType == 'password' && this.MyValue.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$/)) {
        this.$refs.inp.classList.remove('error-input')
        this.$emit('password-error', false);
      }
  	},
  },
  methods: {
    
    setValid() {
      if(this.type != 'tel' && this.valid != 'true') {
        try {
          if(!this.$refs.inp.checkValidity() || this.MyValue == "") 
            this.$refs.inp.classList.add('error-input')
          else {
            if (this.validateType == 'password' && !this.MyValue.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$/)) {
              this.$refs.inp.classList.add('error-input')
              this.$emit('password-error', true);
            } else {
              this.$refs.inp.classList.remove('error-input')
              this.$emit('password-error', false);
            }
          }

          console.log(this.isValid)
        } catch(err) {
          console.log(err)
        }
      }
    },
    setNum(e) {
      if(this.type == 'number') {
        e.target.value = e.target.value.replace(/[^\d]/g,'');
      }  

      let reg = /^\d{2}([./-])\d{2}\1\d{4}$/
      if(this.type == "bod") {
        e.target.value = e.target.value.replace(reg, '')
      }
    },
    // acceptNumber(e) {
    //   if(this.type == 'tel') {
    //     let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    //     e.target.value =!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    //   }
    // },
    setCard(e) {
      if(this.type == 'card') {
        e.target.value = e.target.value.replace(/[^\d]/g,'');
        e.target.value = e.target.value.toString().replace(/\d{4}(?=.)/g, '$& ');

      }
    },
    blur: function() {
      this.$refs.inp.blur()
    },
    focus: function () {
      this.$refs.inp.focus()
    }
  }
}
</script>

<style scoped>

.rtb {
  background-color: #1E1E1E !important;
}
.rg:hover {
    background-color: #1E1E1E !important;
}
.vti__input {
    background-color: black;
}
.vti__dropdown-item {
    background-color: #1E1E1E;
}
.vti__dropdown-item.highlighted {
    background-color: #1E1E1E !important;
}
.vue-tel-input {
    background-color: #1E1E1E !important;
}
.vti__dropdown:hover {
    background-color: #1E1E1E !important;
}
 .user-input-wrp {
	position: relative;
}
.user-input-wrp .inputText{
	width: 100%;
	outline: none;
	background-color: #1E1E1E;
  font-size: 15px;
  color: #FFFFFF;
  padding-left: 10px;
  border: none;
  height: 62px;
	border-radius: 10px;
 	box-shadow: none !important;
}

.user-input-wrp .floating-label {
	position: absolute;
	pointer-events: none;
  color: #ABABAB;
	top: 22px;
	left: 10px;
	transition: 0.2s ease all;
}
.user-input-wrp .inputText:focus {
  border: 1px solid var(--orange-color)
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label{
	top: 5px;
	left: 10px;
	font-size: 12px;
	opacity: 1;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.padding-right-el {
  padding-right: 45px !important;
}
.error-input {
  border: 1px solid #EA4335 !important;
}
</style>
