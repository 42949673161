<template>
    <div class="outer-modal-div-form client-outer" style="display: flex; flex-direction: column">
           <div class="pay-wrapper">
               <div class="inner-modal-div-form w-320 client-wrapper clint-nm">  
                <div class="flex" style="width: 100%; justify-content: center;">
                    <h1 class="logo-client-start m-x-auto">Merci<span class="h orange-color q-logo">Q</span></h1>
                </div>
                <div class="flex flex-column" style="width: 100%; justify-content: center;">
                    <h1 class="logo-client-start m-x-auto"><span class="orange-color q-logo" style="font-size: 30px">{{amount}} USD</span></h1>
                    <p class="other-text-color flex flex-fee-desc" v-if="!showFees">
                        Includes processing <a href="/docs/Fee Plan.pdf" class="orange-color">fee</a>
                        <img src="../../assets/img/UI/client-right-strel.svg" @click="toggleFees" class="strel-input-client">
                    </p>
                    <p class="other-text-color flex flex-fee-desc" v-if="showFees">
                        <img src="../../assets/img/UI/client-right-strel.svg" @click="toggleFees" class="strel-input-client strel-left">
                        (${{ tips }} + ${{ service_fee }} + ${{ stripe_fee }})
                    </p>
                </div>
                    <p class="other-text-color mt-2 client-main-test">Enter your tip transfer details</p>
                    <div class="mt-3">
                        <form id="payment-form" ref="form" style="font-family: 'Rubik-Bold'">
                            <div id="payment-element">
                                <!-- Elements will create form elements here -->
                            </div>
                            <div id="error-message">
                                <!-- Display error message to your customers here -->
                            </div>
                            
                            <div id="payment-request-button">
                                <!-- A Stripe Element will be inserted here. -->
                            </div>
                
                            <my-button class="mt-3" :active="true">Send</my-button>
                        </form>
                    </div>
                </div>
                <p class="other-text-color mt-2 center">TO ACCESS BUSINESS ACCOUNT</p>
                <p class="orange-color pointer mt-1 center"><span @click="$router.push('/staff/signin')">SIGN IN</span> / <span @click="$router.push('/signupshort')">SIGN UP</span></p>
            </div>
       </div>
   </template>
   
   <script>
    import configMixin from '@/mixins/configMixin'
    import { PUBLISHABLE_KEY } from '@/assets/constants/stripe'

       export default {
           name: 'pay client',
            mixins: [configMixin],
           data() {
               return {
                 show: false,
                 isError: false,
                 username: '',
                 amount: '',
                 service_fee: '',
                 stripe_fee: '',
                 tips: '',
                 showFees: false
               }
           },
           async beforeMount() {
            if (this.$route?.query?.token == undefined || this.$route?.query?.token == null) {
                if (localStorage.getItem('payment-secret') != 'undefined' &&
                localStorage.getItem('payment-secret') != undefined) {
                    this.$router.push('/client/payment?token=' + localStorage.getItem('payment-secret'))
                } else {
                    this.$router.go(-1)
                }
            }
           },
           async mounted() {
            await fetch(this.config.API_URL + '/transaction/token', {
                method: "POST",
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({token: localStorage.getItem('payment-secret')})
            }).then(async data => await data.json())
            .then(async data => {
            this.amount = data.amount
            
//            const total = (paymentIntent.amount / 100).toFixed(2)
            this.service_fee = data.service_fee
            this.stripe_fee = data.stripe_fee
            this.tips = data.tips

            const stripe = window.Stripe(PUBLISHABLE_KEY);
            
            const appearance = {
            theme: 'night',
            variables: {
                colorPrimary: '#F28E22',
                colorBackground: '#252525',
                colorText: 'white',
                colorDanger: '#EA4335',
                // See all possible variables below
            },
            };
           
            const options = {
            clientSecret: data.secret,
            // Fully customizable with appearance API.
            appearance,
            fonts: [
            {
            // integrate your font into stripe
            cssSrc: 'https://fonts.googleapis.com/css?family=Rubik:400,500',
            }
        ]
            };
            const stripeStyle = {
            style: {
                base: {
                    fontFamily: 'Rubik-Regular, sans-serif',
                // fontSize, etc. if applicable
                },
            },
            };

            // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
            const elements = stripe.elements(options);

            // Create and mount the Payment Element
            const paymentElement = elements.create('payment', stripeStyle);
            paymentElement.mount('#payment-element');

            const paymentRequest = await stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Demo total',
                amount: parseInt(Math.round(data.amount * 100), 10),
            },
            requestPayerName: true,
            requestPayerEmail: true,
            });
           
            const elementsBtn = stripe.elements();

            const prButton = elements.create('paymentRequestButton', {paymentRequest});

                
            (async () => {
            // Check the availability of the Payment Request API first.
            const result = await paymentRequest.canMakePayment();
            if (result) {
                prButton.mount('#payment-request-button');
            } else {
                document.getElementById('payment-request-button').style.display = 'none';
            }
            })();
        
            paymentRequest.on('paymentmethod', async (ev) => {
                const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
                    data.secret,
                    {payment_method: ev.paymentMethod.id},
                    {handleActions: false}
                );
                if (confirmError) {
                    
                    ev.complete('fail');
                } else {
                    // Report to the browser that the confirmation was successful, prompting
                    // it to close the browser payment method collection interface.
                    ev.complete('success');
                    // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
                    // handle the flow. If using an API version older than "2019-02-11"
                    // instead check for: `paymentIntent.status === "requires_source_action"`.
                    if (paymentIntent.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const {error} = await stripe.confirmCardPayment(clientSecret);

                        if (error) {
                            // The payment failed -- ask your customer for a new payment method.
                        } else {
                           this.$router.push('/client/success?id=' + localStorage.getItem('payment-secret'))
                        }
                    } else {
                        this.$router.push('/client/success?id=' + localStorage.getItem('payment-secret'))
                    }
                }
            })
            

            this.$refs.form.addEventListener('submit', async (event) => {
            event.preventDefault();

            const {error} = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                return_url: window.origin + '/client/success?id=' + localStorage.getItem('payment-secret'),
                },
            });
           
            
            });
        })
        },
        methods: {
            toggleFees() {
                this.showFees = !this.showFees;
            }
        }
       }
   </script>

<style scoped>
    .flex-fee-desc {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
    .strel-input-client {
        margin-left: 0;
    }
    .strel-left {
        transform: rotate(180deg);
    }
</style>