<template>
  <div class="container-dash" >
    <employee-dashboard />
    <div class="wrapper-dash main-mirciq workspace-wrapper-das min-height pb-4 wrapper-profile-div">
      <div class="workspace-wrapper wrapper-profile center-mobile"> 
        <h2 class="main-title-dash pointer" @click="$router.push('/employee/profile')"><img src="../../../assets/img/UI/left-strel.svg">General FAQ</h2>

        <div class="wrapper faq-wrapper">
          <faq-element title="How do I log in to my employee account?" text="To log in to your employee account, open a web browser and go to the MerciQ website. Enter your
              username and password provided by your administrator, then click the &quot;Log In&quot; button." />
          <faq-element title="How can I access my QR code?" text="You can access your QR code by clicking the &quot;QR-code&quot; button on your dashboard. This QR code
              can be used by customers to leave tips directly to your account." />
          <faq-element title="How do I check my balance?" text="Your current balance is displayed on the dashboard under the &quot;Balance&quot; section. This shows the
              total amount of funds available in your account." />
          <faq-element title="How can I view my transaction history?" text="To view your transaction history, click on the &quot;Finance history&quot; button. This will show a detailed list
              of all your financial transactions." />
          <faq-element title="How do I withdraw funds from my account?" text="To withdraw funds, click the &quot;Withdraw&quot; button on your dashboard. Follow the instructions to
              transfer your earnings to your linked bank account or another specified account." />
          <faq-element title="What information can I see in the Analytics section?" text="The Analytics section provides detailed statistical data, such as total earnings, average tip value,
              and customer ratings. You can filter this data by date for more specific insights." />
          <faq-element title="How do I view customer reviews and ratings?" text="To view customer reviews and ratings, click on the &quot;Happy Clients&quot; section. This will display all the
              feedback and ratings left by your customers." />
          <faq-element title="What are Achievements and how do I earn them?" text="Achievements are awards you can earn for various milestones and activities on the platform. When
              your account reaches the maximum rating, contact customer support to claim your reward – a +5%
              bonus on the total amount of tips you have earned in the last 3 months." />
          <faq-element title="How do I update my profile information?" text="To update your profile information, go to &quot;Profile settings&quot; from the sidebar menu. Here you can
              change your name, surname, phone number, and email." />
          <faq-element title="How can I manage notifications?" text="In the &quot;Profile settings&quot; section, select &quot;Notifications&quot; to manage your notification preferences. You
              can choose which notifications you want to receive." />
          <faq-element title="What is the Security Section?" text="The Security Section in &quot;Profile settings&quot; contains your log-in history, which helps you monitor the
              security of your account. It shows details of when and where your account was accessed." />
          <faq-element title="How do I update my bank card information?" text="To update your bank card information, go to &quot;Payment Information&quot; in the Profile settings. Enter
              your new card details to update the bank card linked to your account for withdrawals." />
          <faq-element title="How do I log out of my account?" text="To log out of your account, click the &quot;Log out&quot; button at the bottom of the sidebar menu." />
          <p>If you have any other questions or need further assistance, please contact our technical support
            through the FAQ & Help Center in your Profile settings.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'employee faq',    
  }
</script>

<style scoped>

.wrapper {
    width: 1180px;
    margin-left: auto;
    margin-right: auto;
}

.wrapper p {
    width: 100%;
    text-align: left;
    margin-top: 20px;
    color: #ababab;
    font-family: Rubik-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: .4px;
  }

@media screen and (max-width: 1180px) {
    .wrapper {
        width: 100%;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 1180px) {
    .FAQ-title, .faq-wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
}

</style>