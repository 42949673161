export default {
    methods: {
        //validate forms
        validateComapany() {
            if (this.filled.company.address) {
                if (this.company.address.trim() == '') return false
            }
            if (this.filled.company.zip) {
                if (this.company.zip.trim() == '') return false
            }
            if (this.filled.company.tax) {
                if (this.company.tax.length != 8 && this.company.tax.length != 9) return false
            }
            if (this.filled.company.ssn) {
                if (this.company.ssn.length != 8 && this.company.ssn.length != 9) return false
            }

            return true
        },
        validatePersonal() {
            if (this.filled.personal.address) {
                if (this.admin.address.trim() == '') return false
            }
            if (this.filled.personal.name) {
                if (this.admin.name.trim() == '') return false
            }
            if (this.filled.personal.lastname) {
                if (this.company.zip.trim() == '') return false
            } 
            if (this.filled.personal.birthday) {
                let nowDate = new Date(); 
          
                let lastDate = new Date(this.admin.birthday);
                let d = ((nowDate-lastDate) / 1000 / (60 * 60 * 24) / 365.25).toFixed(0)

                if (d > 120) {
                    this.DOM.error.isNotValidBithday = true 
                    return false
                }
                else this.DOM.error.isNotValidBithday = false 

                if(d < 18) {
                    this.DOM.error.isNotOldBithday = true 
                    return false
                }
                else this.DOM.error.isNotValidBithday = false 
            }
            if (this.filled.personal.phone) {
                if (!this.valid.adminPhoneValid) return false
            }
            if(this.filled.personal.email) {
                const validateEmail = (email) => {
                    return String(email)
                        .toLowerCase()
                        .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                };
                if (!validateEmail(this.admin.email)) return false
            }

            return true
        },
        validatePublic() {
            if (this.filled.public.phone) {
                if (!this.valid.publicPhoneValid) return false
            }
            if (this.filled.public.website) {
                if (this.publicInfo.website.trim() == "") return false
            }
            if (this.filled.public.desc) {
                if (this.publicInfo.desc.trim() == "") return false
            }

            return true
        },
        validateExternalAccount() {
            if (this.filled.external.account_number) {
                
                if (this.external.accountNumber.length > 15 &&
                    this.external.accountNumber.length < 9) return false
            }
            if (this.filled.external.name) {
                if (this.external.nameAccount.trim() == "") return false
            }
            if (this.external.routing_number.trim().length != 9) return false

            return true
        },
        validExternalCard() {
                if (String(this.external.cardMonth).trim() == '') return false
            
                if (String(this.external.cardYear).trim() == '') return false
               
                if (String(this.external.cardNumber).trim().split(" ").join("").length != 16) return false
            
                if (this.external.holderNameCard.trim() == "") return false 
           

            return true
        },
        // make object for request
        makeObjectCompany() {
            let object = {}

            if (this.filled.company.address) object.address = this.company.address
            if (this.filled.company.zip) object.zip = this.company.zip
            if (this.filled.company.tax) object.tax = this.company.tax
            if (this.filled.company.ssn) object.ssn = this.company.ssn

            return JSON.stringify(object)
        },
        makeObjectPersonal() {
            let object = {}

            if (this.filled.personal.address) object.address = this.admin.address
            if (this.filled.personal.name) object.name = this.admin.name
            if (this.filled.personal.lastname) object.lastname = this.admin.lastname 
            if (this.filled.personal.birthday) object.birthday = this.admin.birthday
            if (this.filled.personal.phone) object.phone = this.admin.phone
            if(this.filled.personal.email) object.email = this.admin.email 

            return JSON.stringify(object)
        },
        makePublicObject() {
            let object = {}

            if (this.filled.public.phone) object.phone = this.publicInfo.phone 
            if (this.filled.public.website) object.website = this.publicInfo.website
            if (this.filled.public.desc) object.desc = this.publicInfo.desc

            return JSON.stringify(object)
        },
        makeExternalAccountObject() {
            let object = {}

            if (this.filled.external.account_number) object.account_number = this.external.accountNumber
            if (this.filled.external.name) object.name = this.external.nameAccount
            object.routing_number = this.external.routing_number.trim()

            return JSON.stringify(object)
        },
        makeExternalCardObject() {
            let object = {}

            object.month = this.external.cardMonth
            object.year = '20' + this.external.cardYear
            object.card = this.external.cardNumber.split(" ").join("")
            object.card_name = this.external.holderNameCard
            
            return JSON.stringify(object)
        }
    }
}