<template>
     <div class="container-dash" >
        <employee-dashboard :demo="true"/>
        <div class="wrapper-dash main-mirciq workspace-wrapper-das min-height pb-4 wrapper-profile-div">
            <div class="workspace-wrapper wrapper-profile center-mobile"> 
                <h2 class="main-title-dash pointer" @click="$router.push('/demo/profile')"><img src="../../../assets/img/UI/left-strel.svg">Techical support</h2>

                <div class="mt-6">
                    <a class="tech-number" href="mailto:support@merciq.com">support@merciq.com</a>
                    <a class="tech-number mt-2" href="https://api.whatsapp.com/send/?phone=%2B393314657539&text&type=phone_number&app_absent=0">+39 331 465 7539</a>
                    <a class="center" href="https://api.whatsapp.com/send/?phone=%2B393314657539&text&type=phone_number&app_absent=0"><button class="border-grey mt-4 child-bg-color">Call <span v-if="false">8:30 am</span></button></a>
                    <a class="center" href="mailto:support@merciq.com"><button class="border-grey mt-1 child-bg-color" >Email</button></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'support profile empl'
    }
</script>