export default {
    data() {
        return {
            navPanel: '',
            isActive: [true, false, false, false],
            datePanel: [false, false, false, true],
            isActiveCalendar: false,
            filters: {
                date: ''
            }
        }
    },
    methods: {
        getDateCalendar(value) {
            if(value != '') this.filters.date = value
            this.isActiveCalendar = !this.isActiveCalendar
        },
        setActiveCalendar() {
            console.log(this.isActiveCalendar)
            this.isActiveCalendar = !this.isActiveCalendar
        },
        setClassSelect() {
            this.$refs.select.classList.toggle('closed');
            this.$refs.select.style.marginBottom = '-' + String(this.$refs.select.offsetHeight - 31) + 'px'
        },
        setNavPanel(name, id) {
            this.navPanel = name
            this.setActive(id)
        },
        setActive(id) {
            if(this.isActive[id] != true)this.isActive[id] = !this.isActive[id]
            if(id != 0) this.isActive[0] = false
            if(id != 1) this.isActive[1] = false
            if(id != 2) this.isActive[2] = false
            if(id != 3) this.isActive[3] = false
        },
        setDatePanel(id) {
            if(this.datePanel[id] != true)this.datePanel[id] = !this.datePanel[id]
            if(id != 0) this.datePanel[0] = false
            if(id != 1) this.datePanel[1] = false
            if(id != 2) this.datePanel[2] = false
            if(id != 3) this.datePanel[3] = false
        },
        
    }
}