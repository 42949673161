<template>
   <div v-if="isSet" style="width: 100%; height: 100vh; background-color: #171717; opacity: 0.7; position: absolute; top: 0; left: 0; z-index: 3;">

</div>
<div v-if="isSet" class="wrapper-main-editor">
    <img src="../assets/img/UI/close-calendar.svg" 
    style="position: absolute; top: 50px; right: 40px; margin-top: 20px; cursor: pointer;" @click="setSet">
    <div  v-if="isSet && isNotActive" style="position: absolute;width: 250px;
     height: 250px;
     left: 0;
     right: 0;
     margin-left: auto;
     margin-right: auto;
     z-index: 10; cursor: pointer;" @click="clickCanvas"></div>

    <img :src="require('../assets/img/UI/select-logo.svg')" 
    style="width: 250px; height: 250px;"
    v-if="isNotActive">
    
    <vue-avatar
    :width="250"
    :height="250"
    :rotation="rotation"
    :borderRadius="borderRadius"
    :scale="scale"
    ref="vueavatar"
    id="vue-avatar"
    v-if="isSet"
    @avatar-initials="getAva"
    style="z-index: 7; position: relative;;"
    :style="{'margin-top': (isNotActive) ? '-270px' : '-0', 'opacity': (isNotActive) ? '0' : '1'}"
    @vue-avatar-editor:image-ready="onImageReady"
    ></vue-avatar>

  <br v-if="isSet">
  <label style="color: white" v-if="isSet">
    Zoom : {{scale}}x
    <br>
    <input
      type="range"
      min=1
      max=3
      step=0.02
      v-model='scale'
    />
  </label>
  <br v-if="isSet"><br v-if="isSet">
  <label style="color: white; margin-top: 30px;" v-if="isSet">
    <span @click="clickCanvas">Rotation</span> : {{rotation}}°
    <br>
    <input
      type="range"
      min=0
      max=360
      step=1
      v-model='rotation'
      
    />
  </label>
  <br v-if="isSet">
  <br v-if="isSet">
  <div style="width: 200px; margin-left: auto;margin-right: auto;" v-if="isSet">
    <my-button v-on:click="saveClicked" label="Get image" :active="isNotActive == false"/>
  </div>
</div>
  <br>
  <img ref="image" :src="(srcImg == null || srcImg == undefined || srcImg == false) ? require('../assets/img/UI/select-logo.svg') : srcImg" @click="setSet" style="border-radius: 100%;" class="pointer" :class="{'d-none': isSet}">
   <p class="other-text-color mt-1" style="font-size: 12px;" :class="{'d-none': isSet}">{{ label }}</p>
   <my-button label="Change" @click="setSet" active="true" style="width: 200px; height: 40px;" class="mt-1" :class="{'d-none': isSet}"/>

</template>

<script>

    export default {
        name: 'avatar-editor',
        data: function data() {
        return {
            rotation: 0,
            scale: 1,
            borderRadius: 100,
            isSet: false,
            isNotActive: true
        };
    },
    props: {
        selectImg: String,
        label: String,
        srcImg: String
    },
    watch: {
        isSet() {
            this.isNotActive = true
        }
    },
    methods: {
        clickCanvas(e) {
            let ert = function() {
                document.querySelector("#vue-avatar input").click()
            }
            ert()
            
        },
       
        setSet() {
            this.isSet = !this.isSet
        },
        saveClicked: async function saveClicked() {
            if(this.isNotActive == false) {
            var img = this.$refs.vueavatar.getImageScaled();

            await this.$refs.vueavatar.getImageScaled().toBlob((blob) => {
                
                let formData = new FormData();
                formData.append('file', blob, 'img.png');
                console.log(formData.get('file'))
                this.$emit('get', formData)
               
            })
            this.setSet()
            this.$refs.image.setAttribute('src', img.toDataURL())
            this.$emit('get', img.toDataURL())
        }
        },
        onImageReady: function onImageReady() {
            this.scale = 1;
            this.rotation = 0;
            this.isNotActive = false
        }
    }
    }
</script>
<style scoped>
.wrapper-main-editor {
    position: absolute; 
    width: 500px;
    background-color: #171717; 
    left: 0; top: 0;
    right: 0; 
    z-index: 8; 
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    top: 100px;
    text-align: center; 
    padding-top: 50px;
    border-radius: 20px;
}
@media screen and (max-width: 500px){
    .wrapper-main-editor {
        height: 101vh;
        top: 0;
        left: 0; right: 0;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 120px;
    }
}
</style>