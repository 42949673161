<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getCalendar" v-if="isActiveCalendar"/>
    <leave-request @close="setActiveLeave" @getdata="setActiveLeave" v-if="isActiveLeave" style="z-index: 4"/>
    <div class="container-dash average-bg-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'wrapper-average': true, 'calendar-active-cont': (isActiveCalendar || isActiveLeave)}" class="cont-7">
            <div class="flex top-avarage-mobile d-none-mobile">
                <h2 class="main-title-dash pointer" @click="$router.push('/dashboard')"><img src="../../assets/img/UI/left-strel.svg"> Reviews</h2>
                <div class="PostWebsite mt-2">
                    <div class="PostReviews ml-2"><p style="font-family: 'Rubik-Regular'; font-size: 20px; color: white">Post reviews to your website</p></div>
                    <div class="LeaveRequest"><my-button label="Leave a request" active="true" @click="setActiveLeave"/></div>
                </div>
                <!-- <div class="select main-to average-sel-mob" @click="setClassSelect">
                        <ul  ref="select" class="closed esta-select">
                            <li values="1" class="option">All establishment</li>
                            <li values="2" class="option">All establishment</li>
                            <li values="3" class="option">All establishment</li>
                        </ul> 
                    </div> -->
                <div class="min-panel-end flex panel-average">
                    <div class="panel-calendar-min ml-3" @click="setActiveCalendar" v-if="choiceDate == ''">
                        <img src="../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    <div class="panel-calendar-min ml-3" v-if="choiceDate != ''">
                        <div>
                            <img src="../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delCalendar">
                        </div>
                    </div>
                    
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0), FinanceMixin('Day')">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1), FinanceMixin('Week')">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2), FinanceMixin('Month')">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3), FinanceMixin('Year')">Year</div>
                    </div>
                </div>
                
            </div>
            <div class="d-none-desc">
            <h2 class="main-title-dash pointer" @click="$router.push('/dashboard')"><img src="../../assets/img/UI/left-strel.svg">Reviews</h2>
            <div class="PostWebsite mt-2">
                    <div class="PostReviews ml-2"><p style="font-family: 'Rubik-Regular'; font-size: 20px; color: white">Post reviews to your website</p></div>
                    <div class="LeaveRequest"><my-button label="Leave a request" active="true" @click="setActiveLeave"/></div>
                </div>
            <div class="flex mt-3">
                <div class="min-panel-end flex panel-average">
                    <div class="panel-calendar-min ml-3" @click="setActiveCalendar" v-if="choiceDate == ''">
                        <img src="../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    <div class="panel-calendar-min ml-3" v-if="choiceDate != ''">
                        <div>
                            <span class="choiceDate">{{ choiceDate }}</span>
                            <img src="../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delCalendar">
                        </div>
                    </div>
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0), FinanceMixin('Day')">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1), FinanceMixin('Week')">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2), FinanceMixin('Month')">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3), FinanceMixin('Year')">Year</div>
                    </div>
                </div>
                
            </div>
        </div>
            <Employees 
            v-for="el in transaction"
            :id="zeroPad(el.id)" 
            :time="el.time" 
            :price="Number(el.sum).toFixed(2)" 
            :stars="el.stars" 
            :name="el['User.name']" 
            :desc="(el.comment == null) ? '' : el.comment"
            :date="String(new Date(el.createdAt).getDate()) + '.' + (new Date(el.createdAt).getMonth() + 1) + '.' + String(new Date(el.createdAt).getFullYear())"
            class="mt-1"/>

            <p v-if="transaction.length == 0" class="none-info">At the moment, we don’t have enough data to display the information on this page. Try again later when you get enough incoming transactions</p>
            
            <paginate 
            :count="Number(lastpage)" 
            link="/dashboard/reviews"
            />
        </div>
        
    </div>
    
</template>
    
<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import getReviewsMixin from '@/mixins/getReviewsMixin';
    import dateIconMixin from '@/mixins/dateIconMixin';
    import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'leaders order',
        mixins: [dashboardMixin, getReviewsMixin, dateIconMixin, loaderMixin],
        data() {
            return {
                isActiveLeave: false,  
                transaction: '',
                lastpage: 0,
                transaction: []
            }
        },
        watch: {
            '$route.query.page': function() {
                this.FinanceMixin()
            }
        },
        methods: {
            async setActiveLeave(data) {
                this.isActiveLeave = !this.isActiveLeave

                if (data) {
                    console.log(data);

                    const body = {
                        phone: data.phone,
                        email: data.email,
                        name: data.name
                    }

                    let response = await fetch(window.origin + '/api' + "/reviews/review", {
                        method: "POST",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });

                    let result = await response.json();
                    console.log('result', result);
                }
            },
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            getCalendar(value) {
                let date = (value == null || value == undefined) ? null : JSON.stringify({date: value})
                this.FinanceMixin(value)
                this.getDateIcon(value)
            },
            delCalendar() {
                this.FinanceMixin(null)
                this.delDateIcon()
            },
            async FinanceMixin(date) {
                Array.prototype.max = function() {
                        return Math.max.apply(null, this);
                };

                let page_id = (this.$route.query.page == undefined) ? 1 : this.$route.query.page
                await this.getDataMixin(page_id, date).then(data => {
                    this.transaction = data.rows
                    this.lastpage = Math.ceil(data.count / 10)
            
                })

                this.setLoader(false);
            }
            
        },
        mounted() {
            this.FinanceMixin()
        }

    }
</script>