<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getDateCalendar" v-if="isActiveCalendar"/>
    <div class="container-dash average-bg-mobile">
        <employee-dashboard :demo="true"/>
        <div :class="{'wrapper-dash': true, 'wrapper-average': true, 'calendar-active-cont': isActiveCalendar}">
            <div class="flex top-avarage-mobile">
                <h2 class="main-title-dash pointer" @click="$router.push('/demo/statistic')"><img src="../../../assets/img/UI/left-strel.svg"> Average tip value</h2>
                <div class="min-panel-end flex panel-average">
                   
                </div>
            </div>
            <LineChartContainer 
                class="graph-1-2 mt-5" 
                style="height: 300px" 
                :dataOption="day" 
                v-if="day.length > 0"/>

            <div class="average-el">
                <div class="flex">
                    <div>
                        <h3>Tell your customers about MerciQ</h3>
                        <p>Don't forget to remind your customers that you now accept tips using the website merciq.app, and they can immediately find your page and leave a review and tip by entering your username in the search bar</p>
                    </div>
                    <div>
                        <img src="../../../assets/img/tips/tips1.png" style="width: 120px;">
                    </div>
                </div>
            </div>
            <div class="average-el">
                <div class="flex" >
                    <div>
                        <h3>Use the QR code</h3>
                        <p>You and your employees can offer customers the option to leave a tip using a QR code. There are many ways to interact with this tool, for example: you can print the QR on your business card or show it to your customers from the screen of a mobile phone - directly from the app or using a saved screenshot</p>
                    </div>
                    <div>
                        <img src="../../../assets/img/tips/tips2.png" style="width: 120px;">
                    </div>
                </div>
            </div>
            <div class="average-el">
                <div class="flex">
                    <div>
                        <h3>Order a personalized NFC card</h3>
                        <p>We offer you to order your personal plastic card equipped with NFC technology. With this card, you can receive tips with a single touch of your card to your customer's mobile phone. You can learn more about the card in the "Get your NFC card" menu section or from our customer service</p>
                    </div>
                    <div>
                        <img src="../../../assets/img/tips/tips3.png" style="width: 120px;">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import configMixin from '@/mixins/configMixin';

    export default {
        name: 'average member empl',
        mixins: [dashboardMixin, configMixin],
        data() {
            return {
                day: [0, 0, 0, 0, 0, 15, 175],
            }
        },
        methods: {
            getDay() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/transaction/days', {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    this.day = data.days.map(el => {
                        if(el == null) return 0
                        else return el
                    })
                })
            },
        },
        beforeMount() {
            //this.getDay()
        }
    }
</script>