<template>
  <div id="preloader" v-if="active">
    <div class="spinner"></div>
  </div>
</template>

<script>
  export default {
    name: 'loader',
    props: {
      active: Boolean
    },
    created() {
      console.log('loaderComponent active', this.active);
    }
  }
</script>

<style scoped>
  #preloader {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(23, 23, 23, 0.9);
      width: 100vw;          
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }

  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid var(--dashboard-el);
    border-top-color: var(--orange-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>