<template>

            <div class="flex">
                <h3 class="head-title"><br/>CHANGE PAYOUTS INFORMATION</h3>

            </div>
                    
            <div class="flex payout-tabs">
                <custom-tab label="LINK BANK CARD" @click="onBankCardTabClick" :active="bankCardTabActive" class="mt-2 choose-button" />
                <custom-tab label="LINK BANK ACCOUNT" @click="onBankAccountTabClick" :active="bankAccountTabActive" class="mt-2 choose-button" />
            </div>

                <div class="card-form-inner" v-show="bankCardTabActive">

                    <div class="user-input-wrp">
                        <div class="field"
                            @focus="onFocus"
                            @blur="onBlur"
                            @keyup="onKeyup"
                        >
                            <div 
                                id="card-number" 
                                class="m-stripe-input empty"                                 
                            >
                            </div>
                            <span class="stripe-floating-label">Card number</span>
                        </div>
                    </div>

                    <div class="row" style="gap: 20px;">
                        <div class="user-input-wrp mt-2 left half-width">
                            <div class="field"
                                @focus="onFocus"
                                @blur="onBlur"
                                @keyup="onKeyup"
                            >
                                <div 
                                    id="card-expiry" 
                                    class="m-stripe-input empty" 
                                    
                                >
                                </div>
                                <span class="stripe-floating-label">Exp date</span>
                            </div>
                        </div>

                        <div class="user-input-wrp mt-2 right half-width">
                            <div class="field"
                                @focus="onFocus"
                                @blur="onBlur"
                                @keyup="onKeyup"
                            >
                                <div 
                                    id="card-cvc" 
                                    class="m-stripe-input empty" 
                                    
                                >
                                </div>
                                <span class="stripe-floating-label">CVC</span>
                            </div>
                        </div>
                    </div>

                    <p class="error-input-text err-au" v-if="isErrorCard">Invalid debit card</p>

                </div>

                <div class="card-form-inner" v-show="bankAccountTabActive">

                    <div style="">
                        <custom-input 
                            type="text" 
                            label="Account holder name" 
                            style="margin-left: auto; margin-right: auto;" 
                            v-model="formData.external.nameAccount" 
                            class="w-320-mob"
                            @blur="accountHolderNameValidate"
                            ref="accountHolderNameInput"
                        />
                        <p class="error-input-text err-au" v-if="accountHolderNameError?.length > 0">{{ accountHolderNameError }}</p>
                    </div>

                    <div style="margin-top: 20px;">
                        <custom-input 
                            type="text" 
                            label="Routing number" 
                            style="margin-left: auto; margin-right: auto;" 
                            v-model="formData.external.routing_number" 
                            class="w-320-mob"
                            @blur="routingNumberValidate"
                            ref="routingNumberInput"
                        />
                        <p class="error-input-text err-au" v-if="routingNumberError?.length > 0">{{ routingNumberError }}</p>
                    </div>

                    <div style="margin-top: 20px;">
                        <custom-input 
                            type="text" 
                            label="Account number" 
                            style="margin-left: auto; margin-right: auto;" 
                            v-model="formData.external.accountNumber" 
                            class="w-320-mob"
                            @blur="accountNumberValidate"
                            ref="accountNumberInput"
                        />
                        <p class="error-input-text err-au" v-if="accountNumberError?.length > 0">{{ accountNumberError }}</p>
                    </div>

                </div>

            <my-button label="Confirm" :active="isActiveBtn" @click="setStripePayment" class="mt-4"/>

</template>

<script>
import configMixin from '@/mixins/configMixin'
import { PUBLISHABLE_KEY } from '@/assets/constants/stripe'

    export default {
        mixins: [configMixin],
        name: 'change-stripe-payment',
        data() {
            return {
                isActiveBtn: false,
                formData: {
                    external: {
                        accountNumber: '',
                        nameAccount: '',
                        routing_number: ''
                    }
                },
                stripe: PUBLISHABLE_KEY,
                cardNumberCompleted: false,
                cardExpDateCompleted: false,
                cardCvcCompleted: false,
                isErrorCard: false,
                bankCardTabActive: true,
                bankAccountTabActive: false,
                routingNumberError: '',
                accountNumberError: '',
                accountHolderNameError: '',
            }
        },
        props: {
            activeTab: String
        },
        watch: {
            'formData.external.nameAccount': function(){
            this.isIsset()
            },
            'formData.external.routing_number': function(){
                this.isIsset()
            },
            'formData.external.accountNumber': function(){
                this.isIsset()
            }
        },
        methods: {
            
            close() {
                this.$emit('close')
            },
            getData() {
                this.$emit('getdata')
            },
            async setStripePayment() {
                if (this.isActiveBtn) {
                    
                    if (this.bankCardTabActive) {

                        await this.stripe.createToken(this.cardEl, {
                            currency: "usd"
                        }).then((result) => {
                            console.log(result);
                            if (result?.token?.id != undefined) {
                                
                                this.cardToken = result?.token?.id
                                
                                this.isErrorCard = false

                                let object = {
                                    token: result?.token?.id
                                }
                                fetch(this.config.API_URL + '/user/stripe/business/card', {
                                    method: "PUT",
                                    body: JSON.stringify(object),
                                    headers: {
                                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                                        'Content-Type': 'application/json'
                                    }
                                }).then(data => data.json()).then(data => {
                                    console.log(data);
                                    this.$router.go()
                                })

                            } else {
                                this.isErrorCard = true
                            
                            }
                        });

                    } else if (this.bankAccountTabActive) {

                        await this.stripe.createToken('bank_account', {
                            country: 'US',
                            currency: 'usd',
                            routing_number: this.formData.external.routing_number,
                            account_number: this.formData.external.accountNumber,
                            account_holder_name: this.formData.external.nameAccount,
                            account_holder_type: 'individual',
                        }).then((result) => {
                            console.log(result);
                            if (result?.token?.id != undefined) {
                                
                                this.cardToken = result?.token?.id
                                
                                this.isErrorCard = false

                                let object = {
                                    token: result?.token?.id
                                }
                                fetch(this.config.API_URL + '/user/stripe/business/card', {
                                    method: "PUT",
                                    body: JSON.stringify(object),
                                    headers: {
                                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                                        'Content-Type': 'application/json'
                                    }
                                }).then(data => data.json()).then(data => {
                                    console.log(data);
                                    this.$router.go()
                                })

                            } else {
                                this.isErrorCard = true
                            
                            }
                        });
                        
                    }

                }
            },
            isIsset() {

                const bankCardValid = this.bankCardTabActive &&
                    this.cardNumberCompleted &&
                    this.cardExpDateCompleted &&
                    this.cardCvcCompleted;

                const bankAccountValid = this.bankAccountTabActive &&
                    this.accountHolderNameValidate() &&
                    this.routingNumberValidate() &&
                    this.accountNumberValidate();

                if(bankCardValid || bankAccountValid) 
                    this.isActiveBtn = true;
                else 
                    this.isActiveBtn = false;
            },
            onBankCardTabClick() {
                this.bankCardTabActive = true;
                this.bankAccountTabActive = false;
            },
            onBankAccountTabClick() {
                this.bankAccountTabActive = true;
                this.bankCardTabActive = false;
            },
            routingNumberValidate() {
                
                const validationError = this.formData.external.routing_number.trim().length != 9;
                
                if (validationError) {
                    this.routingNumberError = 'Please enter correct routing number';
                    this.$refs.routingNumberInput.setError(true);
                    return false;
                } else {
                    this.routingNumberError = '';
                    this.$refs.routingNumberInput.setError(false);
                    return true;
                }
            },
            accountNumberValidate() {

                const validationError = this.formData.external.accountNumber.length > 15 ||
                    this.formData.external.accountNumber.length < 9;
                
                console.log ('validationError', validationError);
                if (validationError) {
                    this.accountNumberError = 'Please enter correct account number';
                    this.$refs.accountNumberInput.setError(true);
                    return false;
                } else {
                    this.accountNumberError = '';
                    this.$refs.accountNumberInput.setError(false);
                    return true;
                }
            },
            accountHolderNameValidate() {
            
                const validationResult = this.validateText(this.formData.external.nameAccount);

                if (validationResult) {
                    this.accountHolderNameError = '';
                    this.$refs.accountHolderNameInput.setError(false);
                    return true;
                } else {
                    this.accountHolderNameError = 'Please enter account holder name';
                    this.$refs.accountHolderNameInput.setError(true);
                    return false;
                }
            },
            validateText(value) {
                return value.length > 0;
            },
        },
        mounted() {
            
            this.stripe = window.Stripe(this.stripe);
            
            const appearance = {
            theme: 'day',
            
            };

            const options = {
                appearance,
                fonts: [
                    {
                    // integrate your font into stripe
                    cssSrc: 'https://fonts.googleapis.com/css?family=Rubik:300,400,500',
                    fontSize: "40px"
                    }
                ]                
            };
            /*
            const stripeStyle = {
                style: {
                    base: {
                        fontFamily: 'Rubik-Light, sans-serif',
                        color: "#ABABAB",
                        fontSize: "16px"
                    },
                },
                hidePostalCode: true
            }
            */


            const elementStyles = {
                base: {
                    color: 'white',
                    fontWeight: 300,
                    fontFamily: 'Rubik-Light, Consolas, Menlo, monospace',
                    fontSize: '15px',
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                        color: '#ABABAB',
                    },
                    ':-webkit-autofill': {
                        color: '#e39f48',
                    },
                }
                /*
                invalid: {
                    color: '#E25950',
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
                */
            };

            const elements = this.stripe.elements(options);

            const elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            };

            // Card number
            this.cardEl = elements.create("cardNumber", {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardEl.mount('#card-number');

            this.cardEl.on('change', event => {
//                console.log("LOGGING THE CARD ELEMENT EVENT", event);
                if (event.complete) {
                    console.log('CARD ELEMENT completed');

                    this.cardNumberCompleted = true;


                    // enable payment button
                    this.isIsset()
                } else if (event.error) {
                    // show event.error.message to customer
                    this.cardNumberCompleted = false;
                } else {
                    this.cardNumberCompleted = false;
                }
            });

            // Card number
            this.cardExpiry = elements.create("cardExpiry", {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardExpiry.mount('#card-expiry');

            this.cardExpiry.on('change', event => {
//                console.log("LOGGING THE CARD EXPIRY EVENT", event);
                if (event.complete) {
                    console.log('CARD EXPIRY completed');

                    this.cardExpDateCompleted = true;


                    // enable payment button
                    this.isIsset()
                } else if (event.error) {
                    // show event.error.message to customer
                    this.cardExpDateCompleted = false;
                } else {
                    this.cardExpDateCompleted = false;
                }
            });

            // Card number
            this.cardCvc = elements.create("cardCvc", {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardCvc.mount('#card-cvc');

            this.cardCvc.on('change', event => {
//                console.log("LOGGING THE CARD CVC EVENT", event);
                if (event.complete) {
                    console.log('CARD CVC completed');

                    this.cardCvcCompleted = true;
                    
                    // enable payment button
                    this.isIsset()
                } else if (event.error) {
                    // show event.error.message to customer
                    this.cardCvcCompleted = false;
                } else {
                    this.cardCvcCompleted = false;
                }
            });
        },
        created() {
            console.log('activeTab', this.activeTab);
            if (this.activeTab != null && this.activeTab === 'bank_account') {
                this.onBankAccountTabClick();
            }
        }
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 4;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
.close {
    position: absolute;
    margin-left: 94%;
    margin-top: -40px;
}
.inner-modal-div-form {
    background: #171717;
}
.inner-modal-div-form {
    border-radius: 10px;
}

.InputElement {
    color: white !important
}

.field {
    position: relative;
    cursor: text;
    height: 62px;
    background-color: rgb(37, 37, 37);
    /*
    background-color: #1E1E1E;
    */
    border-radius: 10px;
    display: flex;
    align-items: center;
} 

.m-stripe-input {
    width: 100%;
    padding: 20px 10px;
}


.user-input-wrp .stripe-floating-label {
    position: absolute;
    pointer-events: none;
    color: #ABABAB;
    top: 22px;
    left: 10px;
    transition: 0.2s ease all;
    font-family: 'Rubik-Light';
    font-size: 12px;
}


.user-input-wrp .m-stripe-input.focused + .stripe-floating-label,
.user-input-wrp .m-stripe-input:not(.empty) + .stripe-floating-label {
  top: 5px;
	left: 10px;
	font-size: 12px;
	opacity: 1;
}

.row {
    display: flex;
}

.user-input-wrp.half-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}


.user-input-wrp .m-stripe-input::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-input-wrp .m-stripe-input::-moz-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-input-wrp .m-stripe-input:-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-input-wrp .m-stripe-input.StripeElement {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
}

.user-input-wrp .m-stripe-input.focused,
.user-input-wrp .m-stripe-input:not(.empty) {
  opacity: 1;
}

.user-input-wrp .m-stripe-input.focused::-webkit-input-placeholder,
.user-input-wrp .m-stripe-input:not(.empty)::-webkit-input-placeholder {
  color: #cfd7df;
}

.user-input-wrp .m-stripe-input.focused::-moz-placeholder,
.user-input-wrp .m-stripe-input:not(.empty)::-moz-placeholder {
  color: #cfd7df;
}

.user-input-wrp .m-stripe-input.focused:-ms-input-placeholder,
.user-input-wrp .m-stripe-input:not(.empty):-ms-input-placeholder {
  color: #cfd7df;
}

@media screen and (max-width: 500px) {
    .user-input-wrp.left.half-width {
        margin-left: unset;        
    }
    .user-input-wrp.right.half-width {
        margin-right: unset;     
    }
}

@media screen and (max-width: 500px) {
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #171717;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-left: 101%;
        position: absolute;
        margin-top: 0;
    }
}
</style>