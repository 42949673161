<template>
    <modal-edit-employee v-if="isActiveEditUserPanel" 
    @close="setActivePanelAdd" 
    :email="selectUser.email" 
    :login="selectUser.username" 
    :name="(selectUser.last_name == null) ? selectUser.name : selectUser.name + ' ' + selectUser.last_name" 
    :phone="(selectUser.phone == null) ? '' : selectUser.phone" 
    :establishment="esta"
    :id="selectUser.id"
    :team="teams"/>

    <modal-edit-manager 
    v-if="isActiveEditManager" 
    @close="setActivePanelAddM" 
    :email="selectUser.email"
    :name="(selectUser.last_name == null) ? selectUser.name : selectUser.name + ' ' + selectUser.last_name" 
    :phone="(selectUser.phone == null) ? '' : selectUser.phone" 
    :establishment="esta" 
    :role="setArr(selectUser.acces)"
    :id="selectUser.id"/>
    <div class="container-dash staff-bg-mobile" ref="container">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': (isActiveEditUserPanel || isActiveEditManager), 'staff-wrapper': true}" class="staff-mobile">
            
            <div class="flex">
                <img src="../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.go(-1)">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0;">Staff management</h2>     
            </div>
            <div class="main-staff">
                <div class="top-bar-staff">
                    <div class="flex">
                        <div class="first-staff-nav">
                            <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': navStaff === 'Employee', 'staff-nav-mobile-1': true}" @click="setNav">Employee</p>
                        </div>
                        <div class="second-staff-nav">
                            <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': navStaff === 'Manager', 'staff-nav-mobile-2': true}" @click="setNav">Manager</p>
                        </div> 
                    </div>
                    <div class="border-staff flex border-staff-mobile">
                        <div :class="{'active-first': true, 'active-nav-staff': navStaff === 'Employee', 'staff-nav-mobile-1-line': navStaff === 'Employee'}"></div>
                        <div :class="{'second-first': true, 'active-nav-staff': navStaff === 'Manager', 'staff-nav-mobile-2-line': navStaff === 'Manager'}"></div>
                    </div>
                </div>
                <div v-if="navStaff == 'Employee'">
                    <my-button style="border-radius: 10px; display: flex; justify-content: center;align-items: center; margin-top: 30px;background-color: rgba(242, 142, 34, 0.1);" @click="$router.push('/staff/employeeregistr')">
                        <img src="../../assets/img/UI/plus-staff.svg">
                        <p class="ml-2 main-text-color" style="font-family: 'Rubik-Regular';">Add new employee</p>
                    </my-button>
                    <div class="mobile-boder-staff">
                        <staff-user 
                        v-for="el in workers"
                        :img="el.avatar" 
                        :name="(el.last_name == null) ? el.name : el.name + ' ' + el.last_name" 
                        :job="el.job" 
                        :username="el.username"
                        @getid="setActivePanelAdd" 
                        :id="el.id" 
                        @delete="deleteUser"/>
                    </div>
                </div>
                <div v-else>
                    <my-button style="border-radius: 10px; display: flex; justify-content: center;align-items: center; margin-top: 30px;background-color: rgba(242, 142, 34, 0.1);" @click="$router.push('/staff/mangerregistr')">
                        <img src="../../assets/img/UI/plus-staff.svg">
                        <p class="ml-2 main-text-color" style="font-family: 'Rubik-Regular';">Add new manager</p>
                    </my-button>
                    <div class="mobile-boder-staff">
                        <staff-user 
                        v-for="el in workers"
                        :img="el.avatar" 
                        :name="(el.last_name == null) ? el.name : el.name + ' ' + el.last_name" 
                        :job="el.job" 
                        :username="el.username"
                        @getid="setActivePanelAddM" 
                        :id="el.id" 
                        @delete="deleteUser"

                        :unactive="'false' == el.status"/>
                    </div>
                </div>
            </div>
           <paginate :count="Number(lastpage)" link="/staff" v-if="Number(lastpage) > 0"/>
        </div>

    </div>
</template>
    
<script>
    import staffMixin from '@/mixins/staffMixin.js';
    import getStaddMixin from '@/mixins/getStaddMixin';
    import sendEmployeeRegistrMixin from '@/mixins/sendEmployeeRegistrMixin'
    import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'main staff',
        mixins: [staffMixin, getStaddMixin, sendEmployeeRegistrMixin, loaderMixin],
        data() {
            return {
                job: 'Waiter',
                workers: [],
                lastpage: 0,
                selectUser: '',
                esta: '',
                teams: ''
            }
        },
        watch: {
            navStaff() {
                if(this.navStaff == 'Employee') this.job = "Waiter"
                else this.job = "Manager"
            },
            job() {
                this.getStaffMixins(this.job).then(data => {
                    this.lastpage = Math.ceil(data.count / 10)
                    this.workers = data.rows
                    this.esta = data.establishments
                    this.teams = data.teams
                })
            }
        },
        methods: {
            setArr(role) {
                console.log(role)
                if (role == 'Full access') {
                    return ['Full access', "Branch access"]
                } else {
                    return ['Branch access',  'Full access']
                }
            },
            showPanel(id) {
                console.log(id)
            },
            setActivePanelAdd(id) {
                this.selectUser = this.workers.find(el => el.id == id)
                this.isActiveEditUserPanel = !this.isActiveEditUserPanel
            },
            setActivePanelAddM(id) {
                this.selectUser = this.workers.find(el => el.id == id)
                this.isActiveEditManager = !this.isActiveEditManager
            },
            async deleteUser(id) {
                let query = await fetch(this.config.API_URL + '/user/delete/user/' + id, {
                    method: "DELETE",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("auth-token")
                    }
                })

                let result = await query.json()
                let index = this.workers.findIndex(el => el.id == id)

                this.workers.splice(index, 1)
            }
        },
        mounted() {
            this.getStaffMixins('Waiter').then(data => {
                this.lastpage = Math.ceil(data.count / 10)
                this.workers = data.rows
                this.esta = data.establishments
                this.teams = data.teams

                this.setLoader(false);
            })
        }
    }
</script>