<template>
    <button :class="{btn: true, not_active: !active}"><slot>{{ label }}</slot></button>
</template>

<script>
export default {
  name: 'my-button',
  data() {
	return {
		MyValue: ''
	}
  },
  props: {
    label: String,
    active: Boolean
  }
}
</script>

<style scoped>
.btn {
    width: 100%;
    border-radius: 200px;
    background-color: var(--orange-color);
    color: var(--child-bg-color);
    border: none;
    height: 48px;
    font-size: 16px;
}

.not_active {
    background-color: #2E2E2E;
    color: #818181;
}
</style>