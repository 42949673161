<template>
    <div class="outer-modal-div-form">
           <div class="middle-modal-div-form">
               <div class="inner-modal-div-form">
                       <h3>SIGN IN</h3>
                       <vue-tel-input wrapperClasses="rg" 
                        inputClasses="rtb" class="rg mt-2" 
                        ref="inp" 
                        style="border: 1px solid #252525; height: 47px; margin-top: 40px;outline: none; box-shadow: none; background-color: #1E1E1E !important;"  
                        v-model="phone" :style="{'height': '62px'}" 
                        validCharactersOnly="true" 
                        @validate="phoneObject" 
                        @country-changed="setCountryCode"
                        :enabledCountryCode="true"
                        :enabledFlags="false"
                        mode="international"> 
                            <template v-slot:arrow-icon>
                                
                            </template>
                        </vue-tel-input>  
                       <my-input :type="type" showFunc="true" label="Password" class="mt-1" v-model="password"/>
                       
                       <div><img src="../assets/img/UI/show.png" class="btn-show" @click="showNewPassword"></div>
                       <p class="error-input-text err-au" v-if="error != ''">{{ error }}</p>
                       <p style="color: #F28E22; font-size: 14px; margin-top: 15px" class="pointer sign-click-a" @click="$router.push('/forget')">Forgot your password?</p>
                       <div class="m-x-auto pointer" style="margin-top: 56px"><span style="color: #F28E22; font-size: 16px; font-family: 'Rubik-Medium';" @click="$router.push('/signin')">Sign in with email</span></div>
                       <my-button label="Sign In" @click="sendData" :active="isActive" class="mt-4"/>
                       
               </div>
           </div>
       </div>
       <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
   </template>
   
   <script>
   import signInMixin from '@/mixins/signInMixin'
   
       export default {
           name: 'sign in phone',
           mixins: [signInMixin],
           data() {
               return {
                    phone: '',
                    password: '',
                    isActive: false,
                    type: 'password',
                    isValidPhone: false,
                    countryCode: '',
                    error: ''
               }
           },
           watch: {
               phone() {
                if(this.phone.length < this.countryCode.length) {
                    this.phone = this.countryCode
                }
                   this.send()
               },
               password() {
                   this.send()
               },
               countryCode() {
                this.phone = this.countryCode
               }
           },
           methods: {
                setCountryCode(val) {
                    this.countryCode = "+" + val.dialCode
                },
                phoneObject(object) {
                    this.isValidPhone = object.valid;
                    console.log(object.valid)
                    console.log(this.phone.length)
                    this.send()
                },
               showNewPassword() {
                   if(this.type == 'password') this.type = 'text'
                   else this.type = 'password'
               },
               send() {
                   if(this.phone != "" && this.password != "" && this.isValidPhone) this.isActive = true
                   else this.isActive = false
               },
               sendData() {
                if(this.isActive) {
                    this.Auth(this.phone.split(" ").join(''),this. password).then(data => {

                        if(data?.ok == undefined) {
                            this.error = data.error
                        } else {
                            localStorage.setItem('auth-token', data.token)
                            if(data.role == 'admin') this.$router.push('/dashboard')
                            else this.$router.push('/employee/workspace')
                        }
                        
                    })
                }
               }
           }
       }
   </script>