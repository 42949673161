<template>
    <one-transaction 
        v-if="isActiveHistory"
        @close="setActiveHistory"
        :id="showData.id"
        :time="showData.time"
        :amount="showData.price"
        :desc="showData.desc"
        :date="showData.createdAt"
        :stars="showData.stars"
        style="position: fixed"
    />
    <div class="container-dash" >
        <employee-dashboard />
        <div class="wrapper-dash main-mirciq workspace-wrapper-das min-height pb-4" :class="{'calendar-active-cont': isActiveHistory}">
            <div class="workspace-wrapper"> 
                <h2 class="main-title-dash pointer" @click="$router.push('/employee/workspace')"><img src="../../../assets/img/UI/left-strel.svg">Tips</h2>
                <div class="main-b-graph mt-3">
                <p class="total-graph main-text-color">Total: ${{ Number(balance).toFixed(2) }}</p>
                <div class="line-graph">
                    <div class="flex line-graph-l">
                        <div v-for="el in line_arr" class="l-1" @click="e => {showMoney(e)}">
                            <div class="height-panel-ert">{{el.sum}}$</div>
                            <div class="l-1-div" :style="{'height': el.heightEl + 'px'}"></div>
                            <p class="l-p">{{el.day}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex date-filter-empl mt-2">
                    <div class="el-date-filter-empl pointer" @click="setFilterDate('Today'), get" :class="{'el-date-filter-empl-active': ActiveDateFilter == 'Today'}">
                        Today
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="setFilterDate('Week')" :class="{'el-date-filter-empl-active': ActiveDateFilter == 'Week'}">
                        Week
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="setFilterDate('Month')" :class="{'el-date-filter-empl-active': ActiveDateFilter == 'Month'}">
                        Month
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="setFilterDate('Year')" :class="{'el-date-filter-empl-active': ActiveDateFilter == 'Year'}">
                        Year
                    </div>
            </div>
            <div class="user-transactions">
                <!-- <p class="date-empl-w mt-2">20 December</p> -->
                <div class="histoery-work" v-for="el in trans">
                    <history-transaction-empl 
                        @transaction-show="setActiveHistory" 
                        :id="zeroPad(el.id)" 
                        :time="el.time" 
                        :date="el.createdAt.split('-')[1] + '-' + el.createdAt.split('-')[2].split('T')[0]"
                        :createdAt="el.createdAt"
                        :desc="(el.comment == null) ? '' : el.comment"
                        :stars="(el.stars == null) ? 0 : Number(el.stars)" 
                        :price="el.sum"
                        :type="el.type"
                        :additionalInfo="el.additional_info"
                    />
                </div>
            </div>
           <paginate :count="Number(lastpage)"
           link="/employee/tips"/>
            </div>
        </div>
    </div>
</template>

<script>
    import getFinanceMixin from '@/mixins/getFinanceMixin';
    import configMixin from '@/mixins/configMixin';
    import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'workspace tips',
        mixins: [getFinanceMixin, configMixin, loaderMixin],
        data() {
            return {
                ActiveDateFilter: 'Week',
                isActiveHistory: false,
                showData: {
                    id: '',
                    time: '',
                    price: '',
                    desc: '',
                    stars: '', 
                    createdAt: ''
                },
                trans: '',
                balance: '',
                lastpage: 0,
                line_arr: []
            }
        },
        watch: {
            "$route.query.page": function() {
                if(this.ActiveDateFilter == 'Today') this.FinanceMixin('Day')
                else this.FinanceMixin(this.ActiveDateFilter)
            }
        },
        methods: {
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            showMoney(e) {
                if(e.target.classList[0] != 'l-1') {
                    if(e.target.parentNode.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'none'
                    }
                } else {
                    if(e.target.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.querySelector('.height-panel-ert').style.display = 'none'
                    }
                }
            },
            setFilterDate(value) {
                this.ActiveDateFilter = value
                if(this.ActiveDateFilter == 'Today') this.FinanceMixin('Day')
                else this.FinanceMixin(value)
            },
            setActiveHistory(id, time, price, stars, desc, createdAt) {
                this.showData.id = id
                this.showData.time = time
                this.showData.price = price
                this.showData.stars = stars
                this.showData.desc = desc
                this.showData.createdAt = createdAt
                console.log('isActiveHistory', this.isActiveHistory);
                this.isActiveHistory = !this.isActiveHistory
            },
            getDayOfWeek(date, date_count) {
                if(date_count.view == 12) {
                    let need_date = new Date()  
                    let current = new Date(need_date.getFullYear(), need_date.getMonth()-date_count.value, 1);
                    const month = current.toLocaleString('en-US', { month: 'short' });
                    return month[0];

                } else if(date_count.view == 4) {
                    
                    const getDateForWeek = (countDayAgo) => {
                        const dateDaysAgo = new Date(Date.now() - countDayAgo * 24 * 60 * 60 * 1000)

                        let date = (dateDaysAgo.getDate() < 10) ? "0" + dateDaysAgo.getDate() : dateDaysAgo.getDate()
                        let month = (dateDaysAgo.getMonth() + 1 < 10) ? "0" + (dateDaysAgo.getMonth() + 1) : dateDaysAgo.getMonth() + 1

                        return date + '.' + month
                    }

                    const date_arr = {
                        '0': getDateForWeek(7) + " - " + getDateForWeek(0),
                        '1': getDateForWeek(15) + " - " + getDateForWeek(8),
                        '2': getDateForWeek(23) + " - " + getDateForWeek(16),
                        '3': getDateForWeek(30) + " - " + getDateForWeek(24)
                    }

                    return date_arr[String(date_count.value)]
                } else {
                    let current = date
                    let today = current.toLocaleDateString('en-US',{weekday: 'long'});

                    let today2 = new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(current);
                    
                    return today2[0]
                }
            },
            FinanceMixin(date) {
                Array.prototype.max = function() {
                        return Math.max.apply(null, this);
                };

                
                let page_id = (this.$route.query.page == undefined) ? 1 : this.$route.query.page
                this.getDataMixin(page_id, date).then(data => {
                    this.trans = data.rows
                    this.balance = data.balance
                    this.lastpage = Math.ceil(data.count / 10)
                    
                    let maxEl = Number(data.days.max())

                    this.line_arr = []


                    data.days.forEach((el, i) => {
                        let date = new Date()

                        let maxDay = data.days.length - 1

                        maxDay = maxDay - i

                    
                        date.setDate(new Date().getDate() - maxDay)
                        
                        let days_data = {
                            sum: (el == null) ? 0 : Number(el).toFixed(0),
                            heightEl: (el == null) ? 0 : Number(el) * 80 / maxEl,
                            day: this.getDayOfWeek(date, {
                                view: data.days.length,
                                value: maxDay
                            })
                        }
                        this.line_arr.push(days_data)
                    })

                    this.setLoader(false);
                })
            }
        },
        beforeMount() {
            this.FinanceMixin()
        }
    }
</script>