<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getDateCalendar" v-if="isActiveCalendar"/>
    <div class="container-dash average-bg-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'wrapper-average': true, 'calendar-active-cont': isActiveCalendar}">
            <div class="flex top-avarage-mobile">
                <h2 class="main-title-dash pointer" @click="$router.push('/establishment/member/statistic/' + this.$route.params?.user_id)"><img src="../../assets/img/UI/left-strel.svg"> Average tip value</h2>

            </div>
            <LineChartContainer 
                class="graph-1-2 mt-5" 
                style="height: 300px" 
                :dataOption="day" 
                v-if="day.length > 0"/>

            <div class="average-el">
                <h3>Tip 1</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
            </div>
            <div class="average-el">
                <h3>Tip 1</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
            </div>
            <div class="average-el">
                <h3>Tip 1</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
            </div>
        </div>

    </div>
</template>
    
<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import configMixin from '@/mixins/configMixin';

    export default {
        name: 'average order',
        mixins: [dashboardMixin, configMixin],
        data() {
            return {
                day: [],
            }
        },
        methods: {
            getDay() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/transaction/days', {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    this.day = data.days.map(el => {
                        if(el == null) return 0
                        else return el
                    })
                })
            },
        },
        beforeMount() {
            this.getDay()
        }
    }
</script>