<template>
<div class="cale" v-if="!isAddMemberNew">
        <div class="flex">
            <h3 class="head-title">Add new team</h3>
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="closeAddForms">
        </div>
        <my-input 
            label="Team name" 
            bgColor="#252525" 
            style="margin-top: 31px" 
            v-model="teamName"
            @keydown="codeKeyDown"
        />
        <p class="add-member-title mt-4">Add member</p>
        <staff-user v-for="member in members" 
        :img="member.avatar" 
        class="team-esta-el" 
        :name="member.name + ' ' + member.last_name" 
        :job="member.job" 
        setting="true"
        @delete="deleteUser(member.id)" 
        :id="member.id" />
        <div class="add-member-manager add-new-team-b m-x-auto" @click="setMember">
            <img src="../../assets/img/UI/add-member.svg">
            <span>add member</span>
        </div>
        <my-button label="Save" class="mt-4" :active="isActiveBtn" @click="sendData"/>
    </div>
    <add-member v-if="isAddMemberNew" @close="setMember" @getmembers="getNewUser" @addempl="addNewEmlp"/>
    
</template>

<script>
import configMixin from '@/mixins/configMixin'

    export default {
        name: 'add-team',
        mixins: [configMixin],
        data() {
            return {
                isActiveBtn: false,
                isAddMemberNew: false,
                isActiveAddNewEmpl: false,
                teamName: '',
                members: []
            }
        },
        watch: {
            teamName() {
                if(this.teamName != "") this.isActiveBtn = true
                else this.isActiveBtn = false
            }
        },
        methods: {
            addNewEmlp() {
                this.$emit('addempl')
            },
            getNewUser(users) {
                this.members = users
                this.setMember()
            },
            setMember() {
                this.isAddMemberNew = !this.isAddMemberNew
            },
            deleteUser(id) {
                this.members.forEach((el, i) => {
                    if(el.id == Number(id)) this.members.splice(i, 1)
                })
            },
            sendData() {
                if(this.isActiveBtn) {
                    let body = {
                        name: this.teamName,
                        users: this.members.map(el => {
                            return el.id
                        }),
                        establishment_id: this.$route.params?.establishment_id
                    }
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/team', {
                            method: "POST",
                            headers: {
                                'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                                'Content-Type': "application/json"
                            },
                            body: JSON.stringify(body)
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }
                    return response().then(data => {
                        window.location.reload();
                    })
                }
            },
            closeAddForms() {
                this.$emit('close')
            },
            codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.isActiveBtn) {
                        this.sendData();
                    }
                }
            }
        }
    }
</script>

<style scoped>
.add-new-team-b {
    width: 460px
}
.team-esta-el {
    width: 460px !important;
    background-color: #252525  !important;
}
.add-member-title {
    font-size: 18px;
    font-family: 'Rubik-Regular';
    color: white;
}
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
}
.cale {
    position: absolute;
    top: 200px;
    left: calc(60% - 408px);
    z-index: 3;
    opacity: 1;
    width: 540px;
    padding: 40px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .cale {
        padding: 15px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .team-esta-el {
        width: 290px !important;
    }
    .add-new-team-b {
        width: 290px;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
}
</style>