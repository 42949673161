<template>
    <div class="select-search" :style="style" v-click-outside="hide">
        <div ref="userInputWrp" class="user-input-wrp" @click="setClassSelect">
            <input 
                type="text" 
                ref="inp" 
                class="inputText" 
                style="height: 62px;" 
                v-model="value" 
                @focus="setClassSelect"
            />
            <span class="floating-label">{{ label }}</span>
            <img src="../assets/img/UI/select-panel-min.svg"  class="select-strel">
        </div>
        <div ref="select" class="select-dropdown">
            <input 
                class="inputText" 
                type="search" 
                ref="searchInput"
                autocomplete="off" 
                autocorrect="off" 
                autocapitalize="none" 
                spellcheck="false" 
                role="searchbox" 
                placeholder="Search..."
                @input="setFilter"
            >
            <ul class="" @click="setClassSelect" :style="{width: width ? width : '280px', 'background-color': (bgColor) ? bgColor : '#1E1E1E'}">
                <li v-for="item in filterBySearch" class="option" 
                    :values="(item?.name === undefined) ? item : item.name"                     
                    :style="{'background-color': (bgColor) ? bgColor + ' !important'  : '#1E1E1E'}" 
                    @click="e => {setValue((item?.name === undefined) ? item : item.name, e, item?.id)}"
                >
                    {{ (item?.name === undefined) ? item : item.name }}
                </li>
            </ul>
        </div>
    </div>
    
</template>

<script>

    export default {
        name: 'select-input-search',
        props: {
            width: String,
            label: String,
            inps: Array,
            modelValue: String,
            style: Object,
            bgColor: String
        },
        emits: ['getvalue'],
        data() {
            return {
                value: (this.inps[0]?.name === undefined) ? this.inps[0] : this.inps[0].name,
                id: (this.inps[0]?.id === undefined) ? this.inps[0] : this.inps[0].id,
                searchText: ''
            }
        },
        mounted() {
            this.$emit('getvalue', this.value, this.id);            
        },
        watch: {
            'inps': function() {
                this.value = (this.inps[0]?.name === undefined) ? this.inps[0] : this.inps[0].name;
                this.id = (this.inps[0]?.id === undefined) ? this.inps[0] : this.inps[0].id;
                this.$emit('getvalue', this.value, this.id);
            }
        },
        computed: {
            filterBySearch() {
                if(this.searchText.length > 0)
                    return this.inps.filter( (item) => {
                        const name = item.name !== undefined ? item.name : item;

                        const matcher = new RegExp(this.searchText.toLowerCase())
                        return matcher.test(name.toLowerCase());
                    });
                else
                    return this.inps
            }
        },
        methods: {
            setValue(value, e, id) {
//                this.$refs.select.insertBefore(e.target, this.$refs.select.firstChild)
                this.$refs.inp = value;
                this.value = value
                this.id = id
                this.getValue()
            },
            setClassSelect() {
                this.$refs.select.classList.toggle('open');
                this.$refs.searchInput.focus();
                this.$refs.userInputWrp.classList.toggle('hide');
//                this.$refs.select.style.marginBottom = '-' + String(this.$refs.select.offsetHeight - 500) + 'px'
            },
            getValue() {
                console.log('getvalue', this.value, this.id)
                this.$emit('getvalue', this.value, this.id)
            },
            setFilter(e) {
                console.log('setFilter', e.target.value);
                this.searchText = e.target.value;
            },
            hide: function() {
                console.log('hide');
                this.$refs.select.classList.remove('open');
                this.$refs.userInputWrp.classList.remove('hide');
            }
        },
    }
</script>

<style scoped>

.select-strel {
    position: relative;
    left: -10px
}
.floating-label {
    z-index: 2;
	position: relative;
    left: -320px;
	pointer-events: none;
    color: white;
	transition: 0.2s ease all;
    font-size: 12px;
    color: #ABABAB;
    margin-top: 5px;
    white-space: nowrap;
}
.esta-select, .select-search ul li.option {
    z-index: 1;
    padding-top: 13px;
    padding-bottom: 7px;
    border: none;
    color: white;
}
.esta-select {
    padding-left: 4px;
    max-height: 300px !important;
}
.closed {
    height: 59px !important
}

.rtb {
  background-color: #1E1E1E !important;
}
.rg:hover {
    background-color: #1E1E1E !important;
}
.vti__input {
    background-color: black;
}
.vti__dropdown-item {
    background-color: #1E1E1E;
}
.vti__dropdown-item.highlighted {
    background-color: #1E1E1E !important;
}
.vue-tel-input {
    background-color: #1E1E1E !important;
}
.vti__dropdown:hover {
    background-color: #1E1E1E !important;
}
 .user-input-wrp {
	position: relative;
}
.user-input-wrp .inputText{
	width: 100%;
	outline: none;
	background-color: #1E1E1E;
  font-size: 15px;
  color: #FFFFFF;
  padding-left: 10px;
  border: none;
  height: 62px;
	border-radius: 10px;
 	box-shadow: none !important;
}

.user-input-wrp .floating-label {
	position: absolute;
	pointer-events: none;
  color: #ABABAB;
	top: 22px;
	left: 10px;
	transition: 0.2s ease all;
}
.user-input-wrp .inputText:focus {
  border: 1px solid var(--orange-color)
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label{
	top: 5px;
	left: 10px;
	font-size: 12px;
	opacity: 1;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.padding-right-el {
  padding-right: 45px !important;
}
.error-input {
  border: 1px solid #EA4335 !important;
}
.user-input-wrp {
    width: 320px;
}
.select-dropdown {
    position: absolute;
    display: none;
    background-color: #1E1E1E;
    /* border: 1px solid #caced1; */
    border-radius: 0 10px;
    /* padding: 10px; */
    /* margin-top: 10px; */
    max-height: 312px;
    overflow-y: auto;    
}
.select-dropdown .inputText {
    border: 1px solid #F28E22;
}
.select-dropdown.open {
    display: block;
}
.select-dropdown ul {
    list-style-type: none;
    padding: 10px;
}
.user-input-wrp.hide {
    display: none;
}

</style>