import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        getAchievments() {

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/achievments', {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    },
    data() {
        return {
            achievments: [
                {
                    id: 1,
                    title: 'WELCOME!',
                    text: 'First tip',
                    img: 'welcome.svg'
                },
                {
                    id: 2,
                    title: 'FIRST PAYOUT',
                    text: 'The first payout',
                    img: 'profit.svg'
                },
                {
                    id: 3,
                    title: 'PROFESSIONAL',
                    text: '10 transactions',
                    img: 'profit.svg'
                    
                },
                {
                    id: 4,
                    title: 'GURU',
                    text: '50 transactions',
                    img: 'profit.svg'
                },
                {
                    id: 5,
                    title: 'SUPER STAR',
                    text: '100 transactions',
                    img: 'profit.svg'
                },
                {
                    id: 6,
                    title: 'TOP USER',
                    text: '300 transactions',
                    img: 'profit.svg'
                },
                {
                    id: 7,
                    title: 'PRIORITY USER',
                    text: '500 transactions',
                    img: 'profit.svg'
                },
                {
                    id: 8,
                    title: 'FIRST REVIEW',
                    text: 'First review',
                    img: 'smile.svg'
                },
                {
                    id: 9,
                    title: 'MASTER OF TRUST',
                    text: '50 reviews',
                    img: 'smile.svg'
                },
                {
                    id: 10,
                    title: 'INFLUENCE STAR',
                    text: '100 reviews',
                    img: 'smile.svg'
                },
                {
                    id: 11,
                    title: 'FIRST WEEK',
                    text: '7 days after registration',
                    img: 'calendar.svg'
                },
                {
                    id: 12,
                    title: 'PART OF THE FAMILY',
                    text: '30 days after registration',
                    img: 'calendar.svg'
                },
                {
                    id: 13,
                    title: 'RELIABLE PARTNER',
                    text: '90 days after registration',
                    img: 'calendar.svg'
                },
                {
                    id: 14,
                    title: 'ANNIVERSARY',
                    text: '180 days after registration',
                    img: 'calendar.svg'
                },
                {
                    id: 18,
                    title: 'RIGHT TO MISTAKE',
                    text: 'The first negative review',
                    img: 'dislike.svg'
                },
            ]
        }
    }
}