<template>
    <div id="learning-menu" class="dasboard-nav-panel-mobile">
        <h2 class="logo-dashboard pointer" @click="$router.push('/dashboard')">MERCI<span class="h orange-color logo-dashboard">Q</span></h2>
        <img src="../../assets/img/UI/mobile-menu.svg" @click="setMobile"/>
    </div>
    <div class="null-div-mobile"></div>
    <div class="dasboard-nav-panel" ref="menu">
        <h2 class="logo-dashboard none-mobile pointer" @click="$router.push('/dashboard')">MERCI<span class="h orange-color logo-dashboard">Q</span></h2>
        <div class="dashboard-inner">
            <div class="flex user-title-dashboard">
                <img
                :src="'/img/uploads/logo/esta/' + userData.avatar"
                v-if="userData.avatar != undefined && 
                userData.avatar != null">
                <p class="ml-2">{{ userData.name }}</p>
            </div>
            <div id="learning-menu-desktop" class="dashboard-li mt-4">
                <p class="title-p">Management</p>
                <div class="dashboard-li-punkt flex first pointer" @click="$router.push('/dashboard')">
                    <img src="../../assets/img/UI/dashboard.svg">
                    <p class="ml-1">Dashboard</p>
                </div>
                <div class="dashboard-li-punkt flex last pointer" @click="$router.push('/staff')">
                    <img src="../../assets/img/UI/staff.svg">
                    <p class="ml-1">Staff management</p>
                </div>
            </div>
            <div class="dashboard-li mt-4">
                <p class="title-p">Establishments</p>
                <div class="dashboard-li-punkt flex first pointer" 
                @click="$router.push('/settings/establishments')">
                    <img src="../../assets/img/UI/Establishments.svg">
                    <p class="ml-1">All establishments</p>
                </div>
                <div class="dashboard-li-punkt flex pointer" 
                @click="$router.push('/establishment/team/' + el.id)"
                v-for="el in esta">
                    <img src="../../assets/img/UI/Establishments.svg">
                    <p class="ml-1">{{ el.name }}</p>
                </div>
                <div class="dashboard-li-punkt flex last pointer" @click="$router.push('/establishment/add')">
                    <img src="../../assets/img/UI/add.svg">
                    <p class="ml-1">Add establishment</p>
                </div>
            </div>
            <div class="dashboard-li mt-4">
                <p class="title-p">Opportunities</p>
                <div class="dashboard-li-punkt flex one pointer" @click="$router.push('/excel')">
                    <img src="../../assets/img/UI/Excel.svg">
                    <p class="ml-1">Reporting excel</p>
                </div>
            </div>
            <div class="dashboard-li mt-4">
                <p class="title-p">Profile</p>
                <div id="desktop-menu-learning" class="dashboard-li-punkt flex first pointer" v-if="$route.user.job != 'Manager'" @click="$router.push('/settings')">
                    <img src="../../assets/img/UI/settings.svg">
                    <p class="ml-1">Settings</p>
                </div>
                <div class="dashboard-li-punkt flex pointer" 
                @click="$router.push('/qr')"
                :class="{'first': $route.user.job == 'Manager'}">
                    <img src="../../assets/img/UI/QRclient.svg" style="width: 17px;">
                    <p class="ml-1">QR-code</p>
                </div>
                <div class="dashboard-li-punkt flex last pointer" @click="goToInstruction">
                    <img src="../../assets/img/UI/instructions.svg">
                    <p class="ml-1">Instructions</p>
                </div>
                <div class="dashboard-li-punkt flex last pointer" @click="$router.push('/nfc')">
                    <p class="ml-1">Get your NFC card</p>
                </div>
                <div class="dashboard-li-punkt flex last pointer" @click="$router.push('/stripe')">
                    <p class="ml-1">Verification</p>
                </div>
                <div class="dashboard-li-punkt flex last pointer" @click="$router.push('/payout')">
                    <p class="ml-1">Payout</p>
                </div>
            </div>
            <button class="profile-btn profile-btn-red mt-1" style="width: 100%;" @click="exit()">Log out</button>
            <div class="lang-panel flex">
                <img src="../../assets/img/UI/usa.svg">
                <p>ENG</p>
                <img src="../../assets/img/UI/strel.svg">
            </div>
        </div>
    </div>
    <div  class="main-menu-mobile" ref="mobile" @click="onMobileMenuClick">
        <div class="dasboard-nav-panel-mobile">
            <h2 class="logo-dashboard">MERCI<span class="h orange-color logo-dashboard">Q</span></h2>
            <img id="mobile-menu-hamburger" src="../../assets/img/UI/close-staff.svg" @click="setMobile" class="pointer"/>
        </div>
        <div class="flex user-title-dashboard w-320-mobile mobile-login mb-2">
                <img  
                :src="'/img/uploads/logo/esta/' + userData.avatar"
                v-if="userData.avatar != undefined && 
                userData.avatar != null">
                <p class="ml-2">{{ userData.name }}</p>
        </div>
        <div id="mobile-menu-learning">
            <div :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'menu-active': $router.currentRoute.value.path === '/dashboard'
                }" 
                @click="$router.push('/dashboard')"
            >
                <img src="../../assets/img/UI/dashboard.svg">
                <p class="ml-1">Dashboard</p>
            </div>
            <div :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'menu-active': $router.currentRoute.value.path === '/staff'
                }" 
                @click="$router.push('/staff')"
            >
                <img src="../../assets/img/UI/staff.svg">
                <p class="ml-1">Staff management</p>
            </div>
            <div :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'menu-active': $router.currentRoute.value.path === '/settings/establishments'
                }" 
                @click="$router.push('/settings/establishments')"
            >
                <img src="../../assets/img/UI/Establishments.svg">
                <p class="ml-1">All establishments</p>
            </div>
        </div>
        <div v-for="el in esta" 
            :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'establishment-team': true,
                'menu-active': $router.currentRoute.value.path === '/establishment/team/' + el.id
            }"         
            @click="$router.push('/establishment/team/' + el.id)"
        >
            <img src="../../assets/img/UI/Establishments.svg">
            <p class="ml-1">{{ el.name }}</p>
        </div>
        <div id="mobile-menu-establishment" 
            :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'menu-active': $router.currentRoute.value.path === '/establishment/add'
            }" 
            @click="$router.push('/establishment/add')"
        >
            <img src="../../assets/img/UI/add.svg">
            <p class="ml-1">Add establishment</p>
        </div>
        <div :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'menu-active': $router.currentRoute.value.path === '/excel'
            }" 
            @click="$router.push('/excel')"
        >
            <img src="../../assets/img/UI/Excel.svg">
            <p class="ml-1">Reporting excel</p>
        </div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el" v-if="$route.user.job != 'Manager'"  @click="$router.push('/settings')">
            <img src="../../assets/img/UI/settings.svg">
            <p class="ml-1">Settings</p>
        </div>
        <div :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'menu-active': $router.currentRoute.value.path === '/qr'
            }" 
            @click="$router.push('/qr')"
        >
            <img src="../../assets/img/UI/QRclient.svg" style="width: 17px;">
            <p class="ml-1">QR-code</p>
        </div>
        <div class="dashboard-li-punkt flex main-menu-mobile-el" @click="goToInstruction">
            <img src="../../assets/img/UI/instructions.svg">
            <p class="ml-1">Instructions</p>
        </div>
        <div :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'menu-active': $router.currentRoute.value.path === '/nfc'
            }" 
            @click="$router.push('/nfc')"
        >
            <p class="ml-1">Get your NFC card</p>
        </div>
        <div :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'menu-active': $router.currentRoute.value.path === '/stripe'
            }" 
            @click="$router.push('/stripe')"
        >
            <p class="ml-1">Verification</p>
        </div>
        <div :class="{
                'dashboard-li-punkt': true, 
                'flex': true, 
                'main-menu-mobile-el': true, 
                'menu-active': $router.currentRoute.value.path === '/payout'
            }" 
            @click="$router.push('/payout')"
        >
            <p class="ml-1">Payout</p>
        </div>
        <button class="profile-btn profile-btn-red mt-1" style="width: 295px; margin-left: auto;margin-right: auto; display: flex; justify-content: center;" @click="exit()">Log out</button>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'
import getAdminDataMixin from '@/mixins/getAdminDataMixin'

export default {
    mixins: [configMixin, getAdminDataMixin],
    name: 'dashboard-nav',
    data() {
        return {
            paths: process.env.BASE_URL,
            name: '',
            esta: [],
            userData: {
                avatar: '',
                name: ''
            }
        }
    },
    props: {
        establishments: String,
        avatar: String
    },
    emits: ['getavatar'],
    methods: {
        exit() {
            localStorage.removeItem('auth-token');
            this.$router.push('/');
        },
        setMenu() {
            if(this.$refs.menu.style.display == 'block') {
                this.$refs.menu.style.display = 'none'
            }
            else {
                this.$refs.menu.style.display = 'block'
            }
        },
        setMobile() {
            if(this.$refs.mobile.style.display == 'block') {
                this.$refs.mobile.style.display = 'none'
            }
            else {
                this.$refs.mobile.style.display = 'block'
            }
        },
        getNavData() {
            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/nav', {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    },
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response().then(data => {
                this.esta = data.establishment

                let last_name = (data.user.last_name == null) ? "" : data.user.last_name
                
                this.userData.name = data.user.name + " " + last_name
                this.userData.avatar = data.user.avatar
                this.$emit('getavatar', data.user.avatar)
            })
        },
        onMobileMenuClick(event) {
            const target =  event.target;
            const parent = target.parentNode;

            if (target.classList.contains('dashboard-li-punkt') 
                || target.parentNode.classList.contains('dashboard-li-punkt')
            ) {
                if (target.classList.contains('menu-active') 
                        || target.parentNode.classList.contains('menu-active')
                        || target.classList.contains('establishment-team') 
                        || target.parentNode.classList.contains('establishment-team'))
                    this.$refs.mobile.style.display = 'none';
            }
        },
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        goToInstruction() {
            if (this.isMobile()) {
                window.location.href='/docs/Merciq_business_mob.pdf';
            } else {
                window.location.href='/docs/MerciQ_GUIDE_BUSINESS_WEB.pdf';
            }
        }
    },
    beforeMount() {
        // this.getData().then(data => {
        //     //this.$refs.img_pk.src = '/img/uploads/logo/esta/' + data.admin.avatar

        //     let last_name = (data.admin.last_name == null) ? "" : data.admin.last_name

        //     this.name = data.admin.name + " " + last_name


        // })

        console.log(this.$route)
        this.getNavData()
       

    }

}
</script>

<style scoped>
* {
    z-index: 5;
}
.main-menu-mobile {
    width: 100%;
    display: none;
}
.dasboard-nav-panel-mobile {
    display: none;
    
}
.dasboard-nav-panel-mobile {
    padding-left: 20px;
    height: 54px;
    width: 100%;
    background-color: #1E1E1E;
}
.dasboard-nav-panel-mobile img{
    margin-left: auto;
    margin-right: 23px;
    width: 18px;
}
.dasboard-nav-panel {
    margin-right: 10px;
    border: none;
    padding-top: 40px;
    width: 280px;
    background-color: var(--main-bg-color);
}
.dashboard-inner {
    width: 220px;
    margin-left: 30px;
    margin-top: 104px;
}
.logo-dashboard {
    text-align: center;
    font-size: 18px;
}
.user-title-dashboard p {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    color: var(--main-text-color);
    font-family: 'Rubik-Regular';
}
.user-title-dashboard img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
}
.dashboard-li .title-p {
    font-size: 14px;
    font-family: 'Rubik-Regular';
    color: var(--not-active-text-color);
}
.dashboard-li-punkt {
    padding-top: 15px;
    width: 100%;
    background-color: var(--dashboard-el);
    padding-bottom: 15px;
    border-top: 1px solid #363636;
}
.dashboard-li-punkt img {
    margin-left: 16px;
}
.dashboard-li-punkt p{
    color: var(--main-text-color)
}
.first {
    margin-top: 15px;
    border-radius: 10px 10px 0 0;
    border: none;
}
.last {
    border-radius: 0 0 10px 10px;
}
.lang-panel {
   justify-content: center;
   margin-top: 92px;
   padding-bottom: 40px;
}
.lang-panel p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    font-size: 16px;
    color: var(--main-text-color)
}
.one {
    border-radius: 10px;
    border: none;
    margin-top: 15px;
}

@media screen and (max-width: 500px) {
    .main-menu-mobile {
        display: none;
        background-color: #1E1E1E;
        width: 100%;
        position: fixed;
        z-index: 10;
       padding-bottom: 40px;
        overflow-y: auto;
        padding-bottom: 0;
        top: 0;
        bottom: 0
    }
    .mobile-login {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        margin-top: 50px;
    }
    .main-menu-mobile-el {
        margin-left: auto;
        margin-top: 10px;
        border: none;
        margin-right: auto;
        width: 320px;
        cursor: pointer;
        background-color: #252525;
        padding: 20px;
        padding-left: 5px;
        border-radius: 10px;

    }
    .dasboard-nav-panel {
        display: none;
        position: absolute;
    }
    body {
        opacity: 1;
    }
    .logo-dashboard {
        text-align: left;
        margin-top: 15px;
    }
    .dashboard-inner {
        margin-top: 0px;
    }
    .none-mobile {
        display: none;
    }
    .dasboard-nav-panel-mobile {
        position: fixed;
        display: flex;
    }
    .null-div-mobile {
        height: 40px;
        position: relative;
        z-index: -1;
    }
    .dasboard-nav-panel-mobile {
        
        z-index: 10
    }
}
</style>
