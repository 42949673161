<template>
    <div class="cale">
            <div class="flex">
                <h3 class="head-title">Leave a request</h3>
                <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="closeAddForms">
            </div>
                    <vue-tel-input wrapperClasses="rg" 
                    inputClasses="rtb" class="rg mt-2" 
                    ref="inp" 
                    style="border: 1px solid #252525; height: 47px;outline: none; box-shadow: none; background-color: #252525 !important;"  
                    v-model="formData.phone" :style="{'height': '62px'}" 
                    validCharactersOnly="true" 
                    @validate="phoneObject" 
                    @country-changed="setCountryCode"
                    :enabledCountryCode="true"
                    :enabledFlags="false"
                    mode="international"> 
                        <template v-slot:arrow-icon>
                        </template>
                    </vue-tel-input>  
            <my-input label="Email" bgColor="#252525" style="margin-top: 10px" v-model="formData.email"/>
            <my-input label="Name" bgColor="#252525" style="margin-top: 10px" v-model="formData.name"/>
            <div class="mob-btn-le">
                <my-button label="Send" class="mt-4" :active="isActiveBtn" @click="sendData"/>
            </div>
        </div>
    </template>
    
    <script>
        export default {
            name: 'leave-request',
            data() {
                return {
                    isActiveBtn: false,
                    formData: {
                        phone: '',
                        email: '',
                        name: ''
                    },
                    isValid: false,
                    countryCode: ''
                }
            },
            watch: {
                'formData.phone': function() {
                    this.isIsset()
                },
                'formData.phone': function() {
                    if(this.formData.phone.length < this.countryCode.length) {
                        this.formData.phone = this.countryCode
                    }
                    this.isIsset()
                },
                'formData.name': function() {
                    this.isIsset()
                },
                countryCode() {
                    this.formData.phone = this.countryCode
                }
            },
            methods: {
                setCountryCode(val) {
                    this.countryCode = "+" + val.dialCode
                },
                deleteUser(id) {
                    this.members.forEach((el, i) => {
                        if(el.id == Number(id)) this.members.splice(i, 1)
                    })
                },
                phoneObject(object) {
                        this.isValid = object.valid;
                        console.log(this.isValid)
                },
                sendData() {
                    if(this.isActiveBtn) this.$emit('getdata', this.formData)
                },
                closeAddForms() {
                    this.$emit('close')
                },
                isIsset() {
                   const validateEmail = (email) => {
                        return String(email)
                            .toLowerCase()
                            .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            );
                    };
                    if(this.formData.phone != "" && 
                    this.formData.email != "" && 
                    this.formData.name != "" && validateEmail(this.formData.email)) {
                        this.isActiveBtn = true
                    } else {
                        this.isActiveBtn = false
                    }
                }
            }
        }
    </script>
    
    <style scoped>
    .add-new-team-b {
        width: 460px
    }
    .team-esta-el {
        width: 460px !important;
        background-color: #252525  !important;
    }
    .add-member-title {
        font-size: 18px;
        font-family: 'Rubik-Regular';
        color: white;
    }
    .close {
        margin-left: auto;
    }
    .head-title {
        font-size: 24px;
        font-family: 'Rubik-Medium';
        color: var(--main-text-color)
    }
    .cale {
        position: absolute;
        top: 200px;
        left: calc(60% - 408px);
        z-index: 3;
        opacity: 1;
        width: 540px;
        padding: 40px;
        background-color: #1E1E1E;
        border-radius: 10px;
    }
    @media screen and (max-width: 500px) {
        .cale {
            padding: 20px;
            width: 320px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
        .head-title {
            margin-left: auto;
        }
    }
    </style>