export default {
    data() {
        return {
            navStaff: 'Employee',
            isActiveEditUserPanel: false,
            isActiveEditManager: false,
            edit_id: '',
            dataEditManger: {
                phone: '',
                full_name: '',
                email: '',
                role: '',
                establishment: ''

            },
            dataEditEmployee: {
                name: '',
                login: '',
                establishment: '',
                email: '',
                phone: '',
                team: ''
            }
        }
    },
    methods: {
        setNav() {
            if(this.navStaff == 'Employee') this.navStaff = 'Manager'
            else this.navStaff = 'Employee'
        }
    }
}