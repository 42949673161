import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        getMemeber() {
           
            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/member/' + this.$route.params?.user_id, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })
                if(query.status != 200) return this.$router.push('/dashboard')

                let result = await query.json()

                if(result?.user?.id == undefined || 
                    result?.user?.id == null ||
                    isNaN(Number(result?.user?.id))) return this.$router.push('/dashboard')
                
                return Promise.resolve(result)
            }

            return response()
        }
    }
}