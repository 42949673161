<template>

</template>

<script>
export default {
    name: 'type business',

}
</script>

<style>

</style>