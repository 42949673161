<template>
    <canvas ref="planet_chart" style="pointer-events: none;"></canvas>
</template>
<script>
import Chart from 'chart.js'

export default {
    name: 'LineChartContainer',
    data() {
        return {
            planetChartData: {
            type: "line",
            data: {
            labels: ['', '', '', '', '', '', ''],
            datasets: [{
                data: [...this.dataOption],
                label: 'Total $',
                fill: false,
                borderColor: '#F28E22',
                tension: 0,
                fill: true,
                backgroundColor: '#42311E'
            }]
            },
            options: {
                responsive: false,
                lineTension: 1,
                scales: {
                yAxes: [
                    {
                    ticks: {
                        beginAtZero: true,
                        padding: 25
                    }
                    }
                ]
                }
            }
            }
        }
    },
    methods: {
        getDayOfWeek(date) {
                let current = date

                let today2 = new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(current);

                return today2[0]
        },
    },
    watch: {
        dataOption() {
            this.planetChartData.data.datasets.data = this.dataOption
            console.log(this.planetChartData.data.datasets.data)
            const ctx = this.$refs.planet_chart;
            const exampleChart = new Chart(ctx, this.planetChartData);
        },
    },
    props: {
        dataOption: Array
    },
    mounted()  {
        this.planetChartData.data.labels.forEach((el, i) => {
                let date = new Date()

                let maxDay = 6

                maxDay = maxDay - i

                date.setDate(new Date().getDate() - maxDay)
                this.planetChartData.data.labels[i] = this.getDayOfWeek(date)
            })
        const ctx = this.$refs.planet_chart;
        const exampleChart = new Chart(ctx, this.planetChartData);
    }
}
</script>

<style>
    canvas {
        clip-path: inset(24px 0 0 0);
    }
</style>