export default {
    methods: {
        addBusinessInput(arr) {
            if(arr.indexOf('owners.address.line1') != -1) this.filled.personal.address = true
            if(arr.indexOf('owners.address.postal_code') != -1) this.filled.personal.zip = true
            if(arr.indexOf('owners.dob.day') != -1 || 
            arr.indexOf('owners.dob.month') != -1 ||  
            arr.indexOf('owners.dob.year') != -1) this.filled.personal.birthday = true
            if(arr.indexOf('owners.email') != -1) this.filled.personal.email = true
            if(arr.indexOf('owners.phone') != -1) this.filled.personal.phone = true
            if(arr.indexOf('business_profile.url') != -1) this.filled.public.website = true
            if(arr.indexOf('company.tax_id') != -1) this.filled.company.tax = true
            if(arr.indexOf('ssn_last_4') != -1 || 
            arr.indexOf('company.ssn_last_4') != -1) this.filled.company.ssn = true
            if (arr.indexOf('external_account') != -1) {
                this.filled.external.account_number = true
                this.filled.external.name = true
                this.filled.external.type = true
                this.filled.external.month = true
                this.filled.external.year = true
                this.filled.external.card = true
                this.filled.external.cardName = true
            }
        },
        checkFilledInput(object) {
            object.user = []
            if(object?.required_docs?.requirements?.past_due != undefined) {
                object.user = object.required_docs.requirements.past_due
            }

            object.business = []
            if(object.Business?.required_docs?.requirements?.past_due != undefined) {
                object.business = object.Business.required_docs.requirements.past_due
            }

                object = [
                    ...object.user,
                    ...object.business
                ]

                if(object.length > 0) {
                    this.addBusinessInput(object)
                }
            }
        
    }
}