<template>
    <loader :active="showLoader" />
    <div class="app" :style="{filter: showLoader ? 'blur(0.3rem)' : 'blur(0rem)'}">
        <router-view></router-view>
    </div>
    <accept-cookie style="z-index: 25; position: relative"/>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'App',
        computed: {
            ...mapGetters({
                showLoader: 'loader/getLoader'
            }),

        },
    }
</script>