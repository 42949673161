<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
            <div>
                <VueImageUpload :value="urls"/>
            </div>
            <avatar-editor 
            label="Avatar" 
            @get="getAvatar" 
            :srcImg="formData.avatar"/>
            <img ref="t_img" src="" class="mt-2" style="border-radius: 100px;" v-if="isUploadPhoto">
            <my-input label="Name" width="320px" bgColor="#252525" class="mt-2" v-model="formData.name"/>
            <my-input label="Surname" width="320px" bgColor="#252525" class="mt-2" v-model="formData.last_name"/>
            <div class="flex mt-2" v-if="false">
                <div class="select" :style="style">
                <ul ref="select" class="closed esta-select select-w"  @click="setClassSelect" :style="{ 'background-color': '#252525 !important', border: 'none'}">
                    <li :values="item" v-for="item in inps" class="option" :style="{'background-color': '#252525 !important', 'text-align': 'left', 'padding-top': '20px', 'padding-bottom': '20px', 'padding-left': '10px'}" @click="setValue(item, e)">{{ item }}</li>
                </ul>
            </div>
            <img src="../../assets/img/UI/select-panel-min.svg"  class="select-strel" @click="setClassSelect">
            <span class="floating-label" :style="{left: width ? '-'+ String(Number(width.split('px').join('')) - 10) + 'px' : '-275px'}">{{ label }}</span>
            </div>
            <vue-tel-input wrapperClasses="rg" 
            inputClasses="rtb" class="rg mt-2" 
            ref="inp" 
            style="border: none; height: 47px;outline: none; box-shadow: none; background-color: #1E1E1E !important;"  
            v-model="formData.phone" :style="{'height': '62px'}" 
            validCharactersOnly="true" 
            @validate="phoneObject" 
            @country-changed="setCountryCode"
            :enabledCountryCode="true"
            disabled
            :enabledFlags="false"
            mode="international" > 
            <template v-slot:arrow-icon>
                </template>
            </vue-tel-input>

            <p class="desc-inp-setting-sec empl-ch-data" @click="changePhone">Change</p>
            
            <my-input 
                type="email" 
                v-model="formData.email" 
                @keydown="(e) => e.preventDefault()" 
                width="320px" 
                bgColor="#252525" 
                class="mt-4"                
            />
            
            <p class="desc-inp-setting-sec empl-ch-data" @click="changeEmail">Change</p>
            <my-input 
                type="password" 
                v-model="password" 
                @keydown="(e) => {e.preventDefault(); codeKeyDown(e)}" 
                width="320px" 
                bgColor="#252525" 
                class="mt-4"
            />

                <p class="desc-inp-setting-sec empl-ch-data" @click="changePassword">Change</p>
            <button class="border-grey mt-8 btnj-short" style="height: 48px;border: 1px solid #343434" @click="Notif">Notifications</button> 
            <button class="border-grey mt-1 btnj-short" style="height: 48px;border: 1px solid #343434" @click="Security">Security section</button>
            <my-button 
                label="Save" 
                :active="isActive" 
                @click="sendData" 
                class="mt-1"
            />
        </div>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'

    export default {
        name: 'update-info-empl',
        mixins: [configMixin],
        data() {
          return {
           isActive: false,
           formData: {
              number: '',
              date: {
                day: '',
                month: ''
              },
              cvv: '',
              code: ''
           },
           inps: ['USA', 'USA', 'USA'],
            isSelectImg: false,
            imgs: '',
            urls: '',
            rotation: 0,
            scale: 1,
            formData: {
                avatar: this.avatar,
                email: this.email,
                phone: this.phone,
                name: this.name,
                last_name: this.last_name
            },
            isUploadPhoto: false,
            phone: '+42 042 250 110',
            email: 'sdfsdfsdf@mail.ru',
            isValid: true,
            countryCode: '',
            index: 0,
            isImageSet: false,
            password: '********'
          }
       },
       watch: {
        'formData.avatar': function() {
            this.isIsset()
        },
        'formData.email': function() {
            this.formData.email = this.email
            this.isIsset()
        },
        'formData.phone': function() {
            if(this.formData.phone.length < this.countryCode.length) {
                        this.formData.phone = this.countryCode
                    }
            this.isIsset()
        },
        'formData.name': function() {
            this.isIsset()
        },
        'formData.last_name': function() {
            this.isIsset()
        },
        
       },
       props: {
        avatar: String,
        email: String,
        phone: String,
        name: String,
        last_name: String,
       },
       methods: {
        changePassword() {
            
            this.$emit('changepassword')
        },
        sendData() {
            if(this.isActive) {

                let body = new FormData()

                body.append('name', this.formData.name)
                body.append('last_name', this.formData.last_name)

                if(this.isImageSet) body.append('avatar', this.formData.avatar.get('file'))

                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/employee/profile', {
                        method: "PUT",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        },
                        body: body
                    })
                    
                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    this.$emit('getdata')
                    this.$router.go()
                })
            }
        },
        setCountryCode(val) {
            this.countryCode = "+" + val.dialCode
        },
        phoneObject(object) {
            this.isValid = object.valid;
            this.isIsset()
        },
        setValue(value, e) {
                this.value = value
                this.$refs.select.insertBefore(e.target, this.$refs.select.firstChild)
                this.getValue()
            },
        setClassSelect(value) {
                this.$refs.select.classList.toggle('closed');
                this.$refs.select.style.marginBottom = '-' + String(this.$refs.select.offsetHeight - 500) + 'px'
            },
        getAvatar(value) {
            this.formData.avatar = value
            this.isImageSet = true
        },
        setClick() {
            this.isSelectImg = !this.isSelectImg
        },
       saveClicked () {
            let img = this.$refs.vueavatar.getImageScaled()
            this.$refs.t_img.src = img.toDataURL()
        },
        isIsset() {
            const validateEmail = (email) => {
                return String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            };
            this.index++
            if(this.isValid && 
            this.index > 2
            && this.formData.avatar != '' 
            && this.formData.email != '' 
            && validateEmail(this.formData.email)
            && this.formData.phone != '' 
            && this.formData.name != ''
            && this.formData.last_name != '') this.isActive = true
            else this.isActive = false
        },
        getData() {
            if(this.isActive) this.$emit('getdata')
        },
        changePhone() {
            this.$emit('changephone')
        },
        changeEmail() {
            this.$emit('changeemail')
        },
        close() {
            this.$emit('close')
        },
        setUploadPhoto() {
            this.isUploadPhoto = !this.isUploadPhoto
        },
        Notif() {
            this.$emit('notif')
        },
        Security() {
            this.$emit('security')
        },
        codeKeyDown(event) {
            if (event.keyCode === 13) {
                if (this.isActive) {
                    this.sendData();
                }
            }
        }      
    },
    
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}

.close {
    position: absolute;
    margin-left: 55%;
    margin-top: -40px;
}
.inner-modal-div-form {
    background: #1E1E1E;
}
.inner-modal-div-form {
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .avatar-empl {
        margin-top: 20px;
    }
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #1E1E1E;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
        top: 50px;
        min-height: 100vh;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-left: 40%;
        position: absolute;
        margin-top: -0px;
    }
    .btnj-short  {
        width: 100%;
    }
    .select-w {
        width: 100% !important;
    }
    .select {
        width: 100%;
    }
}
</style>