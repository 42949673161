<template>
    <div class="main_block mt-2">
        <div class="Position white" style="background-color: var(--main-bg-color)">{{ place }}</div>
        <div class="Team_name white" style="background-color: var(--main-bg-color)">{{ team }}</div>
        <div class="Rate white flex" style="background-color: var(--main-bg-color)"><img src="../assets/img/UI/start.svg"><span>{{ rate }}</span></div>
    </div>
</template>

<script>
export default {
    name: 'TeamRate',
    props: {
        place: String,
        team: String,
        rate: String
    },
    
}
</script>

<style scoped>

.Rate img {
    margin-right: 10px;
}
.white span{
    color: #FFFFFF;
}
</style>
