import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        getDataEmployeePublic() {

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/employeedata?email=' + localStorage.getItem('userData.email'), {
                    method: 'GET'
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}