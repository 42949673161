<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <h2 class="main-title-dash pointer" @click="close"><img src="../../assets/img/UI/left-strel.svg"> Transaction history</h2>
            <div class="flex mt-2">
                <p>Amount</p>
                <p>${{amount}}</p>
            </div>
            <div class="flex mt-1">
                <p>ID</p>
                <p>{{id}}</p>
            </div>
            <div class="flex mt-1">
                <p>Time</p>
                <p>{{String(new Date(date).getDate()) + '.' + (new Date(date).getMonth() + 1) + '.' + String(new Date(date).getFullYear()) + " " + time}}</p>
            </div>
            <div class="flex mt-1" v-if="desc">
                <p>Desc</p>
                <p>{{desc}}</p>
            </div>
            <div class="flex mt-1" v-if="stars && stars != '0'">
                <p>Stars</p>
                <div class="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 42 42" v-for="star in stars" @click="setStar(star)">
                        <path fill="rgba(242, 142, 34, 1)" d="M22.033 32.5043C21.4251 32.1631 20.6833 32.1631 20.0754 32.5043L12.5276 36.7403C11.0388 37.5758 9.25508 36.2821 9.58691 34.6074L11.2754 26.0859C11.4104 25.4048 11.1825 24.7025 10.6733 24.2305L4.30641 18.3278C3.05596 17.1685 3.73676 15.0764 5.42999 14.8751L14.0316 13.8523C14.7227 13.7701 15.3214 13.3345 15.6123 12.7022L19.2373 4.82385C19.9514 3.27182 22.157 3.27182 22.8711 4.82385L26.496 12.7022C26.7869 13.3345 27.3856 13.7701 28.0768 13.8523L36.6784 14.8751C38.3716 15.0764 39.0524 17.1685 37.8019 18.3278L31.435 24.2305C30.9259 24.7025 30.698 25.4048 30.8329 26.0859L32.5214 34.6074C32.8533 36.2821 31.0695 37.5758 29.5808 36.7403L22.033 32.5043Z"/>
                    </svg>
                </div>
            </div>
            <!-- <button class="border-grey mt-2 btnj-short" style="height: 48px; border: 1px solid #343434">Change pass</button>        -->
        </div>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'

    export default {
        name: 'one-transaction',
        mixins: [configMixin],
        data() {
            return {
                history: []
            }
        },
        props: {
            id: String,
            desc: '',
            amount: String,
            date: Object,
            stars: String,
            time: String
        },
        methods: {
            getData() {
                if(this.isActive) this.$emit('getdata')
            },
            close() {
                this.$emit('close')
            }
        },
        mounted() {
            console.log("one-transaction mounted")
        }
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
    
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
.close {
    position: absolute;
    margin-left: 55%;
    margin-top: -60px;
}
.inner-modal-div-form {
    background: #1E1E1E;
}
.inner-modal-div-form {
    border-radius: 10px;
}
h2 {
    text-align: left
}
.flex p {
    color: white
}
.flex {
    justify-content: space-between
}
@media screen and (max-width: 500px) {
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #1E1E1E;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
        /*
        padding-top: 90px;
        */
        top: 0;
        min-height: 100vh;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }

    .close {
    position: absolute;
    margin-left: 40%;
    margin-top: -20px;
}
.inner-modal-div-form {
    padding-top: 30px
}
}
</style>