<template>
    <div class="staff-el flex">
        <div class="staff-el-container-left">
            <img v-if="img" :src="'/img/uploads/logo/esta/' + img" class="logo-staff">
            <div class="data-user-staff" @click="$router.push('/establishment/member/' + id)">
                <p class="name">{{ name }}</p>
                <p class="job" >{{ job }}</p>        
            </div>
        </div>
        <div class="staff-el-container-right">
            <div>
                <p class="name">@{{ username }}</p>
            </div>
            <div class="right-block">
                <img v-if="(setting == undefined || setting == false) && unactive != true" src="../assets/img/UI/setting-staff.svg" @click="getId" class="setting-img pointer">
                <p class="job setting-img pointer" style="color: var(--other-text-color)" v-if="unactive == true">Unactive</p>
                <img :class="{'close-img': true, 'pointer': true, 'ml-a': (setting == 'true')}" src="../assets/img/UI/close-staff.svg" @click="deleteUser">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'staff-user',
        props: {
            img: String,
            name: String,
            job: String,
            username: String,
            id: String,
            setting: Boolean,
            link: String,
            unactive: Boolean
        },
        methods: {
            getId() {
                this.$emit('getid', this.id)
            },
            deleteUser() {
                this.$emit('delete', this.id)
            }
        },
    }
</script>

<style scoped>
    .logo-staff {
        object-fit: cover;
        width: 42px;
        height: 42px;
        border-radius: 100%;
    }
    .staff-el {
        margin-top: 15px;
        border-radius: 10px;
        background-color: #1F1F1F;
        width: 940px;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
    }
    .name {
    margin-top: auto;
    margin-bottom: auto;
     color: var(--main-text-color); 
     font-family: 'Rubik-Regular';  
    }
    .job {
    margin-top: auto;
    margin-bottom: auto;
     color: #ABABAB; 
     font-family: 'Rubik-Light'; 
     font-size: 14px; 
    }
    .data-user-staff {
        padding-top: 3px;
        margin-left: 12px;
    }
    .setting-img {
        /*
        margin-left: auto;
        */
    }
    .close-img {
        /*
        margin-left: 24px;
        */
    }
    .ml-a {
        margin-left: auto;
        margin-right: 15px;
    }
    .staff-el-container-left {
        display: flex;
    }
    .staff-el-container-right {
        display: flex;
        gap: 10px;
    }
    .staff-el-container-right .right-block {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    @media screen and (max-width: 500px) {
        .staff-el {
            width: 290px;
            background-color: #2C2C2C;
        }
        .staff-el-container-right {
            flex-direction: column;
        }
        .staff-el-container-right .right-block {
            align-self: flex-end;
        }
    }
</style>