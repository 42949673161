<template>
    <div class="els flex">
        <div class="flex">
            <img src="../assets/img/UI/person.svg">
            <p class="ids">{{ id }}</p>
            <p class="dates ate-mobile">{{ date }}</p>
            <p class="dates">{{ time }}</p>
        </div>
        <div class="flex qwb mt-2-mobile">            
            <p class="counts" v-if="type == 'fee' && this.$route.user.role == 'admin'">${{ count }} (fee)</p>
            <p class="counts" v-else-if="type == 'fee' && this.$route.user.role != 'admin'">${{ count }} (fee)</p>
            <p class="counts" v-else-if="type == 'payout' || type == 'transfer'">-${{ String(count).split("-").join("") }}</p>
            <p class="counts" v-else>${{ count }}</p>
            <p class="counts" v-if="type == 'payout' && additionalInfo != null">(Withdrawal to **{{ additionalInfo }})</p>
            <p class="counts" v-if="type == 'transfer' && additionalInfo != null">(Transfer to @{{ additionalInfo }})</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'user-transaction',
        props: {
            id: String,
            date: String,
            time: String,
            count: String,
            type: String,
            additionalInfo: String
        }
    }
</script>

<style scoped>
    .els {
        width: 880px;
        padding: 20px;
        border-radius: 10px;
        background-color: #252525;
    }
    .ids {
        margin-left: 10px;
        color: #ABABAB;
        font-family: 'Rubik-Regular';
    }
    .dates {
        margin-left: 20px;
        color: #ABABAB;
    }
    .counts {
        color: var(--main-text-color);
    }
    .qwb {
        width: 100%;
        gap: 10px;
        justify-content: flex-end;
    }
    @media screen and (max-width: 500px) {
        .els {
            flex-direction: column;
            width: 320px;
        }

        .ate-mobile {
            margin-left: auto;
        }
        .qwb .dates {
            order: 2;
        }
        .qwb .counts {
            order: 1;
            margin-left: 0;
        }
    }
</style>