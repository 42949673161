<template>
<div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form inner-modal-div-form-dop">
                <div class="wrapper-inner-modal">
                <div class="HeadStroke_father">
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke grey"></div>
                </div>
                    <h3 class="mt-4">YOUR PERSONAL<br>QR-CODE & LINK</h3>
                <div class="FatherQRcode mt-4">
                    <div><img src="../../assets/img/UI/ReloadQRcode.png" @click="updateQr"></div>
                    <img :src="qr_code" class="qr-img" style="width: 132px">
                    <div><img src="../../assets/img/UI/DownloadQRcode.png" @click="downloadImage"></div>
                </div>
                <h3 class="mt-2">{{ name }}</h3>
                <p class="Logo-text mt-1">{{ job }}</p>
                <div>
                    <my-input label="Your personal link" @keydown="(e) => e.preventDefault()" valid="true" v-model="text" class="mt-4"/>

                    <p class="Logo-text mt-4" style="font-size: 11px">
                        *This is you personal code, feel free to save it. 
                        You will be able to access and share it through 
                        your personal page at any time
                    </p>

                    <my-button label="Next" :active="isActive" @click="sendData" class="mt-4"/>
                </div>
                   
                    
                </div>    
                    
            </div>
        </div>
    </div>
    
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import qrRegistrData from '@/mixins/qrRegistrData'
import refreshQr from '@/mixins/refreshQr'
let QRCode = require('qrcode')

export default {
    name: 'MakeEmplQR',
    mixins: [qrRegistrData, refreshQr],
    data() {
        return {
            text: 'https://merciq.app/@login/',
            inviteCode: '',
            isActive: true,
            qr_code: '',
            name: '',
            job: '',
        }
    },
    methods: {
        setInviteCode(value) {
            this.inviteCode = value
            if(value != "") this.isActive = true
            else this.isActive = false
        },
        sendData() {
            if(this.job != "Manager") {
//                this.$router.push("/employee/workspace")

                const url = new URL('/employee/workspace', window.location.origin)
                window.location.href = url.toString();
            } else {
//                this.$router.push("/dashboard")

                const url = new URL('/dashboard', window.location.origin)
                window.location.href = url.toString();
            }
        },
        updateQr() {
            this.refreshQr().then(data => {
                QRCode.toDataURL('/client/employee/' + data.qr, (err, url) => {
                    this.qr_code = url
                })
            })
        },
        async downloadImage() {
            const blob = await (await fetch(this.qr_code)).blob();
            const url = URL.createObjectURL(blob);
            Object.assign(document.createElement('a'), { href: url, download: 'qr.png' })
                .click();
            URL.revokeObjectURL(url);
        }
    },
    mounted() {
       
    }
}
</script>