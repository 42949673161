<template>
    <phone-number 
    :title="title" 
    v-if="isActiveRecovery" 
    @getdata="isIsset" 
    @close="setActiveRecoveryDel"
    :email="userInfo.email"/>

    <change-password 
    @getdata="setPassword" 
    @close="setPassword" 
    v-if="isActivePassword"/>

    <change-phone 
    @getdata="setPhone" 
    @close="setPhone" 
    v-if="isActivePhone"/>

    <change-email 
    @getdata="setEmail" 
    @close="setEmail" 
    v-if="isActiveEmail"/>
    <div class="container-dash absolute-100-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'calendar-active-cont': (isActiveRecovery || isActivePhone || isActiveEmail || isActivePassword), 'dop-wrpapper-setting': true}">
            <div class="flex">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/settings')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0;">Password and security</h2>     
            </div>
            <div class="main-staff setting-div bg-security">
                <p class="el-text-input-sec">Your password</p>
                <div class="name_form_setting sec-is-inp mt-2 w-284-mobile">
                    <my-input type="password" @keydown="(e) => e.preventDefault()" label="Password" v-model="userInfo.password" bgColor="#252525"/>
                    <p class="desc-inp-setting-sec" @click="setActiveRecovery('PASSWORD')">Change password</p>
                </div>
                <p class="el-text-input-sec mt-3">Your phone number</p>
                <div class="name_form_setting sec-is-inp mt-2 w-284-mobile">
                    <my-input label="Phone number" @keydown="(e) => e.preventDefault()" v-model="userInfo.number" bgColor="#252525"/>
                    <p class="desc-inp-setting-sec" @click="setActiveRecovery('PHONE NUMBER')">Change phone number</p>
                </div>
                <p class="el-text-input-sec mt-3">Your Email</p>
                <div class="name_form_setting sec-is-inp mt-2 w-284-mobile">
                    <my-input label="Email" @keydown="(e) => e.preventDefault()" v-model="userInfo.email" bgColor="#252525"/>
                    <p class="desc-inp-setting-sec"  @click="setActiveRecovery('EMAIL')">Change Email</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import configMixin from '@/mixins/configMixin'
    import loaderMixin from '@/mixins/loaderMixin';
    export default {
        name: 'password and security',
        mixins: [configMixin, loaderMixin],
        data() {
            return {
                userInfo: {
                    password: "********",
                    number: '',
                    email: ''
                },
                isActiveRecovery: false,
                title: '',
                isActivePhone: false,
                isActiveEmail: false,
                isActivePassword: false
            }
        },
        methods: {
            setActiveRecovery(value) {
                if(value != null) this.title = value
                this.isActiveRecovery = !this.isActiveRecovery

                if(this.isActiveRecovery == true) {
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/code/user', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                            }
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }

                    return response()
                }
            },
           
            setActiveRecoveryDel() {
                this.isActiveRecovery = !this.isActiveRecovery
            },
            isIsset() {
                this.isActiveRecovery = !this.isActiveRecovery
                if(this.title == 'PASSWORD') this.setPassword()
                else if (this.title == "PHONE NUMBER") this.setPhone()
                else if (this.title == "EMAIL") this.setEmail()
            },
            setPhone() {
                this.isActivePhone = !this.isActivePhone
                if(this.isActivePhone == false) this.$router.go()
            },
            setPassword() {
                this.isActivePassword = !this.isActivePassword
                if(this.isActivePassword == false) this.$router.go()
            },
            setEmail() {
                this.isActiveEmail = !this.isActiveEmail
                if(this.isActiveEmail == false) this.$router.go()
            },
            getData() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/get', {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            }
        },
        beforeMount() {
            this.getData().then(data => {
                this.userInfo.email = data.admin.email
                this.userInfo.number = data.admin.phone

                this.setLoader(false);
            })
          
        }
    }
</script>

