<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getDateCalendar" v-if="isActiveCalendar"/>
    <delete-estas v-if="isActiveDelete" @close="closeEsta" @delete="deleteEsta"/>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': (isActiveCalendar || isActiveDelete), 'staff-wrapper': true}">
            <div class="flex">
                <img src="../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/dashboard')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">{{estaData.name}}</h2>     
            </div>
            <div class="main-staff manager-esta-wrapper main-team-st"> 
                <div class="main-nav-mobile d-none-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex">
                        <h2 :class="{ active_nav_panel: false}" @click="$router.push('/establishment/transaction/' + $route.params?.establishment_id)" >Transactions</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': false}" @click="$router.push('/establishment/team/' + $route.params?.establishment_id)">Team</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/establishment/managers/' + $route.params?.establishment_id)">Managers</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: true}" @click="$router.push('/establishment/information/' + $route.params?.establishment_id)">Information</h2>
                    </div>
                    <div class="main-line-menu">
                        <div class="menu-mobile-nav-line flex">
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-1"></div>
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-2"></div>
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-3"></div>
                            <div  :class="{'fr-main-inp-active': true}" class="mobile-nav-est-4"></div>
                        </div>
                    </div>
                </div>

                <div class="top-bar-staff d-none-desc">
                <div class="flex">
                    <div class="first-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/transaction/' + $route.params?.establishment_id)">Transactions</p>
                    </div>
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/team/' + $route.params?.establishment_id)">Team</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/managers/' + $route.params?.establishment_id)">Managers</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true}" @click="$router.push('/establishment/information/' + $route.params?.establishment_id)">Information</p>
                    </div> 
                    <div class="delete-esta-btn" @click="setActiveDel" v-if="this.$route.user.job != 'Manager'">Delete establishment</div>
                </div>
                <div class="border-staff flex">
                    <div :class="{'active-first': true, 'active-nav-staff': false, 'trans-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'team-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'manage-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': true, 'info-line': true}"></div>
                </div>
               </div>
               <div class="div-null-height-2"></div>
               <div class="flex mt-3 flex-between flex-content-mob"> 
                <div class="delete-btn-esta-mobile" @click="setActiveDel" v-if="this.$route.user.job != 'Manager'">Delete establishment</div>
                <my-input 
                    label="Establishment name" 
                    bgColor="#252525" 
                    v-model="dataForm.name" 
                    class="inp-est-i mt-2-mobile" 
                    @keydown="codeKeyDown"
                />
                
                <my-input 
                    label="Establishment address" 
                    bgColor="#252525" 
                    v-model="dataForm.address" 
                    class="inp-est-i mt-2-mobile" 
                    @keydown="codeKeyDown"
                />
                </div>
                <div class="esta-btn-set m-x-auto mt-3 w-320-mob" v-if="this.$route.user.job != 'Manager'"><my-button label="Change" :active="isActiveBtn" @click="update"/></div>
            </div>
        </div>

    </div>
</template>

<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import establishmentMixin from '@/mixins/establishmentMixin';
    import updateInfoEstablishmentMixin from '@/mixins/updateInfoEstablishmentMixin';

    export default {
        name: 'information establishment',
        mixins: [dashboardMixin, establishmentMixin, updateInfoEstablishmentMixin],
        data() {
            return {
               dataForm: {
                name: '',
                address: ''
               },
               isActiveBtn: false,
               estaData: {
                name: '',
               },
               isRedact: 0
            }
        },
        watch: {
            'dataForm.name': function() {
                this.isRedact++
                this.isIsset()
            },
            'dataForm.address': function() {
                this.isRedact++
                this.isIsset()
            },
            '$route.params.establishment_id': function() {
                this.getInfo()
            }
        },
        methods: {
           isIsset() {
            if(this.dataForm.name != '' && this.dataForm.address != '' && this.isRedact > 2) this.isActiveBtn = true
            else this.isActiveBtn = false
           },
           update() {
            if(this.isActiveBtn) {
                this.updateInfo(this.dataForm.name, this.dataForm.address).then(data => {
                    this.$router.go()
                })
            }
           },
           getInfo() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/team/info/' + this.$route.params?.establishment_id, {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    if(query.status != 200)  this.$router.push('/dashboard')

                    let result = await query.json()

                    if(result?.id == undefined 
                    || result?.id == null) this.$router.push('/dashboard')

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    try {
                        this.estaData.name = data.name
                        this.dataForm.name = data.name
                        this.dataForm.address = data.adress
                    } catch(err) {
                        this.$router.push('/dashboard')
                    }
                }).catch(err => {
                    this.$router.push('/dashboard')
                })
            },
            codeKeyDown(event) {
                if(this.$route.user.job == 'Manager') e.preventDefault();

                if (event.keyCode === 13) {
                    if (this.isActiveBtn) {
                        this.update();
                    }
                }
            }
        },
        beforeMount() {
            if(this.$route.params?.establishment_id == null || 
        this.$route.params?.establishment_id == 'undefined' ||
        this.$route.params?.establishment_id == undefined ||
        isNaN(Number(this.$route.params?.establishment_id))) return this.$router.push('/dashboard')
            this.getInfo()
        }
    }
</script>