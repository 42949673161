<template>
    <div class="container-dash" style="height: 100%">
        <employee-dashboard @getavatar="getAvatar" :demo="true"/>
        <div class="wrapper-dash main-mirciq workspace-wrapper-das" style="height: 100%; position: relative;">
            <div class="workspace-wrapper">
                <div class="d-none-mobile height-work-mobile">
                    
                    <div class="profile-panel-height pointer" @click="$router.push('/demo/profile')">
                        <img :src="'/img/uploads/logo/esta/' + avatar" v-if="avatar != ''" class="logo-p">
                        <div class="data-panel-p">
                            <p>{{ dataUser.name }}</p>
                            <div class="star-p-h">
                                <img src="../../../assets/img/UI/start.svg">
                                <span>{{dataUser.stars}}</span>
                            </div>
                        </div>
                        <img src="../../../assets/img/UI/setting-grey.svg" class="setting-panel-height">
                    </div>
                </div>
                <h2 class="main-title-dash d-none-desc">Workspace</h2>
                <div class="main-b-balance" style="background-color: var(--main-bg-color);" >
                    <p>Balance</p>
                    <h1>${{Number(dataUser.balance).toFixed(2)}}</h1>
                    <div class="b-button" style="width:auto">
                    <button class="btn-boder-grey" @click="$router.push('/demo/finance')">Finance history</button>
                    </div>
                </div>
                <div class="flex mt-1">
                    <p class="date-empl-w">{{ toDate }}</p>
                    <p class="total-price-empl">Total: $175</p>
                </div>
                <div class="histoery-work" v-for="el in transaction">
                    <history-transaction-empl 
                    :id="zeroPad(el.id)" 
                    :time="el.time" 
                    :desc="(el.comment == null) ? '' : el.comment"
                    :stars="(el.stars == null) ? 0 : Number(el.stars)" 
                    :price="el.sum"/>
                </div>
                <div class="center mt-3"><button class="border-active-btn" @click="$router.push('/demo/tips')">All tips</button></div>
            </div>
            <div class="workspace-wrapper mt-2" > 
                <div class="flex flex-between pointer" @click="$router.push('/demo/statistic')"><h2 class="main-title-dash">Analytics</h2><img src="../../../assets/img/UI/border-strel.svg" style="margin-top: auto; margin-bottom: auto;"></div>
                <div class="flex mt-2 column-mobile">
                    <div class="panel-balance-el pointer w-330 w-320-mob" @click="$router.push('/demo/finance')">
                            <p>Total earnings</p>
                            <p class="balance-el-t">${{dataUser.total}}</p>
                            <p class="balance-el-t-c">{{dataUser.count}} transactions</p>
                    </div>
                    <div class="flex mobile-panel-per">
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/demo/average')">
                            <div class="pie " :style="{'--p': 100}">{{ dataUser.stars }}</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{ dataUser.stars }}</span></div>
                            <p class="mt-2">Average rating</p>
                        </div>
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/demo/reviews')">
                            <div class="pie " style="--p:100">{{line_average}}%</div>
                            <div class="pie pie-two" :style="{'--p':line_average}"><span>{{line_average}}%</span></div>
                            <p class="mt-2">Happy clients</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-between mt-2"><h2 class="main-title-dash pointer" @click="$router.push('/demo/achievements')">Achievements</h2> <img src="../../../assets/img/UI/border-strel.svg" class="mt-2 pointer" @click="$router.push('/demo/achievements')" style="margin-top: auto; margin-bottom: auto;"></div>
                <article class="flex flex-between mt-2" style="flex-wrap: wrap;">
                    <div class="div-achiev mt-2"  v-for="el in achieved">
                            <div class="flex flex-start">
                                <div class="div-achi-child">
                                    <img v-if="el.img != null && el.img != undefined" :src="'/img/achievements/' + el.img">
                                    <h3>{{el.title}}</h3>
                                    <p>{{el.text}}</p>
                                </div>
                                <div class="div-achi-ball">+50</div>
                            </div>
                        </div>
                </article>
                <div class="ert-h-nil"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import configMixin from '@/mixins/configMixin';
    import ahievmentsMixin from '@/mixins/ahievmentsMixin'

    export default {
        name: 'workspace main',
        mixins: [configMixin, ahievmentsMixin],
        data() {
            return {
                dataUser: {
                    name: 'DEMO',
                    avatar: '',
                    balance: '',
                    total: '',
                    average_order: '',
                    avarage_rating: '',
                    happy_clients: '',
                    stars: 0,
                    sum: 0
                },
                toDate: '',
                line_average: 0,
                transaction: [],
                achieved: [],
                avatar: ''
            }
        },
        methods: {
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            getToDay() {
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                const d = new Date();
                return d.getDate() + " " + monthNames[d.getMonth()]
            },
            getData() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/employee/main', {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            },
            getAvatar(img) {
                this.avatar = img
                console.log(img)
            }
        },
        beforeMount() {
            this.dataUser.balance = 190
            this.dataUser.total = 190
            this.dataUser.average_order = 90
            this.dataUser.count = 7
            this.dataUser.stars = 90
            this.dataUser.sum = 0
            this.line_average = 90
            this.toDate = this.getToDay()
            let achieved = this.achievments.filter(el => {
                    let result = null
                    let ac = [8, 1, 11]
                    ac.map(item => {
                        if(item == el.id) result = el
                    })
                    if(result == null) {
                        return 
                    } else return result

                })
                this.achieved = achieved
           
        }
 
    }
</script>