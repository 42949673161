import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
        setLoader: 'loader/setLoader',
    }),
  },
  beforeMount() {
    this.setLoader(true);
  }
}