<template>
    <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
                    <h3>SIGN UP</h3>
                    <vue-tel-input wrapperClasses="rg" 
                    inputClasses="rtb" class="rg mt-2" 
                    ref="inp" 
                    style="border: 1px solid #252525; height: 47px;outline: none; box-shadow: none; background-color: #1E1E1E !important;"  
                    v-model="formData.phone" :style="{'height': '62px'}" 
                    validCharactersOnly="true" 
                    @validate="phoneObject" 
                    @country-changed="setCountryCode"
                    :enabledCountryCode="true"
                    :enabledFlags="false"
                    mode="international"> 
                        <template v-slot:arrow-icon>
                        </template>
                    </vue-tel-input> 
                    <p class="error-input-text err-au" v-if="isAvtivePhonErr">This phone already exists</p>
                    <my-input :type="typeNewPassword" showFunc="true" label="New password" class="mt-1" v-model="formData.newPassword"/>
                    
                    <div><img src="../assets/img/UI/show.png" class="btn-show" @click="showNewPassword"></div>
                    <my-input :type="typeRepeatPassword" showFunc="true" label="Repeat password" class="mt-1" v-model="formData.repeatPassword"/>
                    <div><img src="../assets/img/UI/show.png" class="btn-show"  @click="showRepeatPassword"></div>
                    
                    <my-button label="Registration" @click="sendData" :active="isActive" class="mt-4"/>
                    <div class="flex mt-3">
                        <div class="form_radio">
                            <input id="radio-1" type="radio" name="radio" checked value="1"  @click="checks">
                            <label for="radio-1"></label>
                        </div>
                        <p class="radio-text main-text-color">By registering I confirm I have read and agree 
to the <a href="/docs/Terms and Conditions.pdf" class="orange-color">MerciQ Terms of Use</a>, the Fees set out <a href="/docs/Fee Plan.pdf" class="orange-color">here</a>. We manage personal data as set out in our <a href="/docs/Privacy Policy.pdf" class="orange-color">Privacy Policy</a>. I also confirm that I have read and accept the <a class="orange-color" href="https://stripe.com/en-ch/legal/connect-account">Stripe Connected Account Agreement</a></p>
                    </div>
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
import { mapMutations } from 'vuex'
import sendCodeMixin from '@/mixins/sendCodeMixin'
import registrAdminMixin from '@/mixins/registrAdminMixin'
export default {
    name: 'sign up formss',
    mixins: [sendCodeMixin, registrAdminMixin],
    data() {
       return {
        isActive: false,
        typeNewPassword: 'password',
        typeRepeatPassword: 'password',
        formData: {
            phone: '',
            newPassword: '',
            repeatPassword: '',
            checks: true
        },
        isValid: false,
        countryCode: '',
        isAvtivePhonErr: false,
       }
    },
    watch: {
        'formData.phone': function() {
            if(this.formData.phone.length < this.countryCode.length) {
                this.formData.phone = this.countryCode
            }
          
            this.checkCEOPhone(this.formData.phone.split(" ").join("")).then(data => {
                if(data.ok === true) {
                    this.isAvtivePhonErr = false
                    this.isValid = true
                } else if(data.ok === false) {
                    this.isAvtivePhonErr = true
                    this.isValid = false
                } else {
                    this.isValid = false
                }
               
                this.isIsset()
            })
        },
        'formData.newPassword': function() {
            this.isIsset()
        },
        'formData.repeatPassword': function() {
            this.isIsset()
        },
        countryCode() {
            this.formData.phone = this.countryCode
        }
    },
    methods: {
        ...mapMutations({
            setData: 'code/setData'
        }),
        sendData() {
            this.setData(this.formData.email)
            if(this.isActive) {
                localStorage.setItem('userData.phone', this.formData.phone.split(" ").join(""));
                localStorage.setItem('userData.password', this.formData.newPassword);
                this.sendDataPhone(this.formData.phone.split(" ").join(''))
                this.$router.push('/sms')
            }
        },
        checks() {
            this.formData.checks = true
            this.isIsset()
        },
        showNewPassword() {
            if(this.typeNewPassword == 'password') this.typeNewPassword = 'text'
            else this.typeNewPassword = 'password'
        },
        showRepeatPassword() {
            if(this.typeRepeatPassword == 'password') this.typeRepeatPassword = 'text'
            else this.typeRepeatPassword = 'password'
        },
        isIsset() {
            
            if(this.formData.phone != "" && 
            this.formData.newPassword != "" && 
            this.formData.repeatPassword != "" && 
            this.formData.newPassword == this.formData.repeatPassword && 
            this.formData.checks &&
            this.isValid) this.isActive = true
            else this.isActive = false
        },
        setEmail(value) {
            this.formData.email = value
            this.isIsset()
        },
        setRepeatPassword(value) {
            this.formData.repeatPassword = value
            this.isIsset()
        },
        setNewPassword(value) {
            this.formData.newPassword = value
            this.isIsset()
        },
        setCountryCode(val) {
            this.countryCode = "+" + val.dialCode
        },
    }
  
}
</script>