import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        getEstaTeam(idEsta) {
            let page = (this.$route.query?.page == undefined || this.$route.query?.page == null) ? 
            1 : this.$route.query.page

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/business/establishment/teams/' + idEsta + '?page=' + page, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}