<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <div class="flex">
                <h3 class="head-title">CHANGE PAYMENT<br/>INFORMATION</h3>
                <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
            </div>
            <p class="number-desc mt-2">code was sent to the number</p>
            <p class="orange-color">+33 256 33 33 12</p>
            <div class="flex el-sms-div">
                <div class="el-sms-num"><my-input bgColor="#222222" type="text" inputmode="numeric" class="mt-4" ref="v1" v-model="dat.s1"/></div>
                <div class="el-sms-num ml-1"><my-input bgColor="#222222" type="text" inputmode="numeric" class="mt-4" ref="v2" v-model="dat.s2"/></div>
                <div class="el-sms-num ml-1"><my-input bgColor="#222222" type="text" inputmode="numeric" class="mt-4" ref="v3" v-model="dat.s3"/></div>
                <div class="el-sms-num ml-1"><my-input bgColor="#222222" type="text" inputmode="numeric" class="mt-4" ref="v4" v-model="dat.s4"/></div>
            </div>
            <div class="flex flex-center mt-3"><p class="orange-color pointer" v-if="timer.minute == 0 && timer.seconds == 0">Resend</p><p class="other-text-color" v-if="timer.minute != 0 || timer.seconds != 0">Resend after</p><p class="main-text-color ml-1" v-if="timer.minute != 0 || timer.seconds != 0">{{ timer.minute }}:{{ (String(timer.seconds).length < 2) ? "0" + timer.seconds : timer.seconds}}</p></div>
            <my-button label="Confirm" @click="getData" :active="isActiveBtn" class="mt-4"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'phone-number-get',
        mounted() {
            let timerId = setInterval(() => {
                this.timer.seconds--
                if(this.timer.seconds == -1 && this.timer.minute == 1) {
                    this.timer.minute = 0 
                    this.timer.seconds = 59
                }
                if(this.timer.seconds == 0 && this.timer.minute == 0) clearInterval(timerId)
            }, 1000)
        },
        data() {
            return {
                isActiveBtn: false,
                timer: {minute: 1, seconds: 30},
                dat: {
                    s1: "",
                    s2: "",
                    s3: "",
                    s4: "",
                }
            }
        },
        watch: {
            'dat.s1': function() {
                if(this.dat.s1 != "" &&
                this.dat.s2 != "" &&
                this.dat.s3 != "" &&
                this.dat.s4 != "") this.isActiveBtn = true
                else this.isActiveBtn = false
                this.$refs.v2.focus() 
            },
            'dat.s2': function() {
                if(this.dat.s1 != "" &&
                this.dat.s2 != "" &&
                this.dat.s3 != "" &&
                this.dat.s4 != "") this.isActiveBtn = true
                else this.isActiveBtn = false
                this.$refs.v3.focus()

            },
            'dat.s3': function() {
                if(this.dat.s1 != "" &&
                this.dat.s2 != "" &&
                this.dat.s3 != "" &&
                this.dat.s4 != "") this.isActiveBtn = true
                else this.isActiveBtn = false
               this.$refs.v4.focus()
            },
            'dat.s4': function() {
                if(this.dat.s1 != "" &&
                this.dat.s2 != "" &&
                this.dat.s3 != "" &&
                this.dat.s4 != "") this.isActiveBtn = true
                else this.isActiveBtn = false
                
            }
        },
        props: {
            title: String
        },
        methods: {
            close() {
                this.$emit('close')
            },
            getData() {
                this.$emit('getdata')
            }
        }
    
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 400px;
    background-color: #171717;
    border-radius: 10px;
}
.close {
    position: absolute;
    margin-left: 94%;
    margin-top: -40px;
}
.inner-modal-div-form {
    background: #171717;
}
@media screen and (max-width: 500px) {
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #171717;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-left: 101%;
        position: absolute;
        margin-top: 0;
    }
}
</style>