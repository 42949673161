import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        refreshQr() {
            let response = async() => {

                let query = await fetch(this.config.API_URL + '/user/qr/refresh', {
                    method: "PUT",
                    headers: {
                        "Authorization": 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result) 
            }
            return response()
        }
    }
}