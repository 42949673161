<template>
    <div class="container-dash absolute-100-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true}">
            <div class="flex">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/settings')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">Business account</h2>     
            </div>
            <div class="main-staff setting-div bg-setting">
                <div class="main-nav-mobile d-none-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex min-panel-nav-setting">
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/settings/public')">Public information</h2>
                        <h2 :class="{ active_nav_panel: false}" @click="$router.push('/settings/type')"  style="margin-left: 20px;">Type of your Business</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': true}" @click="$router.push('/settings/personal')">Personal information</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/settings/financial')">Financial model</h2>
                    </div>
                    <div class="main-line-menu min-panel-nav-setting">
                        <!-- <div class="menu-mobile-nav-line flex min-panel-nav-setting-child">
                            <div :class="{'fr-main-inp-active': false}" class="sec-setting-mobile-1"  style="margin-left: 90px;"></div>
                            <div :class="{'fr-main-inp-active': false}" class="sec-setting-mobile-2"></div>
                            <div :class="{'fr-main-inp-active': true}" class="sec-setting-mobile-2"></div>
                            <div  :class="{'fr-main-inp-active': false}" class="sec-setting-mobile-3"></div>
                        </div> -->
                    </div>
                </div>
               <div class="top-bar-staff  d-none-desc">
                <div class="flex">
                    <div class="second-staff-nav" style="margin-left: 0;">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/public')">Public information</p>
                    </div> 
                    <div class="first-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/type')" style="margin-left: 20px;">Type of your Business</p>
                    </div>
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true}" @click="$router.push('/settings/personal')">Personal information</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/financial')">Financial model</p>
                    </div> 
                </div>
                <div class="border-staff flex border-set">
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'set-thre-nav': true}" style="margin-left: -10px;"></div>
                    <div :class="{'active-first': true, 'active-nav-staff': false,'set-staff-nav': true}" style="margin-left: 100px;"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': true, 'set-sec-nav': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'set-fo-nav': true}"></div>
                </div>
               </div>
               <div class="div-height-nulls"></div>
               <div class="warning-info">
                    <p>Important Notice: Making changes in this section may temporarily suspend your ability to receive payments and withdraw funds from your account. 
                        Please proceed with caution.</p>
                </div>
                <div class="name_form_setting mt-4 w-320-mob">
                    <my-input 
                        label="Full name of the CEO" 
                        bgColor="#252525" 
                        v-model="formData.ceo_name"
                        @keydown="codeKeyDown"
                    />
                </div>
                <div class="name_form_setting mt-2 w-320-mob">
                    <my-input 
                        label="Occupation" 
                        bgColor="#252525" 
                        v-model="formData.ceo_job"
                        @keydown="codeKeyDown"
                    />
                </div>
                <div class="name_form_setting mt-2 w-320-mob">
                    <vue-tel-input wrapperClasses="rg" 
                        inputClasses="rtb" class="rg mt-2" 
                        ref="inp" 
                        style="border: 1px solid #252525; height: 47px;outline: none; box-shadow: none; background-color: #252525 !important;"  
                        v-model="formData.ceo_number" :style="{'height': '62px'}" 
                        validCharactersOnly="true" 
                        @validate="phoneObject" 
                        @country-changed="setCountryCode"
                        :enabledCountryCode="true"
                        :enabledFlags="false"
                        @keydown="codeKeyDown"
                        mode="international"> 
                        <template v-slot:arrow-icon>
                        </template>
                    </vue-tel-input>
                <p class="error-input-text err-au" v-if="registrAdminMixin">This phone already exists</p>
                </div>
                <div class="name_form_setting mt-2 w-320-mob">
                    <my-input 
                        label="Contact e-mail" 
                        bgColor="#252525" 
                        v-model="formData.ceo_email"
                        @keydown="codeKeyDown"
                    />
                    <p class="error-input-text err-au" v-if="isActiveEmailErr">This email already exists</p>
                </div>
                <div style="margin-top: 20px;">
                    <my-button label="Update" :active="isActiveBtn" @click="updateData" style="width: 320px;"/>
                </div> 
            </div>
        </div>

    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin';
import loaderMixin from '@/mixins/loaderMixin';
import registrAdminMixin from '@/mixins/registrAdminMixin'

    export default {
        name: 'personal info',
        mixins: [configMixin, registrAdminMixin, loaderMixin],
        data() {
            return {
                formData: {
                    ceo_name: '',
                    ceo_job: '',
                    ceo_number: '',
                    ceo_email: '',
                },
                isValidPhone: false,
                countryCode: '',
                isActiveBtn: false,
                isRedact: false,
                ceo_phone: '',
                ceo_email: '',
                index: 0,
                isAvtivePhonErr: false,
                isActiveEmailErr: false
            }
        },
        watch: {
            'formData.ceo_name': function() {
                this.isIsset()
            },
            'formData.ceo_job': function() {
                this.isIsset()
            },
            'formData.ceo_number': function() {
                if(this.formData.ceo_number.length < this.countryCode.length) {
                    this.formData.ceo_number = this.countryCode
                }
                this.checkPublicPhone(this.formData.ceo_number.split(" ").join("")).then(data => {
                    if(data.ok === true) {
                        this.isAvtivePhonErr = false
                    } else if(data.ok === false && this.formData.ceo_phone != this.ceo_number) {
                        this.isAvtivePhonErr = true
                    }
                    this.isIsset()
                })
            },
            'formData.ceo_email': function() {
                this.checkCEOEmail(this.formData.ceo_email).then(data => {
                    if(data.ok === true) {
                        this.isActiveEmailErr = false
                    } else if(data.ok === false && this.formData.ceo_email != this.ceo_email) {
                        this.isActiveEmailErr = true
                    }
                    this.isIsset()
                })
            },
           
        },
        methods: {
            setCountryCode(val) {
                this.countryCode = "+" + val.dialCode
            },
            phoneObject(object) {
                this.isValid = object.valid;
            },
            getBusinessData() {
                let response = async() => {

                    let query = await fetch(this.config.API_URL + '/business/data', {
                        method: "GET",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            },
            isIsset() {
                this.index++
                if(this.index > 5 && !this.isActiveEmailErr && !this.isAvtivePhonErr) this.isActiveBtn = true
            },
            updateData() {
                if(this.isActiveBtn) {
                    let body = {
                        ceo_email: this.formData.ceo_email,
                        ceo_occupation: this.formData.ceo_job,
                        ceo_phone: this.formData.ceo_number.split(" ").join(''),
                        ceo_name: this.formData.ceo_name,
                    }

                    let response = async() => {

                        let query = await fetch(this.config.API_URL + '/business', {
                            method: "PUT",
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }
                    response().then(data => {
                        this.$router.go()
                    })
                }
            },
            codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.isActiveBtn) {
                        this.updateData();
                    }
                }
            }
        },
        beforeMount() {
            this.getBusinessData().then(data => {
                this.formData.ceo_name = data.data.ceo_name

                this.ceo_email = data.data.ceo_email
                this.ceo_phone = data.data.ceo_phone

                this.formData.ceo_email = data.data.ceo_email
                this.formData.ceo_job = data.data.ceo_occupation
                this.formData.ceo_number = (data.data.ceo_phone == null) ? '' : data.data.ceo_phone

                this.setLoader(false);
            })
        }

    }
</script>