<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getCalendar" v-if="isActiveCalendar"/>
   <one-transaction v-if="isOneTransaction"
   @close="setOneTransaction"
   :id="transactionOne.id"
   :time="transactionOne.time"
   :amount="transactionOne.amount"
   :desc="transactionOne.desc"
   :date="transactionOne.date"
   :stars="transactionOne.stars"
   style="position: fixed"/>

    <div class="container-dash average-bg-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'wrapper-average': true, 'calendar-active-cont': isActiveCalendar || isOneTransaction}">
            <div class="flex top-avarage-mobile">
                <h2 class="main-title-dash pointer" @click="$router.push('/dashboard')"><img src="../../assets/img/UI/left-strel.svg"> Transaction history</h2>
                <div class="min-panel-end flex panel-average" style="flex-wrap: wrap">
                    <div class="panel-calendar-min ml-3" @click="setActiveCalendar" v-if="choiceDate == ''">
                        <img src="../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    <div class="panel-calendar-min ml-3 mt-2-mobile mon-0" v-if="choiceDate != ''">
                        <div>
                            <span class="choiceDate" style="white-space: nowrap; margin-left: 0;">{{ choiceDate }}</span>
                            <img src="../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delCalendar">
                        </div>
                    </div>
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0), FinanceMixin('Day')">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1), FinanceMixin('Week')">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2), FinanceMixin('Month')">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3), FinanceMixin('Year')">Year</div>
                    </div>
                </div>
            </div>
            <div class="main-b-graph mt-3">
                <p class="total-graph main-text-color">Total: ${{ Number(balance).toFixed(2) }}</p>
                <div class="line-graph">
                    <div class="flex line-graph-l">
                        <div v-for="el in line_arr" class="l-1" @click="e => {showMoney(e)}">
                            <div class="height-panel-ert">{{el.sum}}$</div>
                            <div class="l-1-div" :style="{'height': el.heightEl + 'px'}"></div>
                            <p class="l-p">{{el.day}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-transactions mt-3">
                <div class="width-trans-div" v-for="el in trans" 
                    @click="setTransaction(el.id, 
                    el.sum, 
                    el?.desc, 
                    el.createdAt,
                    el.time,
                    el.stars)">
                        <history-transaction 
                        :id="zeroPad(el.id)" 
                        :time="el.time " 
                        :date="el.createdAt.split('-')[1] + '-' + el.createdAt.split('-')[2].split('T')[0]"
                        :price="el.sum" 
                        :stars="(el.stars == null) ? 0 : el.stars" 
                        :employee="el['User.username']" 
                        :desc="(el.comment == null) ? '' : el.comment" class="mt-1"
                        :type="el.type"
                        :additionalInfo="el.additional_info"
                    />                   
                </div>
                <p v-if="trans.length == 0" class="none-info">At the moment, we don’t have enough data to display the information on this page. Try again later when you get enough incoming transactions</p>                
            </div>
            <paginate :count="Number(lastpage)" link="/dashboard/history"/>
        </div>

    </div>
</template>
    
<script>
     import dashboardMixin from '@/mixins/dashboardMixin';
    import getFinanceMixin from '@/mixins/getFinanceMixin';
    import dateIconMixin from '@/mixins/dateIconMixin';
    import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'transaction history',
        mixins: [dashboardMixin, getFinanceMixin, dateIconMixin, loaderMixin],
        data() {
            return {
                trans: '',
                balance: '',
                lastpage: 0,
                line_arr: [],
                showData: {
                    id: '1',
                    time: "234234234",
                    price: 'sdfs',
                    desc: 'sdfsdf',
                    stars: 1
                },
                isOneTransaction: false,
                transactionOne: {
                    id: 1,
                    date: '',
                    time: '',
                    amount: '',
                    stars: '0'
                }
            }
        },
        watch: {
            '$route.query.page': function() {
                this.FinanceMixin()
            }
        },
        methods: {
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            setOneTransaction() {
                this.isOneTransaction = !this.isOneTransaction
            },
            setTransaction(id, sum, desc, date, time, stars) {
   
                this.transactionOne.id = id
                this.transactionOne.amount = String(sum)
                this.transactionOne.desc = desc
                this.transactionOne.time = time
                this.transactionOne.date = date
                if(stars != undefined) this.transactionOne.stars = stars
                else this.transactionOne.stars = '0'
                this.setOneTransaction()
            },
            getCalendar(value) {
                let date = (value == null || value == undefined) ? null : JSON.stringify({date: value})
                this.FinanceMixin(value)
                this.getDateIcon(value)
            },
            delCalendar() {
                this.FinanceMixin(null)
                this.delDateIcon()
            },
            showMoney(e) {
                if(e.target.classList[0] != 'l-1') {
                    if(e.target.parentNode.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'none'
                    }
                } else {
                    if(e.target.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.querySelector('.height-panel-ert').style.display = 'none'
                    }
                }
            },



            getDayOfWeek(date, date_count) {
                if(date_count.view == 12) {
                    
                    let need_date = new Date()  
                    let current = new Date(need_date.getFullYear(), need_date.getMonth()-date_count.value, 1);
                    const month = current.toLocaleString('en-US', { month: 'short' });
                    return month[0];

                } else if(date_count.view == 4) {

                    const getDateForWeek = (countDayAgo) => {
                        const dateDaysAgo = new Date(Date.now() - countDayAgo * 24 * 60 * 60 * 1000)
                        
                        let date = (dateDaysAgo.getDate() < 10) ? "0" + dateDaysAgo.getDate() : dateDaysAgo.getDate()
                        let month = (dateDaysAgo.getMonth() + 1 < 10) ? "0" + (dateDaysAgo.getMonth() + 1) : dateDaysAgo.getMonth() + 1

                        return date + '.' + month
                    }

                    const date_arr = {
                        '0': getDateForWeek(7) + " - " + getDateForWeek(0),
                        '1': getDateForWeek(15) + " - " + getDateForWeek(8),
                        '2': getDateForWeek(23) + " - " + getDateForWeek(16),
                        '3': getDateForWeek(30) + " - " + getDateForWeek(24)
                    }

                    return date_arr[String(date_count.value)]
                    
                } else {
                    let current = date
                    let today = current.toLocaleDateString('en-US',{weekday: 'long'});

                    let today2 = new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(current);
                    
                    return today2[0]
                }

            },
            async FinanceMixin(date) {
                Array.prototype.max = function() {
                    return Math.max.apply(null, this);
                };
                
                let page_id = (this.$route.query.page == undefined) ? 1 : this.$route.query.page

                await this.getDataMixin(page_id, date).then(data => {
                    this.trans = data.rows
                    this.balance = data.balance
                    this.lastpage = Math.ceil(data.count / 10)
                    
                    let maxEl = Number(data.days.max())

                    this.line_arr = []


                    data.days.forEach((el, i) => {
                        let date = new Date()

                        let maxDay = data.days.length - 1

                        maxDay = maxDay - i
                    
                        date.setDate(new Date().getDate() - maxDay)
                        
                        let days_data = {
                            sum: (el == null) ? 0 : Number(el).toFixed(0),
                            heightEl: (el == null) ? 0 : Number(el) * 80 / maxEl,
                            day: this.getDayOfWeek(date, {
                                view: data.days.length,
                                value: maxDay
                            })
                        }
                        this.line_arr.push(days_data)
                    })
                })

                this.setLoader(false);

            }
        },
        beforeMount() {
            this.FinanceMixin('Year')
            console.log(this.$route.user.role)
        }
    }
</script>