<template>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'wrapper-manager': true}">
            <div class="flex">
                <img src="../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/staff')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">Manager registration</h2>     
                <!-- <h2 class="main-title-dash">{{estaData.name}}</h2>      -->
            </div>
        
            <div><p class="error-input-text err-au" v-if="establishments.length == 0  && old == true" style="text-align: left; 
                margin-bottom: 0px; 
                margin-top: 0;">Before creating a manager, you need to create an establishment!</p>
                <p class="green-text mt-1" v-if="$route.query?.ok == 'ok'">Invitation sent!</p></div>
            <div class="flex flex-between mt-4">
                <div class="reg-staff" style="width: 100%">
                    <vue-tel-input wrapperClasses="rg" 
                    inputClasses="rtb" class="rg" 
                    ref="inp" 
                    style="border: none; height: 47px;outline: none; box-shadow: none; background-color: #252525 !important;"  
                    v-model="dataForm.phone" :style="{'height': '62px'}" 
                    validCharactersOnly="true" 
                    @validate="phoneObject" 
                    @country-changed="setCountryCode"
                    :enabledCountryCode="true"
                    @input="foo"
                    :enabledFlags="false"
                    @keydown="codeKeyDown"
                    mode="international"> 
                        <template v-slot:arrow-icon>
                        </template>
                    </vue-tel-input>
                    <p class="error-input-text err-au" v-if="isAvtivePhonErr">This phone already exists</p> 
                </div>
            </div>
            <div class="flex flex-between mt-2 flex-content-mob mt-1-mobile">
                <my-input 
                    class="w-290-inp-s" 
                    label="Full name" 
                    v-model="dataForm.fullName"
                    @keydown="codeKeyDown"
                />
                <my-input 
                    class="w-290-inp-s mt-1-mobile" 
                    label="Email" 
                    v-model="dataForm.email"
                    @keydown="codeKeyDown"
                    />
            </div>
            <p class="error-input-text err-au" v-if="siActiveEmailErr">This email already exists</p>
            <div class="warning-message mt-3 warning-message-manager w-320-mob">
                <img src="../../assets/img/UI/warning.svg">
                <p class="ml-1">A message with an invitation code will be sent to the employee. They will need to use this code to complete registration.</p>
            </div>
            <p class="role-manager mt-4">Choose role</p>
            <div class="flex choose-role-manager flex-content-mob">
                <div class="choose-role-manager-el" >
                    <div class="flex">
                        <p class="choose-role-title">Full access</p>
                        <div class="form_radio choose-role-radio">
                            <input id="radio-1" type="radio" name="radio" value="1" checked  @click="checks, setAccess('Full access')">
                            <label for="radio-1"></label>
                        </div>
                    </div>
                    <p class="choose-role-desc">manage financial settings</p>
                    <p class="choose-role-desc">manage other managers</p>
                    <p class="choose-role-desc">all permissions of restricted access</p>
                </div>
                <div class="choose-role-manager-el ml-2 ml-0-mobile mt-1-mobile" @click="setAccess('Branch Access'), setAccess('Branch Access')">
                    <div class="flex">
                        <p class="choose-role-title">Branch Access</p>
                        <div class="form_radio choose-role-radio">
                            <input id="radio-2" type="radio" name="radio" value="2"  @click="checks">
                            <label for="radio-2"></label>
                        </div>
                    </div>
                    <p class="choose-role-desc">Manage employees (invite and delete)</p>
                    <p class="choose-role-desc">Viewing reports, statistics and analytics</p>
                </div>
            </div>
            <p class="role-manager mt-4">Choose which establishments<br> you will have access to</p>
            <div class="flex first-choose">
                <div class="flag-first">
                    <input type="checkbox" 
                    ref="all" 
                    id="check-0" 
                    value="All"
                     @click="checkAll()"
                     :checked="establishments.length == dataForm.select.length"> <label class="check-text" for="check-0">Select All</label>
                </div>
                <p class="uncheck-btn pointer" @click="uncheckAll">Uncheck all</p>
            </div>
            <div  v-for="el in establishments">
                <input 
                type="checkbox" 
                :ref="el.id"
                :id="el.id"
                :value="el.name"
                @click="e => {setChecked(el.id, e)}"
                />
                <label class="check-text mt-2" :for="el.id">{{ el.name }}</label>
            </div>
            <div class="btn-registr-empl m-x-auto mt-4"><my-button @click="sendData" label="Registration" :active="isActiveBtn"/></div>
        </div>
    </div>
</template>

<script>
import registrAdminMixin from '@/mixins/registrAdminMixin'
import sendEmployeeRegistrMixin from '@/mixins/sendEmployeeRegistrMixin'

    export default {
        name: 'manager registration',
        mixins: [registrAdminMixin, sendEmployeeRegistrMixin],
        data() {
            return {
                isActiveBtn: false,
                dataForm: {
                    role: 'Full access',
                    email: '',
                    fullName: '',
                    phone: '',
                    select: [],
                },
                isValid: false,
                countryCode: '',
                siActiveEmailErr: false,
                isAvtivePhonErr: false,
                index: 0,
                establishments: [],
                old: false
            }
        },
        watch: {
            index() {
                if(this.index == 1) {
                    this.isAvtivePhonErr = false
                    this.index++
                }
            },
            'dataForm.email': function() {
                this.checkEmail()
            },
           'dataForm.fullName': function() {
            
                this.isActive()
            },
            'dataForm.phone': function() {
                if(this.dataForm.phone.length < this.countryCode.length) {
                    this.dataForm.phone = this.countryCode
                }
                this.checkPhone()
            },
            'dataForm.role': function() {
                this.isActive()
            },
            countryCode() {
                this.dataForm.phone = this.countryCode
            }
        },
        methods: {
            sendData() {
                let name = this.dataForm.fullName.split(" ")
                
                this.registrManager(this.dataForm.role, this.dataForm.email, 
                name[0], name[1],
                this.dataForm.phone.split(" ").join(""), this.dataForm.select).then(data => {
                    window.location.href = '/staff/mangerregistr?ok=ok'
                })
            },
            setCountryCode(val) {
                this.countryCode = "+" + val.dialCode
            },
            phoneObject(object) {
                this.isValid = object.valid;
            },
            uncheckAll() {
                this.$el.querySelectorAll('input[type="checkbox"]').forEach(el => {
                    el.checked = false
                })
                this.dataForm.select = []
                this.isActive()
            },
            checkPhone() {
                this.checkCEOPhone(this.dataForm.phone).then(data => {
                    if(data?.ok === true) {
                        this.isAvtivePhonErr = false
                    } else if(data?.ok === false) {
                        this.isAvtivePhonErr = true
                    }
                    this.index++
                    this.isActive()
                })
            },
            checkEmail() {
                this.checkAdminEmail(this.dataForm.email).then(data => {
                    if(data?.ok === true) {
                        this.siActiveEmailErr = false
                    } else if(data?.ok === false) {
                        this.siActiveEmailErr = true
                    }
                    this.isActive()
                })
            },
            checkAll() {
                this.$el.querySelectorAll('input[type="checkbox"]').forEach(el => {
                    el.checked = true
                    if(el.checked == true && this.dataForm.select.find(item => item.id == el.id) == undefined) this.dataForm.select.push(el.id)
                })
                this.isActive()
            
            },
            setAccess(value) {
                this.dataForm.role = value
            },
            setChecked(id, e) {
                
                let index = this.dataForm.select.findIndex(item => Number(item) == Number(id))
               
                if(index == -1) {
                    this.dataForm.select.push(Number(id))
                } else {
                    e.target.check = false
                    this.dataForm.select.splice(index, 1)
                }
              
                
                this.isActive()
            },
            isActive() {
                const validateEmail = (email) => {
                return String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
                };
                
                if(this.dataForm.email != "" && 
                this.dataForm.fullName != "" &&
                this.dataForm.phone != "" && 
                this.dataForm.select.length != 0 && 
                this.dataForm.role != "" && validateEmail(this.dataForm.email) && !this.isAvtivePhonErr 
                && !this.siActiveEmailErr ) this.isActiveBtn = true
                else this.isActiveBtn = false
            },
            codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.isActiveBtn) {
                        this.sendData();
                    }
                }
            }
        },
        mounted() {

            this.getDataTeamAndEsta().then(data => {
                this.establishments = data.establishments
                // if(this.establishments.length > 0) {
                //     this.dataForm.select = this.establishments[0].id
                // }
                this.old = true
            })
        }
    }
</script>

<style scoped>
.reg-staff {
    width :320px
}
</style>