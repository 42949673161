<template>
  <div class="container-dash absolute-100-mobile">
      <dashboard-nav />
      <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'calendar-active-cont': (isActiveRecovery || isActivePhone || isActiveEmail || isActivePassword), 'dop-wrpapper-setting': true}">
          <div class="flex">
              <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/settings')">
              <h2 class="main-title-dash pointer setting-title" style="margin-top: 0;">Techical support</h2>     
          </div>
          <div class="wrapper faq-wrapper">
            <faq-element title="What does the dashboard show in the MerciQ Web App?" text="The dashboard serves as the central control panel, providing a comprehensive overview
                of your business’s performance metrics. It includes sections for all analytics, business
                account balance, total earnings, average tip value, and customer ratings." />
            <faq-element title="How do I access and understand the 'Business Account Balance'?" text="The 'Business Account Balance' displays your current available balance. Clicking on this
                section will direct you to a page showing detailed transaction history. You can filter these
                transactions by time period — Day, Week, Month, or Year— to review your financial activity.
                Below the balance, use the 'Payout' button to withdraw funds, noting that the minimum
                payout amount is $10." />
            <faq-element title="What information is provided in the 'Total Earnings' section?" text="The 'Total Earnings' section reflects all earnings accrued from tips across all employees
                invited by the business for a selected time period. It provides a broader financial overview
                than the 'Business Account Balance' and helps monitor the overall financial flow and total
                income from tips." />
            <faq-element title="How can I view and assess customer satisfaction?" text="Click on the 'Average Rating' button on the dashboard to assess customer satisfaction
                through the average rating of employees or teams. This section has tabs for 'Customer
                Ratings' and 'Volume of Income', allowing you to toggle views between customer satisfaction
                levels and total income generated by each team or employee." />
            <faq-element title="What does the 'Happy Clients' section display?" text="The 'Happy Clients' section opens a page displaying transactions along with customer
                service ratings in stars, and textual reviews if provided by the customer. It also includes a
                feature to request integration of a review block on the business’s website via API." />
            <faq-element title="How do I register a new employee or manager?" text="To invite a new employee or manager, navigate to the 'Staff Management' page via
                'Employee Registration' from the Quick Actions section. Click on &quot;Add new employee&quot; or
                &quot;Add new manager&quot; to send an invitation link to their email. Fill in their details, including
                email and role, and send the invitation." />
            <faq-element title="How do I manage established establishments and teams?" text="Access 'All establishments' from the sidebar menu to manage your business locations.
                Here, you can add or manage teams, change establishment details, or delete
                establishments. To add a new establishment, click on 'Add establishment', enter the required
                information, and confirm by clicking 'Save'." />
            <faq-element title="How do I withdraw funds from my account?" text="To withdraw funds, click on 'Payout' in the sidebar menu. Enter the amount you wish to
                withdraw, ensure it meets the minimum requirement of $10, and click 'Withdraw'. Funds will
                be processed and should appear on your linked debit card within a few hours to several
                days." />
            <faq-element title="How can I update the debit card linked for withdrawals?" text="Navigate to 'Settings' and then 'Payment Information' in the sidebar menu. Click 'Update
                linked card', enter the new debit card details, and submit. Only debit cards are accepted for
                these transactions. Confirm all details before finalizing the update." />
            <faq-element title="How can I update public information about my business?" text="To update public details about your business, navigate to the 'Settings' menu from the
                sidebar and select 'Edit My Business Account'. Here, you can update various information
                such as the business name, address, contact details, and description. Once you have made
                the necessary changes, be sure to save them by clicking the 'Save' button to ensure that all
                updates are applied and visible publicly. This feature helps you keep your business
                information accurate for customers and partners." />
            <p>If you have any other questions or need further assistance, please contact our technical support
              through the FAQ & Help Center in your Profile settings.</p>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
      name: 'faq',
  }
</script>

<style scoped>

.wrapper {
    width: 1180px;
    margin-left: auto;
    margin-right: auto;
}

.wrapper p {
    width: 100%;
    text-align: left;
    margin-top: 20px;
    color: #ababab;
    font-family: Rubik-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: .4px;
  }

@media screen and (max-width: 1180px) {
    .wrapper {
        width: 100%;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 1180px) {
    .FAQ-title, .faq-wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
}

</style>