import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        getManagers() {
            let response = async() => {
                let query = await fetch(this.config.API_URL + "/team/managers/" + this.$route.params?.establishment_id, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        },
        deleteManager(id) {
            let body = {
                establishment_id: this.$route.params?.establishment_id
            }

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/team/managers/delete/' + id, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                })

                let result = await query.json()

                return Promise.resolve(result)
            }
            return response()
        },
        updateManagerMixin(users) {
            let body = {
                users: users.map(el => {
                    return el.id
                })
            }

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/team/managers/' + this.$route.params?.establishment_id, {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
        
    }
}