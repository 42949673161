<template>
    <terms @ok="sends" v-if="isActiveTerms"/>
    <loading
    text="Account creation in progress, please wait..."
    v-if="DOM.isActiveLoading" 
    style="position: fixed; z-index: 10"
    />
    <div class="outer-modal-div-form" :class="{'calendar-active-cont': DOM.isActiveLoading}">
           <div class="middle-modal-div-form">
            <div class="inner-modal-div-form inner-modal-div-form-dop" >
                <div class="wrapper-inner-modal" :class='{"calendar-active-cont": isActiveTerms}'>  
               
                       <h3 class="" style="margin-top: 0;" @click="saveClicked">FILLING OUT<br> A PERSONAL PAGE</h3>
                       <div>
                           <VueImageUpload :value="urls"/>
                       </div>
                       <div>
                       <input type="file" id="logo-select" @change="previewFiles">
                       <avatar-editor label="Avatar" @get="getAvatar" style="position: relative; z-index: 20"/>
                       </div>
                       <img ref="t_img" src="" class="mt-2" style="border-radius: 100px;">
                   <div>
                    <div class="flex flex-column" style="margin-left: auto; align-items: left">
                            <div class="flex mt-2" style="overflow-y: auto; overflow-x: hidden;text-align: left; z-index: 5;height: 62px;">
                                <select-input-search width="320px" :inps="state" label="State" @getvalue="State" />
                            </div>
                            <!-- <div class="flex mt-2"><my-input label="State" style="width: 320px" v-model="formData.State"/></div> -->
                            <div class="flex mt-2" style="overflow-y: auto; overflow-x: hidden;text-align: left; z-index: 4;height: 62px;">
                                <select-input-search width="320px" :inps="city" label="City" @getvalue="City" />
                            </div>
                        </div>
                        <custom-input  
                            label="Address" 
                            v-model="formData.address" 
                            class="mt-2" 
                            style="width: 320px; margin-left: auto; margin-right: auto;"
                            @blur="addressValidate"
                            ref="addressInput"
                        />
                        <p class="error-input-text err-au" v-if="addressError?.length > 0">{{ addressError }}</p>
                        <custom-input 
                            label="Zip-code" 
                            maxlength="10" 
                            v-model="formData.zip" 
                            class="mt-2" 
                            style="width: 320px; margin-left: auto; margin-right: auto;"
                            @blur="zipCodeValidate"
                            ref="zipCodeInput"
                        />
                        <p class="error-input-text err-au" v-if="zipCodeError?.length > 0">{{ zipCodeError }}</p>
                        <custom-input 
                            label="Firstname" 
                            v-model="formData.firstname" 
                            class="mt-2" 
                            style="width: 320px; margin-left: auto; margin-right: auto;"
                            @blur="firstnameValidate"
                            ref="firstnameInput"
                        />
                        <p class="error-input-text err-au" v-if="firstnameError?.length > 0">{{ firstnameError }}</p>
                        <custom-input 
                            label="Lastname" 
                            v-model="formData.lastname" 
                            class="mt-2" 
                            style="width: 320px; margin-left: auto; margin-right: auto"
                            @blur="lastnameValidate"
                            ref="lastnameInput"
                        />
                        <p class="error-input-text err-au" v-if="lastnameError?.length > 0">{{ lastnameError }}</p>
                        <custom-input 
                            type="date" 
                            label="Date of Birth" 
                            v-model="formData.birthDay" 
                            class="mt-2" 
                            style="outline: none;width: 320px; margin-left: auto; margin-right: auto"
                            @blur="birthdayValidate"
                            ref="birthdayInput"
                        />
                        <p class="error-input-text err-au" v-if="isOld">Please enter a valid date</p>
                        <p class="error-input-text err-au" v-if="isNewYear">You must be over 18 years old</p>
                        <!-- <my-input  label="Role" v-model="formData.role" class="mt-2"/> -->
                        <vue-tel-input 
                            wrapperClasses="rg" 
                            inputClasses="rtb" class="rg mt-2" 
                            style="z-index: 10;;border: none; height: 47px;outline: none; box-shadow: none; background-color: #1E1E1E !important; width: 320px; margin-left: auto; margin-right: auto"  
                            v-model="formData.phone" :style="{'height': '62px'}" 
                            validCharactersOnly="true" 
                            @validate="phoneObject" 
                            @country-changed="setCountryCode"
                            :enabledCountryCode="true"
                            @input="foo"
                            @blur="phoneValidate"
                            :enabledFlags="false"
                            defaultCountry="US"
                            :styleClasses="phoneInputClasses"
                            :onlyCountries="['US']"
                            mode="international"> 
                                <template v-slot:arrow-icon>
                                </template>
                        </vue-tel-input>  
                    <p class="error-input-text err-au" v-if="isAvtivePhonErr && formData.phone?.length > 11">This phone already exists</p> 
                    <p class="error-input-text err-au" v-if="phoneError?.length > 0">{{ phoneError }}</p>
                    
                    <custom-input 
                        label="Email" 
                        v-model="formData.email"
                        class="mt-2" 
                        style="outline: none;width: 320px; z-index:1; position: relative; margin-left: auto; margin-right: auto"
                        ref="emailInput"
                        @blur="emailValidate"
                    />
                    <p class="error-input-text err-au" v-if="siActiveEmailErr">This email already exists</p>
                    <p class="error-input-text err-au" v-if="emailError?.length > 0">{{ emailError }}</p>

                    <div class="flex ssn-container mt-3">
                        <p class="ssn-fill-label">Fill up 4 last digits of SSN</p>
                        <img src="../../assets/img/UI/ssl_secured_icon.png" class="ssl-secured" style="width: 100px;">
                        <!--<ssn-input 
                            type="text" 
                            style="width: 300px; align-self: center;" 
                            label="Social Security Number" 
                            @input="e => {/*formatedSSN(e)*/}" 
                            v-model="formData.social_number" 
                            class="mt-2" 
                            maxlength="11"
                            @keydown="ssnKeyDown"
                            @select="ssnPreventSelect"
                            ref="ssnInputRef"
                            @blur="ssnValidate"
                        />-->
                        <ssn-input 
                            type="text" 
                            style="width: 300px; align-self: center;" 
                            label="Social Security Number" 
                            @input="e => {/*formatedSSN(e)*/}" 
                            v-model="formData.social_number" 
                            class="mt-2" 
                            maxlength="4"
                            @keydown="ssnKeyDown"
                            @select="ssnPreventSelect"
                            ref="ssnInputRef"
                            @blur="ssnValidate"
                        />
                        <div>
                            <img 
                                :src="ssnHidden 
                                    ? require('@/assets/img/UI/show.png') 
                                    : require('@/assets/img/UI/show-no.png')" 
                                class="btn-show"  
                                @click="showSsn"
                            >
                        </div>
                        <p class="error-input-text err-au" v-if="ssnError?.length > 0">{{ ssnError }}</p>
                        <div style="display:flex; align-items: center; margin-top: 5px;">
                            <img src="../../assets/img/UI/guaranteed_safe_and_secure.png" style="width: 60%;">
                            <img src="../../assets/img/UI/powered_by_stripe.jpg" style="width: 38%;cursor: pointer;" @click="goToStripe">
                        </div>
                        <p class="field-provided-info">
                            This field is provided by <a href="https://stripe.com/" class="orange-color">www.stripe.com</a>; MerciQ App doesn't access this info.<br>
                            Your data is protected with 256-bit SSL encryption. View our <a href="/docs/Privacy Policy.pdf" class="orange-color">Privacy Policy</a>.
                        </p>
                        <div class="warning-message mt-2 w-300" 
                            :style="{
                                'align-self': 'center', 
                                'margin-bottom': !isActiveSSN ? '10px' : '0',
                                'border-bottom-right-radius': !isActiveSSN ? '10px' : '0',
                                'border-bottom-left-radius': !isActiveSSN ? '10px' : '0'                                
                                }">
                            <img src="../../assets/img/UI/warning.svg">
                            <p class="ml-1" style="text-align: left; font-size: 14px">Why are we asking you to provide SSN?</p>
                            <img src="../../assets/img/UI/right-strel.svg" class="pointer" 
                            :style="{transform: !isActiveSSN ? 'rotate(90deg)' : 'rotate(-90deg)', 'margin-left': '2px'}" 
                            @click="setActiveSSN">
                        </div>
                        <div class="warning-message w-300" 
                            style="align-self: center; margin-bottom:10px;border-top-left-radius:0;border-top-right-radius:0;" v-if="isActiveSSN">
                            <img style="visibility: hidden;" src="../../assets/img/UI/warning.svg">
                            <p class="ml-1" style="text-align: left; font-size: 14px">By law, Stripe (our payment provider) must ask for your SSN to make sure you
    who you say you are. Your SSN is for identity verification ONLY; Stripe is federally required to officially know
    someone connected to the account.
    Your information is secure, and MerciQ doesn't access or store it. Stripe
    doesn't run credit checks, so inputting
    your SSN for this purpose won't affect
    your credit score. MerciQ has no access to your banking data, so we don't store it and your privacy is tightly controlled. Stripe keeps all the information you enter in Stripe safe with bank-level security measures.</p>
                        </div>
                    </div>


                    <h3 class="mt-4">PROVIDE INFORMATION FOR PAYOUTS</h3>
                            <p class="mt-2 mb-2 main-text-color">
                                To withdraw funds, provide a debit card for payout or link a Bank account
                            </p>

                    <div class="card-form-wrapper">

                        <div class="card-form" :style="{filter: skipCardForm ? 'blur(0.5rem)' : 'blur(0rem)'}">


                            <div class="flex payout-tabs">
                                <custom-tab label="LINK BANK CARD" @click="onBankCardTabClick" :active="bankCardTabActive" class="mt-2 choose-button" />
                                <custom-tab label="LINK BANK ACCOUNT" @click="onBankAccountTabClick" :active="bankAccountTabActive" class="mt-2 choose-button" />
                            </div>

                            <div class="card-form-inner" v-show="bankCardTabActive">
                            
                                <div class="user-input-wrp" style="width: 320px; margin-left: auto; margin-right: auto">
                                    <div class="field"
                                        @focus="onFocus"
                                        @blur="onBlur"
                                        @keyup="onKeyup"
                                    >
                                        <div 
                                            id="card-number" 
                                            class="m-stripe-input empty"                                 
                                        >
                                        </div>
                                        <span class="stripe-floating-label">Card number</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="user-input-wrp mt-2 left half-width">
                                        <div class="field"
                                            @focus="onFocus"
                                            @blur="onBlur"
                                            @keyup="onKeyup"
                                        >
                                            <div 
                                                id="card-expiry" 
                                                class="m-stripe-input empty" 
                                                
                                            >
                                            </div>
                                            <span class="stripe-floating-label">Exp date</span>
                                        </div>
                                    </div>

                                    <div class="user-input-wrp mt-2 right half-width">
                                        <div class="field"
                                            @focus="onFocus"
                                            @blur="onBlur"
                                            @keyup="onKeyup"
                                        >
                                            <div 
                                                id="card-cvc" 
                                                class="m-stripe-input empty" 
                                                
                                            >
                                            </div>
                                            <span class="stripe-floating-label">CVC</span>
                                        </div>
                                    </div>
                                </div>

                                <div style="display:flex; align-items: center; margin-top: 12px;">
                                    <img src="../../assets/img/UI/guaranteed_safe_and_secure.png" style="width: 60%;">
                                    <img src="../../assets/img/UI/powered_by_stripe.jpg" style="width: 38%;cursor: pointer;" @click="goToStripe">
                                </div>

                            </div>

                            <div class="card-form-inner" v-show="bankAccountTabActive">

                                <div style="">
                                    <custom-input 
                                        type="text" 
                                        label="Account holder name" 
                                        style="width: 320px;margin-left: auto; margin-right: auto;" 
                                        v-model="formData.external.nameAccount" 
                                        class="w-320-mob"
                                        @blur="accountHolderNameValidate"
                                        ref="accountHolderNameInput"
                                    />
                                    <p class="error-input-text err-au" v-if="accountHolderNameError?.length > 0">{{ accountHolderNameError }}</p>
                                </div>

                                <div style="margin-top: 20px;">
                                    <custom-input 
                                        type="text" 
                                        label="Routing number" 
                                        style="width: 320px;margin-left: auto; margin-right: auto;" 
                                        v-model="formData.external.routing_number" 
                                        class="w-320-mob"
                                        @blur="routingNumberValidate"
                                        ref="routingNumberInput"
                                    />
                                    <p class="error-input-text err-au" v-if="routingNumberError?.length > 0">{{ routingNumberError }}</p>
                                </div>

                                <div style="margin-top: 20px;">
                                    <custom-input 
                                        type="text" 
                                        label="Account number" 
                                        style="width: 320px;margin-left: auto; margin-right: auto;" 
                                        v-model="formData.external.accountNumber" 
                                        class="w-320-mob"
                                        @blur="accountNumberValidate"
                                        ref="accountNumberInput"
                                    />
                                    <p class="error-input-text err-au" v-if="accountNumberError?.length > 0">{{ accountNumberError }}</p>
                                </div>

                                <div style="display:flex; align-items: center; margin-top: 22px;">
                                    <img src="../../assets/img/UI/guaranteed_safe_and_secure.png" style="width: 60%;">
                                    <img src="../../assets/img/UI/powered_by_stripe.jpg" style="width: 38%;cursor: pointer;" @click="goToStripe">
                                </div>

                            </div>

                        </div>

                        <p class="mt-1 center" :style="{position: skipCardForm ? 'absolute' : 'relative', top: skipCardForm ? '100px' : 'auto'}">
                            <a 
                                v-if="!skipCardForm" 
                                class="orange-color" 
                                style="font-size: 15px;" 
                                href="" 
                                @click="skipAddingCard"
                            >
                                Or skip that & add card later by clicking here
                            </a>
                            <a 
                                v-else
                                class="orange-color" 
                                style="font-size: 15px;" 
                                href="" 
                                @click="skipAddingCard"
                            >
                                <span>You chose to fill in your card later thru the<br>"payment information" section in the settings</span>
                                <br>If you want to fill in now, click here
                            </a>
                        </p>                    

                    </div>    

                    <p class="mt-2 center">
                        <a class="orange-color" style="font-size: 15px;" href="/docs/reg guide.pdf">
                            NEED HELP WITH THE FORM?<br>CLICK HERE FOR THE GUIDE
                        </a>
                    </p>

                    <p class="error-input-text err-au" style="text-align: left" v-if="isUndefinedError">An error has occurred. Check your details or try again later.</p>
                    <my-button label="Next" :active="true" @click="setActiveTerm" class="mt-2"/>
                   </div>  
                   </div>   
               </div>
           </div>
       </div>
       
   </template>
   
<script>
import sendEmployeeMixin from '@/mixins/sendEmployeeMixin'
import registrAdminMixin from '@/mixins/registrAdminMixin'
import sendEmployeeRegistrMixin from '@/mixins/sendEmployeeRegistrMixin'
import updateEmployeeMixin from '@/mixins/updateEmployeeMixin'
import { PUBLISHABLE_KEY } from '@/assets/constants/stripe'

   export default {
       name: 'filling personal empl',
       mixins: [sendEmployeeMixin, registrAdminMixin, sendEmployeeRegistrMixin, updateEmployeeMixin],
       data() {
           return {
                isActiveTerms: false,
                isActiveBtn: false,
                stripe: PUBLISHABLE_KEY,
                formData: {
                    firstname: '',
                    lastname: '',
                    role: '',
                    email: '',
                    phone: '',
                    logo: '',
                    social_number: '',
                    state: '',
                    city: '',
                    zip: '',
                    ssn: '',
                    birthDay: this.getDate(),
                    address: '',
                    external: {
                        accountNumber: '',
                        typeAccount: '',
                        nameAccount: '',
                        routing_number: ''
                    },
                },
                isActiveSSN: false,
                firstPhone: '',
                firstEmail: '',
                isSelectImg: false,
                imgs: '',
                countryCode: '',
                urls: '',
                rotation: 0,
                scale: 1,
                isValid: false,
                siActiveEmailErr: false,
                isAvtivePhonErr: false,
                index: 0,
                isUndefinedError: false,
                isOld: false,
                isNewYear: false,
                state: [],
                city: [],
                cardEl: '',
                isErrorCard: true,
                DOM: {
                    isErrorCard: true,
                    isActiveLoading: false,
                    cErr: false
                },
                cardNumberCompleted: false,
                cardExpDateCompleted: false,
                cardCvcCompleted: false,
                ssnDigits: [],
                ssnHidden: true,
                phoneValid: false,
                phoneInputClasses: '',
                addressError: '',
                zipCodeError: '',
                firstnameError: '',
                lastnameError: '',
                phoneError: '',
                emailError: '',
                ssnError: '',
                routingNumberError: '',
                accountNumberError: '',
                accountHolderNameError: '',
                bankCardTabActive: true,
                bankAccountTabActive: false,
                skipCardForm: false
           }
       },
       watch: {
            index() {
                if(this.index == 1) {
                    this.isAvtivePhonErr = false
                    this.index++
                }
            },
            'formData.state': async function() {
            
            await fetch('https://www.universal-tutorial.com/api/cities/' + this.formData.state, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + this.token,
                }
            }).then(data => data.json()).then(data => {
                this.formData.city = data[0].city_name
                this.city = data.map(el => el.city_name)
            }).catch(err => {})
        },
            'formData.birthDay': function(){

            let nowDate = new Date(); // Current date now.

            let lastDate = new Date(this.formData.birthDay); // Start of 2010.
            let d = ((nowDate-lastDate) / 1000 / (60 * 60 * 24) / 365.25).toFixed(0)
            if(d > 120) this.isOld = true 
            else this.isOld = false 

            if(d < 18) this.isNewYear = true 
            else this.isNewYear = false 

            this.isIsset()
            },
        'formData.firstname': function() {
            this.isIsset()
        },
        'formData.lastname': function() {
            this.isIsset()
        },
        'formData.role': function() {
            this.isIsset()
        },
        'formData.email': function() {
            this.checkEmail()
        },
        'formData.phone': function() {
            if(this.formData.phone?.length < this.countryCode?.length) {
                this.formData.phone = this.countryCode
            }
            this.checkPhone()
        },
        'formData.social_number': function() {
            this.isIsset()
        },
        'formData.external.nameAccount': function(){
            this.isIsset()
        },
        'formData.external.routing_number': function(){
            this.isIsset()
        },
        'formData.external.accountNumber': function(){
            this.isIsset()
        },
        countryCode() {
            this.formData.phone = '+1'
        },
       },
       methods: {
        getDate() {
                let year = new Date().getFullYear()
                let month = (new Date().getMonth() + 1 < 10) ? "0" + String(new Date().getMonth() + 1) : new Date().getMonth() + 1
                let date = (new Date().getDate() < 10) ? "0" + String(new Date().getDate()) : new Date().getDate()

                return year + "-" + month + "-" + date
            },
        setActiveSSN() {
            this.isActiveSSN = !this.isActiveSSN
        },
        City(value) {
            this.formData.city = value
            this.isIsset()
        },
        State(value) {
            this.formData.state = value
            this.isIsset()
        },
        setActiveTerm() {

            this.addressValidate();
            this.zipCodeValidate();
            this.firstnameValidate();
            this.lastnameValidate();
            this.birthdayValidate();
            this.phoneValidate();
            this.emailValidate();
            this.ssnValidate();

            if(this.isActiveBtn) 
                this.isActiveTerms = !this.isActiveTerms
        },
        setCountryCode(val) {
            this.countryCode = "+1"
        },
        checkEmail() {
            this.checkAdminEmail(this.formData.email).then(data => {
                if(data?.ok === true || this.formData.email == this.firstEmail) {
                    this.siActiveEmailErr = false
                } else if(data?.ok === false && this.formData.email != this.firstEmail) {
                    this.siActiveEmailErr = true
                }
                this.isIsset()
            })
        },
        getAvatar(value) {
            this.formData.logo = value
            this.isIsset()
        },
        checkPhone() {
            this.checkCEOPhone(this.formData?.phone?.split(" ").join("")).then(data => {
                if(data?.ok === true || this.formData?.phone?.split(" ").join("") == this.firstPhone) {
                    this.isAvtivePhonErr = false
                } else if(data?.ok === false && this.formData?.phone?.split(" ").join("") != this.firstPhone) {
                    this.isAvtivePhonErr = true
                }
                this.index++
                this.isIsset()
            })
        },
        onImageReady () {
            this.scale = 1
            this.rotation = 0
            this.isSelectImg = true
            this.saveClicked()
         },
         phoneObject(object) {
            if (!object.valid)
                this.phoneValid = false;
            else 
                this.phoneValid = true;
        },
        previewFiles(event) {
                this.isSelectImg = true
                let reader  = new FileReader();
                let file = event.target.files[0]
                this.urls = event.target.files[0].result

                reader.onload = function(e)  {
                    let image = document.createElement("img");
                    // the result image data
                    image.src = e.target.result;
                    image.style.width = '120px'
                    image.setAttribute('ref', 'imgr')
                    this.imgs = e.target.result;
                    document.getElementById('imgr').appendChild(image)
                }
                // you have to declare the file loading
                reader.readAsDataURL(file);
                setTimeout(() => {
                this.urls = document.querySelector('#imgr img').src
                }, 1000)

            },
            setClick() {
                this.isSelectImg = !this.isSelectImg
            },
            saveClicked () {
             let img = this.$refs.vueavatar.getImageScaled()
             this.$refs.t_img.src = img.toDataURL()
         },
           isIsset() {
                const validateEmail = (email) => {
                return String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
                };

                const bankCardValid = this.bankCardTabActive &&
                    this.cardNumberCompleted &&
                    this.cardExpDateCompleted &&
                    this.cardCvcCompleted;

                const bankAccountValid = this.bankAccountTabActive &&
                    this.accountHolderNameValidate() &&
                    this.routingNumberValidate() &&
                    this.accountNumberValidate();

                if(this.formData.firstname != "" &&
                this.formData.lastname != "" &&
                this.formData.email != "" &&
                this.formData.phone != "" && 
                validateEmail(this.formData.email) &&
                !this.siActiveEmailErr &&
                !this.isAvtivePhonErr &&
//                this.ssnDigits.length == 9 && 
                this.ssnDigits.length == 4 && 
                this.formData.birthDay.trim() != '' && 
                !this.isOld && !this.isNewYear &&
                (bankCardValid || bankAccountValid || this.skipCardForm)) 
                    this.isActiveBtn = true;
                else 
                    this.isActiveBtn = false;
           },
           formatedSSN(e) {
                let val = e.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{3})/, '$1-');
                val = val.replace(/-(\d{2})/, '-$1-');
                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                e.target.value = val;
                this.isIsset()
            },
            /*
            ssnKeyDown(event) {
                const numKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
                const navKeys = [
                    "Backspace",
                    "Delete",
                    "Shift",
                    "ArrowUp",
                    "ArrowDown",
                    "ArrowLeft",
                    "ArrowRight",
                    "Tab"
                ];
                let selectionStart = event.target.selectionStart;            
                const eventKey = event.key;
                console.log('selectionStart', selectionStart);
                console.log('eventKey', eventKey);
                // If not NumKeys or NavKeys prevent event
                if (!numKeys.includes(eventKey) && !navKeys.includes(eventKey)) {
                    event.preventDefault();
                    return false;
                }
                if (event.key === "Backspace" || event.key === "Delete") {
                    selectionStart = selectionStart - 1 <= 0 ? 0 : selectionStart - 1;
                    console.log('Backspace selectionStart', selectionStart);
                    if (selectionStart == 4 || selectionStart == 7) {
                        let value = event.target.value;
                        value = value.substring(0, value.length - 1);
                        event.target.value = value;
                    } 
                    if (selectionStart >= 7 ) {   
                        this.ssnDigits.splice(selectionStart - 2, 1);        
                    } else if (selectionStart >= 4 ) {
                        this.ssnDigits.splice(selectionStart - 1, 1);
                    } else  {
                        this.ssnDigits.splice(selectionStart, 1);
                    }
                } else if (numKeys.includes(eventKey)) {
                    if (selectionStart > 10) {
                        return false;
                    }
                    
                    if (selectionStart >= 8) {
                        this.ssnDigits.splice(selectionStart - 2, 0, eventKey);
                    } else if (selectionStart >= 5) {
                        this.ssnDigits.splice(selectionStart - 1, 0, eventKey);
                    } else {
                        // insert new key at specific index
                        this.ssnDigits.splice(selectionStart, 0, eventKey);
                    }

                    if (selectionStart == 3 || selectionStart == 6) {
                        event.target.value += '-';
                    }
                    // 
                    if (this.ssnHidden) {
                        setTimeout(() => {
                            event.target.value = event.target.value.replace(/\d/g, "*");
                        }, 500);
                    }
                }
                console.log('event.target.value', event.target.value);
                console.log('ssnDigits', this.ssnDigits);
                
                if (this.ssnDigits.length === 9)
                    this.isIsset();

        },
        */
        ssnKeyDown(event) {
                const numKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
                const navKeys = [
                    "Backspace",
                    "Delete",
                    "Shift",
                    "ArrowUp",
                    "ArrowDown",
                    "ArrowLeft",
                    "ArrowRight",
                    "Tab"
                ];
                let selectionStart = event.target.selectionStart;            
                const eventKey = event.key;
                console.log('selectionStart', selectionStart);
                console.log('eventKey', eventKey);
                // If not NumKeys or NavKeys prevent event
                if (!numKeys.includes(eventKey) && !navKeys.includes(eventKey)) {
                    event.preventDefault();
                    return false;
                }
                if (event.key === "Backspace" || event.key === "Delete") {
                    selectionStart = selectionStart - 1 <= 0 ? 0 : selectionStart - 1;
                    console.log('Backspace selectionStart', selectionStart);
                    
                    this.ssnDigits.splice(selectionStart, 1);

                } else if (numKeys.includes(eventKey)) {
                    if (selectionStart >= 4) {
                        return false;
                    }
                    
                    this.ssnDigits.splice(selectionStart, 0, eventKey);

                    // 
                    if (this.ssnHidden) {
                        setTimeout(() => {
                            event.target.value = event.target.value.replace(/\d/g, "*");
                        }, 500);
                    }
                }
                console.log('event.target.value', event.target.value);
                console.log('ssnDigits', this.ssnDigits);
                
                if (this.ssnDigits.length === 4)
                    this.isIsset();

        },
        ssnPreventSelect(event) {
            event.target.selectionStart = event.target.selectionEnd;
        },
        showSsn() {
            if (this.ssnHidden) {
                let val = this.ssnDigits.join("");
                            
//                val = val.replace(/^(\d{3})/, '$1-');
//                val = val.replace(/-(\d{2})/, '-$1-');
//                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                
                this.$refs.ssnInputRef.value = val;

                this.ssnHidden = false;
            } else {
                this.$refs.ssnInputRef.value = this.$refs.ssnInputRef.value.replace(/\d/g, "*");

                this.ssnHidden = true;
            }
//            this.formData.social_number = this.ssnDigits.join("");
        },

        firstnameValidate() {
            this.validateTextInput('firstnameInput', 'firstname', 'firstnameError', 'Please enter your firstname');
        },
        lastnameValidate() {
            this.validateTextInput('lastnameInput', 'lastname', 'lastnameError', 'Please enter your lastname');
        },
        addressValidate() {
            this.validateTextInput('addressInput', 'address', 'addressError', 'Please enter your address');
        },
        zipCodeValidate() {
            this.validateZipCodeInput('zipCodeInput', 'zip', 'zipCodeError', 'Please enter your zip-code');
        },
        birthdayValidate() {
            let nowDate = new Date(); // Current date now.
            
            let lastDate = new Date(this.formData.birthDay); // Start of 2010.
            let d = ((nowDate-lastDate) / 1000 / (60 * 60 * 24) / 365.25).toFixed(0)
            if(d > 120) 
                this.isOld = true 
            else 
                this.isOld = false 

            if(d < 18) 
                this.isNewYear = true 
            else 
                this.isNewYear = false 

            if (this.isNewYear || this.isOld)
                this.$refs.birthdayInput.setError(true);
            else
                this.$refs.birthdayInput.setError(false);

        },
        phoneValidate() {
            if (this.phoneValid) {
                this.phoneError = '';
                this.phoneInputClasses = '';
            } else { 
                this.phoneError = "Please enter your correct phone number";
                this.phoneInputClasses = 'phone-error-input';
            }
        },
        emailValidate() {
            const isEmailValid = this.validateEmail(this.formData.email);
            
            if (isEmailValid) {
                this.emailError = '';
                this.$refs.emailInput.setError(false);
            } else {
                this.emailError = "Please enter your correct email address";
                this.$refs.emailInput.setError(true);
            }

        },
        ssnValidate() {
            this.validateSsnInput('ssnInputRef', 'social_number', 'ssnError', 'Please enter your social security number');
        },        
        validateTextInput(refKey, formDataKey, errorKey, errorText) {
            const inputValue = this.formData[formDataKey];
            
            const validationResult = this.validateText(inputValue);
            
            if (validationResult) {
                this[errorKey] = '';
                this.$refs[refKey].setError(false);
            } else {
                this[errorKey] = errorText;
                this.$refs[refKey].setError(true);
            }
        },
        validateZipCodeInput(refKey, formDataKey, errorKey, errorText) {
            const inputValue = this.formData[formDataKey];
            
            const validationResult = this.validateText(inputValue, 4);
            
            if (validationResult) {
                this[errorKey] = '';
                this.$refs[refKey].setError(false);
            } else {
                this[errorKey] = errorText;
                this.$refs[refKey].setError(true);
            }
        },
        validateSsnInput(refKey, formDataKey, errorKey, errorText) {
            const inputValue = this.formData[formDataKey];
            
            const validationResult = this.validateSsn();
            
            if (validationResult) {
                this[errorKey] = '';
                this.$refs[refKey].setError(false);
            } else {
                this[errorKey] = errorText;
                this.$refs[refKey].setError(true);
            }
        },
        validateText(value, minLength = 0) {
            return value.length > minLength;
        },
        /*        
        validateSsn() {
            return this.ssnDigits.length == 9
        },
        */
        validateSsn() {
            return this.ssnDigits.length == 4
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        routingNumberValidate() {
            
            const validationError = this.formData.external.routing_number.trim().length != 9;
            
            if (validationError) {
                this.routingNumberError = 'Please enter correct routing number';
                this.$refs.routingNumberInput.setError(true);
                return false;
            } else {
                this.routingNumberError = '';
                this.$refs.routingNumberInput.setError(false);
                return true;
            }
        },
        accountNumberValidate() {

            const validationError = this.formData.external.accountNumber.length > 15 ||
                this.formData.external.accountNumber.length < 9;
            
            console.log ('validationError', validationError);
            if (validationError) {
                this.accountNumberError = 'Please enter correct account number';
                this.$refs.accountNumberInput.setError(true);
                return false;
            } else {
                this.accountNumberError = '';
                this.$refs.accountNumberInput.setError(false);
                return true;
            }
        },
        accountHolderNameValidate() {
         
            const validationResult = this.validateText(this.formData.external.nameAccount);

            if (validationResult) {
                this.accountHolderNameError = '';
                this.$refs.accountHolderNameInput.setError(false);
                return true;
            } else {
                this.accountHolderNameError = 'Please enter account holder name';
                this.$refs.accountHolderNameInput.setError(true);
                return false;
            }
        },
        onBankCardTabClick() {
            this.bankCardTabActive = true;
            this.bankAccountTabActive = false;
        },
        onBankAccountTabClick() {
            this.bankAccountTabActive = true;
            this.bankCardTabActive = false;
        },
        goToStripe() {
            window.location.href = "https://stripe.com/";
        },
        skipAddingCard(event) {
            event.preventDefault();

            this.skipCardForm = !this.skipCardForm;

            this.isIsset();
        },
           async sends() {
                localStorage.setItem('name', this.formData.firstname)
                localStorage.setItem('lastname', this.formData.lastname)
                localStorage.setItem('userData.phone', this.formData.phone)
                localStorage.setItem('userData.email', this.formData.email)
                localStorage.setItem('userData.dob', this.formData.birthDay)
                localStorage.setItem('userData.state', this.formData.state)
                localStorage.setItem('userData.city', this.formData.city)

               if(this.isActiveBtn) {
                this.DOM.isActiveLoading = true
                this.isActiveTerms = false

                if (this.skipCardForm) {

//                    this.cardToken = result?.token?.id
                            localStorage.setItem('card_token', '');
                            this.DOM.isErrorCard = false
                            this.DOM.cErr = false
                            this.DOM.isActiveLoading = true

                            this.updateInfo({
                                name: localStorage.getItem('name'),
                                last_name: localStorage.getItem('lastname'),
                                phone: localStorage.getItem('userData.phone'),
                                email: localStorage.getItem('userData.email'), 
                                logo: (this.formData.logo == '') ? '' : this.formData?.logo?.get('file'),
                                username: localStorage.getItem('userData.username'),
                                password: localStorage.getItem('userData.password'),
                                dob: localStorage.getItem('userData.dob'),
                                state: localStorage.getItem('userData.state'),
                                city: localStorage.getItem('userData.city'),
                                zip: this.formData.zip,
                                ssn: this.ssnDigits.join(""),
                                card_token: '',
                                address: this.formData.address
                            }).then(data => {
                                if(data?.token == undefined || data?.token == null) {
                                    this.isUndefinedError = true
                                    this.isActiveTerms = false
                                } else {
                                    
                                    localStorage.setItem('auth-token', data.token)
                                    if(data.role != 'admin') this.$router.push('/employee/qrcode')
                                    else this.$router.push('/dashboard')
                                }
                            }).catch(err => {
                                this.DOM.isActiveLoading = false
                                this.isUndefinedError = true
                                this.isActiveTerms = false
                            })
                    
                } else {

                    if (this.bankCardTabActive) {

                        await this.stripe.createToken(this.cardEl, {
                            currency: "usd"
                        }).then((result) => {

                            if (result?.token?.id != undefined) {
                            
                                this.cardToken = result?.token?.id
                                localStorage.setItem('card_token', this.cardToken)
                                this.DOM.isErrorCard = false
                                this.DOM.cErr = false
                                this.DOM.isActiveLoading = true

                                this.updateInfo({
                                    name: localStorage.getItem('name'),
                                    last_name: localStorage.getItem('lastname'),
                                    phone: localStorage.getItem('userData.phone'),
                                    email: localStorage.getItem('userData.email'), 
                                    logo: (this.formData.logo == '') ? '' : this.formData?.logo?.get('file'),
                                    username: localStorage.getItem('userData.username'),
                                    password: localStorage.getItem('userData.password'),
                                    dob: localStorage.getItem('userData.dob'),
                                    state: localStorage.getItem('userData.state'),
                                    city: localStorage.getItem('userData.city'),
                                    zip: this.formData.zip,
                                    ssn: this.ssnDigits.join(""),
                                    card_token: result?.token?.id,
                                    address: this.formData.address
                                }).then(data => {
                                    if(data?.token == undefined || data?.token == null) {
                                        this.isUndefinedError = true
                                        this.isActiveTerms = false
                                    } else {
                                        
                                        localStorage.setItem('auth-token', data.token)
                                        if(data.role != 'admin') this.$router.push('/employee/qrcode')
                                        else this.$router.push('/dashboard')
                                    }
                                }).catch(err => {
                                    this.DOM.isActiveLoading = false
                                    this.isUndefinedError = true
                                    this.isActiveTerms = false
                                })
                            } else {
                                this.DOM.cErr = true
                            }
                        });

                    } else if (this.bankAccountTabActive) {

                        await this.stripe.createToken('bank_account', {
                            country: 'US',
                            currency: 'usd',
                            routing_number: this.formData.external.routing_number,
                            account_number: this.formData.external.accountNumber,
                            account_holder_name: this.formData.external.nameAccount,
                            account_holder_type: 'individual',
                        }).then((result) => {

                            if (result?.token?.id != undefined) {
                            
                                this.cardToken = result?.token?.id
                                localStorage.setItem('card_token', this.cardToken)
                                this.DOM.isErrorCard = false
                                this.DOM.cErr = false
                                this.DOM.isActiveLoading = true

                                this.updateInfo({
                                    name: localStorage.getItem('name'),
                                    last_name: localStorage.getItem('lastname'),
                                    phone: localStorage.getItem('userData.phone'),
                                    email: localStorage.getItem('userData.email'), 
                                    logo: (this.formData.logo == '') ? '' : this.formData?.logo?.get('file'),
                                    username: localStorage.getItem('userData.username'),
                                    password: localStorage.getItem('userData.password'),
                                    dob: localStorage.getItem('userData.dob'),
                                    state: localStorage.getItem('userData.state'),
                                    city: localStorage.getItem('userData.city'),
                                    zip: this.formData.zip,
                                    ssn: this.ssnDigits.join(""),
                                    card_token: result?.token?.id,
                                    address: this.formData.address
                                }).then(data => {
                                    if(data?.token == undefined || data?.token == null) {
                                        this.isUndefinedError = true
                                        this.isActiveTerms = false
                                    } else {
                                        
                                        localStorage.setItem('auth-token', data.token)
                                        if(data.role != 'admin') this.$router.push('/employee/qrcode')
                                        else this.$router.push('/dashboard')
                                    }
                                }).catch(err => {
                                    this.DOM.isActiveLoading = false
                                    this.isUndefinedError = true
                                    this.isActiveTerms = false
                                })
                            } else {
                                this.DOM.cErr = true
                            }
                        });
                        
                    }

                }


               }
           }
       },
       async beforeMount() {
        this.getDate()
        if(localStorage.getItem('auth-token') != undefined) {
            let query = await fetch(window.origin + '/api/user/auth', {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                }
            })

            if(query.status == 200) {
                const url = new URL('/employee/workspace', window.location.origin)
                window.location.href = url.toString();
                return;
            }
        }
        if(localStorage.getItem('userData.email') == undefined ||
        localStorage.getItem('userData.password') == undefined ||
        //  ID is undefined for the newly registered personal account
//        localStorage.getItem('id') == undefined ||
        localStorage.getItem('userData.username') == undefined) return this.$router.push('/')

        let query = await fetch('https://www.universal-tutorial.com/api/getaccesstoken', {
            method: "GET",
            headers: {
                "Accept": "application/json",
                'api-token': "-g27lPGpwWOzarFcylW620SzveZ7k25ziZdoiO8jvAQ4pIDtb6_LmCZ7Dpxj1ywZrF0",
                'user-email': "qwerty@gmail.ru"
            }
        })

        let json = await query.json()

        this.token = json['auth_token']

        this.state = ["Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware","District of Columbia","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Louisiana","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Montana","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina","North Dakota","Ohio","Oklahoma","Ontario","Oregon","Pennsylvania","Ramey","Rhode Island","South Carolina","South Dakota","Sublimity","Tennessee","Texas","Trimble","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"]
        this.formData.state = this.state[0]
        this.city = ["Alabaster","Albertville","Alexander City","Anniston","Arab","Ashville","Athens","Atmore","Auburn","Bessemer","Birmingham","Capshaw","Center Point","Childersburg","Cullman","Daleville","Daphne","Decatur","Dothan","Enterprise","Eufaula","Fairfield","Fairhope","Florence","Fort Payne","Gadsden","Grand Bay","Grove Hill","Guntersville","Hampton Cove","Hanceville","Hartselle","Headland","Helena","Hodges","Homewood","Hoover","Hueytown","Huntsville","Jacksonville","Jasper","Leeds","Luverne","Madison","Mobile","Montgomery","Mountain Brook","Muscle Shoals","Northport","Notasulga","Opelika","Oxford","Ozark","Pelham","Pell City","Pennsylvania","Phenix City","Prattville","Prichard","Ramer","Roanoke","Saraland","Scottsboro","Selma","Sheffield","Smiths","Sumiton","Sylacauga","Talladega","Thomasville","Trafford","Troy","Trussville","Tuscaloosa","Tuskegee","Vestavia Hills"]
            this.formData.city = this.city[0]
        this.getDataEmployeePublic().then(data => {
            this.formData.firstname = data.user?.name
            this.formData.lastname = data.user?.last_name
            this.formData.email = data.user?.email

            this.firstEmail = data.user?.email
            this.firstPhone = data.user?.phone

            this.formData.phone = data.user?.phone

        })
       },
       mounted() {
        this.stripe = window.Stripe(this.stripe);
            
            
        const appearance = {
            theme: 'day',
            
            };

            const options = {
                appearance,
                fonts: [
                    {
                    // integrate your font into stripe
                    cssSrc: 'https://fonts.googleapis.com/css?family=Rubik:300,400,500',
                    fontSize: "40px"
                    }
                ]                
            };
            /*
            const stripeStyle = {
                style: {
                    base: {
                        fontFamily: 'Rubik-Light, sans-serif',
                        color: "#ABABAB",
                        fontSize: "16px"
                    },
                },
                hidePostalCode: true
            }
            */


            const elementStyles = {
                base: {
                    color: 'white',
                    fontWeight: 300,
                    fontFamily: 'Rubik-Light, Consolas, Menlo, monospace',
                    fontSize: '15px',
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                        color: '#ABABAB',
                    },
                    ':-webkit-autofill': {
                        color: '#e39f48',
                    },
                }
                /*
                invalid: {
                    color: '#E25950',
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
                */
            };

            const elements = this.stripe.elements(options);

            const elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            };

            // Card number
            this.cardEl = elements.create("cardNumber", {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardEl.mount('#card-number');

            this.cardEl.on('change', event => {
//                console.log("LOGGING THE CARD ELEMENT EVENT", event);
                if (event.complete) {
                    console.log('CARD ELEMENT completed');

                    this.cardNumberCompleted = true;

                    this.isIsset()
                    // enable payment button
                } else if (event.error) {
                    // show event.error.message to customer
                    this.cardNumberCompleted = false;
                } else {
                    this.cardNumberCompleted = false;
                }
            });

            // Card number
            this.cardExpiry = elements.create("cardExpiry", {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardExpiry.mount('#card-expiry');

            this.cardExpiry.on('change', event => {
//                console.log("LOGGING THE CARD EXPIRY EVENT", event);
                if (event.complete) {
                    console.log('CARD EXPIRY completed');

                    this.cardExpDateCompleted = true;

                    this.isIsset()   
                    // enable payment button
                } else if (event.error) {
                    // show event.error.message to customer
                    this.cardExpDateCompleted = false;
                } else {
                    this.cardExpDateCompleted = false;
                }
            });

            // Card number
            this.cardCvc = elements.create("cardCvc", {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardCvc.mount('#card-cvc');

            this.cardCvc.on('change', event => {
//                console.log("LOGGING THE CARD CVC EVENT", event);
                if (event.complete) {
                    console.log('CARD CVC completed');

                    this.cardCvcCompleted = true;
                    
                    // enable payment button
                    this.isIsset();
                } else if (event.error) {
                    // show event.error.message to customer
                    this.cardCvcCompleted = false;
                } else {
                    this.cardCvcCompleted = false;
                }
            });
    },
   }
   </script>

<style scoped>

.InputElement {
    color: white !important
}

.field {
    position: relative;
    cursor: text;
    height: 62px;
    background-color: #1E1E1E;
    border-radius: 10px;
    display: flex;
    align-items: center;
} 

.m-stripe-input {
    width: 100%;
    padding: 20px 10px;
}


.user-input-wrp .stripe-floating-label {
    position: absolute;
    pointer-events: none;
    color: #ABABAB;
    top: 22px;
    left: 10px;
    transition: 0.2s ease all;
    font-family: 'Rubik-Light';
    font-size: 12px;
}


.user-input-wrp .m-stripe-input.focused + .stripe-floating-label,
.user-input-wrp .m-stripe-input:not(.empty) + .stripe-floating-label {
  top: 5px;
	left: 10px;
	font-size: 12px;
	opacity: 1;
}

.row {
    display: flex;
}

.user-input-wrp.half-width {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}


.user-input-wrp .m-stripe-input::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-input-wrp .m-stripe-input::-moz-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-input-wrp .m-stripe-input:-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-input-wrp .m-stripe-input.StripeElement {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
}

.user-input-wrp .m-stripe-input.focused,
.user-input-wrp .m-stripe-input:not(.empty) {
  opacity: 1;
}

.user-input-wrp .m-stripe-input.focused::-webkit-input-placeholder,
.user-input-wrp .m-stripe-input:not(.empty)::-webkit-input-placeholder {
  color: #cfd7df;
}

.user-input-wrp .m-stripe-input.focused::-moz-placeholder,
.user-input-wrp .m-stripe-input:not(.empty)::-moz-placeholder {
  color: #cfd7df;
}

.user-input-wrp .m-stripe-input.focused:-ms-input-placeholder,
.user-input-wrp .m-stripe-input:not(.empty):-ms-input-placeholder {
  color: #cfd7df;
}

.card-form-wrapper {
    position: relative;
}

.ssn-container {
        border: 2px solid #282828;
        border-radius: 20px;
        position: relative;
        width: 320px;
        display: flex;
        flex-direction: column;
    }
    .ssl-secured {
        position: absolute;
        width: 100px;
        right: 0;
        top: -16px;
        background: #171717;
    }
    .ssn-fill-label {
        color: white;
        position: absolute;
        left: 10px;
        top: -11px;
        background: #171717;
        padding: 0 3px;
    }
    .field-provided-info {
        font-size: 8px;
        text-align: left;
        font-family: 'Rubik-Medium';
        color: #ABABAB;
        padding-left: 10px;
        margin-top: 5px;
    }

    .btn-show {
        margin-left: 120px;
    }

    .payout-tabs {
        gap: 10px;
    }

    .card-form-inner {
        padding: 20px 0;
        /*
        border: 2px solid #2E2E2E;
        */
        border-top: 0;
        border-radius: 0 0 10px 10px;
    }

    @media screen and (min-width: 501px) {
        .mt-2-state {
            margin-left: 10px;
        }
        .mt-2-city {
            margin-left: 10px;
        }
        
    }

    @media screen and (max-width: 500px) {
        .user-input-wrp.left.half-width {
            margin-left: unset;        
        }
        .user-input-wrp.right.half-width {
            margin-right: unset;     
        }

        .inner-modal-div-form h3:nth-child(1) {
            margin-top: 40px !important;
        }

    }

</style>
