<template>
    <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
                
                    <h3>SIGN UP</h3>
                    <change-button label="Email" @click="sendData" class="mt-6"/>
                    <change-button label="Phone" @click="sendData" class="mt-1"/>
                    <change-button label="WhatsApp" @click="sendData" class="mt-1"/>
                    <div class="flex mt-4 r-icons">
                        <a :href="domain + '/api/google/auth'"><img src="../assets/img/icons/social/google.png"></a>
                        <a :href="domain + '/api/facebook/callback'"><img src="../assets/img/icons/social/facebook.png"></a>
                    </div>
                    <span class="flex mt-4 flex-center">
                        <!-- <p class="orange-color center">Back</p> -->
                        <p class="main-text-color">Already have an account?</p>
                        <RouterLink to="/"><p class="orange-color ml-2">Sign In</p></RouterLink>
                    </span>
                </div>
            
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
export default {
    name: 'sign up',
    data() {
       return {
        domain: window.location.origin
       }
    },
  
}
</script>