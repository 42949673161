<template>
    <div class="outer-modal-div-form client-outer">
           <div class="middle-modal-div-form">
            <img src="../../assets/img/UI/white-close.svg" class="white-close-client mt-2 pointer" @click="$router.push('/')">
               <div class="inner-modal-div-form w-320 client-wrapper">
                <img src="../../assets/img/UI/qr-img.svg">
                <p class="white-text mt-1-5">Point the camera <br>at the qr code</p>
                    <StreamBarcodeReader
                    @decode="onDecode"
                    @loaded="onLoaded"
                    class="mt-4"
                    style="width: 320px;"></StreamBarcodeReader>  
                    <div class="mt-2"></div>
                    <QrcodeCapture ref="qwes" id="img-load" @detect="onDetect"></QrcodeCapture>  
                    <label for="img-load" class="btn-ing-loar-qr"><img src="../../assets/img/UI/white-qr.svg">Select photo</label>
                </div>
           </div>
       </div>
   </template>
   
   <script>
    
       export default {
           name: 'QRscan client',
           data() {
               return {
                 show: false,
               }
           },
             
           mounted() {
            let func = function() {
                console.log(document.querySelector('input[type="file"]'))
                document.querySelector('input[type="file"]').removeAttribute('capture')
            }
            func()
           },
           methods: {
            onDetect(value) {
                if(value.length > 0) {
                    console.log(value)
                    window.location.href = value[0].rawValue
                }
            },
            onDecode(text) {
                window.location.href = text
            },
            onLoaded() {
                this.show = true
            },
          
           }
       }
   </script>