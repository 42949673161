<template>
    <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
              
                    <h3>SIGN UP</h3>
                    <p class="error-input-text mt-1" v-if="isUndErr">An error has occurred. Try later</p>
                    <change-button label="Email" class="mt-6" @click="$router.push('/signupforms')"/>
                    <!-- <change-button label="Phone" class="mt-1" @click="$router.push('/signupnumber')"/> -->
                    <a :href="domain + '/api/google/auth'"><div class="vbn-s-btn mt-2"><img src="../assets/img/icons/social/_google.svg"> Google</div></a>
                    <!-- <div class="flex mt-4 r-icons">
                        <a :href="domain + '/api/google/auth'"><img src="../assets/img/icons/social/google.png"></a>
                        <a v-if="false" :href="domain + '/api/facebook/callback'"><img src="../assets/img/icons/social/facebook.png"></a>
                    </div> -->
                    <p class="orange-color center mt-2 pointer" @click="$router.push('/employee')">Back</p>
                    <span class="flex mt-1 flex-center">
                    
                        <p class="main-text-color">Already have an account?</p>
                        <RouterLink to="/staff/signin"><p class="orange-color ml-2">Sign In</p></RouterLink>
                    </span>
                
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
export default {
    name: 'sign up full',
    data() {
       return {
        isUndErr: false,
        isHasUser: false,
        domain: window.location.origin
       }
    },
    beforeMount() {
        if(this.$route.query?.err == '0') {
            this.isUndErr = true
        } else if(this.$route.query?.err == '1') {
            this.isHasUser = true
        }
    }
  
}
</script>