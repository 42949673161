<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <div class="flex">
                <h3 class="head-title">CREATE A NEW<br/> PASSWORD</h3>
                <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
            </div>
            <my-input :type="typeNewPassword" showFunc="true" label="New password" bgColor="#1E1E1E" class="mt-4" v-model="formData.newPassword"/>
                    
                    <div><img src="../../assets/img/UI/show.png" class="btn-show" @click="showNewPassword"></div>
                    <my-input :type="typeRepeatPassword" showFunc="true" bgColor="#1E1E1E" label="Repeat password" class="mt-1" v-model="formData.repeatPassword"/>
                    <div><img src="../../assets/img/UI/show.png" class="btn-show"  @click="showRepeatPassword"></div>
            <my-button label="Confirm" :active="isActiveBtn" @click="getData" class="mt-4"/>
        </div>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'

    export default {
        name: 'change-password',
        mixins: [configMixin],
        data() {
            return {
                isActiveBtn: false,
                typeNewPassword: 'password',
                typeRepeatPassword: 'password',
                formData: {
                    newPassword: '',
                    repeatPassword: ''
                },
            }
        },
        watch: {
            'formData.newPassword': function() {
                this.isIsset()
            },
            'formData.repeatPassword': function() {
                this.isIsset()
            },
        },
        methods: {
            close() {
                localStorage.removeItem('sms-code')
                this.$emit('close')
            },
            getData() {
                if(this.isActiveBtn == true) {

                    let response = async() => {
                        let body = {
                            password: this.formData.newPassword
                        }

                        let query = await fetch(this.config.API_URL + '/user/update/' + localStorage.getItem('sms-code'), {
                            method: 'PUT',
                            headers: {
                                "Authorization": 'Bearer ' + localStorage.getItem('auth-token'),
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }
                    
                    return response().then(data => {
                        localStorage.removeItem('sms-code')
                        this.$emit('getdata')
                    })
                }
            },
            showNewPassword() {
                if(this.typeNewPassword == 'text') this.typeNewPassword = 'password'
                else this.typeNewPassword = 'text'
            },
            showRepeatPassword() {
                if(this.typeRepeatPassword == 'text') this.typeRepeatPassword = 'password'
                else this.typeRepeatPassword = 'text'
            },
            isIsset() {
                if(this.formData.newPassword != "" &&
                this.formData.repeatPassword != "" && this.formData.newPassword == this.formData.repeatPassword) this.isActiveBtn = true
                else this.isActiveBtn = false
            }
        }
    
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
.close {
    position: absolute;
    margin-left: 94%;
    margin-top: -40px;
}
.inner-modal-div-form {
    background: #171717;
}
.inner-modal-div-form {
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #171717;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-left: 101%;
        position: absolute;
        margin-top: 0;
    }
}
</style>