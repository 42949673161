<template>
    <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form inner-modal-div-form-dop">
                <div class="wrapper-inner-modal">
                <div style="width: 320px; margin-left: auto; margin-right: auto;">
                    <h3>INVITATION</h3>
                    <p style="color: white; font-size: 20px; margin-top: 20px;">{{ this.$route.query?.email }}</p>
                    <my-input 
                        :type="typeNewPassword" 
                        showFunc="true" 
                        label="New password" 
                        class="mt-2 w-320-mob" 
                        v-model="formData.newPassword"
                        validateType="password"
                        @password-error="setPasswordError"
                    />                    
                    <div>
                        <img src="../../assets/img/UI/show.png" class="btn-show " @click="showNewPassword">
                    </div>
                    <p class="error-input-text err-au" v-if="passwordError">Password should be at least 8 symbols with one capital letter and one number</p>

                    <my-input 
                        :type="typeRepeatPassword" 
                        showFunc="true" 
                        label="Repeat password" 
                        class="mt-1 w-320-mob" 
                        v-model="formData.repeatPassword"
                    />
                    <div>
                        <img src="../../assets/img/UI/show.png" class="btn-show"  @click="showRepeatPassword">
                    </div>
                    <my-button label="Registration" @click="sendData" :active="isActive" class="mt-4 w-320-mob"/>
                    <div class="flex mt-3">
                        <div class="form_radio">
                            <input id="radio-1" type="radio" name="radio" checked value="1"  @click="checks">
                            <label for="radio-1"></label>
                        </div>
                        <p class="radio-text main-text-color">By registering I confirm I have read and agree 
to the <a href="/docs/Terms and Conditions.pdf" class="orange-color">MerciQ Terms of Use</a>, the Fees set out <a href="/docs/Fee Plan.pdf" class="orange-color">here</a>. We manage personal data as set out in our <a href="/docs/Privacy Policy.pdf" class="orange-color">Privacy Policy</a>. I also confirm that I have read and accept the <a class="orange-color" href="https://stripe.com/en-ch/legal/connect-account">Stripe Connected Account Agreement</a></p>
                    </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
import { mapMutations } from 'vuex'
import sendCodeMixin from '@/mixins/sendCodeMixin'
import registrAdminMixin from '@/mixins/registrAdminMixin'
import getInviteEmplMixin from '@/mixins/getInviteEmplMixin'

export default {
    name: 'add employee get',
    mixins: [sendCodeMixin, registrAdminMixin, getInviteEmplMixin],
    data() {
       return {
        isActive: false,
        typeNewPassword: 'password',
        typeRepeatPassword: 'password',
        formData: {
            email: '',
            newPassword: '',
            repeatPassword: '',
            checks: true,
        },
        siActiveEmailErr: false,
        passwordError: false
       }
    },
    watch: {
        'formData.newPassword': function() {
            this.isIsset()
        },
        'formData.repeatPassword': function() {
            this.isIsset()
        },
    },
    methods: {
        ...mapMutations({
            setData: 'code/setData'
        }),
        sendData() {
            this.setData(this.formData.email)
            if(this.isActive) {
                localStorage.setItem('userData.email',  this.$route.query?.email);
                localStorage.setItem('userData.password', this.formData.newPassword);
                if(this.sendDataApi(this.formData.email)) {
                    this.$router.push("/employee/username")
                }
            }
        },
        checks() {
            this.formData.checks = true
            this.isIsset()
        },
        showNewPassword() {
            if(this.typeNewPassword == 'password') this.typeNewPassword = 'text'
            else this.typeNewPassword = 'password'
        },
        showRepeatPassword() {
            if(this.typeRepeatPassword == 'password') this.typeRepeatPassword = 'text'
            else this.typeRepeatPassword = 'password'
        },
        isIsset() {
           
            if( 
            this.formData.newPassword != "" && 
            this.formData.repeatPassword != "" && 
            this.formData.newPassword == this.formData.repeatPassword && 
            this.formData.checks && !this.siActiveEmailErr) this.isActive = true
            else this.isActive = false
        },
        setRepeatPassword(value) {
            this.formData.repeatPassword = value
            this.isIsset()
        },
        setNewPassword(value) {
            this.formData.newPassword = value
            this.isIsset()
        },
        setPasswordError(value) {
            console.log('setPasswordError', value)
            this.passwordError = value;
        }
    },
    beforeMount() {
        if(this.$route.query?.id === null || this.$route.query?.id === undefined ||
        this.$route.query?.email === null || this.$route.query?.email === undefined) {
            this.$router.push('/')
        } else {
            this.getDataNewEmployee(Number(this.$route.query?.id), this.$route.query?.email).then(data => {
                localStorage.clear()
                if(data?.ok == true) {
                    localStorage.setItem('id', this.$route.query?.id)
                }
                localStorage.setItem('id', this.$route.query?.id)
            })
        }
    }
  
}
</script>