import "@sjmc11/tourguidejs/src/scss/tour.scss" // Styles
import { TourGuideClient } from "@sjmc11/tourguidejs/src/Tour" // JS
import type { TourGuideOptions } from "@sjmc11/tourguidejs/src/core/options";

class TgInstance {

  static instance: TgInstance;
  tg?: TourGuideClient;
  groupName?: string;

  constructor(options: TourGuideOptions) {
    if ( TgInstance.instance ) {
      return TgInstance.instance
    }

    this.tg = new TourGuideClient(options);

    TgInstance.instance = this;
  }

}

export default TgInstance;