<template>
        <modal-calendar 
        @closecalendar="setActiveCalendar" 
        @setcalendar="getCalendar" 
        v-if="isActiveCalendar"/>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': (isActiveEditUserPanel || isActiveEditManager || isActiveCalendar), 'excel-wrapper': true}">
            <div class="flex">
                <h2 class="main-title-dash pointer" @click="$router.push('/dashboard')"><img src="../../assets/img/UI/left-strel.svg"> Reporting excel</h2>    
            </div>
            <div class="day-excel">
                <div class="flex ml-3 ml-0-mobile excel-date">
                    <div class="flex">
                        <div class="test-ert-n" v-if="choiceDate == ''"></div>
                        <div  :class="{'date-el-exc': true,  'ml-1': true, 'active-excel-date': (filterDate == 'Day')}" class="pointer excel-date-el" @click="setDay"><span>Day</span></div>
                        <div :class="{'date-el-exc': true, 'ml-1': true, 'active-excel-date': (filterDate == 'Week')}" class="pointer" @click="setWeek"><span>Week</span></div>
                        <div :class="{'date-el-exc': true, 'ml-1': true, 'active-excel-date': (filterDate == 'Month')}" class="pointer" @click="setMonth"><span>Month</span></div>
                        <div class="panel-calendar-min ml-3 calendar-left" @click="setActiveCalendar" v-if="choiceDate == ''">
                        <img src="../../assets/img/UI/calendar-min-panel.svg">
                        </div>
                    </div>
                    <div class="panel-calendar-min ml-3 mt-2-mobile mon-0 calendar-left excel-get-date" v-if="choiceDate != ''" style="margin-bottom: 10px">
                        <div>
                            <span class="choiceDate" style="white-space: nowrap; margin-left: 0;">{{ choiceDate }}</span>
                            <img src="../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delCalendar">
                        </div>
                    </div>
                   
                </div>
                <div class="btn-excel-download m-x-auto mt-4 d-none-desc">
                    <my-button active="true" label="Download the report" @click="getExcelReport(filterDate)"/>
                </div>
            </div>

            <span class="excel-trans">Transactions</span>

            <div class="mt-3">
                <user-transaction 
                v-for="el in trans" 
                :id="zeroPad(el.id)" 
                :date="String(new Date(el.createdAt).getDate()) + '.' + (new Date(el.createdAt).getMonth() + 1) + '.' + String(new Date(el.createdAt).getFullYear())" 
                :time="el.time" :count="el.sum" 
                class="mt-1 w-600px"
                :type="el.type"/>
            </div>
            <my-button active="true" class="d-none-mobile mt-2" label="Download the report" @click="getExcelReport(filterDate)"/>
            <paginate :count="Number(lastpage)" link="/excel"/>
        </div>

    </div>
</template>

<script>
import dashboardMixin from '@/mixins/dashboardMixin';
import * as XLSX from "xlsx";
import configMixin from '@/mixins/configMixin';
import dateIconMixin from '@/mixins/dateIconMixin';
import getFinanceMixin from '@/mixins/getFinanceMixin';
import loaderMixin from '@/mixins/loaderMixin';

export default {
    name: 'excel report',
    mixins: [dashboardMixin, configMixin, dateIconMixin, getFinanceMixin, loaderMixin],
    data() {
        return {
            filterDate: 'Day',
            emptyData: [{
                'id': '',
                'sum': '',
                'comment': '',
                'time': '',
                'like_type': '',
                'User.id': '',
                "User.last_name": "",
                "User.name": "",
                "User.username": "",
                "Establishment.id": '',
                "Establishment.name": "",
                "Team.id": '',
                "Team.name": "",
                'createdAt': ''
            }],
            lastpage: 0,
            trans: ''
        }
    },
    watch: {
        '$route.query.page': function() {
            this.FinanceMixin()
        },
        filterDate() {
            if(this.filterDate == 'Day' ||
            this.filterDate == 'Month' || 
            this.filterDate == '24 hour' ||
            this.filterDate == 'Week') this.choiceDate = ''
            this.FinanceMixin()
        }
    },
    methods: {
        zeroPad(num) {
            return num.toString().padStart(7, "0");
        },
        setHours() {
            this.filterDate = '24 hour'
        },
        getCalendar(value) {
                let date = (value == null || value == undefined) ? null : JSON.stringify({date: value})
                this.filterDate = value
                this.getDateIcon(value)
        },
        delCalendar() {
            this.setDay()
            this.FinanceMixin()
            this.delDateIcon()
        },
        setDay() {
            this.filterDate = 'Day'
        },
        setWeek() {
            this.filterDate = 'Week'
        },
        setMonth() {
            this.filterDate = 'Month'
        },
        generateExcelFile(data) {
            const ws = XLSX.utils.json_to_sheet(data);  
            const wb = XLSX.utils.book_new();  
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');  
            XLSX.writeFile(wb, 'report.xlsx');  
        },
        getExcelReport(date) {
            let newDate = date
            if (date == '24 hour') newDate = "Day"
            let dateFilter = (date == null || date == undefined) ? '' : '?date=' + newDate
            if(date != "Day" && date != 'Week' && date != 'Month' && date != 'Day' && date != null && date != undefined) {
                dateFilter = (date == null || date == undefined) ? '' : '?date=' + JSON.stringify({date: date})
            }

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/transaction/excel' + dateFilter, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            response().then(data => {
                if(data?.rows?.length == 0) {
                    this.generateExcelFile(this.emptyData)
                } else {
                    this.generateExcelFile(data.rows.map(el => {
                        el['user id'] = el['User.id']
                        delete el['User.id']

                        el['user username'] = el['User.username']
                        delete el['User.username']

                        el['user lastname'] = el['User.last_name']
                        delete el['User.last_name']

                        el['user name'] = el['User.name']
                        delete el['User.name']

                        el['createdAt'] = el['createdAt'].replace('T', ' ').replace('.000Z', '')

                        el['transaction id'] = el['id']
                        delete el['id']

                        if(el['type'] == 'fee') {
                            el['sum'] = el['sum'] + ' (fee)'
                        }
                        delete el['type']

                        return el
                    }))
                    
                }
            })
        },
        FinanceMixin() {
            let page_id = (this.$route.query?.page == null || this.$route.query?.page == undefined) ? 
            1 : this.$route.query?.page
            let newDate = this.filterDate

            if(this.filterDate == '24 hour') {
                newDate = 'Day'
            }

            this.getDataMixin(page_id, newDate).then(data => {
                this.trans = data.rows
                this.lastpage = Math.ceil(data.count / 10)

                this.setLoader(false);
            })
        }
    },
    beforeMount() {
        this.FinanceMixin()
    }
}
</script>