<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <div class="flex">
                <h3 class="head-title">CHANGE PAYMENT<br/> INFORMATION</h3>
                <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
            </div>
            <vue-tel-input wrapperClasses="rg" 
            inputClasses="rtb" class="rg mt-2" 
            ref="inp" 
            style="border: 1px solid #252525; height: 47px;outline: none; box-shadow: none; background-color: #1E1E1E !important;"  
            v-model="formData.phone" :style="{'height': '62px'}" 
            validCharactersOnly="true" 
            @validate="phoneObject" 
            @country-changed="setCountryCode"
            :enabledCountryCode="true"
            :enabledFlags="false"
            mode="international"> 
                <template v-slot:arrow-icon>
                </template>
            </vue-tel-input>     
            <my-button label="Confirm" :active="isActiveBtn" @click="getData" class="mt-4"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'change-payment',
        data() {
            return {
                isActiveBtn: false,
                formData: {
                    phone: ''
                },
                isValidPhone: false,
                countryCode: ''
            }
        },
        watch: {
            'formData.phone': function() {
                if(this.formData.phone.length < this.countryCode.length) {
                    this.formData.phone = this.countryCode
                }
                this.isIsset()
            },
            countryCode() {
                this.phone = this.countryCode
               }
        },
        methods: {
            setCountryCode(val) {
                this.countryCode = "+" + val.dialCode
            },
            phoneObject(object) {
                this.isValid = object.valid;
                console.log(this.isValid)
            },
            close() {
                this.$emit('close')
            },
            getData() {
                this.$emit('getdata')
            },
            isIsset() {
                if(this.formData.phone != "" ) this.isActiveBtn = true
                else this.isActiveBtn = false
            }
        },
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
.close {
    position: absolute;
    margin-left: 94%;
    margin-top: -40px;
}
.inner-modal-div-form {
    background: #171717;
}
.inner-modal-div-form {
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #171717;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-left: 101%;
        position: absolute;
        margin-top: 0;
    }
}
</style>