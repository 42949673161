import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/style.css';
import components from '@/components/UI'
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/lara-light-indigo/theme.css";     
import "primevue/resources/primevue.min.css";
import Calendar from './components/calendar/Calendar.vue';
import {VueAvatar} from 'vue-avatar-editor-improved'
import { StreamBarcodeReader } from "vue-barcode-reader";
import Vue from 'vue'
import { QrcodeCapture } from 'vue-qrcode-reader'
import  VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css';
const app = createApp(App)

app.directive('click-outside', {
    mounted(el, binding, vnode) {
      el.clickOutsideEvent = function(event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    }
  });

router.beforeEach(async (to: any, from, next) => {    

   if(to.matched[0].meta?.isAuth == true) {
    if(to?.query?.token != undefined &&
        to?.query?.token != null &&
        to?.query?.token != '') {
            localStorage.setItem('auth-token', String(to.query.token))
        }
        if(localStorage.getItem('auth-token') == undefined || 
        localStorage.getItem('auth-token') == null) return next('/')

        let query = await fetch(window.origin + '/api/user/auth', {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
            }
        })

        if(query.status != 200) return next('/')

        let result = await query.json()

        if(to.matched[0].meta?.isWaiter == true || 
        to.matched[0].meta?.isAdmin == true) {

            if(to.matched[0].meta?.isWaiter == true) {
//                if(result.user.role != 'employee' || result.user.job != 'Waiter') return next('/')
                if(result.user.role != 'employee') return next('/')
            } else if(to.matched[0].meta?.isAdmin == true) {
                if(result.user.role != 'admin') return next('/')
            } else if(to.matched[0].meta?.isDev == true) {
                if(result.user.role != 'developer') return next('/')
            }
        }
        to.user = result.user
        next()
    } else if(to.matched[0].meta?.isAuth == false) {
        if(localStorage.getItem('auth-token') == undefined || 
        localStorage.getItem('auth-token') == null) return next()

        let query = await fetch(window.origin + '/api/user/auth', {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
            }
        })

        if(query.status != 200) return next()

        let result = await query.json()

        if(result.user.role == 'admin') return next('/dashboard')
        else return next('/employee/workspace')
    } else {
        next()
    }
    next()
})

router.beforeEach((to, from, next) => {    
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
})

//registr component
components.forEach(component => {
    app.component(component.name, component)
})
app
.use(PrimeVue)
.component('Calendar',Calendar)
.component('VueAvatar',VueAvatar)
.component('StreamBarcodeReader', StreamBarcodeReader)
.component('QrcodeCapture', QrcodeCapture)
.use(VueTelInput)
.use(router)
.use(store)
.mount('#app');
