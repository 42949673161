<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getCalendar" v-if="isActiveCalendar"/>
    <div class="container-dash average-bg-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'wrapper-average': true, 'calendar-active-cont': isActiveCalendar}">
            <div class="flex top-avarage-mobile">
                <h2 class="main-title-dash pointer" @click="$router.push('/dashboard')"><img src="../../assets/img/UI/left-strel.svg"> Business account balance</h2>
                <div class="min-panel-end flex panel-average" style="flex-wrap: wrap;">
                    <div class="panel-calendar-min ml-3" @click="setActiveCalendar" v-if="choiceDate == ''">
                        <img src="../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    <div class="panel-calendar-min mt-2-mobile" style="margin-left: 0" v-if="choiceDate != ''">
                        <div>
                            <span class="choiceDate" style="white-space: nowrap; margin-left: 0;">{{ choiceDate }}</span>
                            <img src="../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delCalendar">
                        </div>
                    </div>
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0), FinanceMixin('Day')">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1), FinanceMixin('Week')">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2), FinanceMixin('Month')">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3), FinanceMixin('Year')">Year</div>
                    </div>
                </div>
            </div>
            <div class="main-b-balance">
                <p>Balance</p>
                <h1>${{ Number(balance).toFixed(2) }}</h1>
                <div class="b-button">
                    <my-button active="true" label="Payout" @click="$router.push('/payout')"/>
                </div>
            </div>
            <div class="main-b-graph mt-3">
                <p class="total-graph main-text-color">Total: ${{ Number(balance).toFixed(2) }}</p>
                <div class="line-graph">
                    <div class="flex line-graph-l">
                        <div v-for="el in line_arr" class="l-1" @click="e => {showMoney(e)}">
                            <div class="height-panel-ert">{{el.sum}}$</div>
                            <div class="l-1-div" :style="{'height': el.heightEl + 'px'}"></div>
                            <p class="l-p">{{el.day}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-transactions mt-3">
                <user-transaction 
                    v-for="el in trans" 
                    :id="zeroPad(el.id)" 
                    :date="String(new Date(el.createdAt).getDate()) + '.' + (new Date(el.createdAt).getMonth() + 1) + '.' + String(new Date(el.createdAt).getFullYear())" 
                    :time="el.time" :count="el.sum" class="mt-1" 
                    style="width: 100%"
                    :type="el.type"
                    :additionalInfo="el.additional_info"
                />
                <p v-if="trans.length == 0" class="none-info">At the moment, we don’t have enough data to display the information on this page. Try again later when you get enough incoming transactions</p>
            </div>
            <paginate :count="Number(lastpage)" link="/dashboard/businessbalance"/>
        </div>

    </div>
</template>
    
<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import getFinanceMixin from '@/mixins/getFinanceMixin';
    import dateIconMixin from '@/mixins/dateIconMixin';
    import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'business account balance',
        mixins: [dashboardMixin, getFinanceMixin, dateIconMixin, loaderMixin],
        data() {
            return {
                trans: '',
                balance: '',
                lastpage: 0,
                line_arr: []
            }
        },
        watch: {
            '$route.query.page': function() {
                this.FinanceMixin()
            }
        },
        methods: {
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            getCalendar(value) {
                let date = (value == null || value == undefined) ? null : JSON.stringify({date: value})
                this.FinanceMixin(value)
                this.getDateIcon(value)
            },
            delCalendar() {
                this.FinanceMixin(null)
                this.delDateIcon()
            },
            showMoney(e) {
                if(e.target.classList[0] != 'l-1') {
                    if(e.target.parentNode.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'none'
                    }
                } else {
                    if(e.target.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.querySelector('.height-panel-ert').style.display = 'none'
                    }
                }
            },
            getDayOfWeek(date, date_count) {
                if(date_count.view == 12) {
                    
                    let need_date = new Date()  
                    let current = new Date(need_date.getFullYear(), need_date.getMonth()-date_count.value, 1);
                    const month = current.toLocaleString('en-US', { month: 'short' });
                    return month[0];

                } else if(date_count.view == 4) {

                    const getDateForWeek = (countDayAgo) => {
                        const dateDaysAgo = new Date(Date.now() - countDayAgo * 24 * 60 * 60 * 1000)

                        let date = (dateDaysAgo.getDate() < 10) ? "0" + dateDaysAgo.getDate() : dateDaysAgo.getDate()
                        let month = (dateDaysAgo.getMonth() + 1 < 10) ? "0" + (dateDaysAgo.getMonth() + 1) : dateDaysAgo.getMonth() + 1

                        return date + '.' + month
                    }

                    const date_arr = {
                        '0': getDateForWeek(7) + " - " + getDateForWeek(0),
                        '1': getDateForWeek(15) + " - " + getDateForWeek(8),
                        '2': getDateForWeek(23) + " - " + getDateForWeek(16),
                        '3': getDateForWeek(30) + " - " + getDateForWeek(24)
                    }

                    return date_arr[String(date_count.value)]

                } else {
                    let current = date
                    let today = current.toLocaleDateString('en-US',{weekday: 'long'});

                    let today2 = new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(current);
                   
                    return today2[0]
                }
            },
            async FinanceMixin(date) {
                Array.prototype.max = function() {
                        return Math.max.apply(null, this);
                };

                
                let page_id = (this.$route.query.page == undefined) ? 1 : this.$route.query.page
                
                await this.getDataMixin(page_id, date).then(data => {
                    this.trans = data.rows
                    this.balance = data.balance
                    this.lastpage = Math.ceil(data.count / 10)
                    
                    let maxEl = Number(data.days.max())

                    this.line_arr = []


                    data.days.forEach((el, i) => {
                        let date = new Date()

                        let maxDay = data.days.length - 1

                        maxDay = maxDay - i 

                    
                        date.setDate(new Date().getDate() - maxDay)
                        
                        let days_data = {
                            sum: (el == null) ? 0 : Number(el).toFixed(0),
                            heightEl: (el == null) ? 0 : Number(el) * 80 / maxEl,
                            day: this.getDayOfWeek(date, {
                                view: data.days.length,
                                value: maxDay
                            })
                        }
                        this.line_arr.push(days_data)
                    })
                })

                this.setLoader(false);

            }
        },
        mounted() {
          
        },
        beforeMount() {
            this.FinanceMixin()
        }
    }
</script>