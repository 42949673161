export default {
    data() {
        return {
            choiceDate: ''
        }
    },
    methods: {
        getDateIcon(value) {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            let first = {
                month: monthNames[value[0].getMonth()],
                day: value[0].getDate(),
                year: value[0].getFullYear(),
            }
            let second = {
                month: monthNames[value[1].getMonth()],
                day: value[1].getDate(),
                year: value[1].getFullYear(),
            }
            this.choiceDate = first.month + " " + first.day + ", " + first.year + ' - ' + second.month + " " + second.day + ", " + second.year
            this.isActiveCalendar = !this.isActiveCalendar
        },
        delDateIcon() {
            this.choiceDate = ''
        }
    }
}