<template>
    <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
                    <h3>Your email</h3>
                    <my-input 
                        type="text" 
                        label="Email" 
                        class="mt-4" 
                        v-model="email"
                        @keydown="codeKeyDown"
                    />
                    <p class="error-input-text err-au" v-if="isActiveErr">{{ error }}</p> 
                    <p style="color: #F28E22; font-size: 14px; margin-top: 15px" class="pointer sign-click-a sern" @click="$router.push('/')">Sign in</p>
                    <div class="m-x-auto pointer" style="margin-top: 56px"><span style="color: #F28E22; font-size: 16px; font-family: 'Rubik-Medium';" v-if="false">Change for e-mail</span></div>
                    <my-button label="Change password" @click="sendData" :active="isActiveBtn" style="margin-top: 18px"/>
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
import configMixin from '@/mixins/configMixin';

    export default {
        name: 'forget email',
        mixins: [configMixin],
        data() {
            return {
                email: '',
                isActiveBtn: false,
                isActiveErr: false,
                error: ''
            }
        },
        watch: {
            email() {
                this.isIsset()
            }
        },
        methods: {
            isIsset() {
                const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            };
                if(this.email != "" && validateEmail(this.email)) this.isActiveBtn = true
                else this.isActiveBtn = false
            },
            sendData() {
                if(this.isActiveBtn) {

                    let body = {
                        email: this.email
                    }
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/user/forget', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }

                    return response().then(data => {
                        if(data?.error != undefined) {
                            this.isActiveErr = true
                            this.error = data.error
                        } else {
                            localStorage.setItem('email-forget', this.email)
                            this.$router.push('/recovery')
                        }
                    })
                }
            },
            codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.isActiveBtn) {
                        this.sendData();
                    }
                }
            }
        }
    }
</script>