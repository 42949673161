<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getDateCalendar" v-if="isActiveCalendar"/>
    <div class="container-dash average-bg-mobile">
        <employee-dashboard v-if="$route.user.role != 'admin'"/>
        <dashboard-nav v-else/>
        <div :class="{'wrapper-dash': true, 'wrapper-average': true, 'calendar-active-cont': isActiveCalendar}">
            <div class="flex top-avarage-mobile">
                <h2 class="main-title-dash pointer" @click="$router.go(-1)"><img src="../assets/img/UI/left-strel.svg"> Get your NFC card</h2>
            </div>
            <div class="min-panel-end flex panel-average" style="margin-top: 100px">
                <p  class="none-info" style="width: 100%">This opportunity coming soon, we still working on it</p>
            </div>
        </div>

    </div>
</template>
    
<script>

    export default {
        name: 'get nfc',
        data() {
            return {
                day: [],
            }
        },
        beforeMount() {
            console.log(this.$route.user)
        },
        
    }
</script>