<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <div class="flex">
                <h3 class="head-title">BANK CARD</h3>
                <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
            </div>
            <div class="card-template mt-4"  style="background-color: #252525; margin-left: auto; margin-right: auto;">
                        <my-input type="card" bgColor="#252525" label="" placeholder="0000 0000 0000 0000 0000" style="border: 1px solid #343434; border-radius: 10px;" maxlength="19" v-model="formData.number" height="46px" class="mt-2"/>
                        
                        <div class="flex">
                            <span class="expo-date-card">
                                <input v-model="formData.date.day" style="background-color: #252525;"  maxlength="2" placeholder="00"/>
                                <span>/</span>
                                <input v-model="formData.date.month" style="background-color: #252525;" maxlength="2" ref="month" placeholder="00"/>
                            </span>
                            <my-input type="number" placeholder="000" bgColor="#252525" label="" style="border: 1px solid #343434; border-radius: 10px; width: 59px; margin-left: 20px; font-size: 30px;" height="46px" maxlength="3" v-model="formData.cvv" class="mt-2"/>
                            <my-input type="number" placeholder="00000" bgColor="#252525" label="" style="border: 1px solid #343434; border-radius: 10px; width: 81px; margin-left: 47px;" maxlength="5" height="46px" v-model="formData.code" class="mt-2"/>
                        </div>
                    </div> 
                <button class="border-grey mt-2" style="height: 48px; width: 320px; border: 1px solid #343434">Change card</button>       
                <p class="desc-mod mt-4" style="color: rgba(171, 171, 171, 1)">Link your debit card or a bank account <br>to withdraw your funds directly</p>
                <my-button label="Save" :active="isActive" @click="getData" class="mt-4"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'credit-card',
        data() {
          return {
           isActive: false,
           formData: {
              number: '',
              date: {
                day: '',
                month: ''
              },
              cvv: '',
              code: ''
           },
           valid: false
          }
       },
       watch: {
        'formData.number': function() {
            this.isIsset();
        },
        'formData.date.day': function() {
            console.log(this.formData.date.day)
            this.formData.date.day = this.formData.date.day.replace(/[^\d]/g,'');
            if(this.formData.date.day.length == 2) this.$refs.month.focus()
            this.isIsset();
        },
        'formData.date.month': function() {
            console.log(this.formData.date.month)
            this.formData.date.month = this.formData.date.month.replace(/[^\d]/g,'');
            this.isIsset();
        },
        'formData.cvv': function() {
            this.isIsset()
        },
        'formData.code': function() {
            this.isIsset()
        }
       },
       methods: {
           setRepeatPassword(value) {
               this.formData.repeatPassword = value
               this.isIsset()
           },
           isIsset() {
            if(this.formData.number.length == 19 && 
            this.formData.date.day.length == 2 &&
            this.formData.date.month.length == 2 &&
            this.formData.cvv.length == 3 &&
            this.formData.code.length == 5) this.isActive = true
            else this.isActive = false
           },
           getData() {
               if(this.isActive) this.$emit('getdata')
           },
           close() {
            this.$emit('close')
           }
       },
    
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 15;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
.close {
    position: absolute;
    margin-left: 94%;
    margin-top: -40px;
}
.inner-modal-div-form {
    background: #1E1E1E;
}
.inner-modal-div-form {
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #1E1E1E;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        left: 0;
        right: 0;
        min-height: 100vh;
        padding: 0;
        padding-top: 50px;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-left: 80%;
        position: absolute;
        margin-top: 20px;
    }
}
</style>