export default {
    methods: {
        setValue(value, e, id, ref, changeValuesFunc) {
            this.$refs[ref].insertBefore(e.target, this.$refs[ref].firstChild)
            this.value = value
            this.id = id
            changeValuesFunc(value)
        },
        setClassSelect(ref) {
            
            this.$refs[ref].classList.toggle('closed');
            this.$refs[ref].style.marginBottom = '-' + String(this.$refs[ref].offsetHeight - 500) + 'px'
        },
    }
}