<template>
 <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form inner-modal-div-form-dop">
                <div class="wrapper-inner-modal"> 
                <div class="HeadStroke_father">
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke grey"></div>
                </div>
                    <h3 class="mt-4">YOUR PERSONAL<br>QR-CODE & LINK</h3>

                    <div class="warning-info">
                        <p>Congrats! This is your QR code and public link for customers. It will be active after your account verification. 
                        Feel free to save it and proceed to the next step when ready.</p>
                    </div>

                <div class="FatherQRcode mt-4">
                    <div><img src="../assets/img/UI/ReloadQRcode.png" @click="updateQr"></div>
                    <img :src="qr_code" class="qr-img" style="width: 132px; margin-left: 10px;">
                    <div class="ml-1"><img src="../assets/img/UI/DownloadQRcode.png" @click="downloadImage"></div>
                </div>
                <h3 class="mt-2">{{ name }}</h3>
                <p class="Logo-text mt-1">{{ job }}</p>
                <div>
                    <my-input label="Your personal link" @keydown="(e) => e.preventDefault()" valid="true" v-model="text" class="mt-4"/>

                    <p class="Logo-text mt-4" style="font-size: 11px">
                        *This is you personal code, feel free to save it. 
                        You will be able to access and share it through 
                        your personal page at any time
                    </p>

                    <my-button label="Next" :active="isActive" @click="sendData" class="mt-4"/>
                </div>
                   
                    </div>
                    
                    
            </div>
        </div>
    </div>
    
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import qrRegistrData from '@/mixins/qrRegistrData'
import refreshQr from '@/mixins/refreshQr'
let QRCode = require('qrcode')

export default {
    name: 'FillingBusiness3',
    mixins: [qrRegistrData, refreshQr],
    data() {
        return {
            text: 'https://merciq.app/@login/',
            inviteCode: '',
            isActive: true,
            qr_code: '',
            name: '',
            job: '',
        }
    },
    methods: {
        setInviteCode(value) {
            this.inviteCode = value
            if(value != "") this.isActive = true
            else this.isActive = false
        },
        sendData() {
            this.$router.push("/FillingBusiness5")
        },
        updateQr() {
            this.refreshQr().then(data => {
                QRCode.toDataURL(window.origin + '/client/employee/' + data.qr, (err, url) => {
                    this.qr_code = url
                })
            })
        },
        async downloadImage() {
            const blob = await (await fetch(this.qr_code)).blob();
            const url = URL.createObjectURL(blob);
            Object.assign(document.createElement('a'), { href: url, download: 'qr.png' })
                .click();
            URL.revokeObjectURL(url);
        }
    },
    beforeMount() {
        localStorage.removeItem('userData.phone')
        localStorage.removeItem('userData.password')
        localStorage.removeItem('userData.username')
        localStorage.removeItem('establishment.typeBusiness')
        localStorage.removeItem('establishment.nameOrganization')
        localStorage.removeItem('adress.adress')
        localStorage.removeItem('adress.city')
        localStorage.removeItem('adress.state')
        localStorage.removeItem('establishment.zipCode')
        localStorage.removeItem('CEO.name')
        localStorage.removeItem('CEO.occupation')
        localStorage.removeItem('CEO.phone')
        localStorage.removeItem('CEO.ceo_email')
        localStorage.removeItem('public.desc')
        localStorage.removeItem('public.phone')
        localStorage.removeItem('public.website')
        localStorage.removeItem('public.website')
        localStorage.removeItem('public.website')
        localStorage.removeItem('google_id')
        localStorage.removeItem('facebook_id')
    }
}
</script>