<template>
    <login-history v-if="isActiveLogin" @close="setActiveLogin" style="position: fixed; z-index: 9; margin-top: 30px"/>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'wrapper-set': true, 'calendar-active-cont': isActiveLogin}">
            <div class="flex">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/dashboard')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">Settings</h2>     
                <!-- <h2 class="main-title-dash">{{estaData.name}}</h2>      -->
            </div>
            <div class="setting-el flex mt-2 pointer" @click="$router.push('/settings/type')">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/pers.svg" alt="">
                <span>Edit my business account</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1 pointer" @click="$router.push('/settings/notifications')">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/notif.svg" alt="">
                <span>Notification</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1 pointer" @click="$router.push('/settings/security')">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/cir.svg" alt="">
                <span>Password and security</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1 pointer" @click="$router.push('/settings/establishments')">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/esta.svg" alt="" >
                <span>My establishments</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1 pointer" @click="$router.push('/settings/payment')">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/card.svg" alt="" >
                <span>Payment information</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <!-- <div class="setting-el flex mt-1 pointer" @click="$router.push('/settings/backup')">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/backup.svg" alt="" >
                <span>Backup code</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div> -->
            <div class="setting-el flex mt-4 pointer" @click="$router.push('/settings/support')">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/info.svg" alt="" >
                <span>Сontact support</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1 pointer" @click="$router.push('/settings/faq')">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/info.svg" alt="" >
                <span>FAQ & Help Center</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <a href="https://api.whatsapp.com/send/?phone=%2B393314657539&text&type=phone_number&app_absent=0"><div class="setting-el flex mt-1 pointer">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/WhatsApp.svg" alt="">
                <span>Chat Support (WhatsApp)</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div></a>
            <div class="setting-el flex mt-1 pointer" @click="$router.push('/settings/terms')">
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/agree.svg" alt="">
                <span>Legal Documents</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1 pointer" @click="setActiveLogin">
                <span style="margin-left: 0; color: #505050; font-family: 'Rubik-Medium';">IP</span>
                <span>Login history</span>
                <img @load="handleLoad" @error="handleLoad" src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <!-- <button class="profile-btn profile-btn-red mt-1" style="width: 100%;" @click="exit()">Log out</button> -->
        </div>
    </div>
</template>

<script>
    import LoginHistory from '@/components/block/LoginHistory.vue';
    import loaderMixin from '@/mixins/loaderMixin';

    // total images on page
    const totalImages = 20;

    export default {
    name: 'setting main',
    mixins: [loaderMixin],
    data() {
        return {
            isActiveLogin: false,
            imgLoaded: 0,            
        }
    },
    methods: {
        handleLoad: function() {
            this.imgLoaded++;
            console.log(this.imgLoaded);
            if (this.imgLoaded === totalImages) {
                this.setLoader(false);
            }
        },
        exit() {
            localStorage.removeItem('auth-token');
            this.$router.push('/');
        },
        setActiveLogin() {
            this.isActiveLogin = !this.isActiveLogin
        }
    },
    components: { LoginHistory }
}
</script>