<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getDateCalendar" v-if="isActiveCalendar"/>
    <div class="container-dash average-bg-mobile coll-1-e">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'wrapper-average': true, 'calendar-active-cont': isActiveCalendar}" class="cont-7 coll-1-e">
            <div class="flex top-avarage-mobile d-none-mobile">
                <h2 class="main-title-dash pointer flex"><img src="../../assets/img/UI/left-strel.svg"  @click="$router.push('/dashboard')"> 
                    <span class="main-title-dash pointer" v-if="!isChoiceOrd" @click="seIstOrd" style="margin-top: -3px;padding-top:  0;">{{ ChoiseOrd }}</span> 

                    <div v-if="isChoiceOrd">
                        <h2 class="main-title-dash pointer" @click="setOrd('Leader teams')" style="padding-top:  0;">Leader teams</h2>
                        <h2 class="main-title-dash pointer" @click="setOrd('Leader employees')" style="padding-top:  0; margin-top: 0;">Leader employees</h2>
                    </div> 
                    <img src="../../assets//img/UI/bot.svg" class="ml-1" v-if="!isChoiceOrd" @click="seIstOrd">
                </h2>
                <div class="flex mt-2">
                    <div :class="{customer: true, grey_block: !(rate == 'Customer ratings'), pointer: true}">
                    <p style="font-size: 14px; font-family: 'Rubik-Regular';" :class="{'active-customer': rate == 'Customer ratings'}" @click="setRate('Customer ratings')">Customer ratings</p>
                </div>
                <div :class="{customer: true, grey_block: !(rate == 'Volume of income'), pointer: true}">
                    <p style="font-size: 14px; font-family: 'Rubik-Regular';" :class="{'active-customer': rate == 'Volume of income'}" @click="setRate('Volume of income')">Volume of income</p>
                </div>
                </div>
                <!-- <div class="min-panel-end flex panel-average">
                    <div class="panel-calendar-min ml-3" @click="setActiveCalendar">
                        <img src="../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0)">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1)">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2)">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3)">Year</div>
                    </div>
                </div> -->
                
            </div>
            <div class="d-none-desc">
            <h2 class="main-title-dash pointer flex"><img src="../../assets/img/UI/left-strel.svg"  @click="$router.push('/dashboard')"> 
                <span class="main-title-dash pointer" v-if="!isChoiceOrd" @click="seIstOrd">{{ ChoiseOrd }}</span> 

                <div v-if="isChoiceOrd">
                    <h2 class="main-title-dash pointer" @click="setOrd('Leader teams')">Leader teams</h2>
                    <h2 class="main-title-dash pointer" @click="setOrd('Leader employees')">Leader employees</h2>
                </div> 
                <img src="../../assets//img/UI/bot.svg" class="ml-1" v-if="!isChoiceOrd" @click="seIstOrd">
            </h2>
            <div class="flex mt-3">
                 <div class="flex">
                    <div :class="{customer: true, grey_block: !(rate == 'Customer ratings'), pointer: true}">
                    <p style="font-size: 14px; font-family: 'Rubik-Regular';" :class="{'active-customer': rate == 'Customer ratings'}" @click="setRate('Customer ratings')">Customer ratings</p>
                </div>
                <div :class="{customer: true, grey_block: !(rate == 'Volume of income'), pointer: true}">
                    <p style="font-size: 14px; font-family: 'Rubik-Regular';" :class="{'active-customer': rate == 'Volume of income'}" @click="setRate('Volume of income')">Volume of income</p>
                </div>
                </div>
                <!-- <div class="min-panel-end flex panel-average">
                    <div class="panel-calendar-min ml-3" @click="setActiveCalendar">
                        <img src="../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0)">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1)">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2)">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3)">Year</div>
                    </div>
                </div> -->
                
            </div>
        </div>
            <div class="block_stroke mt-3">
                <div class="stroke" style="width: 100%"></div>
            </div>
            <div class="main_block mt-3">
                <div class="Position">Position</div>
                <div class="Team_name">Team name</div>
                <div class="Rate">Rate</div>
            </div>
            <div v-if="rate == 'Customer ratings' && ChoiseOrd == 'Leader teams'"><TeamRate :place='(Number(index) + 1) + ((thisId - 1) * 10)' 
            v-for="(el, index) in teams" 
            :team='el.name' 
            :rate='Number(el.rate).toFixed(1)'/>
</div>
            <div v-if="rate == 'Volume of income' && ChoiseOrd == 'Leader teams'">
                <TeamRate-1 :place='(Number(index) + 1) + ((thisId - 1) * 10)' 
            v-for="(el, index) in teams" 
            :team='el.name' 
            :rate='Number(el.sum).toFixed(2)'/>
        
            </div>
            
            <div v-if="rate == 'Customer ratings' && ChoiseOrd == 'Leader employees'">
            <TeamRate :place='(Number(index) + 1) + ((thisId - 1) * 10)' 
            v-for="(el, index) in teams" 
            :team='el.name + el.last_name' 
            :rate='Number(el.rate).toFixed(1)'/>
           
           
            <div v-if="rate == 'Volume of income' && ChoiseOrd == 'Leader employees'">
                <TeamRate-1 :place='(Number(index) + 1) + ((thisId - 1) * 10)' 
            v-for="(el, index) in teams" 
            :team='el.name + el.last_name' 
            :rate='Number(el.sum).toFixed(2)'/>
            </div>
            </div>
            <p v-if="teams.length == 0" class="none-info">At the moment, we don’t have enough data to display the information on this page. Try again later when you get enough incoming transactions</p>
            <!-- <TeamRate place='2' team='Team 2' rate='4.9'/>
            <TeamRate place='3' team='Team 3' rate='4.9'/>
            <TeamRate place='4' team='Team 4' rate='4.9'/>
            <TeamRate place='5' team='Team 5' rate='4.9'/>
            <TeamRate place='6' team='Team 6' rate='4.9'/>
            <TeamRate place='7' team='Team 7' rate='4.9'/>
            <TeamRate place='8' team='Team 8' rate='4.9'/>
            <TeamRate place='9' team='Team 9' rate='4.9'/>
            <TeamRate place='10' team='Team 10' rate='4.9'/>
            <TeamRate place='11' team='Team 11' rate='4.9'/> -->
            <paginate :count="Number(lastpage)" :link="'/dashboard/leaders'"/>
        </div>
        
    </div>
    
</template>
    
<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import getTeamsMixin from '@/mixins/getTeamsMixin';
    import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'leaders order',
        mixins: [dashboardMixin, getTeamsMixin, loaderMixin],
        data() {
            return {
                teams: [],
                lastpage:0,
                thisId: 1,
                rate: 'Customer ratings',
                isChoiceOrd: false,
                ChoiseOrd: 'Leader teams'
            }
        },
        watch: {
            ChoiseOrd() {
                if(this.rate == 'Customer ratings') {
                    this.getTeamsMixins('rate', this.ChoiseOrd).then(data => {
                    this.teams = data.rows
                    this.lastpage = Math.ceil(data.count / 10)
                    this.thisId = (this.$route.query.page == undefined || this.$route.query.page == null) ?
                    1 : Number(this.$route.query.page)
                })
                } else {
                    this.getTeamsMixins('sum', this.ChoiseOrd).then(data => {
                    this.teams = data.rows
                    this.lastpage = Math.ceil(data.count / 10)
                    this.thisId = (this.$route.query.page == undefined || this.$route.query.page == null) ?
                    1 : Number(this.$route.query.page)
                })
                }
            },
            rate() {
                if(this.rate == 'Customer ratings') {
                    this.getTeamsMixins('rate', this.ChoiseOrd).then(data => {
                    this.teams = data.rows
                    this.lastpage = Math.ceil(data.count / 10)
                    this.thisId = (this.$route.query.page == undefined || this.$route.query.page == null) ?
                    1 : Number(this.$route.query.page)
                })
                } else {
                    this.getTeamsMixins('sum', this.ChoiseOrd).then(data => {
                    this.teams = data.rows
                    this.lastpage = Math.ceil(data.count / 10)
                    this.thisId = (this.$route.query.page == undefined || this.$route.query.page == null) ?
                    1 : Number(this.$route.query.page)
                })
                }
            },
            '$route.query.page': function() {
                this.getTeamsMixins().then(data => {
                this.teams = data.rows
                this.lastpage = Math.ceil(data.count / 10)
                this.thisId = (this.$route.query.page == undefined || this.$route.query.page == null) ?
                1 : Number(this.$route.query.page)
            })
            }
        },
        methods: {
            seIstOrd() {
                this.isChoiceOrd = !this.isChoiceOrd
            },
            setOrd(value) {
                this.ChoiseOrd = value
                this.seIstOrd()
            },
            setRate(value) {
                this.rate = value
            }
        },
        mounted() {
            this.getTeamsMixins().then(data => {
                this.teams = data.rows
                this.lastpage = Math.ceil(data.count / 10)
                this.thisId = (this.$route.query.page == undefined || this.$route.query.page == null) ?
                1 : Number(this.$route.query.page)

                this.setLoader(false);
            })
        }

    }
</script>