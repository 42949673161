<template>
    <div class="container-dash absolute-100-mobile" style="height: auto;">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'calendar-active-cont': (isActiveRecovery || isActivePhone || isActiveEmail || isActivePassword), 'dop-wrpapper-setting': true}">
            <div class="flex">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/settings')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0;">User agreement</h2>     
            </div>
            <div class="main-staff setting-div bg-security tech-div">
                <div class="w-user-t">
                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed blandit libero volutpat sed cras ornare arcu dui. Tempus urna et pharetra pharetra massa massa ultricies mi quis. Id diam vel quam elementum pulvinar. Mauris ultrices eros in cursus turpis massa tincidunt dui ut. Duis ut diam quam nulla porttitor massa id neque aliquam. Ullamcorper morbi tincidunt ornare massa. Massa sed elementum tempus egestas. Eget duis at tellus at urna condimentum mattis pellentesque. Massa eget egestas purus viverra accumsan in nisl. Fringilla est ullamcorper eget nulla facilisi etiam. Id ornare arcu odio ut sem. Et magnis dis parturient montes nascetur ridiculus mus. Purus in mollis nunc sed id </p>
                    <p>semper risus in hendrerit. Dignissim diam quis enim lobortis scelerisque fermentum. Sed odio morbi quis commodo. Dictum fusce ut placerat orci.
In hac habitasse platea dictumst vestibulum rhoncus est. Nec feugiat nisl pretium fusce id velit. Nunc mattis enim ut tellus elementum sagittis vitae et. Neque volutpat ac tincidunt vitae semper quis lectus nulla. Sit amet porttitor eget dolor. </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'user agreement',
    }
</script>

