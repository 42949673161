import configMixin from './configMixin'

export default {
    mixins: [configMixin],
    methods: {
        getUserName(username) {
            
            let responseGet = async() => {
                let query = await fetch(this.config.API_URL + '/username/check/' + username, {
                    method: 'GET'
                })

                let result = await query.json()
                return Promise.resolve(result)
            }
            return responseGet()
        }
    }
}