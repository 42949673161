import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        sendMoney(sum, username_empl, username_esta, role, stars, comment, like_type, team_id, email) {
            let star_q = (stars === undefined || stars === 0) ? null : stars
            let comment_q = (comment == undefined) ? null : comment

            let time = new Date()

            let hours = (String(time.getHours()).length == 1) ? "0" + time.getHours() : time.getHours()
            let minutes = (String(time.getMinutes()).length == 1) ? "0" + time.getMinutes() : time.getMinutes()
            time = hours + ":" + minutes

            let jsonObject = {
                username_empl: username_empl,
                sum: Number(sum),
                time: time,
                username_esta: username_esta,
                comment: comment_q,
                stars: Number(star_q),
                role: role,
                like_type: like_type,
                team_id: (team_id == undefined) ? null : Number(team_id)
            }

            const validateEmail = (email) => {
                return String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            };

            if (email?.trim() != undefined && validateEmail(email)) {
                jsonObject.email = email
            }
            
            let response = async() => {

                let query = await fetch(this.config.API_URL + "/transaction/make", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(jsonObject)
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}