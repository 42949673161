<template>
    <modal-calendar  
    @closecalendar="setActiveCalendar" 
    @setcalendar="getDate" 
    v-if="isActiveCalendar"/>
    <div class="container-dash" >
        <employee-dashboard :demo="true"/>
        <div class="wrapper-dash main-mirciq workspace-wrapper-das min-height pb-4" :class="{'calendar-active-cont': isActiveCalendar}">
            <div class="workspace-wrapper"> 
                <div class="flex flex-between"><h2 class="main-title-dash pointer">Analytics</h2> <img v-if="choiceDate == ''" src="../../../assets/img/UI/calendar-2.svg" @click="setActiveCalendar" class="mt-2 pointer" style="margin-top: auto; margin-bottom: auto;">
                    <div v-if="choiceDate != ''" style="margin-top: 5px;">
                        <span class="choiceDate">{{ choiceDate }}</span>
                        <img src="../../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delDate">
                    </div>
                </div>   
                <div class="flex date-filter-empl mt-2 date-st">
                    <div class="el-date-filter-empl pointer" @click="setFilterDate('Day')" :class="{'el-date-filter-empl-active': date == 'Day'}">
                        Day
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="setFilterDate('Week')" :class="{'el-date-filter-empl-active': date == 'Week'}">
                        Last day
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="setFilterDate('Month')" :class="{'el-date-filter-empl-active': date == 'Month'}">
                        Month
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="setFilterDate('Year')" :class="{'el-date-filter-empl-active': date == 'Year'}">
                        Year
                    </div>
                </div>
                <div class="flex mt-2 column-mobile">
                    <div class="panel-balance-el pointer w-330 w-320-mob" @click="$router.push('/demo/finance')">
                            <p>Total earnings</p>
                            <p class="balance-el-t">${{ Number(dataUser.total).toFixed(2) }}</p>
                            <p class="balance-el-t-c">{{dataUser.count}} transactions</p>
                    </div>
                    <div class="flex mobile-panel-per">
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/demo/average')">
                            <div class="pie " style="--p:100">{{dataUser.stars}}</div>
                            <div class="pie pie-two" :style="{'--p': line_average}"><span>{{dataUser.stars}}</span></div>
                            <p class="mt-2">Average rating</p>
                        </div>
                        <div class="panel-stats-el ml-2 pointer" @click="$router.push('/demo/reviews')">
                            <div class="pie " style="--p:100">{{line_average}}%</div>
                            <div class="pie pie-two" :style="{'--p':line_average}"><span>{{line_average}}%</span></div>
                            <p class="mt-2">Happy clients</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="panel-balance-el ml-2 average-panel-empl pointer" @click="$router.push('/demo/average')">
                        <p>Average tip value</p>
                        <p class="average-sum">${{Number(dataUser.average_order).toFixed(2)}}</p>
                    </div>
                    <div class="panel-balance-el ml-2 average-panel-empl pointer" @click="$router.push('/demo/average')">
                        <p>Business account<br>balance</p>
                        <p class="average-sum">${{Number(dataUser.balance).toFixed(2)}}</p>
                    </div>
                    <!-- <div class="panel-balance-el ml-2 average-panel-empl">
                        <p>Transactions with tips</p>
                        <p class="average-sum">100%</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import configMixin from '@/mixins/configMixin';

    export default {
        name: 'statistic',
        mixins: [dashboardMixin, configMixin],
        data() {
            return {
               date: 'Year',
               choiceDate: '',
               dataUser: {
                    name: '',
                    avatar: '',
                    balance: '',
                    total: '',
                    average_order: '',
                    avarage_rating: '',
                    happy_clients: '',
                    stars: 0,
                },
                line_average: 0,
                transaction: []
            }
        },
        watch: {
            choiceDate() {
                if(this.choiceDate != '') {
                    this.date = ''
                }
            }
        },
        methods: {
            setFilterDate(value) {
                this.date = value
                this.choiceDate = ''
                // this.getData(value).then(data => {
                //     this.dataUser.balance = data.user.balance
                //     this.dataUser.total = (data.total == null) ? '0' : data.total
                //     this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                //     this.dataUser.avatar =  data.user.avatar
                //     this.dataUser.count =  (data.count == null) ? '0' : data.count
                //     this.dataUser.stars = (data.stars == null) ? '0' : Math.round(Number(data.stars))

                //     this.line_average = (data.stars == null) ? 0 : (Math.round(Number(data.stars)) * 100) / 5
                // })
            },
            getDate(value) {
                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                ];
                let first = {
                    month: monthNames[value[0].getMonth()],
                    day: value[0].getDate(),
                    year: value[0].getFullYear(),
                }
                let second = {
                    month: monthNames[value[1].getMonth()],
                    day: value[1].getDate(),
                    year: value[1].getFullYear(),
                }
                this.choiceDate = first.month + " " + first.day + ", " + first.year + ' - ' + second.month + " " + second.day + ", " + second.year
                this.isActiveCalendar = !this.isActiveCalendar
                // this.getData(value).then(data => {
                //     this.dataUser.balance = data.user.balance
                //     this.dataUser.total = (data.total == null) ? '0' : data.total
                //     this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                //     this.dataUser.avatar =  data.user.avatar
                //     this.dataUser.count =  (data.count == null) ? '0' : data.count
                //     this.dataUser.stars = (data.stars == null) ? '0' : Math.round(Number(data.stars))

                //     this.line_average = (data.stars == null) ? 0 : (Math.round(Number(data.stars)) * 100) / 5
                // })
            },
            delDate() {
                this.choiceDate = ''
                this.date = 'Year'
                // this.getData('Year').then(data => {
                //     this.dataUser.balance = data.user.balance
                //     this.dataUser.total = (data.total == null) ? '0' : data.total
                //     this.dataUser.average_order = (data.average_sum == null) ? '0' : Number(data.average_sum).toFixed(2)
                //     this.dataUser.avatar =  data.user.avatar
                //     this.dataUser.count =  (data.count == null) ? '0' : data.count
                //     this.dataUser.stars = (data.stars == null) ? '0' : Math.round(Number(data.stars))

                //     this.line_average = (data.stars == null) ? 0 : (Math.round(Number(data.stars)) * 100) / 5
                //     console.log(data.transaction)
                // })
            },
            
            getData(date) {

                let dateFilter = (date == null || date == undefined || date == '') ? '' : '?date=' + date
                if(date != "Day" && date != 'Week' && date != 'Month' && date != 'Year' && date != null && date != undefined) {
                    dateFilter = (date == null || date == undefined) ? '' : '?date=' + JSON.stringify({date: date})
                }
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/employee/main' + dateFilter, {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            }
        },
        beforeMount() {
           
                this.dataUser.balance = 190
                this.dataUser.total = 190
                this.dataUser.average_order = 90
               
                this.dataUser.count =  7
                this.dataUser.stars = 90

                this.line_average = 90
               
            
        }
    }
</script>