<template>
    <div class="cale">
        <div class="flex">
            <h3 class="head-title">{{ (title == undefined || title == null) ? 'Delete establishment' : title }}</h3>
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
        </div>
        <p>Are you sure?</p>
        <div class="flex flex-between mt-4 column-r-s">
            <div class="btn-delte-est">
                <my-button label="Yes" active="true" @click="deletes"/>
            </div>
            <button class="btn-sum" @click="close">No</button>
        </div>
    </div>
</template>
    
<script>
    export default {
        name: 'are-sure',
        data() {
            return {
                
            }
        },
        props: {
            id: String,
            title: String
        },
        methods: {
            close() {
               this.$emit('close')
            },
            deletes() {
                this.$emit('delete', this.id)
            },
        }
    }
</script>
    
<style scoped>
.btn-delte-est {
    width: 219px;
}
.btn-sum {
    width: 219px;
    border: 1px solid #424242;
    border-radius: 200px;
    background: none;
    color: white;
    font-size: 16px;
    font-family: 'Rubik-Medium';

}
p {
    color: #ABABAB;
    width: 373px;
    margin-top: 20px;
}
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
}
.cale {
    position: absolute;
    top: 200px;
    left: calc(60% - 408px);
    z-index: 3;
    opacity: 1;
    width: 540px;
    padding: 40px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .cale {
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .cale p {
        width: 290px;
        text-align: center;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .column-r-s {
        flex-direction: column;
    }
    .btn-sum {
        height: 48px;
        width: 271px;
        margin-top: 20px;
    }
    .btn-delte-est {
        width: 271px;
    }
}
</style>