<template>
     <button class="btn">{{ label }}</button>
</template>

<script>
export default {
  name: 'change-button',
  data() {
	return {
		MyValue: ''
	}
  },
  props: {
    label: String,
  }
}
</script>

<style scoped>
.btn {
    width: 100%;
    border-radius: 200px;
    background-color: var(--child-bg-color);
    color: var(--main-text-color);
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 48px;
    font-size: 16px;
}
</style>
