<template>
    <div class="container-dash absolute-100-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true}">
            <div class="flex">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/settings')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">Business account</h2>     
            </div>
            <div class="main-staff setting-div bg-setting">
                <div class="main-nav-mobile d-none-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex min-panel-nav-setting">
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/settings/public')">Public information</h2>
                        <h2 :class="{ active_nav_panel: false}" @click="$router.push('/settings/type')"  style="margin-left: 20px;">Type of your Business</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': false}" @click="$router.push('/settings/personal')">Personal information</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: true}" @click="$router.push('/settings/financial')">Financial model</h2>
                    </div>
                    <div class="main-line-menu min-panel-nav-setting">
                    </div>
                </div>
               <div class="top-bar-staff  d-none-desc">
                <div class="flex">
                    <div class="second-staff-nav" style="margin-left: 0;">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/public')">Public information</p>
                    </div> 
                    <div class="first-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/type')" style="margin-left: 20px;">Type of your Business</p>
                    </div>
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/settings/personal')">Personal information</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true}" @click="$router.push('/settings/financial')">Financial model</p>
                    </div> 
                </div>
                <div class="border-staff flex border-set">
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'set-thre-nav': true}" style="margin-left: -10px;"></div>
                    <div :class="{'active-first': true, 'active-nav-staff': false,'set-staff-nav': true}" style="margin-left: 120px;"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'set-sec-nav': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': true, 'set-fo-nav': true}"></div>
                </div>
               </div>
               <div class="div-height-nulls ss-div-mob"></div>
                <div class="name_form_setting mt-2 w-320-mob ml-o-mobile">
                    <div class="flex">
                        <span class="sett-check-fin">Set a service fee</span>
                        <label class="toggle">
                            <input class="toggle-checkbox" type="checkbox" :checked="dataForm.fee" @click="setFee">
                            <div class="toggle-switch"></div>
                        </label>
                    </div>
                    <my-input v-if="dataForm.fee" bgColor="#252525" v-model="dataForm.service_fee" type="number" @keyup="e => {validProcent(e)}" :maxlength="3" label="fee, %" width="320px" style="width: 320px; margin-top: 40px"/>
                    <div class="panel-setting-fin-el mt-4" @click="setDist('Direct distribution')">
                        <div class="flex">
                            <p class="panel-setting-fin-el-title">Direct distribution</p>
                            <div class="form_radio_b form-setting">
                                <input id="radio-1" type="radio" name="radio" value="1" :checked="dataForm.dist == 'Direct distribution'">
                                <label for="radio-1"></label>
                            </div>
                        </div>
                        <p class="panel-setting-fin-el-desc">In this case, tips are credited directly from your customers to your employees, and you can monitor the rating and analytics.</p>
                    </div>
                    <div class="panel-setting-fin-el mt-2 mt-1-mobile" @click="setDist('Manual distribution')">
                        <div class="flex">
                            <p class="panel-setting-fin-el-title">Manual distribution</p>
                            <div class="form_radio_b form-setting">
                                <input id="radio-2" type="radio" name="radio" value="1" :checked="dataForm.dist == 'Manual distribution'">
                                <label for="radio-2"></label>
                            </div>
                        </div>
                        <p class="panel-setting-fin-el-desc">All receipts from customers are credited to 
a single balance of your business account. Employees do not have access to earned income, but they see transaction history 
and reviews about themselves.</p>
                    </div>
                    <div style="margin-top: 20px;">
                        <my-button label="Update" :active="isActive" @click="updateData" style="width: 320px;"/>
                    </div> 
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
    import configMixin from '@/mixins/configMixin'
    import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'financial model',
        mixins: [configMixin, loaderMixin],
        data() {
            return {
                dataForm: {
                    fee: Boolean,
                    service_fee: '0',
                    dist: ''
                },
                index: 0,
                isActive: false
            }
        },
        methods: {
            setFee() {
                this.dataForm.fee = !this.dataForm.fee;
                this.isActive = true;
            },
            getBusinessData() {
                let response = async() => {

                    let query = await fetch(this.config.API_URL + '/business/data', {
                        method: "GET",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            },
            setDist(value) {
                this.dataForm.dist = value
                this.isActive = true;          
            },
            updateData() {

                let body = {
                    distribution: this.dataForm.dist,
                    skip_email: true
                }

                if(this.dataForm.fee == true && Number(this.dataForm.service_fee) != 0) body.service_fee = Number(this.dataForm.service_fee)
                else body.service_fee = null

                let response = async() => {

                    let query = await fetch(this.config.API_URL + '/business', {
                        method: "PUT",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }
//                response()
                response().then(data => {
                        this.$router.go()
                    })
            },
            validProcent(e) {
                if(Number(e.target.value) > 100 || String(e.target.value).length > 3) e.preventDefault()
                if(Number(e.target.value) > 100) {
                    e.target.value = e.target.value.substring(0, 2)
                }
                this.dataForm.service_fee =  e.target.value
               
                if(this.checks) {
                    if(String(this.dataForm.service_fee).length == 0) {
                        this.isActive = false
                    }else {
                        this.isActive = true
                    }
                } else {
                    this.isActive = true
                }
//                this.updateData()
        },
            
        },
        beforeMount() {
            this.getBusinessData().then(data => {
                if(data.data.service_fee != null) {
                    this.dataForm.fee = true
                    this.dataForm.service_fee = data.data.service_fee
                } else {
                    this.dataForm.fee = false
                }
                this.dataForm.dist = data.data.distribution
                this.index += 1

                this.setLoader(false);
            })
        },
        /*
        watch: {
            'dataForm.dist': function() {
                this.updateData()
            },
            'dataForm.fee': function() {
                if(this.dataForm.fee == false) {
                    this.updateData()
                }               
            },            
        },
        */
    }
</script>