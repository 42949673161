<template>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'dop-wrpapper-setting': true}">
            <div class="flex">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.go(-1)">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0;">My establishments</h2>     
            </div>
            <div id="learning-establishment-all" class="main-staff setting-div bg-security bg-esta">
                <my-button  v-if="this.$route.user.job != 'Manager'" style="border-radius: 10px; display: flex; justify-content: center;align-items: center;background-color: rgba(242, 142, 34, 0.1);" @click="$router.push('/establishment/add')">
                        <img src="../../../assets/img/UI/plus-staff.svg">
                        <p class="ml-2 main-text-color" style="font-family: 'Rubik-Regular';">Add new establishment</p>
                </my-button>
                <div class="bg-set-esta-s mt-2" v-for="el in esta">
                    <div class="setting-el flex mt-1 bg-esta-setting" @click="$router.push('/establishment/team/' + el.id)">
                        <img src="../../../assets/img/UI/esta.svg" alt="">
                        <span>{{ el.name }}</span>
                        <img src="../../../assets/img/UI/setting-line.svg" class="line-setting pointer">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import configMixin from '@/mixins/configMixin';
    import loaderMixin from '@/mixins/loaderMixin';

    import TgInstance from '@/components/instance/tourguide';

    export default {
        name: 'my establishments',
        mixins: [configMixin, loaderMixin],
        data() {
            return {
                esta: [],
            }
        },
        methods: {
            getEstablishments() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/team/establishment/all', {
                        method: "GET",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    this.esta = data.rows

                    this.setLoader(false);
                })
            }
        },
        beforeMount() {
            console.log(this.$route.user.job)
            this.getEstablishments()
        },
        async created() {
            const tgInstance = TgInstance.instance;
            if (tgInstance) {
                console.log ('TgInstance.instance is set');

                const groupName = tgInstance.groupName;
                if (!tgInstance.tg.isFinished(groupName))
                    tgInstance.tg.visitStep(10);
            }
        }
    }
</script>

