<template>
    <div class="outer-modal-div-form">
           <div class="middle-modal-div-form">
               <div class="inner-modal-div-form">
                       <h3>PASSWORD<br> RECOVERY</h3>
                       <p class="number-desc mt-2">code was sent to the email</p>
                       <p class="orange-color">{{ email }}</p>
                       <div class="flex el-sms-div">
                        <div class="el-sms-num">
                                <input 
                                    type="number"  
                                    class="mt-4 inputText" 
                                    ref="v1" @click="setTest" 
                                    @input=" e => {setCode(e.target.value, 0)}" 
                                    @paste="codePaste"
                                    max="1"
                                />
                            </div>
                            <div class="el-sms-num">
                                <input 
                                    type="number" 
                                    class="mt-4 inputText" 
                                    ref="v2" 
                                    id="v2" 
                                    @input=" e => {setCode(e.target.value, 1)}" 
                                    @paste="codePaste"
                                    @keydown="e => codeKeyPress(e, 1)"
                                    max="1"
                                />
                            </div>
                            <div class="el-sms-num">
                                <input 
                                    type="number" 
                                    class="mt-4 inputText" 
                                    ref="v3" 
                                    @input=" e => {setCode(e.target.value, 2)}" 
                                    @paste="codePaste"
                                    @keydown="e => codeKeyPress(e, 2)"
                                    max="1"
                                />
                            </div>
                            <div class="el-sms-num">
                                <input 
                                    type="number" 
                                    class="mt-4 inputText" 
                                    ref="v4" 
                                    @input=" e => {setCode(e.target.value, 3)}" 
                                    @paste="codePaste"
                                    @keydown="e => codeKeyPress(e, 3)"
                                    max="1"
                                />
                            </div>
                       </div>
                       <p class="error-input-text err-au" v-if="isActiveCode">Incorrect code</p> 
                       <div class="flex flex-center mt-3"><p class="orange-color pointer" v-if="timer.minute == 0 && timer.seconds == 0" @click="sendMail">Resend</p><p class="other-text-color" v-if="timer.minute != 0 || timer.seconds != 0">Resend after</p><p class="main-text-color ml-1" v-if="timer.minute != 0 || timer.seconds != 0">{{ timer.minute }}:{{ (String(timer.seconds).length < 2) ? "0" + timer.seconds : timer.seconds}}</p></div>
                       <my-button label="Next" @click="get" :active="isActive" class="mt-4"/>
                       <div class="flex mt-3">
                           <div class="form_radio">
                               <input id="radio-1" type="radio" @click="setIsCheck" name="radio" value="1" ref="check" v-model="isCheck" checked>
                               <label for="radio-1"></label>
                           </div>
                           <p class="radio-text main-text-color">Allow MerciQ to send me important system notifications and marketing messages.</p>
                       </div>
                       
               </div>
           </div>
       </div>
       <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
   </template>
   
   <script>
   import { mapState, mapGetters, mapMutations } from 'vuex'
   import configMixin from '@/mixins/configMixin'
   export default {
       name: 'recovery password',
       mixins: [configMixin],
       data() {
           return {
               timer: {minute: 1, seconds: 30},
               isResend: false,
               isActive: false,
               isCheck: false,
               code: ['', '', '', ''],
               email: localStorage.getItem('email-forget'),
               isActiveCode: false
           }
       },
       methods: {
           sendData() {
               if(this.isActive) this.$router.push("/newpassword")
           },
           setCode(value, num) {

                console.log('setCode', value);

                if (value[String(value).length - 1] === undefined)
                    return;

                this.$refs['v' + String(num + 1)].value = value[String(value).length - 1]

                console.log('this.code',this.code);

                    if(value != '' && value != ' ') {
                        if(this.code[num] != "") {
                            if(value == "") this.code[num] = ''
                        } else {
                            this.code[num] = value
                        }
                        this.isActive = !this.code.includes("")
                        if(!this.code.includes("") ) this.isActive = true
                        else this.isActive = false

                        if(this.code[2].length != 0) {
                            this.$refs.v4.focus()
                        }else if(this.code[1].length != 0) {

                            this.$refs.v3.focus()
                        } else if(this.code[0].length != 0) {
                            this.$refs.v2.focus()
                        } 
                    } else {
                        const setType = (num) => {
                            console.log('setType', num)
                            this.$refs['v' + num].focus()
                            this.$refs['v' + num].setAttribute('type', 'text')
                            console.log(this.$refs['v' + num].getAttribute('type'))
                            this.$refs['v' + num ].selectionStart =  this.$refs['v' + num].value.length
                        this.$refs['v' + num ].setAttribute('type', 'number')
                        
                    }

                        this.code[num] = ''
                        if(num == 1) {
                            setType(1)
                        
                        } else if(num == 2) {
                            setType(2)
                            
                        } else if(num == 3) {
                            setType(3)
                            
                        }
                    }

                    console.log('this.code',this.code);

                },
                codeKeyPress(event, num) {
                    // backspace pressed
                    if (event.keyCode === 8) {
                        console.log('codeKeyPress num', num);
                        const fieldValue = this.$refs['v' + String(num + 1)].value;
                        console.log('fieldValue', fieldValue);
                        if (fieldValue.length === 0) {
                            this.$refs['v' + String(num)].focus();
                            this.code[num - 1] = '';
                        }
                    } else if (event.keyCode === 13) {
                        if (this.isActive) {
                            this.get();
                        }
                    }
                },
                codePaste(event) {
                    console.log('on paste', event.clipboardData.getData('text'));

                    const data = event.clipboardData.getData('text')
                    const matched = String(data).match(/^\d+$/)
                    if (matched) {
                        this.$refs.v1.value = data[0];
                        this.code[0] = data[0];
                        this.$refs.v2.value = data[1];
                        this.code[1] = data[1];
                        this.$refs.v3.value = data[2];
                        this.code[2] = data[2];
                        this.$refs.v4.value = data[3];
                        this.code[3] = data[3];
                        
                        this.$refs.v4.focus();

                        this.isActive = true;
                    }

                    event.preventDefault();
                },
           setIsCheck() {
            if(this.isCheck == '1') this.isCheck = ''
        },
        sendMail() {
            this.timer.minute = 1
            this.timer.seconds = 30
            let timerId = setInterval(() => {
               this.timer.seconds--
               if(this.timer.seconds == -1 && this.timer.minute == 1) {
                   this.timer.minute = 0 
                   this.timer.seconds = 59
               }
               if(this.timer.seconds == 0 && this.timer.minute == 0) clearInterval(timerId)
           }, 1000)
            let body = {
                        email: localStorage.getItem('email-forget')
                    }
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/user/forget', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }

                    return response()
            },
            get() {
                let func = async () => {
                    const code = this.$refs['v1'].value + this.$refs['v2'].value + this.$refs['v3'].value + this.$refs['v4'].value;
                        let jsonObject = {
                            type: 'email',
                            object: localStorage.getItem('email-forget'),
                            code: code
                        }
                    let response = await fetch(this.config.API_URL + '/code/' + this.email, {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        method: 'POST',
                        body: JSON.stringify(jsonObject)
                    })

                    let codes = await response.json()
                    return Promise.resolve(codes)
                }
                return func().then(data => {
                    if(data?.ok != true) {
                        this.isActiveCode = true
                    } else {
                        const code = this.$refs['v1'].value + this.$refs['v2'].value + this.$refs['v3'].value + this.$refs['v4'].value;
                        localStorage.setItem('verif-code', code)
                        this.$router.push('/newpassword')
                    }
                })
            }
   },
   mounted() {
           let timerId = setInterval(() => {
               this.timer.seconds--
               if(this.timer.seconds == -1 && this.timer.minute == 1) {
                   this.timer.minute = 0 
                   this.timer.seconds = 59
               }
               if(this.timer.seconds == 0 && this.timer.minute == 0) clearInterval(timerId)
           }, 1000)
       },
   beforeMount() {
    if(localStorage.getItem('email-forget') == undefined) return this.$router.push('/')
   }
}
   </script>