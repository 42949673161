<template>
 <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
                    <h3>SMS<br> CONFIRMATION</h3>
                    <p class="number-desc mt-2">code was sent to the number</p>
                    <p class="orange-color">{{ localStorage.getItem('userData.phone') }}</p>
                    <div class="flex el-sms-div">
                        <div class="el-sms-num"><input type="number"  class="mt-4 inputText" ref="v1" @click="setTest" @input=" e => {setCode(e.target.value, 0)}" min="5"/></div>
                           <div class="el-sms-num"><input type="number" class="mt-4 inputText" ref="v2" id="v2" @input=" e => {setCode(e.target.value, 1)}" max="1"/></div>
                           <div class="el-sms-num"><input type="number" class="mt-4 inputText" ref="v3" @input=" e => {setCode(e.target.value, 2)}" max="1"/></div>
                           <div class="el-sms-num"><input type="number" class="mt-4 inputText" ref="v4" @input=" e => {setCode(e.target.value, 3)}" max="1"/></div>
                       </div>
                    <div class="flex flex-center mt-3"><p class="orange-color pointer" v-if="timer.minute == 0 && timer.seconds == 0">Resend</p><p class="other-text-color" v-if="timer.minute != 0 || timer.seconds != 0">Resend after</p><p class="main-text-color ml-1" v-if="timer.minute != 0 || timer.seconds != 0">{{ timer.minute }}:{{ (String(timer.seconds).length < 2) ? "0" + timer.seconds : timer.seconds}}</p></div>
                    <my-button label="Registration" @click="sendData" :active="isActive" class="mt-4"/>
                    <div class="flex mt-3">
                        <div class="form_radio">
                            <input id="radio-1" type="radio" @click="setIsCheck" name="radio" value="1"  v-model="isCheck" checked>
                            <label for="radio-1"></label>
                        </div>
                        <p class="radio-text main-text-color">Allow MerciQ to send me important system notifications and marketing messages.</p>
                    </div>
                    <span class="flex mt-6 flex-center">
                        <p class="main-text-color">No phone access?</p>
                        <RouterLink to="/signupshort"><p class="orange-color ml-2">Change</p></RouterLink>
                    </span>
            </div>
        </div>
    </div>
    <h1 class="title-logo-bottom">MERCI<span class="h orange-color q-logo">Q</span></h1>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'sms confirmation empl',
    data() {
        return {
            timer: {minute: 1, seconds: 30},
            isResend: false,
            isActive: false,
            isCheck: false,
            code: ['', '', '', ''],
            test: false
        }
    },
    methods: {
        setTest() {
            this.test = !this.test
        },
        sendData() {
            if(this.isActive) this.$router.push("/employee/username")
        },
        setCode(value, num) {
            this.$refs['v' + String(num + 1)].value = value[String(value).length - 1]
            
                if(value != '' && value != ' ') {
                    if(this.code[num] != "") {
                        if(value == "") this.code[num] = ''
                    } else {
                        this.code[num] = value
                    }
                    this.isActive = !this.code.includes("")
                    if(!this.code.includes("") ) this.isActive = true
                    else this.isActive = false

                    if(this.code[2].length != 0) {
                        this.$refs.v4.focus()
                    }else if(this.code[1].length != 0) {
    
                        this.$refs.v3.focus()
                    } else if(this.code[0].length != 0) {
                        this.$refs.v2.focus()
                    } 
                } else {
                    const setType = (num) => {
                        console.log(num)
                        this.$refs['v' + num].focus()
                        this.$refs['v' + num].setAttribute('type', 'text')
                        console.log(this.$refs['v' + num].getAttribute('type'))
                        this.$refs['v' + num ].selectionStart =  this.$refs['v' + num].value.length
                       this.$refs['v' + num ].setAttribute('type', 'number')
                    
                }

                    this.code[num] = ''
                    if(num == 1) {
                        setType(1)
                       
                    } else if(num == 2) {
                        setType(2)
                        
                    } else if(num == 3) {
                        setType(3)
                        
                    }
                }
           },
        setIsCheck() {
            if(this.isCheck == '1') this.isCheck = ''
        },

    },
    mounted() {
        let timerId = setInterval(() => {
            this.timer.seconds--
            if(this.timer.seconds == -1 && this.timer.minute == 1) {
                this.timer.minute = 0 
                this.timer.seconds = 59
            }
            if(this.timer.seconds == 0 && this.timer.minute == 0) clearInterval(timerId)
        }, 1000)
        console.log(localStorage.userData.email)
    },
    computed: {
        ...mapState({
            dataUser: state => state.code.data
        }),
        ...mapGetters({
            getData: 'code/getData'
        }),
    }
}
</script>