<template>
 <div class="outer-modal-div-form">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form inner-modal-div-form-dop">
                <div class="wrapper-inner-modal"> 
                <div class="HeadStroke_father">
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                </div>
                    <h3 class=" mt-4">CHOOSING A TIP<br>DISTRIBUTION MODEL</h3>
                <div>
                    <div class="panel-setting-fin-el mt-4" @click="setDist('Direct distribution')">
                        <div class="flex">
                            <p class="panel-setting-fin-el-title">Direct distribution</p>
                            <div class="form_radio_b form-setting">
                                <input id="radio-1" type="radio" name="radio" value="1" checked>
                                <label for="radio-1"></label>
                            </div>
                        </div>
                        <p class="panel-setting-fin-el-desc">In this case, tips are credited directly from your customers to your employees, and you can monitor the rating and analytics.</p>
                    </div>
                    <div class="panel-setting-fin-el mt-1" @click="setDist('Manual distribution')">
                        <div class="flex">
                            <p class="panel-setting-fin-el-title">Manual distribution</p>
                            <div class="form_radio_b form-setting">
                                <input id="radio-2" type="radio" name="radio" value="1">
                                <label for="radio-2"></label>
                            </div>
                        </div>
                        <p class="panel-setting-fin-el-desc">All receipts from customers are credited to 
a single balance of your business account. Employees do not have access to earned income, but they see transaction history 
and reviews about themselves.</p>
                    </div>
                    <div class="flex" style="margin-top: 43px; width: 320px;">
                        <span class="sett-check-fin" style="white-space: nowrap;">Set a service fee</span>
                        <label class="toggle" style="margin-left: -20px">
                            <input class="toggle-checkbox" type="checkbox" checked @change="setCheck">
                            <div class="toggle-switch"></div>
                        </label>
                    </div>
                    <my-input v-if="checks" type="number" @keyup="e => {validProcent(e)}" :maxlength="3" label="fee, %" width="320px" style="width: 320px; margin-top: 40px"/>
                    <div class="fill-5-info" v-if="checks">
                        <div class="flex">
                            <img src="../assets/img/UI/warning.svg"/>
                            <p>Service fee is an amount that is automatically deducted from direct distributions and deposited to the branch balance (as an example, you may use this deduction to manually distribute a portion of collected tips to the backroom staff).</p>
                        </div>
                    </div>
                    <my-button label="Start" :active="isActive" @click="setDate" class="mt-4"/>
                </div>
                   
                    </div>
                    
                    
            </div>
        </div>
    </div>
    
</template>

<script>
import registrAdminMixin from '@/mixins/registrAdminMixin'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'FillingBusiness3',
    mixins: [registrAdminMixin],
    data() {
        return {
           checks: true,
           dist: 'Direct distribution',
           procent: '',
           isActive: false
        }
    },
    watch: {
        checks() {
            if(this.checks) {
                if(this.procent == "") this.isActive = false
                else this.isActive = true
            } else {
                this.isActive = true
            }
        }
    },
    methods: {
       setCheck() {
        this.checks = !this.checks
       },
       setDist(value) {
        this.dist = value
       },
       validProcent(e) {
            if(Number(e.target.value) > 100 || String(e.target.value).length > 3) e.preventDefault()
            if(Number(e.target.value) > 100) {
                e.target.value = e.target.value.substring(0, 2)
            }
            this.procent =  e.target.value
            console.log(String(this.procent).length)
            if(this.checks) {
                if(String(this.procent).length == 0) {
                    this.isActive = false
                }else {
                    this.isActive = true
                }
            } else {
                this.isActive = true
            }
       },
       setDate() {
        if(this.isActive) {
            console.log(this.dist)
            let fee = (this.checks) ? Number(this.procent) : null
            this.updateDistribution(this.dist, fee).then(data => {
                if(data?.ok == true) {
//                    this.$router.push('/dashboard')

                    const url = new URL('/dashboard', window.location.origin)
                    window.location.href = url.toString();
                }
            })
        }
       }
    },
    mounted() {
        console.log(localStorage.getItem('auth-token'))
    }
}
</script>