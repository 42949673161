<template>

    <div class="el-history-transaction" ref="sty"  style="padding-bottom: 0px;">
        <div class="el-top-history flex" style="align-items: flex-start;"> 
            <img 
            :src="'/img/uploads/logo/esta/' + avatar"
            class="f-img">
            <div>
                <div class="flex mt-1">
                    <p class="ids">Id: {{ id }}</p>
                    <p class="times">Date of creation: {{ createdAt }}</p>
                    <p class="employees">Name: {{ name }}</p>
                </div>
                <div class="flex mt-1">
                    <p class="times">Business: {{ business_name }}</p>
                    <p class="times">{{ (esta_name == null || 
                    esta_name == undefined || esta_name == '') ? '' : 'Establishment: ' + esta_name }}</p>
                    <p class="times">Team: {{ (team_name == null || 
                    team_name == undefined || team_name == '') ? '' : 'Team: ' + team_name }}</p>
                </div>
                <div class="flex mt-1">
                    <p class="times">Job: {{ job }}</p>
                    <p class="times">Username: {{ username }}</p>
                    <p class="times">Email: {{ email }}</p>
                    <p class="times">{{ (job == 'Waiter') ? '' : 'Access: ' + access}}</p>
                    <p class="times">Phone: {{ phone }}</p>
                </div>
            </div>
            <div class="stars flex">
            </div>
            <p class="price ml-2">${{ balance }}</p>
        </div>
    
    </div>

    <div class="el-history-transaction-mobile mt-1" @click="setShow">
       <div class="flex">
            <img 
            :src="'/img/uploads/logo/esta/' + avatar"
            class="f-img">
            <div >
                <p class="ids">{{ id }}</p>
                <p class="ids mt-1" style="color:white">${{ balance }}</p>
            </div>
            <p class="price mobile-price">{{ name }}</p>
       </div>

       <div class=" mt-2" v-if="show">
            <p class="times" style="margin-left: 0">Date of creation: {{ createdAt }}</p>
            <p class="times">Business: {{ business_name }}</p>
            <p class="times">{{ (esta_name == null || 
            esta_name == undefined || esta_name == '') ? '' : 'Establishment: ' + esta_name }}</p>
            <p class="times">Team: {{ (team_name == null || 
            team_name == undefined || team_name == '') ? '' : 'Team: ' + team_name }}</p>
            <p class="times">Job: {{ job }}</p>
            <p class="times">Email: {{ email }}</p>
            <p class="times">Username: {{ username }}</p>
            <p class="times">{{ (job == 'Waiter') ? '' : 'Access: ' + access}}</p>
            <p class="times">Phone: {{ phone }}</p>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'user',
        props: {
            id: String,
            name: String,
            phone: String,
            email: String,
            balance: String,
            createdAt: String,
            esta_name: String,
            team_name: String,
            avatar: String,
            job: String,
            access: String,
            username: String,
            business_name: String,
        },
        data() {
            return {
                show: false
            }
        },
        methods: {
            setShow() {
                this.show = !this.show
            }
        }
    }
</script>

<style scoped>
.f-img {
    width: 70px;
    border-radius: 100%;
}
.el-history-transaction {
    background-color: #252525;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
}
.el-top-history {
    padding-bottom: 20px;
}
.ids {
    margin-left: 10px;
    font-family: 'Rubik-Regular';
    color: var(--other-text-color)
}
.times {
    color: var(--other-text-color);
    margin-left: 20px;
}
.employees {
    margin-left: 60px;
    color: white;
    font-family: 'Rubik-Regular';
}
.employee-s {
    font-family: 'Rubik-Regular';
    color: var(--other-text-color)
}
.stars {
    margin-left: auto;
}
.stars img {
    margin-left: 5px;
}
.price {
    color: white;
}
.line-border {
    width: 104.5%;
    margin-left: -2.25% !important;
    margin-left: auto;
    height: 1px;
    background-color: #2E2E2E;
}
.desc {
    margin-top: 20px;
    font-size: 14px;
    color: var(--other-text-color)
}
.el-history-transaction-mobile {
        display: none;
    }
@media screen and (max-width: 500px) {
    .el-history-transaction-mobile {
        display: block;
        padding: 20px;
        border-radius: 10px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        background-color: #252525;
    }
    .el-history-transaction {
        display: none;
    }
    .mobile-price {
        margin-left: 60px;
    }
    .employees {
        margin-left: auto;
    }
    .stars {
        margin-left: 0;
    }
    .f-img {
        width: 50px;
    }
    .times {
        margin-top: 10px;
        margin-left: 0;
    }
}
</style>