import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        getDataEmploy(object) {

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/get/client/' + object, {
                    method: "GET"
                })

                if(query.status != 200) return this.$router.push('/client/404')

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}