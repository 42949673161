<template>
    <div style="position: absolute;
    top: 50%;
    transform: translateY(-50%); text-align: center; left: 0; right: 0">
    <h1 class="logo-client-start m-x-auto">Merci<span class="h orange-color q-logo">Q</span></h1>
        <h1 style="color: var(--orange-color)" class="mt-3">404</h1>
        <p style="font-size: 30px; color: white">Not found</p>
        <!-- <p style="color: white; 
        font-size: 26px; 
        margin-top: 40px; 
        cursor: pointer;" @click="$router.push('/signin')">Back</p> -->
    </div>
</template>

<script>
    export default {
        name: 'not found'
    }
</script>