<template>
<div class="cale">
        <div class="flex">
            <h3 class="head-title">Add members</h3>
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
        </div>
        <member 
        v-for="member in members" 
        :img="member.avatar" 
        class="team-esta-el" 
        :name="member.name" 
        :job="member.job" 
        setting="false" 
        @delete="deleteUser(member.id)" 
        :id="member.id" 
        @add="addMember" v-if="members.length > 0"/>
        <h4 v-else class="mt-3">No free employees</h4>
        <div style="background-color: #ABABAB; width: 100%;height: 1px; margin-top: 10px;">
        </div>
        <staff-user
        :setting="'true'"
        v-for="member in selectUser" 
        :img="member.avatar" 
        class="team-esta-el" 
        :name="member.name" 
        :job="member.job" 
        setting="false" 
        :id="member.id"
        @delete="deleteUser"
        />
        <my-button label="Add employee" class="mt-4"  :active="true" @click="addEmployee"/>
        <my-button label="Save" class="mt-4" :active="isActiveBtn" @click="sendData"/>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'

    export default {
        name: 'add-member',
        mixins: [configMixin],
        data() {
            return {
                members: [
                   
                ],
                selectUser: [],
                isActiveBtn: false
            }
        }, 
        methods:{
            addEmployee() {
                
                this.$emit('addempl')
            },
            close() {
                this.$emit('close')
            },
            getMemeber() {
                let response = async() => {
                    console.log(this.$route.params?.establishment_id)
                    let query = await fetch(this.config.API_URL + '/team/member/' + this.$route.params?.establishment_id, {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }
                return response()
            },
            addMember(id) {
                let user = this.members.find(item => item.id == id)
                this.selectUser.push(user)
                this.members.splice(this.members.findIndex(item => item.id == id), 1)
                
                this.isIsset()
            },
            deleteUser(id) {
                let user = this.selectUser.find(item => item.id == id)
                this.members.push(user)
                this.selectUser.splice(this.selectUser.findIndex(item => item.id == id), 1)
                
                this.isIsset()
            },
            isIsset() {
                if(this.selectUser.length > 0) this.isActiveBtn = true
                else this.isActiveBtn = false
            },
            sendData() {
                if(this.isActiveBtn) {
                    let response = async() => {
                        let object = {
                            users: this.selectUser
                        }

                        let query = await fetch(this.config.API_URL + '/team/add/members/' + this.$route.params?.establishment_id, {
                            method: "PUT",
                            headers: {
                                'Authorization': "Bearer " + localStorage.getItem("auth-token"),
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(object)
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }
                    this.$emit('getmembers', this.selectUser)
                }
            }
        },
        mounted() {
            this.getMemeber().then(data => {
                this.members = data.users
            })
        }
    }
</script>

<style scoped>
.add-new-team-b {
    width: 460px
}
.team-esta-el {
    width: 460px !important;
    background-color: #252525  !important;
}
.add-member-title {
    font-size: 18px;
    font-family: 'Rubik-Regular';
    color: white;
}
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
}
.cale {
    position: absolute;
    top: 200px;
    left: calc(60% - 408px);
    z-index: 3;
    opacity: 1;
    width: 540px;
    padding: 40px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .cale {
        padding: 15px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .team-esta-el {
        width: 290px !important;
    }
    .add-new-team-b {
        width: 290px;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
}
</style>
