<template>
   <div class="el-history-transaction-mobile mt-1">
       <div class="flex">
            <img src="../assets/img/UI/person.svg" class="ml-1">
            <p class="ids">{{ id }}</p>
            <p class="times ml-2">{{ time }}</p>
            <p class="times ml-2" v-if="date != undefined">{{ date }}</p>
       </div>
       <div class="line-border mt-2"></div>
       <div class="flex mt-2">
            <div class="stars flex">
                        <img src="../assets/img/UI/start.svg" v-for="item in Number(stars)">
            </div>
            <p class="employees"><span class="employee-s">Employee:</span> {{ name }}</p>
        </div>
        <p class="desc">{{ desc }}</p>
    </div>
    <div class="m_block mt-2 hide" style="margin-left: auto; margin-right: auto;">
        <div class="top mt-2 " style="padding-right: 15px;">
            <div class="icon ml-2"><img src="../assets/img/UI/person.svg"></div>
            <div class="user_number ml-1">{{ id }}</div>
            <div class="time ml-2">{{ time }}</div>
            <div class="time ml-2" v-if="date != undefined">{{ date }}</div>
            <div class="user_Employee">Employee: {{name}}</div>
            <div class="star ml-2 flex">
                <img src="../assets/img/UI/start.svg" v-for="item in Number(stars)">
            </div>
        </div>
        <div class="middle mt-2 ml-2"></div>
        <div class="bottom ml-2 mt-2" style="width: 95%; text-align: left;">
            <p style="font-size: 14px; color: #ABABAB; width: 100%; text-align: left;">
               {{ desc }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Employees',
    props: {
        id: String,
        time: String,
        name: String,
        desc: String,
        stars: String,
        date: String
    },
    
}
</script>

<style scoped>
.el-history-transaction {
    background-color: #252525;
    width: 880px;
    padding: 20px;
    border-radius: 10px;
}
.el-top-history {
    padding-bottom: 20px;
}
.ids {
    margin-left: 10px;
    font-family: 'Rubik-Regular';
    color: var(--other-text-color)
}
.times {
    color: var(--other-text-color);
    margin-left: 20px;
}
.employees {
    margin-left: 60px;
    color: white;
    font-family: 'Rubik-Regular';
}
.employee-s {
    font-family: 'Rubik-Regular';
    color: var(--other-text-color)
}
.stars {
    margin-left: auto;
}
.stars img {
    margin-left: 5px;
}
.price {
    color: white;
}
.line-border {
    width: 104.5%;
    margin-left: -2.25% !important;
    margin-left: auto;
    height: 1px;
    background-color: #2E2E2E;
}
.desc {
    margin-top: 20px;
    font-size: 14px;
    color: var(--other-text-color);
}
.white{
    color: #FFFFFF;
}
.m_block{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 687px;
    height: 118px;
    border-radius: 10px;
    background-color: #252525;
    color: #ABABAB;
}
.top{
    display: flex;
    width: 100%;
}
.user_Employee{
    margin-left: 60px;
    width: 300px; 
}   
.icon{
    height: 18px;
    width: 18px;
}
.star{
    height: 18px;
    width: 110px;
}

.middle{
    height: 1px;
    width: 664px;
    background-color: #2E2E2E;
}
.el-history-transaction-mobile {
        display: none;
    }
@media screen and (max-width: 500px) {
    .hide {
        display: none;
    }
    .el-history-transaction-mobile {
        display: block;
        padding: 20px;
        border-radius: 10px;
        width: 320px;
        background-color: #252525;
    }
    .el-history-transaction {
        display: none;
    }
    .mobile-price {
        margin-left: 60px;
    }
    .employees {
        margin-left: auto;
    }
    .stars {
        margin-left: 0;
    }
}
</style>
