<template>
    <phone-number-get v-if="isActiveRecovery" @getdata="setActiveRecovery" @close="setActiveRecovery"/>
    <change-payment v-if="isActiveCard" @getdata="setActiveCard" @close="setActiveCard"/>    
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getCalendar" v-if="isActiveCalendar"/>
    <div class="container-dash absolute-100-mobile" style="height: auto">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'calendar-active-cont': (isActiveRecovery || isActiveCard || isActiveCalendar), 'dop-wrpapper-setting-720px': true}">
            <div class="flex">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/settings')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0;">Payment information</h2>     
            </div>
            <div class="main-staff setting-div bg-security bg-esta">
                <div id="learning-update-payment-info" class="bg-set-esta-s mt-2 wilds-23">
                   <div class="flex flex-cc relative">
                        <!--
                        <div class="flex w-320-mobile"><select-input :width="widthSelect" bgColor="#252525" :inps="stripeCardList" style="z-index: 1;position: relative;" label="Type"/></div>
                        -->
                        <div class="inp-pay-set"><my-input label="Bank card" maxlength="19" type="card-placeholder" v-model="card_data" bgColor="#252525" class="mt-2-mobile border-none"/></div>
                        <p class="desc-inp-setting-sec sec-dop" @click="setActiveStripeCard">{{ account_type === 'bank_account' ? 'Update linked account' : 'Update linked card' }}</p>
                    </div>

                    <change-stripe-payment 
                        v-if="isActiveStripeCard" 
                        @getdata="setActiveStripeCard" 
                        @close="setActiveStripeCard" 
                        :activeTab="account_type"
                    />
                </div>

                <!-- <button class="border-button d-none-desc" @click="setActiveRecovery">Add</button> -->
                <!-- <p class="el-text-input-sec mt-3 d-none-desc">Payment metods</p> -->
                <!-- <div class="el-payment-data flex mt-2">
                    <div>
                        <div class="flex">
                            <p class="top-el-data-card">Bank card</p>
                            <p class="top-el-data-card ml-2">Denis Petrov</p>
                        </div>
                        <p class="el-data-card-data mt-1">0000 0000 0000 0000</p>
                    </div>
                    <img src="../../../assets/img/UI/close-calendar.svg" class="ml-auto pointer"/>
                </div>

                <div class="el-payment-data flex mt-2">
                    <div>
                        <div class="flex">
                            <p class="top-el-data-card">Bank card</p>
                            <p class="top-el-data-card ml-2">Denis Petrov</p>
                        </div>
                        <p class="el-data-card-data mt-1">0000 0000 0000 0000</p>
                    </div>
                    <img src="../../../assets/img/UI/close-calendar.svg" class="ml-auto pointer"/>
                </div> -->
                <div class="flex top-avarage-mobile mt-3">
                <h2 class="main-title-dash pointer">Transaction history</h2>
                <div class="min-panel-end flex panel-average" style="flex-wrap: wrap;">
                   <div class="panel-calendar-min" style="margin-left: auto;" @click="setActiveCalendar" v-if="choiceDate == ''">
                        <img src="../../../assets/img/UI/calendar-min-panel.svg">
                    </div>
                    <div class="panel-calendar-min  mt-2-mobile" style="margin-left: 0" v-if="choiceDate != ''">
                        <div>
                            <span class="choiceDate" style="white-space: nowrap; margin-left: 0;">{{ choiceDate }}</span>
                            <img src="../../../assets/img/UI/close-calendar.svg" style="margin-bottom: -5px;" class="pointer" @click="delCalendar">
                        </div>
                    </div>
                    <div class="min-panel-date-filter flex ml-1">
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0), FinanceMixin('Day')">Day</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1), FinanceMixin('Week')">Week</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2), FinanceMixin('Month')">Month</div>
                        <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3), FinanceMixin('Year')">Year</div>
                    </div>
                </div>
            </div>
            <div class="main-b-graph mt-3">
                <p class="total-graph main-text-color">Total: ${{ Number(balance).toFixed(2) }}</p>
                <div class="line-graph">
                    <div class="flex line-graph-l">
                        <div v-for="el in line_arr" class="l-1" @click="e => {showMoney(e)}">
                            <div class="height-panel-ert">{{el.sum}}$</div>
                            <div class="l-1-div" :style="{'height': el.heightEl + 'px'}"></div>
                            <p class="l-p">{{el.day}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-transactions mt-3">
                <user-transaction 
                    v-for="el in trans" 
                    :id="zeroPad(el.id)" 
                    :date="String(new Date(el.createdAt).getDate()) + '.' + (new Date(el.createdAt).getMonth() + 1) + '.' + String(new Date(el.createdAt).getFullYear())" 
                    :time="el.time" :count="el.sum"
                    class="mt-1 w-660px"/>
                    <!-- <user-transaction id="2433437" date="25.04.2023" time="16:30" count="150.00" class="mt-1 w-660px"/>
                    <user-transaction id="2433437" date="25.04.2023" time="16:30" count="150.00" class="mt-1 w-660px"/>
                    <user-transaction id="2433437" date="25.04.2023" time="16:30" count="150.00" class="mt-1 w-660px"/>
                    <user-transaction id="2433437" date="25.04.2023" time="16:30" count="150.00" class="mt-1 w-660px"/>
                    <user-transaction id="2433437" date="25.04.2023" time="16:30" count="150.00" class="mt-1 w-660px"/>
                    <user-transaction id="2433437" date="25.04.2023" time="16:30" count="150.00" class="mt-1 w-660px"/>
                    <user-transaction id="2433437" date="25.04.2023" time="16:30" count="150.00" class="mt-1 w-660px"/> -->

                <p v-if="trans.length == 0" class="none-info">At the moment, we don’t have enough data to display the information on this page. Try again later when you get enough incoming transactions</p>                       
            </div>
            <paginate :count="Number(lastpage)" link="/settings/payment"/>
                </div>
        </div>

    </div>
</template>

<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import dateIconMixin from '@/mixins/dateIconMixin';
    import getFinanceMixin from '@/mixins/getFinanceMixin';
    import loaderMixin from '@/mixins/loaderMixin';

    import TgInstance from '@/components/instance/tourguide';

    export default {
        name: 'payment information',
        mixins: [dashboardMixin, dateIconMixin, getFinanceMixin, loaderMixin],
        data() {
            return {
                widthSelect: '320px',
                isActiveRecovery: false,
                isActiveCard: false,
                isActiveStripeCard: false,
                card_data: '',
                account_type: '',
                trans: '',
                balance: '',
                lastpage: 0,
                line_arr: [],
                stripeCardList: []                
            }
        },
        mounted() {
            if(window.innerWidth <= 500) {
                this.widthSelect = '290px'
            }

            //

        },
        watch: {
           '$route.query.page': function() {
                this.FinanceMixin()
            }
        },
        methods: {
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            getCalendar(value) {
                let date = (value == null || value == undefined) ? null : JSON.stringify({date: value})
                this.FinanceMixin(value)
                this.getDateIcon(value)
            },
            delCalendar() {
                this.FinanceMixin(null)
                this.delDateIcon()
            },
            setActiveRecovery() {
                if(this.isActiveRecovery == true) this.setActiveCard()
                this.isActiveRecovery = !this.isActiveRecovery
            },
            setActiveCard() {
                this.isActiveCard = !this.isActiveCard
            },
            setActiveStripeCard() {
                this.isActiveStripeCard = !this.isActiveStripeCard;
                console.log(!this.isActiveStripeCard);
            },
            showMoney(e) {
                if(e.target.classList[0] != 'l-1') {
                    if(e.target.parentNode.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'none'
                    }
                } else {
                    if(e.target.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.querySelector('.height-panel-ert').style.display = 'none'
                    }
                }
            },
            getDayOfWeek(date, date_count) {
                if(date_count.view == 12) {
                    let need_date = new Date()  
                    let current = new Date(need_date.getFullYear(), need_date.getMonth()-date_count.value, 1);
                    const month = current.toLocaleString('en-US', { month: 'short' });
                    return month[0];

                } else if(date_count.view == 4) {
                    
                    const getDateForWeek = (countDayAgo) => {
                        const dateDaysAgo = new Date(Date.now() - countDayAgo * 24 * 60 * 60 * 1000)

                        let date = (dateDaysAgo.getDate() < 10) ? "0" + dateDaysAgo.getDate() : dateDaysAgo.getDate()
                        let month = (dateDaysAgo.getMonth() + 1 < 10) ? "0" + (dateDaysAgo.getMonth() + 1) : dateDaysAgo.getMonth() + 1

                        return date + '.' + month
                    }

                    const date_arr = {
                        '0': getDateForWeek(7) + " - " + getDateForWeek(0),
                        '1': getDateForWeek(15) + " - " + getDateForWeek(8),
                        '2': getDateForWeek(23) + " - " + getDateForWeek(16),
                        '3': getDateForWeek(30) + " - " + getDateForWeek(24)
                    }

                    return date_arr[String(date_count.value)]
                } else {
                    let current = date
                    let today = current.toLocaleDateString('en-US',{weekday: 'long'});

                    let today2 = new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(current);
                    
                    return today2[0]
                }
            },
            FinanceMixin(date) {
                Array.prototype.max = function() {
                        return Math.max.apply(null, this);
                };

                
                let page_id = (this.$route.query.page == undefined) ? 1 : this.$route.query.page
                this.getDataMixin(page_id, date).then(data => {
                    this.trans = data.rows
                    this.balance = data.balance
                    this.lastpage = Math.ceil(data.count / 10)
                    
                    let maxEl = Number(data.days.max())

                    this.line_arr = []


                    data.days.forEach((el, i) => {
                        let date = new Date()

                        let maxDay = data.days.length - 1

                        maxDay = maxDay - i

                    
                        date.setDate(new Date().getDate() - maxDay)
                        
                        let days_data = {
                            sum: (el == null) ? 0 : Number(el).toFixed(0),
                            heightEl: (el == null) ? 0 : Number(el) * 80 / maxEl,
                            day: this.getDayOfWeek(date, {
                                view: data.days.length,
                                value: maxDay
                            })
                        }
                        this.line_arr.push(days_data)
                    })

                    this.setLoader(false);
                })
            },
            getStripeCardList() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/stripe/business/get-external-accounts', {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            }
        },
        beforeMount() {
            this.FinanceMixin()

            this.getStripeCardList().then(data => {
  
                let cardListInputs = data.data;
                cardListInputs.map((card) => card.name = `**** **** **** ${card.last4}`)

                this.stripeCardList = cardListInputs;

                this.card_data = cardListInputs[0].name;
                this.account_type = cardListInputs[0].object

                console.log('cardListInputs', cardListInputs);
            })
        },
        async created() {
            const tgInstance = TgInstance.instance;
            if (tgInstance) {
                console.log ('TgInstance.instance is set');

                const groupName = tgInstance.groupName;
                if (!tgInstance.tg.isFinished(groupName))
                    tgInstance.tg.visitStep(11);
            }
        }
    }
    console.log(window.screen.availWidth)
</script>

<style scoped>

.relative {
    position: relative;
}

.border-none .error-input {
    border: none !important;
}


.desc-inp-setting-sec {
    position: absolute;
    top: 50%;
    bottom: 0;
    /* left: 0; */
    right: 0;
    /* height: 100px; */
    margin-top: 0;
}

@media screen and (max-width: 500px) {
    .uebf {
        height: 100px;
    }    
    .main-staff {
        padding: 0;
    }
    .wilds-23 {
        position: relative;
    }
    .desc-inp-setting-sec {
        width: 100px;
    }
}

@media screen and (min-width: 501px) {
    .inp-pay-set {
        width: 100%;
    }
}


</style>