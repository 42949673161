<template>
    <div class="container-dash" >
        <employee-dashboard />
        <div class="wrapper-dash main-mirciq workspace-wrapper-das min-height pb-4 wrapper-empl-1105">
            <div class="workspace-wrapper wrapper-empl-1105"> 
                <h2 class="main-title-dash pointer" @click="$router.push('/employee/workspace')"><img src="../../../assets/img/UI/left-strel.svg">Achievements</h2>
                <div class="flex date-filter-empl mt-2 flex-start flex-c">
                    <div class="flex">
                        <div class="el-date-filter-empl pointer" @click="setStatus('Achieved')" :class="{'el-date-filter-empl-active': status == 'Achieved'}">
                            Achieved
                        </div>
                        <div class="el-date-filter-empl ml-05 pointer" @click="setStatus('Unachieved')" :class="{'el-date-filter-empl-active': status == 'Unachieved'}">
                            Unachieved
                        </div>
                    </div>
                    <p class="total-achiev mt-1-mobile">Total: {{sum}}/900</p>
                </div>
                <div class="desc-achi mt-1">
                        <p>Why is achievement scoring important?</p>
                        <p>When your account reaches the maximum rating, contact customer support to claim your reward - a +5% bonus on the total amount of tips you have earned in the last 3 months!</p>
                    </div>
                <div class="no-achive-div-outer" v-if="status == 'Achieved' && achieved.length == 0">
                    <div class="no-achive-div">
                        <p class="title-achive-none">We are waiting for your activity!</p>
                    </div>  
                </div>  
                <div v-if="status == 'Achieved'" class="achiev-p mt-1">
                    <article class="flex flex-between mt-2 w-ach-m" style="flex-wrap: wrap;">
                        <div class="div-achiev mt-2" v-for="el in achieved">
                            <div class="flex flex-start">
                                <div class="div-achi-child">
                                    <img v-if="el.img != null && el.img != undefined" :src="'/img/achievements/' + el.img">
                                    <h3>{{el.title}}</h3>
                                    <p>{{ el.text }}</p>
                                </div>
                                <div class="div-achi-ball">+50</div>
                            </div>
                        </div>
                    </article>
                  
                </div>
                <div v-if="status == 'Unachieved'" class="achiev-p">
                    <article class="flex flex-between mt-2 w-ach-m" style="flex-wrap: wrap;">
                        <div class="div-achiev mt-2" v-for="el in unachieved">
                            <div class="flex flex-start">
                                <div class="div-achi-child">
                                    <img v-if="el.img != null && el.img != undefined" :src="'/img/achievements/' + el.img">
                                    <h3>{{el.title}}</h3>
                                    <p>{{ el.text }}</p>
                                </div>
                                <div class="div-achi-ball">+50</div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ahievmentsMixin from '@/mixins/ahievmentsMixin'
    import loaderMixin from '@/mixins/loaderMixin';

    export default {
        name: 'workspace achievements',
        mixins: [ahievmentsMixin, loaderMixin],
        data() {
            return {
                status: 'Achieved',
                unachieved: [],
                achieved: [],
                sum: 0
            }
        },
        methods: { 
            setStatus(value) {
                this.status = value
            }
        },
        beforeMount() {
            this.getAchievments().then(data => {
                let unachieved = []
                let achieved = this.achievments.filter(el => {
                    let result = null
                    data.achievements.map(item => {
                        if(item == el.id) result = el
                    })
                    if(result == null) {
                        unachieved.push(el)
                        return 
                    } else return result
                    
                })
                this.achieved = achieved
                this.unachieved = unachieved
                this.sum = achieved.length * 50

                this.setLoader(false);
            })
        }
    }
</script>