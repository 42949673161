<template>
    <div class="container-manager-esta">
        <div class="flex flex-content-mob">
            <div class="flex data-div">
                <img :src="require('../assets/img/uploads/ava/' + avatar)" class="logo-staff"/>
                <div class="data-info" @click="$router.push('/establishment/member/' + id)">
                    <p class="name">{{ name }}</p>
                    <div class="access flex"> 
                        <div>
                            <span>{{ job }}</span><br>
                        </div>
                    </div>
                </div>
            </div>
            <p :class="{'phone': true, 'not-active': !activate}">{{ phone }}</p>
            <img src="../assets/img/UI/right-strel.svg" class="close pointer" @click="delManager">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'staff-client',
        props: {
            name: String,
            avatar: String,
            phone: String,
            id: String,
            job: String
        },
        data() {
            return {
                showAccess: false,
            }
        },
        methods: {
            setAccess(value) {
                this.acces = value
                this.$emit('changeaccess', value, this.id)
            },
            setShowAccess() {
                this.showAccess = !this.showAccess
            },
            delManager(id) {
                this.$emit('delete', this.id)
            }
        }
    }
</script>

<style scoped>
.access, .name {
    white-space: nowrap;
}
.close {
    margin-left: auto;
}
.not-act {
    margin-top: 13px;
    color: #F28E22;
    margin-left: 80px;
}
.phone {
    margin-left: 174px;
    color: white;
    font-size: 16px;
    margin-top: 13px;
}
.not-active {
    color: #929292;
}
.access img {
    margin-left: 4px;
}
.access div span {
    color: #ABABAB;
    font-size: 14px;
}
.access {
    margin-top: 6px;
}
.data-info {
    margin-left: 12px;
}
.name {
    color: white;
    font-size: 16px;
    font-family: 'Rubik-Regular';
}
.logo-staff {
    object-fit: cover;
    width: 42px;
    height: 42px;
    border-radius: 100%;
}
    .container-manager-esta {
        width: 100%;
        border-radius: 10px;
        background-color: #252525;
        padding: 12px;
    }
    @media screen and (max-width: 500px) {
        .container-manager-esta {
            background-color: #2C2C2C;
            width: 320px;
        }
        .close {
            order: 2;
            margin-top: -40px;
        }
        .data-div {
            order: 1;
        }
        .phone {
            order: 3;
            margin-left: 0;
            margin-top: 30px;
        }
        .not-act {
            order: 4;
            margin-left: 0;
        }
    }
</style>