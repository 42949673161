<template>

    <div class="el-history-transaction" ref="sty" style="background-color: var(--main-bg-color);" @click="showMore">
        <div class="el-top-history flex" style="padding-bottom: 0;"> 
            <img src="../assets/img/UI/person.svg">
            <p class="ids">{{ id }}</p>
            <p class="times">{{date}} {{ time }}</p>
            <div class="stars flex">
                <img src="../assets/img/UI/start.svg" v-for="item in Number(stars)">
            </div>
            <div class="price-info">
                <p class="price ml-2" v-if="type == 'fee'">${{ price }} (fee)</p>
                <p class="price ml-2" v-else-if="type == 'payout' || type == 'transfer'">-${{ String(price).split("-").join("") }}</p>
                <p class="price ml-2" v-else>${{ price }}</p>
                <p class="price ml-2" v-if="type == 'payout' && additionalInfo != null">(Withdrawal to **{{ additionalInfo }})</p>
                <p class="price ml-2" v-if="type == 'transfer' && additionalInfo != null">(Transfer to @{{ additionalInfo }})</p>
            </div>
        </div>
        <div class="line-border"></div>        
        <p class="desc">{{ desc }}</p>
        <p style="color: var(--other-text-color); text-align: center;" class="mt-1" v-if="!show">Show more</p>
    </div>

    <div class="el-history-transaction-mobile mt-1" @click="showMore" style="background-color: var(--main-bg-color);">
       <div class="flex" >
            <img src="../assets/img/UI/person.svg" class="ml-1">
            <p class="ids">{{ id }}</p>
            <div class="">
                <p class="times ml-2">{{ time }}</p>
                <p class="times ml-2">{{date}}</p>
            </div>
            <div class="price-info">
                <p class="price mobile-price" v-if="type == 'fee'">${{ price }}(fee)</p>
                <p class="price mobile-price" v-else-if="type == 'payout' || type == 'transfer'">-${{ String(price).split("-").join("") }}</p>
                <p class="price mobile-price" v-else>${{ price }}</p>
                <p class="price ml-2" v-if="type == 'payout' && additionalInfo != null">(Withdrawal to **{{ additionalInfo }})</p>
                <p class="price ml-2" v-if="type == 'transfer' && additionalInfo != null">(Transfer to @{{ additionalInfo }})</p>
            </div>
       </div>
       <div class="line-border mt-2" v-if="!show"></div>
       <p style="color: var(--other-text-color); text-align: center;" class="mt-1" v-if="!show">Show more</p>
       <div class="flex mt-2" v-if="show">
            <div class="stars flex">
                        <img src="../assets/img/UI/start.svg" v-for="item in Number(stars)">
            </div>
        </div>
        <p class="desc" v-if="show">{{ desc }}</p>
    </div>

</template>
 
<script>
   export default {
        name: 'history-transaction-empl',
        emits: ['transaction-show'],
        props: {
            id: String,
            time: String,  
            price: String,
            stars: String,
            desc: String,
            length: String,
            width: String,
            type: String,
            date: String,
            createdAt: String,
            additionalInfo: String
        },
        data() {
            return {
                show: false
            }
        },
        mounted() {
            if(this.width != false && this.width != '') {
                this.$refs.sty.style.width = this.width
            }
        },
        methods: {
            setShow() {
                this.show = !this.show
            },
            showMore() {
                this.$emit('transaction-show', this.id, this.time, this.price, this.stars, this.desc, this.createdAt)
            }
        }
    }
</script>

<style scoped>
    .el-history-transaction {
        background-color: #252525;
        width: 100%;
        padding: 20px;
        border-radius: 10px;
        margin-top: 10px;
    }
    .el-top-history {
        padding-bottom: 20px;
    }
    .ids {
        margin-left: 10px;
        font-family: 'Rubik-Regular';
        color: var(--other-text-color)
    }
    .times {
        color: var(--other-text-color);
        margin-left: 20px;
    }
    .employees {
        margin-left: 60px;
        color: white;
        font-family: 'Rubik-Regular';
    }
    .employee-s {
        font-family: 'Rubik-Regular';
        color: var(--other-text-color)
    }
    .stars {
        margin-left: auto;
    }
    .stars img {
        margin-left: 5px;
    }
    .price {
        color: white;
    }
    .line-border {
        width: 104.5%;
        margin-left: -2.25% !important;
        margin-left: auto;
        height: 1px;
        background-color: #2E2E2E;
    }
    .desc {
        margin-top: 20px;
        font-size: 14px;
        color: var(--other-text-color)
    }
    .el-history-transaction-mobile {
        display: none;
    }
    .price-info {
        text-align: right;
    }
    @media screen and (max-width: 500px) {
        .el-history-transaction-mobile {
            display: block;
            padding: 20px;
            border-radius: 10px;
            width: 320px;
            background-color: #252525;
        }
        .el-history-transaction {
            display: none;
        }
        .mobile-price {
            margin-left: 60px;
        }
        .employees {
            margin-left: auto;
        }
        .stars {
            margin-left: 0;
        }
        .stars :nth-child(1) {
            margin-left: 0;
        }
        .desc {
            margin-top: 10px;
        }
    }
</style>