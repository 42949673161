<template>
    <transfer-funds 
        v-if="showTransferFunds"
        @close="close"
        :username="username"
        :balance="DOM.balance"
        :user="user"
    />
    <div class="container-dash" style="align-items: flex-start;">
        <employee-dashboard />
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'calendar-active-cont': isDel || isActiveAddManagers}">
            <div id="learning-payout">
                <h2 class="main-title-dash pointer" @click="$router.push('/employee/workspace')"><img src="../../assets/img/UI/left-strel.svg"> Payout</h2>
                <p class="payout-title mt-2">Available for withdrawal: ${{DOM.balance}}</p>
                <my-input 
                    label="Enter amount" 
                    type="number"
                    bgColor="#252525" 
                    v-model="amount" 
                    class="mt-2"
                    @keydown="codeKeyDown"
                />
            </div>
            <p class="error-input-text err-au" v-if="DOM.error.isLess">You cannot withdraw less than $10</p>
            <p class="error-input-text err-au" v-if="DOM.error.isMore">Available for withdrawal:  ${{DOM.balance}}</p>
            <p class="error-input-text err-au" v-if="DOM.error.isHasExternal">You have no data to withdraw! Add data in the verification tab!</p>
            <p class="error-input-text err-au" v-if="DOM.error.isPayoutInvalid">An error occurred, please try later</p>
            <p class="error-input-text err-au green-text" v-if="DOM.error.isPayoutSuccess">Payment successful! The speed at which funds arrive in your account depends on the speed of your bank</p>
            <div class="m-x-auto w-320-mob mt-2"><my-button :active="true" @click="allMoney" label="Withdraw the entire amount"  class=""/></div>
            <div class="m-x-auto w-320-mob mt-2"><my-button :active="DOM.isActiveBtn" label="Withdraw" @click="sendData" class=""/></div>
            <div class="transfer-container mt-6">
                <h2>Transfer to MerciQ User</h2>
                <p class="payout-title">
                    YOU CAN TRANSFER FUNDS TO ANOTHER MERCIQ USER (E.G., YOUR EMPLOYEE OR PARTNER) USING THEIR USERNAME. ENTER IT IN THE FIELD BELOW.
                </p>
                <my-input 
                    label="@username" 
                    type="text" 
                    bgColor="#252525" 
                    v-model="username" 
                    class="mt-2"
                    @keydown="codeKeyDownUsername"
                />
                <div class="m-x-auto w-320-mob mt-2">
                    <my-button :active="DOM.isActiveFindUserBtn" label="Find User" @click="findUser" class=""/>
                </div>
                <p class="error-input-text err-au" v-if="DOM.error.isTransferInvalid">An error occurred, please try later</p>
                <p class="error-input-text err-au green-text" v-if="DOM.error.isTransferSuccess">Transfer successful!</p>
            </div>
        </div>
    </div>
</template>

<script>
    import configMixin from "@/mixins/configMixin"
    import { useRoute } from 'vue-router';
    import loaderMixin from "@/mixins/loaderMixin";

    import TgInstance from '@/components/instance/tourguide';

    export default {
        name: 'add establishment',
        mixins: [configMixin, loaderMixin],
        setup() {

            const route = useRoute();
            // Now you can access params like:
            console.log('learning', route.query.learning);

            let learning = false;
            if (route.query.learning == 'true')
                learning = true;

            return { learning };
        },
        data() {
            return {
                DOM: {
                    external_account: false,
                    balance: 0,
                    error: {
                        isLess: false,
                        isMore: false,
                        isHasExternal: true,
                        isPayoutInvalid: false,
                        isPayoutSuccess: false,
                        isTransferInvalid: false,
                        isTransferSuccess: false
                    },
                    isActiveBtn: false,
                    isActiveFindUserBtn: false,                    
                },
                showTransferFunds: false,
                amount: 0,
                username: '',
                user: {}
            }
        },
        watch: {
            
            amount() {
                if (this.amount < 10  && this.amount != 0) {
                    this.DOM.error.isLess = true
                } else {
                    this.DOM.error.isLess = false
                }

                if (this.amount > this.DOM.balance) {
                    this.DOM.error.isMore = true
                } else {
                    this.DOM.error.isMore = false
                }
                this.isActive()
            }, username() {
                if(this.username[0] != "@") 
                    this.username = '@' + this.username;

                this.DOM.isActiveFindUserBtn = this.username.length > 0;
            }
        },
        methods: {
           isActive() {
            if(this.amount != 0 && !this.DOM.error.isMore && !this.DOM.error.isLess && !this.DOM.error.isHasExternal) {
                this.DOM.isActiveBtn = true
            } else {
                this.DOM.isActiveBtn = false
            }
           },
           allMoney() {
            this.amount = this.DOM.balance
           },
           sendData() {
            if (this.DOM.isActiveBtn) {

                const body = {
                    amount: this.amount
                }

                fetch('/api/transaction/employee/payout', {
                    method: "POST",
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }).then(data => data.json()).then(data => {
                    if(data?.balance != undefined) {
                        this.DOM.balance = data.balance
                        this.amount = 0
                        this.DOM.error.isPayoutInvalid = false
                        this.DOM.error.isPayoutSuccess = true
                    } else {
                        this.DOM.error.isPayoutInvalid = true
                        this.DOM.error.isPayoutSuccess = false
                    }
                }).catch(err => {
                    this.DOM.error.isPayoutInvalid = true
                    this.DOM.error.isPayoutSuccess = false
                })
            }
           },
           getDataEmploy(object) {
                object = object.split('@').join("")
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/get/client/' + object, {
                        method: "GET"
                    })
                    if(query.status != 200) return Promise.resolve({error: 'Employee not found'})
                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            },
            findUser() {
                this.getDataEmploy(this.username.split("@").join("")).then(data => {
                    if(data?.error != undefined) {
                        this.user = {};
                        this.showTransferFunds = true;
                        this.isError = true;
                    } else {
                        this.user = data;
                        this.showTransferFunds = true;
                    }
                })
            },
           codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.DOM.isActiveBtn) {
                        this.sendData();
                    }
                }
            },
            codeKeyDownUsername(event) {
                if (event.keyCode === 13) {
                    if (this.DOM.isActiveBtn) {
                        this.transferFunds();
                    }
                }
            },
            close() {
                this.user = {};
                this.showTransferFunds = false;
            }
        },
        beforeMount() {
            fetch(this.config.API_URL + '/user/employee/payout', {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                }
            }).then(data => data.json())
            .then(data => {
               this.DOM.external_account = data.external_account
               this.DOM.balance = data.balance
               this.DOM.error.isHasExternal = (data.external_account == null) ? true : false

               this.setLoader(false);
            })
        },
        mounted() {
            const tgInstance = TgInstance.instance;
            if (tgInstance) {
                console.log ('TgInstance.instance is set');

                const groupName = tgInstance.groupName;
                if (!tgInstance.tg.isFinished(groupName))
                    tgInstance.tg.visitStep(7);
            }
        }
        
    }
</script>

<style scoped>
.reg-staff {
    width :320px
}
</style>