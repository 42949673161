<template>
    <div class="outer-modal-div-form">
           <div class="middle-modal-div-form">
            <div class="inner-modal-div-form inner-modal-div-form-dop">
                <div class="wrapper-inner-modal"> 
                <div class="HeadStroke_father">
                       <div class="HeadStroke white"></div>
                       <div class="HeadStroke grey"></div>
                       <div class="HeadStroke grey"></div>
                       <div class="HeadStroke grey"></div>
                       <div class="HeadStroke grey"></div>
                   </div>
                       <h3 class="mt-4 center">USER NAME</h3>
                       <p class="desc-title-empl mt-2">Latin characters a-z, digits 0-9, special characters are not allowed</p>
                   <div class="flex flex-column">
                        <my-input 
                            type="text" 
                            style="width: 320px" 
                            label="Username" 
                            v-model="formData.userName" 
                            class="mt-2"
                            @keydown="codeKeyDown"
                        />
                        <p class="error-input-text err-au" v-if="isUse" :class="{'green-text': isActiveErr}">{{ textRes }}</p>
                        <my-button label="Next" style="width: 320px" :active="isActive" @click="send" class="mt-4"/>
                   </div>
                      
                   </div>    
                       
                       
               </div>
           </div>
       </div>
       
   </template>
   
   <script>
   import usernameMixin from '@/mixins/usernameMixin'

   export default {
       name: 'username empl',
       mixins: [usernameMixin],
       data() {
          return {
           isActive: false,
           formData: {
              userName: ''
           },
           isUse: false,
           isActiveErr: false,
           textRes: ''
          }
       },
       watch: {
        'formData.userName': function() {
            if(this.formData.userName.length > 3) {
                const username = this.formData.userName;
                if (username.match(/^[a-z0-9]+$/)) {
                    console.log('username match');
                    this.isCheck();
                } else {
                    this.isUse = true;
                    this.textRes = 'Username can only contain lowercase letters and digits';
                    this.isActiveErr = false;
                    this.isIsset()
                }
            }
        }
       },
       methods: {
           setRepeatPassword(value) {
               this.formData.repeatPassword = value
               this.isIsset()
           },
           isIsset() {
            if(this.formData.userName != "") this.isActive = true
            else this.isActive = false
           },
           isIsset() {
            if(this.formData.userName != "" && this.isActiveErr && this.formData.userName.length > 3) this.isActive = true
            else this.isActive = false
           },
           isCheck() {
            this.getUserName(this.formData.userName).then(data => {
                console.log(data)
                if(data.ok) {
                    this.isUse = true
                    this.textRes = 'Username is available'
                    this.isActiveErr = true
                } else {
                    this.isUse = true
                    this.textRes = 'Username is taken'
                    this.isActiveErr = false
                }
                this.isIsset()
            })
           },
           send() {
               if(this.isActive) {
                localStorage.setItem('userData.username', this.formData.userName)
                this.$router.push("/employee/personal")
               }
           },
           codeKeyDown(event) {
                if (event.keyCode === 13) {
                    if (this.isActive) {
                        this.send();
                    }
                }
            }
       },
       beforeMount() {
        if(localStorage.getItem('userData.email') == undefined ||
        localStorage.getItem('userData.password') == undefined ||
        localStorage.getItem('id') == undefined) return this.$router.push('/')
       }
   }
   </script>