<template>
    <notifications @close="Close" @getdata="setData"/>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': true,'wrapper-set': true, }">
            <div class="setting-el flex mt-2">
                <img src="../../assets/img/UI/pers.svg" alt="">
                <span>Edit my Business account</span>
                <img src="../../assets/img/UI/setting-line.svg" class="line-setting pointer" @click="$router.push('/settings/type')">
            </div>
            <div class="setting-el flex mt-1">
                <img src="../../assets/img/UI/notif.svg" alt="">
                <span>Notification</span>
                <img src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1">
                <img src="../../assets/img/UI/cir.svg" alt="">
                <span>Password and security</span>
                <img src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1">
                <img src="../../assets/img/UI/esta.svg" alt="">
                <span>My establishments</span>
                <img src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1">
                <img src="../../assets/img/UI/card.svg" alt="">
                <span>Payment information</span>
                <img src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-4">
                <img src="../../assets/img/UI/info.svg" alt="">
                <span>Сontact support</span>
                <img src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1">
                <img src="../../assets/img/UI/WhatsApp.svg" alt="">
                <span>Chat Support (WhatsApp)</span>
                <img src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1">
                <img src="../../assets/img/UI/agree.svg" alt="" @click="$router.push('/settings/agreement')">
                <span>Legal Documents</span>
                <img src="../../assets/img/UI/setting-line.svg" @click="$router.push('/settings/agreement')" class="line-setting pointer">
            </div>
            <div class="setting-el flex mt-1" @click="setActiveLogin">
                <span style="margin-left: 0; color: #505050; font-family: 'Rubik-Medium';">IP</span>
                <span>Login history</span>
                <img src="../../assets/img/UI/setting-line.svg" class="line-setting pointer">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'notif setting',
        methods: {
            Close() {
                this.$router.push('/settings')
            },
            setData(){
                this.$router.push('/settings')
            }
        }
    }
</script>