<template>
    <button :class="{btn: true, not_active: !active}">
      <slot>{{ label }}</slot>
      <div class="img-container">
        <img src="../assets/img/UI/arrow-right-icon.svg" style="width: 16px;">
      </div>
    </button>
</template>

<script>
export default {
  name: 'custom-button',
  data() {
	return {
		MyValue: ''
	}
  },
  props: {
    label: String,
    active: Boolean
  }
}
</script>

<style scoped>
  .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;
      border-radius: 10px;
      background-color: var(--orange-color);
      color: var(--child-bg-color);
      border: none;
      height: 67px;
      font-size: 16px;      
  }

  .not_active {
      background-color: #2E2E2E;
      color: #818181;
  }

  @media screen and (max-width: 500px) {
    .btn {
      width: auto;
      margin-right: auto;
      background-color: unset;
      height: 29px;
      color: var(--orange-color);
    }
    .btn .img-container {
      display: flex;
      justify-content: center;
      align-items: center;      
      height: 24px;
      width: 24px;
      border-radius: 6px;
      background-color: var(--orange-color);
    }
  }

</style>