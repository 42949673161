import configMixin from "./configMixin"

export default {
    mixins: [configMixin],
    data() {
        return {
            isActiveDelete: false,
        }
    },
    methods: {
        setActiveDel() {
            this.isActiveDelete = !this.isActiveDelete
        },
        deleteEsta() {
            this.setActiveDel()
        },
        closeEsta() {
            this.setActiveDel()
        },
        getDataAboutEstablishment() {
            if(this.$route.params?.establishment_id == null ||
                this.$route.params?.establishment_id == undefined) this.$route.push('/404')

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/team/get/' + this.$route, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let status = query.status

                if(status == 403) {
                    this.$route.push('/404')
                }

                let result = await query.json()

                if(result?.error == 'Unauthorized') {
                    this.$route.push('/404')
                }

                return Promise.resolve(result)
            }
            return response()
        }
        
    }
}