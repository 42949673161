<template>
    <error v-if="isError"/>
    <div class="outer-modal-div-form client-outer client-oter-dop" :class="{'calendar-active-cont': isSucces || isError}">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form w-800 client-page">  
                <div class="height-client-est flex">
                    <div class="flex w-320-mob desc-esta-container">
                        <img :src="'/img/uploads/logo/esta/' + estaData.logo" class="est-client-logo">
                        <div class="data-esta-client">
                            <h1>{{ estaData.name }}</h1>
                            <p class="desc-esta">{{ estaData.desc }}</p>
                        </div>
                    </div>
                    <div class="star-esta-s d-none-desc">
                        <img src="../../assets/img/UI/big-star-active.svg" v-for="star in estaData.star" @click="setStar(star)">
                        <img src="../../assets/img/UI/big-star-grey.svg" v-for="star_grey in 5 - estaData.star" @click="setStar(estaData.star + star_grey)">
                    </div>
                </div>
                <div class="esta-bottom-er wrapper-inner-modal">
                    <div class="flex flex-between flex-center flex-c bnmv" style="text-align: center;">
                        <div class="stick-client w-320">
                            <p class="px-16 d-none-desc center" style="color: white; font-family: 'Rubik-Regular'">Did you like everything?</p>
                            <div class="flex flex-between mt-3">
                                <div class="el-like-client" :class="{'el-like-client-active': formData.check == 'Quality products'}"> 
                                    <div class="form_radio_b form-setting">
                                        <input id="radio-1" type="radio" name="radio" value="Quality products" checked @click="setCheck('Quality products')">
                                        <label for="radio-1"></label>
                                    </div>
                                    <img src="../../assets/img/UI/star-dynamic-premium.png">
                                    <p>Quality<br>  products</p>
                                </div>
                                <div class="el-like-client" :class="{'el-like-client-active': formData.check == 'Excellent service'}"> 
                                    <div class="form_radio_b form-setting">
                                        <input id="radio-2" type="radio" name="radio" value="Excellent service" @click="setCheck('Excellent service')">
                                        <label for="radio-2"></label>
                                    </div>
                                    <img src="../../assets/img/UI/thumb-up-dynamic-premium.png">
                                    <p>Excellent<br>service</p>
                                </div>
                                <div class="el-like-client" :class="{'el-like-client-active': formData.check == 'Great atmosphere'}"> 
                                    <div class="form_radio_b form-setting">
                                        <input id="radio-3" type="radio" name="radio" value="Great atmosphere" @click="setCheck('Great atmosphere')">
                                        <label for="radio-3"></label>
                                    </div>
                                    <img src="../../assets/img/UI/heart-dynamic-premium.png">
                                    <p>Great<br>atmosphere</p>
                                </div>
                            </div>
                            <div class="center tyi" :class="{tyi: isAddComment}">
                                <button v-if="!isAddComment" class="btn-leave-client sbn mt-2" @click="setAddComment">Leave a comment</button>
                                <img src="../../assets/img/UI/close-calendar.svg" class="close-comment-cient pointer" @click="setAddComment" v-if="isAddComment">
                                <textarea class="comment-client" style="padding-right: 18px; font-size: 17px" v-if="isAddComment" placeholder="Comment here" maxlength="253" v-model="formData.comment" @input="e => {autoHeight(e)}"></textarea>
                                <p class="count-symbols" v-if="isAddComment"><span>{{ formData.comment.length }}</span>/150</p>
                            </div>
                        </div>
                        <div class="d-none-mobile order-1 mt-4">
                            <p class="other-text-color">Did you like everything?</p>
                            <svg xmlns="http://www.w3.org/2000/svg" class="mt-2" width="42" height="42" viewBox="0 0 42 42" v-for="star in estaData.star" @click="setStar(star)">
                                <path fill="rgba(242, 142, 34, 1)" d="M22.033 32.5043C21.4251 32.1631 20.6833 32.1631 20.0754 32.5043L12.5276 36.7403C11.0388 37.5758 9.25508 36.2821 9.58691 34.6074L11.2754 26.0859C11.4104 25.4048 11.1825 24.7025 10.6733 24.2305L4.30641 18.3278C3.05596 17.1685 3.73676 15.0764 5.42999 14.8751L14.0316 13.8523C14.7227 13.7701 15.3214 13.3345 15.6123 12.7022L19.2373 4.82385C19.9514 3.27182 22.157 3.27182 22.8711 4.82385L26.496 12.7022C26.7869 13.3345 27.3856 13.7701 28.0768 13.8523L36.6784 14.8751C38.3716 15.0764 39.0524 17.1685 37.8019 18.3278L31.435 24.2305C30.9259 24.7025 30.698 25.4048 30.8329 26.0859L32.5214 34.6074C32.8533 36.2821 31.0695 37.5758 29.5808 36.7403L22.033 32.5043Z"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" class="mt-2" width="42" height="42" viewBox="0 0 42 42" v-for="star_grey in 5 - estaData.star" @click="setStar(estaData.star + star_grey)">
                                <path fill="#303030" d="M22.033 32.5043C21.4251 32.1631 20.6833 32.1631 20.0754 32.5043L12.5276 36.7403C11.0388 37.5758 9.25508 36.2821 9.58691 34.6074L11.2754 26.0859C11.4104 25.4048 11.1825 24.7025 10.6733 24.2305L4.30641 18.3278C3.05596 17.1685 3.73676 15.0764 5.42999 14.8751L14.0316 13.8523C14.7227 13.7701 15.3214 13.3345 15.6123 12.7022L19.2373 4.82385C19.9514 3.27182 22.157 3.27182 22.8711 4.82385L26.496 12.7022C26.7869 13.3345 27.3856 13.7701 28.0768 13.8523L36.6784 14.8751C38.3716 15.0764 39.0524 17.1685 37.8019 18.3278L31.435 24.2305C30.9259 24.7025 30.698 25.4048 30.8329 26.0859L32.5214 34.6074C32.8533 36.2821 31.0695 37.5758 29.5808 36.7403L22.033 32.5043Z"/>
                            </svg>
                            
                        </div>
                        <div class="price-client w-320">
                            <p class="px-16" style="font-family: 'Rubik-Regular'; color: white" v-if="!isAddChoise">Enter the amount of the your order, and we will calculate the amount of tip for you</p>
                            <div class="mt-3" v-if="!isAddChoise">
                                <input type="text" class="input-client-esta" @input="e => {isNumber(e)}" v-model="formData.price" maxlength="15">
                                <img src="../../assets/img/UI/close-client-inp.svg" @click="delPrice" class="close-inp-price-client">
                            </div>
                            <div class="mt-3" v-if="isAddChoise">
                                <input type="text" placeholder="Amount of the tip" class="input-client-esta" @input="e => {isNumber(e)}" v-model="formData.manualCh" maxlength="15">
                                <img src="../../assets/img/UI/close-client-inp.svg" @click="delCh" class="close-inp-price-client">
                            </div>
                            <my-button v-if="isAddChoise" label="Choose order amount" @click="setAddChoise" style="width: 300px;height: 40px; margin-left: 10px; margin-top: -10px; margin-bottom: 10px" active="true"/>
                            <div class="set-ch" v-if=" !isAddChoise" >
                                <input type="text" class="input-client-esta sgh" disabled :value="formData.convers">
                              
                                <div class="flex pro-div-ch flex-between">
                                    <div class="el-pro-ch pointer" @click="setProcent(18)" :class="{'el-pro-ch-active': chProcent == 18}">18%</div>
                                    <div class="el-pro-ch pointer" @click="setProcent(20)" :class="{'el-pro-ch-active': chProcent == 20}">20%</div>
                                    <div class="el-pro-ch pointer" @click="setProcent(22)" :class="{'el-pro-ch-active': chProcent == 22}">22%</div>
                                    <div class="el-pro-ch pointer" @click="setProcent(25)" :class="{'el-pro-ch-active': chProcent == 25}">25%</div>
                                </div>
                                <my-button label="Choose manual amount of the tip" @click="setAddChoise" style="width: 300px;height: 40px; margin-left: 10px;margin-top: 10px;" active="true"/>
                            </div>
                            <p class="other-text-color center the-fin">the final payment amount will include the payment system commission and <a href="/docs/Fee Plan.pdf" class="user-client-agree">service fee</a></p>
                        </div>
                    </div>
                   
                    <div class="w-320-mob mt-1">
                        <my-button label="Go to pay" :active="isActiveBtn && !estaData.requirementsNotCompleted" class="mt-2" @click="sendData"/>
                    </div>
                    
                    <div class="flex mt-2">
                        <div class="form_radio_b">
                            <input id="radio-5" type="radio" checked>
                            <label for="radio-5"></label>
                        </div>
                        <p class="other-text-color ml-1 bottom-client">I agree with the <a href="/docs/Terms and Conditions.pdf"><span class="user-client-agree pointer">Terms & Conditions</span></a> of the <a href="/docs/Privacy Policy.pdf"><span class="user-client-agree pointer">Privacy Policy</span></a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>
import { data } from '@/components/calendar/calendar.cjs'
import checkEmplClientMixin from '@/mixins/checkEmplClientMixin'
import sendTipMixin from '@/mixins/sendTipMixin'

    export default {
        mixins: [checkEmplClientMixin, sendTipMixin],
        name: 'employee client',
        data() {
            return {
                estaData: { 
                    name: '',
                    logo: '',
                    star: 0,
                    username_empl: '',
                    username_esta: '',
                    role: '',
                    team_id: ''
                },
                formData: {
                    check: 'Quality products',
                    price: '$',
                    star: '',
                    comment: '',
                    convers: '',
                    manualCh: '',
                    email: ''
                },
                isAddComment: false,
                chProcent: 22,
                isAddChoise: false,
                isActiveBtn: false,
                isSucces: false,
                isError: false
            }
        },
        
        async beforeMount() {
 
            
            let our_price = Number(this.formData.price.split('$').join(''))
            this.formData.convers = (our_price * (this.chProcent / 100)).toFixed(2)
            
            this.getDataEmploy(this.$route.params.id.split("@").join("")).then(data => {
                this.estaData.name = data.name
                this.estaData.logo = data.logo
                this.estaData.username_empl = data.username_empl
                this.estaData.username_esta = data.username_esta
                this.estaData.role = data.role
                this.estaData.team_id = data.team_id
                this.estaData.desc = data.desc
                this.estaData.requirementsNotCompleted = data.requirements_not_completed;
            })
        },
        watch: {
            'formData.price': function() {
                if(this.formData.price.length > 1)this.formData.price = this.formData.price.replace(/[^0-9.]/g,'')
                let our_price = Number(this.formData.price.replace('$', ''))
                this.formData.convers = (our_price * (this.chProcent / 100)).toFixed(2)
                if(isNaN(this.formData.convers)) {
                    this.formData.price = String(this.formData.price).substring(0, String(this.formData.price).length - 1);
                }
               
                if(this.formData.price[0] != "$") {
                    this.formData.price = '$' + this.formData.price
                }

                if(this.formData.price != "") this.isActiveBtn = true
                else this.isActiveBtn = false
            },
            'formData.manualCh': function() {
                if(this.formData.manualCh.length > 1)this.formData.manualCh = this.formData.manualCh.replace(/[^0-9.]/g,'')
                let our_price = Number(this.formData.manualCh.replace('$', ''))
                if(isNaN(this.formData.convers)) {
                    this.formData.manualCh = String(this.formData.manualCh).substring(0, String(this.formData.manualCh).length - 1);
                }
               
                if(this.formData.manualCh[0] != "$") {
                    this.formData.manualCh = '$' + this.formData.manualCh
                }

                if(this.formData.manualCh != "" && this.formData.manualCh != "$") this.isActiveBtn = true
                else this.isActiveBtn = false

                console.log('manualCh', this.formData.manualCh);
            },
            'chProcent': function() {
                let our_price = Number(this.formData.price.split('$').join(''))
                this.formData.convers = (our_price * (this.chProcent / 100)).toFixed(2)
            },
            isAddChoise() {
                if(this.isAddChoise == true) {
                    if(this.formData.manualCh == '') this.isActiveBtn = false
                    else this.isActiveBtn = true
                }
            }
        },
        methods: {
            setAddChoise() {
                this.isAddChoise = !this.isAddChoise
            },
            setCheck(value) {
                this.formData.check = value
            },
            isNumber(e) {
                e.target.value =  e.target.value.replace(/[^0-9.]/g,'');
                if(e.target.value[0] != "$") {
                    e.target.value = '$' + e.target.value
                }
            },
            delPrice() {
                this.formData.price = '$'
            },
            delCh() {
                this.formData.manualCh = '$'
            },
            setStar(value) {
                console.log(value)
                this.estaData.star = value
                this.formData.star = value
            },
            autoHeight(e) {
                if(e.target.scrollHeight > 136) {
                    e.target.style.height = 'auto';
                    let applyNow = e.target.style.offsetHeight;
                    e.target.style.height = e.target.scrollHeight - 20 + 'px';
                }
            },
            setAddComment() {
                this.formData.comment = ''
                this.isAddComment = !this.isAddComment
            },
            setProcent(value) {
                this.chProcent = value
            },
            sendData() {
                if(this.isActiveBtn) {
                    let dataSend = {}

                    if(this.formData.manualCh.split('$').join('') == '') {
                        dataSend.sum = this.formData.convers.split("$").join("")
                        console.log(this.formData.convers)
                    } else {
                        dataSend.sum = this.formData.manualCh.split("$").join("")
                    }

                    dataSend.username_empl = this.estaData.username_empl
                    dataSend.username_esta = this.estaData.username_esta
                    dataSend.role = this.estaData.role


                    if(this.estaData.star == 0 || this.estaData.star == null) dataSend.stars == null
                    else dataSend.stars = this.estaData.star

                    if(this.formData.comment == "") dataSend.comment == null
                    else dataSend.comment = this.formData.comment

                    const validateEmail = (email) => {
                        return String(email)
                            .toLowerCase()
                            .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            );
                    };

                    if (this.formData.email.trim() != "" && validateEmail(this.formData.email.trim())) {
                        dataSend.email = this.formData.email.trim()
                    }
                    this.sendMoney(dataSend.sum, dataSend.username_empl, dataSend.username_esta, dataSend.role, this.estaData.star, dataSend.comment, this.formData.check, this.estaData.team_id, dataSend?.email).then(data => {
                        if(data?.token != undefined) {
                            localStorage.setItem('payment-secret', data.token)
                            this.$router.push('/client/payment?token=' + data.token)
                        }
                        else this.isError = true
                    })
                }
            }
        }
    }
</script>
<style scoped>

.desc-esta-container {
    flex: 1;
}

@media screen and (max-width: 500px) {
    .wrapper-inner-modal {
        position: static
    }
}
</style>