<template>
    <div class="cale">
        <div class="inner-modal-div-form">
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
            <div v-if="!isEmptyObject(user)">
                <h2>Transfer to the user</h2>
                <div class="flex mt-4 ml-4 mr-4">
                    <div class="flex container-left">
                        <img  
                            :src="'/img/uploads/logo/esta/' + user.logo"
                            v-if="user.logo != undefined && 
                            user.logo != null"
                        >
                        <!--
                        <img src="../../assets/img/UI/transfer-funds-icon.png">
                        -->
                    </div>
                    <div class="flex flex-column container-right">
                        <h2>{{ user.name }}</h2>
                        <h3>(@{{user.username_empl}})</h3>
                    </div>
                </div>
                <p class="payout-title mt-4 ml-2 mr-2">
                    If you are sure you want to transfer funds to this user,
                    enter the amount in the field below and confirm.
                </p>
                <p class="payout-title mt-4">Available for withdrawal: ${{balance}}</p>
                <my-input 
                    label="Enter amount" 
                    type="number" 
                    bgColor="#252525" 
                    v-model="amount" 
                    class="mt-2 ml-4 mr-4"
                    @keydown="codeKeyDown"
                />
                <p class="error-input-text err-au" v-if="error.isMore">Available for withdrawal:  ${{balance}}</p>
                <div class="m-x-auto w-320-mob mt-2">
                    <my-button :active="isActiveTransferFundsBtn" label="Confirm and transfer" @click="transferFunds" class=""/>
                </div>
                <p class="error-input-text err-au" v-if="error.isTransferInvalid">An error occurred, please try later</p>
                <p class="error-input-text err-au green-text" v-if="error.isTransferSuccess">Transfer successful!</p>
            </div>
            <div v-else>
                <p class="payout-title mt-4 ml-2 mr-2">
                    User not found. You may have entered an incorrect username. 
                    Please go back to the previous step and try again
                </p>
                <div class="m-x-auto w-320-mob mt-2">
                    <my-button :active="true" label="Back" @click="close" class=""/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'
import jsonMixin from "@/mixins/jsonMixin";

    export default {
        name: 'transfer-funds',
        mixins: [configMixin, jsonMixin],
        data() {
            return {
                amount: 0,
                error: {
                    isTransferInvalid: false,
                    isTransferSuccess: false,
                    isMore: false
                },
                isValid: false,
                isValidEmail: false,
                siActiveEmailErr: false,
                isAvtivePhonErr: false,
                isActiveTransferFundsBtn: false
            }
        },
        props: {
            username: String,
            balance: String,
            user: Object
        },
        watch: {
            amount() {
                console.log('amount', this.amount);
                console.log('balance', this.balance);
                if (parseFloat(this.amount) > parseFloat(this.balance)) {
                    this.error.isMore = true
                } else {
                    this.error.isMore = false
                }
                this.isActive()
            },
        },
        methods: {
            isActive() {
                if(this.amount != 0 && !this.error.isMore) {
                    this.isActiveTransferFundsBtn = true
                } else {
                    this.isActiveTransferFundsBtn = false
                }
            },
            transferFunds() {
                if (this.isActiveTransferFundsBtn) {

                    const body = {
                        amount: this.amount,
                        username_empl: this.user.username_empl
                    }

                    fetch('/api/transaction/transfer', {
                        method: "POST",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('auth-token'),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    }).then(data => data.json()).then(data => {
                        if(data?.id != undefined) {
//                            this.balance = data.balance
                            this.amount = 0
                            this.error.isTransferInvalid = false
                            this.error.isTransferSuccess = true
                        } else {
                            this.error.isTransferInvalid = true
                            this.error.isTransferSuccess = false
                        }
                    }).catch(err => {
                        this.error.isTransferInvalid = true
                        this.error.isTransferSuccess = false
                    })
                }
            },
            close() {
                this.$emit('close')
            },
        },        
    }
</script>

<style scoped> 
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color);
    margin-left: auto;
    margin-right: auto;
}
.cale {
    position: absolute;
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 1;
    width: 400px;
    background-color: #1E1E1E;
    border-radius: 10px;
}

.close {
    position: absolute;
    margin-left: 55%;
    margin-top: -40px;
}
.inner-modal-div-form {
    background: #1E1E1E;
}
.inner-modal-div-form {
    border-radius: 10px;
}
.container-left {
    flex: 1;
}
.container-left img {
    max-width: 100%;
    border-radius: 100%;
}
.container-right {
    flex: 2;
    justify-content: center;
}
@media screen and (max-width: 500px) {
    .avatar-empl {
        margin-top: 20px;
    }
    .btn-show {
      margin-left: 110px;
    }
    .inner-modal-div-form {
        width: 100%;
        padding: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
    }
    .inner-modal-div-form {
    background: #1E1E1E;
}
    .inner-modal-div-form h3:nth-child(1) {
        margin-top: 20px;
    }
    .cale {
        padding: 20px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
        top: 50px;
        min-height: 100vh;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .close {
        margin-left: 40%;
        position: absolute;
        margin-top: -0px;
    }
    .btnj-short  {
        width: 100%;
    }
    .select-w {
        width: 100% !important;
    }
    .select {
        width: 100%;
    }
}
</style>