<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getDateCalendar" v-if="isActiveCalendar"/>
    <delete-estas v-if="isActiveDelete" @close="closeEsta" @delete="deleteEsta"/>
    <add-manager v-if="isActiveAddManagers" @close="setActiveAddManagers" @getmembers="updateManager"/>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': (isActiveCalendar || isActiveDelete || isActiveAddManagers), 'staff-wrapper': true}">
            <div class="flex">
                <img src="../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/dashboard')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">{{estaData.name}}</h2>      
            </div>
            <div class="main-staff manager-esta-wrapper main-team-st"> 
                <div class="main-nav-mobile d-none-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex">
                        <h2 :class="{ active_nav_panel: false}" @click="$router.push('/establishment/transaction/' + $route.params?.establishment_id)" >Transactions</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': false}" @click="$router.push('/establishment/team/' + $route.params?.establishment_id)">Team</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: true}" @click="$router.push('/establishment/managers/' + $route.params?.establishment_id)" >Managers</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/establishment/information/' + $route.params?.establishment_id)">Information</h2>
                    </div>
                    <div class="main-line-menu">
                        <div class="menu-mobile-nav-line flex">
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-1"></div>
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-2"></div>
                            <div :class="{'fr-main-inp-active': true}" class="mobile-nav-est-3"></div>
                            <div  :class="{'fr-main-inp-active': false}" class="mobile-nav-est-4"></div>
                        </div>
                    </div>
                </div>

                <div class="top-bar-staff d-none-desc">
                <div class="flex">
                    <div class="first-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/transaction/' + $route.params?.establishment_id)">Transactions</p>
                    </div>
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/team/' + $route.params?.establishment_id)">Team</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true}" @click="$router.push('/establishment/managers/' + $route.params?.establishment_id)">Managers</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/information/' + $route.params?.establishment_id)">Information</p>
                    </div> 
                    <div class="delete-esta-btn" @click="setActiveDel" v-if="this.$route.user.job != 'Manager'">Delete establishment</div>
                </div>
                <div class="border-staff flex">
                    <div :class="{'active-first': true, 'active-nav-staff': false, 'trans-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'team-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': true, 'manage-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'info-line': true}"></div>
                </div>
               </div>
               <div class="div-null-height-2"></div>
               <manager-esta class="mt-2 est-mamger-m pointer" 
               @delete="delManager" 
               @changeaccess="setAccess" 
               v-for="item in managers" 
               :avatar="item.avatar" 
               :name="item.name" 
               :id="item.id" 
               :phone="item.phone" 
               :activate="item.status == 'true'"
               :ac="item.acces"/>
               <div class="add-member-manager add-memeber-manger-esta  m-x-auto" @click="setActiveAddManagers"  v-if="this.$route.user.job != 'Manager'">
                        <img src="../../assets/img/UI/add-member.svg">
                        <span>add member</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import dashboardMixin from '@/mixins/dashboardMixin';
import establishmentMixin from '@/mixins/establishmentMixin';
import getEstablismntsManagerMixin from '@/mixins/getEstablismntsManagerMixin';
import configMixin from "@/mixins/configMixin"

    export default {
        name: 'managers establishment',
        mixins: [dashboardMixin, establishmentMixin, getEstablismntsManagerMixin],
        data() {
            return {
                managers: '',
                    // {id: 1, name: 'Denis Petrov', avatar: 'ava.jpg', activate: true, phone: '+7 930 245 15 20', access: 'Full access'},
                
                member: '',
                isActiveAddManagers: false,
                userData: {
                    name: ''
                },
                estaData: {
                    name: ''
                }
            }
        },
        methods: {
            setAccess(value, id) {
                console.log(id)
                console.log(value)
                this.managers[this.managers.findIndex(user => user.id == Number(id))].access = value
                console.log(this.managers)
            },
            setActiveAddManagers() {
                this.isActiveAddManagers = !this.isActiveAddManagers
            },
            delManager(id) {
                this.deleteManager(id)
                this.managers.splice([this.managers.findIndex(user => user.id == id)], 1)
            },
            updateManager(user) {
                this.updateManagerMixin(user).then(data => {
                    window.location.reload();
                })
            },
            getMemeber() {
                    let response = async() => {
                        console.log(this.$route.params?.establishment_id)
                        let query = await fetch(this.config.API_URL + '/team/avilable/managers/' + this.$route.params?.establishment_id, {
                            method: "GET",
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                            }
                        })
    
                        if(query.status != 200)  this.$router.push('/dashboard')

                        let result = await query.json()

                        if(result?.id == undefined 
                        || result?.id == null) this.$router.push('/dashboard')
    
                        return Promise.resolve(result)
                    }
                    return response()
                },
                getInfo() {
            let response = async() => {
                let query = await fetch(this.config.API_URL + '/team/info/' + this.$route.params?.establishment_id, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                if(query.status != 200)  this.$router.push('/dashboard')

                let result = await query.json()

                if(result?.id == undefined 
                || result?.id == null) this.$router.push('/dashboard')

                return Promise.resolve(result)
            }

            return response().then(data => {
                try {
                    this.estaData.name = data.name
                } catch(err) {
                    this.$router.push('/dashboard')
                }
            }).catch(err => {
                this.$router.push('/dashboard')
            })
           }
        
        },
        beforeMount() {
        //     if(this.$route.params?.establishment_id == null || 
        // this.$route.params?.establishment_id == 'undefined' ||
        // this.$route.params?.establishment_id == undefined ||
        // isNaN(Number(this.$route.params?.establishment_id))) return this.$router.push('/dashboard')

            
        this.getManagers().then(data => {
                try {
                    this.managers = data.rows.map(el => {
                        if( el == null || el == undefined) return
                        else return el
                    })
                    this.userData.name = data.name
                } catch(err)  {
                    this.$router.push('/dashboard')
                }
            }).catch(err => {
                this.$router.push('/dashboard')
            })
            this.getInfo()
        }
    }
</script>