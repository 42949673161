<template>
 <div class="container">
        <div class="wrapper">
            <div class="flex date-filter-empl mt-2">
                    <div class="el-date-filter-empl pointer" @click="$router.push('/developer/main')" :class="{'el-date-filter-empl-active': false}">
                        Business
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="$router.push('/developer/users')" :class="{'el-date-filter-empl-active': false}">
                        Users
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="$router.push('/developer/transactions')" :class="{'el-date-filter-empl-active': true}">
                        Transaction
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="$router.push('/developer/invite')" :class="{'el-date-filter-empl-active': false}">
                        Invite
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="LogOut" style="border-color: #EA4335; background-color: rgba(234, 67, 53, 0.05);">
                        Log out
                    </div>
            </div>
            <div style="height: 20px"></div>
            <history-transaction
            v-for="el in business"
            :id="zeroPad(el.id)"
            :time="el.time"
            :employee="el['User.username']"
            :price="el.sum"
            :stars="(el.stars == null) ? 0 : Number(el.stars)"
            :desc="(el.comment == null) ? '' : el.comment"
            />
            <paginate :count="Number(lastpage)"
            link="/developer/transactions"/>
        </div>
    </div>
</template>

<script>
 import configMixin from '@/mixins/configMixin'
    export default {
        name: 'transaction developer',
        mixins: [configMixin],
        data() {
            return {
                business: [],
                lastpage: 0
            }
        },
        watch:{
            '$route.query.page': function() {
                this.getBusiness()
            }
        },
        methods: {
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            async getBusiness() {

                let page_id = (this.$route.query.page == undefined) ? 1 : this.$route.query.page

                let query = await fetch(this.config.API_URL + '/developer/transactions?page=' + page_id, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            },
            LogOut() {
                localStorage.clear()
                this.$router.push('/')
            },
        },
        beforeMount() {
            this.getBusiness().then(data => {
                this.business = data.data
                this.lastpage = Math.ceil(data.count / 20)
            })
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
    }
    .wrapper {
        width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 500px) {
        .wrapper {
            width: 320px;
        }
    }
</style>