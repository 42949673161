<template>
    <div class="container">
        <div class="wrapper">
            <div class="flex date-filter-empl mt-2">
                    <div class="el-date-filter-empl pointer" @click="$router.push('/developer/main')" :class="{'el-date-filter-empl-active': false}">
                        Business
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="$router.push('/developer/users')" :class="{'el-date-filter-empl-active': false}">
                        Users
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="$router.push('/developer/transactions')" :class="{'el-date-filter-empl-active': false}">
                        Transaction
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="$router.push('/developer/invite')" :class="{'el-date-filter-empl-active': true}">
                        Invite
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="LogOut" style="border-color: #EA4335; background-color: rgba(234, 67, 53, 0.05);">
                        Log out
                    </div>
            </div>
            <div style="height: 20px"></div>
            <div class="form">
                <h2>CREATE NEW INVITE CODE</h2>
                <input class="inpe" placeholder="Code" v-model="code">
                <my-button label="Create" :active="isActive" class="mt-2" @click="createCode"/>
            </div>
           <div class="invite" v-for="el in business">
            {{ el.code }}
                <img src="../../assets/img/UI/close-calendar.svg" @click="deleteCode(el.id)" class="pointer">
           </div>
        </div>
    </div>
</template>

<script>
    import configMixin from '@/mixins/configMixin'

    export default {
        name: 'developer invite',
        mixins: [configMixin],
        data() {
            return {
                business: [],
                lastpage: 0,
                isActive: false,
                code: ''
            }
        },
        watch:{
            '$route.query.page': function() {
                this.getBusiness()
            },
            code() {
                this.isIsset()
            }
        },
        methods: {
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            async getBusiness() {

                let query = await fetch(this.config.API_URL + '/developer/invite', {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            },
            async createCode() {
                if(this.isActive) {
                    let body = {
                        code: this.code
                    }

                    let query = await fetch(this.config.API_URL + '/developer/invite', {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    })

                    let result = await query.json()
                    this.$router.go()
                }
            },
            async deleteCode(id) {
                let query = await fetch(this.config.API_URL + '/developer/invite/' + id, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    },
                })

               this.$router.go()
            },
            LogOut() {
                localStorage.clear()
                this.$router.push('/')
            },
            isIsset() {
                if(this.code != '') this.isActive = true
                else this.isActive = false
            }
        },
        beforeMount() {
            this.getBusiness().then(data => {
                this.business = data.data
                this.lastpage = Math.ceil(data.count / 20)
            })
        }
    }
</script>

<style scoped>
.form {
    width: 320px;
    border-radius: 10px;
    background-color: #171717;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    margin-bottom: 18px;
    margin-top: 20px;
    text-align: center;
}
.inpe {
    border: none;
    border-radius: 20px;
    background-color: #1E1E1E;
    width: 100%;
    height: 47px;
    margin-top: 20px;
    padding-left: 2%;
    outline: none;
    color: white;
    font-size: 20px;
}
.invite {
    width: 100%;
    border-radius: 10px;
    background-color: #171717;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
}
    .container {
        width: 100%;
    }
    .wrapper {
        width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 500px) {
        .wrapper {
            width: 320px;
        }
    }
</style>