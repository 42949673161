<template>
    <div class="container-dash">
        <employee-dashboard/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': isActiveAdd || isAddMemberTeam || isDeleteTeam, 'staff-wrapper': true}">
            <h2 class="main-title-dash pointer" @click="$router.push('/employee/workspace')"><img src="../../../assets/img/UI/left-strel.svg"> QR code</h2>
            <div class="inner-modal-div-form inner-modal-div-form-dop" style="position: static">
                   <div class="wrapper-inner-modal">
                  
                   <div class="FatherQRcode mt-4">
                       <!-- <div><img src="../../assets/img/UI/ReloadQRcode.png" @click="updateQr"></div> -->
                       <img :src="qr_code" class="qr-img" style="width: 300px;">
                       <!-- <div class="ml-1"><img src="../../assets/img/UI/DownloadQRcode.png" @click="downloadImage"></div> -->
                   </div>
                   <h3 class="mt-2">{{ name }}</h3>
                   <p class="Logo-text mt-1">{{ (job == "Waiter") ? 'Employee' : job }}</p>
                   <p class="mt-2 main-text-color">@{{ username }}</p>
                   <div class="w-320">
                    <my-button label="Update" :active="true" @click="updateQr" class="mt-4"/>  
                    </div> 
                    </div>
                       
                       
               </div>
        </div>
    </div>
  
       
   </template>
   
   <script>
   import { mapState, mapGetters, mapMutations } from 'vuex'
 
   import refreshQr from '@/mixins/refreshQr'
   import loaderMixin from '@/mixins/loaderMixin';
   let QRCode = require('qrcode')
   
   export default {
       name: 'te',
       mixins: [ refreshQr, loaderMixin],
       data() {
           return {
               text: 'https://merciq.app/@login/',
               inviteCode: '',
               isActive: true,
               qr_code: '',
               name: '',
               job: '',
               dataUser: {
                name: '',
                qr: ''
               },
               username: ''
           }
       },
       methods: {
           setInviteCode(value) {
               this.inviteCode = value
               if(value != "") this.isActive = true
               else this.isActive = false
           },
           sendData() {
               this.$router.push("/FillingBusiness5")
           },
           updateQr() {
               this.refreshQr().then(data => {
                   QRCode.toDataURL(window.origin + '/client/employee/' + data.qr, (err, url) => {
                       this.qr_code = url
                   })
               })
           },
           async downloadImage() {
               const blob = await (await fetch(this.qr_code)).blob();
               const url = URL.createObjectURL(blob);
               Object.assign(document.createElement('a'), { href: url, download: 'qr.png' })
                   .click();
               URL.revokeObjectURL(url);
           },
           getData() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/employee/main', {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response()
            },
       },
       beforeMount() {
            this.getData().then(data => {
                let last_name = (data.user.last_name == null) ? "" : data.user.last_name

                this.name = data.user.name + " " + last_name,
                this.job = data.user.job
                this.username = data.user.username

                QRCode.toDataURL(window.origin + '/client/employee/' + data.user.qr_id, (err, url) => {
                    let result = url
                    this.qr_code = result

                    this.setLoader(false);
                })
               
            })

           
        }
      
   }
   </script>