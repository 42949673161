import configMixin from "./configMixin"

export default {
    mixins: [configMixin],
    watch: {
  
    },
    beforeMount() {
        if(this.$route.params?.establishment_id == undefined || 
        this.$route.params?.establishment_id == null || 
        isNaN(Number(this.$route.params?.establishment_id))) return this.$router.push('/dashboard')
    }
}