<template>

    <div class="el-history-transaction" ref="sty"  style="padding-bottom: 0px;">
        <div class="el-top-history flex" style="align-items: flex-start;"> 
            <img 
            :src="'/img/uploads/logo/esta/' + logo"
            class="f-img">
            <div>
                <div class="flex mt-1">
                    <p class="ids">Id: {{ id }}</p>
                    <p class="times">Date of creation: {{ createdAt }}</p>
                    <p class="employees">Name: {{ name }}</p>
                </div>
                <div class="flex mt-1">
                    <p class="times">CEO name: {{ ceo_name }}</p>
                    <p class="times">CEO email: {{ ceo_email }}</p>
                    <p class="times">CEO phone: {{ ceo_phone }}</p>
                </div>
                <div class="flex mt-1">
                    <p class="times">{{ country }}</p>
                    <p class="times">{{ address }}</p>
                    <p class="times">{{ (state == '' 
                    || state == undefined 
                    || state == null) ? '' : state }}</p>
                    <p class="times">{{ city }}</p>
                </div>
                <div class="flex mt-1">
                    <p class="times">Distribution model: {{ distribution }}</p>
                    <p class="times">Set a service fee: {{ (service_fee == null) ? 'null' : service_fee + '%' }}</p>
                    <p class="times">Zip: {{ zip }}</p>
                </div>
            </div>
            <div class="stars flex">
            </div>
            <p class="price ml-2">Type: {{ type }}</p>
        </div>
        <div class="desc" style="padding-bottom: 20px; margin-top: -10px">
            <p class="desc">Description: {{ desc }}</p>
        </div>
    </div>

    <div class="el-history-transaction-mobile mt-1" @click="setShow">
       <div class="flex">
            <img 
            :src="'/img/uploads/logo/esta/' + logo"
            class="f-img">
            <div >
                <p class="ids">{{ id }}</p>
                <p class="ids mt-1">{{ type }}</p>
            </div>
            <p class="price mobile-price">{{ name }}</p>
       </div>

       <div class=" mt-2" v-if="show">
            <p class="times" style="margin-left: 0">Date of creation: {{ createdAt }}</p>
            <p class="times">CEO name: {{ ceo_name }}</p>
            <p class="times">CEO email: {{ ceo_email }}</p>
            <p class="times">CEO phone: {{ ceo_phone }}</p>
            <p class="times">Country: {{ country }}</p>
            <p class="times">Adsress: {{ address }}</p>
            <p class="times">State: {{ (state == '' 
            || state == undefined 
            || state == null) ? '' : state }}</p>
            <p class="times">City: {{ city }}</p>
            <p class="times">Distribution model: {{ distribution }}</p>
            <p class="times">Set a service fee: {{ (service_fee == null) ? 'null' : service_fee + '%' }}</p>
            <p class="times">Zip: {{ zip }}</p>
        </div>
        <p class="desc" v-if="show">Description: {{ desc }}</p>
    </div>

</template>

<script>
    export default {
        name: 'business',
        props: {
            id: String,
            ceo_name: String,
            ceo_phone: String,
            ceo_occupation: String,
            ceo_email: String,
            createdAt: String,
            name: String,
            desc: String,
            logo: String,
            type: String,
            country: String,
            address: String,
            city: String,
            state: String,
            zip: String,
            distribution: String,
            service_fee: String,
        },
        data() {
            return {
                show: false
            }
        },
        methods: {
            setShow() {
                this.show = !this.show
            }
        }
    }
</script>

<style scoped>
.f-img {
    width: 70px;
    border-radius: 100%;
}
.el-history-transaction {
    background-color: #252525;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
}
.el-top-history {
    padding-bottom: 20px;
}
.ids {
    margin-left: 10px;
    font-family: 'Rubik-Regular';
    color: var(--other-text-color)
}
.times {
    color: var(--other-text-color);
    margin-left: 20px;
}
.employees {
    margin-left: 60px;
    color: white;
    font-family: 'Rubik-Regular';
}
.employee-s {
    font-family: 'Rubik-Regular';
    color: var(--other-text-color)
}
.stars {
    margin-left: auto;
}
.stars img {
    margin-left: 5px;
}
.price {
    color: white;
}
.line-border {
    width: 104.5%;
    margin-left: -2.25% !important;
    margin-left: auto;
    height: 1px;
    background-color: #2E2E2E;
}
.desc {
    margin-top: 20px;
    font-size: 14px;
    color: var(--other-text-color)
}
.el-history-transaction-mobile {
        display: none;
    }
@media screen and (max-width: 500px) {
    .el-history-transaction-mobile {
        display: block;
        padding: 20px;
        border-radius: 10px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        background-color: #252525;
    }
    .el-history-transaction {
        display: none;
    }
    .mobile-price {
        margin-left: 60px;
    }
    .employees {
        margin-left: auto;
    }
    .stars {
        margin-left: 0;
    }
    .f-img {
        width: 50px;
    }
    .times {
        margin-top: 10px;
        margin-left: 0;
    }
}
</style>