export const codeModule = {
    state: () => ({
        data: ''
    }),
    getters: {
        getData(state) {
            return state.data
        }
    },
    mutations: {
        setData(state, value) {
            state.data = value
        }
    },
    actions: {

    },
    namespaced: true
}