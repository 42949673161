<template>
    <modal-calendar  @closecalendar="setActiveCalendar" @setcalendar="getCalendar" v-if="isActiveCalendar"/>
    <delete-estas v-if="isActiveDelete" @close="closeEsta" @delete="deleteEsta"/>
    <div class="container-dash">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'calendar-active-cont': isActiveCalendar, 'staff-wrapper': true}">
            <div class="flex">
                <img src="../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/dashboard')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0">{{estaData.name}}</h2>     
            </div>
            <div class="main-staff main-team-st"> 
                <div class="main-nav-mobile d-none-mobile" ref="mobile_menu" >
                    <div class="min-panel-nav flex">
                        <h2 :class="{ active_nav_panel: true}" @click="$router.push('/establishment/transaction/' + $route.params?.establishment_id)" >Transactions</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': false}" @click="$router.push('/establishment/team/' + $route.params?.establishment_id)">Team</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/establishment/managers/' + $route.params?.establishment_id)">Managers</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: false}" @click="$router.push('/establishment/information/' + $route.params?.establishment_id)">Information</h2>
                    </div>
                    <div class="main-line-menu">
                        <div class="menu-mobile-nav-line flex">
                            <div :class="{'fr-main-inp-active': true}" class="mobile-nav-est-1"></div>
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-2"></div>
                            <div :class="{'fr-main-inp-active': false}" class="mobile-nav-est-3"></div>
                            <div  :class="{'fr-main-inp-active': false}" class="mobile-nav-est-4"></div>
                        </div>
                    </div>
                </div>

                <div class="top-bar-staff d-none-desc">
                <div class="flex">
                    <div class="first-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': true}" @click="$router.push('/establishment/transaction/' + $route.params?.establishment_id)">Transactions</p>
                    </div>
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/team/' + $route.params?.establishment_id)">Team</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/managers/' + $route.params?.establishment_id)">Managers</p>
                    </div> 
                    <div class="second-staff-nav">
                        <p :class="{'nav-staff': true, 'pointer': true, 'active-staff-nav': false}" @click="$router.push('/establishment/information/' + $route.params?.establishment_id)">Information</p>
                    </div> 
                    <div class="delete-esta-btn" @click="setActiveDel" v-if="this.$route.user.job != 'Manager'">Delete establishment</div>
                </div>
                <div class="border-staff flex">
                    <div :class="{'active-first': true, 'active-nav-staff': true, 'trans-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'team-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'manage-line': true}"></div>
                    <div :class="{'second-first': true, 'active-nav-staff': false, 'info-line': true}"></div>
                </div>
               </div>
               <div class="div-null-height-1"></div>
                <div class="flex mt-3">
                    <div class="min-panel-end flex panel-average">
                        <div class="panel-calendar-min ml-3" @click="setActiveCalendar">
                            <img src="../../assets/img/UI/calendar-min-panel.svg">
                        </div>
                        <div class="min-panel-date-filter flex ml-1">
                            <div :class="{'panel-date-el': true, 'active-date': datePanel[0]}" @click="setDatePanel(0), getFinance('Day')">Day</div>
                            <div :class="{'panel-date-el': true, 'active-date': datePanel[1]}" @click="setDatePanel(1), getFinance('Week')">Week</div>
                            <div :class="{'panel-date-el': true, 'active-date': datePanel[2]}" @click="setDatePanel(2), getFinance('Month')">Month</div>
                            <div :class="{'panel-date-el': true, 'active-date': datePanel[3]}" @click="setDatePanel(3), getFinance('Year')">Year</div>
                        </div>
                    </div>
                </div>
                <div class="user-transactions mt-3">
                    <history-transaction v-for="el in trans"
                        :id="zeroPad(el.id)" 
                        :time="el.time" 
                        :price="el.sum" 
                        :stars="(el.stars == null) ? 0 : el.stars" 
                        :employee="el?.User?.name" 
                        :desc="(el.comment == null) ? '' : el.comment" 
                        class="mt-1"
                        :type="el.type"
                        :additionalInfo="el.additional_info"
                    />
                    <p v-if="trans.length == 0" class="none-info">At the moment, we don’t have enough data to display the information on this page. Try again later when you get enough incoming transactions</p>
                </div>
                <paginate :count="Number(lastpage)" link="/establishment/transaction/"/>
            </div>
        </div>
        
    </div>
</template>
    
<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import establishmentMixin from '@/mixins/establishmentMixin';
    import configMixin from '@/mixins/configMixin';
    import dateIconMixin from '@/mixins/dateIconMixin';

    export default {
        name: 'transaction establishment',
        mixins: [dashboardMixin, establishmentMixin, configMixin, dateIconMixin],
        data() {
            return {
                trans: '',
                balance: '',
                lastpage: 0,
                line_arr: [],
                trans: [],
                estaData: {
                    name: ''
                }
            }
        },
        watch: {
            '$route.query.page': function() {
                this.getFinance()
            },
            
        },
        methods: {
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            getCalendar(value) {
                let date = (value == null || value == undefined) ? null : JSON.stringify({date: value})
                this.getFinance(value)
                this.getDateIcon(value)
            },
            delCalendar() {
                this.getFinance(null)
                this.delDateIcon()
            },
            getFinance(date) {
                let dateFilter = (date == null || date == undefined) ? '' : '&date=' + date
                
                if(date != "Day" && date != 'Week' && date != 'Month' && date != 'Year' && date != null && date != undefined) {
                    dateFilter = (date == null || date == undefined) ? '' : '&date=' + JSON.stringify({date: date})
                }
                let page = (this.$route.query?.page == undefined || this.$route.query?.page == null || this.$route.query?.page == "") ? 
                1 : this.$route.query?.page

                let response = async () => {
                    let query = await fetch(this.config.API_URL + '/transaction/establishment/' + this.$route.params?.establishment_id + '?page=' + page + dateFilter, {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    if(query.status != 200)  this.$router.push('/dashboard')

                    let result = await query.json()

                    if(result?.id == undefined 
                    || result?.id == null) this.$router.push('/dashboard')

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    this.trans = data.rows
                    this.balance = data.balance
                    this.lastpage = Math.ceil(data.count / 10)
                    this.estaData.name = data.name
                })
            }
        },
        beforeMount() {
            if(this.$route.params?.establishment_id == null || 
        this.$route.params?.establishment_id == 'undefined' ||
        this.$route.params?.establishment_id == undefined ||
        isNaN(Number(this.$route.params?.establishment_id))) return this.$router.push('/dashboard')

            this.getFinance()
        }
    }
</script>