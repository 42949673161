<template>
    <div class="outer-modal-div-form client-outer">
           <div class="middle-modal-div-form">
               <div class="inner-modal-div-form w-320 client-wrapper clint-nm">  
                <div class="flex" style="width: 100%; justify-content: center;">
                    <h1 class="logo-client-start m-x-auto">Merci<span class="h orange-color q-logo">Q</span></h1>
                </div>
                    <p class="other-text-color mt-2 client-main-test">Enter username to leave a tip</p>
                    <div class="mt-6">
                        <div class="w-320 flex">
                            <input 
                                class="client-start-input w-350" 
                                placeholder="@username" 
                                v-model="username"
                                @keydown="codeKeyDown"
                            >
                            <img src="../../assets/img/UI/client-right-strel.svg" @click="findEmployee" class="strel-input-client">
                        </div>
                        <p class="error-input-text err-au" v-if="isError">Employee not found</p> 
                        <div class="flex flex-between w-320 mt-2 flex-all-center">
                            <div class="row-1"></div>
                            <p class="other-text-color client-main-test">Or</p>
                            <div class="row-1"></div>
                        </div>
                        <div class="w-320 flex mt-2 pointer" @click="$router.push('/client/scan')">
                            <div class="qr-3-div">
                                <img src="../../assets/img/UI/QRclient.svg" class="qr-3" width="30">
                            </div>
                            <input type="button" class="client-start-input inp-2" value="Scan the QR" style="border-radius: 0px 10px 10px 0 !important;">
                            <img src="../../assets/img/UI/client-right-strel.svg" class="strel-input-client">
                        </div>
                        <!--
                        <p class="other-text-color mt-2 center d-none-mobile">TO ACCESS BUSINESS ACCOUNT</p>
                        <p class="orange-color pointer mt-1 center d-none-mobile"><span @click="$router.push('/staff/signin')">SIGN IN</span> / <span @click="$router.push('/signupshort')">SIGN UP</span></p>
                        <p class="other-text-color mt-8 center d-none-mobile"><a class="other-text-color" href="/docs/tip guide.pdf">Need help with tipping? Click here for guide</a></p>                        
                        -->
                    </div>
                </div>
                <p class="other-text-color mt-2 center">TO ACCESS BUSINESS ACCOUNT</p>
                <p class="orange-color pointer mt-1 center"><span @click="$router.push('/staff/signin')">SIGN IN</span> / <span @click="$router.push('/signupshort')">SIGN UP</span></p>
                <p class="other-text-color mt-8 center"><a class="other-text-color" href="/docs/tip guide.pdf">Need help with tipping? Click here for guide</a></p>
           </div>
       </div>
   </template>
   
   <script>
    import configMixin from '@/mixins/configMixin'

       export default {
           name: 'start client',
            mixins: [configMixin],
           data() {
               return {
                 show: false,
                 isError: false,
                 username: ''
               }
           },
           watch: {
            username() {
                if(this.username[0] != "@") this.username = '@' + this.username
            }
           },
           methods: {
                getDataEmploy(object) {
                    object = object.split('@').join("")
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/user/get/client/' + object, {
                            method: "GET"
                        })
                        if(query.status != 200) return Promise.resolve({error: 'Employee not found'})
                        let result = await query.json()

                        return Promise.resolve(result)
                    }

                    return response()
                },
                findEmployee() {
                    this.getDataEmploy(this.username.split("@").join("")).then(data => {
                        if(data?.error != undefined) this.isError = true
                        else this.$router.push('/client/employee/' + this.username.split("@").join(""))
                    })
                },
                codeKeyDown(event) {
                    if (event.keyCode === 13) {
                        this.findEmployee();
                    }
                }

            },
            beforeMount() {
                
            }
       }
   </script>