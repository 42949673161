<template>

    <qr-code 
    v-if="isActiveQRcode" 
    @close="setActiveQR" 
    :personal="userData.username"
    :qr="qr_id"
    @update="updateQr"/>
    <div class="container-dash">
        <employee-dashboard :demo="true"/>

        <div class="wrapper-dash main-mirciq workspace-wrapper-das min-height pb-4 wrapper-profile-div" :class="{'calendar-active-cont': (isActiveDel || 
        isActiveCard || 
        isActiveEmpl || 
        isActiveNotife || 
        isActiveLogin || 
        isActiveQRcode || isActiveRecovery || isActivePhone || isActiveEmail || isDeleteAccount || isActivePassword)}">
            <div class="workspace-wrapper wrapper-profile center-mobile"> 
                <h2 class="main-title-dash d-none-desc">Profile</h2>
                <div class="avatar-empl mt-4 m-x-auto-mobile">
                    <img :src="userData.avatar">
                </div>
                <h3 class="name-emppl-profile mt-2">{{userData.name + ' ' + userData.last_name}}</h3>
                <p class="nickname-empl mt-1">@{{userData.username}}</p>    
                <div class=" mt-4 flex">
                    <button class="border-active-btn w-btn-1" >Update profile info</button> 
                    <button class="border-active-btn w-btn-1 ml-1" >Update bank info</button>
                </div>
                <button class="profile-btn mt-10 mt-4-mobile" @click="setActiveQR">Personal QR & Link</button>
                <button class="profile-btn mt-1" @click="$router.push('/demo/support')">FAQ & Help Centr</button>
                <button class="profile-btn mt-1">Contact technical support</button>
                <button class="profile-btn profile-btn-unactive mt-4" >Delete account</button>
                <button class="profile-btn profile-btn-red mt-1" >Log out</button>
            </div>
        </div>
    </div>
</template>

<script>
import configMixin from '@/mixins/configMixin'
import refreshQr from '@/mixins/refreshQr'

    export default {
        name: 'profile empl',
        mixins: [configMixin, refreshQr],
        data() {
            return {
                ActiveDateFilter: 'Week',
                isActiveHistory: false,
                isActiveDel: false,
                isActiveCard: false,
                isActiveEmpl: false,
                isActiveNotife: false,
                isActiveLogin: false,
                isActiveQRcode: false,
                profile: 'Nickname',
                userData: {
                    name: '',
                    last_name: '',
                    username: '',
                    avatar: null,
                    phone: '',
                    email: ''
                },
                settings: '',
                qr_id: '',
                isActiveRecovery: false,
                title: '',
                isActivePhone: false,
                isActiveEmail: false,
                isActivePassword: false,
                Edit: '',
                isDeleteAccount: false
            }
        },
        methods: {
            setActiveRecovery(value) {
                this.isActiveRecovery = !this.isActiveRecovery

                this.isActiveEmpl = !this.isActiveEmpl

                this.Edit = value
                if(this.isActiveRecovery == true) {
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/code/user', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                            }
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }

                    return response()
                }
            },
            getTitleRecovery(value){
                if(value == 'phone') return 'PHONE NUMBER'
                else if(value == 'email') return 'EMAIL'
                else return 'PASSWORD'
            },
            setActiveDelete() {
                this.isActiveDel = !this.isActiveDel
            },
            setDelete() {
                this.isActiveDel = false
                this.isDeleteAccount = !this.isDeleteAccount
            },
           setActiveDel() {
            this.isActiveDel = !this.isActiveDel
            this.Edit = 'delete'
            if(this.isActiveDel == true) {
                    let response = async() => {
                        let query = await fetch(this.config.API_URL + '/code/user', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                            }
                        })

                        let result = await query.json()

                        return Promise.resolve(result)
                    }

                    return response()
                }
           },
           setActiveRecoveryDel() {
            this.isActiveRecovery = !this.isActiveRecovery
           },
           setActiveCard() {
            this.isActiveCard = !this.isActiveCard
           },
           setActiveEmpl() {
            this.isActiveEmpl = !this.isActiveEmpl
           },
           setActiveNotif() {
            this.isActiveNotife = !this.isActiveNotife
           },
           setActiveLogin() {
            this.isActiveLogin = !this.isActiveLogin
           },
           setActiveQR() {
            this.isActiveQRcode = !this.isActiveQRcode
           },
           updateQr() {
            this.refreshQr().then(data => {
                this.qr_id = data.qr
            })
           },
           isSend() {
                this.isActiveRecovery = !this.isActiveRecovery
                if(this.Edit == 'email') this.setEmail()
                else if (this.Edit == "phone") this.setPhone()
                else if(this.Edit == 'delete') this.setActiveDel()
                else if(this.Edit == 'password') this.setPassword()
            },
            setPhone() {
                this.isActivePhone = !this.isActivePhone
                if(this.isActivePhone == false) this.$router.go()
            },
            setPassword() {
                this.isActivePassword = !this.isActivePassword
                if(this.isActivePassword == false) this.$router.go()
            },
            setEmail() {
                this.isActiveEmail = !this.isActiveEmail
                if(this.isActiveEmail == false) this.$router.go()
            },
            LogOut() {
                localStorage.removeItem('auth-token')
                this.$router.push('/')
            },
            deleteAccount() {
                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/employee/profile', {
                        method: "DELETE",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                        }
                    })

                    let result = await query.json()

                    return Promise.resolve(result)
                }

                return response().then(data => {
                    localStorage.clear()
                    this.$router.push('/')
                })
            },
           getData() {
            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/employee/profile', {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            //return response().then(data => {
                this.userData.phone = '+1000000000'
                this.userData.name = "Demo"
                this.userData.last_name = "User"
                this.userData.email = "demo.merciq@gmail.com"
                this.userData.avatar = '/img/uploads/logo/esta/1487716857-user_81635.svg'
                this.qr_id = '/demo/'
                this.settings = ''
                this.userData.username = 'demo'

            //})
           }
        },
        beforeMount() {
            this.getData()
        }
    }
</script>