<template>
     <div class="container-dash" >
        <employee-dashboard />
        <div class="wrapper-dash main-mirciq workspace-wrapper-das min-height pb-4 wrapper-profile-div">
            <div class="workspace-wrapper wrapper-profile center-mobile"> 
                <h2 class="main-title-dash pointer" @click="$router.push('/employee/profile')"><img src="../../../assets/img/UI/left-strel.svg">Techical support</h2>

                <div class="mt-6">
                    <p class="tech-number">You can contact us by using the following methods</p>
                    <button class="border-grey mt-4 child-bg-color" @click="goToWa">Chat via WhatsApp</button>
                    <button class="border-grey mt-1 child-bg-color" @click="goToEmail">Email</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'support profile empl',
        methods: {
            goToWa() {
                window.location.href='https://wa.me/+393314657539';
            },
            goToEmail() {
                window.location.href='mailto:merciqceo@gmail.com';
            }
        }
    }
</script>