<template>
        <div class="select" :style="style">
            <ul ref="select" class="closed esta-select"  @click="setClassSelect" :style="{width: width ? width : '280px', 'background-color': (bgColor) ? bgColor : '#1E1E1E'}">
                <li :values="(item?.name === undefined) ? item : item.name" v-for="item in inps" class="option" :style="{'background-color': (bgColor) ? bgColor + ' !important'  : '#1E1E1E'}" @click="e => {setValue((item?.name === undefined) ? item : item.name, e, item?.id)}">{{ (item?.name === undefined) ? item : item.name }}</li>
            </ul>
        </div>
        <img src="../assets/img/UI/select-panel-min.svg"  class="select-strel" @click="setClassSelect">
        <span class="floating-label" :style="{left: width ? '-'+ String(Number(width.split('px').join('')) - 10) + 'px' : '-275px'}">{{ label }}</span>
</template>

<script>
    export default {
        name: 'select-input',
        props: {
            width: String,
            label: String,
            inps: Array,
            modelValue: String,
            style: Object,
            bgColor: String
        },
        emits: ['getvalue'],
        data() {
            return {
                value: (this.inps[0]?.name === undefined) ? this.inps[0] : this.inps[0].name,
                id: (this.inps[0]?.id === undefined) ? this.inps[0] : this.inps[0].id
            }
        },
        mounted() {
            this.$emit('getvalue', this.value, this.id);

        },
        methods: {
            setValue(value, e, id) {
                this.$refs.select.insertBefore(e.target, this.$refs.select.firstChild)
                this.value = value
                this.id = id
                this.getValue()
            },
            setClassSelect(value) {
                this.$refs.select.classList.toggle('closed');
                this.$refs.select.style.marginBottom = '-' + String(this.$refs.select.offsetHeight - 500) + 'px'
            },
            getValue() {
                console.log('getvalue', this.value, this.id)
                this.$emit('getvalue', this.value, this.id)
            }
        },
    }
</script>

<style>
.select-strel {
    position: relative;
    left: -10px
}
.floating-label {
    z-index: 2;
	position: relative;
    left: -320px;
	pointer-events: none;
    color: white;
	transition: 0.2s ease all;
    font-size: 12px;
    color: #ABABAB;
    margin-top: 5px;
    white-space: nowrap;
}
.esta-select, .select ul li.option {
    z-index: 1;
    padding-top: 13px;
    padding-bottom: 7px;
    border: none;
}
.esta-select {
    padding-left: 4px;
    max-height: 300px !important;
}
.closed {
    height: 59px !important
}
</style>