import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        getDataEmployee(name, last_name, esta_id, email, phone, team_id, job) {
            let objectBody = {
                name: name,
                last_name: last_name,
                email: email,
                phone: phone,
                establishment_id: esta_id,
                team_id: team_id,
                job: job
            }

            let response = async() => {

                let query = await fetch(this.config.API_URL + '/user/employee', {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(objectBody)
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        },
        getDataTeamAndEsta() {

            let response = async() => {

                let query = await fetch(this.config.API_URL + '/business/collectinos', {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        },
        registrManager(role, email, name, lastname, phone, select) {
            let object = {
                role: role,
                name: name,
                last_name: lastname,
                phone: phone,
                email: email,
                establishments: select
            }

            let response = async() => {
                let query = await fetch(this.config.API_URL + '/user/manager', {
                    method: "POST",
                    headers: {
                        "Content-Type": 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("auth-token")
                    },
                    body: JSON.stringify(object)
                })

                let result = await query.json()

                return Promise.resolve(result)
            }

            return response()
        }
    }
}