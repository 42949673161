<template>
  <div class="faq-el mt-2" @click="showTick = !showTick">
    <div class="flex">
      <h3>{{ title }}</h3>
      <img src="../../assets/img/UI/chevron-down.svg" class="pointer" :style="{transform: showTick ? 'rotate(180deg)' : 'none'}">
    </div>
    <p v-if="showTick" class="faq-p">{{ text }}</p>
  </div>
</template>

<script>
  export default {
    name: 'faq-element',
    props: {
      title: String,
      text: String
    },
    data: function () {
      return {
          showTick: false
      }
    },
  }
</script>

<style scoped> 

  .faq-el {
      padding: 20px;
      border: 1px solid #f28e22;
      border-radius: 20px;
  }
  .mt-2 {
      margin-top: 20px;
  }

  .faq-el .flex {
      justify-content: space-between;
  }

  .faq-el h3 {
      color: #fff;
      font-size: 30px;
  }

  .faq-el p {
    width: 100%;
    text-align: left;
    margin-top: 20px;
    color: #ababab;
    font-family: Rubik-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: .4px;
  }

  .h1, .h2, .h3, .h4, .h5, h1, h2, h3, h4, h5, h6 {
      font-family: 'Rubik-Bold'
  }

  .pointer {
      cursor: pointer;
  }

</style>