<template>
    <history-el 
    @close="setActiveHistory" 
    v-if="isActiveHistory" 
    :id="showData.id" 
    :time="showData.time" 
    :price="showData.price" 
    :desc="showData.desc" 
    :stars="showData.stars" style="position: fixed;"/>
    <div class="container-dash" >
        <employee-dashboard :demo="true"/>
        <div class="wrapper-dash main-mirciq workspace-wrapper-das min-height pb-4" :class="{'calendar-active-cont': isActiveHistory}">
            <div class="workspace-wrapper"> 
                <h2 class="main-title-dash pointer" @click="$router.push('/demo/workspace')"><img src="../../../assets/img/UI/left-strel.svg">Finance history</h2>
                <div class="main-b-balance">
                <p>Balance</p>
                <h1>$190.00</h1>
                <div class="b-button">
                    <my-button active="true" label="Withdraw"/>
                </div>
            </div>
            <div class="title-li-panel flex mt-5">
                <div class="main-nav-mobile" ref="mobile_menu" @touchstart="touchStartFunc" @touchmove="touchEndFunc" @touchend="touchEndFunc">
                    <div class="min-panel-nav flex">
                        <h2 :class="{ active_nav_panel: isActive[0]}" @click="setNavPanel('', 0)" >All</h2>
                        <h2 :class="{'ml-4': true, 'active_nav_panel': isActive[1]}" @click="setNavPanel('',1)">Income</h2>
                        <h2 :class="{'ml-4': true, active_nav_panel: isActive[2]}" @click="setNavPanel('Test', 2)">Withdrawals</h2>
                    </div>
                    <div class="main-line-menu">
                        <div :class="{'fr-main-inp-active': isActive[0]}" class="fr-main-inp empl-l-1"></div>
                        <div :class="{'fr-main-inp-active': isActive[1]}" class="sc-main-inp empl-l-2"></div>
                        <div :class="{'fr-main-inp-active': isActive[2]}" class="tr-main-inp empl-l-3"></div>
                    </div>
                </div>
            </div>
                <div class="main-b-graph mt-3">
                <!-- <p class="total-graph main-text-color">Total: ${{balance}}</p> -->
                <div class="line-graph">
                    <div class="flex line-graph-l">
                        <div v-for="el in line_arr" class="l-1" @click="e => {showMoney(e)}">
                            <div class="height-panel-ert">{{el.sum}}$</div>
                            <div class="l-1-div" :style="{'height': el.heightEl + 'px'}"></div>
                            <p class="l-p">{{el.day}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex date-filter-empl mt-2">
                    <div class="el-date-filter-empl pointer" @click="setFilterDate('Day')" :class="{'el-date-filter-empl-active': ActiveDateFilter == 'Day'}">
                        Day
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="setFilterDate('Week')" :class="{'el-date-filter-empl-active': ActiveDateFilter == 'Week'}">
                        Week
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="setFilterDate('Month')" :class="{'el-date-filter-empl-active': ActiveDateFilter == 'Month'}">
                        Month
                    </div>
                    <div class="el-date-filter-empl ml-05 pointer" @click="setFilterDate('Year')" :class="{'el-date-filter-empl-active': ActiveDateFilter == 'Year'}">
                        Year
                    </div>
            </div>
            <div class="user-transactions" v-if="isActive[0]">
                <!-- <p class="date-empl-w mt-2">20 December</p> -->
                <div class="histoery-work" v-for="el in trans">
                    <history-transaction-empl 
                    @click="setActiveHistory(el.id, el.time, el.price, el.stars, el.desc)" 
                    :id="zeroPad(el.id)" 
                    :time="el.time" 
                    :desc="(el.comment == null) ? '' : el.comment" 
                    :stars="(el.stars == null) ? 0 : el.stars" 
                    :price="el.sum"/>
                </div>
                <p v-if="trans.length == 0" class="none-info">At the moment, we don’t have enough data to display the information on this page. Try again later when you get enough incoming transactions</p>
                <paginate 
                :count="Number(lastpage)"
                link="/employee/finance"/>
             </div>
             <div class="user-transactions" v-if="isActive[1]">
                <!-- <p class="date-empl-w mt-2">20 December</p> -->
                <div class="histoery-work" v-for="el in trans">
                    <history-transaction-empl 
                    @click="setActiveHistory(el.id, el.time, el.price, el.stars, el.desc)" 
                    :id="zeroPad(el.id)" 
                    :time="el.time" 
                    :desc="(el.comment == null) ? '' : el.comment" 
                    :stars="(el.stars == null) ? 0 : el.stars" 
                    :price="el.sum"/>
                </div>
                <p v-if="trans.length == 0" class="none-info">At the moment, we don’t have enough data to display the information on this page. Try again later when you get enough incoming transactions</p>
                <paginate 
                :count="Number(lastpage)"
                link="/employee/finance"/>
             </div>
             <div class="user-transactions" v-if="isActive[3]">
                <!-- <p class="date-empl-w mt-2">20 December</p> -->
                <div class="histoery-work" v-for="el in trans">
                    <history-transaction-empl 
                    @click="setActiveHistory(el.id, el.time, el.price, el.stars, el.desc)" 
                    :id="zeroPad(el.id)" 
                    :time="el.time" 
                    :desc="(el.comment == null) ? '' : el.comment" 
                    :stars="(el.stars == null) ? 0 : el.stars" 
                    :price="el.sum"/>
                </div>
                <p v-if="trans.length == 0" class="none-info">At the moment, we don’t have enough data to display the information on this page. Try again later when you get enough incoming transactions</p>
                <paginate 
                :count="Number(lastpage)"
                link="/employee/finance"/>
             </div>
             
            </div>
    </div>
            
    </div>
</template>

<script>
    import dashboardMixin from '@/mixins/dashboardMixin';
    import getFinanceMixin from '@/mixins/getFinanceMixin';

    export default {
        name: 'finance main',
        mixins: [dashboardMixin, getFinanceMixin],
        data() {
            return {
                ActiveDateFilter: 'Week',
                isActiveHistory: false,
                showData: {
                    id: '',
                    time: '',
                    price: '',
                    desc: '',
                    stars: ''
                },
                trans: [
                    {
                        id: 1,
                        time: '16:30',
                        comment: "It was great",
                 
                        sum: 20
                    },
                    {
                        id: 2,
                        time: '15:34',
                        comment: "It was great",
                        stars: '4',
                        sum: 70
                    },
                    {
                        id: 3,
                        time: '14:12',
                        stars: '5',
                        sum: 10
                    },
                    {
                        id: 4,
                        time: '13:59',
                        stars: '4',
                        sum: 5
                    },
                    {
                        id: 5,
                        time: '11:45',
                        stars: '5',
                        sum: 30
                    },
                    {
                        id: 6,
                        time: '9:01',
                        desc: "It was great",
                        sum: 25
                    },
                    {
                        id: 7,
                        time: '18:31',
                        stars: '4',
                        sum: 15
                    }
                ],
                balance: 0,
                lastpage: 0
            }
        },
        warch: {
            "$route.query.page": function() {
               
            },
        },
        methods: {
            zeroPad(num) {
                return num.toString().padStart(7, "0");
            },
            showMoney(e) {
                if(e.target.classList[0] != 'l-1') {
                    if(e.target.parentNode.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.parentNode.querySelector('.height-panel-ert').style.display = 'none'
                    }
                } else {
                    if(e.target.querySelector('.height-panel-ert').style.display == 'none') {
                        e.target.querySelector('.height-panel-ert').style.display = 'block'
                    } else {
                        e.target.querySelector('.height-panel-ert').style.display = 'none'
                    }
                }
            },
            setFilterDate(value) {
                this.ActiveDateFilter = value
                if(this.ActiveDateFilter == 'Day') {
                    this.trans = [
                    {
                        id: 1,
                        time: '16:30',
                        comment: "It was great",
                 
                        sum: 20
                    },
                    {
                        id: 2,
                        time: '15:34',
                        comment: "It was great",
                        stars: '4',
                        sum: 70
                    },
                    {
                        id: 3,
                        time: '14:12',
                        stars: '5',
                        sum: 10
                    },
                    {
                        id: 4,
                        time: '13:59',
                        stars: '4',
                        sum: 5
                    },
                    {
                        id: 5,
                        time: '11:45',
                        stars: '5',
                        sum: 30
                    },
                    {
                        id: 6,
                        time: '9:01',
                        desc: "It was great",
                        sum: 25
                    },
                    
                    ]
                } else {
                    this.trans = [
                    {
                        id: 1,
                        time: '16:30',
                        comment: "It was great",
                 
                        sum: 20
                    },
                    {
                        id: 2,
                        time: '15:34',
                        comment: "It was great",
                        stars: '4',
                        sum: 70
                    },
                    {
                        id: 3,
                        time: '14:12',
                        stars: '5',
                        sum: 10
                    },
                    {
                        id: 4,
                        time: '13:59',
                        stars: '4',
                        sum: 5
                    },
                    {
                        id: 5,
                        time: '11:45',
                        stars: '5',
                        sum: 30
                    },
                    {
                        id: 6,
                        time: '9:01',
                        desc: "It was great",
                        sum: 25
                    },
                    {
                        id: 7,
                        time: '18:31',
                        stars: '4',
                        sum: 15
                    }
                    ]
                }
            },
            setActiveHistory(id, time, price, stars, desc) {
                this.showData.id = id
                this.showData.time = time
                this.showData.price = price
                this.showData.stars = stars
                this.showData.desc = desc
                this.isActiveHistory = !this.isActiveHistory
            },
            getDayOfWeek(date, date_count) {
                if(date_count.view == 12) {
                    let need_date = new Date()  
                    let current = new Date(need_date.getFullYear(), need_date.getMonth()-date_count.value, 1);
                    const month = current.toLocaleString('en-US', { month: 'short' });
                    return month[0];

                } else if(date_count.view == 4) {

                    const getDateForWeek = (countDayAgo) => {
                        const dateDaysAgo = new Date(Date.now() - countDayAgo * 24 * 60 * 60 * 1000)

                        let date = (dateDaysAgo.getDate() < 10) ? "0" + dateDaysAgo.getDate() : dateDaysAgo.getDate()
                        let month = (dateDaysAgo.getMonth() + 1 < 10) ? "0" + (dateDaysAgo.getMonth() + 1) : dateDaysAgo.getMonth() + 1

                        return date + '.' + month
                    }

                    const date_arr = {
                        '0': getDateForWeek(7) + " - " + getDateForWeek(0),
                        '1': getDateForWeek(15) + " - " + getDateForWeek(8),
                        '2': getDateForWeek(23) + " - " + getDateForWeek(16),
                        '3': getDateForWeek(30) + " - " + getDateForWeek(24)
                    }

                    return date_arr[String(date_count.value)]
                } else {
                    let current = date
                    let today = current.toLocaleDateString('en-US',{weekday: 'long'});

                    let today2 = new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(current);
                    
                    return today2[0]
                }

            },
            FinanceMixin(date) {
                Array.prototype.max = function() {
                        return Math.max.apply(null, this);
                };

                
                let page_id = (this.$route.query.page == undefined) ? 1 : this.$route.query.page
                this.getDataMixin(page_id, date).then(data => {
                    console.log(data.rows.length)
                    this.trans = data.rows
                    this.balance = data.balance
                    this.lastpage = Math.ceil(data.count / 10)
                    
                    let maxEl = Number(data.days.max())

                    this.line_arr = []


                    data.days.forEach((el, i) => {
                        let date = new Date()

                        let maxDay = data.days.length - 1

                        maxDay = maxDay - i

                    
                        date.setDate(new Date().getDate() - maxDay)
                        
                        let days_data = {
                            sum: (el == null) ? 0 : Number(el).toFixed(0),
                            heightEl: (el == null) ? 0 : Number(el) * 80 / maxEl,
                            day: this.getDayOfWeek(date, {
                                view: data.days.length,
                                value: maxDay
                            })
                        }
                        this.line_arr.push(days_data)
                    })
                })
            }
        },
        beforeMount() {
            this.lastpage = 1
            Array.prototype.max = function() {
                        return Math.max.apply(null, this);
                };

            this.line_arr = []

            let data = [0, 0, 0, 0, 0, 15, 175];
            let maxEl = Number(data.max())
            data.forEach((el, i) => {
                let date = new Date()

                let maxDay = data.length - 1

                maxDay = maxDay - i


                date.setDate(new Date().getDate() - maxDay)
                
                let days_data = {
                    sum: (el == null) ? 0 : Number(el).toFixed(0),
                    heightEl: (el == null) ? 0 : Number(el) * 80 / maxEl,
                    day: this.getDayOfWeek(date, {
                        view: data.length,
                        value: maxDay
                    })
                }
                this.line_arr.push(days_data)
            })
        }
    }
</script>