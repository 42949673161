<template>
    <loading
    text="Account creation in progress, please wait..."
    v-if="DOM.isActiveLoading"/>

 <div class="outer-modal-div-form" :class="{'calendar-active-cont': DOM.isActiveLoading}">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form">
                <div class="wrapper-inner-modal"> 
                <div class="HeadStroke_father">
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke white"></div>
                    <div class="HeadStroke grey"></div>
                    <div class="HeadStroke grey"></div>
                </div>
                    <h3 class=" mt-4" @click="saveClicked">PUBLIC<br>INFORMATION ABOUT<br>THE ORGANISATION</h3>
                    
                    <div>
                        <input type="file" id="logo-select" @change="previewFiles" style="position: relative; z-index: 15">
                        <avatar-editor label="Logo of the Organization" @get="getAvatar"/>
                        </div>
                        <img ref="t_img" src="" class="mt-2" style="border-radius: 100px;">
                    <div>
                    <my-input 
                        v-if="isInvidual" 
                        bgColor="#252525" 
                        v-model="formData.publicName"                         
                        type="text" 
                        @getValue="formData.publicName"
                        label="Public Name Of The Organisation" 
                        width="320px" 
                        style="width: 320px;"
                    />
                    <div class="flex mt-2 gap-10" v-if="isInvidual">
                        <span class="sett-check-fin">Use my Legal Name as Public Name</span>
                        <label class="toggle">
                            <input 
                                class="toggle-checkbox" 
                                type="checkbox" 
                                :checked="useLegalName" 
                                @click="toggleLegalName"
                            >
                            <div class="toggle-switch"></div>
                        </label>
                    </div>
                    
                    <vue-tel-input 
                        wrapperClasses="rg" 
                        inputClasses="rtb" class="rg mt-2" 
                        ref="inp" 
                        style="display: none; z-index: 7; position: relative; border: none; height: 47px;outline: none; box-shadow: none; background-color: #252525 !important;"  
                        v-model="formData.Country" :style="{'height': '62px'}" 
                        validCharactersOnly="true" 
                        @validate="phoneObject" 
                        @country-changed="setCountryCode"
                        :enabledCountryCode="true"
                        @input="foo"
                        :enabledFlags="false"
                        defaultCountry="US"
                        mode="international"
                    >
                    <template v-slot:arrow-icon>
                    </template>
                    </vue-tel-input>
                    <p class="error-input-text err-au" v-if="isActivePhoneErr">This phone already exists</p>
                    <!--
                        <my-input  label="Website or page on social networks" v-model="formData.Adress" @getvalue="Adress" class="mt-2" style="position: relative; z-index: 5"/>
                    -->
                    <p class="error-input-text err-au" v-if="isNotValidUrl">Please enter a valid url</p>
                    <textarea class="business-desc" placeholder="Business Description" v-model="formData.desc"></textarea>
                    <p class="error-input-text err-au" v-if="isOneErr">{{ isOneErrMessage }}</p>
                    <p class="error-input-text err-au" v-if="error.length > 0">Incorrect data</p>
                    <p class="error-input-text err-au" v-if="error.length > 0" v-for="el in error">{{el.error}}</p>
                    <my-button label="Next" :active="isActiveBtn" @click="sends" class="mt-4"/>
                </div>
                   
            </div>      
                    
                    
            </div>
        </div>
    </div>
    
</template>

<script>
import registrAdminMixin from '@/mixins/registrAdminMixin';

export default {
    name: 'FillingBusiness3',
    mixins: [registrAdminMixin],
    data() {
        return {
            timer: {minute: 1, seconds: 30},
            isActiveBtn: false,
            formData: {
                NameOrganisation: '',
                Country: '',
                Adress: '',
                logo: '',
                desc: '',
                publicName: ''
            },
            isSelectImg: false,
            imgs: '',
            urls: '',
            rotation: 0,
            scale: 1,
            isValid: false,
            countryCode: '',
            isActivePhoneErr: false,
            isPhoneValid: false,
            error: [],
            isNotValidUrl: false,
            isOneErr: false,
            isOneErrMessage: '',
            DOM: {
                isActiveLoading: false
            },
            isInvidual: false,
            useLegalName: true,        
        }
    },
    watch: {
        'formData.Country': function() {
            if(this.formData.Country.length < this.countryCode.length) {
                        this.formData.Country = this.countryCode
                    }
            this.checkPublicPhone(this.formData.Country).then(data => {
                if(data.ok === true) {
                    this.isPhoneValid = true
                    this.isActivePhoneErr = false
                } else if(data.ok === false) {
                    this.isActivePhoneErr = true
                } else {
                this.isPhoneValid = false
                }
                this.sendData()
            })
        },
        'formData.NameOrganisation': function() {
            this.sendData()
        },
        /*
        'formData.Adress': function() {            
            this.sendData()
        },
        */
        'formData.publicName': function() {   
            const publicName = localStorage.getItem('CEO.name') + ' ' + localStorage.getItem('CEO.lastname');
            if (this.formData.publicName != publicName) 
                this.useLegalName = false;
                        
            this.sendData();
        },
        'formData.logo': function() {
            this.sendData()
        },
        countryCode() {
            this.formData.phone = '+1'
            this.formData.Country = localStorage.getItem('CEO.phone')
        },
        'formData.desc': function() {
            this.sendData()
        }
    },
    methods: {
       async downloadImage(url) {
            var base64 = await axios
                .get(url, {
                responseType: "arraybuffer"
                })
                .then(response =>
                Buffer.from(response.data, "binary").toString("base64")
                );
            var img = new Image();
            img.src = "data:image/jpeg;base64, " + base64;
            return img;
            },
        setCountryCode(val) {
            this.countryCode = "+" + val.dialCode
        },
        phoneObject(object) {
                this.isValid = object.valid;
                console.log(this.isValid)
        },
        NameOrganisation(value) {
            this.formData.NameOrganisation = value
            this.sendData()
        },
        Country(value) {
            this.formData.Country = value
            this.sendData()
        },

        Adress(value) {

            this.formData.Adress = value

            /*
            const isValidHttpUrl = (string) => {
                let url;
                
                try {
                    url = new URL(string);
                } catch (_) {
                    return false;  
                }

                return url.protocol === "http:" || url.protocol === "https:";
            }

            if (!isValidHttpUrl(value)) {
                this.isNotValidUrl = true;
            } else {
                this.isNotValidUrl = false;
            }
            */

            this.sendData()
        },
        City(value) {
            this.formData.City = value
            this.sendData()
        },
        State(value) {
            this.formData.State = value
            this.sendData()
        },
        ZipCode(value) {
            this.formData.ZipCode = value
           this.sendData()
        },
        getAvatar(value) {
            this.formData.logo = value
            this.sendData()
        },
        sendData() {

            const addHttps = (url) => {
                if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
                    url = "https://" + url;
                }
                return url;
            }

            if(this.formData.Country != "" && 
            this.isActivePhoneErr == false && this.isPhoneValid == true &&
            this.formData.desc.trim() != '' && this.formData.Adress.trim() != '') this.isActiveBtn = true
            else this.isActiveBtn = false
            localStorage.setItem('public.desc', this.formData.NameOrganisation)
            localStorage.setItem('public.phone', this.formData.Country)

//            localStorage.setItem('public.website', addHttps(this.formData.Adress))
            localStorage.setItem('public.website', this.formData.Adress);

            localStorage.setItem('public.logo', this.formData.logo)
            localStorage.setItem('public.desc_product', this.formData.desc)

            if (this.isInvidual) {                
                localStorage.setItem('establishment.nameOrganization', this.formData.publicName)
            }

        },
        sends() {
            if(this.isActiveBtn) {
                this.isActiveBtn = false
                this.DOM.isActiveLoading = true
                
                this.sendApiData(this.formData.logo).then((data) => {
                    console.log(data);
                    if(data?.ok == true) {
                        localStorage.setItem('auth-token', data.token)
                        this.$router.push('/FillingBusiness4')
                    } else if (data?.error == 'Invalid data') {
                        this.isOneErr = true;
                        this.isOneErrMessage = "An error has occured. Try filling in the data again";
                    } else if(Array.isArray(data)) {
                        this.error = data.map(el => {
                            let err = el.error.replace('String', el.input)

                            el.error = err

                            return el
                        })
                    } else if (data?.error.length > 0) {
                        this.isOneErr = true;
                        this.isOneErrMessage = data?.error;
                        this.DOM.isActiveLoading = false
                    }
                }).catch(err => {
                    this.isOneErr = true
                    console.log(err);
                    this.isOneErrMessage = err.error;
                    this.DOM.isActiveLoading = false
                })
            }
        },
        toggleLegalName() {
            const uselegalNameNewValue = !this.useLegalName
            this.useLegalName = uselegalNameNewValue;
            if (!uselegalNameNewValue) {
                this.formData.publicName = '';
            } else {
                this.formData.publicName = localStorage.getItem('CEO.name') + ' ' + localStorage.getItem('CEO.lastname');
            }
        }
    },
    beforeMount() {
        

        if(localStorage.getItem('code-start') == undefined ||
        (localStorage.getItem('userData.email') == undefined && localStorage.getItem('userData.phone') == undefined) || 
        localStorage.getItem('userData.password') == undefined || 
        localStorage.getItem('userData.username') == undefined ||
        localStorage.getItem('establishment.typeBusiness') == undefined ||
        localStorage.getItem('establishment.nameOrganization') == undefined ||
        localStorage.getItem('adress.country') == undefined || 
        localStorage.getItem('adress.adress') == undefined || 
        localStorage.getItem('adress.city') == undefined ||
        localStorage.getItem('adress.state') == undefined ||
        localStorage.getItem('establishment.zipCode') == undefined ||
        localStorage.getItem('CEO.name') == undefined ||
        localStorage.getItem('CEO.occupation') == undefined ||
        localStorage.getItem('CEO.phone') == undefined ||
        localStorage.getItem('CEO.ceo_email') == undefined ) return this.$router.push('/signupforms')

        if (localStorage.getItem('establishment.typeBusiness') == "Self-Employed") {
            this.isInvidual = true;
            this.formData.publicName = localStorage.getItem('CEO.name') + ' ' + localStorage.getItem('CEO.lastname');
        }

        this.formData.Country = localStorage.getItem('CEO.phone')

        const username = localStorage.getItem('userData.username');
        this.formData.Adress = `https://merciq.app/@${username}`;        
    }
}
</script>

<style scoped>
    .sett-check-fin {
        font-size: 15px;
        margin-right: 0;
    }
</style>