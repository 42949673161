<template>
    <div class="outer-modal-div-form client-outer">
        <div class="middle-modal-div-form">
            <div class="inner-modal-div-form w-800">  
                <div class="height-client-est flex">
                    <img :src="require('../../assets/img/uploads/' + estaData.logo)" class="est-client-logo">
                    <div class="data-esta-client">
                        <h1>{{ estaData.name }}</h1>
                        <button class="btn-empl-choice" @click="$router.push('/client/search')">choose employees</button>
                    </div>
                    <div class="star-esta-s d-none-desc">
                        <img src="../../assets/img/UI/big-star-active.svg" v-for="star in estaData.star" @click="setStar(star)">
                        <img src="../../assets/img/UI/big-star-grey.svg" v-for="star_grey in 5 - estaData.star" @click="setStar(estaData.star + star_grey)">
                    </div>
                </div>
                <div class="esta-bottom-er">
                    <div class="flex flex-between flex-center flex-c">
                        <div class="stick-client w-320">
                            <h3 class="px-16 d-none-desc">Did you like everything?</h3>
                            <div class="flex flex-between mt-3">
                                <div class="el-like-client" :class="{'el-like-client-active': formData.check == 'Gourmet dishes'}"> 
                                    <div class="form_radio_b form-setting">
                                        <input id="radio-1" type="radio" name="radio" value="Gourmet dishes" checked @click="setCheck('Gourmet dishes')">
                                        <label for="radio-1"></label>
                                    </div>
                                    <img src="../../assets/img/UI/star-dynamic-premium.png">
                                    <p>Gourmet<br> dishes</p>
                                </div>
                                <div class="el-like-client" :class="{'el-like-client-active': formData.check == 'Excellent service'}"> 
                                    <div class="form_radio_b form-setting">
                                        <input id="radio-2" type="radio" name="radio" value="Excellent service" @click="setCheck('Excellent service')">
                                        <label for="radio-2"></label>
                                    </div>
                                    <img src="../../assets/img/UI/thumb-up-dynamic-premium.png">
                                    <p>Excellent<br>service</p>
                                </div>
                                <div class="el-like-client" :class="{'el-like-client-active': formData.check == 'Great atmosphere'}"> 
                                    <div class="form_radio_b form-setting">
                                        <input id="radio-3" type="radio" name="radio" value="Great atmosphere" @click="setCheck('Great atmosphere')">
                                        <label for="radio-3"></label>
                                    </div>
                                    <img src="../../assets/img/UI/heart-dynamic-premium.png">
                                    <p>Great<br>atmosphere</p>
                                </div>
                            </div>
                            <div class="center tyi" :class="{tyi: isAddComment}">
                                <button v-if="!isAddComment" class="btn-leave-client sbn mt-2" @click="setAddComment">Leave a comment</button>
                                <img src="../../assets/img/UI/close-calendar.svg" class="close-comment-cient pointer" @click="setAddComment" v-if="isAddComment">
                                <textarea class="comment-client" v-if="isAddComment" placeholder="Comment" v-model="formData.comment" @input="e => {autoHeight(e)}"></textarea>
                                <p class="count-symbols" v-if="isAddComment"><span>{{ formData.comment.length }}</span>/150</p>
                            </div>
                        </div>
                        <div class="d-none-mobile order-1 mt-4">
                            <p class="other-text-color">Did you like everything?</p>
                            <img src="../../assets/img/UI/mobile-big-active-star.svg" class="mt-2" v-for="star in estaData.star" @click="setStar(star)">
                            <img src="../../assets/img/UI/mobile-big-star-grey.svg" class="mt-2" v-for="star_grey in 5 - estaData.star" @click="setStar(estaData.star + star_grey)">
                        </div>
                        <div class="price-client w-320">
                            <h3 class="px-16">Enter the amount of the your order, and we will calculate the amount of tip for you</h3>
                            <div class="mt-3">
                                <input type="text" class="input-client-esta" @input="e => {isNumber(e)}" v-model="formData.price" maxlength="15">
                                <img src="../../assets/img/UI/close-client-inp.svg" @click="delPrice" class="close-inp-price-client">
                            </div>
                            <div class="" v-if="isAddChoise">
                                <input type="text" placeholder="Amount of the tip" class="input-client-esta" @input="e => {isNumber(e)}" v-model="formData.manualCh" maxlength="15">
                                <img src="../../assets/img/UI/close-client-inp.svg" @click="delCh" class="close-inp-price-client">
                            </div>
                            <div class="set-ch" v-if="formData.price.length > 1 && !isAddChoise" >
                                <input type="text" class="input-client-esta sgh" disabled :value="formData.convers">
                              
                                <div class="flex pro-div-ch flex-between">
                                    <div class="el-pro-ch pointer" @click="setProcent(10)" :class="{'el-pro-ch-active': chProcent == 10}">10%</div>
                                    <div class="el-pro-ch pointer" @click="setProcent(15)" :class="{'el-pro-ch-active': chProcent == 15}">15%</div>
                                    <div class="el-pro-ch pointer" @click="setProcent(20)" :class="{'el-pro-ch-active': chProcent == 20}">20%</div>
                                    <div class="el-pro-ch pointer" @click="setProcent(25)" :class="{'el-pro-ch-active': chProcent == 25}">25%</div>
                                </div>
                                <my-button label="Choose manual amount of the tip" @click="setAddChoise" style="width: 300px;height: 40px; margin-left: 10px;margin-top: 10px;" active="true"/>
                            </div>
                            <p class="other-text-color center the-fin">the final cost of payment will include<br>a commission 7.5% +30 cents</p>
                        </div>
                    </div>
                    <my-button label="Pay by card" active="true" class="mt-6"/>
                    <button class="border-grey paymet-method" style="border: 1px solid #343434">Other payment methods</button>       
                    <div class="flex mt-2">
                        <div class="form_radio_b">
                            <input id="radio-5" type="radio" checked>
                            <label for="radio-5"></label>
                        </div>
                        <p class="other-text-color ml-1">I agree with the terms of the <span class="user-client-agree pointer" @click="$router.push('')">user agreement</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>

    export default {
        name: 'establishment client',
        data() {
            return {
                estaData: { 
                    name: 'Denis Petrov',
                    logo: 'l.png',
                    star: 4
                },
                formData: {
                    check: 'Gourmet dishes',
                    price: '$',
                    star: '',
                    comment: '',
                    convers: '',
                    manualCh: ''
                },
                isAddComment: false,
                chProcent: 15,
                isAddChoise: false
            }
        },
        mounted() {
            let our_price = Number(this.formData.price.split('$').join(''))
            this.formData.convers = (our_price * (this.chProcent / 100)).toFixed(2)
        },
        watch: {
            'formData.price': function() {
                if(this.formData.price.length > 1)this.formData.price = this.formData.price.replace(/[^0-9.]/g,'')
                let our_price = Number(this.formData.price.replace('$', ''))
                this.formData.convers = (our_price * (this.chProcent / 100)).toFixed(2)
                if(isNaN(this.formData.convers)) {
                    this.formData.price = String(this.formData.price).substring(0, String(this.formData.price).length - 1);
                }
               
                if(this.formData.price[0] != "$") {
                    this.formData.price = '$' + this.formData.price
                }
            },
            'formData.manualCh': function() {
                if(this.formData.manualCh.length > 1)this.formData.manualCh = this.formData.manualCh.replace(/[^0-9.]/g,'')
                let our_price = Number(this.formData.manualCh.replace('$', ''))
                if(isNaN(this.formData.convers)) {
                    this.formData.manualCh = String(this.formData.manualCh).substring(0, String(this.formData.manualCh).length - 1);
                }
               
                if(this.formData.manualCh[0] != "$") {
                    this.formData.manualCh = '$' + this.formData.manualCh
                }
            },
            'chProcent': function() {
                let our_price = Number(this.formData.price.split('$').join(''))
                this.formData.convers = (our_price * (this.chProcent / 100)).toFixed(2)
            }
        },
        methods: {
            setAddChoise() {
                this.isAddChoise = !this.isAddChoise
            },
            setCheck(value) {
                this.formData.check = value
            },
            isNumber(e) {
                e.target.value =  e.target.value.replace(/[^0-9.]/g,'');
                if(e.target.value[0] != "$") {
                    e.target.value = '$' + e.target.value
                }
            },
            delPrice() {
                this.formData.price = '$'
            },
            delCh() {
                this.formData.manualCh = '$'
            },
            setStar(value) {
                console.log(value)
                this.estaData.star = value
                this.formData.star = value
            },
            autoHeight(e) {
                if(e.target.scrollHeight > 136) {
                    e.target.style.height = 'auto';
                    let applyNow = e.target.style.offsetHeight;
                    e.target.style.height = e.target.scrollHeight - 20 + 'px';
                }
            },
            setAddComment() {
                this.formData.comment = ''
                this.isAddComment = !this.isAddComment
            },
            setProcent(value) {
                this.chProcent = value
            }
        }
    }
</script>