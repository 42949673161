import { createRouter, createWebHistory } from 'vue-router'
//ADMIN
import InviteCode from '@/pages/InviteCode'
import Registr from '@/pages/Registr'
import Registr_1 from '@/pages/Registr-1'
import SignIn_1 from '@/pages/SignIn-1'
import SignInPhone from '@/pages/SignInPhone'
import RegistrFroms from '@/pages/RegistrFroms'
import Sms from '@/pages/Sms'
import TypeBusiness from '@/pages/TypeBusiness'
import Main from '@/pages/dashboard/Main'
import Average from '@/pages/dashboard/Average'
import AccountBalance from '@/pages/dashboard/AccountBalance'
import TransactionHistory from '@/pages/dashboard/TransactionHistory'
import StaffMain from '@/pages/staff/Main'
import Leaders from '@/pages/dashboard/Leaders'
import Reviews from '@/pages/dashboard/Reviews'
import EmployRegistr from '@/pages/staff/EmployRegistr'
import ManagerRegistr from '@/pages/staff/ManagerRegistr'
import AddEstablishment from '@/pages/establishment/AddEstablishment'
import MainEsta from '@/pages/establishment/Main'
import Transaction from '@/pages/establishment/Transaction'
import ManagersEsta from '@/pages/establishment/Managers'
import InfoEsta from '@/pages/establishment/Info'
import TeamMember from '@/pages/establishment/Member'
import Statistic from '@/pages/establishment/StatisticMember'
import FillingBusiness from '@/pages/FillingBusiness'
import FillingBusiness2 from '@/pages/FillingBusiness-2'
import FillingBusiness3 from '@/pages/FillingBusiness-3'
import FillingBusiness4 from '@/pages/FillingBusiness-4'
import FillingBusiness5 from '@/pages/FillingBusiness-5'
import SignIn from '@/pages/SignIn'
import SignInCode from '@/pages/SignInCode'
import ForgetEmail from '@/pages/ForgetEmail'
import PasswordRecovery from '@/pages/PasswordRecovery'
import NewPassword from '@/pages/NewPassword'
import ExcelReport from '@/pages/dashboard/ExcelReport'
import SettingMain from '@/pages/settings/Main'
import TypeBusinessSetting from '@/pages/settings/account/Type'
import PersonalSetting from '@/pages/settings/account/Personal'
import PublicInfo from '@/pages/settings/account/PublicInfo'
import SettingFin from '@/pages/settings/account/Finance'
import Notif from '@/pages/settings/NotifSetting'
import Security from '@/pages/settings/security/Main'
import MyEsta from '@/pages/settings/esta/Main'
import PaymentSetting from '@/pages/settings/payment/Main'
import TechSupport from '@/pages/settings/support/Main'
import Faq from '@/pages/settings/help/Faq.vue'
import UserAgreement from '@/pages/settings/agreement/Main'
import HistoryMember from '@/pages/establishment/History'
import AverageMember from '@/pages/establishment/Avarage'
import EmailConf from '@/pages/Email'
import RegistrFroms_1 from '@/pages/RegistrFroms-1'
import Username from '@/pages/Username'
import NotFound from '@/pages/404'
import TypeSign from '@/pages/TypeSign'
import AccountType from '@/pages/AccountType.vue'
import QRDash from '@/pages/dashboard/QRDash'
import Terms from '@/pages/settings/terms/Main'
import NFC from '@/pages/NFC'
import Backup from '@/pages/settings/backup/Main'
import StripeVerification from '@/pages/dashboard/Verification.vue'
import PayoutAdmin from '@/pages/dashboard/Payout.vue'
//EMPLOYEE
import RegistrChoice from '@/pages/employee/Registr'
import RegistrFormsEmpl from '@/pages/employee/RegistrFroms'
import SMSEmpl from '@/pages/employee/Sms'
import UsernameEmpl from '@/pages/employee/Username'
import FillingPersonalEmpl from '@/pages/employee/FillingPersonal'
import CreditCardEmpl from '@/pages/employee/CreditCard'
import SmsCreditCard from '@/pages/employee/BankCardCode'
import MakeEmolQr from '@/pages/employee/MakeQR'
import WorkSpaceMain from '@/pages/employee/workspace/Main'
import TipsEmpl from '@/pages/employee/workspace/Tips'
import AchievementsEmpl from '@/pages/employee/workspace/Achievements'
import Statistics from '@/pages/employee/statistic/Main'
import LeadersEmpl from '@/pages/employee/statistic/Leaders'
import EmplProfile from '@/pages/employee/profile/Main'
import SupportProfileEmpl from '@/pages/employee/profile/Tech'
import FinanceEmpl from '@/pages/employee/finance/Main'
import ReviewsEmpl from '@/pages/employee/statistic/Reviews'
import AvarageEmpl from '@/pages/employee/statistic/Average'
import TotalEarningsEmpl from '@/pages/employee/statistic/TransactionHistory'
import AddEmplGet from '@/pages/employee/AddEmployee'
import MakeQREmpl from '@/pages/employee/workspace/MakeQR.vue'
import EmployeeVerif from '@/pages/employee/docs/Verification.vue'
import PayoutEmpl from '@/pages/employee/Payout.vue'
import PaymentEmpl from '@/pages/employee/payment/Main.vue'
import FaqEmployee from '@/pages/employee/help/Faq.vue'
//CLIENT PAGES
import ClientStart from '@/pages/client/Main'
import QRscanClient from '@/pages/client/QRscan'
import EstaClient from '@/pages/client/Esta'
import SearchClent from '@/pages/client/Search'
import EmplClient from '@/pages/client/Employee'
import Success from '@/pages/client/Succes'
import Client404 from '@/pages/Employee404'
import Payment from '@/pages/client/Payment.vue'
//DEVELOPER
import MainDeveloper from '@/pages/developer/Main'
import UsersDeveloper from '@/pages/developer/User'
import TransactionDeveloper from '@/pages/developer/Transaction'
import InviteDev from '@/pages/developer/Invite'
//DEMO
import WorkspaceDemo from '@/pages/demo/workspace/Main'
import DemoAchievments from '@/pages/demo/workspace/Achievements'
import DemoTips from '@/pages/demo/workspace/Tips'
import DemoStatistic from '@/pages/demo/statistic/Main'
import DemoQR from '@/pages/demo/workspace/MakeQR'
import DemoFinanceEmpl from '@/pages/demo/finance/Main'
import DemoAverage from '@/pages/demo/statistic/Average'
import DemoReviews from '@/pages/demo/statistic/Reviews'
import DemoProfile from '@/pages/demo/profile/Main'
import DemoTech from '@/pages/demo/profile/Tech'

const routes = [
  //DEMO
  {
    path: '/demo/workspace',
    name: 'demo workspace',
    component: WorkspaceDemo,
  },
  {
    path: '/demo/support',
    name: 'demo support',
    component: DemoTech,
  },
  {
    path: '/demo/profile',
    name: 'demo profile',
    component: DemoProfile,
  },
  {
    path: '/demo/reviews',
    name: 'demo reviews',
    component: DemoReviews,
  },
  {
    path: '/demo/average',
    name: 'demo average',
    component: DemoAverage,
  },
  {
    path: '/demo/qr',
    name: 'demo qr',
    component: DemoQR,
  },
  {
    path: '/demo/statistic',
    name: 'demo statistic',
    component: DemoStatistic,
  },
  {
    path: '/demo/achievements',
    name: 'demo achievements',
    component: DemoAchievments,
  },
  {
    path: '/demo/tips',
    name: 'demo tips',
    component: DemoTips,
  },
  {
    path: '/demo/finance',
    name: 'demo finance main',
    component: DemoFinanceEmpl,
  },
  // DEVELOPER
  {
    path: '/developer/main',
    name: 'developer main',
    component: MainDeveloper,
    meta: {
      isDev: true,
      isAuth: true
    }
  },
  {
    path: '/nfc',
    name: 'nfc',
    component: NFC,
    meta: {
      isAuth: true
    }
  },
  {
    path: '/developer/invite',
    name: 'developer invite',
    component: InviteDev,
    meta: {
      isDev: true,
      isAuth: true
    }
  },
  {
    path: '/developer/transactions',
    name: 'transaction developer',
    component: TransactionDeveloper,
    meta: {
      isDev: true,
      isAuth: true
    }
  },
  {
    path: '/developer/users',
    name: 'user developer',
    component: UsersDeveloper,
    meta: {
      isDev: true,
      isAuth: true
    }
  },
  //CLIENT PAGES
  {
    path: '/',
    name: 'start client',
    component: ClientStart
  },
  {
    path: '/client/payment',
    name: 'payment client',
    component: Payment
  },
  {
    path: '/client/404',
    name: 'not found employee',
    component: Client404
  },
  {
    path: '/client/employee',
    name: 'employee client',
    component: EmplClient
  },
  {
    path: '/client/success',
    name: 'success client operation',
    component: Success
  },
  {
    path: '/client/employee/:id',
    name: 'employee client',
    component: EmplClient
  },
  {
    path: '/@:id',
    name: 'employee client @',
    component: EmplClient
  },
  {
    path: '/client/scan',
    name: 'QRscan client',
    component: QRscanClient
  },
  {
    path: '/client/search',
    name: 'search client',
    component: SearchClent
  },
  {
    path: '/client/establishment',
    name: 'establishment client',
    component: EstaClient
  },
  // EMPLOYEE PAGES
  {
    path: '/employee/personal',
    name: 'filling personal empl',
    component: FillingPersonalEmpl
  },
  {
    path: '/employee/stripe',
    name: 'stripe empl',
    component: EmployeeVerif,
    meta: {
      isWaiter: true,
      isAuth: true
    }
    
  },
  {
    path: '/employee/payout',
    name: 'employee payout',
    component: PayoutEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  }, 
  {
    path: '/employee/payment',
    name: 'employe payment information',
    component: PaymentEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },  
  {
    path: '/employee/qr',
    name: 'emplqr',
    component: MakeQREmpl
  },
  {
    path: '/employee/invitation',
    name: 'add employee get',
    component: AddEmplGet
  },
  {
    path: '/employee/average',
    name: 'average member empl',
    component: AvarageEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/reviews',
    name: 'rewiews order empl',
    component: ReviewsEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/leaders',
    name: 'leaders order empl',
    component: LeadersEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/support',
    name: 'support profile empl',
    component: SupportProfileEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/faq',
    name: 'Faq and Help Employee',
    component: FaqEmployee,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/finance',
    name: 'finance main',
    component: FinanceEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/profile',
    name: 'profile empl',
    component: EmplProfile,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/statistic',
    name: 'statistic',
    component: Statistics,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/achievements',
    name: 'workspace achievements',
    component: AchievementsEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/tips',
    name: 'workspace tips',
    component: TipsEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/workspace',
    name: 'workspace main',
    component: WorkSpaceMain,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/qrcode',
    name: 'MakeEmplQR',
    component: MakeEmolQr,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/bank',
    name: 'sms credit card',
    component: SmsCreditCard,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/card',
    name: 'bank card',
    component: CreditCardEmpl,
    meta: {
      isAuth: true,
      isWaiter: true
    }
  },
  {
    path: '/employee/username',
    name: 'username empl',
    component: UsernameEmpl,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/employee/sms',
    name: 'sms confirmation empl',
    component: SMSEmpl,
    meta: {
      isAuth: false
    }
  },
  {
    // path: '/employee',
    // name: 'sign up full empl',
    // component: RegistrChoice,
    // meta: {
    //   isAuth: false
    // }
  },  
  {
    path: '/employee/signup',
    name: 'sign up forms empl',
    component: RegistrFroms,
    meta: {
      isAuth: false
    }
  },
  // ADMIN PAGES
  {
    path: '/excel',
    name: 'excel report',
    component: ExcelReport,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  }, 
  {
    path: '/payout',
    name: 'payout',
    component: PayoutAdmin,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  }, 
  {
    path: '/stripe',
    name: 'stripe verification',
    component: StripeVerification,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  }, 
  {
    path: '/settings/terms',
    name: 'terms and condition',
    component: Terms,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  }, 
  {
    path: '/qr',
    name: 'qr code',
    component: QRDash,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  }, 
  {
    path: '/employee',
    name: 'input type',
    component: TypeSign,
  },  
  {
    path: '/accounttype',
    name: 'account type',
    component: AccountType,
    meta: {
      isAuth: false
    }
  },  
  {
    path: '/username',
    name: 'username admin',
    component: Username
  }, 
  {
    path: '/establishment/member/average',
    name: 'average member',
    component: AverageMember,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },  
  {
    path: '/establishment/member/average/:user_id',
    name: 'average member',
    component: AverageMember,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  }, 
  {
    path: '/establishment/member/history',
    name: 'member account history',
    component: HistoryMember,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  }, 
  {
    path: '/establishment/member/history/:user_id',
    name: 'member account history',
    component: HistoryMember,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },   
  {
    path: '/invitecode',
    name: 'invite code',
    component: InviteCode,
    meta: {
      isAuth: false
    }
  },  
  {
    path: '/signupshort',
    name: 'sign up',
    component: Registr,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/signupfull',
    name: 'sign up full',
    component: Registr_1,
    meta: {
      isAuth: false
    }
  }, 
  {
    path: '/staff/signin',
    name: 'first sign',
    component: SignIn_1,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/signupnumber',
    name: 'sign up formss',
    component: RegistrFroms_1,
    meta: {
      isAuth: false
    }
  }, 
  {
    path: '/signupforms',
    name: 'sign up forms',
    component: RegistrFroms,
    meta: {
      isAuth: false
    }
  },  
  {
    path: '/sms',
    name: 'sms confirmation',
    component: Sms,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/email',
    name: 'email confirmation',
    component: EmailConf,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/typebusiness',
    name: 'type business',
    component: TypeBusiness,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/dashboard',
    name: 'main dashboard',
    component: Main,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/dashboard/average',
    name: 'average order',
    component: Average,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/dashboard/businessbalance',
    name: 'business account balance',
    component: AccountBalance,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/dashboard/history',
    name: 'transaction history',
    component: TransactionHistory,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/staff',
    name: 'main staff',
    component: StaffMain,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/dashboard/leaders',
    name: 'leaders order',
    component: Leaders,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/dashboard/reviews',
    name: 'Reviews order',
    component: Reviews,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/staff/employeeregistr',
    name: 'employee registration',
    component: EmployRegistr,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/staff/mangerregistr',
    name: 'manager registration',
    component: ManagerRegistr,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/add',
    name: 'add establishment',
    component: AddEstablishment,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/team',
    name: 'main establishment',
    component: MainEsta,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/team/:establishment_id',
    name: 'main establishment',
    component: MainEsta,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/transaction',
    name: 'transaction establishment',
    component: Transaction,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/transaction/:establishment_id',
    name: 'transaction establishment',
    component: Transaction,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/managers',
    name: 'managers establishment',
    component: ManagersEsta,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/managers/:establishment_id',
    name: 'managers establishment',
    component: ManagersEsta,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/information',
    name: 'information establishment',
    component: InfoEsta,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/information/:establishment_id',
    name: 'information establishment',
    component: InfoEsta,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/member/',
    name: 'team member',
    component: TeamMember,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/member/:user_id',
    name: 'team member',
    component: TeamMember,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/member/statistic',
    name: 'statistic member',
    component: Statistic,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/establishment/member/statistic/:user_id',
    name: 'statistic member',
    component: Statistic,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  
  {
    path: '/FillingBusiness',
    name: 'Filling Business',
    component: FillingBusiness,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/FillingBusiness2',
    name: 'Filling Business2',
    component: FillingBusiness2,
    meta: {
      isAuth: false
    }
    },
  {
    path: '/FillingBusiness3',
    name: 'Filling Business3',
    component: FillingBusiness3,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/FillingBusiness4',
    name: 'Filling Business4',
    component: FillingBusiness4,
   
  },
  {
    path: '/FillingBusiness5',
    name: 'Filling Business5',
    component: FillingBusiness5,
   
  },
  {
    path: '/signin',
    name: 'sign in',
    component: SignIn,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/authcode',
    name: 'sign code',
    component: SignInCode,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/signin/phone',
    name: 'sign in phone',
    component: SignInPhone,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/forget',
    name: 'forget email',
    component: ForgetEmail
  },
  {
    path: '/recovery',
    name: 'recovery password',
    component: PasswordRecovery
  },
  {
    path: '/newpassword',
    name: 'new password',
    component: NewPassword
  },
  {
    path: '/settings',
    name: 'setting main',
    component: SettingMain,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/backup',
    name: 'setting backup',
    component: Backup,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/type',
    name: 'type business',
    component: TypeBusinessSetting,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/personal',
    name: 'personal info',
    component: PersonalSetting,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/public',
    name: 'personal information',
    component: PublicInfo,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/financial',
    name: 'financial model',
    component: SettingFin,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/notifications',
    name: 'notif setting',
    component: Notif,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/security',
    name: 'password and security',
    component: Security,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/establishments',
    name: 'my establishments',
    component: MyEsta,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/payment',
    name: 'payment information',
    component: PaymentSetting,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/support',
    name: 'techical support',
    component: TechSupport,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/faq',
    name: 'Faq and Help',
    component: Faq,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/settings/agreement',
    name: 'user agreement',
    component: UserAgreement,
    meta: {
      isAuth: true,
      isAdmin: true
    }
  },
  { 
    path: '/404', 
    name: 'not found',
    component: NotFound 
  },
  { path: "/:catchAll(.*)", redirect: '/404' },  
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),

})

export default router

