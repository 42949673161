<template>
    <div class="container-dash absolute-100-mobile">
        <dashboard-nav/>
        <div :class="{'wrapper-dash': true, 'staff-wrapper': true, 'calendar-active-cont': (isActiveRecovery || isActivePhone || isActiveEmail || isActivePassword), 'dop-wrpapper-setting': true}">
            <div class="flex">
                <img src="../../../assets/img/UI/left-strel-setting.svg" class="pointer mt-2-mobile" @click="$router.push('/settings')">
                <h2 class="main-title-dash pointer setting-title" style="margin-top: 0;">Techical support</h2>     
            </div>
            <div class="main-staff setting-div bg-security tech-div">
                <a class="tech-number" href="mailto:support@merciq.com">support@merciq.com</a>
               <a class="tech-number mt-2" href="https://api.whatsapp.com/send/?phone=%2B393314657539&text&type=phone_number&app_absent=0">+39 331 465 7539</a>
               <a class="center" href="https://api.whatsapp.com/send/?phone=%2B393314657539&text&type=phone_number&app_absent=0"><button class="border-grey mt-4">Call <span v-if="false">8:30 am</span></button></a>
               <a class="center" href="mailto:support@merciq.com"><button class="border-grey mt-1" >Email</button></a>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'techical support',
    }
</script>

