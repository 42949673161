<template>
    <div class="cale">
        <div class="flex">
            <h3 class="head-title">Employee QR</h3>
            <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
        </div>
        <img :src="datau" class="qr-img" style="width: 200px">
        <div class="center"><p v-if="personal != undefined" class="personal-nick">@{{ personal }}</p></div>
        <!--
        <div class="flex flex-center mt-4">
            <div class="btn-delte-est">
                <my-button label="update" active="true" @click="updateQR"/>
            </div>
        </div>
        -->
    </div>
</template>
    
<script>
    var QRCode = require('qrcode')

    export default {
        name: 'qr-code',
        props: {
            personal: String,
            qr: String
        },
        data() {
            return {
                datau: ''
            }
        },
        watch: {
            qr() {
                let result = ''
                QRCode.toDataURL(window.location.origin + '/client/employee/' + this.qr, function (err, url) {
                    result = url
                })
                this.datau = result
            }        },
        mounted() {
            let result = ''
            QRCode.toDataURL(window.location.origin + '/client/employee/' + this.qr, function (err, url) {
                result = url
            })
            this.datau = result
        },
        methods: {
            close() {
               this.$emit('close')
            },
            updateQR() {
                this.$emit('update')
            },
            makeid(length) {
                let result = '';
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                const charactersLength = characters.length;
                let counter = 0;
                while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
                }
                return result;
            }
        }
    }
</script>
    
<style scoped>
.qr-img {
    width: 180px;
    margin-left: 145px;
    margin-top: 49px;
}
.btn-delte-est {
    width: 460px;
}
.personal-nick {
    color: white;
    font-size: 25px;
    width: 100%;
}
.btn-sum {
    width: 219px;
    border: 1px solid #424242;
    border-radius: 200px;
    background: none;
    color: white;
    font-size: 16px;
    font-family: 'Rubik-Medium';

}
p {
    color: #ABABAB;
    width: 373px;
    margin-top: 20px;
}
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
}
.cale {
    position: absolute;
    top: 200px;
    left: calc(60% - 408px);
    z-index: 3;
    opacity: 1;
    width: 540px;
    padding: 40px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .cale {
        padding: 15px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .qr-img {
        text-align: center;
        margin-left: 60px;
    }
}
</style>