import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        updateInfo(data) {
        
            let objectJson = new FormData()

            objectJson.append('name', data.name)
            objectJson.append('last_name', data.last_name)
            if(data.phone != null && data.phone != undefined) objectJson.append('phone', data.phone.split(" ").join(""))
            else objectJson.append('phone', '')
            objectJson.append('email', data.email)
            objectJson.append('logo', data.logo)
            objectJson.append('username', data.username)
            objectJson.append('password', data.password)
            objectJson.append('dob', data.dob)
            objectJson.append('city', data.city)
            objectJson.append('state', data.state)
            objectJson.append('zip', data.zip)
            objectJson.append('ssn', data.ssn)
            objectJson.append('card_token', data.card_token)
            objectJson.append('address', data.address)


            console.log('itemId', localStorage.getItem('id'));

            // create a new personal business account for that case
            if (localStorage.getItem('id') == undefined) {

                if(localStorage.getItem('google_id') != undefined) {
                    objectJson.append('google_id', localStorage.getItem('google_id'))
                } else if(localStorage.getItem('facebook_id') != undefined) {
                    objectJson.append('facebook_id', localStorage.getItem('facebook_id'))
                }   

                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/employee/create', {
                        method: 'POST',
                        body: objectJson
                    })
                    if (query.ok) {
                        let result = await query.json()

                        return Promise.resolve(result)
                    } else {
                        return Promise.reject()
                    }
                }

                return response()
            } else {

                let response = async() => {
                    let query = await fetch(this.config.API_URL + '/user/employee/update?id=' + localStorage.getItem('id'), {
                        method: 'PUT',
                        body: objectJson
                    })
                    if (query.ok) {
                        let result = await query.json()

                        return Promise.resolve(result)
                    } else {
                        return Promise.reject()
                    }
                }

                return response()
            }
            
        }
    }
}