export default {
    data() {
        return {
            touchendX: 0,
            touchstartX: 0,
            refs: ''
        }
    },

    methods: {
        touchStartFunc(event) {
            if(event?.changedTouches[0]?.screenX != undefined) {
            this.touchstartX = event.changedTouches[0].screenX;
            this.touchstartY = event.changedTouches[0].screenY;
            }
        },
        touchEndFunc(event) {
            if(event?.changedTouches[0]?.screenX != undefined) {
            this.touchendX = event.changedTouches[0].screenX;
            this.touchendY = event.changedTouches[0].screenY;
            this.handleGesture(this.refs);
            }
        },
        handleGesture(es) {
            if (this.touchendX < this.touchstartX&& this.refs.style.left == '-150px') {
                this.refs.style.left = String(Number(this.refs.style.left.split('px').join('')) - 1) + "px"
            }
        
            if (this.touchendX > this.touchstartX && this.refs.style.left <= '0px') {
                this.refs.style.left = String(Number(this.refs.style.left.split('px').join('')) + 1) + "px"
            }
        
        }
    }
}