<template>
    <div class="cale" style="width: 320px">
        <h1 style="color: white; text-align: center;">An error has occurred! Try later!</h1>
        <my-button label="Ok" active="true" class="mt-2" @click="reloadPage"/>
    </div>
</template>
    
<script>
    export default {
        name: 'error',
        data() {
            return {
                
            }
        },
        methods: {
            reloadPage() {
                this.$router.go()
            }
        }
    }
</script>
    
<style scoped>
.btn-delte-est {
    width: 219px;
}
.btn-sum {
    width: 219px;
    border: 1px solid #424242;
    border-radius: 200px;
    background: none;
    color: white;
    font-size: 16px;
    font-family: 'Rubik-Medium';

}
p {
    color: #ABABAB;
    width: 373px;
    margin-top: 20px;
}
.close {
    margin-left: auto;
}
.head-title {
    font-size: 24px;
    font-family: 'Rubik-Medium';
    color: var(--main-text-color)
}
.cale {
    position: fixed;
    top: 200px;
    left: calc(60% - 408px);
    z-index: 3;
    opacity: 1;
    width: 540px;
    padding: 40px;
    background-color: #1E1E1E;
    border-radius: 10px;
}
@media screen and (max-width: 500px) {
    .cale {
        padding: 20px;
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .cale p {
        width: 290px;
        text-align: center;
    }
    .head-title {
        margin-left: auto;
        text-align: center;
        width: 300px;
    }
    .column-r-s {
        flex-direction: column;
    }
    .btn-sum {
        height: 48px;
        width: 271px;
        margin-top: 20px;
    }
    .btn-delte-est {
        width: 271px;
    }
}
</style>