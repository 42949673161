<template>
    <div class="cale-1">    
        <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
        <div class="el-history-transaction" ref="sty" style="background-color: var(--main-bg-color);">
            <div class="el-top-history flex" style="padding-bottom: 0;"> 
                    <img src="../../assets/img/UI/person.svg">
                    <p class="ids">{{ id }}</p>
                    <p class="times">{{ time }}</p>
                    <div class="stars flex">
                        <img src="../../assets/img/UI/start.svg" v-for="item in Number(stars)">
                    </div>
                    <p class="price ml-2">${{ price }}</p>
                </div>
            <div class="line-border mt-2" ></div>
            <p class="desc" >{{ desc }}</p>
        </div>

        <div class="el-history-transaction-mobile mt-1" style="background-color: var(--main-bg-color);">
        <div class="flex" >
                <img src="../../assets/img/UI/person.svg" class="ml-1">
                <p class="ids">{{ id }}</p>
                <p class="times ml-2">{{ time }}</p>
                <p class="price mobile-price">${{ price }}</p>
        </div>
        <div class="line-border mt-2"></div>
        <div class="flex mt-2">
                <div class="stars flex">
                            <img src="../../assets/img/UI/start.svg" v-for="item in Number(stars)">
                </div>
            </div>
            <p class="desc">{{ desc }}</p>
        </div>
    </div>
</template>
    
<script>
    export default {
        name: 'history-el',
        props: {
            id: String,
            time: String,
            stars: String,
            price: String,
            desc: String
        },
        data() {
            return {
                
            }
        },
        methods: {
            close() {
               this.$emit('close')
            },
        }
    }
</script>
    
<style scoped>

.el-history-transaction {
    background-color: #252525;
    width: 730px;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
}
.el-top-history {
    padding-bottom: 20px;
}
.ids {
    margin-left: 10px;
    font-family: 'Rubik-Regular';
    color: var(--other-text-color)
}
.times {
    color: var(--other-text-color);
    margin-left: 20px;
}
.employees {
    margin-left: 60px;
    color: white;
    font-family: 'Rubik-Regular';
}
.employee-s {
    font-family: 'Rubik-Regular';
    color: var(--other-text-color)
}
.stars {
    margin-left: auto;
}
.stars img {
    margin-left: 5px;
}
.price {
    color: white;
}
.line-border {
    width: 104.5%;
    margin-left: -2.25% !important;
    margin-left: auto;
    height: 1px;
    background-color: #2E2E2E;
}
.desc {
    margin-top: 20px;
    font-size: 14px;
    color: var(--other-text-color)
}
.el-history-transaction-mobile {
        display: none;
    }
    .cale-1 {
        position: absolute;
        left: calc(60% - 408px);
        top: 200px;
        opacity: 1;
        z-index: 3;
    }
.close {
    margin-left: 730px;
    margin-bottom: -30px;
}
@media screen and (max-width: 500px) {
    .close {
    margin-left: 320px;
    margin-bottom: -30px;
}
    .el-history-transaction-mobile {
        display: block;
        padding: 20px;
        border-radius: 10px;
        width: 320px;
        background-color: #252525;
    }
    .el-history-transaction {
        display: none;
    }
    .mobile-price {
        margin-left: 60px;
    }
    .employees {
        margin-left: auto;
    }
    .stars {
        margin-left: 0;
    }
    .stars :nth-child(1) {
        margin-left: 0;
    }
    .desc {
        margin-top: 10px;
    }
    .cale-1 {
        padding: 20px;
        width: 320px;
        margin-left: 5%;
        margin-right: auto;
        left: 0;
        position: fixed;
        right: 0;
    }
}
</style>