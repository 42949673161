import configMixin from "./configMixin";

export default {
    mixins: [configMixin],
    methods: {
        getDataMixin(page, date) {

            let dateFilter = (date == null || date == undefined) ? '' : '&date=' + date
            if(date != "Day" && date != 'Week' && date != 'Month' && date != 'Year' && date != null && date != undefined) {
                dateFilter = (date == null || date == undefined) ? '' : '&date=' + JSON.stringify({date: date})
            }
           
            let response = async () => {
                let query = await fetch(this.config.API_URL + '/transaction/reviews?page=' + page + dateFilter, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
                    }
                })

                let result = await query.json()

                return Promise.resolve(result)
            }   

            return response()
        }
    }
}