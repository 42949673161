<template>
  <div class="cale">
      <div class="inner-modal-div-form">
          <img src="../../assets/img/UI/close-calendar.svg" class="pointer close" @click="close">
          <p class="payout-title mt-4 ml-2 mr-2">
            {{ desc }}
          </p>
          <div class="m-x-auto w-320-mob mt-2">
              <my-button :active="true" label="Provide Information" @click="goTo" class=""/>
          </div>
          <div class="m-x-auto w-320-mob mt-2">
              <my-button :active="true" label="Skip for later" @click="close" class="not_active"/>
          </div>
      </div>
  </div>
</template>

<script>
  import configMixin from '@/mixins/configMixin';

  export default {
      name: 'custom-modal',
      mixins: [configMixin],
      data() {
          return {
              
          }
      },
      props: {
        desc: String
      },
      emits: ['close', 'goTo'],      
      methods: {
          close() {
              this.$emit('close')
          },
          goTo() {
            this.$emit('goTo')
          }
      },        
  }
</script>

<style scoped> 
.close {
  margin-left: auto;
}
.head-title {
  font-size: 24px;
  font-family: 'Rubik-Medium';
  color: var(--main-text-color);
  margin-left: auto;
  margin-right: auto;
}
.cale {
  position: absolute;
  top: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 3;
  opacity: 1;
  width: 400px;
  background-color: #1E1E1E;
  border-radius: 10px;
}

.close {
  position: absolute;
  margin-left: 55%;
  margin-top: -40px;
}
.inner-modal-div-form {
  background: #1E1E1E;
}
.inner-modal-div-form {
  border-radius: 10px;
}

@media screen and (max-width: 500px) {
  
  .btn-show {
    margin-left: 110px;
  }
  .inner-modal-div-form {
      width: 100%;
      padding: 0;
      padding-left: 18px;
      padding-right: 18px;
      padding-bottom: 18px;
  }
  .inner-modal-div-form {
    background: #1E1E1E;
  }
  .inner-modal-div-form h3:nth-child(1) {
      margin-top: 20px;
  }
  .cale {
      padding: 20px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      padding: 0;
      top: 50px;
      min-height: 100vh;
  }
  .head-title {
      margin-left: auto;
      text-align: center;
      width: 300px;
  }
  .close {
      margin-left: 40%;
      position: absolute;
      margin-top: -0px;
  }
  .btnj-short  {
      width: 100%;
  }
  .select-w {
      width: 100% !important;
  }
  .select {
      width: 100%;
  }
}
</style>