<template>
     <div class="calev" v-if="isActiveMore" style="position: absolute;z-index: 20">
        <h1>This website uses cookies</h1>
        <p class="mt-2">We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you've provided to them or that they've collected from your use of their services.</p>
        <div class="center">
            <button @click="accept" class="btn" style="color: white !important">Accept</button> 
        </div>
    </div> 
    <div class="cales center" v-if="isActive && !isActiveMore">
        <p style="font-family: 'Rubik-Regular'; font-size: 14px">We use cookies for your convenience
Please continue to use this web site only if you agree to this</p>
        <div class="center">
            <!-- <button @click="accept" class="btn">Accept</button> -->
            <p style="color: var(--orange-color); font-family: 'Rubik-Regular'" @click="showMore">What does that means?</p>
            <button @click="accept" class="btn" style="color: white !important">Accept</button>
            
        </div>
    </div>
</template>

<script>
    export default {
        name: "accept-cookie",
        data() {
            return {
                isActive: true,
                isActiveMore: false
            }
        },
        methods: {
            accept(){
                document.cookie = "accept_cookie=true; max-age=630720000";

                function getCookie(name) {
                var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
                return matches ? decodeURIComponent(matches[1]) : undefined;
            }
            let results = getCookie('accept_cookie')
           
            if(results != null && results != undefined) {
                this.isActive = false
                this.isActiveMore = false
            }
            else {
                this.isActive = false
                this.isActiveMore = false
            }
            },
            showMore() {
                this.isActiveMore = !this.isActiveMore
            }
        },
        beforeMount() {

            function getCookie(name) {
                var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
                return matches ? decodeURIComponent(matches[1]) : undefined;
            }
            let results = getCookie('accept_cookie')
            
            if (results != null && results != undefined) this.isActive = false
            else {
                if(window.innerWidth <= 500) {
                    this.isActive = true
                } else {
                    this.isActive = false;
                    this.isActiveMore = true
                }
            }
        }
    }
</script>

<style scoped>
    .calev {
        width: 320px;
        padding: 20px;
        border-radius: 20px;
        background-color: var(--dashboard-el);
        position: absolute;
        top: 200px;
        text-align: center;
        right: 0; left: 0;
        margin-left: auto; margin-right: auto;
    }
    .calev p {
        color: white;
    }

    .cales {
        position: fixed;
        width: 320px;
        padding: 20px;
        background-color: #171717;
        /* left: 10px;
        bottom: 10px;
        border-radius: 20px; */
        width: 100%;
        bottom: 0;
        z-index: 20;
        background-color: #2b2b2b;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .cales p {
        margin-top: 10px;
        color: white
    }
    .btn {
        margin-top: 20px;
        margin-left: auto; 
        margin-right: auto;
        width: 150px;
        border: none;
        font-size: 17px;
        background-color: var(--orange-color);
        border-radius: 20px;
        height: 30px
    }
   
    @media screen and (max-width: 500px) {
        .cales {
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: auto;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
</style>